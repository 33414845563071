import { useEffect, useState } from "react";
import { useSoftPhoneCtx } from "../../../context/softPhoneCtx/useSoftPhoneCtx";
import PendingWithCustomer from "./PendingWithCustomer";


const PendingCustomerConfirmation = ({handleOpen}:{handleOpen: () => void;
}) => {
  const { softPhoneState, methods: softPhoneMethods } = useSoftPhoneCtx();
  return (
    <PendingWithCustomer
      softPhoneCtxState={softPhoneState}
      softPhoneCtxMethods={softPhoneMethods}
      handleOpen = {handleOpen}
    />
  );
};

export default PendingCustomerConfirmation;
