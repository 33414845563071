import { ReactNode, useReducer } from "react";
import { CallLogForScreenProps } from "../softPhoneCtx/softPhoneCtx";
import {
  FloatModalForProps,
  SET_CUSTOMER_DETAILS_DISPATCH,
  SoftPhoneAndFreshChatFloatUICtx,
  SoftPhoneAndFreshChatFloatUIState,
  TOGGLE_FLOAT_BTN_UI_DISPATCH,
  TOGGLE_FLOAT_MODAL_FOR_DISPATCH,
} from "./softPhoneAndFreshChatFloatUICtx";
import softPhoneAndFreshChatFloatUICtxReducer from "./softPhoneAndFreshChatFloatUICtxReducer";

// initial state
const initialState: SoftPhoneAndFreshChatFloatUIState = {
  showFloatBtnsOptions: false,
  floatModalFor: "",
  callState: {
    // call state is used to identify in which screen call initiated and customer details (custId and mobile num) or rm details
    screen: "",
    churnId: "",
    deliquencyId: "",
    isCustomer: false,
    customer: {
      custId: "",
      mobileNum: "",
    },
    isRm: false,
    rm: {
      mobileNum: "",
    },
  },
};

const SoftPhoneAndFreshChatFloatUICtxProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  // reducer
  const [softPhoneAndFreshChatFloatUIState, dispatch] = useReducer(
    softPhoneAndFreshChatFloatUICtxReducer,
    initialState
  );

  // methods
  // toggle float btn UI
  const toggleFloatBtnUI = ({
    showFloatBtnsOptions,
  }: {
    showFloatBtnsOptions: boolean;
  }) => {
    dispatch({
      type: TOGGLE_FLOAT_BTN_UI_DISPATCH,
      payload: { showFloatBtnsOptions },
    });
  };

  // toggles the modal type missed call or mobile numbers list
  const toggleFloatModalFor = ({
    floatModalFor,
  }: {
    floatModalFor: FloatModalForProps;
  }) => {
    dispatch({
      type: TOGGLE_FLOAT_MODAL_FOR_DISPATCH,
      payload: { floatModalFor },
    });
  };

  // close the float UI options and float modal UI
  const closeFloatBtnUIAndFloatModal = () => {
    dispatch({
      type: TOGGLE_FLOAT_BTN_UI_DISPATCH,
      payload: { showFloatBtnsOptions: false },
    });
    dispatch({
      type: TOGGLE_FLOAT_MODAL_FOR_DISPATCH,
      payload: { floatModalFor: "" },
    });
  };

  // used to set the customer and screen details in the call state
  const setCustomerDetails = ({
    screen,
    isCustomer,
    custId,
    mobileNum,
    churnId,
    deliquencyId,
  }: {
    screen: CallLogForScreenProps;
    isCustomer: boolean;
    custId: string;
    mobileNum: string;
    churnId: string;
    deliquencyId: string;
  }) => {
    dispatch({
      type: SET_CUSTOMER_DETAILS_DISPATCH,
      payload: { screen, isCustomer, custId, mobileNum, deliquencyId, churnId },
    });
  };

  return (
    <SoftPhoneAndFreshChatFloatUICtx.Provider
      value={{
        softPhoneAndFreshChatFloatUIState,
        dispatch,
        methods: {
          toggleFloatBtnUI,
          toggleFloatModalFor,
          setCustomerDetails,
          closeFloatBtnUIAndFloatModal,
        },
      }}
    >
      {children}
    </SoftPhoneAndFreshChatFloatUICtx.Provider>
  );
};

export default SoftPhoneAndFreshChatFloatUICtxProvider;
