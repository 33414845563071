import { yupResolver } from "@hookform/resolvers/yup";
import CallIcon from "@mui/icons-material/Call";
import { Box, Grid, IconButton, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useSoftPhoneCtx } from "../../../context/softPhoneCtx/useSoftPhoneCtx";
import FormProvider from "../../CommonComponent/rhfComponents/formProvider";
import RHFTextField from "../../CommonComponent/rhfComponents/rhfTextField";
import { useGetCustDetailsByPhNumQuery } from "../hooks/useGetCustDetailsQuery";
import CallHistory from "./callHistory";
import SoftPhoneHeaderSection from "./softPhoneHeaderSection";
import { UGA_PHONE_NUM_DIGIT_COUNT } from "../../../consts";
import CustDetailUI from "./custDetailUI";
import { SOFT_PHONE_LOG_MODAL } from "../../../context/softPhoneCtx/softPhoneCtx";

const FormSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required("Phone number is required to make call")
    .min(UGA_PHONE_NUM_DIGIT_COUNT, "Invalid Number")
    .max(UGA_PHONE_NUM_DIGIT_COUNT, "Invalid Number"),
  custName: Yup.string(),
});

const SoftPhoneUI = () => {
  // ctx
  const {
    methods: { initiateCall },
    softPhoneState: { showCallStatusModal, isSoftPhoneClientReady },
  } = useSoftPhoneCtx();

  // hook
  const theme = useTheme();

  // hookForm
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues: { phoneNumber: "", custName: "" },
  });

  // rhf returns
  const {
    handleSubmit,
    watch,
    formState: { isValid },
  } = methods;

  // const
  const phoneNumber = watch("phoneNumber") ?? "";
  const hasAnyOngoingCall = showCallStatusModal;
  const isClientReady = isSoftPhoneClientReady;
  const bgStyle = {
    backgroundColor:
      // @ts-ignore
      theme.palette.custom_colors.old_primary_400,
    my: 1,
    px: 2,
    height: "76vh",
  };

  // queryhook
  const { isFetching } = useGetCustDetailsByPhNumQuery({ phoneNumber });

  // const
  const disableCallBtn =
    hasAnyOngoingCall || !isClientReady || !isValid || isFetching;

  // handler
  const onSubmit = handleSubmit((formData) => {});

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {/* header section UI */}
      <SoftPhoneHeaderSection />
      {/* call initiate UI */}
      <Grid container>
        <Grid item xs={12} md={6} lg={4} sx={bgStyle}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginY={2}
          >
            <RHFTextField name="phoneNumber" label="Phone Number" />
            <IconButton
              disabled={disableCallBtn}
              type="button"
              onClick={() =>
                initiateCall({
                  phoneNumber,
                  screen: SOFT_PHONE_LOG_MODAL,
                  churnOrDeliquencyId: "",
                })
              }
              sx={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                backgroundColor: "#02D130",
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.success.main,
                },
                ml: 2,
              }}
            >
              <CallIcon sx={{ width: "25px", height: "25px" }} />
            </IconButton>
          </Box>
          {/* call history UI */}
          <CallHistory />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <Box sx={{ ...bgStyle, overflowY: "auto", ml: 1 }}>
            <CustDetailUI />
          </Box>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default SoftPhoneUI;
