import React from 'react';
import FlowContainer from '../../common/container/core/FlowContainer';
import $ from 'jquery';	
import DatePicker from 'react-datetime';
import SearchCallLog from '../component/SearchCallLog';
import {getCallLogs} from '../../../actions/call_log_actions';
import {format_date_tbl, capitalize_first_letter, time_duration, page_count} from '../../../helpers/common_helper';
import {dd_value} from '../../../actions/common_actions';
import { Link} from 'react-router-dom';
import {get,get_item,check_priv, Config} from '../../../helpers/storage_helper';
import moment from 'moment';
import CommonReactTable from '../../../helpers/commonReact_table';
import withRouter from '../../../Router/Withrouter';
import { BasicReactTable } from '../../../helpers/react_table';


class CallLogContainer extends FlowContainer{
	constructor(props){
    super(props)
	this.state = {
		call_log : {'individual_logs' : false},
		logs:[],
		columns :[],
		log_table : false,
		purpose_field : false,
		paginate : Config('paginate') ? Config('paginate') : null,
		export_data:[],
		individual_logs : false,
		total : 0,
		loader : false,
		}	
	}

	componentWillMount(){
		const call_log = this.state.call_log
		if(check_priv("call_log","all_search")){
			const status="enabled";
			var priv_code =  "call_log/post"
			const role_code = get('role_codes');
			if(role_code == "customer_success"){
				var role_codes = ["customer_success","customer_success_officer","relationship_manager", "rm_support"]
				var priv_code = null
			}else if(role_code == "rm_support"){
				var role_codes = ["relationship_manager","rm_support"]
				var priv_code = null
			}else{
				var role_codes = null;
			}

			this.setOptionsListToStateFromAppApi("common","priv_users",this.req({priv_code,status,role_codes}), ['id' , 'name']);
		}else if(check_priv("call_log","self_search")){
			call_log.search_type = "self_search"
		}
		if(this.props.params.cust_id){
			call_log.call_end_time = moment(new Date())
			call_log.call_start_time = moment().subtract(1, 'months')
			call_log.individual_logs = true
			call_log.cust_call_history = true
			call_log.cust_id = this.props.params.cust_id
		}
		this.setState({call_log})


	}


	componentDidMount(){
		if(this.props.params.cust_id){
			var call_log = this.state.call_log
			call_log.call_start_time = call_log.call_start_time.format("YYYY-MM-DD")
			call_log.call_end_time = call_log.call_end_time.format("YYYY-MM-DD")
			this.handleSearch()
		}
	}

	/*handleOverdueSearch = () =>{
		var call_log = this.state.call_log
		var purpose_field = true
			call_log.call_end_time = moment(new Date())
			call_log.call_start_time = moment().subtract(7, 'days')	
			call_log.overdue_history = true
			call_log.call_type_select = true
			call_log.call_purpose = "overdue_follow-ups"
			call_log.call_type = "outgoing"
			this.setState({call_log,purpose_field})
			this.handleSearch()

		}
	*/
	handleSearch = (event)=>{
		this.setState({loader:true})
		if(event == 'search'){
			this.setState({log_table: false,logs : [], total : 0})
			if(this.state.paginate){
				this.setState({paginate: Config('paginate')})
			}
			
		}
		let request = JSON.parse(JSON.stringify(this.state.call_log))
		if(!request.call_start_time && !request.call_end_time){
			this.setState({loader : false})
			alert("Please enter valid search criteria")
		}else{
			if(this.state.call_log.individual_logs === true){
				request.paginate = this.state.paginate
			}else{
				request = this.removeKey('paginate')
			}
           
			if((request.call_type == "all") && (request.call_purpose)){
				 delete request.call_purpose;
			}

			getCallLogs(this.req(request))
				.then((response) => {
					this.resp(response)
				    if(!response){return this.setState({loader : false})};
		        	if(response.status === "success"){
		        		this.resp(response)
						if(this.state.paginate != null && response.data.individual_logs === true){								
                            this.setState(state=>state.total=response.data.total_counts)	
                            this.page_count()
                        }
                        else{
                            this.setState(state=>state.total=response.data.logs.length)
                        }
		        		var columns = this.state.columns
		        		if(response.data.individual_logs === true){
							this.setState({individual_logs : true})
		        			columns = [{  
											Header: 'Call Logger Name',  
											sortable : true,
											accessor: 'call_logger_name' ,
										},{  
											Header: 'Cust Name',  
											sortable : true,
											accessor: 'cust_name' ,
										},{  
											Header: 'Call Type',  
											id: 'call_type' ,
											sortable : true,
											accessor :row =>capitalize_first_letter(row.call_type)
										},{  
											Header: 'Cust ID',  
											accessor: 'cust_id' ,
											sortable : true,
											Cell: e =>  (
												<>
												  {e.value ? (
													e.value == 'unknown_customer' ? (
													  <span>Unknown Customer</span>
													) : (
													  <Link to={`/borrower/indiv/view/${e.value}`} target="_blank">
														{e.value}
													  </Link>
													)
												  ) : (
													'NA'
												  )}
												</>
											  ),
											width:175,
										},{  
											Header: 'Call Start Time',
											id: 'call_start_time', 
											sortable : true,
										accessor:row => row.call_start_time ? format_date_tbl(row.call_start_time,true)  : "-"
										},{  
											Header: 'Call Duration',
											id: "dur_secs",
											sortable : this.state.paginate ? false : true,
											accessor:row => time_duration(row.dur_secs),
											
										},
										{  
											Header: 'Purpose',  
											id: 'call_purpose',
											width: 250,
											style: { whiteSpace: 'unset' },
											accessor:row => (!row.call_purpose || row.call_purpose.length === 0) ? "-" : row.call_purpose&&row.call_purpose.map(purpose=>{
											let value = dd_value(purpose, row.call_type == "outgoing" ? "outgoing_call_purpose" : "incoming_call_purpose")
											let data = value?.slice(-21) == 'Customer Confirmation' ? value?.slice(0, -23) : value
											return data
											}).join(", ")

										},{  
											Header: 'Remarks',  
											accessor: row => <div className='text-wrap'>{row.remarks ? row.remarks : "-"}</div> ,
											sortable : true,
											width: 400,
											style: { whiteSpace: 'unset'},
										}]
						}
						if(response.data.individual_logs === false) {       
							this.setState({individual_logs : false})   					  	
			        		columns =   [
										{  
											Header: 'Call Logger Name',  
											accessor: 'call_logger_name' ,
											sortable : true,
										},{  
											Header: 'Log date',
											id: 'log', 
											sortable : true,
											accessor:row => format_date_tbl(row.log_date) ,
										},{  
											Header: 'Logs',  
											accessor: 'logs',
											sortable : true,
										}]
		        		}											     
						this.setState({log_table:true})

						this.setState((state) => {
							return { columns,logs  : response.data.logs};
						}, ()=>this.setState({loader:false}));
		        	}
		        					
				})
		}
	}
	removeKey = (key) => {
		const { [key]: deletedKey, ...updatedObject } = this.state.call_log;
		this.setState({ call_log: updatedObject });
		return updatedObject
	};

	handleCallChange =(event) =>{
	 	var call_log = this.state.call_log
	 	var purpose_field = this.state.purpose_field
	 	const value = event.target.id === 'individual_logs' ? event.target.checked : event.target.value;
		if(value === false){
			this.removeKey('paginate')
		}
 		call_log[event.target.id] = value
 		if(call_log.call_type =="all" || call_log.call_type =="" ){
 			purpose_field = false
 		}else if(call_log.call_type == "incoming" || call_log.call_type == "outgoing"){
 			purpose_field = true
 		}
		 if((event.target.id =="call_type") && value=="incoming" || value == "outgoing" ){
			call_log.call_purpose =""
		}
 		this.setState({call_log,log_table:false,logs:[],purpose_field})
	}

	pagination = (page, count, sorted)=>{
	
		this.setState((state) => {
			return {
			  paginate: {
				...state.paginate,
				pagination_count: count,
				pagination_page:page,
				sorting : sorted,
				total_count : this.state.total
			  },
			};
		  }, ()=>this.handleSearch());
	
	}

	page_count = () => {
		const total = this.state.total
		const page_count_mod = (total % this.state.paginate.pagination_count)

		if (page_count_mod > 0) {
			const page_count = (total / this.state.paginate.pagination_count)
			this.setState({ 'page_count': Math.trunc(page_count) + 1 }, () => console.log(this.state.page_count))

		} else {
			const page_count = (total / this.state.paginate.pagination_count)
			this.setState({ 'page_count': page_count })
		}
	}

	handleExportCSV = (event) => {
		var request = this.removeKey('paginate')
		let call_log = JSON.parse(JSON.stringify(request));
			let resp = null;
			if(event == 'email') {
				call_log.export = 'email'
				resp = window.confirm(`The current search exceeds ${Config("export_csv").limit} rows. So the exported data will be sent to your email shortly.`)
			}
			if(event == 'email' && !resp){
				return
			}else{
				this.handleCSVapi(call_log, event)
			}
	}

	handleCSVapi(call_log, action){
		let calling_api = true
		if (action == 'email') calling_api = false 
		getCallLogs(this.req(call_log, true, null, calling_api))
			.then((response) => {
				this.resp(response)
				if(action == 'email'){
					return
				}
				this.setState(state => state.export_data = response.data.logs)
			})
	}

	render(){
    	return(
    		<div className="container max-width containerTopmargin no-padding">
    			<div className="row no-margin">
    				<h5 className="field_visit headerTitle no-margin headerPadvert">
	    				{ this.state.call_log.cust_call_history ? "Search Call Log " + " ("+this.state.call_log.cust_id+")" 
	    					: "Search Call Log" }</h5>
	    		</div>
	    		<SearchCallLog  id="call_log" 
	    			onComponentChange={this.handleCallChange} 
	    			onComponentElementChange={this.handleCallChange}
	    			onSearch={this.handleSearch}
	    			search_btn = {this.state.calling_api}
	    			call_log = {this.state.call_log}
	    			purpose_field = {this.state.purpose_field}
	    			overdueSearch = {this.handleOverdueSearch}
	    			priv_users	  =	{this.state.priv_users_dd} 
	    		/>
				{this.state.log_table && !this.state.individual_logs && this.state.logs.length > 0 &&
					<div>		

						  <BasicReactTable row_data={this.state.logs !== undefined ? this.state.logs : []} column_data={this.state.columns} pagination={true } FName={"Call log"} export_csv_datas={this.state.logs ? this.state.logs : []}   default_page_size = {10} csv_file={true} searchData={[{...this.state.call_log,title:'Search Call Log'}]}/>  


					
					</div>
				}
				{this.state.log_table && this.state.individual_logs && this.state.logs.length > 0 &&

					<div>{
						<div style={{display:!this.state.loader ?'':'none'}}>
							{this.state.paginate == null ?
							  <BasicReactTable row_data={this.state.logs !== undefined ? this.state.logs : []} column_data={this.state.columns} pagination={true } FName={"Call log"} export_csv_datas={this.state.logs ? this.state.logs : []}   default_page_size = {10} csv_file={true} searchData={[{...this.state.call_log,title:'Search Call Log'}]}/>  

									
								:

								
									// <CommonReactTable showPag={true} minRow={0} row_data={this.state.logs !== undefined ? this.state.logs : []} column_data={this.state.columns} resizable={false} exportCSV={true} csvFile={"Call log"} csvData={this.state.export_data !== undefined ? this.state.export_data : []} searchData={[{...this.state.call_log,title:'Search Call Log'}]} filter_data = {this.pagination} pagination = {true} page_count = {this.state.page_count} defaultPageSize={10} handleExport = {this.handleExportCSV} csv_total_data = {this.state.total !== undefined ? this.state.total : 0}/>
								<BasicReactTable  row_data={this.state.logs !== undefined ? this.state.logs : []} column_data={this.state.columns}  server_side_pagination={this.state.paginate} csv_file={true} default_page_size={10} total_page_count={page_count(this.state.total, this.state.paginate.pagination_count)} handleExport={this.handleExportCSV} searchData={[{...this.state.call_log,title:'Search Call Log'}]} filter_data={this.pagination} FName={'Call log'} export_csv_datas={this.state.export_data !== undefined ? this.state.export_data : []} pagination={this.state.logs !== undefined && this.state.logs.length > 9 ? true : false} csv_total_data={this.state.total !== undefined ? this.state.total : 0}/>
							}
						</div> }
					</div>
				}

				{this.state.logs.length == 0 && this.state.log_table &&
				<p className='text-white text-center' style={{marginTop:"15%"}}>NO DATA FOUND</p>
				}
				<div style={{display:this.state.loader ?'':'none'}}>
							<div style={{marginTop:"15%"}} > <p className='text-light mt-3 text-center'>Loading...</p>
								<div className="product_takeup_loader"></div>
							</div>
						</div>
    		</div>
    	)
	}

}

export default withRouter (CallLogContainer);