import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useCallback, useState } from "react";
import { BRIDGED } from "../../../../consts";
import { useSoftPhoneCtx } from "../../../../context/softPhoneCtx/useSoftPhoneCtx";
import { FormatSecondsToHHMMSS } from "../../../../helpers/helper";
import {
  HeaderVarientH2Typo,
  HeaderVarientH3Typo,
} from "../../../CommonComponent/typography";
import { CustDetailProp } from "../../hooks/useGetCustDetailsQuery";
import SoftPhoneCallLogModal from "./softPhoneCallLogModal";

const CallLogModal = ({ timer }: { timer: number }) => {
  // hook
  const theme = useTheme();

  // state
  const [showSuccessModal, setshowSuccessModal] = useState(false);
  const [customerList, setcustomerList] = useState<CustDetailProp | undefined>(
    undefined
  );

  // ctx
  const {
    softPhoneState: {
      showCallLogModal,
      callState: { status },
    },
    methods: { closeCallLogModal, closeCallLogModalOnParticularWindow },
  } = useSoftPhoneCtx();

  // handler
  const closeModalHandler = useCallback(() => {
    closeCallLogModal();
    setshowSuccessModal(true);
  }, []);

  const closeLogSuccessModal = () => {
    setcustomerList(undefined);
    setshowSuccessModal(false);
  };

  // const
  const islgBelow = useMediaQuery(theme.breakpoints.down("lg"));
  const isCallConnected = status === BRIDGED;

  return (
    <>
      <Dialog
        sx={{
          "& .MuiPaper-root": {
            maxWidth: "none !important",
          },
        }}
        open={showCallLogModal}
        PaperProps={{
          style: {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "20px",
            width: islgBelow ? "90vw" : "60vw",
          },
        }}
      >
        <Box
          sx={{
            color: (theme) => theme.palette.text.primary,
            textAlign: "center",
            backgroundColor: "#01062C",
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <HeaderVarientH2Typo
              text="Call Log"
              overRideStyles={{ color: "#fff!important" }}
            />
            <Box display="flex" alignItems="center">
              {timer !== 0 && (
                <HeaderVarientH3Typo
                  text={isCallConnected ? FormatSecondsToHHMMSS(timer) : ""}
                  overRideStyles={{ color: "#fff" }}
                />
              )}
              <IconButton
                onClick={closeCallLogModalOnParticularWindow}
                sx={{ ml: 2 }}
              >
                <CloseIcon
                  sx={{ width: "30px", height: "30px", cursor: "pointer" }}
                />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <DialogContent
          sx={{
            textAlign: "center",
            minWidth: { xs: "auto", md: "430px" },
          }}
        >
          <SoftPhoneCallLogModal
            setcustomerList={setcustomerList}
            closeModalHandler={closeModalHandler}
          />
        </DialogContent>
      </Dialog>
      {/* log status show modal UI */}
      <Dialog
        open={showSuccessModal}
        PaperProps={{
          style: {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "20px",
          },
        }}
        onClose={closeLogSuccessModal}
      >
        <DialogTitle sx={{ color: "#fff" }}>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "#fff" }}>
            {`Your call log with customer ${
              customerList?.biz_name ?? "Unknown"
            } has been successfully logged`}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ m: "15px" }}>
          <Button
            onClick={closeLogSuccessModal}
            variant="contained"
            color="secondary"
            sx={{ minWidth: "75px" }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CallLogModal;
