import React, { useState, useEffect } from 'react';
import { getValueFromLangJson, req } from '../../../helpers/common_helper';
import { Box, Grid, useTheme } from '@mui/material';
import AuditKycCard from '../component/MobileNumberCards';
import { useParams } from 'react-router-dom';
import { viewLead } from '../../../actions/lead_actions';
import { useSelector } from 'react-redux';
import Softphone from '../../cs_management/container/cs_soft_phone';

const SectionHeader = ({ title, subtitle }) => (
  <div className="col-lg-12 pt-3 mt-5" style={{ background: '#01062c' }}>
    <h2 className="text-white text-center"><b>{title}</b></h2>
    {subtitle && (
      <h2 className="d-flex mt-1 text-center justify-content-center align-items-center">
        <img src="/img/pending_with_rm.png" height="50" alt="Pending" />
        <b className="ml-2 align-self-center" style={{ color: '#F3EA0B' }}>{subtitle}</b>
      </h2>
    )}
  </div>
);

const MobileNumberGrid = ({ numbers, section, handlers }) => (
  <Grid container spacing={0} className="m-0" justifyContent="center" >
    {numbers?.map((item, idx) => (
      <Grid item xs={12} sm={6} md={4} lg={3} key={idx} >
        <AuditKycCard
          biz_mob_num={{ ...item, section, index: idx }}
          {...handlers}
        />
      </Grid>
    ))}
  </Grid>
);

const AuditKycMobileNumberVerification = ({
  biz_mobilenumbers_data,
  account_owner_handler,
  file_upload_submit_handler,
  verify_handler,
  audit_kyc_modify_handler,
  audit_kyc_submit_callLog,
  callLogRejectHandler,
}) => {
  const { id } = useParams(); // Retrieve the URL parameter

  const [localBizMobileNumbersData, setLocalBizMobileNumbersData] = useState(biz_mobilenumbers_data);

  console.log('biz_mobilenumbers_data', biz_mobilenumbers_data)
  useEffect(() => {       
    localBizMobileNumbersData.cust_reg_json = biz_mobilenumbers_data.cust_reg_json

    localBizMobileNumbersData.timers = biz_mobilenumbers_data.timers
    localBizMobileNumbersData.ussd_code = biz_mobilenumbers_data.ussd_code
   

  }, [biz_mobilenumbers_data.timers, biz_mobilenumbers_data.cust_reg_json
 , biz_mobilenumbers_data.ussd_code ])
  // Function to fetch and update lead data
  const view_lead = () => {
    const lead_id = id;
    viewLead(req({ id: lead_id }))
      .then((response) => {
        if (response && response.status === 'success') {
          setLocalBizMobileNumbersData((prevData) => ({
            ...prevData,
            cust_reg_json: response.data.cust_reg_json,
          }));
        }
      })
      .catch((error) => {
        console.error("Failed to fetch lead data:", error);
      });
  };

  const rm_label_short = getValueFromLangJson("rm_label_short");
  const handlers = {
    numbers_verify: verify_handler,
    retrieval_api: account_owner_handler,
    audit_state: localBizMobileNumbersData,
    submit_holder_name_proof: file_upload_submit_handler,
    audit_kyc_modify_handler,
    audit_kyc_submit_callLog,
    callLogRejectHandler,
    cust_reg_json: localBizMobileNumbersData?.cust_reg_json,

    view_lead, // Passing the function to handlers
  };

  const pendingWith =
    localBizMobileNumbersData.active_section?.pending_with === 'rm'
      ? rm_label_short ?? ''
      : 'YOU';

  const title = `${localBizMobileNumbersData?.cust_reg_json?.same_as_owner_person ? '6' : '7'} - Mobile Number Verification`;

  const { lead_num_verify, outgoing_call_data } = useSelector((state) => state.cs_soft_phone);

  return (
    <div>
      <SectionHeader
        title={title}
        subtitle={`Pending with ${pendingWith}`}
      />
      <p className="m-0 pb-3 text-white text-center reassignment_Label">Business Mobile Numbers</p>
      <MobileNumberGrid
        numbers={localBizMobileNumbersData?.cust_reg_json?.mobile_numbers?.biz_mob_num}
        section="biz_mob_num"
        handlers={handlers}
      />
      <div className="col-lg-12 mt-3" style={{ background: '#01062c' }}>
        <p className="m-0 py-3 text-white text-center reassignment_Label">Additional Mobile Numbers</p>
      </div>
      <MobileNumberGrid
        numbers={localBizMobileNumbersData?.cust_reg_json?.mobile_numbers?.addl_num}
        section="addl_num"
        handlers={handlers}
      />


      {/* {
        lead_num_verify &&
        <Softphone screen_from={"lead_num_verify"}
          is_direct_outgoing={true}
          lead_id={id}
          outgoing_call_data={outgoing_call_data} />
      } */}
    </div>
  );
};

export default AuditKycMobileNumberVerification;