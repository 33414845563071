import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import RHFAutocomplete from '../../CommonComponent/rhfComponents/rhfAutoComplete';
import { RhfOptionsTypeAndKeyCheckProperties } from '../../../helpers/helper';
import { useForm } from 'react-hook-form';
import FormProvider from '../../CommonComponent/rhfComponents/formProvider';
import { dd } from '../../../actions/common_actions';
import { useParams } from 'react-router-dom';
import { req } from '../../../helpers/common_helper';
import { updateAccPrvdrCode } from '../../../actions/lead_actions';
const UpdateMobileOperator = ({ biz_mob_num, modal_close, view_lead }) => {
    const { id } = useParams();

    const cardHeaderText = biz_mob_num.section == "witness_num" ? "Witness Mobile Number" :
    
    biz_mob_num.section === "biz_mob_num"
        ? biz_mob_num.index === 0
            ? "Registered Mobile Number"
            : `Alternate Mobile Number ${biz_mob_num.index}`
        : `Additional Mobile Number ${biz_mob_num.index + 1}`;


    const methods = useForm(
        { defaultValues: { mobile_operator: { id: 'choose', name: "Choose", code: null } }, }
    );
    const { formState, watch, setValue, reset, handleSubmit, resetField } = methods

    const change_operator = () => {
        seteditOperator(true)
    }

    const mobile_operators = dd("mob_num_operator")
        .filter((i) => i.data_code !== biz_mob_num?.serv_prvdr?.value)
        .map((i) => ({
            id: i.id,
            name: i.data_value,
            code: i.data_code,
        }));

    const submit_operator = async () => {
    
        
        let type = biz_mob_num?.section + "_"+ biz_mob_num?.index
        let request={
        "category": type ??biz_mob_num?.category,
          "operator": watch('mobile_operator')?.code,
          "lead_id": id,
          "mobile_num":biz_mob_num?.mobile_num?.value }
    
        if (window.confirm("Are you sure to change the mobile operator?")) {
        updateAccPrvdrCode(req(request))
        .then((response)=>{
          if(!response){return}
          if(response.status=="success"){
            view_lead() 
            alert(response.message)
    
           
          }
        })}

        resetField('mobile_operator')
        modal_close()
    }


    return (
        <FormProvider methods={methods} >

            <Box>
                <Typography sx={{ textAlign: "center", color: "text.primary" }}>
                    {cardHeaderText} - {biz_mob_num?.mobile_num?.value}
                </Typography>

                {/* From Section */}
                <Grid container spacing={2} alignItems="center" my={2} justifyContent="space-around">
                    <Grid item xs={2}>
                        <Typography color="white" noWrap>
                            From
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Box
                            component="img"
                            src={`/img/${biz_mob_num?.serv_prvdr?.value}_logo_color.png`}
                            alt="Provider Logo"
                            sx={{ width: 60, height: 30, cursor: 'pointer' }}
                        />
                    </Grid>
                </Grid>

                {/* To Section */}
                <Grid container spacing={2} alignItems="center" justifyContent="space-around">
                    <Grid item xs={2}>
                        <Typography color="white" noWrap>
                            To
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <RHFAutocomplete
                            name="mobile_operator"
                            label="Choose Mobile Operator"
                            options={[{ id: 'choose', name: 'Choose', code: null }, ...mobile_operators]}
                            fullWidth
                            {...RhfOptionsTypeAndKeyCheckProperties}
                            disableClearable
                        />
                    </Grid>
                </Grid>

                {/* Action Buttons */}
                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 1 }}>
                    <Button variant="outlined" color="primary" size="small" sx={{ textTransform: "none", borderRadius: 2 }}
                        onClick={() => { modal_close() }}
                    >
                        Cancel
                    </Button>
                    <Button variant="contained" color="secondary" size="small" sx={{ textTransform: "none", borderRadius: 2 }}
                    type='submit'
                    onSubmit={submit_operator}
                        disabled={!watch('mobile_operator')?.code}
                        onClick={submit_operator}
                    >
                        Submit
                    </Button>
                </Box>
            </Box>
        </FormProvider>
    );
};

export default UpdateMobileOperator;