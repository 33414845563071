import { Link } from 'react-router-dom';
import { ExcesstimeAction, TooltipLabel } from '../../common/partial';
import { dd_value } from '../../../actions/common_actions';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FcInfo } from 'react-icons/fc';
import { check_priv } from '../../../helpers/storage_helper';
import { Homeacc_prvdrLogoselector, lbl_amt, title_case } from '../../../helpers/common_helper';



export const capture_fields = (column_fields =[],server_time) => {
    return column_fields.map((key) => ({
        field: key,
        headerName: dd_value(key, key), 
                align: "left",
        headerAlign: "left",
        resizable: true,
        width: 200,
        flex: 1,
        renderCell: (params) => {
            switch (key) {
                case "loan_doc_id":
                    return (
                        <div className="vertical_aligned">
                            <div className="column_line_height">
                                <Link className="d-block rms_date_picker" to={`/fa/view/${params.row.loan_doc_id}`} target="_blank">
                                    {params.row.loan_doc_id}
                                </Link>
                                <Link className="d-block rms_date_picker" to={`/borrower/indiv/view/${params.row.cust_id}`} target="_blank">
                                    {params.row.acc_number} {params.row.branch ? `(${title_case(params.row.branch)})` : ""}
                                </Link>
                            </div>
                        </div>
                    );
                case "acc_prvdr_code":
                    return (
                        <div className="d-flex">
                            <Homeacc_prvdrLogoselector logotype={params.row.acc_prvdr_code} />
                        </div>
                    );
                case "cust_mobile_num":
                    return (
                        <div className="vertical_aligned">
                            <div className="column_line_height">
                                <TooltipLabel
                                    className="d-block"
                                    full_text={params.row.biz_name}
                                    overlay={`${params.row.cust_name} / ${lbl_amt(params.row.loan_principal)}`}
                                    no_reduce
                                />
                                <label className="no-margin d-block">{params.row.cust_mobile_num}</label>
                            </div>
                        </div>
                    );
                case "loan_appl_date":
                    return (
                        <div className="vertical_aligned">
                            <div className="column_line_height">
                                <div>
                                    Applied: <ExcesstimeAction data={params.row} timedata={server_time} item="applied" />
                                </div>
                                <div>
                                    Approved: <ExcesstimeAction data={params.row} timedata={server_time} item="approved" />
                                </div>
                            </div>
                        </div>
                    );
                case "status":
                    return (
                        <div className="vertical_aligned">
                            <div className="column_line_height">
                                <div>{dd_value(params.row.status)}</div>
                                {params.row.disbursal_status && (
                                    <OverlayTrigger
                                        placement="auto"
                                        trigger={["hover", "focus"]}
                                        overlay={
                                            <Popover
                                                id={params.row.id}
                                                style={{
                                                    fontSize: "15px",
                                                    padding: "15px",
                                                    color: "mediumblue",
                                                    fontFamily: "Montserrat",
                                                }}
                                            >
                                                Float Transferred. Not Captured on App
                                            </Popover>
                                        }
                                    >
                                        <div>
                                            {title_case(params.row.disbursal_status || "not_initiated")} <sup><FcInfo size={14} /></sup>
                                        </div>
                                    </OverlayTrigger>
                                )}
                            </div>
                        </div>
                    );
                default:
                    return params.value ? dd_value(params.value):"NA"; 
            }
        },
    }));
};
