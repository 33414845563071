import { get_path } from '../helpers/common_helper';
import { get } from '../helpers/storage_helper';
import {call_fetch} from './../helpers/fetch_helper';

let clientName;
let user_id;
let token;
let time_zone = "EAT";

const getDetailsFromLocal = () => {
    let userName = get('user_name');
    if(userName) {
        clientName = userName.split(' ')[0]

    }

    user_id = get('user_id')
    token = get('access_token')

}

export const createClient = async (data) => {    

    const stream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true })
    let clientData = {
        username: "flow",
        clientName,
        phoneNumber: data.mobile_num,
        expire: "86400s"
    }

    let headers = {
        'apiKey': data.api_key,
        'Content-Type': 'application/json',

    }
    let root =  (process.env.REACT_APP_APP_API_ROOT).replace('/app', '')
 console.log('root', root)
    const response = await fetch(`${root}/proxy/proxy_fetch`, {

    // const response = await fetch("https://app.flowglobal.net/api/proxy/proxy_fetch", {
        method: "POST",
        headers,
        body: JSON.stringify({ ...clientData}),
    });

    return response.json()
}

export const getCsCallLogs = request => {
    getDetailsFromLocal();
    return call_fetch(get_path('app') + "/webrtc/call_log", {...request, user_id, time_zone,token})
}

export const csDetailsUpdate =  request => {
    getDetailsFromLocal();
    return call_fetch(get_path('app') + "/webrtc/update_cs_details", {...request, user_name:clientName, user_id, time_zone,token})
}

export const getCSDevice = request => {
    getDetailsFromLocal();
    return call_fetch(get_path('app') + "/webrtc/cs_device", {...request, user_id, time_zone, token})
}

export const getCustDetail = request => {
    getDetailsFromLocal();
    return call_fetch(get_path('app') + "/webrtc/cust_details", {...request, time_zone, token})
}

export const getWebrctConfigs = request => {
    getDetailsFromLocal();
    return call_fetch(get_path('app') + "/webrtc/webrct_configs", {...request, time_zone, token})
}

export const softphoneCallDuration = request => {
    getDetailsFromLocal();
    return call_fetch(get_path('admin') + "/call_log/get_soft_phone_call_duration", {...request, time_zone, token})
}

export const updateCallScreen = request => {
    getDetailsFromLocal();
    return call_fetch(get_path('admin') + "/call_log/update_call_screen", {...request, time_zone, token})
}

export const custDetails = request => {
    getDetailsFromLocal();
    return call_fetch(get_path('admin') + "/call_log/get_soft_phone_icon_data", {...request, time_zone, token})
}

export const process_call_log = request => {
    getDetailsFromLocal();
    return call_fetch(get_path('admin') + "/call_log/process_call_log", {...request, time_zone, token})
}

export const getPrevCallIntercations = request => {
    getDetailsFromLocal();
    return call_fetch(get_path('admin') + "/call_log/get_prev_call_intercations", {...request, time_zone, token})
}

export const get_uncompleted_call_log = request => {
    getDetailsFromLocal();
    return call_fetch(get_path('admin') + "/call_log/get_uncompleted_call_log", {...request, time_zone, token})
}

export const missedCallsList = request => {
    getDetailsFromLocal();
    return call_fetch(get_path('admin') + "/call_log/get_soft_phone_icon_data", {...request, time_zone, token})
}

export const completeMissedCall = request => {
    getDetailsFromLocal();
    return call_fetch(get_path('admin') + "/call_log/complete_missed_call", {...request, time_zone, token})

}

export const get_pending_call_logs = () => {
    return call_fetch(get_path('app') + "/webrtc/pending_call_logs", {user_id, time_zone, token})
}

export const getUserDetailsForSpamCheck = request => {
    return call_fetch(get_path('app') + "/webrtc/check_spam", {...request, time_zone, token})
}

