import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CHURN_ROUTE } from "../../../consts";
import { useSoftPhoneAndFreshChatCtx } from "../../../context/softPhoneAndFreshChatModalUICtx/useSoftPhoneAndFreshChatCtx";
import {
  CHURN_CALL_LOG_NEW_MODAL,
  CHURN_CALL_LOG_OLD_MODAL,
} from "../../../context/softPhoneCtx/softPhoneCtx";
import { useSoftPhoneCtx } from "../../../context/softPhoneCtx/useSoftPhoneCtx";
import ChaurnManagementContainer from "./chaurn_management";

const ChurnManagement = () => {
  // softphone ctx
  const { softPhoneState, methods: softPhoneMethods } = useSoftPhoneCtx();

  // float ui and fresh chat ctx
  const {
    softPhoneAndFreshChatFloatUIState,
    methods: softPhoneAndFreshChatMethods,
  } = useSoftPhoneAndFreshChatCtx();

  // hook
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // state
  const [isValidating, setisValidating] = useState(true);

  // const
  const churnType = searchParams.get("churn");
  const churnId = searchParams.get("churn_id");
  const custId = searchParams.get("cust_id");
  const isPendingCallLog = churnType && churnId ? true : false;

  // effect
  useEffect(() => {
    getUserCallLog();
  }, []);

  // cb
  const getUserCallLog = async () => {
    if (isPendingCallLog) {
      const { hasAnyPrevPendCallLog, callLog } =
        await softPhoneMethods.checkUserHasAnyPendingCallLog({ screen: "" });
      let isUserDoingAnyWrongAction = false;
      if (
        hasAnyPrevPendCallLog &&
        callLog?.screen === CHURN_CALL_LOG_NEW_MODAL &&
        churnType === "new" &&
        churnId === callLog?.churn_id
      ) {
        isUserDoingAnyWrongAction = false;
      } else if (
        hasAnyPrevPendCallLog &&
        callLog?.screen === CHURN_CALL_LOG_OLD_MODAL &&
        churnType === "past" &&
        churnId === callLog?.churn_id
      ) {
        isUserDoingAnyWrongAction = false;
      } else {
        isUserDoingAnyWrongAction = true;
      }
      if (isUserDoingAnyWrongAction) {
        navigateToChurnRoute();
      }
      setisValidating(false);
    }
    setisValidating(false);
  };

  const navigateToChurnRoute = () => navigate(CHURN_ROUTE);

  if (isValidating) return <></>;

  return (
    <ChaurnManagementContainer
      softPhoneCtxState={softPhoneState}
      softPhoneCtxMethods={softPhoneMethods}
      softPhoneAndFreshChatCtxFloatUIState={softPhoneAndFreshChatFloatUIState}
      softPhoneAndFreshChatCtxMethods={softPhoneAndFreshChatMethods}
      isPendingCallLog={isPendingCallLog}
      churnType={churnType}
      churnId={churnId}
      custId={custId}
      navigateToChurnRoute={navigateToChurnRoute}
    />
  );
};

export default ChurnManagement;
