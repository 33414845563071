import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material'
import { MuiOtpInput } from 'mui-one-time-password-input';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import CustomButton from '../../../MUI/Components/CustomButton';
import { userService } from '../../../actions/auth_actions';
import CustomSnackbar from '../../../MUI/Components/CustomSnackbar';
import { get } from '../../../helpers/storage_helper';
import { fa_appl_resend_otp } from '../../../actions/loan_appl_actions';
import { get_time_zone_str, getTimeDiffSince } from '../../../helpers/common_helper';

const  ApplyFAOtpMadal = (props) => {
    const [otp, setOtp] = useState('');
    const [seconds, setSeconds] = useState(600);
    const theme = useTheme();
    const market = get('market')
    const [invalidOtp, SetInvalidOtp] = useState(false);
    const [custData , setCustData] = useState({
        'otp_id' : props.data.otp_id,
        'mob_num' : props.data.mob_num
    })
    const [timer, setTimer] = useState(true);
    const [isDisable, setIsDisable] = useState(false)

    useEffect(() => {
        if (seconds <= 0) return;
        if(!timer) return;

        const time = setInterval(() => {
            setSeconds((prev) => prev - 1);
        }, 1000);

        return () => clearInterval(time);
    }, [seconds]);
    
    useEffect(() => {
        if(props.data.exp_at) {
            setTime(props.data.exp_at)
        }
        if(process.env.REACT_APP_UI_VERSION == "local" &&  props?.data?.otp_code){
            setOtp(props.data.otp_code)
            setIsDisable(false)
        }
    }, []);

    const formatTime = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const secs = totalSeconds % 60;
        return `${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
    };

    const setTime = (exp_at) => {

        let time_diff = getTimeDiffSince(exp_at);
        time_diff = time_diff < 0 ? 0 : time_diff;
        setSeconds(time_diff);
    }   

    const input_otp = {
        display: 'flex',
        justifyContent: 'center',
        '& .MuiOutlinedInput-root': {
            width: { xs: '40px', sm: '50px', md: '60px' },
            height: { xs: '40px', sm: '50px', md: '48px' },
            borderRadius: '5px !important',
            '& fieldset': {
                borderColor: invalidOtp  ? theme.palette.custom_colors.negative_600 : theme.palette.custom_colors.secondary_a_400,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.custom_colors.secondary_a_400,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.custom_colors.secondary_a_400,
                borderWidth: '2px',
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: invalidOtp ? theme.palette.custom_colors.negative_600 : theme.palette.custom_colors.secondary_a_400,
        }
    }

    const submit_btn = {
        mt: 7,
        backgroundColor: theme.palette.custom_colors.secondary_500,
        color: theme.palette.custom_colors.secondary_a_50,
        borderRadius: '5px',
        cursor: isDisable ? 'not-allowed' : 'pointer',
        opacity: isDisable ? 0.5 : 1
    }

    const handleChange = (newValue) => {
        
        setOtp(newValue)
        invalidOtp && SetInvalidOtp(false)
        if(newValue.length <6){
            setIsDisable(false)
        }
    }

    const handleSubmit = () =>{
        const otp_id = custData.otp_id 
        const mobile_num =  custData.mob_num
        const type = 'loan_appl'
        setIsDisable(true)
        userService.verify_login_otp(otp,otp_id,null,type,mobile_num)
        .then((response)=>{
            
            if(!response){ 
                setIsDisable(false)
                return;
            }
            if(response&& response?.status =="success" && !response.data.is_error){
                setIsDisable(false)
                props.onhide()
                props.show_sccess_msg()
            }
            if(response.data.is_error){
                SetInvalidOtp(true);
                setOtp('')
            }
        });
        
    }

    const handleResendOtp = () =>{

        setTimer(false);
        SetInvalidOtp(false)

        let request = {
            data : {
                "purpose" : "loan_appl",
                "cust_id": props.customer.cust_id,
                "loan_appl_id" : props.data.fa_appl_id,
                "loan_appl_doc_id" : props.data.loan_appl_doc_id,
                "cust_mobile_num" : props.data.mob_num,
                "cust_name" : props.customer.cust_name,
                "resend_otp" : true
            }
        }
     
        fa_appl_resend_otp({...request, ...market})
        .then((response)=>{
            
            if(!response){ return;}
            if(response?.status =="success"){
                setCustData({otp_id: response.data.otp_id, mob_num: response.data.mob_num})
                alert('The OTP has been resent successfully.')
                setTime(response.data.exp_at)
                setTimer(true);
            }
            
        });
        
    }

  return (
    <Modal className='otp_madal' show={props.show} onHide={()=>props.onhide()} backdrop="static" size="l" style={{top:"25px"}}>
        <Modal.Header closeButton className="p-0" >
            <Modal.Title className='p-3 text-start'>OTP Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Box>
                <Typography variant="m_inter_medium_regular_white" component={'div'} > 
                    Enter the OTP that has been sent to the customer’s mobile number {props.data.mob_num}.
                </Typography>
                <Box sx={{
                    margin: { xs: "20px 0px 20px 0px", sm: "25px 0px 20px 0px", md: "28px 0px 20px 0px" } // Adjusted for responsiveness
                }}>
                    <MuiOtpInput
                        sx={input_otp}
                        value={otp}
                        length={6}
                        onChange={handleChange}
                        className='default_outlined_variant'
                    />
                    { invalidOtp && 
                        <Typography variant="h5" component={'div'} sx={{ color: theme.palette.custom_colors.negative_600, margin: '10px 0px 10px 0px'}}> 
                        Incorrect OTP
                        </Typography>
                    }
                </Box>
                <Box 
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <Typography variant="m_inter_medium_regular_white"
                        component={'div'} 
                        onClick={()=>handleResendOtp()}
                        sx={{
                            color: seconds === 0 ? 'white' : 'grey',
                            cursor: seconds === 0 ? 'pointer' : 'not-allowed',
                            pointerEvents: seconds === 0 ? 'auto' : 'none',
                            userSelect: 'none',
                        }}
                     > 
                        Resend code
                    </Typography>
                    <Typography variant="m_inter_medium_regular_white" component={'div'} > 
                    {formatTime(seconds)}
                    </Typography>
                </Box>
            </Box>
            <Box 
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <CustomButton 
                    isLoading={false} 
                    variant = "small" 
                    component = 'button' 
                    text = {"Submit"} 
                    onClick = {() => handleSubmit()} 
                    style = {submit_btn}  
                    type = {"submit"} 
                    isDisabled = {isDisable} 
                />
            </Box>
            
        </Modal.Body>
    </Modal>
  )
}

export default ApplyFAOtpMadal