import { Typography, TypographyStyle } from "@mui/material";

/**
 *
 * @param param0 text
 * @param param1 override styling
 * @returns bolded text with applied styles h2 varient
 */
export const HeaderVarientH2Typo = ({
  text,
  overRideStyles,
}: {
  text: string;
  overRideStyles?: TypographyStyle;
}) => {
  return (
    <Typography
      variant="h2"
      sx={{
        fontSize: "1.5rem !important",
        ...overRideStyles,
      }}
    >
      {text}
    </Typography>
  );
};

/**
 *
 * @param param0 text
 * @param param1 override styling
 * @returns bolded text with applied styles h3 varient
 */
export const HeaderVarientH3Typo = ({
  text,
  overRideStyles,
}: {
  text: string;
  overRideStyles?: TypographyStyle;
}) => {
  return (
    <Typography
      variant="h3"
      sx={{
        fontSize: "1.375rem",
        ...overRideStyles,
      }}
    >
     {text}
    </Typography>
  );
};

/**
 *
 * @param param0 text
 * @param param1 override styling
 * @returns bolded text with applied styles h4 varient
 */
export const HeaderVarientH4Typo = ({
  text,
  overRideStyles,
}: {
  text: string;
  overRideStyles?: TypographyStyle;
}) => {
  return (
    <Typography
      variant="h4"
      sx={{
        fontSize: "1.125rem!important",
        ...overRideStyles,
      }}
    >
      {text}
    </Typography>
  );
};

/**
 *
 * @param param0 text
 * @param param1 override styling
 * @returns bolded text with applied styles h5 varient
 */
export const HeaderVarientH5Typo = ({
  text,
  overRideStyles,
}: {
  text: string;
  overRideStyles?: TypographyStyle;
}) => {
  return (
    <Typography
      variant="h5"
      sx={{
        fontSize: "1rem!important",
        ...overRideStyles,
      }}
    >
      {text}
    </Typography>
  );
};

/**
 *
 * @param param0 text
 * @param param1 override styling
 * @returns bolded text with applied styles in body varient 1
 */
export const BodyVarientOneTypo = ({
  text,
  overRideStyles,
}: {
  text: string;
  overRideStyles?: TypographyStyle;
}) => {
  return (
    <Typography
      variant="body2"
      sx={{
        fontSize: "1rem",
        ...overRideStyles,
      }}
    >
      {text}
    </Typography>
  );
};

/**
 *
 * @param param0 text
 * @param param1 override styling
 * @returns bolded text with applied styles in body varient 2
 */
export const BodyVarientTwoTypo = ({
  text,
  overRideStyles,
}: {
  text: string;
  overRideStyles?: TypographyStyle;
}) => {
  return (
    <Typography
      variant="body2"
      sx={{
        fontSize: "0.875rem",
        ...overRideStyles,
      }}
    >
      {text}
    </Typography>
  );
};

/**
 *
 * @param param0 text
 * @param param1 override styling
 * @returns bolded text with applied styles in subtitle1 varient
 */
export const SubTitleVarientOneTypo = ({
  text,
  overRideStyles,
}: {
  text: string;
  overRideStyles?: TypographyStyle;
}) => {
  return (
    <Typography
      variant="subtitle1"
      sx={{
        fontSize: "1.25rem",
        ...overRideStyles,
      }}
    >
      {text}
    </Typography>
  );
};

/**
 *
 * @param param0 text
 * @param param1 override styling
 * @returns bolded text with applied styles in subtitle2 varient
 */
export const SubTitleVarientTwoTypo = ({
  text,
  overRideStyles,
}: {
  text: string;
  overRideStyles?: TypographyStyle;
}) => {
  return (
    <Typography
      variant="subtitle2"
      sx={{
        fontSize: "1rem",
        ...overRideStyles,
      }}
    >
      {text}
    </Typography>
  );
};
