import { append_request } from "./common_helper";
import { check_priv, Config, get } from "./storage_helper";
import * as Yup from "yup";

interface RequestPayload {
  [key: string]: any; // Allow any key with any type of value
}

export const common_req = (
  request: RequestPayload | null = null,
  send_ap_code: boolean = true,
  key: any = null,
  calling_api: boolean = true
) => {
  return append_request({ ...request }, send_ap_code, key);
};

export const amount_format = (amount: string | number): string => {
  const nf = new Intl.NumberFormat();
  const currency = get("market").currency_code ?? "";

  if (amount) {
    return nf.format(Number(amount)) + " " + currency;
  } else {
    return "-";
  }
};

import moment, { Moment } from "moment";
import { REQUIRED } from "../consts";
import { RhfOptionType, RhfOptionTypeDataKeyOmited } from "../type";

export function format_date(
  date: Moment | string | null,
  incl_time: boolean = false,
  year_digits: 2 | 4 = 4,
  str_date_format: string = "YYYY-MM-DD h:mm a"
): string {
  let out_format = year_digits === 2 ? "DD MMM YY" : "DD MMM YYYY";

  if (incl_time) {
    out_format += " h:mm a";
  }

  if (moment.isMoment(date)) {
    return date.format(out_format);
  } else if (!date) {
    return "";
  } else {
    return moment(date, str_date_format).format(out_format);
  }
}

export const requiredRecordValidation = Yup.mixed<Record<string, string>>()
  .required(REQUIRED)
  .test(
    "not-default-value",
    REQUIRED,
    (value) => value && value.id !== "choose" && value.name !== "Choose"
  );

export const RhfOptionsTypeAndKeyCheckProperties = {
  getOptionLabel: (option: RhfOptionType | string) =>
    typeof option === "object" ? option.name : option,
  getOptionKey: (option: RhfOptionType | string) =>
    typeof option === "object" ? option.id : option,
  isOptionEqualToValue: (option: any, value: any) =>
    typeof option === "object" && typeof value === "object"
      ? option.id === value.id
      : option === value,
};

export const RhfOptionsDataKeyOmitedTypeAndKeyCheckProperties = {
  getOptionLabel: (option: RhfOptionTypeDataKeyOmited | string) =>
    typeof option === "object" ? option.name : option,
  getOptionKey: (option: RhfOptionTypeDataKeyOmited | string) =>
    typeof option === "object" ? option.actualId : option,
  isOptionEqualToValue: (option: any, value: any) =>
    typeof option === "object" && typeof value === "object"
      ? option.actualId === value.actualId // updated to use actualId
      : option === value,
};

export function handleProviderSelection(
  provider: string,
  selectedProviders: string[],
  acc_prvdrs: string[],
  rows: any[],
  updateSelectedProviders: (providers: string[]) => void,
  updateRows: (filteredRows: any[]) => void
) {
  const isSelectAll = provider === "All";

  let updatedProviders: string[];

  if (isSelectAll) {
    updatedProviders = selectedProviders.includes("All")
      ? []
      : ["All", ...acc_prvdrs];
  } else {
    if (selectedProviders.includes(provider)) {
      updatedProviders = selectedProviders.filter((item) => item !== provider);
    } else {
      updatedProviders = [
        ...selectedProviders.filter((item) => item !== "All"),
        provider,
      ];
    }

    if (
      updatedProviders.length === acc_prvdrs.length &&
      acc_prvdrs.every((prvdr) => updatedProviders.includes(prvdr))
    ) {
      updatedProviders = ["All", ...acc_prvdrs];
    } else {
      updatedProviders = updatedProviders.filter((item) => item !== "All");
    }
  }

  updateSelectedProviders(updatedProviders);
  updateRows(filter_rows(rows, updatedProviders));
}

export function filter_rows(rows: any[], updatedProviders: string[]): any[] {
  if (updatedProviders.includes("All")) {
    return rows;
  }

  if (updatedProviders.length === 0) {
    return [];
  }

  return rows.filter((row: any) =>
    updatedProviders.includes(row.acc_prvdr_code)
  );
}

export const FormatSecondsToHHMMSS = (seconds: number) => {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  return [hrs, mins, secs]
    .map((value) => String(value).padStart(2, "0"))
    .join(":");
};

export const checkCurentCountryHasSoftPhonePriv = () =>
  Config("softphone_supported_countries")?.includes(
    get("market")?.country_code
  );

/**
 *
 * @returns boolean,
 * the user should has the calling priv and the current logged in country should has the softphone priv
 */
export const checkUserHasCallPriv = () => {
  const currCountryHasCallPriv = checkCurentCountryHasSoftPhonePriv();
  return (
    get("role_codes") &&
    check_priv("home", "soft_phone") &&
    currCountryHasCallPriv
  );
};
