import { Box, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { HeaderVarientH3Typo } from '../../CommonComponent/typography';
import FormProvider from '../../CommonComponent/rhfComponents/formProvider';
import RhfDatePicker from '../../CommonComponent/rhfComponents/rhfDatePicker';
import { useForm } from 'react-hook-form';
import CommonReport from './CommonReport';
import { getReport } from '../../../actions/report_actions';
import moment from 'moment';
import { format_date_tbl, Homeacc_prvdrLogoselector, lbl_amt, req, set_id_for_rows } from '../../../helpers/common_helper';
import { GridColDef } from '@mui/x-data-grid';
import { dd_value } from '../../../actions/common_actions';
import { Link, useLocation } from 'react-router-dom';
interface ReportData {
    from_date?: string;
    to_date?: string;
}
const CustomerReports = () => {
    const [rows, setrows] = useState([])
    const [columns, setcolumns] = useState<GridColDef[]>([]);
    const [loader, setloader] = useState(false)
    const location = useLocation()

    useEffect(() => {
        get_report()
    }, [])

    const title = (location.pathname).replace(/^\/|\/$/g, '')
    const get_report = (datas: ReportData = {}) => {
        setloader(true)
        console.log('ReportData', datas)
        const { from_date, to_date } = datas || {};
        const start_date = from_date ? moment(from_date).format('YYYY-MM-DD') :
         moment().startOf('month').format('YYYY-MM-DD');
        const end_date = to_date ? moment(to_date).format('YYYY-MM-DD') :
         moment().format('YYYY-MM-DD');
        // @ts-ignore
        getReport(req({ report_type: title  , start_date, end_date }))
            .then((response) => {
                if (response?.status === 'success') {
                    console.log('response___>', response);
                    setrows(set_id_for_rows(response.data));
                    setcolumns(set_columns(response.data));
                    setloader(false)
                }
            });
    }
    
    const field_label = (title=="disbursement") ? "Disbursal" : title=="collection" ? "Payment" : "Registered"
    const date_field = [ 
        {key:'from_date' , label:`${field_label} Date From`} , 
        {key:'to_date' , label:`${field_label} Date To`} ]

    
        const getHeaderName = (key: string): string => {
            if (key.includes("_id")) {
                const updatedKey = key.replace("_id", " ID");
                // @ts-ignore
                return dd_value(updatedKey, updatedKey) || updatedKey; 
            } else {
                // @ts-ignore
                return dd_value(key, key) || key; 
            }
        };
    const set_columns = (records: Record<string, any>[] = []): GridColDef[] => {
        if (records.length === 0) {
          return []; 
        }
        const keys = Object.keys(records[0]);
        return keys.map((key) => ({
          field: key,
       
          headerName: getHeaderName(key) , 
          align: "left", 
          headerAlign: "left",
          resizable:true,
          width:200,
          renderCell: (params: any) => {
            if ( ['ongoing_loan_doc_id','customer_id','loan_id'].includes(key)) {
                return  <Link
                to={`${key == 'loan_id' ? '/fa/view/': `/borrower/indiv/view/`}${params.value}`}
                target="_blank"
                >
               {params.value}
              </Link> 
            }
            if(key.includes('date') || key =="agreement_valid_upto"){
                return (format_date_tbl(params.value, true)) 
            }
            if(['loan_principal','flow_fee','due_amount','amount','current_os_amount','paid_amount','paid_principal','paid_fee','current_fa_limit','first_fa_amount'].includes(key)){
                return (lbl_amt(params.value)) 
            }
            if(['biz_addr_prop_type','shop_type'].includes(key)){
                return (params.value ? dd_value(params.value) :'-') 
            }

            return params.value ? dd_value(params.value):params.value;
        }
        }));
      };
      

    return (
        <Box>
            <CommonReport
                get_report={get_report}
                rows={rows}
                columns={columns}
                is_loading ={loader}
                date_fields={date_field}
            />
        </Box>
    );
};

export default CustomerReports;