import FlowComponent from '../common/component/core/FlowComponent';
import React from 'react';
import {borrowerSearch} from '../../actions/borrower_actions';
import {
	format_date_tbl,
	capitalize_first_letter,
	get_time_diff,
	title_case,
	lbl_amt,
	Homeacc_prvdrLogoselector,
	person_name, format_date, getValueFromLangJson
} from '../../helpers/common_helper';
import { Link} from 'react-router-dom';

import {searchLoan, searchHeldLoan,unreversed_excess,unreversed_duplicate_disbursals, retryTransferAttempt, changeSwitchDisbursalStatus} from "../../actions/loan_actions";

import DisburseLoan from '../loan/component/DisburseLoan';
import moment from "moment";
import {searchLoanAppl} from "../../actions/loan_appl_actions";
import {ExcesstimeAction, TooltipLabel} from "../common/partial";
// import {FcRefresh} from "react-icons/all";
// import {GrRefresh} from "@react-icons/all-files/gr/GrRefresh";
import { each } from 'jquery';
import { listAccountProvider } from '../../actions/account_provider_actions';
import { Config, get, check_priv } from '../../helpers/storage_helper';
import CommonReactTable from '../../helpers/commonReact_table';
import { sortBizcommon,sortAmtcommon, sortTimecommon } from '../../helpers/sortingHelpers';
import CallLog from "../call_log/component/CallLog";
import {Button, Modal, OverlayTrigger, Popover} from "react-bootstrap";
import {FcInfo} from 'react-icons/fc';
import {StartCallLog} from "../../actions/call_log_actions";
import {dd_value} from "../../actions/common_actions";
import CaptureFailedDisbursal from '../loan/component/CaptureFailedDisbursal';
// import Accordion from 'react-bootstrap/Accordion';
import {Card} from 'react-bootstrap'
import {BsChevronDown} from 'react-icons/bs';
import { kycPendingLeads, updateAuditedby, viewLead } from '../../actions/lead_actions';
import Sms_modal from '../loan/container/Sms_modal';
import { listTaskRequests } from '../../actions/task_actions';
import { excess_reversal } from '../../actions/loan_actions';
import ViewTransactions from '../loan/component/ViewTransactions';
import { searchLead } from '../../actions/lead_actions';
import { pendingFloatSwitch } from '../../actions/loan_product_actions';
import { BasicReactTable } from '../../helpers/react_table';
import { connect } from 'react-redux';
import Softphone from '../cs_management/container/cs_soft_phone';
import PendingWithCustomer from './PendingCustomerConfirmation/PendingWithCustomer';
import { setValuesIntoRedux } from '../../Redux/Slices/CsSoftphoneSlice';
import HomeScreenTable from './HomeScreenTable';
import { v4 as uuidv4 } from 'uuid';
import { Accordion, AccordionDetails, AccordionSummary, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DataGridTable from '../CommonComponent/table';
import { GridToolbar } from '@mui/x-data-grid';
import CustomTableGridToolbar from '../CommonComponent/table/components/customTableGridToolbar';
import MUIAccordion from '../../MUI/Components/MUIAccordion';
import AccProviderFilter from '../common/component/AccProviderFilter';
import { handleProviderSelection } from '../../helpers/helper';
import PendingCustomerConfirmation from './PendingCustomerConfirmation';


import { DisbAtmptHistory } from '../CommonComponent/DisbAtmptHistory';
import { list_acc_prvdr } from '../../helpers/common_apicalls';

class DisplayList extends FlowComponent {

	state = {
		columns : [],
		lists : [],	
		copy: [],	
		refresh: false,							
		dyn_Btn_selected:[],
		all_count:0,				
		filter_actions:{},
		all_Btn_status:false,
		all_Acc_pvdrs:[],
		modal: false,
		sortVariable : "",
		defaultSortOrder : true,
		disb_manually: true,
		show_table: false,
		role_code : get('role_codes'),
		page: { page: 0, pageSize: 10 },
		selectedProviders: [],
        filteredRows: [],
		disb_atmpt_modal:false,
		txn_mode: '',
		doc_id: '',

	}

	handleToggle = (e) => {

        switch(e){
            case("Account"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
        }
    }
	 
	componentWillMount() {
		this.getListData();		
		this.fetch_acc_prvdr()
		const ls_market = get('market')
		var req = {country_code: ls_market.country_code, time_zone: ls_market.time_zone, status: ""};		
		// listAccountProvider({...req, status: 'enabled', biz_account: true})
		// .then((response)=>{					
		// 	if(!response){return };
		// 	if(response.status === "success"){
		// 		let acc_prvdrs = []    
				
		// 		console.log('response.data.list', response.data.list)        
		// 		response.data.list.map(each=>
		// 		acc_prvdrs.push(each.acc_prvdr_code)
		// 		)  
		// 		this.setState(state=>state.all_Acc_pvdrs = acc_prvdrs,()=>{			
					
		// 			this.getListData(acc_prvdrs);				          
		// 		})  
		// 	}
		// });
		if(this.props.list_name == "pending_w_cust"){
			this.props.setValuesIntoRedux({screen_from:"pending_w_cust"})

		}
    }

	filter_count=(list)=>{
		var count=[]
		const filter_each=(a)=>{
			let each=list.filter((item)=>item.acc_prvdr_code==a)
			count.push(each.length)}
		this.state.all_Acc_pvdrs.map((item)=>filter_each(item))
		this.setState({each_count:[...count]})
	}


	 fetch_acc_prvdr = async () => {
		const acc_prvdr_list = await list_acc_prvdr();
		const acc_prvdr_codes = acc_prvdr_list?.map((item) => item.acc_prvdr_code); 
		this.setState({acc_prvdrs: acc_prvdr_codes})
		 
		};

	getListData = (acc_prvdr_list = false) => {	

		const fa_rm_approval_late1 = Config("fa_rm_approval_late1")
		const fa_rm_approval_late2 = Config("fa_rm_approval_late2")
		const fa_late1 = Config("fa_late1")
		const fa_late2 = Config("fa_late2")
		const fa_cust_late1 = Config("fa_cust_late1")
		const fa_cust_late2 = Config("fa_cust_late2")
		const float_switch_late1 = Config("float_switch_late1")
		const float_switch_late2 = Config("float_switch_late2")
		
		this.setState({fa_rm_approval_late1,fa_rm_approval_late2,fa_late1,fa_late2,fa_cust_late1,fa_cust_late2,float_switch_late1,float_switch_late2})
		

		const market = get('market')
		const market_name = market.country_code			
		this.getListRows().then((lists) => {
			if(!lists){return}
			let columns = this.getListColumns()
			this.setState({lists,columns,refresh: false,})					
			this.setState(state=>state.all_count = lists.length)	
            if(acc_prvdr_list){
				let acc_prvdrs = this.state.selectedProviders.includes("All") ? this.state.acc_prvdrs :this.state.selectedProviders
				this.handle_acc_prvdr( "All",lists ,acc_prvdrs)	

			}
			// if(this.props.list_name === "pending_w_cust"){				
			// 	this.setState(state=>state.copy = [])
			// 	if(lists.length !== 0){																						
			// 		const newArr = get([market_name]+'_pendingCUS') === undefined ?  this.state.all_Acc_pvdrs : get([market_name]+'_pendingCUS') 																						
			// 		this.state.all_Acc_pvdrs.map(each=>{																				
			// 			const count = lists.filter(indiv => indiv.acc_prvdr_code === each)		
			// 			const length = count.length										
			// 			this.setState(state=>state.filter_actions[each] = [{count:0},{status:newArr.includes(each)}],()=>{
			// 				this.CUSdynClickhandler(this.state.filter_actions[each][1].status,each)

			// 			})
			// 			this.setState(state=>state.filter_actions[each][0].count = length)																
			// 		})
			// 	}
			// }
			// else if(this.props.list_name === "pending_w_rm"){
			// 	this.setState(state=>state.copy = [])				
			// 	if(lists.length !== 0){												
			// 		const newArr = get([market_name]+'_pendingRM') === undefined ?  this.state.all_Acc_pvdrs : get([market_name]+'_pendingRM')																					
			// 		this.state.all_Acc_pvdrs.map(each=>{
			// 			const count = lists.filter(indiv => indiv.acc_prvdr_code === each)		
			// 			const length = count.length										
			// 			this.setState(state=>state.filter_actions[each] = [{count:0},{status:newArr.includes(each)}],()=>{
			// 				this.RMdynClickhandler(this.state.filter_actions[each][1].status,each)							

			// 			})
			// 			this.setState(state=>state.filter_actions[each][0].count = length)						
			// 		})
			// 	}																																								
			// }
			// else if(this.props.list_name === "active_cust_without_fa"){
			// 	if(lists.length !== 0){
			// 		const newArr = get([market_name]+'_withoutFa') === undefined ?  this.state.all_Acc_pvdrs : get([market_name]+'_withoutFa')
			// 		this.state.all_Acc_pvdrs.map(each=>{
			// 			const count = lists.filter(indiv => indiv.acc_prvdr_code === each)
			// 			const length = count.length
			// 			this.setState(state=>state.filter_actions[each] = [{count:0},{status:newArr.includes(each)}])
			// 			this.CustWithOutFaClickhandler(this.state.filter_actions[each][1].status,each)
			// 			this.setState(state=>state.filter_actions[each][0].count = length)
			// 		})
			// 	}
			// }
			// else if(this.props.list_name === "pending_capture_disbursal"){
			// 	this.setState(state=>state.copy = [])
			// 	if(lists.length !== 0){
			// 		const newArr = get([market_name]+'_faPendingdisbcapture') === undefined ?  this.state.all_Acc_pvdrs : get([market_name]+'_faPendingdisbcapture')
			// 		this.state.all_Acc_pvdrs.map(each=>{
			// 			const count = lists.filter(indiv => indiv.acc_prvdr_code === each)
			// 			const length = count.length
			// 			this.setState(state=>state.filter_actions[each] = [{count:0},{status:newArr.includes(each)}], ()=>this.FAPendingCaptureClickhandler(this.state.filter_actions[each][1].status,each))
						
			// 			this.setState(state=>state.filter_actions[each][0].count = length)
			// 		})
			// 	}
			// }

			// else if(this.props.list_name === "unreversed_excess"){
			// 	if(lists.length !== 0){
			// 		const newArr = get([market_name]+'_unreversed_excess') === undefined ?  this.state.all_Acc_pvdrs : get([market_name]+'_unreversed_excess')
			// 		this.state.all_Acc_pvdrs.map(each=>{
			// 			const count = lists.filter(indiv => indiv.acc_prvdr_code === each)
			// 			const length = count.length
			// 			this.setState(state=>state.filter_actions[each] = [{count:0},{status:newArr.includes(each)}],()=>{
			// 				this.reversedexcessClickhandler(this.state.filter_actions[each][1].status,each)

			// 			})
			// 			this.setState(state=>state.filter_actions[each][0].count = length)
			// 		})
			// 	}
			// }

			// else if(this.props.list_name === "unreversed_duplicate_disbursals"){
			// 	if(lists.length !== 0){
			// 		const newArr = get([market_name]+'_unreversed_duplicate_disbursals') === undefined ?  this.state.all_Acc_pvdrs : get([market_name]+'_unreversed_duplicate_disbursals')
			// 		this.state.all_Acc_pvdrs.map(each=>{
			// 			const count = lists.filter(indiv => indiv.acc_prvdr_code === each)
			// 			const length = count.length
			// 			// this.setState(state=>state.filter_actions[each] = [{count:0},{status:newArr.includes(each)}])
			// 			// this.setState(state=>state.filter_actions[each][0].count = length)
			// 			// this.filter_click_handler('ALL')

			// 			this.setState(prevState => ({
			// 				filter_actions: {
			// 				  ...prevState.filter_actions,
			// 				  [each]: [
			// 					{ count: length },
			// 					{ status: newArr.includes(each) }
			// 				  ]
			// 				}
			// 			  }), ()=>this.filter_click_handler('ALL'));
			// 		})
			// 	}
			// }

			// else if(this.props.list_name === "kyc_in_progress"){
			// 	if(lists.length !== 0){
			// 		const newArr = get([market_name]+'_kyc_in_progress') === undefined ?  this.state.all_Acc_pvdrs : get([market_name]+'_kyc_in_progress')
			// 		this.state.all_Acc_pvdrs.map(each=>{
			// 			const count = lists.filter(indiv => indiv.acc_prvdr_code === each)
			// 			const length = count.length
			// 			this.setState(state=>state.filter_actions[each] = [{count:0},{status:newArr.includes(each)}])
			// 			this.setState(state=>state.filter_actions[each][0].count = length, ()=>this.filter_click_handler('ALL'))
						
						
			// 		})
			// 	}
			// }
			// else if(this.props.list_name === "kyc_in_pending_with_audit"){
			// 	if(lists.length !== 0){
			// 		const newArr = get([market_name]+'_kyc_in_pending_with_audit') === undefined ?  this.state.all_Acc_pvdrs : get([market_name]+'_kyc_in_pending_with_audit')
			// 		this.state.all_Acc_pvdrs.map(each=>{
			// 			const count = lists.filter(indiv => indiv.acc_prvdr_code === each)
			// 			const length = count.length
			// 			this.setState(state=>state.filter_actions[each] = [{count:length},{status:newArr.includes(each)}], ()=>this.filter_click_handler('ALL'))
			// 			// this.setState(state=>state.filter_actions[each][0].count = length)
						
					
			// 		})
			// 	}
			// }
			// else if(this.props.list_name === "kyc_assigned_for_audit"){
			// 	if(lists.length !== 0){
			// 		const newArr = get([market_name]+'_kyc_assigned_for_audit') === undefined ?  this.state.all_Acc_pvdrs : get([market_name]+'_kyc_assigned_for_audit')
			// 		this.state.all_Acc_pvdrs.map(each=>{
			// 			const count = lists.filter(indiv => indiv.acc_prvdr_code === each)
			// 			const length = count.length
			// 			this.setState(state=>state.filter_actions[each] = [{count:0},{status:newArr.includes(each)}])
			// 			this.setState(state=>state.filter_actions[each][0].count = length)
			// 			this.filter_click_handler('ALL',null,lists)
						
						
			// 		})
			// 	}
			// }
			// else if(this.props.list_name === "reassignment_pending_with_rm"){
			// 	if(lists.length !== 0){
			// 		const newArr = get([market_name]+'_reassignment_pending_with_rm') === undefined ?  this.state.all_Acc_pvdrs : get([market_name]+'_reassignment_pending_with_rm')
			// 		this.state.all_Acc_pvdrs.map(each=>{
			// 			const count = lists.filter(indiv => indiv.acc_prvdr_code === each)
			// 			const length = count.length
			// 			this.setState(state=>state.filter_actions[each] = [{count:0},{status:newArr.includes(each)}])
			// 			this.setState(state=>state.filter_actions[each][0].count = length, ()=>this.filter_click_handler('ALL',null,lists))
						
						
			// 		})
			// 	}
			// }else if(this.props.list_name === "pending_statement"){
			// 	if(lists.length !== 0){
			// 		const newArr = get([market_name]+'_pending_statement') === undefined ?  this.state.all_Acc_pvdrs : get([market_name]+'_pending_statement')
			// 		this.state.all_Acc_pvdrs.map(each=>{
			// 			const count = lists.filter(indiv => indiv.acc_prvdr_code === each)
			// 			const length = count.length
			// 			this.setState(state=>state.filter_actions[each] = [{count:0},{status:newArr.includes(each)}])
			// 			this.setState(state=>state.filter_actions[each][0].count = length)
			// 			this.pending_statement_click_handler('ALL')
						
			// 		})
			// 	}
			// }

			// else if(this.props.list_name === "cust_app_access"){
			// 	if(lists.length !== 0){
			// 		this.setState({copy:this.state.lists})
			// 	}
			// }
			// else if(this.props.list_name === "pending_float_switch"){
			// 	this.setState(state=>state.copy = [])
			// 	if(lists && lists.length > 0){
			// 		const newArr = get([market_name]+'_pending_float_switch') === undefined ?  this.state.all_Acc_pvdrs : get([market_name]+'_pending_float_switch')
			// 		this.state.all_Acc_pvdrs.map(each=>{
			// 			const count = lists.filter(indiv => indiv.acc_prvdr_code === each)
			// 			const length = count.length
			// 			this.setState(state=>state.filter_actions[each] = [{count:0},{status:newArr.includes(each)}],()=>{
			// 				console.log('first', this.state.filter_actions[each][1].status,each)
			// 				this.filter_click_handler(each)

			// 			})
			// 			this.setState(state=>state.filter_actions[each][0].count = length, ()=>this.filter_click_handler('ALL'))
						
			// 		})
			// 	}
			// }

			// if(this.props.is_open_accordion){
			// 	this.handleToggle("Account")
			// }

		})
	}

	set_id_for_rows = (records)=>{
		const data = records.map((row) => ({
			...row,
			id: uuidv4(), 
		})); 
		this.handle_acc_prvdr("All",data )
		return data

	}

	getListRows = async () => {
		let resp, data
		// await this.server_time(this.time_zone)		
		switch(this.props.list_name){
			case 'pending_w_cust':
				let loan_search_criteria = {pending_w_cust: 'true', mode : 'search', alert: false}
				resp = await searchLoan(this.req({loan_search_criteria, home_table : true}))
				this.setState(state => state.sortVariable = "loan_doc_id")
				if(resp && resp.status === 'success'){
					
					data = this.set_id_for_rows(resp.data.results) }							
				break;

			case 'pending_w_rm':
				let loan_appl_search_criteria = {pending_w_rm: 'true'}
      			resp = await searchLoanAppl(this.req({loan_appl_search_criteria, home_table : this.props.isrefresh}))
				  this.setState(state => state.sortVariable = "loan_doc_id")
				  if(resp && resp.status === 'success'){ data = this.set_id_for_rows(resp.data) }
  
				break;

			case 'active_cust_without_fa':
				let borrower_search_criteria = {active_cust : 'true', not_have_ongoing_loan : 'true', not_have_overdue_loan : 'true', not_have_pending_loan_appl : 'true', profile_status : 'open', status : 'enabled'}
				resp = await borrowerSearch(this.req({borrower_search_criteria}))
				this.setState(state => state.sortVariable = "row")
				this.setState(state=>state.defaultSortOrder = false)
				if(resp && resp.status === 'success'){ data =   this.set_id_for_rows(resp.data.results)}
				break;

			case 'unreversed_excess':

				// let borrower_search_criteria = {active_cust : 'true', not_have_ongoing_loan : 'true', not_have_overdue_loan : 'true', not_have_pending_loan_appl : 'true', profile_status : 'open', status : 'enabled'}
				resp = await unreversed_excess(this.req())
				this.setState(state => state.sortVariable = "row")
				this.setState(state=>state.defaultSortOrder = true)
				if(resp && resp.status === 'success'){ data = this.set_id_for_rows(resp.data ) }
				break;
			case 'unreversed_duplicate_disbursals':
				resp = await unreversed_duplicate_disbursals(this.req())
				this.setState(state => state.sortVariable = "row")
				this.setState(state=>state.defaultSortOrder = false)
				if(resp && resp.status === 'success'){data = this.set_id_for_rows(resp.data ) }
				break;
				
				case 'kyc_in_progress':
					var request={owner:'rm',
					is_reassigned:false}
					resp = await kycPendingLeads(this.req(request))
					this.setState(state => state.sortVariable = "row")
					this.setState(state=>state.defaultSortOrder = false)
					if(resp && resp.status === 'success'){data = this.set_id_for_rows(resp.data )}
					break;
				case 'kyc_in_pending_with_audit':
					var request={owner:'auditor',
					show_audited_leads:false}
					resp = await kycPendingLeads(this.req(request))
					this.setState(state => state.sortVariable = "row")
					this.setState(state=>state.defaultSortOrder = false)
					if(resp && resp.status === 'success'){data = this.set_id_for_rows(resp.data )}
					break;
				case 'kyc_assigned_for_audit':
					var request={owner:'auditor',
					show_audited_leads:true}
					resp = await kycPendingLeads(this.req(request))
					this.setState(state => state.sortVariable = "row")
					this.setState(state=>state.defaultSortOrder = false)
					if(resp && resp.status === 'success'){data = this.set_id_for_rows(resp.data )}
					break;	
				case 'reassignment_pending_with_rm':
					var request={owner:'rm',
					is_reassigned:true}
					resp = await kycPendingLeads(this.req(request))
					this.setState(state => state.sortVariable = "row")
					this.setState(state=>state.defaultSortOrder = false)
					if(resp && resp.status === 'success'){data = this.set_id_for_rows(resp.data )}
					break;
	
			case 'cust_app_access':
				var request={
					task_type:'cust_app_access',
				}
				resp = await listTaskRequests(this.req(request))
				this.setState(state => state.sortVariable = "row")
				this.setState(state=>state.defaultSortOrder = false)
				if(resp && resp.status === 'success'){ data = this.set_id_for_rows(resp.data ) }
				break;

			
			case 'pending_statement':
				var request={
					lead_search_criteria:{
						profile_status:'open',
						status:'23_pending_statement'
					}
				}
				resp = await searchLead(this.req(request))
				this.setState(state => state.sortVariable = "row")
				this.setState(state=>state.defaultSortOrder = false)
				if(resp && resp.status === 'success'){data = this.set_id_for_rows(resp.data )}
				break;
	
			case 'pending_capture_disbursal':
				let loan_pending_capture_search = {status : "pending_disb_capture", disburse_attempt: false, mode : 'search'};
				resp = await searchHeldLoan(this.req({loan_search_criteria: loan_pending_capture_search}))
				this.setState(state => state.sortVariable = "row")
				this.setState(state=>state.defaultSortOrder = false)
				if(resp && resp.status === 'success'){ data =  this.set_id_for_rows(resp.data.results  )}
				break;

			case 'pending_float_switch':
					var request={
						sales_search:{
							status:'pending_float_switch'
						}
					}
					resp = await pendingFloatSwitch(this.req(request))
					this.setState(state => state.sortVariable = "row")
					this.setState(state=>state.defaultSortOrder = false)
					if(resp && resp.status === 'success'){data = this.set_id_for_rows(resp.data.results  )}
					break;
		}

		if(resp && resp.status === 'success'){
			this.setState(state => state.server_time = resp.server_time)
			return data;
		}
	}
	
	pending_statement_filter_count=(list,event)=>{
		if(event){	event.stopPropagation()}

		var count=[]
		const filter_each=(a)=>{
			let each=list.filter((item)=>item.acc_prvdr_code==a)
			count.push(each.length)}
		this.state.all_Acc_pvdrs.map((item)=>filter_each(item))
		this.setState({each_count:[...count]})
	}
		
	automatic_excess_reversal = obj => { 
		const resp=window.confirm(`Are you sure you want to transfer excess amount to this customer's loan - ${obj.loan_doc_id}?`);
		if(resp){
			excess_reversal(this.req(obj))
			.then((response)=>{
				this.resp(response)
				if(!response){
					return
				}	
				if(response.status=='success'){
					alert(response.data);
				}
			});
			
		}
		else{
			return
		}
	}
	

    handleAuditKyc = (audited_by,lead_id) =>{
        
        if(audited_by == null && check_priv('lead','pending_kyc_list')){
           var resp = window.confirm("You are going to initiate the audit KYC process for this lead. Once you start, no other auditor can act on this lead.Are you sure?")
            if(resp){
                updateAuditedby(this.req({id: lead_id}))
                .then((response) => {
                    this.resp(response)
                    if(!response){return};
                    if(response.status === "success"){
						const url = "/lead/audit_kyc/" + lead_id;
						const newTab = window.open(url, "_blank");
						newTab.focus();
                    }
                })
            }
        }
        else if ( !check_priv('lead','pending_kyc_list')){
			window.alert(`You cannot take any action to this lead. However you can view the KYC record`)
			const url = "/lead/audit_kyc/" + lead_id;
			const newTab = window.open(url, "_blank");
			newTab.focus();
		}    
    }

	getListColumns = () => {
		const show_risk_category = check_priv('customer' , 'risk_category');
		switch(this.props.list_name){			
			case 'pending_w_cust':				
				return [
					{
						headerName : "A/C Number",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',


						width:240,
						id: 'acc_number',
						field:'acc_number',
						renderCell: params =>  <Link to={"/borrower/indiv/view/"+params.row.cust_id} target="_blank" >{params.row.acc_number}</Link>,

					},
					{
						headerName: 'Channel',
						id: 'channel',						
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						field:'channel',
						
						renderCell: params => {return <p className='text-light '>{params.row.channel?title_case(params.row.channel):''}</p>}
					},
					{
						headerName : "ACC PRVDR",
						field: 'acc_prvdr_code' ,
						maxWidth:85,
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <Homeacc_prvdrLogoselector logotype={params.row.acc_prvdr_code}/>
					},
					{
						headerName: 'FA ID',
						field: 'loan_doc_id',						
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell: params => <Link to={"fa/view/"+params.row.loan_doc_id} target="_blank" >{params.row.loan_doc_id}</Link>
					},
					// {
					// 	headerName : "Mobile Number",
					// 	headeralign: 'left', flex: 1,resizable: true, align: 'left',
					// 	id: 'cust_mobile_num',
					// 	renderCell: row => {return row.cust_mobile_num}
					// },
					{
						headerName : 'Biz Name & Mobile Number',
						field: 'biz_name',
						maxWidth:300,
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						sortMethod: (a,b) => this.sortBiz(a,b),
						renderCell: params => <div>
											<TooltipLabel full_text={params.row.biz_name} overlay={params.row.cust_name} no_reduce={true} />
											<label className='no-margin d-block'>{params.row.cust_mobile_num}</label> 
										</div>
					},{
						headerName : 'FA Amount',
						field: 'loan_principal',	
						headeralign: 'left', flex: 1,resizable: true, align: 'left',						
						sortMethod : (a,b) => this.sortAmount(a,b),	        			
						renderCell: params => <div className='tableAmount_rightAlign' style={{paddingRight:15}}>{lbl_amt(params.row.loan_principal,this.currency_code)}</div>
					},
					{
						headerName : "Applied & Approved",									
						field: 'loan_appl_date',
						width:210,
						
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						sortMethod: (a,b) => this.sortTime(a,b),
						renderCell:params => <div>
											<span>Applied : <ExcesstimeAction data={params.row} timedata={this.state.server_time} item={"applied"} time_limit={[this.state.fa_late1,this.state.fa_late2]}/></span><br/>
											<span>Approved : <ExcesstimeAction data={params.row} timedata={this.state.server_time} item={"approved"} time_limit={[this.state.fa_cust_late1,this.state.fa_cust_late2]}/></span>
										</div> 
					},
					// {
					// 	headerName : "RM Approved",	
					// 	id:'loan_approved_date',
					// 	width: 120,
					// 	headeralign: 'left', flex: 1,resizable: true, align: 'left',											
					// 	renderCell:row => <ExcesstimeAction data={row} timedata={this.state.server_time.server_time_db} item={"approved"} time_limit={[this.state.fa_cust_late1,this.state.fa_cust_late2]}/>
					// },
					// {
					// 	headerName : "No. of Conf. Codes Sent",
					// 	renderCell: 'otp_count',
					// 	headeralign: 'left', flex: 1,resizable: true, align: 'left',
					// },
					
					{
						headerName : 'Next Action',
						field: 'action',
						width: 190,
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell: params => <div>
							            <div className='text-left'><Button className="btn-std" onClick={() => { this.call_to_customer(params.row)}} >Get Phone Confirmation </Button></div> 

							 {/* <ExcesstimeAction data={cell.row.original} timedata={this.state.server_time} item={"next_action_conf"} action={this.handleOpen} time_limit={[this.state.fa_late1,this.state.fa_late2]}/> */}
							 </div>
					},
					{
						headerName: 'SMS Logs',
						field: 'sms_logs',
						headeralign: 'left', flex: 1,resizable: true, align: 'left',						
						renderCell: params => <Sms_modal btn_style={'btn-sm'} view={'home'} loan_doc_id={params.row.loan_doc_id}/>
					},]
			case 'pending_w_rm':							
				return [
					{
						headerName : getValueFromLangJson('ac_number'),
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						field: 'acc_number',
						renderCell: params =>  <Link to={"/borrower/indiv/view/"+params.row.cust_id} target="_blank" >{params.row.acc_number}</Link>,
					},
					{
						headerName: getValueFromLangJson('channel'),
						field: 'channel',						
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell: params => {return<p className='text-light text-uppercase'>{params.row.channel?title_case(params.row.channel):''}</p>}
					},
					{
						headerName : getValueFromLangJson("acc_prvdr"),
						field: 'acc_prvdr_code' ,
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						width:100,
						renderCell:params => <Homeacc_prvdrLogoselector logotype={params.row.acc_prvdr_code}/>
					},
					{
						headerName: getValueFromLangJson('fa_applcation_id'),
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						field: 'loan_appl_doc_id',headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell: params => <Link to={"/fa_appl/view/"+params.row.loan_appl_doc_id} >{params.row.loan_appl_doc_id}</Link>
					},
					{
						headerName : getValueFromLangJson('mobile_number'),
						headeralign: 'left', flex: 1,resizable: true, align: 'left',		
						field: 'cust_mobile_num',
						renderCell: params => {return params.row.cust_mobile_num}
					},
					{
						headerName : getValueFromLangJson("bz_name"),
						sortMethod: (a,b) => this.sortBiz(a,b),
						field: 'biz_name',
						headeralign: 'left', flex: 1,resizable: true, align: 'left',		
						renderCell: params => <TooltipLabel full_text={params.row.biz_name} overlay={params.row.cust_name} no_reduce={true} />
					},
					
					{
						headerName : getValueFromLangJson("fa_amount"),							
						field: 'loan_principal',
						headeralign: 'left', flex: 1,resizable: true, align: 'left',		

						sortMethod : (a,b) => this.sortAmount(a,b),	
						renderCell: params => <div className='tableAmount_rightAlign' style={{paddingRight:15}}>{lbl_amt(params.row.loan_principal,this.currency_code)}</div>
					},
					{
						headerName : getValueFromLangJson("applied"),
						sortMethod: (a,b) => this.sortTime(a,b),
						field: 'loan_appl_date',	
						headeralign: 'left', flex: 1,resizable: true, align: 'left',		
	        			
						renderCell:params => <ExcesstimeAction data={params.row} timedata={this.state.server_time} item={"applied"} time_limit={[this.state.fa_rm_approval_late1,this.state.fa_rm_approval_late2]}/>
					},
					
					{
						headerName : getValueFromLangJson("next_action"),
						field: 'loan_approver_name' ,							
						headeralign: 'left', flex: 1,resizable: true, align: 'left',		

						renderCell: params => <ExcesstimeAction data={params.row} timedata={this.state.server_time} item={"next_action"} time_limit={[this.state.fa_rm_approval_late1,this.state.fa_rm_approval_late2]}/>
					},
					{
						headerName : getValueFromLangJson('calls_made'),
						field: 'tot_calls_made' ,							
						headeralign: 'left', flex: 1,resizable: true, align: 'left',		
						renderCell: params => {return params.row.tot_calls_made}
					}
				]												

			case 'active_cust_without_fa':
				return [
					{
						headerName : "A/C Number",
						field: "acc_number",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => {return params.row.acc_number}
					},
					{
						headerName : "ACC PRVDR",
						field: "acc_prvdr_code",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <Homeacc_prvdrLogoselector logotype={params.row.acc_prvdr_code} />
					},
					{
						headerName : " Cust ID",
						field: "cust_id",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <Link to={"/borrower/indiv/view/"+params.row.cust_id} target="_blank" className="flow_custid" >{params.row.cust_id}</Link>
					},
					{
						headerName : "Category",
						field: "category",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',		

						renderCell:params => {return params.row.category}
					},
					{
						headerName : "Business Name",
						field: "biz_name",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',		
						renderCell:params => <TooltipLabel full_text={params.row.biz_name} overlay={person_name(params.row)} />
					},
					{
						headerName : ` ${getValueFromLangJson("rm_label_short")} Name`,
						field: "flow_rel_mgr_name",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',		
						renderCell:params => {return params.row.flow_rel_mgr_name}
					},
					{
						headerName : `Reg  ${getValueFromLangJson("rm_label_short")}  Name`,
						field: "reg_flow_rel_mgr_name",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => {return params.row.reg_flow_rel_mgr_name}
					},
					{
						headerName : "Total FAs",
						field: "tot_loans",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',

						renderCell:params => {return params.row.tot_loans ? params.row.tot_loans : 0}
					},
					{
						headerName : "Reg Date",
						field: "reg_date",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => {return params.row.reg_date ?
							<span>{format_date(params.row.reg_date)}</span> : "NA"}
					},
					{
						headerName : "Last Visit",
						field: "last_visit_date",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => {return params.row.last_visit_date ?
							<span>{format_date(params.row.last_visit_date)}</span> : "NA"}
					},
					{
						headerName : "Last FA",
						field: "last_loan_date",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => {return params.row.last_loan_date ?
							<span>{format_date(params.row.last_loan_date)}</span> : "NA"}
					},
					{
						headerName : "Cond Count",
						field: "cond_count",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',

						renderCell:params => {return params.row.cond_count}
					},
					{
						headerName : "Territory",
						field: "territory",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',

						renderCell:params => {return dd_value(params.row.territory)}
					},
					{
						headerName : "Location",
						field: "location",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',

						renderCell:params => {return dd_value(params.row.location)}
					},
					{
						headerName : "Profile Status",
						field: "profile_status",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',

						renderCell:params => {return dd_value(params.row.profile_status)}
					},
					{
						headerName : "Risk Category",
						field: "risk_category",
						show:show_risk_category,
						headeralign: 'left', flex: 1,resizable: true, align: 'left',

						renderCell:params => {return show_risk_category && dd_value(params.row.risk_category)}
					},
		]
			case 'cust_app_access':
				return [
					{
						headerName : ` ${getValueFromLangJson("rm_label_short")}  Name`,
						field: "visitor_name",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => capitalize_first_letter(params.row.approval_json[0].approver_name) 
					},
					{
						headerName : "Customer Name",
						field: "cust_name",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className='d-flex align-items-center'><p className='no-margin' > {params.row.cust_name}</p>  </div> 
					},
					{
						headerName : "Cust ID",
						field: "cust_id",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className='d-flex align-items-center'><p className='no-margin' > {params.row.cust_id}</p>  </div> 
					},
					{
						headerName : "Requested Date",
						field: "created_at",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className='d-flex align-items-center'><p className='no-margin' > {moment(params.row.created_at).format('DD-MMM-YYYY')}</p>  </div> 
					},
				]
		case 'pending_statement':
				return [
					{
						headerName : "Account Provider & Account Number",
						field: "account_num",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className='d-flex align-items-center text-left  pl-1 m-0'><p className=' pr-0 pl-1 m-0'><Homeacc_prvdrLogoselector logotype={params.row.acc_prvdr_code} /></p><p className='no-margin  pr-0 pl-2 m-0' > {params.row.account_num}</p> </div> 
					},{
						headerName : "Business Name & Mobile Number",
						field: "biz_name",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className=' column_line_height d-flex row p-0 m-0 align-items-center'><p className='no-margin col-12 p-0' > {capitalize_first_letter(params.row.biz_name)}</p><p className='no-margin p-0 col-12' > {params.row.mobile_num}</p>  </div> 
					},{
						headerName : ` ${getValueFromLangJson("rm_label_short")}  Name`,
						field: "flow_rel_mgr_name",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params =>  capitalize_first_letter(params.row.flow_rel_mgr_name)
					},{
						headerName : "Status",
						field: "status",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className='d-flex align-items-center'><p className='no-margin text-capitalize' > {params.row.status.split('_').join(' ')}</p>  </div> 
					},
					{
						headerName : "Lead Created by",
						field: "sales_rep_id",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => params.row.sales_rep_id,
						Cell: (cell) => {
							return <>{(cell.value?'Sales Rep':` ${getValueFromLangJson("rm_label_short")} `)}</>;
						}, 
					},
					{
						headerName : "Action",
						field: "action",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <Link to={"/lead/edit/"+params.row.id} target='_blank' className='btn product_takeup_submit' style={{color:'white'}}>View Lead</Link>
					}
				]
			
					
		
			// case 'unreversed_excess':
			// 	return [
			// 		{
			// 			headerName : "Channel",
			// 			id: "channel",  
			// 			headeralign: 'left', flex: 1,resizable: true, align: 'left',
			// 			maxWidth:85,               
			// 			renderCell:row => {return <p className='text-light text-uppercase '>{row.channel?title_case(row.channel):''}</p>}
			// 		},
			// 		{
			// 			headerName : <div style={{paddingLeft:'20%'}}>SI No.</div>,
			// 			id: "row",
			// 			maxWidth:'100',
			// 			Cell: (row) => {
			// 				return <div  style={{paddingLeft:'20%'}}>{row.index+1}</div>;
			// 			}
			// 		},
			// 		{
			// 			headerName : "Account Number",
			// 			id: "acc_prvdr_code",
			// 			headeralign: 'left', flex: 1,resizable: true, align: 'left',
			// 			renderCell:row => <div className='d-flex align-items-center'> <Homeacc_prvdrLogoselector logotype={row.acc_prvdr_code}/> <p className='no-margin' style={{paddingLeft:'20px'}}> {row.acc_number}</p>  </div> 
			// 		},
			// 		// {
			// 		// 	headerName : "FLOW Cust ID",
			// 		// 	id: "cust_id",
			// 		// 	headeralign: 'left', flex: 1,resizable: true, align: 'left',
			// 		// 	renderCell:row => <Link to={"/borrower/indiv/view/"+row.cust_id} target="_blank" className="flow_custid" >{row.cust_id}</Link>
			// 		// },
			// 		{
			// 			headerName : "FA ID",
			// 			id: "loan_doc_id",
			// 			headeralign: 'left', flex: 1,resizable: true, align: 'left',
			// 			renderCell:row =>  <Link to={"/fa/view/"+row.loan_doc_id} target="_blank" className="flow_custid" >{row.loan_doc_id}</Link>
			// 		},
			// 		{
			// 			headerName :<div className='text-right' style={{width:'100px'}}>Paid Excess Amount</div> ,
			// 			id: "paid_excess",
			// 			sortMethod: (a,b) => this.sortAmount(a,b),

			// 			renderCell:row => { return <div  className = "text-right" style={{width:'100px'}}>{lbl_amt(row.paid_excess,this.currency_code)}</div>}
			// 		},

			// 		{
			// 			headerName : "Paid Date",
			// 			id: "paid_date",
			// 			sortMethod: (a,b) => this.sortTime(a,b),
			// 			renderCell:row => {return <div className='text-white'>{moment(row.paid_date).format("DD MMM YYYY")}</div>}
			// 		},
			// 		{
			// 			headerName : "Customer Name",
			// 			id: "cust_name",
			// 			headeralign: 'left', flex: 1,resizable: true, align: 'left',
			// 			renderCell:row => {return row.cust_name}
			// 		},
					
					
			// 		{
			// 			headerName : <div className=''>Business Name</div> ,
			// 			id: "biz_name",
			// 			width:250,
			// 			maxWidth:250, 
			// 			// sortMethod : (a,b) => this.sortBiz(a,b),
			// 			renderCell:row => <div className='w-100' >{row.biz_name}</div> 
			// 		},
			// ]
			case 'unreversed_duplicate_disbursals':
				return [
					{
						headerName :" ACC PRVDR",
						field: "acc_prvdr_code",   
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
            
						renderCell:params => <div className='d-flex align-items-center'> <Homeacc_prvdrLogoselector logotype={params.row.acc_prvdr_code}/> <p className='no-margin' style={{paddingLeft:'20px'}}> {params.row.acc_prvdr_num}</p>  </div>
					},
					{
						headerName : "FA ID",
						field: 'loan_doc_id',
						headeralign: 'left', flex: 1,resizable: true, align: 'left',

						renderCell: params => <div>
											<Link  to={"/fa/view/"+params.row.loan_doc_id} target="_blank">{params.row.loan_doc_id}</Link>
										 </div>
					},
					{
						headerName : "A/C Number",
						width:100,
						headeralign: 'left', flex: 1,resizable: true, align: 'left',

						field: 'acc_number',
						renderCell: params => <div>
											<Link to={"/borrower/indiv/view/"+params.row.acc_number} target="_blank">{params.row.acc_number}</Link>

										 </div>
					},
					{
						headerName : "	Biz Name & Mobile Number",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',

						field: 'cust_mobile_num',
						renderCell: params => <div>
											<p>	{params.row.biz_name}<label className='no-margin d-block'>{params.row.cust_mobile_num}</label>  </p>                                                                      
										 </div> 
					},
					{
						headerName :"Disbursed Amount" ,
						field: "disbursed_amount",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',

						renderCell:params => { return <div  className = "text-right" style={{width:'100px'}}>{lbl_amt(params.row.amount,this.currency_code)}</div>}
					},
					{
						headerName : "Reversed Amount" ,
						field: "paid_amount",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',

						renderCell:params => { return <div  className = "text-right" style={{width:'100px'}}>{lbl_amt(params.row.paid_amount,this.currency_code)}</div>}
					},
					{
						headerName : "Applied & Approved",
						field: 'loan_appl_date',
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						width:240,
						renderCell: params => <div className='column_line_height'>
											<span >Applied :{params.row.applied_name?params.row.applied_name:"Null"} </span><br/>
											<span >Approved :{params.row.loan_approver_name} </span>
										 </div>
					},
					{
						headerName : "Channel",
						field: "channel",  
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => {return <p className='text-light text-uppercase '>{params.row.channel?title_case(params.row.channel):"NUll"}</p>}
					}
				]

			case 'kyc_in_progress':
				return [
					{
						headerName : "Account Provider &  Account Number ",
						field: "account_num",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className='d-flex align-items-center'>
						<div>
						<img className="img-fluid" style={{maxWidth:'60px'}} src={`/img/${params.row.acc_prvdr_code}_logo_color.png`} alt={params.row.acc_prvdr_code}/>			
						</div>
						<p className='my-0 mx-2' > {params.row.account_num}</p>
						</div> 
					},{
						headerName : "Business Name",
						field: "biz_name",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						width:150,
						maxWidth:200,
						renderCell:params => <div className='d-flex row p-0 m-0 align-items-center column_line_height'><p className='no-margin col-12 p-0' > {capitalize_first_letter(params.row.biz_name)}</p><p className='no-margin p-0 col-12' > {params.row.mobile_num}</p>  </div> 
					},{
						headerName : ` ${getValueFromLangJson("rm_label_short")}  Name`,
						field: "rm_name",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params =>capitalize_first_letter(params.row.rm_name)
					},{
						headerName : "Start Time",
						field: "start_time",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className='d-flex align-items-center '>{moment(params.row.start_time).format('DD-MMM-YYYY HH:mm')} </div> 
					},{
						headerName : "Location",
						field: "location",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className='d-flex align-items-center'><p className='no-margin' > {capitalize_first_letter(dd_value(params.row.location))}</p>  </div> 
					},
					{
						headerName : "Lead Created by",
						field: "sales_rep_id",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell: (params) => {
							return <>{(params.row.value?'Sales Rep':` ${getValueFromLangJson("rm_label_short")} `)}</>;
						}, 
					},{
						headerName :'Action' ,
						field: "lead_id",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className='d-flex align-items-center'>
						<div>
						<a type='button' className='btn view_btn_kyc text-light font' href={`/lead/edit/${params.row.lead_id}`} target='_blank'>View Lead</a>	
						</div>
						
						</div> 
						
						
						
					



					},
				]
			case 'kyc_in_pending_with_audit':
				return [
					{
						headerName : "Account Provider & Account Number ",
						field: "account_num",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => 
						<div className='d-flex align-items-center'>
						<div>
						<img className="img-fluid" style={{maxWidth:'60px'}} src={`/img/${params.row.acc_prvdr_code}_logo_color.png`} alt={params.row.acc_prvdr_code}/>			
						</div>
						<p className='my-0 mx-2' > {params.row.account_num}</p>
						</div> 
					},{
						headerName : "Business Name",
						field: "biz_name",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						width:200,

						renderCell: (params) => (
						<div className="d-flex row p-0 m-0 align-items-center">
							<p className="no-margin col-12 p-0">
							{params.row.biz_name
								? capitalize_first_letter(params.row.biz_name)
								: params.row?.first_name 
								? `${params.row.first_name} ${params.row?.last_name}`
								:  "NA"}
							</p>
						</div>
						)

					},{
						headerName : ` ${getValueFromLangJson("rm_label_short")}  Name`,
						field: "rm_name",
						width:200,
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => capitalize_first_letter(params.row.rm_name)
					},{
						headerName : "Status",
						field: "status",
						width:200,
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className='d-flex align-items-center'><p className='no-margin' > {params.row.status=='50_pending_audit'?'Pending For Audit':''}</p>  </div> 
					},{
						headerName : "Location",
						field: "location",						
						width:200,

						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className='d-flex align-items-center'><p className='no-margin' > {capitalize_first_letter(dd_value(params.row.location))}</p>  </div> 
					},
					{
						headerName : "Lead Created by",
						field: "sales_rep_id",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell: (cell) => {
							return <>{(cell.row.value?'Sales Rep':`${getValueFromLangJson("rm_label_short")}`)}</>;
						}, 
					},{
						headerName : "Action",
						field: "view_lead",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params =>  <div className='d-flex align-items-center'>
						<div>
						<a type='button' className='btn view_btn_kyc text-light font' href={`/lead/edit/${params.row.lead_id}`} target='_blank'>View Lead</a>		
						</div>
						</div> 
					},{
						headerName : "Action",
						field: "view_kyc",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className='d-flex align-items-center'>
						<div>
						<button type='button' className='btn view_btn_kyc text-light font' onClick={()=>this.handleAuditKyc(params.row.audited_by,params.row.lead_id)}>{check_priv('lead','pending_kyc_list')?'Audit KYC':'View KYC'}</button>		
						</div>
						
						</div> 
						
						
						// <div className='d-flex  align-items-center mt-1'><button type='button' className='btn view_btn_kyc text-light font' onClick={()=>this.handleAuditKyc(params.row.audited_by,params.row.lead_id)}>{check_priv('lead','pending_kyc_list')?'Audit KYC':'View KYC'}</button> </div> 
					},
				]
			case 'kyc_assigned_for_audit':
				return [
					{
						headerName : "Account Provider &  Account Number ",
						field: "account_num",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className='d-flex align-items-center'>
							<div>
							<img className="img-fluid" style={{maxWidth:'60px'}} src={`/img/${params.row.acc_prvdr_code}_logo_color.png`} alt={params.row.acc_prvdr_code}/>			
							</div>
							<p className='my-0 mx-2' > {params.row.account_num}</p>
							</div> 
					},{
						headerName : "Business Name",
						field: "biz_name",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						width:150,
						maxWidth:200,
						renderCell:params => <div className='d-flex row p-0 m-0 align-items-center'><p className='no-margin col-12 p-0' > {capitalize_first_letter(params.row.biz_name)}</p><p className='no-margin p-0 col-12' > {params.row.mobile_num}</p>  </div> 
					},{
						headerName : `${getValueFromLangJson("rm_label_short")} Name`,
						field: "rm_name",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => capitalize_first_letter(params.row.rm_name) 
					},{
						headerName : "Status",
						field: "status",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className='d-flex align-items-center '> {params.row.status=='50_pending_audit'?'In Progress':''}  </div> 
					},{
						headerName : "Location",
						field: "location",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className='d-flex align-items-center '>{capitalize_first_letter(dd_value(params.row.location))} </div> 
					},
					{
						headerName : "Lead Created by",
						field: "sales_rep_id",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell: (cell) => {
							return <>{(cell.value?'Sales Rep':`${getValueFromLangJson("rm_label_short")}`)}</>;
						}, 
					},{
						headerName : "Action",
						field: "view_lead",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params =><div className='d-flex align-items-center'>
						<div>
						<a type='button' className='btn view_btn_kyc text-light font' href={`/lead/edit/${params.row.lead_id}`} target='_blank'>View Lead</a> 	
						</div>
						
						</div> 
						
						
					},{
						headerName : "Action",
						field: "view_kyc",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params =><div className='d-flex align-items-center'>
						<div>
						<a type='button' className='btn view_btn_kyc text-light font' href={`/lead/audit_kyc/${params.row.lead_id}`} target='_blank'>Audit KYC</a>	
						</div>
						
						</div> 
						
				
					},
				]
				case 'reassignment_pending_with_rm':
				return [
					{
						headerName : "Account Provider & Account Number " ,
						field: "account_num",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => 
						<div className='d-flex align-items-center'>
						<div>
						<img className="img-fluid" style={{maxWidth:'60px'}} src={`/img/${params.row.acc_prvdr_code}_logo_color.png`} alt={params.row.acc_prvdr_code}/>			
						</div>
						<p className='my-0 mx-2' > {params.row.account_num}</p>
						</div> 
					},{
						headerName : "Business Name",
						field: "biz_name",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						width:190,
						renderCell:params => <div className='d-flex row p-0 m-0 align-items-center'><p className='no-margin col-12 p-0' > {capitalize_first_letter(params.row.biz_name)}</p><p className='no-margin p-0 col-12' > {params.row.mobile_num}</p>  </div> 
					},{
						headerName : `${getValueFromLangJson("rm_label_short")}Name`,
						field: "rm_name",
						width:190,
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => capitalize_first_letter(params.row.rm_name)
					},{
						headerName : "Status",
						field: "status",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className='d-flex align-items-center '> {`Pending With ${getValueFromLangJson("rm_label_short")}`}  </div> 
					},{
						headerName : "Location",
						field: "location",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className='d-flex align-items-center '>{capitalize_first_letter(dd_value(params.row.location))} </div> 
					},
					{
						headerName : "Lead Created by",
						field: "sales_rep_id",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						// renderCell:params => params.row.sales_rep_id,
						renderCell: (cell) => {
							return <>{(cell.value?'Sales Rep':`${getValueFromLangJson("rm_label_short")}`)}</>;
						}, 
					},{
						headerName : "Action",
						field: "view_lead",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className='d-flex align-items-center'>
						<div>
						<a type='button' className='btn view_btn_kyc text-light font' href={`/lead/edit/${params.row.lead_id}`} target='_blank'>View Lead</a>
						</div>
						
						</div> 
						
					},{
						headerName : "Action" ,
						field: "view_kyc",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className='d-flex align-items-center'>
						<div>
						<a type='button' className='btn view_btn_kyc text-light font' href={`/lead/audit_kyc/${params.row.lead_id}`} target='_blank'>View KYc</a>
						</div>
						
						</div>
						
						
					}]

					// case 'cust_app_access':
					// 		return [
					// 			{
					// 				headerName : "RM Name",
					// 				id: "visitor_name",
					// 				headeralign: 'left', flex: 1,resizable: true, align: 'left',
					// 				renderCell:row => <div className=' align-items-center'><p className='no-margin' > {capitalize_first_letter(row.approval_json[0].approver_name)}</p>  </div> 
	
					// 			},{
					// 				headerName : "Status",
					// 				id: "status",
					// 				headeralign: 'left', flex: 1,resizable: true, align: 'left',
					// 				renderCell:row => <div className='d-flex align-items-center'><p className='no-margin' > {capitalize_first_letter(row.status)}</p>  </div> 
					// 			},
					// 			{
					// 				headerName : "Cust ID",
					// 				id: "cust_id",
					// 				headeralign: 'left', flex: 1,resizable: true, align: 'left',
					// 				renderCell:row => <div className='d-flex align-items-center'><p className='no-margin' > {row.cust_id}</p>  </div> 
					// 			},
					// 			{
					// 				headerName : "Requested Date",
					// 				id: "date",
					// 				headeralign: 'left', flex: 1,resizable: true, align: 'left',
					// 				renderCell:row => <div className='d-flex align-items-center'><p className='no-margin' > {moment(row.created_at).format('DD-MMM-YYYY')}</p>  </div> 
					// 			},
					// 		]
			case 'unreversed_excess':
				return [
					{
						headerName : "Account Number",
						field: "acc_number",
						maxWidth:180, 
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => <div className='d-flex align-items-center '>
							 <div>
							 <img className="img-fluid" style={{maxWidth:'50px'}} src={`/img/${params.row.acc_prvdr_code}_logo_color.png`} alt={params.row.acc_prvdr_code}/>			
							 </div>
							 <p className='no-margin ' style={{paddingLeft:'10px'}}> {params.row.acc_number}</p>  </div> 
					},
					// {
					// 	headerName : "Channel",
					// 	id: "channel",  
					// 	headeralign: 'left', flex: 1,resizable: true, align: 'left',
					// 	maxWidth:85,               
					// 	renderCell:row => {return <p className='text-light text-uppercase '>{row.channel?title_case(row.channel):''}</p>}
					// },
					// {
					// 	headerName : "FLOW Cust ID",
					// 	id: "cust_id",
					// 	headeralign: 'left', flex: 1,resizable: true, align: 'left',
					// 	renderCell:row => <Link to={"/borrower/indiv/view/"+row.cust_id} target="_blank" className="flow_custid" >{row.cust_id}</Link>
					// },
					{
						headerName : "FA ID",
						field: "loan_doc_id",
						maxWidth:200, 

						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params =>  <Link to={"/fa/view/"+params.row.loan_doc_id} target="_blank" className="flow_custid" >{params.row.loan_doc_id}</Link>
					},
					{
						headerName :'Paid Excess Amount',
						field: "paid_excess",
						sortMethod: (a,b) => this.sortAmount(a,b),
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						width:200,
						renderCell:params => { return <div>{lbl_amt(params.row.paid_excess,this.currency_code)}</div>}
					},

					{
						headerName : "Paid Date",
						field: "paid_date",
						width: 150,
						sortMethod: (a,b) => this.sortTime(a,b),
						renderCell:params => {return <div className='text-white'>{moment(params.row.paid_date).format("DD MMM YYYY")}</div>}
					},
					{
						headerName : "Customer Name",
						field: "cust_name",
						headeralign: 'left', flex: 1,resizable: true, align: 'left',
						renderCell:params => {return params.row.cust_name}
					},
					
					
					{
						headerName : "Business Name" ,
						field: "biz_name",
						width:250,
						
						// sortMethod : (a,b) => this.sortBiz(a,b),
						renderCell:params => <div className='w-100' >{params.row.biz_name}</div> 
					},
					{
						headerName : "View Transations",
						field: "view_transations",
						flex:1,
						width:190,
						renderCell:params =><ViewTransactions parent="view_loan" loan_doc_id={params.row.loan_doc_id} btn={"loan_txn_btn"}/>
					},
					{
						headerName : "Action" ,
						field: "action",
						width:200,
						flex:1,
						renderCell:params =>  <div className='text-center'>
							<button onClick={()=>this.automatic_excess_reversal({'loan_doc_id':params.row.loan_doc_id, 'amount':params.row.paid_excess})} className='btn product_takeup_submit w-100'>Reverse Excess</button>
						</div>
						
					},



			]

		case 'pending_capture_disbursal':
			return [
				{
					headerName : "FA ID & A/C Number",
					field: 'loan_doc_id',
					headeralign: 'left', flex: 1,resizable: true, align: 'left',
					renderCell: params => 
					<div className='vertical_aligned'>
						<div className='column_line_height'>
							<Link className='d-block rms_date_picker' to={"/fa/view/"+params.row.loan_doc_id} target="_blank">{params.row.loan_doc_id}</Link>
							<Link className='d-block rms_date_picker' to={"/borrower/indiv/view/"+params.row.cust_id} target="_blank">{params.row.acc_number}</Link>

							<p style={{margin:0}}>{params.row.distributor_code == null ? " " : "("+title_case(params.row.distributor_code)+")"}</p>
						</div>
					</div>
				},
				{
					headerName : "Channel",
					field: "channel",  
					headeralign: 'left', flex: 1,resizable: true, align: 'left',
					width:80,          
					renderCell:params => {return <p className='text-light  '>{params.row.channel?title_case(params.row.channel):''}</p>}
				},
			{
                headerName : "ACC PRVDR",
                field: "acc_prvdr_code", 
				width:80,           
                headeralign: 'left', flex: 1,resizable: true, align: 'left',
                renderCell:params =><div className='d-flex'> 
					 <Homeacc_prvdrLogoselector logotype={params.row.acc_prvdr_code}/>
					 </div>
            },
            {
                headerName : "Biz Name & Mobile Number",
				 headeralign: 'left', flex: 1,resizable: true, align: 'left',
                field: 'cust_mobile_num',
                renderCell: params => <div className='vertical_aligned'>
				<div className='column_line_height'>
                                    <TooltipLabel className='d-block' full_text={params.row.biz_name} overlay={`${params.row.cust_name} / ${lbl_amt(params.row.loan_principal, this.currency_code)}`} no_reduce={true} />
                                    <label className='no-margin d-block'>{params.row.cust_mobile_num}</label>                                    
                                </div> </div>
            },
            {
                headerName : "Applied & Approved",
                field: 'loan_appl_date',
                headeralign: 'left', flex: 1,resizable: true, align: 'left',
				width:250,
                renderCell: params => <div className='vertical_aligned'>
					<div className='column_line_height'>
                                    <div >Applied :  <ExcesstimeAction data={params.row} timedata={this.state.server_time} item={"applied"} time_limit={[this.state.fa_late1,this.state.fa_late2]}/></div>
                                    <div >Approved :  <ExcesstimeAction data={params.row} timedata={this.state.server_time} item={"approved"} time_limit={[this.state.fa_late1,this.state.fa_late2]}/></div>
                                 </div> </div>
            },
			
            {
                headerName : " FA Status  Disbursal Status",
                field : 'status',
                headeralign: 'left', flex: 1,resizable: true, align: 'left',
                renderCell : params => 
				<div className='vertical_aligned'>
                <div className='column_line_height'>
                    <div>{dd_value(params.row.status)}</div>
                    {(params.row.disbursal_status != null) ?
 
                    	<OverlayTrigger placement='auto' trigger={['hover', 'focus']} overlay={  <Popover style={{fontSize: "15px", padding: "15px", color:"mediumblue", fontFamily: "Montserrat"}}>Float Transferred. Not Captured on  App</Popover>}><div>{title_case(params.row.disbursal_status || "not_initiated")} <sup><FcInfo size={14}/></sup></div></OverlayTrigger>
					:
                        <>
                        </>
                    } 
                </div> </div>
            },
            {
			
                headerName :  check_priv('loan','home_capture_disbursal') ? "Action" :'',
                headeralign: 'left', flex: 1,resizable: true, align: 'left',
                field:'btn',
                renderCell : params =><>{check_priv('loan','home_capture_disbursal') && this.buttonName(params.row) }</>           

            }
		]
		case 'pending_float_switch':
			
				return [
					{
						
						headerName: <div className='d-flex justify-content-center'>Acc Prvdr</div>,
						field: "acc_prvdr_code",
						sortable: false,
						flex:1,minWidth:180,
						headerAlign: "center",
						renderCell: (cell) => {
						  const fromProvider = cell.row.from_acc_prvdr_code;
						  const toProvider = cell.row.to_acc_prvdr_code;
					  
						  return (
							<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
							  <img
								className="img-fluid"
								style={{ maxWidth: "60px", height: "37px", marginRight: "10px", borderRadius: "20px" }}
								src={`/img/${fromProvider}_switch_logo.png`}
								alt={fromProvider}
							  />
							  <span style={{ fontSize: "20px", margin: "0 10px", color: "gray" }}>→</span>
							  <img
								className="img-fluid"
								style={{ maxWidth: "60px", height: "37px", marginLeft: "10px", borderRadius: "20px" }}
								src={`/img/${toProvider}_switch_logo.png`}
								alt={toProvider}
							  />
							</div>
						  );
						},
					  },					  
					{
						headerName :"Switch ID",
						field: "sales_doc_id",
						sortable:false,
						flex:1,minWidth:140,
						renderCell:(cell)=>{return <Link to={"/sales/view/"+cell.value} target="_blank" >{cell.value}</Link>}
					},
					{
						headerName :"Biz Name",
						field: "biz_name",
						flex:1,minWidth:150,
						headeralign: 'left',resizable: true, align: 'left',
						renderCell:(cell)=>{return <><TooltipLabel full_text={cell.value}  no_reduce={false} /></>}
					},
					{
						headerName :"Cust ID",
						field: "cust_id",
						flex:1,minWidth:140,
						headeralign: 'left', resizable: true, align: 'left',
						renderCell:(cell)=>{return <><Link to={"/borrower/indiv/view/"+cell.value} target="_blank" >{cell.value}</Link></>}
					},
					{
						headerName :"Payment Date",
						field: "payment_date",
						headeralign: 'left', resizable: true, align: 'left',
						flex:1,minWidth:190,
						renderCell:(cell)=>{return <>{cell.value==null ? 'NA' :moment(cell.value).format('DD-MMM-YYYY hh-mm a')} </>}
					},
					{
						headerName : "Initiated",
						flex:1,minWidth:150,
						resizable: true,
						field: 'created_at',		        			
						renderCell:params => <ExcesstimeAction data={params.row} timedata={this.state.server_time} item={"created_at"} time_limit={[this.state.float_switch_late1,this.state.float_switch_late2]}/>
					},
					{
						headerName :"Product Amount",
						field: "product_amt",
						flex:1,minWidth:170,
						headeralign: 'left', resizable: true, align: 'left',
						renderCell:(cell)=>{return <>{lbl_amt(cell.value,this.currency_code)}</>}
					},
					{
						headerName :"Transfer Amount",
						field: "transfer_amt",
						headeralign: 'left',resizable: true, align: 'left',
						flex:1,minWidth:170,
						renderCell:(cell)=>{return <> { cell.value ? lbl_amt(cell.value) :"-"}</>}
					},
					{
						headerName : "Switch & Disbursal Status",
						field: "disbursal_status",
						headeralign: 'left',resizable: true, align: 'left',
						flex:1,minWidth:200,
						renderCell:cell =><div className='vertical_aligned'>
						<div className='column_line_height'>
						<span>{title_case(cell.row.status)}</span>
						
						{(cell.row.disbursal_status != null) ?
						
							<div>{title_case(cell.row.disbursal_status || "not_initiated")} </div>
						:
							<>
							</>
						} 
						</div> 
						</div>
					},
					{
						headerName : "Action",
						field:'btn',
						headeralign: 'left',resizable: true, align: 'left',
						flex:1, minWidth:200,             
						renderCell: (cell) => {
							return (
								<div className='d-flex justify-content-center align-items-center'>
									
									{this.salesRetry(cell.row)}

									<div >
										{
											check_priv('disbursal', 'show_disb_atmpts') &&
											<button className='btn btn-primary text-light font'
												style={{ marginLeft: '10%',marginBottom: '3%'}}
												onClick={() => { this.attempt_status(cell.row.sales_doc_id, 'Float Switch'); }}>
												Attempts
											</button>
										}
									</div>
								</div>
							);
						}
					},
					
				]
		}		
	}
    

	statusChange (event, sales) {


        let value = event.target.value
        let alert_msg = ""
        if (value === 'pending_disb_capture') {
            alert_msg = "Are you sure if the disbursal attempt is successful and there is a debit transaction to the customer found in the A/C statement?"
        } else if (value === 'failed') {
            alert_msg = "Are you sure the disbursal attempt got failed and there is no debit transaction to the customer found in the A/C statement?"
        }
        if (window.confirm(alert_msg)) {
            const request = {sales_doc_id: sales.sales_doc_id, disbursal_status: value}
            changeSwitchDisbursalStatus(this.req(request)).then((res) => {
                this.resp(res)
                if (res && res.status == 'success') {
					this.refreshList("show_acc_prvdr")
                }
            })

        }
    }

	salesRetry = (sales) => {

		let value;

		if(sales.disbursal_status == 'failed'){

			value = <div> 
						{check_priv('loan','retry_disbursal') && <input style={{marginBottom: '3%', color: "#202940"}} id={sales.sales_doc_id} type='submit' value ='Retry' onClick={(event) => {this.retryFloatTransfer(sales.sales_doc_id)}} className='btn btn-primary text-light font'/>} 
					</div>
		}
		else if(sales.disbursal_status == 'unknown') {
			 value = <td><select style={{minHeight:'30px',width:90}} onChange={(event) => {this.statusChange(event, sales)}}>
					 <option disabled selected hidden className='text-white'>choose</option>
					 <option value="pending_disb_capture" className='text-white'>Pending Capture</option>
					 <option value="failed" className='text-white'>Failed</option></select></td>
		}
 
		return  value
 
	}

	attempt_status = (doc_id,mode) => {
		this.setState({
			txn_mode: mode,
			doc_id: doc_id,
			disb_atmpt_modal: true
		});
	}
	handleClosemodel = () => {
		this.setState({ disb_atmpt_modal: false });
	}
	
	retryFloatTransfer = (sales_doc_id) => {

        // if(this.state.calling_api){return}
        if(!window.confirm("Have you verified in the A/C statement if there is no debit transaction found related to this Float Switch?\n\nPlease note that clicking OK will re-attempt the Transfer")){return}
        retryTransferAttempt(this.req({'sales_doc_id' : sales_doc_id}, true)).then((response) => {
            this.resp(response)
            this.refreshList("show_acc_prvdr")
        })


	}

	buttonName = (loan) => {

        let value
        if(loan.disbursal_status == 'pending_disb_capture'){
           value =  <CaptureFailedDisbursal id="disbursal_txn" data={loan} title="Disbursal Loan" />
        }
        else if(loan.status == 'pending_mnl_dsbrsl'){
           const loan_data = loan;
           loan_data.amount = loan.loan_principal;
           value =  <div className='text-left'>  
                    	{check_priv('loan','disburse_manually') && <DisburseLoan id="disbursal_txn" data={loan_data} parent="home" disabled={this.state.calling_api} onStatusChange={this.refreshList} onDisburseClick={(is_refresh_enabled) => this.setState({disb_manually: is_refresh_enabled})}/>}
                    </div>
		}

        return  value
    }
	
	refreshList = (get_acc_prvdr = false) => {		
		this.setState({refresh: true})		
		this.getListData(get_acc_prvdr)		
		
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.refresh != this.props.refresh && this.state.disb_manually == true ){
			if(this.props.isrefresh && this.props.isrefresh == "false"){
				return null
			}else{
				this.refreshList()
			}
        }		
    }
	handleClose = () => {
        this.setState({modal: false})
    }
	handleOpen = (cust_id, loan_doc_id) => {
		let call_log = {cust_id: cust_id, call_type: 'outgoing',  from : 'cust_conformation'}
		StartCallLog(this.req({call_log})).then((response)=>{
				this.resp(response)
				if(!response){return };
                    if(response.status === "success"){
                        const  log = response.data
                        var resp = true;
                        if(log.action == "lastcall"){
                            resp = window.confirm(log.message);
                        }
                        if(resp && log.call_log_id){
							this.setState({modal: true, call_cust_id:  (log.action == "lastcall" ) ? log.cust_id : cust_id,  call_id: log.call_log_id  })
                        }
                        }else{
                            alert(response.message  +"\n" + JSON.stringify(response.data));
                        }
			}
		)

}

 call_to_customer  (data) {
    dispatch(setValuesIntoRedux({cust_id:data.cust_id}))
    setcustomer_data(data)
  }

  handle_acc_prvdr(provider , rows = null , acc_prvdr_list = null ) {
	let row_data;
	 if(rows){
		row_data= rows
	 }else{
		 row_data =this.state.lists
	 }
	
	handleProviderSelection(
	  provider,
	  acc_prvdr_list ?? this.state.selectedProviders,
	  this.props.acc_prvdr_list,
	  row_data,
	  (updated_prvdr) => this.setState({ selectedProviders: updated_prvdr }),
	  (filtered_rows) => this.setState({ filteredRows: filtered_rows })
	);
  }

    render(){	
      const row_data =(this.props.list_name == "kyc_in_pending_with_audit" || this.props.list_name == "reassignment_pending_with_rm" || this.props.list_name == "kyc_assigned_for_audit") ? this.state.lists : this.state.copy

     const show_acc_prvdr_list = this.state.all_Acc_pvdrs?.length>1 
        return (
		<>
			<div className='display_list'>

			{this.props.list_name == 'pending_w_cust' ? <> 
			<PendingCustomerConfirmation type ='pending_w_cust' handleOpen={this.handleOpen}/>
			
				{this.props.list_name == 'pending_w_cust' && <Modal dialogClassName= "rec-form-modal" show={this.state.modal} onHide={this.handleClose}>
                    <Modal.Body closeButton>
						<CallLog is_conf match={{params: {id: this.state.call_id, cust_id: this.state.call_cust_id , }}} onComplete={() => window.location.reload()} onCancel={() => this.setState({modal: false})}/>
						</Modal.Body>
				</Modal>}</>
				: <>
				<MUIAccordion
				title ={
					<div 
					// className={`${accordion_cond && (!accordion_cond.length > 0) ? 'empty_display_list':'non_empty_display_list'}`}
					>

					<div className="card-header w-90 card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between" > 
					<h4 className="card-title text-white tableHead_font ">{this.getListTitle()}</h4>
					<AccProviderFilter
                            rows={this.state.lists??[]} 
                            handle_acc_prvdr={(provider)=>this.handle_acc_prvdr(provider)}
                            selectedProviders ={this.state.selectedProviders}
                            />
								
					</div>	

					</div>
				}

				body ={
								<DataGridTable
								rows={this.state.filteredRows??[]  } 
								paginationMode= {"client"}
								page={this.state.page}
								setpage={(newPage) => this.setState({ page: newPage })}
								// isLoading={ this.state.loader }
								rowCount={this.state.filteredRows?.length}
								columns={this.state.columns??[]}
								server_time ={this.state.server_time??0}
								slots={{ toolbar: CustomTableGridToolbar }}
								slotProps={{
								toolbar: {
									showQuickFilter: true,
								
								},
								}}
								
								/>
				}
				total_count = { this.props.acc_prvdr_list?.length > 1 ? 0 : (this.state.lists?.length)}

				
				>

				</MUIAccordion>
				</>}
		</div>
			{this.state.disb_atmpt_modal && (
				<DisbAtmptHistory
				docId={this.state.doc_id}
				mode={this.state.txn_mode}
				onClose={this.handleClosemodel}
				/>
			)}
		</>
        	)
    }

	getListTitle = () => {
		switch (this.props.list_name){
			case 'pending_w_cust':
				return "FAs Pending Customer Confirmation "
			case 'pending_w_rm':
				return getValueFromLangJson('fas_pending_rm_approval')
			case 'active_cust_without_fa':
				return "Active Customers without FA"
			case 'pending_capture_disbursal':
				return "FAs Pending Capture"
			case 'unreversed_excess':
				return "Unreversed Excess"
			case 'kyc_in_progress':
				return "KYCs In Progress at Customer's Place"
			case 'kyc_in_pending_with_audit':
				return "KYCs Unassigned for Audit"
			case 'kyc_assigned_for_audit':
				return "KYCs Assigned for Me"		
			case 'reassignment_pending_with_rm':
				return `KYCs Pending with ${getValueFromLangJson("rm_label_short")} for Rectification`		
			case 'cust_app_access':
				return "Pending Customer App Access "			
			case 'unreversed_duplicate_disbursals':
				return "Unreversed Duplicate Disbursals"
			case 'pending_statement':
				return "Leads Pending Statement Upload"				
			case 'pending_float_switch':
				return "Pending Float Switch "		
		}
	}
}



const mapStateToProps = (state) => {
	return {
	  softPhoneState: state.cs_soft_phone,
	  acc_prvdr_list : state.common_slice?.acc_prvdr_list

	};
  };

const mapDispatchToProps ={
    setValuesIntoRedux
  
  };


export default connect(mapStateToProps, mapDispatchToProps)(DisplayList);
