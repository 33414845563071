import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { dd } from "../../../actions/common_actions";
import {
  process_call_log,
  updateCallScreen,
} from "../../../actions/cs_management_actions";
import { INCOMING } from "../../../consts";
import { useSoftPhoneCtx } from "../../../context/softPhoneCtx/useSoftPhoneCtx";
import { RhfOptionsTypeAndKeyCheckProperties } from "../../../helpers/helper";
import { RhfOptionType } from "../../../type";
import FormProvider from "../../CommonComponent/rhfComponents/formProvider";
import RHFAutocomplete from "../../CommonComponent/rhfComponents/rhfAutoComplete";
import RHFTextField from "../../CommonComponent/rhfComponents/rhfTextField";
import { useGetCustDetailsByPhNumQuery } from "../hooks/useGetCustDetailsQuery";
import SpamCheckUI from "./spamCheckUI";
import { Config } from "../../../helpers/storage_helper";
import { PENDING_WITH_CUST_CALL_LOG_MODAL } from "../../../context/softPhoneCtx/softPhoneCtx";

const FormSchema = Yup.object().shape({
  reason: Yup.array<RhfOptionType>().required("Remark is required"),
  remarks: Yup.string().required("Reason is required"),
  isKnownCustomer: Yup.boolean(),
  validAccNumOrCustNumber: Yup.string(),
  isSpamCheckedOnce: Yup.boolean(),
});

const RemarksAndReasonUI = React.memo(
  ({
    isCallLogForOutGoingCall,
    phoneNumber,
    closeModalHandler,
    isCallConnected,
    setcustomerList,
  }: {
    isCallLogForOutGoingCall: boolean;
    phoneNumber: string;
    closeModalHandler: Function;
    isCallConnected: boolean;
    setcustomerList: Function;
  }) => {
    // hook
    const queryClient = useQueryClient();

    // ctx
    const {
      softPhoneState: {
        callLogDetails: { callLogForIncomingOrOutGoing, callLogForScreen },
      },
    } = useSoftPhoneCtx();

    // state
    const [isSubmitting, setisSubmitting] = useState(false);

    // hookForm
    const methods = useForm({
      resolver: yupResolver(FormSchema),
      defaultValues: {
        reason: [],
        remarks: "",
        isKnownCustomer: false,
        validAccNumOrCustNumber: "",
        isSpamCheckedOnce: false,
      },
    });

    // rhf returns
    const {
      handleSubmit,
      formState: { isValid },
      watch,
    } = methods;

    // query
    const { data, isError, isLoading } = useGetCustDetailsByPhNumQuery({
      phoneNumber,
    });

    const customerList = data?.data.customer_list[0];

    const isKnownCustomer = watch("isKnownCustomer");
    const isSpamCheckedOnce = watch("isSpamCheckedOnce");

    // handler
    const onSubmit = handleSubmit(async (formData) => {
      setisSubmitting(true);
      const req = { screen: "soft_phone" };
      const res = await updateCallScreen(req);
      const callLogDetails = res?.data?.call_log_details;
      const canLogTheCall =
        callLogDetails?.call_log_id && callLogDetails?.status === "pending";
      if (canLogTheCall) {
        const currentOrPrevLoan = customerList?.current_loan
          ? "current_loan"
          : "last_loan";
        const reqObj = {
          reason: formData.reason.map((r) => r.code),
          remarks: formData.remarks,
          call_log_id: callLogDetails?.call_log_id,
          loan_doc_id: customerList
            ? customerList[currentOrPrevLoan]?.loan_doc_id
            : null,
          ...(isKnownCustomer && { cust_id: customerList?.cust_id }),
        };
        process_call_log(reqObj)
          .then((res) => {
            if (res && res.status == "success") {
              queryClient.invalidateQueries({
                queryKey: ["prev_call_interactions"],
              });
              setcustomerList(customerList);
              closeModalHandler();
            }
          })
          .finally(() => setisSubmitting(false));
      } else {
        setisSubmitting(false);
        alert("Unable to log the call!");
      }
    });

    // const options*
    const incomingCallReasonOptions = dd("incoming_call_purpose").map(
      (i: Record<string, string>) => ({
        id: i.id,
        name: i.data_value,
        code: i.data_code,
      })
    );
    const outGoingCallReasonOptions = dd("outgoing_call_purpose").map(
      (o: Record<string, string>) => ({
        id: o.id,
        name: o.data_value,
        code: o.data_code,
      })
    );
    const getPhoneConfirmationReasonOptions = Config(
      "get_phone_confm_dd_options"
    ).map((o: Record<string, string>) => ({
      id: o.data_code,
      name: o.data_value,
      code: o.data_code,
    }));

    const disableSubmitButton = !isValid || isCallConnected || isSubmitting;
    const showSpamCheckUI =
      (!!!customerList &&
        !isLoading &&
        !isError &&
        callLogForIncomingOrOutGoing === INCOMING) ||
      isSpamCheckedOnce;

    return (
      <>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          {/* spam check UI */}
          {showSpamCheckUI && <SpamCheckUI />}
          {/* Reason and remarks UI */}
          <RHFAutocomplete
            name="reason"
            multiple
            options={
              callLogForScreen === PENDING_WITH_CUST_CALL_LOG_MODAL
                ? [...getPhoneConfirmationReasonOptions, { name: "", id: "" }]
                : isCallLogForOutGoingCall
                ? [...outGoingCallReasonOptions, { name: "", id: "" }]
                : [...incomingCallReasonOptions, { name: "", id: "" }]
            }
            sx={{
              mb: 2,
            }}
            {...RhfOptionsTypeAndKeyCheckProperties}
            placeholder="Call Reason"
          />
          <RHFTextField
            name="remarks"
            multiline
            rows={5}
            placeholder="Remarks"
            sx={{ mb: 2 }}
          />
          <Box textAlign="center">
            <Button
              disabled={disableSubmitButton}
              variant="contained"
              type="submit"
              color="secondary"
              sx={{ minWidth: "75px", my: 2 }}
            >
              Submit
            </Button>
          </Box>
        </FormProvider>
      </>
    );
  }
);

export default RemarksAndReasonUI;
