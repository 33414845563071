import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { CircularProgress } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { getUserDetailsForSpamCheck } from "../../../actions/cs_management_actions";
import { useSoftPhoneCtx } from "../../../context/softPhoneCtx/useSoftPhoneCtx";
import RHFTextField from "../../CommonComponent/rhfComponents/rhfTextField";

const SpamCheckUI = () => {
  // hook
  const queryClient = useQueryClient();

  // form ctx
  const { setValue, watch } = useFormContext();
  const validAccNumOrCustNumber = watch("validAccNumOrCustNumber");
  const isValidCustomer = watch("isKnownCustomer");

  // ctx
  const {
    softPhoneState: {
      callLogDetails: { phoneNumber },
    },
  } = useSoftPhoneCtx();

  // hook
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (reqParam: string) =>
      getUserDetailsForSpamCheck({ verify_prams: reqParam }),
    onSuccess: (data) => {
      const isNotSpam = data?.data?.customer_list;
      setValue("isKnownCustomer", !!isNotSpam);
      setValue("isSpamCheckedOnce", true);
      queryClient.setQueryData(
        ["selected_ph_num_s_cust_det", phoneNumber],
        () => {
          return {
            data: {
              customer_list: [data?.data?.customer_list],
            },
          };
        }
      );
    },
    onError: () => {
      setValue("isSpamCheckedOnce", true);
    },
  });

  // effect
  useEffect(() => {
    const handler = setTimeout(() => {
      // if (validAccNumOrCustNumber) {
        mutate(validAccNumOrCustNumber);
      // }
    }, 1000);
    return () => clearTimeout(handler);
  }, [validAccNumOrCustNumber]);

  return (
    <RHFTextField
      name="validAccNumOrCustNumber"
      placeholder="Acc No Or Customer Mobile Number"
      sx={{ mb: 2 }}
      InputProps={{
        endAdornment: (
          <>
            {isPending ? ( // pending status
              <CircularProgress
                sx={{
                  "&.MuiCircularProgress-root": {
                    width: "22px!important",
                    height: "22px!important",
                    color: "#fff",
                  },
                }}
              />
            ) : (
              <>
                {isValidCustomer && isSuccess ? ( // not spam case *verified icon will show once the mutation done
                  <VerifiedUserIcon
                    sx={{ color: "#00c853", width: "22px", height: "22px" }}
                  />
                ) : (
                  <>
                    {!isValidCustomer && isSuccess ? (
                      <NoAccountsIcon
                        sx={{ color: "#e53935", width: "22px", height: "22px" }}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
          </>
        ),
      }}
    />
  );
};

export default SpamCheckUI;
