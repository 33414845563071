import React from 'react';
import '../../../styles/flow.css';
import {Modal,Button } from 'react-bootstrap';
import {listLoanTxn,removePayment, removeDisbursal} from '../../../actions/loan_actions';

import FlowComponent from '../../common/component/core/FlowComponent';
import {reduce_character,format_date,capitalize_first_letter, getValueFromLangJson} from '../../../helpers/common_helper';
import {rt_algn, lbl_amt} from '../../../helpers/common_helper';
import {TooltipLabel} from '../../common/partial';
import {dd_value} from '../../../actions/common_actions';
import $ from 'jquery';
import CommonReactTable from '../../../helpers/commonReact_table';
import  {check_priv, get} from '../../../helpers/storage_helper';
import { BasicReactTable } from '../../../helpers/react_table';

class ViewTransactions extends FlowComponent {

	state = {
		loanTxnList: null
	}

	handleRemovePayment = (txn_id) => {

		const resp = window.confirm("Are you sure to remove this payment?")
  		if(resp === false){
			return;
		}
		const request = {loan_txn_id : txn_id}
		removePayment(this.req(request, true))
		.then((response) => {
			if(!response){return };
			if(response.status === "success"){
				//alert('Payment reversed successfully.');
				window.location.reload();

			}else{
				alert(response.message + "\n" + JSON.stringify(response.data));  
			}
			}
		);
	}

	handleRemoveDisbursal = (txn_id) => {

		const resp = window.confirm("Are you sure to remove this disbursal ?")
  		if(resp === false){
			return;
		}
		const request = {loan_txn_id : txn_id}
		removeDisbursal(this.req(request, true))
		.then((response) => {
			if(!response){return };
			if(response.status === "success"){
				//alert('Payment reversed successfully.');
				window.location.reload();

			}else{
				alert(response.message + "\n" + JSON.stringify(response.data));  
			}
			}
		);
	}

   handleViewTxnClick = (event) => {
   		const loan_doc_id = this.props.loan_doc_id;
		listLoanTxn(this.req({loan_doc_id}))
        .then((response) => {
          if(!response){return };
					this.setState(state=>state.rowdatas = response.data)
					this.setState(state=>state.copy = response.data)
					this.setState(state=>state.columndatas=this.columndatas())
		          const loanTxnList = response.data.map((loan_txn,i)=>
		              <tr key={i}>
		                <td>{++i}</td>
			            <td>{dd_value(loan_txn.txn_type)}</td>
			            <td>{lbl_amt(loan_txn.amount, this.currency_code)}</td>
			            <td>{format_date(loan_txn.txn_date,true)}</td>
			            <td><TooltipLabel full_text={reduce_character(loan_txn.from_ac_text)} overlay={loan_txn.from_ac_text} /></td>
			            <td><TooltipLabel full_text={reduce_character(loan_txn.to_ac_text)} overlay={loan_txn.to_ac_text} /></td>
			            <td>{loan_txn.txn_id}</td>
			            <td>{dd_value(loan_txn.txn_mode)}</td>
			            <td>{capitalize_first_letter(loan_txn.txn_exec_by)}</td>
						{/* <td>{(loan_txn.txn_type == 'payment' || loan_txn.txn_type == 'penalty_payment') && <Button onClick={(e) => this.handleReversePayment(loan_txn.txn_id)}>Reverse Payment</Button>}</td> */}
		              </tr>
		          );
	         
          		  this.setState({loanTxnList: loanTxnList});
         
        });
		this.setState({modalBox: true});
	}

	columndatas () {
		return[
			// {
			// 	Header : "SI No.", 
			// 	id: "row",
			// 	minWidth: 50,				       
			// 	Cell: (row) => {return <div>{row.index+1}</div>;}
			// },
			{
				Header : getValueFromLangJson('txn_type'),
				id: "txn_type",
				minWidth: 120,
				accessor:row => {return dd_value(row.txn_type)}
			},
			{
				Header : getValueFromLangJson("txn_amount"),
				id: "amount",
				minWidth: 150,
				accessor:row => {return lbl_amt(row.amount, this.currency_code)}
			},
			{
				Header : () => (<div className={'text-right'}>{getValueFromLangJson("txn_date_time")}</div>),
				id: "txn_date",
				minWidth: 180,
				accessor:row => {return rt_algn(format_date(row.txn_date,true))}
			},
			{
				Header : getValueFromLangJson('from_acc'),
				id: "from_ac_text",
				accessor:row => <TooltipLabel full_text={reduce_character(row.from_ac_text)} overlay={row.from_ac_text} />
			},
			{
				Header : getValueFromLangJson("to_acc"),
				id: "to_ac_text",
				accessor:row => <TooltipLabel full_text={reduce_character(row.to_ac_text)} overlay={row.to_ac_text} />
			},
			{
				Header : getValueFromLangJson("txn_id"),
				id: "txn_id",
				accessor:row => {return row.txn_id}
			},
			{
				Header : getValueFromLangJson("txn_mode"),
				id: "txn_mode",
				minWidth: 130,
				accessor:row => {return dd_value(row.txn_mode)}
			},
			{
				Header : getValueFromLangJson("txn_executed_by"),
				id: "txn_exec_by",
				minWidth: 130,
				accessor:row => {return capitalize_first_letter(row.txn_exec_by)}
			},
			
			// !this.props.partial &&
			{
				Header : () => check_priv('loan', 'reverse_payment') && (<div className={'text-center'}>Action</div>),
				id: "remove_pament",
				minWidth: 130,
				show: !this.props.partial ,
				accessor:'remove_pament',
				Cell: (cell) =>{return <div className={'text-center'}>{(check_priv('loan', 'reverse_payment') && (cell.row.original.txn_type == 'payment' || cell.row.original.txn_type == 'penalty_payment')) ? <Button className="btn-sm" onClick={(e) => this.handleRemovePayment(cell.row.original.id)}>Remove Payment</Button>
				: check_priv('loan', 'reverse_payment') && (cell.row.original.txn_type == 'disbursal') && <Button className="btn-sm" onClick={(e) => this.handleRemoveDisbursal(cell.row.original.id)}>Remove Disbursal</Button> }</div>}

			}
		
			
		]
	}

	handleModalClose = (event) => {
      this.setState({ modalBox: false });
   }

   changeHandler = (e) => {		
		const toFilter = e

		const filterfrom = this.state.rowdatas
		const filteredData = filterfrom.filter(value => {
			return(	
				
				value.txn_type !==null && value.txn_type.toLowerCase().includes(toFilter.toLowerCase()) ||
				// value.from_ac_text !==null && value.from_ac_text.includes(toFilter) ||
				value.to_ac_text !==null && value.to_ac_text.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.txn_id  !==null && value.txn_id.toLowerCase().includes(toFilter.toLowerCase()) ||
				value.txn_mode !==null && value.txn_mode.toLowerCase().includes(toFilter.toLowerCase())	
			);
		})
		this.setState(state=>state.copy = filteredData)	
	}
	
	componentDidUpdate(){
	$('#list_txn').dataTable({
		  "order" : [],
	      "bAutoWidth": true,
	      "bDestroy": true, 
	      dom:'Bfrtip',
          buttons: [
            'csv','excel','print'
           ]
	    });
	}
	render(){


		return(

		<div className="" style={{'padding-left':'0px'}} >
		    <div className="pull">   				
	            <input  type='submit' value={getValueFromLangJson("view_txns")} onClick={this.handleViewTxnClick} className={`${this.props.btn == "btn" ? "btn btn-primary" : this.props.btn == 'loan_txn_btn'? "btn product_takeup_submit" : "btn-sm btn-primary"}`}/>

	        </div>
	    
		   <Modal show={this.state.modalBox} onHide={this.handleModalClose} size="xl">
             	<Modal.Header closeButton show={this.state.modalBox} onHide={this.handleModalClose} >
                 	 <Modal.Title><h4>{getValueFromLangJson("txns_of_foat_advance_id")}: {this.props.loan_doc_id}</h4></Modal.Title>
              	</Modal.Header>
              	<Modal.Body>
             			{(this.state.loanTxnList != null) &&
				<div>
		            {/* <table id ='list_txn' className="table">
		                <thead className={"text-info"}>
		                  <tr><th>S.No</th>
		                      <th>Transaction Type</th>
		                      <th>Transaction Amount</th>
		                      <th>Transaction Date / Time</th>
		                      <th>From Account</th>
		                      <th>To Account</th>
		                      <th>Transaction ID</th>
		                      <th>Transaction Mode</th>
		                      <th>Txn Executed By</th>
							  <th>Action</th>
		                      </tr></thead>
		                      <tbody> 
		                      {this.state.loanTxnList}
		                      </tbody>
		             </table> */}
					 <div className='d-flex justify-content-between align-items-center' style={{padding:5}}>
						<p className='no-margin text-white'><b>{getValueFromLangJson("total_entries")} : {this.state.copy.length}</b></p>
						<div style={{position:'relative'}}>
							<i class="fa-solid fa-magnifying-glass search_pos"></i>
							<input placeholder='Search here' className='search_posInput' onChange={(e)=>this.changeHandler(e.target.value)}></input>
							{/* <button className='btn-std btn btn-primary' onClick={()=>this.resetHandler()}>Reset</button> */}
						</div>
					</div>
					{/* <CommonReactTable showPag={true} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} resizable={false} defaultSorted={[{id:"txn_date",desc:false}]} exportCSV={true} csvFile={"TransactionsList"} csvData={this.state.copy} searchData={[{loan_doc_id:this.props.loan_doc_id,title:'View Transaction'}]}/
					>                        */}
					{this.state.copy && this.state.copy.length >0 &&
					<BasicReactTable 
						row_data={this.state.copy !== undefined ? this.state.copy : []} 
						column_data={this.state.columndatas} FName={"TransactionsList"} 
						csv_file={true} pagination={true} default_page_size = {15} 
						searchData={[{loan_doc_id:this.props.loan_doc_id,title:'View Transaction'}]}  
						// defaultSorted={[{id:"txn_date",desc:false}]}  
						/>          
	                }          
		        </div>
		    }
             	</Modal.Body>
             	<Modal.Footer>
                	<Button variant="secondary" onClick={this.handleModalClose}>{getValueFromLangJson("close")}</Button>
              	</Modal.Footer>
          	</Modal>
		

		    </div>

			);
		}
	

}

export default ViewTransactions;