import React, { useEffect, useState } from "react";
import {
  Card, CardContent, Typography, Box, Avatar, IconButton, useTheme, Button
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import CustomModal from "../../../MUI/Components/CustomModal";
import BizMobileVerifyModal from "./BizMobileVerifyModal";
import BizMobileVerifyIdentity from "./BizMobileVerifyIdentity";
import VerifiedIcon from '@mui/icons-material/Verified';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import { dd, dd_value } from "../../../actions/common_actions";
import UpdateMobileOperator from "./UpdateMobileOperator";
import { check_priv, Config, get } from "../../../helpers/storage_helper";
import { FaPhoneAlt } from "react-icons/fa";
import CallIcon from '@mui/icons-material/Call';
import { useDispatch, useSelector } from "react-redux";
import { setValuesIntoRedux } from "../../../Redux/Slices/CsSoftphoneSlice";
import { useParams } from "react-router-dom";
import Softphone from '../../cs_management/container/cs_soft_phone';
import { useSoftPhoneCtx } from "../../../context/softPhoneCtx/useSoftPhoneCtx";


const AuditKycCard = ({ cust_reg_json, biz_mob_num, retrieval_api, audit_state, submit_holder_name_proof, numbers_verify, audit_kyc_modify_handler, audit_kyc_submit_callLog, callLogRejectHandler, view_lead }) => {

  const theme = useTheme();
  const [verifyModal, setVerifyModal] = useState({});
  const [verifyNumberModal, setVerifyNumberModal] = useState(false);
  const [verifyIdentityModal, setIdentityModal] = useState(false);
  const [editOperator, seteditOperator] = useState(false);
  const [mob_numbers, setmob_numbers] = useState({});
  const dispatch = useDispatch();
  const { id } = useParams();
  const { methods: softPhoneMethods } = useSoftPhoneCtx();

  useEffect(() => {
    if (cust_reg_json && biz_mob_num) {

      biz_mob_num['rm_entered_name'] = cust_reg_json?.id_proof?.first_name?.value + " " + cust_reg_json?.id_proof?.last_name?.value
      setmob_numbers(biz_mob_num)
    }
  }, [cust_reg_json])

  const handleVerifyNumber = () => {
    setVerifyNumberModal(true);
    const key = `${biz_mob_num.section}_${biz_mob_num.index}`;

    setVerifyModal({ [key]: biz_mob_num });
    if (!biz_mob_num.hasOwnProperty('holder_name')) {
      retrieval_api(biz_mob_num)
    }
  };

  const handleVerifyIdentity = () => {
    setIdentityModal(true);
    const key = `${biz_mob_num.section}_${biz_mob_num.index}`;
    setVerifyModal({ [key]: biz_mob_num });
  };


  const cardHeaderText = biz_mob_num.section === "biz_mob_num"
    ? biz_mob_num.index === 0
      ? "Registered Mobile Number"
      : `Alternate Mobile Number ${biz_mob_num.index}`
    : `Additional Mobile Number ${biz_mob_num.index + 1}`;

  const show_mismatch_reason = (biz_mob_num.section === "biz_mob_num") ? mob_numbers?.hasOwnProperty('verify_identity') : mob_numbers.verify

  const show_verified_flag = (mob_numbers?.section === "biz_mob_num") ? mob_numbers?.verify_identity === 'verified' : (mob_numbers?.hasOwnProperty("verify") && mob_numbers?.verify)

  const show_rejected_flag = (mob_numbers?.section === "biz_mob_num") ? mob_numbers?.verify_identity === 'rejected' : (mob_numbers?.hasOwnProperty("verify") && mob_numbers?.verify)


  const disable_edit = (mob_numbers?.verify_identity === 'verified' || show_mismatch_reason) || (mob_numbers?.verify_identity === 'rejected' || show_rejected_flag)

  const className = ((cust_reg_json?.mobile_numbers?.to_reassign === true || cust_reg_json?.mobile_numbers?.pending_with === "rm") || (audit_state?.audited_by != get("user_id") || audit_state?.audited_by == null || audit_state?.profile_status == 'closed'))
    ? " " : "";


  const mobileNumberVerify = (type, data) => {
    data['section'] = biz_mob_num.section
    data['verify'] = true
 
    numbers_verify(type, data)
    setmob_numbers(data)
  }

  const SubmitIdentity = async (data) => {
    if (window.confirm("Are you sure to submit the calllog ?")) {
    data['section'] = biz_mob_num.section
    await audit_kyc_submit_callLog(data)
     data['verify_identity'] = 'verified'
     setmob_numbers(data)
    }

  }

  const RejectIdentity = async (data) => {
    if (window.confirm("Are you sure to reject the calllog ?")) {
    data['section'] = biz_mob_num.section
    await callLogRejectHandler(data)
    data['verify_identity'] = 'rejected'
    setmob_numbers(data)
    }

  }

  const submitNameProof = (data) => {

    let is_same = biz_mob_num.rm_entered_name?.trim().split(" ").join(" ")
      === data.holder_name?.trim().split(" ").join(" ");
    if (is_same) {
      data['verify'] = true
    }

    data['section'] = biz_mob_num.section
    setmob_numbers(data)
    submit_holder_name_proof(data, !is_same)
  }

  const handleCall = (mobile_num) => {

    softPhoneMethods.initiateCall({
      phoneNumber: mobile_num,
      screen: "lead_num_verify",
      churnOrDeliquencyId: ""
    });

    // dispatch(setValuesIntoRedux({ screen_from: "lead_num_verify", lead_id: id, outgoing_call_data: { lead_id: id, lead_mob_num: mobile_num }, lead_details: { lead_id: id, lead_mob_num: mobile_num }, lead_num_verify: true }))
  }

  const { lead_num_verify, outgoing_call_data } = useSelector((state) => state.cs_soft_phone);



  return (
    <>
      <Box sx={{
        p: 2,
        height: biz_mob_num?.isModified ? "360px" : biz_mob_num?.is_rekyc ? "388px"
          : '100%'
      }}>
        <Card sx={{
          height: '100%',
          backgroundColor: "#3a4d75", borderRadius: 2, boxShadow: 4
        }}>
          <CardContent sx={{}}>
            {/* Card Header */}

            <Typography sx={{ backgroundColor: "#4b6587", fontWeight: "bold", textAlign: "center", borderRadius: 2, p: 1, color: theme.palette.text.primary }}>
              {cardHeaderText}

            </Typography>


            {/* Mobile Number Row */}
            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {!(biz_mob_num?.json_type) && !(disable_edit || mob_numbers?.verify) &&
                  <IconButton className={(disable_edit || mob_numbers?.verify) ? "disable_div" : ""} >
                    <EditIcon sx={{ fontSize: "1.3rem", color: theme.palette.text.primary }} onClick={() => { seteditOperator(true) }} />
                  </IconButton>
                }

                <img src={`/img/${biz_mob_num?.serv_prvdr?.value}_logo_color.png`} alt={biz_mob_num?.serv_prvdr?.value} width={50} />
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {biz_mob_num?.mobile_num?.value}
                </Typography>

                {!(biz_mob_num?.json_type) &&
                Config('softphone_supported_countries')?.includes(get('market').country_code)&&

                 check_priv('lead', 'verify_lead_num_call') &&
                  <IconButton
                    id="dial-icon"
                    className={`lead_call_icon ml-1 ${!lead_num_verify ? "" : 'disable_div'}`}
                    onClick={() => handleCall(biz_mob_num?.mobile_num?.value)}
                    sx={{
                      backgroundColor: 'primary.main',
                      color: 'white',
                      
                      '&:hover': {
                        backgroundColor: 'primary.dark',
                      },
                    }}
                  >
                    <CallIcon size={35} />
                  </IconButton>
                }


              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar sx={{ bgcolor: "#ffffff", color: "#3a4d75" }}>
                  <PersonIcon />
                </Avatar>
                <Typography sx={{ color: theme.palette.text.primary, textAlign: 'center' }}>
                  {biz_mob_num.section === "biz_mob_num" ? "Self" :
                    biz_mob_num?.relation?.value ? dd_value(biz_mob_num?.relation?.value) : 'NA'
                  }
                </Typography>
              </Box>
            </Box>

            {/* Name as Entered by RM */}
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2">Name as Entered by RM</Typography>
              <Box sx={{ px: 0, py: 1, borderRadius: 1, }}>
                <Typography sx={{ fontWeight: "700", color: theme.palette.text.primary }}>
                  {biz_mob_num.section === "biz_mob_num" ?
                    <b>{cust_reg_json?.id_proof?.first_name?.value ?? " "}  </b>

                    : biz_mob_num?.name?.value ?? "NA"

                  }
                </Typography>
              </Box>
            </Box>

            {/* Account Holder Name */}
            {/* {biz_mob_num.holder_name ? ( */}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
              <Box>
                <Typography variant="body2">Account Holder Name</Typography>
                <Typography
                  sx={{
                    py: 1,
                    fontWeight: 900,
                    color: theme.palette.text.primary,
                  }}
                >
                  {mob_numbers.holder_name || 'Unable to retrieve the name of the mobile number.'}
                </Typography>
                {biz_mob_num?.json_type &&
                  <Typography variant="body2" sx={{ color: '#0f0' }}> <b>Manually Verified</b></Typography>
                }

              </Box>

              {!(biz_mob_num?.json_type) &&
                <>
                  {(show_verified_flag) ? (
                    <VerifiedIcon sx={{ width: '5em', height: '2em', color: '#0f0' }} />
                  ) : (
                    (show_rejected_flag) && (
                      <HighlightOffSharpIcon sx={{ width: '5em', height: '2em', color: 'red' }} />
                    )
                  )}
                </>
              }

            </Box>

            {show_mismatch_reason && !(biz_mob_num?.json_type) && <>
              <Typography sx={{ fontWeight: 500, color: theme.palette.text.primary }}>
                {biz_mob_num.name_match ? "" : `Mismatch Reason -  ${mob_numbers.mismatch_reason ? dd_value(mob_numbers.mismatch_reason, mob_numbers.mismatch_reason) : "-"}`}
              </Typography>
            </>
            }
          </CardContent>


          {/* Buttons at Bottom */}
          {!(biz_mob_num?.json_type) &&
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", pb: 2, px: 2 }}
              className={className}
            >
              {!(mob_numbers?.hasOwnProperty('verify_identity')) &&
                <>
                  {(!mob_numbers?.verify) &&
                    <Button variant="contained" color="primary" sx={{ textTransform: "none", borderRadius: 2, width: "48%" }} onClick={handleVerifyNumber}>
                      Verify Number
                    </Button>
                  }
                  {biz_mob_num.section === "biz_mob_num" &&
                    <Button variant="contained" color="secondary"
                      className={mob_numbers?.verify ? "" : "disable_div"}
                      sx={{ textTransform: "none", borderRadius: 2, width: "48%" }} onClick={handleVerifyIdentity} >
                      Verify Identity
                    </Button>
                  }
                </>
              }
            </Box>
          }

        </Card>

      </Box>

      {/* Verify Number Modal */}
      {verifyNumberModal && (
        <CustomModal
          show={verifyNumberModal}
          onClose={() => setVerifyNumberModal(false)}
          header="Verify Name"
          body={<BizMobileVerifyModal
            biz_mob_numbers={mob_numbers}
            state_values={audit_state}
            numbers_verify={mobileNumberVerify}
            submit_holder_name_proof={submitNameProof}
            modal_close={() => setVerifyNumberModal(false)}
            audit_kyc_modify_handler={audit_kyc_modify_handler}

          />}
          size="sm"
          alignItems="center"
        />
      )}

      {verifyIdentityModal && (
        <CustomModal
          show={verifyIdentityModal}
          onClose={() => setIdentityModal(false)}
          header={`Verify - ${cardHeaderText}`}
          body={
            <BizMobileVerifyIdentity biz_mob_numbers={mob_numbers}
              audit_kyc_submit_callLog={SubmitIdentity}
              callLogRejectHandler={RejectIdentity}
              modal_close={() => setIdentityModal(false)}
            />}
          size="md"
          alignItems="center"
        />
      )}

      {editOperator && (
        <CustomModal
          show={editOperator}
          onClose={() => seteditOperator(false)}
          header={`Change Mobile Operator`}
          body={<UpdateMobileOperator
            biz_mob_num={mob_numbers}
            modal_close={() => seteditOperator(false)}
            view_lead={view_lead}

          />
          }
          size="sm"
          alignItems="center"
        />
      )}



    </>
  );
};

export default AuditKycCard;