import { Box } from '@mui/material';
import { connect } from 'react-redux';
import { setValuesIntoRedux } from '../../../Redux/Slices/CsSoftphoneSlice';
import FlowContainer from '../../common/container/core/FlowContainer';
import EnclosedTabSwitchUI from '../../CommonComponent/enclosedTabSwitchUI';
import CustFollowUp from '../component/cust_follow_up';
import CustRetain from '../component/cust_retain';

class ChrunManagementContainer extends FlowContainer {

  state = {
    active: 'to_retain',
  }

  tabCheck = (e) => {
    if(this.props.isPendingCallLog) {
      this.props.navigateToChurnRoute()
    }
    this.setState({
      ...this.state,
      active: e === 0 ? "to_retain" : "cust_follow_up",
    })
  }

  componentDidMount() {
    this.props.setValuesIntoRedux({ screen_from: "churn_manangement" })
    const isPendingCallLog = this.props?.isPendingCallLog ?? false
    const activeTab = this.props?.churnType === 'new' ? 'to_retain' : 'cust_follow_up'
    if (isPendingCallLog) {
      this.setState({ active: activeTab })
    }
  }

  render() {
    return (
      <div>
        <h4 className='text-center mt-3'> <b>Churn Management</b></h4>
        <Box display="flex" alignItems="center" justifyContent='center'>
          <EnclosedTabSwitchUI
            viewOverRideStyles={{
              "& button": { minHeight: "auto" },
              "& .MuiTabs-indicator": {
                height: "100% !important",
                backgroundColor: "#3149a1",
              },
              "& .MuiButtonBase-root": {
                zIndex: 1,
                width: '300px'
              },
              "& .MuiTabs-root": {
                minHeight: "35px",
                border: "solid 2px #3149a1",
                borderRadius: "5px",
                marginY: 2,
              },
            }}
            tab={this.state.active === 'to_retain' ? 0 : 1}
            setTab={this.tabCheck}
            tabItems={[{ label: "New Churns", icon: <></> }, { label: "Past Churns", icon: <></> }]}
            activeColor="#fff"
          />
        </Box>
        {(this.state.active == 'to_retain') &&
          <CustRetain tab={this.state.active} ComponentChange={this.handleComponentChange} {...this.props} />
        }
        {(this.state.active == 'cust_follow_up') &&
          <CustFollowUp tab={this.state.active} onComponentChange={this.handleComponentChange} {...this.props} />
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    softPhoneState: state.cs_soft_phone,

  };
};

const mapDispatchToProps = {
  setValuesIntoRedux

};


export default connect(mapStateToProps, mapDispatchToProps)(ChrunManagementContainer);






