import React from 'react';
import {getChurnById, getCustToRetain} from '../../../actions/churn_actions'; 
import {format_date,Homeacc_prvdrLogoselector,person_name} from '../../../helpers/common_helper';
import {dd_value} from '../../../actions/common_actions';
import { Link} from 'react-router-dom';
import _ from 'lodash';
import FlowContainer from '../../common/container/core/FlowContainer';
import CommonReactTable from '../../../helpers/commonReact_table';
import {TooltipLabel} from "../../common/partial";
import {check_priv } from '../../../helpers/storage_helper';
import { sortBizcommon,sortAmtcommon, sortTimecommon} from '../../../helpers/sortingHelpers';
import { custFollowUpTask,getChurnCallLog } from '../../../actions/churn_actions';
import moment from 'moment';
import { getHolidayList } from '../../../actions/lead_actions';
import { Modal } from 'react-bootstrap';
import { FcCalendar } from 'react-icons/fc';
import { Config } from '../../../helpers/storage_helper';
import FlowComponent from '../../common/component/core/FlowComponent';
import { lbl_date, title_case ,RoundOffSplitter,lbl_amt} from '../../../helpers/common_helper';
import { AiFillDollarCircle } from "react-icons/ai";
import { BiCalendar } from "react-icons/bi";
import {BsClockFill} from "react-icons/bs";
import {get} from '../../../helpers/storage_helper';
import Select from "react-select";
import { BasicReactTable } from '../../../helpers/react_table';
import { connect } from 'react-redux';
import Softphone from '../../cs_management/container/cs_soft_phone';
import SoftphoneCallSections from '../../cs_management/container/SoftphoneCallSections';
import ConfirmModal from '../../common/component/ConfirmModal';
import withRouter from '../../../Router/Withrouter';
import { setValuesIntoRedux } from '../../../Redux/Slices/CsSoftphoneSlice';
import { custDetails } from '../../../actions/cs_management_actions';
import { CHURN_CALL_LOG_NEW_MODAL } from '../../../context/softPhoneCtx/softPhoneCtx';

class CustRetain extends FlowComponent {
    constructor(props) {
        super(props);
        this. state ={
            cust_wo_fa :{
            },
            columns :[],
            cust_wo_fa_list:[],
            cust_wo_fa_table:"hide_div",
            copy:[],
            cust_follow_up:'',
            reason:'',
            remarks: '',
            next_follow_up_date:'',
            max_follow_up_days:'',
            market_holidays:[],
            resp_day_for_reason:'',
            customer_intent_datas:[
            {title:"Customer ID",key:'cust_id'},
            {title:"RM Name",key:'flow_rel_mgr_name'},
            {title:"Business Name",key:'biz_name'},
            {title:"Location",key:'location'},
            {title:"Mobile Number",key:'mobile_num'},
            {title:"Total FA(s)",key:'tot_loans'},
            {title:"Registration Date",key:'reg_date'},
            {title:"Last FA Paid",key:'last_fa_paid_date'},
            {title:"Status",key:'cust_status'},
            {title:"Reason",key:'cust_status'}
        ],
            las_fa_paid_options:[
           
            {value:'30',label:'30 days'},
            {value:'45',label:'45 days'},
            {value:'60',label:'60 days'},
            {value:'90',label:'90 days'}]
        ,refresh:false,
        last_fa_paid:30,
        remarksArr:[],
        fetching_churn_call_log: false
        }
        this.channel = new BroadcastChannel('cs_soft_phone')
        this.softphone_is_supported
    }
   

    refreshList = () => {		
		this.setState({refresh: true})
        this.loadtable()  
       
	}

    componentDidMount(prevState,prevProps) {
        console.log(this.props, 'this.props is here to checking')
        this.refreshInterval = setInterval(() => {
          this.refreshList(); 
        }, 300000); 
        this.softphone_is_supported =  check_priv('home', 'soft_phone')  && this.props.softPhoneState.is_softphone_supported

        
      }
    
      componentWillUnmount() {
        clearInterval(this.refreshInterval);
      }

    componentDidUpdate(prevProps,prevState){
        if(prevState.resp_day_for_reason!=this.state.resp_day_for_reason){
            if(this.state.resp_day_for_reason=='NA'){
                this.setState({next_follow_up_date:null})
            }
        }
    }
    sortBiz (a,b) {				        		
		return sortBizcommon(a.props.full_text,b.props.full_text)        
	}
	sortAmount (a,b) { 		   
        return sortAmtcommon(a,b)
    }
	sortTime(a,b){
        const currentTime = a.props.data.loan_appl_date
        const prevTime = b.props.data.loan_appl_date
        const timeServer = this.state.server_time        
        return sortTimecommon(currentTime,prevTime,timeServer)		
    }
    
    async handleCustFollowUp(e){
        const { hasAnyPrevPendCallLog, callLog } = await this.props.softPhoneCtxMethods.checkUserHasAnyPendingCallLog({screen: ""})
        if(hasAnyPrevPendCallLog && this.state.using_physicalphone && this.softphone_is_supported) { // user has pending log, and wrongly select physical phone
            alert("Your previous call was not initiated by physical phone")
            window.location.reload()
            return;
        }
        if(!hasAnyPrevPendCallLog && !this.state.using_physicalphone && this.softphone_is_supported) { // user don't have any pending log, and wrongly select soft phone
            alert("Your previous call was not initiated by soft phone")
            window.location.reload()
            return;
        }
        // if(hasAnyPrevPendCallLog) {
            let soft_phone_priv = this.softphone_is_supported
            if(!window.confirm("Are you sure want to Follow up this customer "+" ?")) return;
            var request={
                type:'new_churn',
                reason:this.state.reason,
                cust_id:this.state.cust_follow_up.cust_id,
                next_follow_up_date:this.state.next_follow_up_date,
                last_loan_doc_id : this.state.cust_follow_up.last_loan_doc_id,
                remarks:this.state.remarks,
                status: 'in_progress',
                id:this.state.new_churn_row_data.id,

            }
            if(soft_phone_priv && (!this.state.using_physicalphone )){
                // request.call_log_id = this.props.softPhoneState.call_log_details.call_log_id ? this.props.softPhoneState.call_log_details.call_log_id :null
                request.call_log_id = callLog.call_log_id
            }
            if(soft_phone_priv && (this.state.using_physicalphone )){
                request.physical_phone = true
            }

            custFollowUpTask(this.req(request))
                .then((response)=>{
                    this.resp(response)
                    if(!response)return;
                    if(response){
                        
                        this.setState({reason:'',next_follow_up_date:null, remarks: ''})
                        alert(response.message)
                        
                            if(soft_phone_priv && !this.state.using_physicalphone  ){
                            this.props.setValuesIntoRedux({ call_log_details: null,CallLogModal:false, ModalIsOpen: false,incoming_call_details:[],incomingcall_num:null,callNum:null ,pending_rms_details :null,pending_cust_details:null})

                            this.channel.postMessage({message:"submit_call_log", data:{call_log_details: null,CallLogModal:false, ModalIsOpen: false,incoming_call_details:[],incomingcall_num:null,callNum:null ,pending_rms_details :null,pending_cust_details:null}})
                            
                        }
                        if(e=="apply_fa"){
                            // window.location.replace(
                            //   `fa_appl_for/${this.state.cust_follow_up.cust_id}`,
                            // );
                            this.props.navigate(`/fa_appl_for/${this.state.cust_follow_up.cust_id}`)
                            }
                        else{
                            this.refreshList()
                        }
                            
                        this.setState({cust_follow_up_modal:false,reason:'',next_follow_up_date:null,setCallTypeConfirm:false})
                        if(e !== "apply_fa") { // redirect to it's own route this handler is not for apply_fa reason case
                            this.props.navigateToChurnRoute()
                        }
                        this.props.softPhoneAndFreshChatCtxMethods.setCustomerDetails({screen: '', custId: '', isCustomer: false, mobileNum: '', churnId: "", deliquencyId: ""})
                        this.props.softPhoneAndFreshChatCtxMethods.closeFloatBtnUIAndFloatModal()
                    }
                })
            // } 
            // else {
            //     alert("Unable to update the call log")
            // }
        }

        
        
    changeDaysForReason=(e)=>{
    
        var reason_with_day = Config('follow_up_intervals').second_follow_up
        this.setState({resp_day_for_reason:reason_with_day[this.state.reason]})
        this.minDateAfter(reason_with_day[this.state.reason])
    }
    minDateAfter(number) {
        if(Number.isInteger(number)){
            const dates = [];
            const today = moment();
            var i=0;
            while(dates.length<=number) {
              const nextDate = moment(today).add(i, 'days');
              if (nextDate.day() !== 0 &&  !this.state.market_holidays.includes(nextDate.format('YYYY-MM-DD'))) {
                dates.push(moment(nextDate).format('YYYY-MM-DD'));
              }
              i++
            }
            this.setState({min_follow_up_date:dates[dates.length-1]},()=>
            this.setState({next_follow_up_date:moment(this.state.min_follow_up_date). format('YYYY-MM-DD')}))
        }
        else{
            this.setState({min_follow_up_date:false})
        }
      }
    changeHandler = (e) => {      
        const toFilter = e
        const filterfrom = this.state.cust_wo_fa_list    
        const filteredData = filterfrom.filter(value => { 
          return(    
          value.cust_id !== null && value.cust_id.toLowerCase().includes(toFilter.toLowerCase())     
          )
        })
        this.setState(state=>state.copy = filteredData)		
      }
        get_holidays=()=>{
            var request={
                days:60
            }
            getHolidayList(this.req(request))
            .then((response)=>{	
                if(!response){return};
                if(response.status == "success"){
                    this.setState({market_holidays:response.data.market_holidays})
                }
                })
        }

    componentWillMount(){
         this.loadtable()     
         if(this.props.isPendingCallLog) { // if the screen for log the pending call, that means in router we will have churn, and churn_id keys
            getChurnById({churn_id: +this.props.churnId}).then(r => this.cust_follow_up_handler(r?.data))
         }
                    
    }
    sortAmount (a,b) { 		
        console.log('a,b', a,b)   
        return sortAmtcommon(a,b)
    }
    loadtable(){
        this.get_holidays()
        this.setState({loader:true,copy:[],no_data:false})
        var request={borrower_search_criteria : { not_have_ongoing_loan : 'true', not_have_overdue_loan : 'true', not_have_pending_loan_appl : 'true', profile_status : 'open', status : 'enabled',cust_follow_up:'false', churn : true, churn_days : 30}}
		if(this.state.last_fa_paid){
            request["last_fa_paid_days"]=this.state.last_fa_paid
        }
        getCustToRetain(this.req(request))
		.then((response) => {
			this.resp(response)
			if(!response){return };this.setState({loader:false,no_data:true})
			if(response.status === "success"){
				this.resp(response)
                this.setState({refresh: false})

				var columns = this.state.columns
                const show_risk_category = check_priv('customer' , 'risk_category');
                columns = [
                
                {
                    Header : "Account Number",
                    id: "acc_prvdr_code",
                    sortable:false,
                    accessor:row => <div className='d-flex align-items-center' style={{paddingLeft:'20px'}}> <Homeacc_prvdrLogoselector logotype={row.acc_prvdr_code}/> <p className='no-margin' style={{paddingLeft:'20px'}}> {row.acc_number}</p>  </div> ,
                    width : 250
                   

                },
                {
                    Header : "Customer ID",
                    id: "cust_id",
                    sortable:false,
                    accessor:row => <Link to={"/borrower/indiv/view/"+row.cust_id} target="_blank" className="flow_custid" >{row.cust_id}</Link>,
                    width : 150,
                    style : {textAlign:"center"} ,
                    width : 200
                },
                  
               
                {
                    Header : "Business Name",
                    id: "biz_name",
                    sortable:false,

                    accessor:row => <TooltipLabel  no_reduce={true} full_text={row.biz_name} overlay={person_name(row)}  style={{width:'800px'}}/>,
                    style : {textAlign:"center"} ,
                    width : 350
                },
                {
                    Header: "Mobile Number",
                    id: "mobile_num",
                    accessor:row=>row.mobile_num,
                    Cell: (cell) =>(<p className='cust_follow_text' style={{textAlign:"center"}}>{title_case(cell.value)}</p>),
                    width : 200
                
        
                },
               
                {
                    Header : "Last FA Repayment Amount",
                    id: 'last_fa',
                    sortable:true,
                    style : {textAlign:"center"} ,
                    width : 250,
                    sortMethod : (a,b) => this.sortAmount(a,b),	        			
                    accessor:row => lbl_amt(row.last_loan_amount,get('market').currency_code),
                },
                {
                    Header : "Last FA Paid Date",
                    id: "last_fa_paid_diff",
                    accessor:row => row.last_fa_paid_diff +" ago",    
                    style : {textAlign:"center"} ,
                    sortMethod : (a,b) => this.sortAmount(a,b),	   
                    
                },
                {
                    Header : "Action",
                    id: "action",
                    accessor:row => {return <button className='btn product_takeup_submit px-3 py-2 text-white' onClick={()=>this.cust_follow_up_handler(row) } >Call Log</button>},
                    style : {textAlign:"center"} 
                    
                },
            ]
            }
            this.setState({columns,cust_wo_fa_list:response.data,copy :response.data,cust_wo_fa_table:"show_div",loader:false,no_data:false})
            if(response.data.length<=0){
                this.setState({no_data:true})
            }
        })
           
    }
    handleClose=async()=>{
        // const { hasAnyPrevPendCallLog, callLog } = await this.props.softPhoneCtxMethods.checkUserHasAnyPendingCallLog({screen: ""})
        // if(hasAnyPrevPendCallLog && callLog.screen === CHURN_CALL_LOG_NEW_MODAL) {
        //     alert("Unable to close the modal before call log!")
        // } else {
            this.props.softPhoneAndFreshChatCtxMethods.setCustomerDetails({screen: '', custId: '', isCustomer: false, mobileNum: '', churnId: "", deliquencyId: ""})
            this.props.softPhoneAndFreshChatCtxMethods.closeFloatBtnUIAndFloatModal()
            this.setState({cust_follow_up_modal:false,reason:null,setCallTypeConfirm:false})
            this.props.setValuesIntoRedux({show_nums:false,cust_id:null})
        // }
    }

    handlePurposeChange(value,type){
        this.setState({[type]:value},()=>{this.loadtable()})
    }
   
    cust_follow_up_handler = (data) => {
        this.setState({fetching_churn_call_log: true})
        var req={"cust_id":data.cust_id,"type":"new_churn","id":data.id}
        getChurnCallLog(this.req(req))
        .then((response)=>{
            if(!response){return}
            if(response.status=="success"){
                this.props.setValuesIntoRedux({cust_id:data.cust_id})
                
                this.setState({setCallTypeConfirm:true, cust_follow_up:response.data.borr_details,new_churn_row_data:data,previous_fa_datas:response.data.last_five_fas})

            }
        })
        this.setState({fetching_churn_call_log: false})
    }

    handleRemarks = (value,type) =>{
        const myArray = value.split(" ");
        const nonEmptyRemarks = myArray.filter((remark) => remark !== "");

        this.setState({remarks : value,remarksArr:nonEmptyRemarks});
      }

    checkDisableStatus = () =>{

        if(this.softphone_is_supported && !this.state.using_physicalphone){
            var a  = false
            if(this.props.softPhoneState.call_log_details && this.props.softPhoneState.call_log_details.call_log_id ){
               a = true
            }
            return a
        }else{
            return true
        }

      
    }

    toggle_modal(type){
        if(type=="yes"){
            this.setState({using_physicalphone:true,cust_follow_up_modal:true})    
            
        } else if(type =="no"){
            // this will set the customer id and mob number in the softphone and float btns UI ctx
            this.props.softPhoneAndFreshChatCtxMethods.setCustomerDetails({screen: CHURN_CALL_LOG_NEW_MODAL, custId: this.state.new_churn_row_data.cust_id,isCustomer: true, mobileNum: this.state.new_churn_row_data.mobile_num, churnId: this.state.new_churn_row_data.id, deliquencyId: ""})
            this.setState({using_physicalphone:false,cust_follow_up_modal:true})
           
        } else if(type === "close") {
            this.props.softPhoneAndFreshChatCtxMethods.setCustomerDetails({screen: '', custId: '', isCustomer: false, mobileNum: '', churnId: "", deliquencyId: ""})
            this.props.softPhoneAndFreshChatCtxMethods.closeFloatBtnUIAndFloatModal()
        }
        this.setState({setCallTypeConfirm:false})


    }

    render(){
        var submit_btn_status = (this.state.reason && this.state.remarks &&(this.state.remarksArr.length >= 3))
       const softphone_is_supported = check_priv('home', 'soft_phone')  && this.props.softPhoneState.is_softphone_supported
        return(

            
			<div className='container max-width no-padding cust_follow_up'>

            <div className='d-flex align-items-center mt-4 justify-content-center row'>
                <div className='d-flex align-items-center col-lg-3 col-md-12 justify-content-center row px-0 py-0'>

                    <div className='d-flex align-items-center col-2row justify-content-center'>
                        <label className='col-8 p-0 m-0'>Last FA Paid :</label>
                        <div className='col-12 pl-0 date_select'>
                            <Select
                                onChange={(e) => { this.handlePurposeChange(e.value, "last_fa_paid"); }}
                                value={this.state.select_value}
                                options={this.state.las_fa_paid_options}
                                placeholder="Days"
                                styles={this.select_custom_styles()}
                                classNamePrefix="my-select" // Add a custom class prefix to target the components
                            />
                        </div>
                    </div>
                </div>
            </div>


                {/* modal */}
                {this.state.cust_follow_up&&
                <Modal
                show={this.state.cust_follow_up_modal && !this.props.softPhoneCtxState.showCallLogModal} onHide={this.handleClose} 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop={'static'}
                >
                <Modal.Header closeButton className='p-0' style={{background:'#31364a'}}>
                <h4 class="modal-title py-2" id="exampleModalLabel">Customer Intent</h4>
                       
                </Modal.Header>
                <Modal.Body className='pt-0'>
                <div className={`card p-3 `}>
                    <div >
                    <div className='modal_scroll_style'>
                    <div className='row mx-0 '>

                    <fieldset className='border border-white p-2 my-2 col-md-12' style={{borderRadius:'10px'}}>
                        <legend className='w-auto text-light ml-4 px-2'>{this.state.cust_follow_up.owner_name}</legend>

                        <div className='row m-0 d-flex justify-content-between'>
                        {this.state.customer_intent_datas.map((item,idx)=>{return(
                            
                            <div className='col-lg-6 px-4 py-3 col-md-6 px-0'>
                                    <div className='d-flex align-items-center'>
                                        <label className=" p-0 m-0 personalDetailsviewText" >{item.key=='cust_status' && this.state.cust_follow_up.cust_status.status =='enabled' ?'': <b>{item.title}</b>}</label>
                                        <label className=" pb-0 m-0 pl-3 cust_follow_up_font" style={{fontSize:'12px'}}>{(item.key=="reg_date")?format_date(this.state.cust_follow_up[item.key]) :
                                        <>
                                        {item.key=="cust_status" ?
                                        this.state.cust_follow_up.cust_status.status != 'enabled'?
                                        <>{(item.title=="Status")? dd_value(this.state.cust_follow_up[item.key].status): dd_value(this.state.cust_follow_up[item.key].reason[0])}</>:''
                                        :
                                        <>
                                        {
                                        
                                        (item.key=="last_fa_paid_date")?  <>{this.state.cust_follow_up["last_fa_paid_diff"]} ago </> : <>{(item.key=="location" )? dd_value(this.state.cust_follow_up[item.key]):this.state.cust_follow_up[item.key]}</>
                                        }
                                        </>
                                        }
                                        </>
                                        }</label>  
                                    </div>  
                            </div> 
                        
                        )})}
                        </div>

                    </fieldset>


                   

                    </div>

                    <div className='row mx-0 my-3 mt-4'>
                        {this.state.previous_fa_datas &&
                        <fieldset className={`border border-white p-2 my-2 col-md-12 ` } style={{borderRadius:'10px'}}>
                            <legend className='w-auto text-light ml-4 px-3'> Previous FAs Details <span className='ml-1' style={{fontSize:'12px'}}> (Overall Performance : {this.state.cust_follow_up.cust_perff} %) </span></legend>
                        
                            <div className={`row mx-0 mt-3 mb-1 d-flex  ${this.state.previous_fa_datas.length >3 ? "justify-content-center " :"justify-content-center"}`}>
                            { this.state.previous_fa_datas.map((item,idx)=>{return(
                                
                                <div className={`  col-md-4 ${this.state.previous_fa_datas.length>3 ? " col-lg-4 mb-4" :"col-lg-4  "}`}>
                                        <div className='p-3 previous_fa_details_card'>
                                            
                                            <p className=" p-0 m-0 text-right " style={{fontSize:'16px'}} ><b> #{idx+1} </b></p>
                                            <div className='d-flex align-items-center' >
                                                <p className='m-0'><AiFillDollarCircle size={20}/></p>
                                                <span className='text-white pl-3 py-0 m-0 sizsm'  >{ lbl_amt(item.loan_principal,get('market').currency_code) } </span>
                                            </div>
                                            <div className='d-flex align-items-center my-3' >
                                                <p className='m-0'><BiCalendar size={20}/></p>
                                                <label className='text-white pl-3 sizsm m-0'  >{item.due_date?format_date(item.due_date):''}</label>
                                            </div>

                                            <div className='d-flex align-items-center my-3' >
                                                <p className='m-0'><BsClockFill color={(item.delay_days=="0 days")?"#088b00":''} size={17}/></p>
                                                <label className='text-white pl-3 sizsm m-0'  > {(item.delay_days=="0 days" )? <span style={{color:`#088b00`}}> <b>Paid Ontime</b></span>: <> Delayed : {item.delay_days}</>} </label>
                                            </div>
                                            
                                        </div>  
                                </div> 
                            
                            )})}
                            </div>

                        </fieldset>
                        }
                    </div>

                    <div className='col-md-12 pt-3 px-0'>
                    <fieldset className='border border-light p-2 my-2' style={{borderRadius:'10px',minHeight:`${this.state.reason ? "191px" :"66px"}` }}>
                        <div className='row m-0 p-0 w-100'>
                            <div className='col-md-12 d-flex align-items-center'>
                                <label className='col-2 pl-4 pr-0 font mb-0 mt-2' style={{fontSize:"14px"}}>Reason <span className='product_red_star'>* </span> :</label>
                                <div className='w-100 col-10 m-0 pr-5 pl-0'>
                                    <select className='form-control' onChange={(e)=>this.setState({reason:e.currentTarget.value,next_follow_up_date:null,min_follow_up_date:null},this.changeDaysForReason)}>       
                                    {this.getSelectOptions('cust_follow_up_reasons')}
                                    </select>  
                                </div>
                            </div>
                        </div>
                            <div className='row m-0 p-0 w-100'>
                                {this.state.reason ?
                            <div className='col-md-12 px-3 mt-3'>
                                <label className='col-12  font mb-0 mt-4 new_churn_remarks_text' >Remarks <span className='product_red_star'>*</span> </label>
                                <div className='w-100 col-12 m-0 p-0'>
                                <div className="col-md-12 p-0">
                                <div className='row m-0 d-flex align-items-center'>
                                <div class=" col-lg-11 col-md-11 pr-0  pl-5" >
                                <textarea class=" col-lg-12 col-md-11 form-control " id="exampleFormControlTextarea1"   onChange={(e)=> this.handleRemarks(e.target.value,"remarks")}  maxlength="150"  ></textarea>
                               </div>
                                { ( this.state.remarks && this.state.remarksArr && this.state.remarksArr.length >= 3)&&
                                <p className='col-1 p-0 mb-0 pointer d-flex justify-content-end pr-4' style={{fontSize:'15px'}}>
                                <RoundOffSplitter className="biz_operation_font_size" font_color={true} val={`${this.state.remarks } `} style={{color:'white'}} unit={""} align_center={true} position={"top"} notify={true} />
                                </p>
                                }
                                </div>
                                <p className='text-white text-right m-0 pr-4'> {this.state.remarks ? this.state.remarks.length :"0"}/150</p>
                                {( this.state.remarks &&this.state.remarksArr&& this.state.remarksArr.length < 3)&&
                                    <p className='m-0 pl-5 churn_alert_text' >Please include more than 3 words in your remark for clarity</p>

                                }
                                <p className='churn_alert_text' >{ (this.state.remarks && this.state.remarks.length==150)?"You have reached your character limit":"" }</p>
                                </div>
                                </div>
                            </div>:""}
                          

                        
                    </div>

                   
                    </fieldset>
                    </div>
                 
                    <div className='mt-4 col d-flex w-100 justify-content-center'>
                    {this.state.reason=="apply_fa" ?
                    <button className='btn btn-success product_takeup_submit ml-3'  disabled={(this.state.reason && this.state.remarks )?false:true} onClick={()=>this.handleCustFollowUp("apply_fa")}  >APPLY FA</button>
                    : 
                    <button className='btn product_takeup_submit' onClick={()=>this.handleCustFollowUp()} disabled={submit_btn_status?false:true} >Submit</button>

                    }

                    </div>
                    </div>
                    </div>
                </div>

                </Modal.Body>
               
                </Modal>
                } 
			<div>     
              {this.state.refresh ? "":
                <>

{/* <div className='d-flex align-items-center col-3 row '>
                        <label className='col-3 p-0 m-0'> Last FA Paid :</label>
                        <div className='col-8 pl-0'>
                        <Select onChange={(e) =>{this.handlePurposeChange(e.value,"last_fa_paid");}}
                              value = {this.state.select_value}
                              options={this.state.las_fa_paid_options} 
							  placeholder="Days"
							  styles={this.select_custom_styles()}
							  classNamePrefix="my-select" // Add a custom class prefix to target the components
							  />
                        </div>
                       </div> */}
				{this.state.cust_wo_fa_list&&this.state.cust_wo_fa_list.length > 0 &&
					<div className={this.state.cust_follow_up_modal || this.state.fetching_churn_call_log ? "disable_div":""}>
						
                        {/* {this.state.copy.length>0&&
                        <BasicReactTable  row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns}  pagination={this.state.copy.length>9? true:false} default_page_size = {15}  defaultSorted={[{id:"name",desc:false}]}   searchData={[{title:'Active Customers without FA'}]} />  

						
                        } */}
                        <div className='d-flex align-items-center d-flex justify-content-between row mx-0 mb-3'>
                        <p className='m-0 text-white pl-3'> <b>Total Entries :{this.state.copy.length >0 ? this.state.copy.length :"0"} </b></p>
                       
                        </div>
                        <div className='cust_next_follow_up' style={{marginTop:5}}>
                        <BasicReactTable csv_file={true} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} FName={"Churn Management - New Churns"}  pagination={this.state.copy.length>9? true:false} default_page_size = {10}  defaultSorted={[{id:"last_fa_paid_diff",desc:true}]} header_class='table_background_header'  searchData={[{...this.state.copy,title:'Churn Management'}]} />  

                        {/* <CommonReactTable className='border' exportCSV={true} csvFile={"Churn Management - New Churns"} csvData={this.state.copy} row_data={this.state.copy}  column_data={this.state.columns}  FName="Customer Follow Up" global_search={false} showPag={true} minRow={0} default_page_size={10} defaultSorted={[{id:"last_fa_paid_diff",desc:true}]} resizable={false} background_header={true} searchData={[{...this.state.copy,title:'Churn Management'}]}/>  */}
                        </div>
             
                    </div>
				}

                {this.state.no_data&&<h6 className='text-center text-light p-5 m-5'>No Data Found</h6>}
                {this.state.copy&&this.state.copy.length<=0&&this.state.loader&&
                <>
              
                <>
                    <p className='text-light  text-center' style={{marginTop:'35vh'}}>Loading...</p>
                    <div className="product_takeup_loader"></div>
                    </>
                
                </>
                }
                </>}
			</div>

                {this.state.setCallTypeConfirm &&
                  <ConfirmModal alert_message={"Please note that this activity will be logged.\nKindly confirm the device you used to initiate the call?"} is_toggle={(a) => { this.toggle_modal(a) }} 
                  SoftphoneCallOptions= {true}/>
                
                }
             {/* {console.log('this.softphone_is_supported', softphone_is_supported)}
            {this.props.softPhoneState.softPhone && (!this.props.softPhoneState.missed_calls_view)   && (!this.props.softPhoneState.isIncomingCall) && (!this.props.softPhoneState.outgoingCall) && ( this.state.cust_follow_up_modal && this.props.softPhoneState.show_nums) &&( softphone_is_supported && !this.state.using_physicalphone ) &&

				<div className='Call_log_section zoomIn_animation'>
					<Softphone screen_from ={"churn_management"} cust_id={this.state.new_churn_row_data?this.state.new_churn_row_data.cust_id:null}/>
             
				</div>


			} */}
		  </div>

			 )
    }

}

const mapStateToProps = (state) => {
	return {
	  softPhoneState: state.cs_soft_phone,

	};
  };

const mapDispatchToProps ={
    setValuesIntoRedux
  
  };


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustRetain));
