import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import { AiFillEdit} from "react-icons/ai";


import FileUpload from '../../common/component/FileUpload';
import {get, Config} from '../../../helpers/storage_helper';
import {capitalize_first_letter,getLabel,getValueFromLangJson,get_dir_path, pdf_viewer, req} from '../../../helpers/common_helper';
import DisplayImage from '../../common/component/DisplayImage';
import { approveKYC,updateLeadStatus} from '../../../actions/kyc_actions';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import isValid from 'date-fns/fp/isValid';
import {dd_value} from '../../../actions/common_actions';
import moment from 'moment';
import PDFtoImage from '../component/Pdf_to_image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { connect } from 'react-redux';
import { updateValuesIntoRedux } from '../../../Redux/Slices/CommonSlice';
import UpdateMobileOperator from '../component/UpdateMobileOperator';
import withRouter from '../../../Router/Withrouter';
import CustomModal from '../../../MUI/Components/CustomModal';


class AuditKYCAgreementsVerification extends FlowComponent{
    
      constructor() {
        super();
        this.state={
          cust_reg_json:'',
          view_pdf:false,
          pdf_view_error:true,
          pdfMOdal:false,
          biz_aggr :{}

        }
        
      
    }
    componentWillMount(){
      this.setState({ rm_label_short : getValueFromLangJson("rm_label_short")})
    }
    

    componentDidUpdate(prevProps, prevState, snapshot){
      if(this.props.agreements_data.agreements_verify_status &&( this.state.cust_reg_json!=prevProps.agreements_data.cust_reg_json.agreements)){
        
        return this.setState({cust_reg_json:prevProps.agreements_data.cust_reg_json.agreements})
    
      }
     if(this.props.agreements_data && (this.state.view_pdf==false)){
      this.setState({view_pdf:true})
     }
    }
    pdf_viewer(link){

    const url = link;
    fetch(url, { method: 'HEAD' })
    .then(response => {
    const contentType = response.headers.get('Content-Type');

    if (contentType === 'application/pdf') {
      this.setState({pdf_view_error:true})
      console.log('The URL points to a PDF file.');
    } else {
      this.setState({pdf_view_error:false})

      console.log('The URL does not point to a PDF file.');
    }
  })
  .catch(error => {
    console.log('An error occurred:', error);
    this.setState({pdf_view_error:false})

  });    
    }
    
    handleModalClose(type){
      this.setState({pdfMOdal: type=="open" ? true :false})
    }

    


    Pdf_modal(props) {


      return (
        <>
  
          <div  className="w-100 h-100 p-0  " style={{position:'relative'}}  >
            {props.pdf_view_error ?                     
              <object id="pdf-object" type='application/pdf' className='bg-secondary' data={`${props.modal_title}?#toolbar=0&navpanes=0&page=1`}  width={'100%'} height={'100%'} allowtransparency='true' onLoad={()=>props.pdf_viewer(`${props.root}${props.modal_title}`)}>  
              {/* {props.device&&<a href={`${props.root}${props.modal_title}`} className='btn btn-primary w-100 d-block'>View Pdf </a>} */}
              {props.device&& <PDFtoImage pdfUrl={`${props.root}${props.modal_title}`}/>}
              <p className='text-center'>NO DATA</p>
             </object> 
             :
              <div class="d-flex align-items-center">
              <p className='text-center float_acc_balance_view_bttn' >NO DATA</p>

            </div> }
             
          </div>
          <div className={`w-100 h-100 p-0 ${props.device?"":'position_ab'}`} style={{top:0}}  ></div>
        
          <Modal show={props.pdfMOdal && props.pdf_view_error} onHide={()=>props.handleModalClose("close")} size = 'lg' >
                     <div className="row d-flex justify-content-center align-items-center ml-0">
                     <h5 className="modal-title text-center mb-0 mt-5 " id="">{props.title}</h5>

                    </div>
                    <Modal.Body >
                    <div className="modal-body py-0" >
                <object type='application/pdf' className='forpdf'  data={`${props.root}${props.modal_title}?#toolbar=0&navpanes=0&page=1`} width={'100%'} height={'485vh'} allowtransparency='true'>
                {props.device&& <PDFtoImage pdfUrl={`${props.root}${props.modal_title}`}/>}
                </object>
                </div>
                <div className="modal-footer d-flex">
                <a href={`${props.root}${props.modal_title}`} className='btn btn-success btn-sm my-0 mx-3' target="_blank">Download Pdf</a>
                  <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal" onClick={()=>props.handleModalClose("close")}>Close</button>
                  
                </div>
                    </Modal.Body>
            </Modal>
        </>
      );
    }

    audit_section_spinner(){
        return(
          <div class="audit_spinner" style={{margin:'20vh 0px  30vh 45%'}}>
          <span class="level level-1"></span>
          <span class="level level-2"></span>
          <span class="level level-3"></span>
          <span class="level level-4"></span>
          <span class="level level-5"></span>
          <span class="level level-6"></span>
        </div>
        )
      }

      setAuditLeadState = (event) => {
        this.setChangeToState(event)
       }
    
       checkbox_spinner(){
        return(
          <div class="d-flex col-lg-10 py-2 px-5 mt-4 justify-content-center ml-5">
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
         </div>
        )
      }


      onCreateUpload = (fileData) => {
        this.setState(prevState => ({
          biz_aggr: { 
                ...prevState.biz_aggr, 
                holder_name_proof_path: fileData?.target?.f_name 
            }
        }));
       };
    
    acc_holder_name = (value, type, idx = null, item = null) => {
        
      if(value !== "retrieved_franchise_name" && type === "mismatch_reason"  && value){
        this.setState({is_edit:false})
        this.props.updateValuesIntoRedux({'agr_reason_editable':false})

      }
        this.setState(prevState => {
            let biz_agreements = { ...prevState.biz_aggr };
            if (type === "mismatch_reason" && value !== 'retrieved_franchise_name') {
                Object.assign(biz_agreements, {
                    [`${type}_${idx}`]: value,
                    mismatch_reason: value,
                    acc_number: item?.witness_info?.mobile_num?.value,
                    acc_type: `witness_info_${idx}`
                });
                this.props.verify_handler("witness_info", biz_agreements);
            } else {
              biz_agreements[type] = value;
            }
    
            return { biz_aggr: biz_agreements };
        });
    };
    
    upload_file = async (idx, item) => {
        const biz_agreements = {
            holder_name : this.state.biz_aggr.holder_name,
            category: `witness_num_${idx}`,
            index: idx,
            mobile_num: item.witness_info.mobile_num,
            holder_name_proof_path : this.state.biz_aggr.holder_name_proof_path

        };
        
        await this.props.file_upload_submit_handler(biz_agreements);
        this.setState({ biz_aggr: {} });
    };


    is_disable (){
      let value =(this.state.biz_aggr?.holder_name_proof_path && this.state.biz_aggr?.holder_name) 
      return !value
    }

    handleEdit = () =>{
   
     let aggr = {...this.state.biz_aggr}
      if( this.state.biz_aggr.mismatch_reason!=="retrieved_franchise_name"){
        aggr['mismatch_reason'] = ''
        this.setState({ biz_aggr:aggr})
      }

      this.setState({is_edit:true })
      this.props.updateValuesIntoRedux({'agr_reason_editable':true})
    }




    
      
    render(){
      const { active_section, responseData } = this.props.agreements_data;

      let address_fields =  Config('addr_hiearchy')?.[get('market').country_code]??[]
      const audit_kyc =  this.props.audit_kyc  && this.props.audit_kyc['agreements']
      const show_witness_sign = responseData?.cust_reg_json?.show_witness_sign
      const signature_ref_path =  responseData?.cust_reg_json.agreements[0]?.signature_ref_path??null
           
      let className =  (active_section[0].verify || active_section[0].to_reassign === true ||  active_section[0].pending_with === "rm") || (responseData.audited_by != get("user_id") || responseData.audited_by == null)
        ? "disable_div" : "";
        
      let is_disable = !(this.state.biz_aggr.holder_name && this.state.biz_aggr.holder_name_proof_path)
      return(
            <div>
      
           <>{this.props.agreements_data.View_lead_section_loader ? this.audit_section_spinner():
             
             <div className='row no-margin justify-content-center'>
             <div className='col-lg-12 py-3 mt-5 ' style={{background:' #01062c'}}>
               <h2 className='text-white text-center'>
               <b> {this.props.agreements_data.cust_reg_json.same_as_owner_person ? "8" :"9" } - Agreement Verification</b> 
   
              
               </h2>
               <h2 className=' d-flex mt-1 text-center justify-content-center align-items-center' ><img src='/img/pending_with_rm.png' height={'50'}/> <b className='ml-2 align-self-cente' style={{color:'#F3EA0B'}}> Pending with {this.props.agreements_data.active_section && (this.props.agreements_data.active_section[0].pending_with=="rm"?this.state.rm_label_short??'':'YOU')}</b></h2>
 
              </div>
 
           

               < >
              
              <div className='col-sm-6 p-2'>
               <div className='m-2 text-light border h-100 w-100' >
 
                 {this.props.agreements_data.cust_reg_json&&
                  <div style={{height:'100%'}} onClick={()=> {!this.state.pdfMOdal ? this.handleModalClose('open'):null}}>
                 <this.Pdf_modal title={"Agreement PDF"} root={this.props.agreements_data.root} device={this.props.Ismobile()} modal_title={this.props.agreements_data.cust_reg_json.agreements[0].aggr_file_path} pdf_view_error={this.state.pdf_view_error} pdf_viewer={(a)=>{this.pdf_viewer(a)}} pdfMOdal={this.state.pdfMOdal}  handleModalClose ={(type)=>{this.handleModalClose(type)}}/>
                  
                 </div>
                  }
                  {this.props.agreements_data.active_section &&
                   <div>
                       {this.props.agreements_data.active_section[0].verify &&
                         <div className="closedStamp ">
                         <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                         </div>   
                       }
                   </div>
                  }
                 
                 
                
               </div>
              </div>
              <div className='col-sm-6 p-2  '>
               <div className='m-2 text-light border h-100 w-100  px-4 pt-3' >
               {/* <p className='text-white  business_operations_text mt-3 '>BUSINESS NAME</p>
               <h2 className='text-light bold sizel mb-2 pb-1'>{capitalize_first_letter(this.props.agreements_data.cust_reg_json.license_proof.biz_name.value)}</h2>
               */}
               <p className='  text-white  business_operations_text '>CUSTOMER NAME</p>
               <h2 className='text-light bold sizel mb-2'>{capitalize_first_letter(this.props.agreements_data.cust_reg_json.id_proof.first_name.value)}&nbsp;{capitalize_first_letter(this.props.agreements_data.cust_reg_json.id_proof.last_name.value)}</h2>
              
               <p className=' text-white   business_operations_text '> CUST ID</p>
               <h2  className='text-light bold sizel mb-2 pb-1'>{this.props.agreements_data.cust_reg_json.cust_id}</h2>
              
               <p className='  text-white   business_operations_text '>{getLabel('national_id')}</p>
               <h2 className='text-light bold sizel mb-2 pb-1'>{this.props.agreements_data.cust_reg_json.id_proof.id_proof_num.value}</h2>
             
               <p className=' text-white   business_operations_text '>MOBILE NUMBER</p>
               <h2 className='text-light bold sizel mb-2'>{this.props.agreements_data.cust_reg_json.mobile_numbers.biz_mob_num[0]?.mobile_num?.value} </h2>
             
               <p className=' text-white   business_operations_text '>ADDRESS</p>
             
               <h2 className='text-light bold sizel mb-2 pb-1'>
                {address_fields.map((field, index) => {
                  const value = this.props.agreements_data?.cust_reg_json.gps_address.biz_address[field]?.value;
                  return value ? dd_value(capitalize_first_letter(value)) + (field !== 'village' ? ',' : '') : null;
                })}
              </h2>   

               <p className=' text-white  business_operations_text '>Date</p>
               <h2 className='text-light bold  sizel mb-2 '> {  moment(this.props.agreements_data.cust_reg_json.agreements[0].aggr_signed_date).format("DD-MMM-YYYY")  }</h2>
 
               <p className=' no-margin text-white   business_operations_text '>SIGNATURE REFERENCE</p>

               { get('market').country_code =="MDG" ? 
                  <>
                    <DisplayImage image_path={get_dir_path(this.props.agreements_data.cust_reg_json.id_proof.photo_id_proof_back.path,this.props.agreements_data.cust_reg_json.id_proof.photo_id_proof_back.value)} style={{height: '350px',width:'100%'}} file_name={this.props.agreements_data.cust_reg_json.id_proof.photo_id_proof_back.value} />
                    
                      {this.props.agreements_data.cust_reg_json.agreements[0].witness_info.photo_id_proof_back?.path && 
                        <>
                          <p className=' no-margin text-white   business_operations_text '>WITNESS SIGNATURE REFERENCE</p>
                          <DisplayImage image_path={get_dir_path(this.props.agreements_data.cust_reg_json.agreements[0].witness_info.photo_id_proof_back?.path, this.props.agreements_data.cust_reg_json.agreements[0].witness_info.photo_id_proof_back?.value)} style={{height: '350px',width:'100%'}} file_name={this.props.agreements_data.cust_reg_json.agreements[0].witness_info.photo_id_proof_back.value} />
                        </>
                      }
                  </>
               :  
               <>
               {
                   signature_ref_path ? 
                    <div>
                    <img src={ this.props.agreements_data.root + signature_ref_path}
                   alt={signature_ref_path} style={{width:'450px',height:'400px'}} />
                   </div>
               :
                  <DisplayImage image_path={ 
                     get_dir_path(this.props.agreements_data.cust_reg_json.id_proof.photo_id_proof.path,this.props.agreements_data.cust_reg_json.id_proof.photo_id_proof.value)
                    } style={{height: '350px',width:'100%'}} file_name={
                      this.props.agreements_data.cust_reg_json.id_proof.photo_id_proof.value} />
                      }
             </>
               }
              </div>
              </div>
              
          
               </>
               {/* {show_witness_sign && */}
               <div className='col-lg-10 col-md-12 col-sm-12 my-3'>
               {(
                
                !this.props.agreements_data.agreements_verify_status ? this.props.agreements_data.cust_reg_json.agreements :(this.state.cust_reg_json.length>0 ? this.state.cust_reg_json:[])
               
              ).map((item,idx)=>{
                


                return(
                
              <>{item.witness_info &&
                <div className='third_party_bg'>
                <div className='col-lg-12 py-3 mt-5 ' style={{background:' #01062c'}}>
                 <h2 className='text-white text-center'>
                 <b> { `Witness Mobile Number Verification ` } </b> 
                 </h2>
               </div>
              <div className={`d-flex pt-3 third_party_bg no-margin mb-4 justify-content-around row `}>
                <div className='col-md-5  mt-3 mb-5 col-lg-5' >
                <div className='h-100'  style={{background: '#E4E7E9',minHeight:'295px'}}>
                  <p  className="no-margin audit_kyc_text py-3 px-4" style={{color:'black'}}>Name as entered by {this.state.rm_label_short??''}</p><hr className='no-margin' style={{border:'1px solid black'}}/>
                  <div className='p-3 '>

                  <p  className="no-margin py-2 mt-2 audit_kyc_text  px-3 pt-3 " style={{fontSize:'14px'}}>{` WITNESS NAME (as per ${getLabel('national_id')})`}</p>
                  <h4 className='my-1 px-3  audit_kyc_text mb-5 text_dark' style={{fontSize:'26px'}}><b>{capitalize_first_letter(item.witness_info?.name?.value )} </b> </h4>
                  
                 </div>
                  
                  </div>

                </div>
                
                <div className='col-md-2 mt-3 mb-5 col-lg-1' style={{alignSelf:'center'}}>
                {((!item.witness_info.hasOwnProperty('holder_name'))) ? "" :  
                <div className='px-2 py-3' style={{background:` ${item.witness_info.name_match ? '#22cd07':'red'}`,borderRadius:'30px'}}>
                  <p className='no-margin text-white  text-center audit_kyc_text '> <b style={{fontSize:'25px'}}> { item.witness_info.name_match ? "=" : '≠'}</b></p>
                </div>}
                </div>
                
                <div className='col-md-5 mt-3 mb-5 col-lg-5'  >
                <div  style={{background: '#E4E7E9',minHeight:'295px'}}>
                <p  className="no-margin audit_kyc_text py-3 px-4" style={{color:'black'}}>{(get('language_json'))?.holder_name}</p><hr className='no-margin' style={{border:'1px solid black'}}/>

              <div className='p-3 '>
              <div className='d-flex align-items-center  align-items-center my-2 mx-2 '>

                <div  className={`${(((this.props.agreements_data.responseData.audited_by== get("user_id")) || (this.props.agreements_data.responseData.audited_by==null ))  && this.props.agreements_data.active_section.pending_with !="rm")?"":'disable_div'}`}>
                  <OverlayTrigger  placement="top"  delay={{ show: 250, hide: 400 }} overlay={  <Tooltip id="button-tooltip">  click to change <br/> the Mobile Operator   </Tooltip>}>     
                    <AiFillEdit className='pointer mr-2' size={15}
                    onClick={()=>{this.setState({changeOperator:true})}}/>

                  </OverlayTrigger>
                </div>

                <img src={`/img/${ item.witness_info.serv_prvdr?.value}_logo_color.png`} style={{width:'60px',height:'30px'}}/>
                      {/* <p className='no-margin text-white col-4 business_operations_text '>  COUNTRY</p> */}
                  <p className='col-8 pl-4 no-margin audit_kyc_text'> <b>{ item.witness_info.mobile_num?.value}</b></p>
              </div>
              {this.state.changeOperator &&
                
                  <CustomModal
                  show={this.state.changeOperator}
                  onClose={() => {this.setState({changeOperator:false})}}
                  header={`Change Mobile Operator`}
                  body={
                  <UpdateMobileOperator 
                    biz_mob_num={{ ...item.witness_info,category:"witness_num_0", section: 'witness_num',index: 0 }} 
                    modal_close={() => this.setState({changeOperator:false})}
                    view_lead={()=>this.props.view_lead()}
                  />
                  }
                  size="sm"
                  alignItems="center"
                />

             
              }
             
              {(this.props.agreements_data.timers[item.witness_info.mobile_num?.value]!=0 &&
               (!item.witness_info.hasOwnProperty('holder_name')) 
               && get('app_config').audit_kyc_line[item.witness_info.serv_prvdr?.value]=="ussd") ?

              this.checkbox_spinner()
              :
                <>
              {item.witness_info.hasOwnProperty('holder_name') ?

              <>
              {item.witness_info.name_match ?
              <>
                <p  className="no-margin  mt-4 mb-2 pt-3 " style={{fontSize:'14px'}}>{'Name as per '+ dd_value(item.witness_info.serv_prvdr?.value,'provider_list') +' records'}</p>
                <h4 className='my-1 mt-3 px-3 py-2 audit_kyc_text mb-3' style={{background:'rgba(0, 0, 0, 0.3)',color:'black'}}><b>{ item.witness_info.holder_name}</b> </h4>
                </>
              :
              <div> 
                <h2 className='my-1 mt-3 px-3 py-2 audit_kyc_text '  style={{background:'rgba(0, 0, 0, 0.3)'}}><b>{item.witness_info.holder_name}</b> </h2>
                <div className='mt-4' style={{color:'black'}} ><label className='audit_kyc_text-black no-margin'>Account Holder Name Mismatch Reason </label>

                {item.witness_info.hasOwnProperty("mismatch_reason") && !this.state.is_edit ?  
                <b className='d-flex align-items-center'>
                  <p className='audit_kyc_text no-margin '>{
                  this.state.biz_aggr?.[`mismatch_reason`]?
                   dd_value( this.state.biz_aggr[`mismatch_reason`] ,'mismatch_reason_')
                  :
                  dd_value(item.witness_info.mismatch_reason , "mismatch_reason")}
                  </p>
                  <p className='mx-5 mb-0 pointer' onClick={this.handleEdit}>
                    
                     <AiFillEdit/> Edit</p>

                </b>
                :
                  
                  <select
                  id="holder_name_mismatch_reason"
                  className="form-control px-0 text_dark col-md-12"
                  value={this.state.biz_aggr?.mismatch_reason ?? ''}
                  onChange={(e)=> this.acc_holder_name(e.target.value,"mismatch_reason" , idx, item)}
                    style={{ color: "black" }}
                  >
                  <option value={''} >Choose</option>
                  {this.getSelectOptions("holder_name_mismatch_reason", null,null,null,null, true )}

                  </select>
                  }
                  </div>
                  { (this.state.biz_aggr.mismatch_reason=="retrieved_franchise_name" )&&
                     <div className='col-12 no-padding'>
                     <div class="form-group mt-4 mb-2">
                     <input type="text" className='p-3' id="lname" name="lname" style={{width:'100%',background:'#a0a2a3',color:'black',border:'hidden',fontSize:'14px'}} autocomplete="off" placeholder='ENTER HOLDER NAME'
                     
                    //  onChange={(e)=>this.props.account_owner_name_handler(e.target.value,`agreements_witness_name_${idx}`)}
                     onChange={(e)=>this.acc_holder_name(e.target.value,'holder_name')} 
                     
                     />
                   </div>
                   
                   <FileUpload className="audit_kyc_file_upload" lead_id = {this.props.lead_id} id="account_holder_name_proof"  mode = "create" entity ='leads' type="image"          
                     onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}} onCreateUpload={this.onCreateUpload} />
                   <div className='mt-2'>

                   <button type="button" class="btn btn-info btn-sm "
                    disabled={is_disable}

                    onClick={()=>this.upload_file(idx,item)}>submit</button>
                   </div>
                   </div>
                   }
                  {/* {()=>this.props.account_owner_checkbox_handler()} */}
              </div>
              }

              {item.witness_info.hasOwnProperty('holder_name_proof') &&(this.state.biz_aggr.mismatch_reason !="retrieved_franchise_name" )&&
              <div className='m-2'>
                <DisplayImage image_path={get_dir_path(item.witness_info.holder_name_proof_path,item.witness_info.holder_name_proof)} style={{height: '50px',width:'90px'}} file_name={item.witness_info.holder_name_proof} />
              </div>
              }
              </>
              :
              <div className='col-12 no-padding'>
                <div class="form-group mt-3 mb-4">
                <input type="text" className='p-3' id="lname" name="lname" style={{width:'100%',background:'#a0a2a3',color:'black',border:'hidden',fontSize:'14px'}} autocomplete="off" placeholder='ENTER HOLDER NAME'        
                onChange={(e)=>this.acc_holder_name(e.target.value,'holder_name')} 
                />
              </div>
              
              <FileUpload className="audit_kyc_file_upload" lead_id = {this.props.lead_id} id="account_holder_name_proof"  mode = "create" entity ='leads' type="image"          
                onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}}  onCreateUpload={this.onCreateUpload}/>
              <div className='mt-4'>
              <button type="button" class="btn btn-info btn-sm "
               onClick={()=>this.upload_file(idx,item)}
              disabled={is_disable}
              
              >submit</button>
              </div>
              </div>

              }
                </>
                  } 
              </div>
              </div>
                </div>
              
              </div>
              { ( item.witness_info.hasOwnProperty("name_match") && !(item.witness_info.name_match ))&&
                  <div className='row  pb-5 justify-content-center no-margin pt-2  px-4' >
                  <div className='col-lg-12 p-3 d-flex align-items-center' style={{background:'#fe9f9f'}}>
                    <h2 className='d-flex '> <b>Note</b> <p className='no-margin audit_kyc_text pl-3' style={{color:'black'}}>The name of the customer doesn’t match either with customer name nor business name.</p></h2>
                  </div>
                  </div>
                  }
                </div>
                }
              </>
              )})}
               </div>
               
                
              <div className='col-lg-10 col-md-12 col-sm-12 my-3'>
              {this.props.agreements_data.active_section &&
               <>
                 
                  <form className={`col-sm-12 mt-3 mb-3 ${className}  `}> 

                     <div className='d-flex align-items-center'>
                     <input type="checkbox" id="vehicle1" className='pointer' style={{height:'19px',width:'20px'}} 
                    
                    checked={ audit_kyc && this.props.audit_kyc['agreements'].agreement_data_match && true} 
                    
                    onChange={(e)=>this.props.checkbox_verification(e.target.checked,"agreement_data_match")} />
                     <label className='no-margin pl-3 audit_kyc_text pointer' for="vehicle1"  >{`The business name, customer name, flow cust ID, ${getLabel('national_id')}, mobile number, address and date matches with the agreement form. `}</label><br/><br/>
                     </div>
 
                     {audit_kyc && this.props.audit_kyc['agreements'].hasOwnProperty("signature_match") &&
                     <div className='d-flex align-items-center'>
                     <input type="checkbox" id="vehicle2" style={{height:'19px',width:'20px'}} 
                     checked={this.props.audit_kyc['agreements'].signature_match && true}
                      onChange={(e)=>this.props.checkbox_verification(e.target.checked,"signature_match")} />
                     <label className='no-margin pl-3 audit_kyc_text' for="vehicle2">{`The signature of the customer in the Float Advance Agreement matches with the signature in the ${getLabel('national_id')}`}</label><br/><br/>
                     </div>
                     }

                    {audit_kyc && this.props.audit_kyc['agreements'].hasOwnProperty("witness_contact_verified") &&this.props.agreements_data.active_section[0]?. witness_sign  && 
                    this.props.agreements_data.active_section[0]?.witness_info &&
                     <div className='d-flex'>
                     <input type="checkbox" id="vehicle3" style={{height:'19px',width:'25px'}} className='pointer mt-1' checked={this.props.audit_kyc['agreements'].witness_contact_verified && true} onChange={(e)=>this.props.checkbox_verification(e.target.checked,"witness_contact_verified")} />
                     <label className='pointer no-margin pl-3 audit_kyc_text' for="vehicle3" style={{lineHeight:'25px'}}>The witness contact verified matches the name input by {this.state.rm_label_short??''}</label><br/><br/>
                     </div>
                     }
 
                 </form>
               
               </>
               }
              </div>
             </div>
              }</>
           
            </div>

        )        
     }
}

const mapStateToProps = (state) => {
  return {
    audit_kyc: state.common_slice,
  };
  };

const mapDispatchToProps ={
    updateValuesIntoRedux
};

export default  withRouter(connect(mapStateToProps,mapDispatchToProps)(AuditKYCAgreementsVerification));