import { Popper, PopperProps } from "@mui/material";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import TextField, { TextFieldVariants } from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";

export interface RHFAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  label?: string;
  placeholder?: string;
  type?: string;
  variant?: TextFieldVariants;
  required?: boolean;
  popperFontFamily?: string;
  helperText?: React.ReactNode;
  customOnchangeCb?: Function; // some times for the single field change there is a need to chage multiple field values synchronously, for that we can send the customOnchangeCb from the parent component, (eg: <BorrowerCommonAddressFormUI />)
}

export default function RHFAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  name,
  label,
  type,
  variant,
  helperText,
  placeholder,
  popperFontFamily,
  customOnchangeCb,
  ...other
}: Omit<
  RHFAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  "renderInput"
>) {
  const { control, setValue } = useFormContext();

  // custom component to override the popper paper
  const CustomPopper = (props: PopperProps) => (
    <Popper
      {...props}
      sx={{
        "& .MuiAutocomplete-listbox": {
          fontFamily: popperFontFamily,
          backgroundColor: "#111936",
          // for standard variant custom border style is applied
          border: variant !== "standard" ? "solid 2px #919191" : "none",
          borderRadius: "12px",
          marginTop: "3px",
        },
        zIndex: "10000!important",
      }}
    />
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Autocomplete
            {...field}
            id={`autocomplete-${name}`}
            onChange={(_, newValue: any) => {
              if (customOnchangeCb) {
                setValue(name, newValue, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
                customOnchangeCb();
              } else
                setValue(name, newValue, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
            }}
            PopperComponent={(props) => CustomPopper(props)}
            slotProps={{
              popper: {
                sx: {
                  zIndex: 10000,
                },
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant={variant}
                placeholder={placeholder}
                error={!!error}
                helperText={error ? error?.message : helperText}
              />
            )}
            {...other}
          />
        );
      }}
    />
  );
}
