import { Box } from "@mui/material";
import { ReactNode } from "react";

const IconTypeTextField = ({
  icon,
  pairTextUI,
}: {
  icon: ReactNode;
  pairTextUI: ReactNode;
}) => (
  <Box display="flex" alignItems="center">
    {icon}
    <Box sx={{ pl: 2 }}>{pairTextUI}</Box>
  </Box>
);

export default IconTypeTextField;
