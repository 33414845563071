import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import {lbl_dur, lbl_repayment_amt, lbl_amt, lbl_flow_fee_obj} from '../../../helpers/product_helper';
import * as moment from "moment";
import {addDays,format_date} from '../../../helpers/common_helper';


class TnCPanel extends FlowComponent {
state = {
	chk_tnc : false,
	chk_consent : false,
}

handleTnCConsentClick = (event) => {
	event.target.value = event.target.checked;
	/*if(event.target.checked){
	this.setState({chk_tnc: true,chk_consent: true});
	}else{
	this.setState({chk_tnc: false,chk_consent: false});
	}*/
	this.handleChange(event);
	//this.setState({[event.target.id]: true});
	//this.setState({submit_btn_div_style: "show_div"});
}
render(){
//alert(JSON.stringify(this.props.customer));
	return(
				<div className="container FAApplicationTermsContainer" >
					<div className="row">
						<u><h4>Terms & Conditions</h4></u>
					</div>
					
					<div className="row">
						<span>1. The requested float advance is {lbl_amt(this.props.product.max_loan_amount, this.currency_code)}.</span>
					</div>
					
					<div className="row">
						<span>2. The  fee applicable is {lbl_flow_fee_obj(this.props.product, this.currency_code)}.</span>
					</div>
					
					<div className="row">
						<span>3. Total repayment amount is {lbl_repayment_amt(this.props.product, this.currency_code)}.</span>
					</div>
					
					<div className="row">
						<span>4. Repayment date is {lbl_dur(this.props.product.duration, this.props.product.flow_fee_duration, this.props.product.loan_purpose)} from the date of disbursal.</span>
					</div>

					<div className="row">
						<span>5. Repayment date shall be on or before {this.props.product.due_date} provided the float advance is disbursed today <b>(Sundays & Interest Free days not considered.)</b>.</span>
					</div>
					
					{this.props.customer.acc_number ? 
					<div className="row">
						<span>6. Subjected to the approval, an amount of <b>{lbl_amt(this.props.product.max_loan_amount , this.currency_code)}</b> will be disbursed to <b>{this.props.customer.acc_prvdr_name} ({this.props.customer.acc_type}) Account number {this.props.customer.acc_number}</b>.</span>
					</div> : <div></div>
					} 
					<br/>
					<div className="row">
						<label><input type="checkbox" id="chk_tnc" onChange={this.handleTnCConsentClick} />&nbsp;
						Customer has agreed to the above float advance terms and conditions.</label>
					</div>

					{/*<div className="row">
						<label><input type="checkbox" id="chk_consent" onChange={this.handleTnCConsentClick} />&nbsp;
						Customer has agreed and given consent to use his/her transaction data with {this.props.customer.data_prvdr_name} for the purpose of calculating the credit score.</label>
					</div>*/}
					<br/>
					
		</div>

		);
}

}

export default TnCPanel;