import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { powered_by_text } from '../../../helpers/common_helper';
import { get } from '../../../helpers/storage_helper';

const AppFooter = () => {
   const {brand_info} =  useSelector(state=>state.common_slice)
   let footer_data = get('global_icon') ?? brand_info;
   const theme = useTheme()
    return (<Box  sx={{
            position: 'fixed',
            bottom: 0,
            width:'100%',
            background: theme.palette.custom_colors.primary_500,
            zIndex:1500}}>
            {powered_by_text(footer_data.footer.logo,theme.palette.custom_colors.secondary_a_400 )}
        </Box>
    );
};

export default AppFooter;