import React, { Component } from 'react'
import FlowComponent from '../../common/component/core/FlowComponent';
import Select from "react-select";
import { Config, check_priv, get } from '../../../helpers/storage_helper';
import { getLoanSearchCriteria, getRmNameUnderTm } from '../../../actions/common_actions';
import { BiCalendar } from 'react-icons/bi';
import { IoMdPerson } from "react-icons/io";
import { GoBriefcase } from "react-icons/go";
import { IoIosPhonePortrait} from "react-icons/io";
import { IoPersonAdd } from "react-icons/io5";
import { VscCircleFilled } from "react-icons/vsc";
import DatePicker from 'react-datetime';
import moment from "moment";
import { get_suffix_date_str, getValueFromLangJson, RoundOffSplitter } from '../../../helpers/common_helper';
import { getReport } from '../../../actions/report_actions';
import { FaArrowTrendUp,FaArrowTrendDown } from "react-icons/fa6";


const view_by = [{value:"rm", label: getValueFromLangJson("rm_label")},{value:"tm", label:"Territory Manager"}]
export default class Coo_dashboard extends FlowComponent {
    state = {
        rm_name : {'value': 'all', 'label' : 'All' },
        tm_name: {'value': 'all', 'label' : 'All'},
        rm_ids : [],
        cur_month : null,
        compr_month : null,
        loader : false,
        filteredRmData : [],
        currency_code : this.currency_code,
        user_id : get('user_id'),
        market : get('market'),
        tm_id :[...Config('rm_head_person_id'), ...Config('btl_manager')],
        date_format:false,
        start_date:moment(new Date()).format('DD MMM YYYY'),
        type:"rm",
        switch_data:false,
        par_metrics : [
            {key:'par_5', label:'PAR 5', show:true},
            {key:'par_10', label:'PAR 10', show:true},
            {key:'par_15', label:'PAR 15', show:true},
            {key:'par_30', label:'PAR 30', show:true},
            {key:'par_60', label:'PAR 60', show:false},
            {key:'par_90', label:'PAR 90', show:false},
            {key:'par_120', label:'PAR 120', show:false},
            {key:'par_270', label:'PAR 270', show:false},

        ],
        show_all:false,
        view_by :[{value:"rm", label: getValueFromLangJson("rm_label")},{value:"tm", label:"Territory Manager"}],
        selected_view_by:{value:"rm", label: getValueFromLangJson("rm_label")},

    }

    handle_show_more = ()=>{
        this.setState(prevState=>({show_all:!prevState.show_all}))

    }

    componentWillMount (){
        if(this.state.tm_id.includes(this.state.user_id)){
            this.handleTerritoryManager()
        }else{
            this.handleApiCall()
        }
        const switchCountries = Config("switch_supported_countries");
        this.setState({switch_data: switchCountries.includes(this.state.market.country_code)})
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.compr_month_start_date != this.state.compr_month_start_date  || prevState.compr_month_end_date != this.state.compr_month_end_date){
            this.handleRequest()
        }
    }

    handleTerritoryManager = () => {
        getRmNameUnderTm(this.req({"report_to" : this.state.user_id}))
            .then((response) => {
                if(!response) {
                    this.setState({loader:false})
                    return 
                }
                if(response.status === "success"){
                    var result = response.data
                    // result.unshift({ id: null,name : 'All'});
                    var { rm_list, ids } = this.convertKeys(result)
                    this.setState({flow_rel_mgr:rm_list, rm_ids : ids})
                    this.getCurrentMonth()
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({loader:false})
                }
        });
        
    }


  

    handleApiCall = () => {
        this.load_flow_rel_mgr(null, 'rel_mgr', {value: 'all', label : 'All'})
        if(!this.state.tm_id.includes(this.state.user_id)){
            this.load_territory_manager(null, 'territory_mgr', {value: 'all', label : 'All'})
        }
        this.getCurrentMonth()
    }

    convertKeys = (arrayOfObjects)=> {
            let rm_list = [];
            let ids = [];
            
            arrayOfObjects.forEach(obj => {
                rm_list.push({
                    value: obj.id,
                    label: obj.name
                });
                ids.push(obj.id);
            });
            rm_list = rm_list.slice().sort((a, b) => a.label.localeCompare(b.label));
            rm_list.unshift({ value: 'all', label : 'All'});
            return { rm_list, ids };
        }

    select_custom_styles(){
        var customStyles = {
           option: (provided, state) => ({
             ...provided,
             backgroundColor: state.isFocused ? '#097afa' : '#202940',
             color: 'white',
             padding: '3px 0px 3px 10px',
           }),
           singleValue: (provided) => ({
             ...provided,
             color: 'black',
           }),
           control: (provided, state) => ({
             ...provided,
             backgroundColor: '#353751',
             border: '#333b50',
             borderRadius:'5px',
             boxShadow: state.isFocused ? 'none' : "",
             paddingLeft:'10px'
             }),
             input: (provided) => ({
               ...provided,
               color: 'white',
               gridTemplateColumns: 'auto'
             }),
             placeholder: (provided,state) => ({
                ...provided,
                color: 'white',
            }),  
            singleValue: (provided) => ({
                ...provided,
                color: 'white',
            }),
           };
         return customStyles;
       }
       
    handleSelectChange = (e, $type) => {
       if($type == 'view_by'){
            this.setState({selected_view_by : e, type:e.value}, ()=> this.filterData());
        }else{
            let name = $type + "_name";
            this.setState({[name] : e },()=> this.filterData());
        }
    }  
    
    getCurrentMonth = () => {
        const today = moment();
        const startOfMonth = moment().subtract(1, 'month').startOf('month');
        const yesterday = moment().subtract(1, 'day');
        // let beforeStartMonth =  moment().subtract(2, 'month').startOf('month');
        let startDate, endDate, beforeStartMonth;
      
        if (today.date() === 1) {
          startDate = startOfMonth.format('DD MMM YYYY');
          endDate = yesterday.format('DD MMM YYYY');
          beforeStartMonth =  moment().subtract(2, 'month').startOf('month');
        } else {
          startDate = today.startOf('month').format('DD MMM YYYY');
          endDate = yesterday.format('DD MMM YYYY');
          beforeStartMonth =  moment().subtract(1, 'month').startOf('month');
        }
      
        this.setState({cur_month_start_date : startDate ,cur_month_end_date : endDate},()=>this.monthHandler(beforeStartMonth))

        if(startDate == endDate){
            this.setState({cur_month:startDate})
        }else{
            this.setState({cur_month :`${startDate} - ${endDate}`})
        }
  
    }

    changeReportMonth = (current , compare) => {
        
        let cur_start = moment(current).startOf('month').format('DD MMM YYYY');
        let compr_start = moment(compare).startOf('month').format('DD MMM YYYY');
        
        this.setState({cur_month_start_date : cur_start ,cur_month_end_date : moment(current).format('DD MMM YYYY')})
        // this.setState({compr_month_start_date : compr_start, compr_month_end_date :  moment(compare).format('DD MMM YYYY')})

        if(cur_start == current){
            this.setState({cur_month : cur_start, compr_month : compr_start})

        }else{
            this.setState({cur_month :`${cur_start} - ${moment(current).format('DD MMM YYYY')}`})
            this.setState({compr_month :`${compr_start} - ${moment(compare).format('DD MMM YYYY')}`})
        }

    }

    monthHandler = (e,key = false) => {

        const compr_start_date = moment(e).format('DD MMM YYYY');
        const start_date = moment(e).startOf('month').format('DD MMM YYYY');
        const endDateCurMonth = moment(this.state.cur_month_end_date, 'DD MMM YYYY');
        const endOfselectedMonth = moment(e).endOf('month').format('DD MMM YYYY');
        const selectedMonth = moment(e).month();
        const selectedYear = moment(e).year();
        let compr_end_date;
        if(moment(endDateCurMonth).date() > moment(endOfselectedMonth).date()){
            compr_end_date = moment({
                year: selectedYear,
                month: selectedMonth,
                date: moment(endOfselectedMonth).date() 
            }).format('DD MMM YYYY');


        }else{
            compr_end_date = moment({
                year: selectedYear,
                month: selectedMonth,
                date: endDateCurMonth.date() 
            }).format('DD MMM YYYY');
        }

    this.setState({compr_month_start_date : start_date,date_format:true, compr_month_end_date : key ?  compr_start_date : compr_end_date})
    if(start_date == (key ?  compr_start_date : compr_end_date)){
        this.setState({compr_month: key ?  compr_start_date : compr_end_date})
    }else{
        this.setState({compr_month :`${start_date} - ${key ? compr_start_date : compr_end_date}`})
    }
  
    }


    handleRequest = ()=>{
    this.setState({records:[], loader : true, no_data:false})
    var req = { 
        country_code : this.country_code, 
        report_type : "get_rm_performance_report",
        cur_month : moment(this.state.cur_month_end_date).format('YYYY-MM-DD'),
        compr_month : moment(this.state.compr_month_end_date).format('YYYY-MM-DD'),
        rm_ids : this.state.rm_ids ? this.state.rm_ids : null
     }
        getReport(this.req(req))
            .then((response) => {
                if(!response) {
                    this.setState({loader:false})
                    return 
                }
                if(response.status === "success"){
                    const result = response.data.report_data
                    this.setState({records : result})
                    
                    if(result.length == 0){
                        this.setState({no_data:true, loader:false})
                    }else{
                        let reportDate = response.data.last_report_date;
                        let reportPrevDate =  response.data.prev_report_date;
                        
                        if(this.state.cur_month_end_date != reportDate){
                           this.changeReportMonth(reportDate, reportPrevDate)  
                        }
                        
                        this.setState({prevMonthDate : reportPrevDate})
                        this.filterData(result)
                    }
                }else{
                    alert(response.message + "\n" + JSON.stringify(response.data));
                    this.setState({loader:false})
                }
        });
    }
       
    filterData = (data = null) => {
        let id = '';
        if(this.state.tm_id.includes(this.state.user_id) && this.state.rm_name.value == "all"){
            id = 'tm_' + this.state.user_id
        }else{
            id = this.state.type == 'rm' ? 'rm_' + this.state.rm_name.value : 'tm_' + this.state.tm_name.value;
        }
        const filteredData = data ? data.filter(e => e.person_id == id) : this.state.records.filter(e => e.person_id == id)
        this.setState({filteredRmData : filteredData},()=>this.setState({loader:false}))
    }

    PercentageChange = (current, comparison, opposite_clr = false) => {
        let color, displayPercentage, position;
        let negative = opposite_clr ? '#20AA1D' : '#FF3F3F'
        let positive = opposite_clr ? "#FF3F3F" : "#20AA1D"
        if (current === 0) {
            if (comparison === 0) {
               displayPercentage = '0.00%'
                color = '#e9e7e7';
            } else {
               displayPercentage = '-100%'
               color = negative 
               position = 'down'
            }
        }else if (comparison === 0) {
           displayPercentage = '+100%'
            color = positive ; 
            position = "up"
        }else{
            const percentageChange = ((current - comparison) / comparison) * 100;
            const formattedPercentage = percentageChange.toFixed(1);
            color = percentageChange < 0 ? negative : positive;
            position = percentageChange < 0 ? "down" : "up"
            displayPercentage = `${percentageChange < 0 ? '' : '+'}${formattedPercentage}%`;
        }
        return (
            <div style={{minWidth:"100px"}}>
                {color == "#e9e7e7" ? null : 
                position == "up" ? <FaArrowTrendUp  style={{color:color, fontSize:"18px"}}/> : <FaArrowTrendDown style={{color:color, fontSize:"18px"}}/>
                }
                <span style={{ color ,fontWeight:'500',fontSize:'12px', marginLeft:"6px"}}>
                    {displayPercentage}
                </span>
            </div>
        );
    }

    calculatTotalPercentage = (tot_val, count, key = false, border = false, text = "") => {
  
        let displayPercentage;
        if (count === 0) {
           displayPercentage = text == "num" ? 0 : "0%"
        }else{
            const percentageChange = (count / tot_val) * 100;
            const formattedPercentage = percentageChange == 'Infinity' ? 0 : percentageChange.toFixed(2);
            displayPercentage = text == "num" ? formattedPercentage : `${formattedPercentage}%`;
        }
        return (
            key == false && text == "" ?
            <td className="text-center field_style " style={{borderRight : border ? "1px solid #7B7575" : ''}}>
                {displayPercentage}
            </td>
            : text == "num" ? displayPercentage :
            <div className='w-100 p-0' style={{fontSize:'12px',color:"white"}}>{`(${displayPercentage} ${text})`}</div>
        );
    }
    
    handleHeader = (ytd = true) => {
        return (
            <tr>
                <th scope="col" class="col-2"></th>
                <th scope="col" className='col-2 text-white text-center field_header'>{moment(this.state.cur_month_start_date).format('MMM YYYY')} - MTD</th>
                <th scope="col" className='col-2 text-white text-center field_header'>{moment(this.state.prevMonthDate).format('MMM YYYY')}</th>
                {/* <th scope="col" className='col-3 text-white text-center field_header'>{moment(this.state.cur_month_start_date).format('MMM YYYY')} vs {moment(this.state.prevMonthDate).format('MMM YYYY')}</th> */}
                <th scope="col" className='col-2 text-white text-center field_header'>{ytd ? moment(this.state.cur_month_start_date).format('YYYY') + " YTD" : null}</th>
            </tr>
        );
    }  

  
    
    formatNumber(n, decimal = 2) {
        var ranges = [
          { divider: 1e9 , suffix: 'B' },
          { divider: 1e6 , suffix: 'M' },
          { divider: 1e3 , suffix: 'K' }	
        ];
    
        for (var i = 0; i < ranges.length; i++) {
          if (n >= ranges[i].divider) {
            let format_num = (n / ranges[i].divider).toFixed(decimal) + " " + ranges[i].suffix
            
            return  (
                <>
                    {format_num} <span style={{ fontWeight: 'normal', fontSize:"12px" }}>{this.state.currency_code}</span>
                </>
            );
           
          }
        }

        return( 
            <>{Number(n).toFixed(0)} <span style={{ fontWeight: 'normal', fontSize:"12px" }}>{this.state.currency_code}</span></>
        );
    
      }
    
  render() {
    const data = this.state.filteredRmData[0];
    const switch_support = Config('switch_supported_countries')?.includes(get('market').country_code)
    return (
        <div className='container-fluid'>
        <div>
            <h2 className='text-white p-3' style={{fontSize:'32px'}}>{getValueFromLangJson("rm_label_short")} Performance</h2>
        </div>

        <div class="py-4 pl-3">
        <div className='d-flex mb-4'>
            {!this.state.tm_id.includes(this.state.user_id) && Config('countries_with_tms')?.includes(get('market').country_code) &&

                <div className='col-sm-5 col-md-4 col-lg-3 marT-5 mr-4'> 
                    <div className="dropdown-container">
                        <p className='text-white' style={{fontSize:'14px'}}>View by</p>
                        <Select onChange={(e) =>{this.handleSelectChange(e, 'view_by')}}
                            value = {this.state.selected_view_by}
                            options={ this.state.view_by}
                            placeholder="All"
                            styles={this.select_custom_styles()}
                            classNamePrefix="my-select" 
                            isMulti={false}
                        />
                    </div>
                    
                </div>
            }
            {this.state.type == 'rm' &&
                <div className='col-sm-5 col-md-4 col-lg-3 marT-5'> 
                    <div className="dropdown-container">
                        <p className='text-white' style={{fontSize:'14px'}}>{getValueFromLangJson("rm_label")}</p>
                        <Select onChange={(e) =>{this.handleSelectChange(e, 'rm')}}
                            value = {this.state.rm_name}
                            options={this.state.flow_rel_mgr}
                            placeholder="All"
                            styles={this.select_custom_styles()}
                            classNamePrefix="my-select" 
                            isMulti={false}
                        />
                    </div>
                </div>
            }
            
            {this.state.type == 'tm' && 
                <div className='col-sm-5 col-md-4 col-lg-3 marT-5'> 
                    <div className="dropdown-container">
                        <p className='text-white' style={{fontSize:'14px'}}>Territory Manager</p>
                        <Select onChange={(e) =>{this.handleSelectChange(e, 'tm')}}
                            value = {this.state.tm_name}
                            options={this.state.flow_territory_mgr}
                            placeholder="All"
                            styles={this.select_custom_styles()}
                            classNamePrefix="my-select" 
                            isMulti={false}
                        />
                    </div>
                </div>
            }
            
        </div>
            
        <div class="d-flex">
            <div class="col-md-4 col-sm-5 col-lg-3">
                <div class="">
                    <p className='text-white' style={{fontSize:'14px'}}>Current Month</p>
                        <div className="form-group  px-2 py-0  m-0 d-flex align-items-center  churn_report_date_picker"  style={{opacity:0.5}}>
                            <label className='col-1.5 no-margin d-flex p-0 ' style={{ position: 'static' }} htmlFor='rm_performance_start_date' >
                                <p className='m-0 pr-1'><BiCalendar size={20} /></p>
                            </label>

                            <div className='col  p-0 py-1 pr-0' style={{pointerEvents:'none'}}>

                                <DatePicker
                                    value={ this.state.cur_month }
                                    inputProps={{ id: 'rm_performance_start_date', name: 'Referral_from',autoComplete:'off' }}  
                                />
                            
                            </div>
                        </div>
                </div> 
            </div>
            <div class="pb-3 d-flex align-items-end">
                <p className='text-white m-0' style={{fontSize:'15px'}} >v/s</p>
            </div>
            <div class="col-md-4 col-sm-5 col-lg-3">
                <div class="">
                    <p className='text-white' style={{fontSize:'14px'}}>Comparison Month</p>
                        <div className="form-group m-0 px-2 py-0  d-flex align-items-center  churn_report_date_picker" >
                            <label className='col-1.5 no-margin d-flex p-0 ' style={{ position: 'static' }} htmlFor='rm_performance_date'>
                                <p className='m-0 pr-1'><BiCalendar size={20} /></p>
                            </label>

                            <div className='col  p-0 py-1 pr-0'>


                           

                                <DatePicker closeOnSelect={true} 
                                    isValidDate={(current) => {
                                       
                                        const currentMonth = moment(this.state.cur_month_end_date);
                                        const sixMonthsAgo = moment(currentMonth).subtract(6, 'months');
                                        const previousMonth = moment(this.state.cur_month_end_date).subtract(1, 'month').startOf('month');

                                        return  current.isBetween(sixMonthsAgo, currentMonth, 'month', '[]') && current.isSameOrBefore(previousMonth, 'month')}}

                                        // const previousMonth = moment().subtract(1, 'month').startOf('month');
                                        // return current.isSameOrBefore(previousMonth, 'month');  }} 
                                    value={ this.state.compr_month ? this.state.compr_month:''}           
                                    onChange={(value)=>this.monthHandler(value,true)} 
                                    inputProps={{ id: 'rm_performance_date', name: 'Referral_from',autoComplete:'off' }}  
                                    dateFormat= { this.state.date_format ? "DD MMM YYYY":"MM YYYY"} 
                                    showMonthYearPicker timeFormat={false} required={false}
                                    />
                            
                            </div>
                        </div>
                </div>
            </div>
        </div>
        {!this.state.loader && this.state.filteredRmData.length > 0 &&
         <>
        <div className='px-4 pb-4 my-4' style={{backgroundColor:'#20233F',borderRadius:'10px'}}>
            <div className=' d-flex align-items-center p-4'>
                <img src='/img/Service.png' alt='icon'/>
                <p className='text-white m-0 px-3' style={{fontSize:'25px'}}>Efforts</p>
            </div>
            <div className='coo-efforts' style={{}}>
                <div style={{backgroundColor:'#353751'}}>
                    <p className='text-white p-4 pl-5 m-0' style={{fontSize:'17px',fontWeight:'500'}} >Field Visits</p>
                </div>
                <div className='px-4  py-3 coo_dashboard_scroll'>
                    <table class="table table-borderless">
                        <thead>
                            {this.handleHeader()}
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="row" className='text-white field_side_header'>Sales Visits</td>
                                <td>
                                    <div className='row m-0'>
                                        <div className='col-5 text-right p-0 field_style'>{data.sales_visits.current}</div>
                                        <div className='col-6'>{this.PercentageChange(data.sales_visits.current,data.sales_visits.compr)}</div>
                                    </div>
                                    
                                </td>
                                <td class="text-center field_style">{data.sales_visits.compr}</td>
                                <td class="text-center field_style">{data.sales_visits.year}</td>
                            </tr>
                            <tr>
                                <td scope="row" className='text-white field_side_header'>Regular Visits</td>
                                <td>
                                    <div className='row m-0'>
                                        <div className='col-5 text-right p-0 field_style'>{data.regular_visit.current}</div>
                                        <div className='col-6'>{this.PercentageChange(data.regular_visit.current,data.regular_visit.compr)}</div>
                                    </div>
                                </td>
                                <td class="text-center field_style">{data.regular_visit.compr}</td>
                                
                                <td class="text-center field_style">{data.regular_visit.year}</td>
                            </tr>
                            <tr>
                                <td scope="row" className='text-white field_side_header'>Overdue Visits</td>
                                <td>
                                    <div className='row m-0'>
                                        <div className='col-5 text-right p-0 field_style'>{data.overdue_visit.current}</div>
                                        <div className='col-6'>{this.PercentageChange(data.overdue_visit.current,data.overdue_visit.compr)}</div>
                                    </div>
                                </td>
                                <td class="text-center field_style">{data.overdue_visit.compr}</td>
                                <td class="text-center field_style">{data.overdue_visit.year}</td>
                            </tr>
                            {/* <tr>
                                <td scope="row" className='text-white field_side_header'>Reactivation Visits</td>
                                <td>
                                    <div className='row m-0'>
                                        <div className='col-5 text-right p-0 field_style'>{data.reactivation_visits.current}</div>
                                        <div className='col-6'>{this.PercentageChange(data.reactivation_visits.current,data.reactivation_visits.compr)}</div>
                                    </div>
                                </td>
                                <td class="text-center field_style">{data.reactivation_visits.compr}</td>
                                <td class="text-center field_style">{data.reactivation_visits.year}</td>
                            </tr> */}
                        </tbody>
                    </table>
                </div>
            </div>
            {Config("rm_call_logs") &&
            <div className='mt-4 coo-efforts shadow-lg' style={{}}>
                <div style={{backgroundColor:'#353751'}}>
                    <p className='text-white p-4 pl-5  m-0' style={{fontSize:'17px',fontWeight:'500'}} >Calls</p>
                </div>
                <div className='px-4  py-3 coo_dashboard_scroll'>
                    <table class="table table-borderless">
                        <thead>
                            {this.handleHeader()}
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="row" className='text-white field_side_header'>Outgoing</td>
                                <td>
                                    <div className='row m-0'>
                                        <div className='col-5 text-right p-0 field_style'>{data.outgoing_calls.current}</div>
                                        <div className='col-6'>{this.PercentageChange(data.outgoing_calls.current,data.outgoing_calls.compr)}</div>
                                    </div>
                                </td>
                                <td class="text-center field_style">{data.outgoing_calls.compr}</td>
                                <td class="text-center field_style">{data.outgoing_calls.year}</td>
                            </tr>
                            <tr>
                                <td scope="row" className='text-white field_side_header'>Incoming</td>
                                <td>
                                    <div className='row m-0'>
                                        <div className='col-5 text-right p-0 field_style'>{data.incoming_calls.current}</div>
                                        <div className='col-6'>{this.PercentageChange(data.incoming_calls.current,data.incoming_calls.compr)}</div>
                                    </div>
                                </td>
                                <td class="text-center field_style">{data.incoming_calls.compr}</td>
                                <td class="text-center field_style">{data.incoming_calls.year}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
            }

        </div>
    
        <div className='px-4 pb-4  my-4' style={{backgroundColor:'#20233F',borderRadius:'10px'}}>
            <div className=' d-flex align-items-center p-4'>
                <img src='/img/Ratings.png' alt='icon'/>
                <p className='text-white m-0 px-3' style={{fontSize:'25px'}}>Results</p>
            </div>
            <div className='coo-efforts coo_dashboard_scroll ' style={{borderTop:'2px solid black'}}>
                <div className='px-4  py-3'>
                    <table class="table table-borderless">
                        <thead>
                            {this.handleHeader()}
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="row" className='text-white field_side_header'>Acquisition</td>
                                <td>
                                    <div className='row m-0'>
                                        <div className='col-5 text-right p-0 field_style'>{data.acquisitions.current}</div>
                                        <div className='col-6'>{this.PercentageChange(data.acquisitions.current,data.acquisitions.compr)}</div>
                                    </div>
                                    <div  class="text-center ">{this.calculatTotalPercentage(data.rm_target.current,data.acquisitions.current,true, false, " of target")}</div>
                                </td>
                                <td class="text-center">
                                    <div className='field_style'>{data.acquisitions.compr}</div>
                                    {this.calculatTotalPercentage(data.rm_target.compr,data.acquisitions.compr,true, false, " of target")}
                                </td>
                                <td class="text-center field_style">{data.acquisitions.year}</td>
                            </tr>
                           { Config('do_rekyc') &&
                            <>
                            <tr>
                                <td scope="row" className='text-white field_side_header'>ReKYC<span style={{fontSize:"12px"}}>{' (Renewal Excluded)'}</span></td>
                                <td>
                                    <div className='row m-0'>
                                        <div className='col-5 text-right p-0 field_style'>{data.rekyc_visits.current}</div>
                                        <div className='col-6'>{this.PercentageChange(data.rekyc_visits.current,data.rekyc_visits.compr)}</div>
                                    </div>
                                </td>
                                <td class="text-center field_style">{data.rekyc_visits.compr}</td>
                                <td class="text-center field_style">{data.rekyc_visits.year}</td>
                            </tr>
                            <tr>
                                <td scope="row" className='text-white field_side_header'>
                                    KYC Renewal
                                    <div className='d-inline-block pl-2 rm-performance' style={{}}>
                                        <RoundOffSplitter val={"Re-KYC done for agreement renewal only"} unit={""} align_center={true} position={"top"} notify={true}/>    
                                    </div> 
                                </td>
                                <td>
                                    <div className='row m-0'>
                                        <div className='col-5 text-right p-0 field_style'>{data.kyc_renewal_visits.current}</div>
                                        <div className='col-6'>{this.PercentageChange(data.kyc_renewal_visits.current,data.kyc_renewal_visits.compr)}</div>
                                    </div>
                                </td>
                                <td class="text-center field_style">{data.kyc_renewal_visits.compr}</td>
                                <td class="text-center field_style">{data.kyc_renewal_visits.year}</td>
                            </tr>
                            </>
                            }
                        </tbody>
                    </table>
                </div>
                
            </div>
        </div>  

        <div className='px-4 pb-4  my-4' style={{backgroundColor:'#20233F',borderRadius:'10px'}}>
            <div className=' d-flex align-items-center p-4'>
                <IoPersonAdd size={32} color='white'/>
                <p className='text-white m-0 px-3' style={{fontSize:'25px'}}>Lead Pipeline</p>
            </div>
            <div className='coo-efforts coo_dashboard_scroll ' style={{borderTop:'2px solid black'}}>
                <div className='px-4  py-3'>
                    <table class="table table-borderless">
                        <thead>
                            {this.handleHeader(false)}
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="row" className='text-white field_side_header'>Leads Created</td>
                                <td>
                                    <div className='row m-0'>
                                        <div className='col-5 text-right p-0 field_style'>{data.created_leads.current}</div>
                                        <div className='col-6'>{this.PercentageChange(data.created_leads.current,data.created_leads.compr)}</div>
                                    </div>
                                </td>
                                <td class="text-center field_style">{data.created_leads.compr}</td>
                                <td class="text-center field_style"></td>

                            </tr>
                            <tr>
                                <td scope="row" className='text-white field_side_header'>Assessment Done</td>
                                <td>
                                    <div className='row m-0'>
                                        <div className='col-5 text-right p-0 field_style'>{data.assessed_leads.current}</div>
                                        <div className='col-6'>{this.PercentageChange(data.assessed_leads.current,data.assessed_leads.compr)}</div>
                                    </div>
                                    <div  class="text-center ">{this.calculatTotalPercentage(data.created_leads.current,data.assessed_leads.current,true, false, " from lead created")}</div>
                                </td>
                                <td class="text-center field_style">{data.assessed_leads.compr}</td>
                                <td class="text-center field_style"></td>

                            </tr>
                            <tr>
                                <td scope="row" className='text-white field_side_header'>Eligible Leads</td>
                                <td>
                                    <div className='row m-0'>
                                        <div className='col-5 text-right p-0 field_style'>{data.eligible_leads.current}</div>
                                        <div className='col-6'>{this.PercentageChange(data.eligible_leads.current,data.eligible_leads.compr)}</div>
                                    </div>
                                    <div  class="text-center ">{this.calculatTotalPercentage(data.assessed_leads.current,data.eligible_leads.current,true, false, " from assessment done")}</div>
                                </td>
                                <td class="text-center field_style">{data.eligible_leads.compr}</td>
                                <td class="text-center field_style"></td>

                            </tr>
                            <tr>
                                <td scope="row" className='text-white field_side_header'>Onboarded Customers</td>
                                <td>
                                    <div className='row m-0'>
                                        <div className='col-5 text-right p-0 field_style'>{data.acquisitions.current}</div>
                                        <div className='col-6'>{this.PercentageChange(data.acquisitions.current,data.acquisitions.compr)}</div>
                                    </div>
                                    <div  class="text-center ">{this.calculatTotalPercentage(data.eligible_leads.current,data.acquisitions.current,true, false, " from eligible leads")}</div>
                                </td>
                                <td class="text-center field_style">{data.acquisitions.compr}</td>
                                <td class="text-center field_style"></td>

                            </tr>
                            
                        </tbody>
                    </table>
                </div>
                
            </div>
        </div>  

         <div className='px-4 pb-4  my-4' style={{backgroundColor:'#20233F',borderRadius:'10px'}}>
            <div className=' d-flex align-items-center p-4'>
            <IoMdPerson color='white' size={32}/>
                <p className='text-white m-0 px-3' style={{fontSize:'25px'}}>Customer Metrics</p>
            </div>
            <div className='coo-efforts coo_dashboard_scroll' style={{borderTop:'2px solid black'}}>
                <div className='px-4 py-3'>
                    <table class="table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col" class="col-2"></th>
                                <th scope="col" className='col-2 text-white text-center field_header'>{moment(this.state.cur_month_start_date).format('MMM YYYY')} - MTD</th>
                                <th scope="col" className='col-2 text-white text-center field_header'>{moment(this.state.prevMonthDate).format('MMM YYYY')}</th>
                                {/* <th scope="col" className='col-2 text-white text-center field_header'>{moment(this.state.cur_month_start_date).format('MMM YYYY')} vs {moment(this.state.prevMonthDate).format('MMM YYYY')}</th> */}
                                <th scope="col" className='col-2 text-white text-center field_header'>{moment(this.state.cur_month_start_date).format('YYYY')} YTD</th>
                                <th scope="col" className='col-2 text-white text-center field_header'>Cumulative Count (Till Date)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="row" className='text-white field_side_header'>
                                    Registered
                                    <div className='d-inline-block pl-2 rm-performance' style={{}}>
                                        <RoundOffSplitter val={
                                            Config('self_reg') ?
                                            `This includes ${getValueFromLangJson("rm_label_short")}-registered and self-registered customers, who have taken atleast 1 FA` :  "Onboarded customers who have taken at least one FA"} unit={""} align_center={true} position={"top"} notify={true}/>    
                                    </div> 
                                </td>
                                <td>
                                    <div className='row m-0'>
                                        <div className='col-5 text-right p-0 field_style'>{data.registered_customers.current}</div>
                                        <div className='col-6'>{this.PercentageChange(data.registered_customers.current,data.registered_customers.compr)}</div>
                                    </div>
                                </td>
                                <td class="text-center field_style">{data.registered_customers.compr}</td>
                                <td class="text-center field_style">{data.registered_customers.year}</td>
                                <td class="text-center field_style">{data.registered_customers.cumulative}</td>
                            </tr>
                            {this.state.switch_data && 
                            <tr>
                                <td scope="row" className='text-white field_side_header'>
                                    New Switch
                                    <div className='d-inline-block pl-2 rm-performance' style={{}}>
                                        <RoundOffSplitter val={"This includes existing customers who did their first switch this month"} unit={""} align_center={true} position={"top"} notify={true}/>    
                                    </div> 
                                </td>
                                <td>
                                    <div className='row m-0'>
                                        <div className='col-5 text-right p-0 field_style'>{data.new_switch_custs.current}</div>
                                        <div className='col-6'>{this.PercentageChange(data.new_switch_custs.current,data.new_switch_custs.compr)}</div>
                                    </div>
                                </td>
                                <td class="text-center field_style">{data.new_switch_custs.compr}</td>
                                <td class="text-center field_style">{data.new_switch_custs.year}</td>
                                <td class="text-center field_style">{data.new_switch_custs.cumulative}</td>
                            </tr>
                            }
                            {this.state.switch_data && 
                            <tr>
                                <td scope="row" className='text-white field_side_header'>
                                    Switch Exclusive
                                    <div className='d-inline-block pl-2 rm-performance' style={{}}>
                                        <RoundOffSplitter val={"Customers  registered exclusive  only for switch"} unit={""} align_center={true} position={"top"} notify={true}/>    
                                    </div> 
                                </td>
                                <td>
                                    <div className='row m-0'>
                                        <div className='col-5 text-right p-0 field_style'>{data.switch_onboard_cust.current}</div>
                                        <div className='col-6'>{this.PercentageChange(data.switch_onboard_cust.current,data.switch_onboard_cust.compr)}</div>
                                    </div>
                                </td>
                                <td class="text-center field_style">{data.switch_onboard_cust.compr}</td>
                                <td class="text-center field_style">{data.switch_onboard_cust.year}</td>
                                <td class="text-center field_style">{data.switch_onboard_cust.cumulative}</td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='d-flex flex-row row m-0'>
                <div className=' col-lg-7 col-md-12 col-sm-12 ml-0 px-0 my-4 cust_metrx_padding' >
                    <div className='px-4 py-3 coo-efforts h-100 ' style={{borderTop:'2px solid black'}}>
                        <table class="table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col" class="col-2"></th>
                                    <th scope="col" className='col-2 text-white text-center field_header'>
                                        Current Count
                                        <div className='d-inline-block pl-2 rm-performance'>
                                            <RoundOffSplitter val={"This represents the total count as of yesterday"} unit={""} align_center={true} position={"top"} notify={true}/>    
                                        </div> 
                                    </th>
                                    <th scope="col" className='col-2 text-white text-center field_header'>% of Total Registered</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row" className='text-white field_side_header'>Enabled</td>
                                    <td class="text-center field_style">{data.enabled_customers}</td>
                                    {this.calculatTotalPercentage(data.registered_customers.cumulative,data.enabled_customers)}
                                </tr>
                                <tr>
                                    <td scope="row" className='text-white field_side_header'>Disabled</td>
                                    <td class="text-center field_style">{data.disabled_customers}</td>
                                    {this.calculatTotalPercentage(data.registered_customers.cumulative,data.disabled_customers)}
                                </tr>
                                <tr>
                                    <td scope="row" className='text-white field_side_header'>
                                        Active
                                        <div className='d-inline-block pl-2 rm-performance'>
                                                <RoundOffSplitter val={"Customers who have taken atleast 1 FA in last 30 days"} unit={""} align_center={true} position={"top"} notify={true}/>    
                                        </div> 
                                    </td>
                                    <td class="text-center field_style">{data.active_customers}</td>
                                    {this.calculatTotalPercentage(data.registered_customers.cumulative,data.active_customers)}
                                </tr>
                                {this.state.switch_data && 
                                <tr>
                                    <td scope="row" className='text-white field_side_header'>Switch</td>
                                    <td class="text-center field_style">{data.switch_custs.current}</td>
                                    {this.calculatTotalPercentage(data.registered_customers.cumulative,data.switch_custs.current)}
                                </tr>
                                }
                                <tr>
                                    <td scope="row" className='text-white field_side_header'>Inactive</td>
                                    <td class="text-center field_style">{data.inactive_customers}</td>
                                    {this.calculatTotalPercentage(data.registered_customers.cumulative,data.inactive_customers)}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className=' col-lg-5 col-md-12 col-sm-12 my-4  pr-0 cust_metrx_padding ' >
                    <div className='px-4 py-3 coo-efforts h-100 ' style={{borderTop:'2px solid black'}}>
                        <table class="table table-borderless h-100" style={{width:"100%"}}>
                            <thead>
                                <tr>
                                    <th scope="col" class="col-2"></th>
                                    <th scope="col" className='col-2 text-white text-center field_header'>
                                        Current Count
                                        <div className='d-inline-block pl-2 rm-performance' style={{}}>
                                            <RoundOffSplitter val={"This represents the total count as of yesterday"} unit={""} align_center={true} position={"top"} notify={true}/>    
                                        </div> 
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td scope="row" className='text-white field_side_header'>Probation</td>
                                    <td class="text-center field_style">{data.probation_customers}</td>
                                </tr>
                                <tr>
                                    <td scope="row" className='text-white field_side_header'>Regular</td>
                                    <td class="text-center field_style">{data.regular_customers}</td>
                                </tr>
                                <tr>
                                    <td scope="row" className='text-white field_side_header'>Pre-approved</td>
                                    <td class="text-center field_style">{data.preapproved_customers}</td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>   

        <div className='px-4 pb-4  my-4' style={{backgroundColor:'#20233F',borderRadius:'10px'}}>
            <div className=' d-flex align-items-center p-4'>
                <GoBriefcase  size={32} color='white'/>
                <p className='text-white m-0 px-3' style={{fontSize:'25px'}}>Portfolio</p>
            </div>  
            <div className='coo-efforts' style={{borderTop:'2px solid black'}}>
                <p className='text-white p-4 pl-4 m-0' style={{fontSize:'18px'}} >Business Performance</p>
                <div className='px-2 mx-4 py-3' style={{backgroundColor:'#2C2F4C'}}>
                    <div className='coo_dashboard_scroll'>
                    <table class="table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col" class="col-2"></th>
                                <th scope="col" className='col-4 text-white text-center field_header'>{moment(this.state.cur_month_start_date).format('MMM YYYY')} - MTD</th>
                                <th scope="col" className='col-3 text-white text-center field_header'>{moment(this.state.prevMonthDate).format('MMM YYYY')}</th>
                                <th scope="col" className='col-3 text-white text-center field_header'>{moment(this.state.cur_month_start_date).format('YYYY')} YTD</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="2">
                                    <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="col-3"></th>
                                            <th scope="col" className='col-4 text-white text-center field_side_header count_min_width'>Count</th>
                                            <th scope="col" className='col-5 text-white text-center field_side_header value_min_width'>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope="row" className='text-white field_side_header'>FA Disbursed</td>
                                            <td>
                                                <div className='row m-0'>
                                                    <div className='col-5 text-right p-0 field_style'>{data.fa_disbursed_count.current}</div>
                                                    <div className='col-6'>{this.PercentageChange(data.fa_disbursed_count.current,data.fa_disbursed_count.compr)}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='row m-0'>
                                                    <div className='col-5 text-right p-0 field_style '>{this.formatNumber(data.fa_disbursed_value.current)}</div>
                                                    <div className='col-6 '>{this.PercentageChange(data.fa_disbursed_value.current,data.fa_disbursed_value.compr)}</div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className='text-white field_side_header'>FA Settled</td>
                                            <td>
                                                <div className='row m-0'>
                                                    <div className='col-5 text-right p-0 field_style'>{data.fa_settled_count.current}</div>
                                                    <div className='col-6'>{this.PercentageChange(data.fa_settled_count.current,data.fa_settled_count.compr)}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='row m-0'>
                                                    <div className='col-5 text-right p-0 field_style'>{this.formatNumber(data.fa_settled_value.current)}</div>
                                                    <div className='col-6'>{this.PercentageChange(data.fa_settled_value.current,data.fa_settled_value.compr)}</div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className='text-white field_side_header'>
                                                FA Ongoing
                                                <div className='d-inline-block pl-2 rm-performance'>
                                                    <RoundOffSplitter val={"Outstanding FAs that are not yet overdue "} unit={""} align_center={true} position={"top"} notify={true}/>    
                                                </div>
                                            </td>
                                            <td>
                                                <div className='row m-0'>
                                                    <div className='col-5 text-right p-0 field_style'>{data.fa_og_count.current}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='row m-0'>
                                                    <div className='col-5 text-right p-0 field_style'>{this.formatNumber(data.fa_og_value.current)}</div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className='text-white field_side_header'>
                                                FA Overdue (1-5 Days)
                                            </td>
                                            <td>
                                                <div className='row m-0'>
                                                    <div className='col-5 text-right p-0 field_style'>{data.new_overdue_recovered_par_5_count.current}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='row m-0'>
                                                    <div className='col-5 text-right p-0 field_style'>{this.formatNumber(data.new_overdue_recovered_par_5_value.current)}</div>
                                                </div>
                                            </td>
                                        </tr>
                                        {Config('fa_upgrade') &&
                                        <>
                                        <tr>
                                            <td scope="row" className='text-white field_side_header'>FA Upgrades - Approved</td>
                                            <td>
                                                <div className='row m-0'>
                                                    <div className='col-5 text-right p-0 field_style'>{data.fa_upgrades_approved_count.current}</div>
                                                    <div className='col-6'>{this.PercentageChange(data.fa_upgrades_approved_count.current,data.fa_upgrades_approved_count.compr)}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='row m-0'>
                                                    <div className='col-5 text-right p-0 field_style'>{this.formatNumber(data.fa_upgrades_approved_value.current)}</div>
                                                    <div className='col-6 '>{this.PercentageChange(data.fa_upgrades_approved_value.current,data.fa_upgrades_approved_value.compr)}</div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td scope="row" className='text-white field_side_header'>FA Upgrades - Rejected</td>
                                            <td>
                                                <div className='row m-0'>
                                                    <div className='col-5 text-right p-0 field_style'>{data.fa_upgrades_rejected_count.current}</div>
                                                    <div className='col-6 '>{this.PercentageChange(data.fa_upgrades_rejected_count.current,data.fa_upgrades_rejected_count.compr, true)}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='row m-0'>
                                                    <div className='col-5 text-right p-0 field_style'>{this.formatNumber(data.fa_upgrades_rejected_value.current)}</div>
                                                    <div className='col-6'>{this.PercentageChange(data.fa_upgrades_rejected_value.current,data.fa_upgrades_rejected_value.compr, true)}</div>
                                                </div>
                                            </td>
                                        </tr>
                                        </>
                                        }
                                        {check_priv("report","coo_dash_revenue") &&
                                            <tr>
                                                <td scope="row" className='text-white field_side_header'>
                                                    Revenue Generated
                                                    <div className='d-inline-block pl-2 rm-performance'>
                                                        <RoundOffSplitter val={"It represents the fees and penalties for FA only"} unit={""} align_center={true} position={"top"} notify={true}/>    
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='row m-0'>
                                                        <div className='col-5 text-right p-0 field_style'>{data.revenue_generated_count.current}</div>
                                                        <div className='col-6'>{this.PercentageChange(data.revenue_generated_count.current,data.revenue_generated_count.compr)}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='row m-0'>
                                                        <div className='col-5 text-right p-0 field_style'>{this.formatNumber(data.revenue_generated_value.current)}</div>
                                                        <div className='col-6'>{this.PercentageChange(data.revenue_generated_value.current,data.revenue_generated_value.compr)}</div>
                                                    </div>
                                                </td>
                                            </tr>
                                            }
                                    </tbody>
                                    </table>
                                </td>
                                <td>
                                    <table class="table table-borderless">
                                        <thead>
                                            <tr className='col-12'>
                                                <th scope="col" className='col-6 text-white text-center field_side_header'>Count</th>
                                                <th scope="col" className='col-6 text-white text-center field_side_header'>Value</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{borderRight: "1px solid #7B7575"}}>
                                            <tr>
                                                <td class="text-center field_style">{data.fa_disbursed_count.compr}</td>
                                                <td class="text-right field_style coo_portfolio_padding" >{this.formatNumber(data.fa_disbursed_value.compr)}</td>
                                            </tr>
                                            <tr>
                                                <td class="text-center field_style">{data.fa_settled_count.compr}</td>
                                                <td class="text-right field_style coo_portfolio_padding" >{this.formatNumber(data.fa_settled_value.compr)}</td>
                                            </tr>
                                            <tr>
                                                <td class="text-center field_side_header" >NA</td>
                                                <td class="text-right field_side_header coo_portfolio_padding" >NA</td>
                                            </tr>
                                            <tr>
                                                {/* <td class="text-center field_style" >{data.new_overdue_recovered_par_5_count.compr}</td>
                                                <td class="text-right field_style coo_portfolio_padding" >{this.formatNumber(data.new_overdue_recovered_par_5_value.compr)}</td> */}
                                                <td class="text-center field_side_header" >NA</td>
                                                <td class="text-right field_side_header coo_portfolio_padding" >NA</td>
                                            </tr>
                                          { Config('fa_upgrade') && <>
                                            <tr>
                                                <td class="text-center field_style">{data.fa_upgrades_approved_count.compr}</td>
                                                <td class="text-right field_style coo_portfolio_padding" >{this.formatNumber(data.fa_upgrades_approved_value.compr)}</td>
                                            </tr>
                                            <tr>
                                                <td class="text-center field_style">{data.fa_upgrades_rejected_count.compr}</td>
                                                <td class="text-right field_style coo_portfolio_padding" >{this.formatNumber(data.fa_upgrades_rejected_value.compr)}</td>
                                            </tr>
                                            </>}
                                            { check_priv("report","coo_dash_revenue") &&
                                                <tr>
                                                    <td class="text-center field_style">{data.revenue_generated_count.compr}</td>
                                                    <td class="text-right field_style coo_portfolio_padding" >{this.formatNumber(data.revenue_generated_value.compr)}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <table class="table table-borderless">
                                        <thead>
                                            <tr className='col-12'>
                                                <th scope="col" className='col-6 text-white text-center field_side_header'>Count</th>
                                                <th scope="col" className='col-6 text-white text-center field_side_header'>Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="text-center field_style">{data.fa_disbursed_count.year}</td>
                                                <td class="text-right field_style coo_portfolio_padding" >{this.formatNumber(data.fa_disbursed_value.year)}</td>
                                            </tr>
                                            <tr>
                                                <td class="text-center field_style">{data.fa_settled_count.year}</td>
                                                <td class="text-right field_style coo_portfolio_padding" >{this.formatNumber(data.fa_settled_value.year)}</td>
                                            </tr>
                                            <tr>
                                                <td class="text-center field_side_header">NA</td>
                                                <td class="text-right field_side_header coo_portfolio_padding" >NA</td>
                                            </tr>
                                            <tr>
                                                {/* <td class="text-center field_style">{data.new_overdue_recovered_par_5_count.year}</td>
                                                <td class="text-right field_style coo_portfolio_padding" >{this.formatNumber(data.new_overdue_recovered_par_5_value.year)}</td> */}
                                                <td class="text-center field_side_header">NA</td>
                                                <td class="text-right field_side_header coo_portfolio_padding" >NA</td>
                                            </tr>
                                            {Config('fa_upgrade') &&
                                             <>
                                            <tr>
                                                <td class="text-center field_style">{data.fa_upgrades_approved_count.year}</td>
                                                <td class="text-right field_style coo_portfolio_padding" >{this.formatNumber(data.fa_upgrades_approved_value.year)}</td>
                                            </tr>
                                            <tr>
                                                <td class="text-center field_style">{data.fa_upgrades_rejected_count.year}</td>
                                                <td class="text-right field_style coo_portfolio_padding" >{this.formatNumber(data.fa_upgrades_rejected_value.year)}</td>
                                            </tr>
                                            </>
                                            }
                                            {check_priv("report","coo_dash_revenue") &&
                                                <tr>
                                                    <td class="text-center field_style">{data.revenue_generated_count.year}</td>
                                                    <td class="text-right field_style coo_portfolio_padding" >{this.formatNumber(data.revenue_generated_value.year)}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            
                        </tbody>
                               
                    </table>
                    </div>
                </div>
                
                <div className='px-4 mx-4 my-4 py-3 coo_dashboard_scroll' style={{backgroundColor:'#2C2F4C'}}>
                    <table class="table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col" class="col-2"></th>
                                <th scope="col" className='col-4 text-white text-center field_header' >{moment(this.state.cur_month_start_date).format('MMM YYYY')} - MTD</th>
                                <th scope="col" className='col-3 text-white text-center field_header'>{moment(this.state.prevMonthDate).format('MMM YYYY')}</th>
                                <th scope="col" className='col-3 text-white text-center field_header'>{moment(this.state.cur_month_start_date).format('YYYY')} YTD</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="row" className='text-white field_side_header'>Ontime Repayments</td>
                                <td>
                                    <div className='row m-0'>
                                        <div className='col-5 text-right p-0 field_style'>{this.calculatTotalPercentage(data.fa_settled_count.current,data.ontime_repayments.current, false, false, "num")}%</div>
                                        <div className='col-6'>{this.PercentageChange(this.calculatTotalPercentage(data.fa_settled_count.current,data.ontime_repayments.current, false, false, "num"),this.calculatTotalPercentage(data.fa_settled_count.compr,data.ontime_repayments.compr, false, false, "num"))}</div>
                                    </div>
                                </td>
                                {this.calculatTotalPercentage(data.fa_settled_count.compr,data.ontime_repayments.compr)}
                                {this.calculatTotalPercentage(data.fa_settled_count.year,data.ontime_repayments.year)}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='coo-efforts mt-5' style={{borderTop:'2px solid black'}}>
                <div className='d-flex justify-content-between mx-4 mt-3 align-items-center' style={{}}>
                    <p className='text-white m-0 my-3' style={{fontSize:'18px'}} >PAR Metrics</p>
                    {/* <div>
                        <p className='text-white m-0'><VscCircleFilled size={23} color={'green'}/> Recovered</p>
                        <p className='text-white m-0'><VscCircleFilled size={23} color={'red'}/> Unrecovered</p>
                    </div> */}
                </div> 
                <div>
                    {/* <div className='row m-0'>
                    <div className='col-xl-3 m-0 p-0 col-sm-12 col-lg-7' >
                        <div className='p-3 ml-4 coo_dashboard_table_bg coo_dashboard_scroll' style={{backgroundColor:'#2C2F4C', height:"95%"}}>
                        <table class="table table-borderless" style={{backgroundColor:'none'}}>
                            <thead>
                                <tr>
                                    <th scope="col" colSpan={'3'} className='text-white px-2 py-3' style={{fontSize:'18px', fontWeight:400}}>PAR Distribution</th>
                                </tr>
                            </thead>
                            <tbody>
                                <br/>
                                <tr>
                                    <td scope="row" className='text-white field_side_header'>PAR 3-15</td>
                                    <td class="text-right field_style" >{this.formatNumber(data.par_3_to_15.cumulative)}</td>
                                    {this.calculatTotalPercentage(data.par_1_to_2.cumulative + data.par_3_to_15.cumulative + data.par_16_to_30.cumulative + data.par_31_to_60.cumulative + data.par_61_to_90.cumulative + data.par_90_above.cumulative ,data.par_3_to_15.cumulative)}
                                </tr>
                                <tr>
                                    <td scope="row" className='text-white field_side_header'>PAR 16-30</td>
                                    <td class="text-right field_style" >{this.formatNumber(data.par_16_to_30.cumulative)}</td>
                                    {this.calculatTotalPercentage(data.par_1_to_2.cumulative + data.par_3_to_15.cumulative + data.par_16_to_30.cumulative + data.par_31_to_60.cumulative + data.par_61_to_90.cumulative + data.par_90_above.cumulative ,data.par_16_to_30.cumulative)}
                                </tr>
                                <tr>
                                    <td scope="row" className='text-white field_side_header'>PAR 31-60</td>
                                    <td class="text-right field_style" >{this.formatNumber(data.par_31_to_60.cumulative)}</td>
                                    {this.calculatTotalPercentage(data.par_1_to_2.cumulative + data.par_3_to_15.cumulative + data.par_16_to_30.cumulative + data.par_31_to_60.cumulative + data.par_61_to_90.cumulative + data.par_90_above.cumulative ,data.par_31_to_60.cumulative)}
                                </tr>
                                <tr>
                                    <td scope="row" className='text-white field_side_header'>PAR 61-90</td>
                                    <td class="text-right field_style" >{this.formatNumber(data.par_61_to_90.cumulative)}</td>
                                    {this.calculatTotalPercentage(data.par_1_to_2.cumulative + data.par_3_to_15.cumulative + data.par_16_to_30.cumulative + data.par_31_to_60.cumulative + data.par_61_to_90.cumulative + data.par_90_above.cumulative ,data.par_61_to_90.cumulative)}

                                </tr>
                                <tr>
                                    <td scope="row" className='text-white field_side_header'>PAR 90+</td>
                                    <td class="text-right field_style" >{this.formatNumber(data.par_90_above.cumulative)}</td>
                                    {this.calculatTotalPercentage(data.par_1_to_2.cumulative + data.par_3_to_15.cumulative + data.par_16_to_30.cumulative + data.par_31_to_60.cumulative + data.par_61_to_90.cumulative + data.par_90_above.cumulative ,data.par_90_above.cumulative)}
                                </tr>
                                <tr style={{borderTop:'1px solid #7B7575'}}>
                                    <td className='text-white field_side_header'>Total PAR</td>
                                    <td class="text-right field_style" >{this.formatNumber(data.par_1_to_2.cumulative + data.par_3_to_15.cumulative + data.par_16_to_30.cumulative + data.par_31_to_60.cumulative + data.par_61_to_90.cumulative + data.par_90_above.cumulative)}</td>
                                    <td class="text-center" ></td>
                                </tr>
                            </tbody>
                        </table>

                        </div>
                    </div>
                    <div className='col-xl-9 col-sm-12 pb-sm-3 pb-xl-0' >
                        <div className='p-3 mt-sm-4 mt-xl-0 coo_dashboard_scroll pb-4' style={{backgroundColor:'#2C2F4C', height:"95%"}}>
                        <p className='text-white pl-3 p-2 m-0' style={{fontSize:'18px', fontWeight:400}}>Recoveries</p>
                        <table class="table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col" class="col-1"></th>
                                    <th scope="col" className='col-6 text-white text-center field_header'>{moment(this.state.cur_month_start_date).format('MMM YYYY')} - MTD</th>
                                    <th scope="col" className='col-3 text-white text-center field_header'>{moment(this.state.prevMonthDate).format('MMM YYYY')}</th>
                                    <th scope="col" className='col-2 text-white text-center field_header'>{moment(this.state.cur_month_start_date).format('YYYY')} YTD</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <table class="table table-borderless">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody style={{borderRight: "1px solid #7B7575"}}>
                                                <tr><td scope="row" className='text-white field_side_header'>{"New Overdue (> PAR 5)"}<br/> <span style={{fontSize:"12px"}}>Unrecovered</span></td></tr>
                                                <tr><td scope="row" className='text-white field_side_header'>Overdue Recovered (PAR 15)<br/><span style={{fontSize:"12px"}}>Recovered</span></td></tr>
                                                <tr><td scope="row" className='text-white field_side_header'>Overdue Recovered (PAR 30)<br/><span style={{fontSize:"12px"}}>Recovered</span></td></tr>
                                                <tr>
                                                    <td scope="row" className='text-white field_side_header'>Outstanding - PAR 30 
                                                        <div className='d-inline-block pl-2 rm-performance'>
                                                            <RoundOffSplitter val={"Unpaid amounts of partially paid FAs"} unit={""} align_center={true} position={"top"} notify={true}/>    
                                                        </div> <br/> <span style={{fontSize:"12px"}}>(Partial Payments)</span></td>
                                                </tr>
                                            
                                            </tbody> 
                                        </table>
                                    </td>
                                    <td>
                                        <table class="table table-borderless">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className='col-4 text-white text-center field_side_header'>Count</th>
                                                    <th scope="col" className='col-8 text-white text-center field_side_header'>Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className='row m-0'>
                                                            <div className='col-6 text-right p-0 field_style'>{data.new_overdue_recovered_par_5_count.current}</div>
                                                            <div className='col-6'>{this.PercentageChange(data.new_overdue_recovered_par_5_count.current,data.new_overdue_recovered_par_5_count.compr, true)}</div>
                                                        </div><br/>
                                                    </td>
                                                    <td>
                                                        <div className='row m-0'>
                                                            <div className='col-7 text-right p-0 field_style'>{this.formatNumber(data.new_overdue_recovered_par_5_value.current)}</div>
                                                            <div className='col-5'>{this.PercentageChange(data.new_overdue_recovered_par_5_value.current,data.new_overdue_recovered_par_5_value.compr, true)}</div>
                                                        </div><br/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className='row m-0'>
                                                            <div className='col-6 text-right p-0 field_style'>{data.overdue_recovered_par_15_count.current}</div>
                                                            <div className='col-6'>{this.PercentageChange(data.overdue_recovered_par_15_count.current,data.overdue_recovered_par_15_count.compr)}</div>
                                                        </div><br/>
                                                    </td>
                                                    <td>
                                                        <div className='row m-0'>
                                                            <div className='col-7 text-right p-0 field_style'>{this.formatNumber(data.overdue_recovered_par_15_value.current)}</div>
                                                            <div className='col-5'>{this.PercentageChange(data.overdue_recovered_par_15_value.current,data.overdue_recovered_par_15_value.compr)}</div>
                                                        </div><br/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className='row m-0'>
                                                            <div className='col-6 text-right p-0 field_style'>{data.overdue_recovered_par_30_count.current}</div>
                                                            <div className='col-6'>{this.PercentageChange(data.overdue_recovered_par_30_count.current,data.overdue_recovered_par_30_count.compr)}</div>
                                                        </div><br/>
                                                    </td>
                                                    <td>
                                                        <div className='row m-0'>
                                                            <div className='col-7 text-right p-0 field_style'>{this.formatNumber(data.overdue_recovered_par_30_value.current)}</div>
                                                            <div className='col-5'>{this.PercentageChange(data.overdue_recovered_par_30_value.current,data.overdue_recovered_par_30_value.compr)}</div>
                                                        </div><br/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className='row m-0'>
                                                            <div className='col-6 text-right p-0 field_style'>{data.outstanding_par_30_count.current}</div>
                                                            <div className='col-6'>{this.PercentageChange(data.outstanding_par_30_count.current,data.outstanding_par_30_count.compr, true)}</div>
                                                        </div><br/>
                                                    </td>
                                                    <td>
                                                        <div className='row m-0'>
                                                            <div className='col-7 text-right p-0 field_style'>{this.formatNumber(data.outstanding_par_30_value.current)}</div>
                                                            <div className='col-5'>{this.PercentageChange(data.outstanding_par_30_value.current,data.outstanding_par_30_value.compr, true)}</div>
                                                        </div><br/>
                                                    </td>
                
                                                </tr>
                                            </tbody> 
                                        </table>  
                                    </td> 
                                    <td>
                                        <table class="table table-borderless">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className='col-6 text-white text-center field_side_header'>Count<br/></th>
                                                    <th scope="col" className='col-6 text-white text-center field_side_header'>Value<br/></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="text-center field_style" ><div>{data.new_overdue_recovered_par_5_count.compr}</div><br/></td>
                                                    <td class="text-right field_style" ><div>{this.formatNumber(data.new_overdue_recovered_par_5_value.compr)}</div><br/></td>
                                                    
                                                </tr>
                                                <tr>
                                                    <td class="text-center field_style" ><div>{data.overdue_recovered_par_15_count.compr}</div><br/></td>
                                                    <td class="text-right field_style" ><div>{this.formatNumber(data.overdue_recovered_par_15_value.compr)}</div><br/></td>
                                                </tr>
                                                <tr>
                                                    <td class="text-center field_style" ><div>{data.overdue_recovered_par_30_count.compr}</div><br/></td>
                                                    <td class="text-right field_style" ><div>{this.formatNumber(data.overdue_recovered_par_30_value.compr)}</div><br/></td>
                                                </tr>
                                                <tr>
                                                    <td class="text-center field_style" ><div>{data.outstanding_par_30_count.compr}</div><br/></td>
                                                    <td class="text-right field_style" ><div>{this.formatNumber(data.outstanding_par_30_value.compr)}</div><br/></td>
                
                                                </tr>
                                            </tbody> 
                                        </table>  
                                    </td> 
                                    <td>
                                        <table class="table table-borderless">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className='text-white text-center field_side_header'>Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr><td className='text-center field_side_header'><div>NA</div><br/></td></tr>
                                            <tr><td className='text-center field_side_header'><div>NA</div><br/></td></tr>
                                            <tr><td className='text-center field_side_header'><div>NA</div><br/></td></tr>
                                            <tr><td className='text-center field_style' ><div>{this.formatNumber(data.outstanding_par_30_value.year)}</div><br/></td></tr>
                                            
                                            </tbody> 
                                        </table>
                                    </td>
                                </tr>                   
                            </tbody>
                        </table>   
                        </div>  
                    </div>
                    </div> */}
                    <div className='d-flex justify-content-between coo_dashboard_scroll'>
                        <div className=' col-lg-6 col-md-6'>
                            <div className='col-12 text-center p-3 coo_par_header'>PAR Distribution</div>

                            <table className='w-100 coo_dashboard_par_metrics_table'>
                                <thead>
                                    <tr>
                                    <th></th>
                                    <th colspan="2" className='field_header'>Today</th>
                                    <th colspan="2" className='field_header'>As On {moment(this.state.prevMonthDate).format('MMMM D')}{get_suffix_date_str(moment(this.state.prevMonthDate).format('D'))}</th>
                                    </tr>
                                    <tr>
                                    <th></th>
                                    <th>Count</th>
                                    <th>Value</th>
                                    <th>Count</th>
                                    <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.par_metrics && this.state.par_metrics.map((val)=>{
                                        if(val.show || this.state.show_all){
                                            return(
                                                <tr>
                                                    <td className='text-left field_side_header'>{val.label}</td>
                                                    <td>
                                                        <div className='row m-0'>
                                                            <div className='col-12 text-center p-0 field_style'>{data[val.key + '_count'].current}</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='row m-0'>
                                                            <div className='col-5 text-right p-0 field_style'>{this.formatNumber(data[val.key + '_value'].current)}</div>
                                                            <div className='col-6 text-left'>{this.PercentageChange(data[val.key + '_value'].current, data[val.key + '_value'].compr, true)}</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='row m-0'>
                                                            <div className='col-12 text-center p-0 field_style'>{data[val.key + '_count'].compr}</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='row m-0'>
                                                            <div className='col-12 text-center p-0 field_style'>{this.formatNumber(data[val.key + '_value'].compr)}</div>
                                                        </div>
                                                    </td>
                                                
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                                </table>
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <div className='col-12 text-center p-3 coo_par_header'>Recoveries</div>
                            <table className='w-100 coo_dashboard_par_metrics_table'>
                                <thead>
                                    <tr>
                                    <th colspan="2" className='field_header'>{moment(this.state.cur_month_start_date).format('MMM YYYY')} - MTD</th>
                                    <th colspan="2" className='field_header'>{moment(this.state.prevMonthDate).format('MMM YYYY')}</th>
                                    </tr>
                                    <tr>
                                    <th>Count</th>
                                    <th>Value</th>
                                    <th>Count</th>
                                    <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.par_metrics && this.state.par_metrics.map((val)=>{
                                        if(val.show || this.state.show_all){
                                            return(
                                                <tr>
                                                    <td>
                                                        <div className='row m-0'>
                                                            <div className='col-12 text-center p-0 field_style'>{data[val.key + '_recovered_count'].current}</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='row m-0'>
                                                            <div className='col-5 text-right p-0 field_style'>{this.formatNumber(data[val.key + '_recovered_value'].current)}</div>
                                                            <div className='col-6 text-left'>{this.PercentageChange(data[val.key + '_recovered_value'].current, data[val.key + '_recovered_value'].compr)}</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='row m-0'>
                                                            <div className='col-12 text-center p-0 field_style'>{data[val.key + '_recovered_count'].compr}</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='row m-0'>
                                                            <div className='col-12 text-center p-0 field_style'>{this.formatNumber(data[val.key + '_recovered_value'].compr)}</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                                <tfoot >
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td   onClick={()=>this.handle_show_more()} style={{cursor:'pointer'}}>
                                            <a >{this.state.show_all ? "Show less" : "Show more"}</a>
                                        </td>
                                    </tr>
                                    </tfoot>
                                </table>
                        </div>
                        
                    </div>
                </div>
            </div>


        </div> 

        <div className='px-4 pb-4  my-4' style={{backgroundColor:'#20233F',borderRadius:'10px'}}>
            <div className=' d-flex align-items-center p-4'>
                <IoIosPhonePortrait size={32} color='white'/>
                <p className='text-white m-0 px-3' style={{fontSize:'25px'}}>Customer App Usage</p>
            </div>
            <div className='coo-efforts' style={{borderTop:'2px solid black'}}>
                <div className='px-4  py-3 coo_dashboard_scroll' >
                    <table class="table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col" className="col-2"></th>
                                <th scope="col" className='col-4 text-white text-center field_header'>{moment(this.state.cur_month_start_date).format('MMM YYYY')} - MTD</th>
                                <th scope="col" className='col-2 text-white text-center field_header'>{moment(this.state.prevMonthDate).format('MMM YYYY')}</th>
                                <th scope="col" className='col-2 text-white text-center field_header'>{moment(this.state.cur_month_start_date).format('YYYY')} YTD</th>
                                <th scope="col" className="col-2 text-white text-center field_header">Cumulative Count <br/> (Till Date)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td colspan="5" >
                            
                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="col-2"></th>
                                            <th scope="col" className='col-2 text-white text-center field_side_header'>Count</th>
                                            <th scope="col" className='col-2 text-white text-center field_side_header'>% of Total Registered</th>
                                            <th scope="col" className='col-1 text-white text-center field_side_header'>Count</th>
                                            <th scope="col" className='col-1 text-white text-center field_side_header'>% of Total Registered</th>
                                            <th scope="col" className='col-1 text-white text-center field_side_header'>Count</th>
                                            <th scope="col" className='col-1 text-white text-center field_side_header'>% of Total Registered</th>
                                            <th scope="col" className='col-2 text-white text-center field_side_header'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope="row" className='text-white field_side_header'>
                                            {getValueFromLangJson("rm_label_short")} registered Customers
                                                <div className='d-inline-block pl-2 rm-performance'>
                                                    <RoundOffSplitter val={`Mobile App users onboarded via ${getValueFromLangJson('rm_label_short')}s`} unit={""} align_center={true} position={"top"} notify={true}/>    
                                                </div>
                                            </td>
                                            <td>
                                                <div className='row m-0'>
                                                    <div className='col-5 text-right p-0 field_style'>{data.rm_registered_customers.current}</div>
                                                    <div className='col-6'>{this.PercentageChange(data.rm_registered_customers.current,data.rm_registered_customers.compr)}</div>
                                                </div>
                                            </td>
                                            {this.calculatTotalPercentage(data.registered_customers.cumulative,data.rm_registered_customers.current)}
                                            <td class="text-center field_style">{data.rm_registered_customers.compr}</td>
                                            {this.calculatTotalPercentage(data.reg_cust_compr,data.rm_registered_customers.compr)}
                                            <td class="text-center field_style">{data.rm_registered_customers.year}</td>
                                            {this.calculatTotalPercentage(data.registered_customers.cumulative,data.rm_registered_customers.year)}
                                            <td class="text-center field_style">{data.rm_registered_customers.cumulative}</td>
                                        </tr>
                                        {Config('self_reg') &&
                                        <tr>
                                            <td scope="row" className='text-white field_side_header'>
                                                Self-registered Customers
                                                <div className='d-inline-block pl-2 rm-performance'>
                                                    <RoundOffSplitter val={"Customers who have onboarded themselves"} unit={""} align_center={true} position={"top"} notify={true}/>    
                                                </div>
                                            </td>
                                            <td>
                                                <div className='row m-0'>
                                                    <div className='col-5 text-right p-0 field_style'>{data.self_registered_customers.current}</div>
                                                    <div className='col-6'>{this.PercentageChange(data.self_registered_customers.current,data.self_registered_customers.compr)}</div>
                                                </div>
                                            </td>
                                            {this.calculatTotalPercentage(data.registered_customers.cumulative,data.self_registered_customers.current)}
                                            <td class="text-center field_style">{data.self_registered_customers.compr}</td>
                                            {this.calculatTotalPercentage(data.registered_customers.cumulative,data.self_registered_customers.compr)}
                                            <td class="text-center field_style">{data.self_registered_customers.year}</td>
                                            {this.calculatTotalPercentage(data.registered_customers.cumulative,data.self_registered_customers.year)}
                                            <td class="text-center field_style">{data.self_registered_customers.cumulative}</td>
                                        </tr>
                                        }

                                    </tbody>
                                </table>
                            </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </div>

            <div className='coo-efforts mt-5' style={{borderTop:'2px solid black'}}>
                <div className='px-4  py-3 coo_dashboard_scroll' >
                    <table class="table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col" className="col-2"></th>
                                <th scope="col" className='col-4 text-white text-center field_header'>{moment(this.state.cur_month_start_date).format('MMM YYYY')} - MTD</th>
                                <th scope="col" className='col-2 text-white text-center field_header'>{moment(this.state.prevMonthDate).format('MMM YYYY')}</th>
                                <th scope="col" className='col-2 text-white text-center field_header'>{moment(this.state.cur_month_start_date).format('YYYY')} YTD</th>
                                <th scope="col" className="col-2 text-white text-center field_header">Cumulative Count <br/> (Till Date)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="5" >
                                    <table class="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="col-2"></th>
                                                <th scope="col" className='col-2 text-white text-center field_side_header'>Count</th>
                                                <th scope="col" className='col-2 text-white text-center field_side_header'>% of Total App Users</th>
                                                <th scope="col" className='col-1 text-white text-center field_side_header'>Count</th>
                                                <th scope="col" className='col-1 text-white text-center field_side_header'>% of Total App Users</th>
                                                <th scope="col" className='col-1 text-white text-center field_side_header'>Count</th>
                                                <th scope="col" className='col-1 text-white text-center field_side_header'>% of Total App Users</th>
                                                <th scope="col" className='col-2 text-white text-center field_side_header'></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td scope="row" className='text-white field_side_header'>
                                                    Active App Users
                                                    <div className='d-inline-block pl-2 rm-performance'>
                                                        <RoundOffSplitter val={`Customers who have used the mobile app for FA  ${ switch_support ? "or Float Switch" :""} in last 15 days`} unit={""} align_center={true} position={"top"} notify={true}/>    
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='row m-0'>
                                                        <div className='col-5 text-right p-0 field_style'>{data.active_app_users.current}</div>
                                                    </div>
                                                </td>
                                                {this.calculatTotalPercentage(data.rm_registered_customers.cumulative + data.self_registered_customers.cumulative, data.active_app_users.current)}
                                                <td class="text-center field_side_header">NA</td>
                                                <td class="text-center field_side_header">NA</td>
                                                <td class="text-center field_side_header">NA</td>
                                                <td class="text-center field_side_header">NA</td>
                                                <td class="text-center field_side_header">NA</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" className='text-white field_side_header'>Float Advances</td>
                                                <td>
                                                    <div className='row m-0'>
                                                        <div className='col-5 text-right p-0 field_style'>{data.fa_appl_custs.current}</div>
                                                        <div className='col-6'>{this.PercentageChange(data.fa_appl_custs.current,data.fa_appl_custs.compr)}</div>
                                                    </div>
                                                </td>
                                                {this.calculatTotalPercentage(data.rm_registered_customers.cumulative + data.self_registered_customers.cumulative, data.fa_appl_custs.current)}
                                                <td class="text-center field_style">{data.fa_appl_custs.compr}</td>
                                                {this.calculatTotalPercentage(data.rm_reg_cust_compr + data.self_reg_cust_compr, data.fa_appl_custs.compr)}
                                                <td class="text-center field_style">{data.fa_appl_custs.year}</td>
                                                {this.calculatTotalPercentage(data.rm_registered_customers.cumulative + data.self_registered_customers.cumulative, data.fa_appl_custs.year)}
                                                <td class="text-center field_style">{data.fa_appl_custs.cumulative}</td>
                                            </tr>
                                            { Config('switch_supported_countries')?.includes(this.state.market.country_code)  && 
                                            <>
                                            <tr>
                                                <td scope="row" className='text-white field_side_header'>Float Switches</td>
                                                <td>
                                                    <div className='row m-0'>
                                                        <div className='col-5 text-right p-0 field_style'>{data.cust_app_switch.current}</div>
                                                        <div className='col-6'>{this.PercentageChange(data.cust_app_switch.current,data.cust_app_switch.compr)}</div>
                                                    </div>
                                                </td>
                                                {this.calculatTotalPercentage(data.rm_registered_customers.cumulative + data.self_registered_customers.cumulative, data.cust_app_switch.current)}
                                                <td class="text-center field_style">{data.cust_app_switch.compr}</td>
                                                {this.calculatTotalPercentage(data.rm_reg_cust_compr + data.self_reg_cust_compr, data.cust_app_switch.compr)}
                                                <td class="text-center field_style">{data.cust_app_switch.year}</td>
                                                {this.calculatTotalPercentage(data.rm_registered_customers.cumulative + data.self_registered_customers.cumulative, data.cust_app_switch.year)}
                                                <td class="text-center field_style">{data.cust_app_switch.cumulative}</td>
                                            </tr>
                                            
                                            <tr>
                                                <td scope="row" className='text-white field_side_header'>
                                                FA & Switch
                                                    <div className='d-inline-block pl-2 rm-performance'>
                                                        <RoundOffSplitter val={"Customers who have used FA apply feature and switch product"} unit={""} align_center={true} position={"top"} notify={true} />    
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='row m-0'>
                                                        <div className='col-5 text-right p-0 field_style'>{data.fa_appl_switch_custs.current}</div>
                                                        <div className='col-6'>{this.PercentageChange(data.fa_appl_switch_custs.current,data.fa_appl_switch_custs.compr)}</div>
                                                    </div>
                                                </td>
                                                {this.calculatTotalPercentage(data.rm_registered_customers.cumulative + data.self_registered_customers.cumulative, data.fa_appl_switch_custs.current)}
                                                <td class="text-center field_style">{data.fa_appl_switch_custs.compr}</td>
                                                {this.calculatTotalPercentage(data.rm_reg_cust_compr + data.self_reg_cust_compr, data.fa_appl_switch_custs.compr)}
                                                <td class="text-center field_style">{data.fa_appl_switch_custs.year}</td>
                                                {this.calculatTotalPercentage(data.rm_registered_customers.cumulative + data.self_registered_customers.cumulative, data.fa_appl_switch_custs.year)}
                                                <td class="text-center field_style">{data.fa_appl_switch_custs.cumulative}</td>
                                            </tr> 
                                            </>
                                            }
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
        </div>  
        </>
        }
        {this.state.no_data &&
            <h6 className='text-center text-light p-5 m-5'>No Data Found</h6>
        }
        {this.state.loader  && 
        <div style={{marginTop:"15%"}} > <p className='text-light mt-3 text-center'>Loading...</p>
            <div className="product_takeup_loader"></div>
        </div>
        }
           

</div>
 
      
      
    </div>
    )
  }
}
