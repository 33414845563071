import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DELIQUENCY_ROUTE } from "../../../consts";
import { useSoftPhoneAndFreshChatCtx } from "../../../context/softPhoneAndFreshChatModalUICtx/useSoftPhoneAndFreshChatCtx";
import { useSoftPhoneCtx } from "../../../context/softPhoneCtx/useSoftPhoneCtx";
import OverdueCallTasks from "./OverdueCallTasks";
import { DELIQUENCY_CALL_TASK_LOG_MODAL, DELIQUENCY_OVERDUE_BY_COMMIT_DATE_LOG_MODAL, DELIQUENCY_OVERDUE_BY_DAYS_LOG_MODAL } from "../../../context/softPhoneCtx/softPhoneCtx";

const DeliquencyManagement = () => {
  // softphone ctx
  const { softPhoneState, methods: softPhoneMethods } = useSoftPhoneCtx();

  // float ui and fresh chat ctx
  const {
    softPhoneAndFreshChatFloatUIState,
    methods: softPhoneAndFreshChatMethods,
  } = useSoftPhoneAndFreshChatCtx();

  // hook
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // state
  const [isValidating, setisValidating] = useState(true);

  // const
  const deliquencyId = searchParams.get("delinquency_id");
  const delinquencyTaskId = searchParams.get("delinquency_task_id");
  const screen = searchParams.get("screen");
  const isPendingCallLog = deliquencyId || delinquencyTaskId ? true : false;

  // effect
  useEffect(() => {
    getUserCallLog();
  }, []);

  // cb
  const getUserCallLog = async () => {
    if (isPendingCallLog) {
      const { hasAnyPrevPendCallLog, callLog } =
        await softPhoneMethods.checkUserHasAnyPendingCallLog({ screen: "" });
      let isUserDoingAnyWrongAction = true;
      if (
        hasAnyPrevPendCallLog &&
        callLog?.screen === DELIQUENCY_OVERDUE_BY_COMMIT_DATE_LOG_MODAL &&
        deliquencyId === callLog?.delinquency_id
      ) {
        isUserDoingAnyWrongAction = false;
      }
      if (
        hasAnyPrevPendCallLog &&
        callLog?.screen === DELIQUENCY_OVERDUE_BY_DAYS_LOG_MODAL &&
        deliquencyId === callLog?.delinquency_id
      ) {
        isUserDoingAnyWrongAction = false;
      }
      if (
        hasAnyPrevPendCallLog &&
        callLog?.screen === DELIQUENCY_CALL_TASK_LOG_MODAL &&
        delinquencyTaskId === callLog?.delinquency_task_id
      ) {
        isUserDoingAnyWrongAction = false;
      } 
      if (isUserDoingAnyWrongAction) {
        navigateToDeliquencyRoute();
      }
      setisValidating(false);
    }
    setisValidating(false);
  };

  const navigateToDeliquencyRoute = () => navigate(DELIQUENCY_ROUTE);

  if (isValidating) return <></>;

  return (
    <OverdueCallTasks
      softPhoneCtxState={softPhoneState}
      softPhoneCtxMethods={softPhoneMethods}
      softPhoneAndFreshChatCtxFloatUIState={softPhoneAndFreshChatFloatUIState}
      softPhoneAndFreshChatCtxMethods={softPhoneAndFreshChatMethods}
      isPendingCallLog={isPendingCallLog}
      deliquencyId={deliquencyId}
      delinquencyTaskId={delinquencyTaskId}
      navigateToDeliquencyRoute={navigateToDeliquencyRoute}
      screen={screen}
    />
  );
};

export default DeliquencyManagement;
