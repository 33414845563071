import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import {Form, Col,Checkbox,Button} from 'react-bootstrap';
import DatePicker from 'react-datetime';
import Select from "react-select";
import { getValueFromLangJson } from '../../../helpers/common_helper';
import { Config, get } from '../../../helpers/storage_helper';

class SearchLead extends FlowComponent{

    state ={
        addr_hiearchy: Config("addr_hiearchy")[get("market").country_code]
    }
    componentWillMount(){
        this.load_flow_rel_mgr(null,"multiselect_rm")
        this.setOptionsListToStateFromAppApi('common', 'list',this.req({country_code : this.country_code, status: "enabled",biz_account:true}) , ['acc_prvdr_code', 'name'], "acc_prvdrs");
        this.setOptionsListToStateFromAppApi("common","priv_users",this.req({priv_code:'',status:'enabled',role_codes:["operations_auditor"]}), ['id' , 'name']);
    }

   

    render(){
        return(
            <Form className="mb-3">
	        	<Form.Row className = "my-4" style={{display:"flex",alignItems:"center"}}>
                    <div className="col-3 mt-4">
                        <span>Created From</span>
                        <DatePicker onChange={(value) => {this.handleDateChange("created_from", value)}}   closeOnSelect={true}     dateFormat="DD MMM YYYY" timeFormat={false}  />
                    </div>
                    <div className="col-3 mt-4">
                        <span>Created To</span>
                        <DatePicker onChange={(value) => {this.handleDateChange("created_to", value)}}   closeOnSelect={true}     dateFormat="DD MMM YYYY" timeFormat={false}  />
                    </div>
                    <div className="col-3 mt-4 ">
                     <span>  {getValueFromLangJson("rm_label_short")}</span>

                        <Select onChange={(e) =>{this.props.handlePurposeChange(e,"flow_rel_mgr_id");}}
                              value = {this.state.select_value}
							  options={this.state.flow_rel_mgr}
							  placeholder="choose"
							  styles={this.select_custom_styles()}
							  classNamePrefix="my-select" // Add a custom class prefix to target the components
							  isMulti/>
                    </div>
                    {this.state.addr_hiearchy?.includes('territory') && 
                    <div className="col-3 mt-4">
                        <span>Territory</span>
                        <Select onChange={(e) =>{this.props.handlePurposeChange(e,"territory");}}
                              
                              options={this.getMultiSelectOptions('territory')} 
							  placeholder="choose"
							  styles={this.select_custom_styles()}
							  classNamePrefix="my-select" 
							  isMulti/>
                            
                    </div>
    }                         
                    {/* </Form.Row>
                <Form.Row className = "my-4" style={{display:"flex",alignItems:"center"}}> */}
                    <div className="col-3 mt-4 no-padding-input">
                        <span>Account Provider</span>
                        <Form.Control as="select" id="acc_prvdr_code"  onChange={this.handleChange}>
                            {this.state.acc_prvdrs_dd}
                        </Form.Control>
                    </div>
                    <div className="col-3 mt-4 no-padding-input">
                        <span>Account Number</span>
                        <Form.Control id="account_num" onChange={this.handleChange} >         
                        </Form.Control>
                    </div>
                    <div className="col-3 mt-4 no-padding-input">
                        <span>Alter Account Number</span>
                        <Form.Control id="alt_account_num" onChange={this.handleChange} >         
                        </Form.Control>
                    </div>
                    <div className="col-3 mt-4">
                        <span>Location</span>
                        <Select onChange={(e) =>{this.props.handlePurposeChange(e,"location");}}
                              
                              options={this.getMultiSelectOptions('location')} 
							  placeholder="choose"
							  styles={this.select_custom_styles()}
							  classNamePrefix="my-select" 
							  isMulti/>
                      
                    </div>
                {/* </Form.Row>
                <Form.Row className = "my-4" style={{display:"flex",alignItems:"center"}}> */}
                    <div className="col-3 mt-4 no-padding-input">
                        <span>Biz Name (or) Cust Name</span>
                        <Form.Control id="biz_name" onChange={this.handleChange} >         
                        </Form.Control>   
                    </div>
                    <div className="col-3 mt-4 no-padding-input">
                        <span>Mobile Number</span>
                        <Form.Control id="mobile_num" onChange={this.handleChange} >         
                        </Form.Control>   
                    </div>
                    <div className="col-3 mt-4 no-padding-input">
                        <span>Cust ID</span>
                        <Form.Control id="cust_id" onChange={this.handleChange} >         
                        </Form.Control>   
                    </div>

                    <div className="col-3 mt-4 no-padding-input">
                        <span>Account Purpose</span>
                        <Form.Control as="select" id="acc_purpose"  onChange={this.handleChange}>
                            {this.getSelectOptions("account_purpose", true, 'customer')}
                        </Form.Control>
                    </div>          
                {/* </Form.Row>
                <Form.Row className ="my-4" style={{display:"flex",alignItems:"center"}}> */}
                    <div className="col-3 mt-4 no-padding-input">
                        <span>Lead Status</span>
                        <Form.Control as="select" id="status"   onChange={this.handleChange}>
                            {this.getSelectOptions("lead_status")}
                        </Form.Control>
                    </div>  
                    <div className="col-3 mt-4 no-padding-input">
                        <span>National ID</span>
                        <Form.Control id="national_id" onChange={this.handleChange} >         
                        </Form.Control>   
                    </div>
                    <div className="col-3 mt-4 no-padding-input">
                        <span>Lead Type</span>
                        <Form.Control as="select" id="type"  onChange={this.handleChange}>
                            {this.getSelectOptions("lead_type")}
                        </Form.Control>
                    </div>
                    <div className="col-3 mt-4 no-padding-input">
                        <span> Audited By</span>
                        <Form.Control as="select" id="audited_by"  onChange={this.handleChange}>
                            {this.state.priv_users_dd}
                        </Form.Control>
                    </div>
                    <div className="col-3 mt-4 no-padding-input">
                        <span>Lead Channel</span>
                        <Form.Control as="select" id="channel"  onChange={this.handleChange}>
                            {this.getSelectOptions("lead_channel")}
                        </Form.Control>
                    </div>
                    <div className='col-3 mt-4'>
                        <Form.Group as={Col} className=''>
                            <Form.Label for="closed_profile" className='no-margin'> Include Closed Profiles
                                <Form.Check type="checkbox"  defaultChecked={this.props.profile_status.closed_profile} id = "closed_profile"  className= "float-left no-margin" onChange={(e) =>{this.props.onComponentElementChange(e)}} style={{paddingRight:5,paddingLeft:5}}/>
                            </Form.Label>
                        </Form.Group>
                    </div>
                    <Form.Group as={Col} className="col-3"></Form.Group>
                </Form.Row>

            </Form>
        )
    }
}
export default SearchLead;
