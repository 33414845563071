import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';

import {get, Config} from '../../../helpers/storage_helper';
import {capitalize_first_letter,getLabel,getValueFromLangJson,get_dir_path} from '../../../helpers/common_helper';
import DisplayImage from '../../common/component/DisplayImage';

import {dd_value} from '../../../actions/common_actions';
import { connect } from 'react-redux';



class AuditKYCOwnerInfoComponent extends FlowComponent{
    constructor() {
        super();
       
        this.national_id_details=['first_name','last_name','national_id','gender','dob']
        
        this.state = {
            county_list : ['UGA', 'MDG']
        }
    }

      audit_section_spinner(){
        return(
          <div class="audit_spinner" style={{margin:'20vh 0px  30vh 45%'}}>
          <span class="level level-1"></span>
          <span class="level level-2"></span>
          <span class="level level-3"></span>
          <span class="level level-4"></span>
          <span class="level level-5"></span>
          <span class="level level-6"></span>
        </div>
        )
      }

      checkbox_spinner(){
        return(
          <div class="d-flex col-lg-10 py-2 px-5 mt-4 justify-content-center ml-5">
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
         </div>
        )
      }
      
      rekyc_modify_state_handler(json_type,json_key,key){
        if((this.props.data[json_type][json_key][key].hasOwnProperty("modified")) && (this.props.data[json_type][json_key][key].modified)){
          return 'new_kyc_json';
        }else{
          return "";
          }

      }
    
      license_proof_check_boxes(biz_lic_proof_type){
        return(
            <form className={`${(this.props.owner_and_biz_info_data.active_section.verify || (this.props.owner_and_biz_info_data.active_section.to_reassign==true) )? "disable_div":''}  ${ ((this.props.owner_and_biz_info_data.responseData.audited_by== get("user_id")) || (this.props.owner_and_biz_info_data.responseData.audited_by==null )) ?"":'disable_div'}`}>
            <div className='d-flex align-items-center'>
            <input type="checkbox" id= {biz_lic_proof_type+"_1"} className='pointer' style={{height:'19px',width:'20px'}} checked={ audit_kyc &&this.props.audit_kyc['id_proof'].is_original && true} name="biz_lic_proof1" value="Bike" onChange={(e)=>this.props.checkbox_verification(e.target.checked,"is_original")}/>
            <label className='no-margin pl-3 audit_kyc_text pointer'  for={biz_lic_proof_type+"_1"}>The  {biz_lic_proof_type} submitted by the customer is original</label><br/><br/><br/>
            </div>
              <div className='d-flex align-items-center'>
            <input type="checkbox" id= {biz_lic_proof_type+"_2"}  className='pointer' style={{height:'19px',width:'20px'}} checked={audit_kyc && this.props.audit_kyc['id_proof'].clear_and_readable && true} name="biz_proof2" value="Bike" onChange={(e)=>this.props.checkbox_verification(e.target.checked,"clear_and_readable")}/>
            <label className='no-margin pl-3 audit_kyc_text pointer'  for={biz_lic_proof_type+"_2"}>The  {biz_lic_proof_type} is in a clear and readable state</label><br/><br/><br/>
            </div>
           
        </form>
        )
      }

    display_image(proof_full_path,proof_path,actual_file=null,file_of=null,height=null,id=null){
        

        return(
            <div className={`w-100 no-padding `}>
            <DisplayImage image_path={get_dir_path(proof_full_path,proof_path)} index={null} rotate={true} file_of={file_of} lead_id={this.props.lead_id} style={{height: height!=null ? "560px" :'100%',width:'100%'}} file_name={proof_path}  view_lead={()=>this.props.view_lead()} actual_file={actual_file} />
            </div> 
        )
    }

   
    show_biz_addr(address_fields) {
        for (const item of address_fields) {
          const proofValue = this.props.owner_and_biz_info_data?.cust_reg_json?.id_proof?.[item]?.value;
          if (proofValue) {
            return true; 
          }
        }
        return false; 
      }

    render(){
        var audit_kyc =  this.props.audit_kyc &&this.props.owner_and_biz_info_data.active_key
        let address_fields = Config('addr_hiearchy')?.[get('market').country_code] ?? [];
        let hide_nat_id_back = !(this.props.owner_and_biz_info_data?.cust_reg_json?.capture_nat_id_back)
         const rm =  getValueFromLangJson("rm_label_short")
        const show_biz_addr = this.show_biz_addr(address_fields)
         
        const country_code = get('market').country_code;
        return(
            <div>
                {this.props.owner_and_biz_info_data.audit_kyc_wizard_menus.id_proof &&
                <>
                 {this.props.owner_and_biz_info_data.View_lead_section_loader ? this.audit_section_spinner():

                    <div className='row no-margin justify-content-center'>
                    <div className=' col-lg-12 py-3 mt-1' style={{background:' #01062c'}}>
                    <h2 className='text-white text-center'>
                    <b>1 - Personal Info Verification</b> 
                    </h2>
                    <h2 className=' d-flex mt-1 text-center justify-content-center align-items-center' ><img src='/img/pending_with_rm.png' height={'50'}/> <b className='ml-2 align-self-cente' style={{color:'#F3EA0B'}}> Pending with {this.props.owner_and_biz_info_data.active_section && (this.props.owner_and_biz_info_data.active_section.pending_with=="rm"?rm:'YOU')}</b></h2>

                    </div> 
                    <div className=' col-lg-12 py-3 mb-5 ' style={{background:'#4a4c51'}}>
                    <h2 className='text-white text-center'>
                        { this.props.owner_and_biz_info_data && this.props.owner_and_biz_info_data.cust_reg_json && this.props.owner_and_biz_info_data.cust_reg_json.hasOwnProperty('id_proof') && 
                    <b>{this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type.value ?<>{ get('kyc_docs_config')[this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type.value] + " Verification"}</>:'-' }</b> 
                   }  </h2>

                    </div> 
                    <>
                    
                  {(this.props.owner_and_biz_info_data.cust_reg_json&&   ((this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type.value=="nira_letter") || (this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type.value=="nat_id_replacement_cer")))  &&
                  <>
                    <div className='col-lg-10 py-5 mt-3 px-5' style={{background:'#223b56'}}>
                   
                    {this.props.owner_and_biz_info_data.cust_reg_json &&    
                    <>
                    <div className=' mt-4 row '>
                   
                    <div className='col-md-12 no-padding col-lg-6' >
                        {this.display_image(this.props.owner_and_biz_info_data.cust_reg_json.id_proof.photo_id_proof.path,this.props.owner_and_biz_info_data.cust_reg_json.id_proof.photo_id_proof.value,"photo_id_proof","nira_letter","710px")}
                   
                    </div>
                    <div className='col-md-12 col-lg-6 ' >
                    <div className='m-0 text-light border h-100 w-100' >
                    <h2 className='px-4 py-2 text-white' style={{background:'#0086B0'}}> <b>RETRIEVED DETAILS</b></h2>
                        
                        <div className=' py-4' >
                        
                        {this.national_id_details.map((item,idx)=>{return(
                            <div className={`align-items-center col-12  mb-3 my-4 `}>
                            <div className='row no-margin py-2'>
                                    <p className='no-margin text-white business_operations_text col-4'>  {dd_value(item.toUpperCase())}  </p>
                                    {(item=="national_id")? 
                                    <h2 className='text-white audit_kyc_text col-8 '> <b>{ capitalize_first_letter( this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_num ? this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_num.value :'')}</b></h2>

                                    :
                                    <h2 className='text-white audit_kyc_text col-8 '> <b>{ capitalize_first_letter( this.props.owner_and_biz_info_data.cust_reg_json.id_proof[item] ? this.props.owner_and_biz_info_data.cust_reg_json.id_proof[item].value :'')}</b></h2>
                                    }
                                    </div>
                            </div>
                        )})}

                           
                            <br/>
                    
                        </div>
                    
                       

                    { this.props.owner_and_biz_info_data.active_section &&
                        <div>
                            {this.props.owner_and_biz_info_data.active_section.verify &&
                            <div className="closedStamp ">
                            <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                            </div>   
                            }
                        </div>
                    }   
                    </div>


                    </div>

                    {this.props.owner_and_biz_info_data.allow_biz_owner_manual_id_capture &&

                    <div className='col-lg-6 p-3 d-flex mt-3' style={{background:'#fe9f9f'}}>
                        <p className='no-margin pl-3' style={{color:'black',fontSize:'14px'}}> <b className='pr-2' style={{color:'red'}}>Note</b>  App was not able to extract text from the {getLabel('national_id')} and {rm} typed the data fields from {getLabel('national_id')} manually.  If both are not matching, please re-assign back to {rm} and ask to correct it.</p>
                    </div>
                    
                   }
                    </div>

                   

                    <div className='row'>
                    {/* {this.props.owner_and_biz_info_data.form_spinner ? 
                    this.checkbox_spinner()
                        : */}
                        <div className='col-lg-10 py-2 px-0 mt-4'>
                    {this.props.owner_and_biz_info_data.active_section &&
                        <form className={`${(this.props.owner_and_biz_info_data.active_section.verify || (this.props.owner_and_biz_info_data.active_section.to_reassign==true) )? "disable_div":''}  ${ ((this.props.owner_and_biz_info_data.responseData.audited_by== get("user_id")) || (this.props.owner_and_biz_info_data.responseData.audited_by==null )) ?"":'disable_div'}`}>
                            <div className='d-flex align-items-center'>
                            <input type="checkbox" id="vehicle6" className='pointer' style={{height:'19px',width:'20px'}} 
                            
                            checked={ (this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type.value=="nira_letter")?audit_kyc && this.props.audit_kyc['id_proof'].nira_letter_clear_nd_readable :audit_kyc && this.props.audit_kyc['id_proof'].nat_id_replacement_cer_clear_nd_readable }

                             name="vehicle6" value="Bike" onChange={(e)=>this.props.checkbox_verification(e.target.checked,(this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type.value=="nira_letter")? "nira_letter_clear_nd_readable":"nat_id_replacement_cer_clear_nd_readable")}/>
                            <label className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle6">The {(this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type.value=="nira_letter")?"NIRA":`Certificate for Replacement of ${getLabel('national_id')} `} letter is in a clear and readable state.</label><br/><br/><br/>
                            </div>
                           
                        </form>
                        }
                        
                    </div>
                    {/* // } */}
                    </div>
                    </>
                    }
                   
                    </div>

                    <div className=' col-lg-12 py-3 my-5 ' style={{background:'#4a4c51'}}>
                    <h2 className='text-white text-center'>
                        { this.props.owner_and_biz_info_data && this.props.owner_and_biz_info_data.cust_reg_json && this.props.owner_and_biz_info_data.cust_reg_json.hasOwnProperty('id_proof') && 
                        <b> {getLabel('national_id')} Front Side Verification </b> 
                        }  
                    </h2>

                    </div> 
                   </>
                    }
                   
                  

                    <div className='col-lg-10 py-5 mt-3 px-5' style={{background:'#223b56'}}>
                      {(this.props.owner_and_biz_info_data.cust_reg_json&&this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type.value == "nat_id_replacement_cer") &&
                    <h2 className='text-white'>
                    <b> {getLabel('national_id')} Front Side Verification</b>
                    </h2>
                      }
                    {this.props.owner_and_biz_info_data.cust_reg_json &&
                    <>
                    <div className=' mt-4 row '>
                   
                    <div className='col-md-12 no-padding col-lg-6' >
                    <div className='m-0 text-light  h-100 w-100' >
                    {( (this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type)&&(this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type.value=="nira_letter") || ((this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type)&&(this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type.value=="nat_id_replacement_cer"))) &&
                            <>
                            {this.display_image(this.props.owner_and_biz_info_data.cust_reg_json.id_proof?.photo_national_id_copy?.path, this.props.owner_and_biz_info_data.cust_reg_json.id_proof.photo_national_id_copy?.value,"photo_national_id_copy","national_id_copy","710px",null)}
                            </>
                    }
                    {((this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type.value && this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type.value!="nira_letter") &&(this.props.owner_and_biz_info_data.cust_reg_json && this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type.value!="nat_id_replacement_cer") )&&
                        <>

                         {this.display_image(this.props.owner_and_biz_info_data.cust_reg_json.id_proof.photo_id_proof.path,this.props.owner_and_biz_info_data.cust_reg_json.id_proof.photo_id_proof?.value,"photo_id_proof",this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type.value ,"710px",null)}

                        </>
                    }
                   




                    </div>
                    </div>
                    <div className='col-md-12 col-lg-6 ' >
                    <div className='m-0 text-light border h-100 w-100' >
                    <h2 className='px-4 py-2 text-white' style={{background:'#0086B0'}}> <b>RETRIEVED DETAILS</b></h2>
                
                        <div className=' py-4' >

                        
                
                        {this.national_id_details.map((item,idx)=>{return(
                            <div className={`align-items-center col-12  mb-3 my-5 `}>
                               {((item=="national_id") ) ?
                                 <div className='row no-margin'>
                                    <p className='no-margin upper_case text-white business_operations_text col-4'> {((this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type.value!="nira_letter") &&(this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type.value!="nat_id_replacement_cer"))? (dd_value(this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type.value)) : getLabel('national_id')}  NO  </p>
                                    <h2 className='text-white audit_kyc_text col-8 '> <b>{ capitalize_first_letter( this.props.owner_and_biz_info_data.cust_reg_json.id_proof["id_proof_num"] ? this.props.owner_and_biz_info_data.cust_reg_json.id_proof["id_proof_num"].value :'')}</b></h2>
                                 </div>:

                                 
                                <div className='row no-margin'>
                                    <p className='no-margin text-white business_operations_text col-4'>  {dd_value(item.toUpperCase(),item)}  </p>
                                    <h2 className='text-white audit_kyc_text col-8 '> <b>  { capitalize_first_letter( this.props.owner_and_biz_info_data.cust_reg_json.id_proof[item] ? this.props.owner_and_biz_info_data.cust_reg_json.id_proof[item].value :'')}</b></h2>
                                </div>
                            }
                            

                            </div>
                        )})}
                         <div className='row no-margin pl-3'>
                                    <p className='no-margin text-white business_operations_text col-4'>  NATIONALITY  </p>
                                    <h2 className='text-white audit_kyc_text col-8 '> <b>  {this.props?.owner_and_biz_info_data?.cust_reg_json.nationality ?? get("market")?.country_code}</b></h2>
                            </div>
                           
                            <br/>
                    
                        </div>
                    
                       

                    { this.props.owner_and_biz_info_data.active_section &&
                        <div>
                            {this.props.owner_and_biz_info_data.active_section.verify &&
                            <div className="closedStamp ">
                            <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                            </div>   
                            }
                        </div>
                    }   
                    </div>


                    </div>

                    {this.props.owner_and_biz_info_data.allow_biz_owner_manual_id_capture &&

                    <div className='col-lg-6 p-3 d-flex mt-3' style={{background:'#fe9f9f'}}>
                        <p className='no-margin pl-3' style={{color:'black',fontSize:'14px'}}> <b className='pr-2' style={{color:'red'}}>Note</b>  App was not able to extract text from the {getLabel('national_id')} and {rm} typed the data fields from {getLabel('national_id')} manually.  If both are not matching, please re-assign back to {rm} and ask to correct it.</p>
                    </div>
                    
                   }
                    </div>

                   

                    <div className='row'>
                    {/* {this.props.owner_and_biz_info_data.form_spinner ? 
                    this.checkbox_spinner()
                        : */}
                        <div className='col-lg-10 py-2 px-0 mt-4'>
                    {this.props.owner_and_biz_info_data.active_section &&
                        <form className={`${(this.props.owner_and_biz_info_data.active_section.verify || (this.props.owner_and_biz_info_data.active_section.to_reassign==true) )? "disable_div":''}  ${ ((this.props.owner_and_biz_info_data.responseData.audited_by== get("user_id")) || (this.props.owner_and_biz_info_data.responseData.audited_by==null )) ?"":'disable_div'}`}>
                            <div className='d-flex align-items-center'>
                            <input type="checkbox" id="vehicle1" className='pointer' style={{height:'19px',width:'20px'}} checked={audit_kyc &&this.props.audit_kyc['id_proof']?.original_id_proof && true} name="vehicle1" value="Bike" onChange={(e)=>this.props.checkbox_verification(e.target.checked,`original_id_proof`)}/>
                            <label className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle1">The {   get('kyc_docs_config')[this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type.value]}  submitted by the customer is  original.</label><br/><br/><br/>
                            </div>
                            <div className='d-flex align-items-center'> {console.log('this.props.audit_kyc', this.props.audit_kyc)}
                            <input type="checkbox" id="vehicle2" name="vehicle2" className='pointer' value="Car" checked={audit_kyc && this.props.audit_kyc['id_proof']?.clear_and_readable && true} style={{height:'19px',width:'20px'}} onChange={(e)=>this.props.checkbox_verification(e.target.checked,"clear_and_readable")}/>
                            <label  className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle2"> The {  get('kyc_docs_config')[this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type.value]}  is in a clear and readable state.</label><br/><br/>
                            </div>

                            <div className='d-flex align-items-center mt-3'>
                            <input type="checkbox" id="vehicle3" name="vehicle3" className='pointer' checked={audit_kyc && this.props.audit_kyc['id_proof'].face_recogonizable && true} style={{height:'19px',width:'20px'}} onChange={(e)=>this.props.checkbox_verification(e.target.checked,"face_recogonizable")}/>
                            <label  className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle3">  The face of the customer photo in the {  get('kyc_docs_config')[this.props.owner_and_biz_info_data.cust_reg_json.id_proof.id_proof_type.value]} is recognizable.</label><br/>
                            </div>
                        </form>
                        }
                        
                    </div>
                    
                    </div>
                    </>
                    }
                    </div>

                    {this.props.owner_and_biz_info_data.cust_reg_json?.id_proof?.photo_id_proof_back?.value && 
                   
                    <div className={`col-lg-10 py-5 mt-3 px-5 `} style={{background:'#223b56'}}>
                    <h2 className={`text-white ${show_biz_addr ? "":"text-center"}`}>
                    <b>{getLabel('national_id')} Back Side Verification</b>
                    </h2>
                    {this.props.owner_and_biz_info_data.cust_reg_json &&
                    <>
                    <div className={` mt-4 row  ${show_biz_addr ? "":" d-flex justify-content-center"}`}>
                   
                    <div className='col-md-6 col-lg-6 no-padding' >
                    <div className='m-0 text-light  h-100 w-100' >
                    {this.props.owner_and_biz_info_data.cust_reg_json.id_proof?.photo_id_proof_back &&
                    <>
                     {this.display_image(this.props.owner_and_biz_info_data.cust_reg_json.id_proof?.photo_id_proof_back?.path,this.props.owner_and_biz_info_data.cust_reg_json.id_proof.photo_id_proof_back?.value,"photo_id_proof_back","national_id_back","710px")}
                     </>
                    }
                    </div>
                    </div>
                    {show_biz_addr &&
                    <div className='col-md-6 col-lg-6 ' >
                        <div className='m-0 text-light border h-100 w-100' >
                        {this.props.owner_and_biz_info_data.cust_reg_json.id_proof?.photo_id_proof_back?.value &&
                            <div>
                                <h2 className='text-white audit_kyc_text no-margin px-4 py-3'> <b>National ID Address </b> </h2>
                                <hr className='no-margin'/>
                                <div className=' p-4' >
                                    {address_fields.map((item,idx)=>{return(
                                        this.props.owner_and_biz_info_data.cust_reg_json.id_proof?.[item]?.value ? 
                                        <>
                                        <div className='d-flex row align-items-center  my-4 mx-2'>
                                        <p className='no-margin text-white col-4 business_operations_text'>  {dd_value(item)} </p>
                                        <h2 className='text-white pl-4 col-8 audit_kyc_text'><b>{this.props.owner_and_biz_info_data.cust_reg_json.id_proof?.[item]?.value ?
                                            capitalize_first_letter(this.props.owner_and_biz_info_data.cust_reg_json.id_proof?.[item]?.value) : "-"} </b></h2>
                                        </div>
                                        </> : null
                                    )})}
                                </div>
                            </div>
                        
                            }
                        </div>
                    </div>
                    }

                  
                    </div>

                  
                    </>
                    }
                    </div>
                    }

                    
                    
                    </>

                    { (this.props.owner_and_biz_info_data.cust_reg_json && this.props.owner_and_biz_info_data.cust_reg_json.id_proof.hasOwnProperty("photo_lc_letter")) && (this.props.owner_and_biz_info_data.cust_reg_json.id_proof.photo_lc_letter.value !=null) && 
                    <>
                     <div className=' col-lg-12 py-3  my-5' style={{background:'#4a4c51'}}>
                     <h2 className='text-white text-center'> <b> LC Letter</b> </h2>
 
                     </div> 
                      
                    <div className='row m-0 justify-content-center col-10 px-0 py-3' >
                        <div className='col-lg-6 col-md-12 px-0'>
                          {this.display_image(this.props.owner_and_biz_info_data.cust_reg_json.id_proof?.photo_lc_letter.path,this.props.owner_and_biz_info_data.cust_reg_json.id_proof.photo_lc_letter.value,"photo_lc_letter","lc_letter" ,"710px")}

                          <div className='col-lg-12 col-md-12 py-2 px-0  mt-4'>

                          {this.props.owner_and_biz_info_data.active_section &&
                        <form className={`${(this.props.owner_and_biz_info_data.active_section.verify || (this.props.owner_and_biz_info_data.active_section.to_reassign==true) )? "disable_div":''}  ${ ((this.props.owner_and_biz_info_data.responseData.audited_by== get("user_id")) || (this.props.owner_and_biz_info_data.responseData.audited_by==null )) ?"":'disable_div'}`}>
                            <div className='d-flex align-items-center'>

                            <input type="checkbox" id="lec_letter" className='pointer' style={{height:'19px',width:'20px'}} 
                            
                            checked={audit_kyc && this.props.audit_kyc['id_proof'].lc_letter_clear_and_readable? this.props.audit_kyc['id_proof'].lc_letter_clear_and_readable:false} 

                            name="lec_letter" onChange={(e)=>this.props.checkbox_verification(e.target.checked,`lc_letter_clear_and_readable`)}/>

                            <label className='no-margin pl-3 audit_kyc_text pointer'  for="lec_letter">Text in LC letter is clear and readable</label><br/><br/><br/>
                            </div>
                            
                        </form>
                        }
                            </div>
                        </div>
                    </div>
                    </>
                    
                    }
                </div>
                }
                </>
                }

              
            </div>

        )        
     }
}


const mapStateToProps = (state) => {
    return {
      audit_kyc: state.common_slice,
    };
    };
  
export default connect(mapStateToProps)(AuditKYCOwnerInfoComponent);
