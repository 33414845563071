import { useEffect } from "react";
import { SoftPhoneState } from "../softPhoneCtx";

type Props = {
  softPhoneState: SoftPhoneState;
  canCreateAtInstance: boolean;
  hasCallPriv: boolean;
};

/**
 *
 * @param softPhoneState
 * @param canCreateAtInstance
 * @param hasCallPriv
 * this function is used to update the AIT client connected status on the tab's document section
 */
const useUpdateDocTitle = ({
  softPhoneState,
  canCreateAtInstance,
  hasCallPriv,
}: Props) => {
  // this is used to change the document title which one shows the softphone client's connected status
  useEffect(() => {
    if (hasCallPriv) {
      const isSoftPhoneClientReady = softPhoneState.isSoftPhoneClientReady;
      if (isSoftPhoneClientReady) document.title = "📶 FLOW THE APP";
      else if (!canCreateAtInstance && !isSoftPhoneClientReady)
        document.title = "FLOW THE APP";
      else if (!isSoftPhoneClientReady) document.title = "🚫 FLOW THE APP";
    }
  }, [softPhoneState.isSoftPhoneClientReady, canCreateAtInstance, hasCallPriv]);
};

export default useUpdateDocTitle;
