import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../../UI/CommonComponent/Header";
import AdminMenu from "../../UI/home/AdminMenu"
import { useDispatch, useSelector } from "react-redux";
import SelectMarket from "../../UI/home/SelectMarket";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import MenuDrawer from "../../UI/home/MenuDrawer";
import CommonModal from "../../UI/common/component/CommonModal";
import { check_priv, Config, get } from "../../helpers/storage_helper";
import CSSoftPhoneContainer from "../../UI/cs_management/container/CSSoftPhoneContainer";
import FreshChat from "../../UI/utility/Component/FreshChat";
import { side_bar } from "../../styles/common_styles";
import { remove_bg_scroll } from "../../helpers/common_helper";
import { setValuesIntoRedux } from "../../Redux/Slices/CsSoftphoneSlice";
import SoftPhoneInstanceProvider from "../../context/softPhoneCtx/softPhoneInstanceProvider";
import { SOFTPHONE_ROUTE } from "../../consts";





const HomeLayOut = ()=>{

    const datas = useSelector(state=>state.common_slice)


    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const { name_verification, configuration, market, is_drawer_open } = useSelector(state => state.HeaderSlice)
    const {is_modalOpened} = useSelector(state => state.cs_soft_phone)

    const role_codes = get('role_codes')
    const select_market = datas.admin_roles.includes(role_codes)
    const acc_prvdr_code = get('acc_prvdr_code')
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!localStorage.getItem('bacgroundColor')) {
            localStorage.setItem('backgroundColor', theme.palette.background.default)
            document.body.style.backgroundColor = theme.palette.background.default
        }

    }, [datas && datas.mode])

    useEffect(() => {
        dispatch(setValuesIntoRedux({ showMenus: false, show_nums: false, missed_calls_view: false }))
    }, [location.pathname])

    useEffect(() => {
        
    let  status = is_drawer_open? is_drawer_open :is_modalOpened
       remove_bg_scroll(status )
    }, [is_drawer_open , is_modalOpened]);

    const fixedHeader = {
        position: "fixed",
        top: '0',
        zIndex: is_drawer_open ? "1009" : 2000,
    }

    const softphone_supported_countries = Config('softphone_supported_countries')?.includes(get('market')?.country_code)
    dispatch(setValuesIntoRedux({ is_softphone_supported : softphone_supported_countries }))

    // for SOFTPHONE_ROUTE no need to show the menu items
    const is_hide_for_sa = get('role_codes') == 'system_admin' || location.pathname === SOFTPHONE_ROUTE
    const navigate = useNavigate()
    return (
        <SoftPhoneInstanceProvider>
        <>{get('access_token') && 
            <div>
                <Box>
                    <div>
                        <Box className='d-flex align-items-center justify-content-between' sx={(isMobile || isTablet) ? fixedHeader : ""}>
                        {is_hide_for_sa &&
                        <Box >
                        <Box
                            onClick={()=>{navigate('/')}}
                             component="img"
                             className="pointer"
                             src={datas?.brand_info?.logo}
                             alt="Drawer Logo"
                             sx={{height:'42px', position:'absolute',top:'21px', left:'10px',backgroundColor:
                             `${ datas?.brand_info?.footer? theme.palette.text.primary :""}`, borderRadius:'20px',padding:'9px 0px'}}
                              
                           />
                        </Box>
                        }

                            <Header />
                        </Box>
                        <div className="d-flex align-items-center justify-content-between">
                            {!is_hide_for_sa &&
                            <Box sx={(isMobile || isTablet) ? side_bar:""}>
                            <MenuDrawer />
                            </Box>
                            }
                            <div className= {is_hide_for_sa ?"w-100" :"main-panel"} style={{paddingTop:"80px"}}>
                                <div className=" content container-fluid outlet_content">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                        {(name_verification || configuration) &&
                            <CommonModal type={name_verification ? "name_verification" : configuration ? "configuration" : ""} />
                        }
                    </div>
                    
                        {/* <div>
                            {(get('role_codes') && check_priv('home', 'soft_phone') &&  softphone_supported_countries) ?
                        <CSSoftPhoneContainer /> 
                        :
                        get('role_codes') && check_priv('user', 'chat_support') && <FreshChat />}
                        </div> */}
                    
                </Box>
            </div>
            }
        </>
        </SoftPhoneInstanceProvider>
    )
}


export default HomeLayOut;