import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  IconButton,
  Button,
  Grid,
  Alert,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { BodyVarientTwoTypo } from "../../CommonComponent/typography";
import FormProvider from "../../CommonComponent/rhfComponents/formProvider";
import RHFTextField from "../../CommonComponent/rhfComponents/rhfTextField";
import RHFAutocomplete from "../../CommonComponent/rhfComponents/rhfAutoComplete";
import FileUpload from "../../common/component/FileUpload";
import { useForm } from "react-hook-form";
import { dd, dd_value } from "../../../actions/common_actions";
import { get } from "../../../helpers/storage_helper";
import { RhfOptionsTypeAndKeyCheckProperties } from "../../../helpers/helper";
import { useParams } from 'react-router-dom';

const BizMobileVerifyModal = ({ biz_mob_numbers, state_values, submit_holder_name_proof, numbers_verify, modal_close, audit_kyc_modify_handler }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isSameHolderName, setisSameHolderName] = useState(false);

  const [file_name, setfile_name] = useState(true);  // Loader state
  const { id } = useParams();
  const methods = useForm({
    defaultValues: { enteredName: biz_mob_numbers.holder_name, mismatch_reason: {id: 'choose', name: "Choose", code: 'choose'} },
  });
  const { formState, watch, setValue, reset, handleSubmit, resetField } = methods

  const mismatchReasonOptions = dd("holder_name_mismatch_reason").map((i) => ({
    id: i.id,
    name: i.data_value,
    code: i.data_code,
  }));


  const handle_modal_close = () =>{
    // setisSameHolderName(false)
    setfile_name(true)
    modal_close()
  }
 

  const edit_handler = () => {
    if (watch('mismatch_reason')?.code === "retrieved_franchise_name") {
      resetField('mismatch_reason');
    }
    setIsEdit(true);
    setfile_name(true)

  };

  const ussdCode =  state_values?.ussd_code

  console.log('state_values', state_values)

  const renderField = (label, value, isEditable) => (
    <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 4 }}>
      <Grid item xs={12} sm={5}>
        <BodyVarientTwoTypo text={label} />
      </Grid>
      <Grid item xs={12} sm={7}>
        {isEditable ? (
          <RHFTextField name="enteredName" label="Entered Name" autoFocus />
        ) : (
          <Box sx={{ display: 'flex' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: `${(biz_mob_numbers.name_match || isSameHolderName) ? "green" : ""}` }}>
              {value}
            </Typography>
            {label === "Entered Name" && !biz_mob_numbers.name_match && (!isSameHolderName) &&(
              <IconButton onClick={edit_handler}>
                <EditIcon />
              </IconButton>
            )}
          </Box>
        )}
      </Grid>
    </Grid>
  );

  const handleCreateUpload = (fileData) => {
   
    biz_mob_numbers['holder_name_path'] = fileData?.target?.value ;
    biz_mob_numbers['holder_name_proof_path'] = fileData?.target?.f_name ;
    setfile_name(fileData?.target?.f_name ?? false)
  };

  const handleEditUpload = (fileData) => {
  };

  const onSubmit = handleSubmit((formData) => {
    resetField('mismatch_reason');
  });

  const verifyMobileNumber = async () => {
    biz_mob_numbers['category'] = biz_mob_numbers.section + "_" + biz_mob_numbers.index;

    let rm_entered_name = (biz_mob_numbers?.section === "biz_mob_num") 
    ? biz_mob_numbers?.rm_entered_name 
    : biz_mob_numbers.name?.value;

    if (isEdit ) {
    

      let holder_name = watch('enteredName');
      let is_same = rm_entered_name?.trim().split(" ").join(" ") === holder_name?.trim().split(" ").join(" ");

      if(is_same){
        setisSameHolderName(is_same)
        setIsEdit(false)
      }
      biz_mob_numbers['holder_name'] = watch('enteredName');
      await audit_kyc_modify_handler(biz_mob_numbers);
      setIsEdit(false);

    } else {
      if (watch('mismatch_reason')?.code === "retrieved_franchise_name") {
        
        let is_same = rm_entered_name?.trim().split(" ").join(" ") ===
        (watch('holder_name'))?.trim().split(" ").join(" ");
        if(is_same){
          setisSameHolderName(is_same)
        }

        biz_mob_numbers['holder_name'] = watch('holder_name');

        await submit_holder_name_proof(biz_mob_numbers);
        resetField('mismatch_reason');
        resetField('holder_name');
        setfile_name(true)

       
      } else if (biz_mob_numbers.hasOwnProperty('holder_name') && biz_mob_numbers.holder_name) {

        biz_mob_numbers['mismatch_reason'] = watch('mismatch_reason')?.code;
        await numbers_verify("mobile_num_verify", biz_mob_numbers);
        resetField('mismatch_reason');
        resetField('holder_name');
        setfile_name(true)
        setisSameHolderName(false)

        handle_modal_close();

      } else {
        biz_mob_numbers['holder_name'] = watch('holder_name');
        let is_same = rm_entered_name?.trim().split(" ").join(" ") === (watch('holder_name'))?.trim().split(" ").join(" ");
        if(is_same){
          setisSameHolderName(is_same)
        }

        await submit_holder_name_proof(biz_mob_numbers);
        resetField('mismatch_reason');
        resetField('holder_name');
        setfile_name(true)



      }
    }
  };

  const isHolderNameValid = biz_mob_numbers.holder_name ? true
    : biz_mob_numbers.holder_name_proof_path && watch('holder_name');

  const isMismatchReasonValid =
    biz_mob_numbers.hasOwnProperty('name_match') && !biz_mob_numbers.name_match
      ? watch('mismatch_reason')?.code === "retrieved_franchise_name"
        ? biz_mob_numbers.holder_name_proof_path && watch('mismatch_reason').code != "choose" && watch('holder_name')
        : watch('mismatch_reason').code != "choose" && true
      : true;

  const btn_disable = isEdit
    ? watch('enteredName')
    : isHolderNameValid && isMismatchReasonValid;

  const rm_entered_name = (biz_mob_numbers?.section == "biz_mob_num") ? biz_mob_numbers?.rm_entered_name : biz_mob_numbers.name?.value;

  useEffect(()=>{
    if(watch('mismatch_reason')?.code === "retrieved_franchise_name"){
      setfile_name(false)
    }else{
      setfile_name(true)
    }
  },[watch('mismatch_reason')?.code === "retrieved_franchise_name"])


  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Box sx={{ margin: "auto" }}>
        {/* Provider Logo & Mobile Number */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, marginBottom: 4 }}>
          <Box
            component="img"
            src={`/img/${biz_mob_numbers?.serv_prvdr?.value}_logo_color.png`}
            alt="provider"
            sx={{ height: 30 }}
          />
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {biz_mob_numbers?.mobile_num?.value}
          </Typography>
        </Box>

        {/* Dynamic Fields */}
        {renderField("Name as Entered by RM", rm_entered_name, false)}
        
        {(
           (get('app_config').audit_kyc_line[biz_mob_numbers?.serv_prvdr?.value]=="ussd") &&
            (state_values?.timers[biz_mob_numbers.mobile_num?.value] != 0 ) 
           && !biz_mob_numbers.hasOwnProperty("holder_name")) ?
          <div className="d-flex col-lg-12 py-2 px-5 mt-4 justify-content-center">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          
          </div>
          :
          <>
            {biz_mob_numbers.holder_name ?
              <>
                {renderField("Entered Name", 
                watch('enteredName') ?? (biz_mob_numbers.holder_name || "file_upload"), 
                isEdit)}
              </>
              : ""
            }

            {/* Name Mismatch Reason */}
            {((!isEdit && biz_mob_numbers.holder_name && !biz_mob_numbers.name_match ) && (!isSameHolderName)
            ) &&
              <>
                <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 1 }}>
                  <Grid item xs={12} sm={5}>
                    <BodyVarientTwoTypo text="Name Mismatch Reason" />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <RHFAutocomplete
                      name="mismatch_reason"
                      label="Mismatch Reason"
                      options={[{ id: 'choose', name: 'Choose', code: 'choose' }, ...mismatchReasonOptions]}
                      fullWidth
                      {...RhfOptionsTypeAndKeyCheckProperties}
                      disableClearable
                    />
                  </Grid>
                </Grid>

                {/* Note Alert */}
                <Alert severity="error" sx={{ backgroundColor: "#FFCDD2", color: "red", px: 0 }}>
                  <Typography>
                    <strong>Note:</strong> {
                      watch('mismatch_reason')?.code == 'retrieved_franchise_name' ?
                        " If the SMS is shown on the mobile screen, please merge them into a single image using any photo editing software and upload it." :
                        "The name doesn't match with the customer or business name."
                    }
                  </Typography>
                </Alert>
              </>
            }

            {/* File Upload */}
            {(!biz_mob_numbers.holder_name || watch('mismatch_reason')?.code == 'retrieved_franchise_name') &&
              <>
                <Box sx={{ my: 1 }}>
                  <FileUpload
                    className="audit_kyc_file_upload"
                    lead_id={id}
                    id="account_holder_name_proof"
                    mode="create"
                    entity="leads"
                    type="image"
                    onEditUpload={handleEditUpload}
                    onCreateUpload={handleCreateUpload}
                    label = "Account Holder Name Proof"
                  />
                </Box>
                {/* USSD Note */}
                {(ussdCode ) && (

                    <Box sx={{ background: "#fe9f9f", p:1,mt:2, borderRadius: 2 }}>
                      {console.log('ussdCode', ussdCode)}
                    {/* Note 1 */}
                    <Typography sx={{ color: "black", mb: 1,}}>
                      <b style={{ color: "red", paddingRight: "8px" }}>Note</b>
                      <Typography sx={{fontSize:'13px' }}>
                      1. Alternatively, You can use the USSD code (
                      <b>{ussdCode ?? "-"}</b>) from your <b>{biz_mob_numbers?.serv_prvdr?.value}</b> phone to retrieve and upload the screenshot manually.
                      </Typography>
                    </Typography>

                    {/* Note 2 */}
                    <Typography sx={{ color: "black",fontSize:'13px' }}>
                      2. Once retrieved on your phone, upload here.
                    </Typography>
                    </Box>
                  // <Alert severity="error" sx={{ backgroundColor: "#FFCDD2", color: "red", px: 0, my: 2 }} >

                  //   <Typography>
                  //     <strong>Note:</strong>
                  //     1. Alternatively, You can use the USSD code (<b>{ussdCode}</b>) from your MTN phone to retrieve and upload the screenshot manually.
                  //   </Typography>
                  //   <Typography>
                  //     2. Once retrieved on your phone upload here.
                  //   </Typography>
                  // </Alert>
                )}
              </>
            }

            {/* Name Input if Empty */}
            {(!biz_mob_numbers.holder_name || watch('mismatch_reason')?.code == 'retrieved_franchise_name') && (
              <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
                <Grid item xs={12} sm={5}>
                  <BodyVarientTwoTypo text="Enter Name" />
                </Grid>
                <Grid item xs={12} sm={7}>
                  <RHFTextField name="holder_name" label="Enter Name" autoFocus />
                </Grid>
              </Grid>
            )}

            {/* Action Buttons */}
            <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => isEdit ? setIsEdit(false) :handle_modal_close()}
              >
                Cancel
              </Button>
              <Button variant="contained" color="secondary"
              className={file_name?"":"disable_div"}
                onClick={verifyMobileNumber}
                disabled={!btn_disable && !isSameHolderName }
                type="button"
              >
                {(isEdit || isSameHolderName) ? "Update" : "Submit"}
              </Button>
            </Box>
          </>
        }
      </Box>
    </FormProvider>
  );
};

export default BizMobileVerifyModal;
