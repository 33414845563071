import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import {getCustProfile} from '../../../actions/borrower_actions';
import {  Navigate,Link } from 'react-router-dom';
import {Form,Modal,Col,Row,Card,Button} from 'react-bootstrap';
import {format_date,lbl_amt,capitalize_first_letter} from '.././../../helpers/common_helper';
import {dd_value} from '../../../actions/common_actions';
import Select from 'react-select';
import {CompleteCall,CancelCall,callLogs} from '../../../actions/call_log_actions';
import { MdCallEnd ,MdCall} from 'react-icons/md';
import withRouter from '../../../Router/Withrouter';
import { connect } from "react-redux";
import { setValuesIntoRedux } from '../../../Redux/Slices/CsSoftphoneSlice';

class CallLog extends FlowComponent{
  constructor(props) {
		super(props);
    this.state = {
            server_time : {}, 
            timer_sec :0,
            timer : '',
            timer_min:0, 
            loan_data :{},
            cust_data:{},
            loan_type:"",
            call_log:{},
            call_type :"",
            call_start_time : null,
            toSuccess : false,
            modal_show : false,
            show_type : false,
            complaint_type : "",
            is_duplicate_txn : true,
            dup_fa_details:null
                           
          }
         
        }

//     componentDidMount() {
//     window.addEventListener('beforeunload', this.beforeunload);
//   }

//   componentWillUnmount() {
//     window.removeEventListener('beforeunload', this.beforeunload);
//   }

//   beforeunload = (e) => {
// if (this.props.dataUnsaved) {
// e.preventDefault();
// e.returnValue = true;
// }
// };
 

	componentWillMount(){
   
    	this.server_time()
      console.log('reduxvlaue', this.props.callPropsData)
    	if(this.state.timer_sec == 0){
        this.refresh_timer()
      }
      else{
        this.setState({timer_sec:0,timer_min:0})
      }
      const queryParams = new URLSearchParams(window.location.search);
      let call_type = queryParams.get('type');
      call_type = call_type ? call_type : this.props.call_type
      this.setState({call_type})  
    	this.cust_id = this.props.match ? this.props.match.params.cust_id :  this.props.params.cust_id
      const id = this.props.match ? this.props.match.params.id : this.props.params.id 
      let data = {}
      if(this.props.callPropsData && this.props.callPropsData.cust_id == this.cust_id){
        let val = this.props.callPropsData
        let entity_id = val.type == 'float_advance' ? 'loan_doc_id' : 'sales_doc_id'
        data.dup_fa_call_details = {type : val.type, [entity_id]:val[entity_id]}
      }
    	getCustProfile(this.req({cust_id : this.cust_id, incl_addr : true,incl_fa:true, ...data}, true)).
        	then((response) =>{
            this.resp(response)
             if(!response){return };
             if(response.status === "success"){
                if(response.data){
                  var cust_data = this.state.cust_data
                  var loan_data = this.state.loan_data
                  var loan_type = this.state.loan_type
                  var dup_fa_details = this.state.dup_fa_details
                  if(response.data.current_loan){
                    loan_data = response.data.current_loan
                    cust_data = response.data
                    loan_type = "current_loan"
                  }
                  else{
                    cust_data = response.data 
                    loan_type = "no_loan" 
                  } 

                  if(response.data.dup_fa_details){
                    dup_fa_details = response.data.dup_fa_details
                  }
                 
                  this.setState({cust_data,loan_data,loan_type,dup_fa_details,cust_id : this.cust_id})
                }
             }
             else{
               alert(response.message  +"\n" + JSON.stringify(response.data));  
             }
        })
      callLogs(this.req({id})).
        then((response) => {
          if(!response){return };
             if(response.status === "success"){
              const call_start_time = response.data.call_start_time
              const sec_diff = response.data.sec_diff
              const min_diff = response.data.min_diff
              this.setState({call_start_time,timer_sec:sec_diff,timer_min : min_diff , call_purpose : response.data?.call_purpose})
             }


        })
        var call_log = this.state.call_log
        call_log["cust_id"] = this.props.match ? this.props.match.params.cust_id : this.props.params.cust_id
        this.setState({call_log});


  	}
  
  

  	tick() {
        if(this.state.timer_sec == 0 || this.state.timer_sec != 0 ){
          this.setState({
            timer_sec: this.state.timer_sec + 1,
           });
          if(this.state.timer_sec >= 60){
            this.setState({
                timer_min:this.state.timer_min +1,
                timer_sec : 0
            })
          }
      }else{
        this.refresh_timer()
      }
      // if(this.state.timer_min == 15 && this.state.timer_sec == 0) {
      //   this.setState({btn_style:false})
      // }
    }

  refresh_timer = () =>{
    setInterval(() => this.tick(),1000) 
  }

  handlePurposeChange = (selectedOptions) =>{
    var call_log = this.state.call_log
    if(Array.isArray(selectedOptions)){
        call_log.call_purpose = selectedOptions.map((item) => ( item.value))
        if(call_log.call_purpose.includes("complaint")){
          this.setState({show_type : true})
        }else{
          this.setState({show_type : false})
          call_log.complaint_type = null
        }
    }
    else{
        // if isconfig key is available via props
        // may be the selected option have value key or id key so that here 
        // we have added the key by possibilities
        call_log.call_purpose = [selectedOptions?.value ?? selectedOptions?.id] 
    }
    this.setState({call_log});
  }

  handleTypeChange = (selected)=>{
    var call_log = this.state.call_log
    call_log.complaint_type = selected.value
    this.setState({call_log})
    
  }

  handleRemarks = (event) =>{
    var call_log = this.state.call_log
    call_log[event.target.id] = event.target.value
    this.setState({call_log});
  }

  handleCancelCall = (event) =>{
    const resp = window.confirm("Are you sure to Cancel the call?");
    if(resp == false){
      return;
    }
    const log_id = this.props.match ? this.props.match.params.id : this.props.params.id;
    CancelCall(this.req({log_id})).
      then((response) =>{
        if(!response){return };
        this.resp(response)
        if(response.status === "success"){
          alert(response.message)
          this.setState({toSuccess : this.props.not_redirect ? false : true})
        if(this.props.hasOwnProperty('onCancel')){
                  this.props.onCancel()
              }
        }else{
          alert(response.message  +"\n" + JSON.stringify(response.data));  
        }
    })
  }


  handleCallComplete = (event) =>{
    const call_log  = this.state.call_log
    if(call_log.call_purpose && call_log.remarks){
      var modal_show = this.state.modal_show
      if(call_log.call_purpose.includes("cust_sms_not_rcvd") && call_log.call_purpose.includes("cust_sms_not_sent")){
        alert("You can only select one Customer Confirmation purpose")
        return
      }
      if(call_log.call_purpose == "complaint" && !call_log.complaint_type){
        modal_show = true
        this.setState({modal_show})
        return
      }

      const resp = window.confirm("Are you sure to complete the call?");
      if(resp == false){
        return;
      }

     
      if(this.state.loan_type === 'current_loan' && !this.state.dup_fa_details){
          call_log.loan_doc_id = this.state.loan_data.loan_doc_id
      }
      if(this.state.dup_fa_details){
        let entity_id = this.state.dup_fa_details.type == "float_advance" ? "loan_doc_id" : "sales_doc_id"
        call_log.duplicate_txn_call_details = {[entity_id]:this.state.dup_fa_details[entity_id]}
      }
      call_log.id = this.props.match ? this.props.match.params.id : this.props.params.id;
      call_log.mob_num = this.state.cust_data.cust_mobile_num
      var toSuccess = this.state.toSuccess
      
        CompleteCall(this.req({call_log})).
            then((response) =>{
              this.resp(response)
                if(!response){return };
                if(response.status === "success"){
                  alert(response.message)
                  toSuccess = this.props.not_redirect ? false : true
                  if(this.props.hasOwnProperty('onComplete')){
                      this.props.onComplete()
                  }
                  if(this.props.hasOwnProperty('onCancel')){
                    this.props.onCancel()
                }
                }else{
                   alert(response.message  +"\n" + JSON.stringify(response.data));  
                }
                this.setState({toSuccess})
              })
            }else{
              modal_show = true
            }
            this.setState({modal_show})


  }


  
  
  render(){    
    if (this.state.toSuccess === true) {   
      return <Navigate to={"/"}/> 
    }
    let is_conf = this.props.hasOwnProperty('is_conf') || this.props.hasOwnProperty("is_duplicate") ? "col-md-12" : "col-md-8"
    return(
      
      <div className="container " style={{maxWidth :"1500px"}}>
        {this.vaildationAlert(this.state.modal_show)}
          <br/>
        <br/>
        <form >
          <Card >
            <Card.Header style = {{backgroundColor: "#01062c" , color: "white"}}><h4>{capitalize_first_letter(this.state.call_type)} Call Log<span className = "float-right">Timer : {this.state.timer_min}m {this.state.timer_sec}s </span></h4></Card.Header>
            <Card.Body>
              <div className ="row justify-content-center">
                <div className={`${is_conf}`}>
                  <fieldset className = "border border-dark p-2">
                     <legend className="w-auto" style ={{fontSize : "15px"}}>{this.state.cust_data.cust_name}</legend>
                     <div className ="row">
                      <div className = "col-md-6">
                       <div className="form-group row reset-margin justify-content-center">
                          <label className="col-md-5"><b>Business Name</b></label>
                          <label className="col-md-5 text-right" >{this.state.cust_data.biz_name}</label>  
                       </div>
                       <div className="form-group row reset-margin justify-content-center py-2 ">
                          <label className="col-md-5"><b>Mobile Number</b></label>
                          <label className="col-md-5 text-right" >{this.state.cust_data.cust_mobile_num} </label>  
                       </div>
                       <div className="form-group row reset-margin justify-content-center">
                          <label className="col-md-5"><b>DOB</b></label>
                          <label className="col-md-5 text-right" >{format_date(this.state.cust_data.cust_dob)} </label>  
                       </div>
                      </div>
                      <div className = "col-md-6">
                       <div className="form-group row reset-margin justify-content-center ">
                          <label className="col-md-5"><b>Location</b></label>
                          <label className="col-md-5 text-right" >{dd_value(this.state.cust_data.location)} </label>  
                       </div>
                       <div className="form-group row reset-margin justify-content-center py-2 ">
                          <label className="col-md-5"><b> Cust ID</b></label>
                          <label className="col-md-5 text-right" ><Link  to={"/borrower/indiv/view/"+this.state.cust_data.cust_id}  target="_blank">{this.state.cust_data.cust_id} </Link></label>  
                       </div>
                       <div className="form-group row reset-margin justify-content-center ">
                          <label className="col-md-5"><b>Data Prvdr Cust ID</b></label>
                          <label className="col-md-5 text-right" >{this.state.cust_data.acc_number} </label>  
                       </div>
                       </div>
                       </div>
                  </fieldset>
                </div>
              </div>
              {this.state.dup_fa_details ? 
                  <div className = "row justify-content-center pt-4">
                    <div className = {`${is_conf}`}>
                      <fieldset className = "border border-dark p-2">
                                
                        <legend className="w-auto" >
                          {this.state.dup_fa_details.type == "float_advance" ? 
                          <Link style ={{fontSize : "15px"}} to={"/fa/view/"+this.state.dup_fa_details.loan_doc_id} target="_blank" >{this.state.dup_fa_details.loan_doc_id}</Link>:
                          this.state.dup_fa_details.sales_doc_id
                          }
                        </legend>
                          <div className = "row">
                            <div className = "col-md-6">
                              <div className="form-group row reset-margin justify-content-center">
                                <label className="col-md-5"><b>Status</b></label>
                                <label className="col-md-5 text-right " >{dd_value(this.state.dup_fa_details.status)}</label>
                              </div>
                              <div className="form-group row reset-margin justify-content-center py-2">
                                  <label className="col-md-5"><b>Txn Date</b></label>
                                  <label className="col-md-5 text-right " >{format_date(this.state.dup_fa_details.txn_date)} </label>  
                              </div>
                              <div className="form-group row reset-margin justify-content-center ">
                                <label className="col-md-5 "><b>Transferred Amt</b></label>
                                <label className="col-md-5 text-right" >{lbl_amt(this.state.dup_fa_details.transferred_amount,this.currency_code)} </label>  
                              </div>
                              </div>
                                <div className = "col-md-6">
                                  <div className="form-group row reset-margin justify-content-center">
                                  <label className="col-md-5"><b>Paid Amount</b></label>
                                  <label className="col-md-5 text-right" >{lbl_amt(this.state.dup_fa_details.paid_amount,this.currency_code)}</label>  
                                </div>
                                <div className="form-group row reset-margin justify-content-center py-2">
                                  <label className="col-md-5" style ={{color:"green"}}><b>Amt to Recover</b></label>
                                  <label className="col-md-5 text-right" style ={{color:"green"}} >{lbl_amt(this.state.dup_fa_details.amount_to_recover,this.currency_code)}</label>  
                                </div>
                              </div>
                            </div>
                        </fieldset>
                      </div>
                    </div>
              : 
              
              this.state.loan_type == "current_loan"  ? 
              <div className = "row justify-content-center pt-4">
                <div className = {`${is_conf}`}>
                  <fieldset className = "border border-dark p-2">
                  
                     <legend className="w-auto" ><Link style ={{fontSize : "15px"}} to={"/fa/view/"+this.state.loan_data.loan_doc_id} target="_blank" >{this.state.loan_data.loan_doc_id}</Link></legend>
                      <div className = "row">
                      <div className = "col-md-6">
                      <div className="form-group row reset-margin justify-content-center">
                        <label className="col-md-5"><b>Status</b></label>
                        <label className="col-md-5 text-right " >{dd_value(this.state.loan_data.status)}</label>
                      </div>
                      <div className="form-group row reset-margin justify-content-center py-2">
                        <label className="col-md-5"><b>Due Date</b></label>
                        <label className="col-md-5 text-right " >{format_date(this.state.loan_data.due_date)} </label>  
                      </div>
                      <div className="form-group row reset-margin justify-content-center ">
                        <label className="col-md-5 "><b>Due Amount</b></label>
                        <label className="col-md-5 text-right" >{lbl_amt(this.state.loan_data.due_amount,this.currency_code)} </label>  
                      </div>
                      </div>
                      <div className = "col-md-6">
                      <div className="form-group row reset-margin justify-content-center">
                        <label className="col-md-5"><b>Paid Amount</b></label>
                        <label className="col-md-5 text-right" >{lbl_amt(this.state.loan_data.paid_amount,this.currency_code)}</label>  
                        </div>
                      <div className="form-group row reset-margin justify-content-center py-2">
                        <label className="col-md-5" style ={{color:"green"}}><b>Current OS Amount</b></label>
                        <label className="col-md-5 text-right" style ={{color:"green"}} >{lbl_amt(this.state.loan_data.current_os_amount,this.currency_code)}<br/><small>(Penalty not included)</small> </label>  
                      </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
                </div>
                :
                null
              }
                <div className = "row justify-content-center pt-4">
                <div className = {`${is_conf}`}>
                  <fieldset className = "border border-dark p-2">
                     <legend className="w-auto"></legend>
                     <div className="form-group row reset-margin justify-content-center ">
                        <label className="col-md-3"><b>Call Start Time</b></label>
                        <label className="col-md-5 " >{format_date(this.state.call_start_time,true)} </label>
                      </div>
                      <div className="form-group row reset-margin justify-content-center py-3">
                        <label className="col-md-3 mt-2"><b>Purpose<font className="text-danger">   *  </font></b></label>
                        {this.props.hasOwnProperty("is_conf") ?
                            <Select className ="col-md-5"  onChange={(e) =>{this.handlePurposeChange(e);}}
                                    options ={  
                                       this.state.call_purpose?.length >0  ? this.state.call_purpose:
                                      [{value:"cust_sms_not_rcvd", label: dd_value('cust_sms_not_rcvd', 'outgoing_call_purpose'), id:"1",},
                                               {value:"cust_sms_not_sent", label: dd_value('cust_sms_not_sent', 'outgoing_call_purpose'), id:"2",}]
                                      }/>

                            :
                        <Select className ="col-md-5" onChange={(e) =>{this.handlePurposeChange(e);}}
                               
                                options={ 
                                  this.state.call_purpose?.length >0  ? this.state.call_purpose:
                                  this.getMultiSelectOptions(`${this.state.call_type}_call_purpose`).filter(option => !['cust_sms_not_rcvd', 'cust_sms_not_sent'].includes(option.value))}
                                 isMulti/>
                        }
                        
                      </div>
                      {this.state.show_type && 
                      <div className="form-group row reset-margin justify-content-center py-3">
                        <label className="col-md-3 mt-2"><b>Complaint About<font className="text-danger">   *  </font></b></label>
                           <Select className ="col-md-5" onChange={(e)=>this.handleTypeChange(e)} id="complaint_type"
                           options={this.getMultiSelectOptions(`complaint_type`)} />
                      </div>
                      }
                      <div className="form-group row reset-margin justify-content-center pb-2">
                        <label className="col-md-3"><b>Remarks<font className="text-danger">   *  </font></b></label>
                        <div className="col-md-5 ">
                          <textarea type="text" id="remarks" className="form-control" rows = {5} onChange={this.handleRemarks}  maxLength='512'/>
                        </div>
                      </div>
                  </fieldset>
                     </div>
                     </div>
                     <div className="form-group d-flex  reset-margin justify-content-center py-4">
                      <Button  variant = "danger" disabled={this.state.calling_api} className= "mr-3" onClick={this.handleCancelCall} ><MdCallEnd className ="mr-2" />Cancel Call Log</Button>
                      <Button  variant = "success" disabled={this.state.calling_api}  onClick={this.handleCallComplete} ><MdCall className ="mr-2" />Complete Call Log</Button>
                     </div>
            </Card.Body>
          </Card>
        </form>
      </div>
    )
	}
}


const mapStateToProps = (state) => {
  return { duplicate_txn_value: state.cs_soft_phone };
};

const mapDispatchToProps = {
  setValuesIntoRedux
};
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(CallLog));
