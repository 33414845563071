import { useQuery } from "@tanstack/react-query";
import { getPrevCallIntercations } from "../../../actions/cs_management_actions";

type PrevCallInteractionGetResProps = {
  data: CallInteractionsListProps[];
};

type CallInteractionsListProps = {
  call_date: string;
  call_dialed_time: string;
  call_purpose: string;
  caller_name: string;
  mobile_num: string;
  remarks: string;
};

export const useGetPrevCallInteractionQuery = ({
  custId,
}: {
  custId: string | undefined;
}) => {
  const { isFetching, isFetched, data, isLoading, isError } =
    useQuery<PrevCallInteractionGetResProps>({
      queryKey: ["prev_call_interactions", custId],
      queryFn: () => getPrevCallIntercations({ cust_id: custId }),
      enabled: !!custId,
    });

  return { isLoading, isFetching, isFetched, data, isError };
};
