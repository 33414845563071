
import { useSoftPhoneCtx } from "../../../context/softPhoneCtx/useSoftPhoneCtx";
import PendingRmVisits from "./PendingRmVisits";

const RMVisits = () => {
  // softphone ctx
  const { softPhoneState, methods: softPhoneMethods } = useSoftPhoneCtx();
  return (
    <PendingRmVisits
      softPhoneCtxState={softPhoneState}
      softPhoneCtxMethods={softPhoneMethods}  
    />
  );
};

export default RMVisits;
