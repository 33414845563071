import React from 'react';
import Sms_modal from '../../loan/container/Sms_modal';
import { Button, Card, Modal, Spinner } from 'react-bootstrap';
import { ExcesstimeAction, TooltipLabel } from '../../common/partial';
import { Homeacc_prvdrLogoselector, dynAccPrvdrFilter, title_case } from '../../../helpers/common_helper';
import { Link } from 'react-router-dom';
import { Config, check_priv, get, set } from '../../../helpers/storage_helper';
import { searchLoan } from '../../../actions/loan_actions';
import CallLog from '../../call_log/component/CallLog';
import FlowComponent from '../../common/component/core/FlowComponent';
import { lbl_amt } from '../../../helpers/product_helper';
import { connect } from 'react-redux';
import { setValuesIntoRedux } from '../../../Redux/Slices/CsSoftphoneSlice';
import { v4 as uuidv4 } from 'uuid';
import MUIAccordion from '../../../MUI/Components/MUIAccordion';
import DataGridTable from '../../CommonComponent/table';
import CustomTableGridToolbar from '../../CommonComponent/table/components/customTableGridToolbar';
import AccProviderFilter from '../../common/component/AccProviderFilter';
import { handleProviderSelection } from '../../../helpers/helper';
import { getCSDevice } from '../../../actions/cs_management_actions';

class PendingWithCustomer extends FlowComponent {
    constructor(props) {
        super(props);
        this.state = {
            dynamic_filter: {
                dyn_Btn_selected: [],
                all_Btn_status: false,
                filter_actions: {},
                copy: [],
            },
            refresh: false,
            rowdatas: [],
            columndatas: [],
            page: { page: 0, pageSize: 10 },
            selectedProviders: [],
            filteredRows: [],
            cs_status: 'disabled', // used to handle the getconfirmation screen based on softphone status
        }
        this.softphone_supported_countries = Config('softphone_supported_countries')?.includes(get('market').country_code)
    }

    componentWillMount() {

        const acc_prvdr_support_ussd = Config("acc_prvdr_support_ussd")
        this.setState({ acc_prvdr_support_ussd })
        this.pendingCustList()
        this.getCustStatus()
    }

    getCustStatus () {
        getCSDevice({ type: "webrtc" }).then(r => {
            this.setState({cs_status: r?.data?.status})
        })
    }

    pendingCustList = (acc_prvdrs = this.state.all_Acc_pvdrs) => {
        const fa_rm_approval_late2 = Config("fa_rm_approval_late2")
        const fa_late2 = Config("fa_late2")
        const fa_cust_late2 = Config("fa_cust_late2")
        let loan_search_criteria = { pending_w_cust: 'true', mode: 'search', alert: false }
        searchLoan(this.req({ loan_search_criteria, home_table: true }))
            .then((response) => {
                if (!response) { return }
                if (response.status == "success") {
                    let data = response.data.results.map((row) => ({
                        ...row,
                        id: uuidv4(),
                    }));
                    this.setState({ columndatas: this.columndatas(), server_time: response.server_time, fa_rm_approval_late2, fa_late2, fa_cust_late2, rowdatas: data })
                    this.handle_acc_prvdr("All", data)
                }
            })
    }


    handle_acc_prvdr(provider, rows = null) {
        handleProviderSelection(
            provider,
            this.state.selectedProviders,
            this.props.acc_prvdr_list,
            rows ?? this.state.rowdatas,
            (updated_prvdr) => this.setState({ selectedProviders: updated_prvdr }),
            (filtered_rows) => this.setState({ filteredRows: filtered_rows })
        );
    }

    columndatas() {
        return [
            {
                headerName: "A/C Number",
                headerAlign: 'left',
                flex: 1, resizable: true,
                align: 'left',
                width: 180,
                field: 'acc_number',
                renderCell: params => <Link to={"/borrower/indiv/view/" + params.row.cust_id} target="_blank" >{params.row.acc_number}</Link>,

            },
            {
                headerName: 'Channel',
                field: 'channel',
                align: 'left',
                headerAlign: 'left',
                flex: 1, resizable: true,
                width: 200,
                renderCell: params => { return <p className='text-light text-uppercase '>{params.row.channel ? title_case(params.row.channel) : ''}</p> }
            },
            {
                headerName: "ACC PRVDR",
                field: 'acc_prvdr_code',
                headerAlign: 'left',
                flex: 1, resizable: true,
                width: 180,

                align: 'left',
                renderCell: params => <Homeacc_prvdrLogoselector logotype={params.row.acc_prvdr_code} />
            },
            {
                headerName: 'FA ID',
                field: 'loan_doc_id',
                headerAlign: 'left',
                flex: 1, resizable: true,
                width: 240,
                align: 'left',
                renderCell: params => <Link to={"fa/view/" + params.row.loan_doc_id} target="_blank" >{params.row.loan_doc_id}</Link>
            },
            // {
            // 	headerName : "Mobile Number",
            // 	 headerAlign: 'left',

            // 	field: 'cust_mobile_num',
            // 	renderCell: row => {return row.cust_mobile_num}
            // },
            {
                headerName: 'Biz Name & Mobile Number',
                field: 'cust_name',
                width: 240,
                headerAlign: 'left',
                flex: 1, resizable: true,
                align: 'left',
                // sortMethod: (a,b) => this.sortBiz(a,b),
                renderCell: params => <div className="vertical_aligned">
                    <div className='column_line_height'>
                        <div>
                            <TooltipLabel full_text={params.row.biz_name} overlay={params.row.cust_name} no_reduce={true} />
                        </div>
                        <div>
                            <label className="no-margin d-block">{params.row.cust_mobile_num}</label>
                        </div>
                    </div>
                </div>
            }, {
                headerName: 'FA Amount',
                field: 'loan_principal',
                flex: 1, resizable: true,
                headerAlign: 'left',
                width: 240,

                align: 'left',
                // sortMethod : (a,b) => this.sortAmount(a,b),	        			
                renderCell: params => <div style={{ paddingRight: 15 }}>{lbl_amt(params.row.loan_principal, this.currency_code)}</div>
            },
            {
                headerName: "Applied & Approved",
                field: 'loan_appl_date',
                width: 240,
                headerAlign: 'left',
                align: 'left',
                flex: 1, resizable: true,
                // sortMethod: (a,b) => this.sortTime(a,b),
                renderCell: params => <div className='vertical_aligned'

                >
                    <div className="column_line_height">
                        <div>
                            Applied:{" "}
                            <ExcesstimeAction
                                data={params.row}
                                timedata={this.state.server_time}
                                item={"applied"}
                                time_limit={[this.state.fa_late1, this.state.fa_late2]}
                            />
                        </div>
                        <div>
                            Approved:{" "}
                            <ExcesstimeAction
                                data={params.row}
                                timedata={this.state.server_time}
                                item={"approved"}
                                time_limit={[this.state.fa_cust_late1, this.state.fa_cust_late2]}
                            />
                        </div>
                    </div>
                </div>

            },
            // {
            // 	headerName : "RM Approved",	
            // 	field:'loan_approved_date',
            // 	width: 120,
            // 	 headerAlign: 'left',

            // 	accessor:row => <ExcesstimeAction data={row} timedata={this.state.server_time.server_time_db} item={"approved"} time_limit={[this.state.fa_cust_late1,this.state.fa_cust_late2]}/>
            // },
            // {
            // 	headerName : "No. of Conf. Codes Sent",
            // 	accessor: 'otp_count',
            // 	 headerAlign: 'left',
            // },

            {
                headerName: 'Next Action',
                field: 'action',
                width: 200,
                flex: 1, resizable: true,

                align: 'left',
                headerAlign: 'left',
                renderCell: cell =>
                    <div>
                    {this.softphone_supported_countries && this.state.cs_status === 'enabled'  ?
                    <div className='text-left'>
                            <Button className={`${this.props.softPhoneState.pending_cust_loader ? "disable_div" : ""} btn-std1`} onClick={() => { this.call_to_customer(cell.row) }}
                            >
                            Get Phone Confirmation
                            </Button>
                    </div>
                :
                 <ExcesstimeAction data={cell.row} timedata={this.state.server_time} item={"next_action_conf"} action={this.props.handleOpen} time_limit={[this.state.fa_late1,this.state.fa_late2]}/> }
                 </div>
            },
            {
                headerName: 'SMS Logs',
                field: 'sms_logs',
                headerAlign: 'left',
                flex: 1, resizable: true,
                align: 'left',
                width: 200,

                renderCell: cell => <Sms_modal btn_style={'btn-sm'} view={'home'} loan_doc_id={cell.row.loan_doc_id} />
            },]
    }

    call_to_customer(data) {

        this.props.softPhoneCtxMethods.initiateCall({
            phoneNumber: data.cust_mobile_num ?? "",
            screen: "pending_w_cust",
            churnOrDeliquencyId: ""
        });
    }

    render() {
        const soft_phone_modal = (!this.props.softPhoneState.isIncomingCall && !this.props.softPhoneState.outgoingCall)

        const show_data_count = this.props.acc_prvdr_list?.length > 1
        return (
            <>
                {this.props.type == 'pending_w_cust' && <Modal dialogClassName="rec-form-modal" show={this.state.modal} onHide={this.handleClose}>
                    <Modal.Body closeButton>
                        <CallLog is_conf match={{ params: { id: this.state.call_id, cust_id: this.state.call_cust_id } }} onComplete={() => window.location.reload()} onCancel={() => this.setState({ modal: false })} />
                    </Modal.Body>
                </Modal>}
                {/* {this.props.softPhoneState.pending_cust_details && this.props.type == "pending_w_cust" && soft_phone_modal && this.softphone_supported_countries  &&
						<div className='Call_log_section zoomIn_animation'>
						<Softphone screen_from ={"pending_w_cust"} is_direct_outgoing = {true} cust_id={this.props.softPhoneState.pending_cust_details.cust_id ?this.props.softPhoneState.pending_cust_details.cust_id :null } outgoing_call_data = {this.props.softPhoneState.pending_cust_details} remove_state_value = {()=>{this.remove_state_value()}}/>
						</div>
				} */}
                <MUIAccordion
                    title={

                        <div className='home_header_d-flex_at_response'>
                            <h4 className="card-title text-white tableHead_font " style={{ width: "max-content" }}>FAs Pending Customer Confirmation </h4>

                            <AccProviderFilter
                                rows={this.state.rowdatas}
                                handle_acc_prvdr={(provider) => this.handle_acc_prvdr(provider)}
                                selectedProviders={this.state.selectedProviders}
                            />
                           

                        </div>


                    }
                    total_count={show_data_count ? 0 : (this.state.dynamic_filter?.copy?.length)}
                    body={

                        <DataGridTable
                            rows={this.state.filteredRows}
                            columns={this.state.columndatas ?? []}
                            paginationMode="client"
                            sortingMode="client"
                            rowCount={this.state.filteredRows?.length}
                            page={this.state.page}
                            setpage={(newPage) => this.setState({ page: newPage })}
                            isLoading={undefined}
                            isError={false}
                            errorText=""
                            noOfColumnsToLoad={10}
                            slots={{ toolbar: CustomTableGridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,

                                },
                            }}
                            rowHeight={70}

                        />


                    }

                />
            </>



        );

    }
}

const mapStateToProps = (state) => {
    return {
        softPhoneState: state.cs_soft_phone,
        acc_prvdr_list: state.common_slice?.acc_prvdr_list

    };
};

const mapDispatchToProps = {
    setValuesIntoRedux

};


export default connect(mapStateToProps, mapDispatchToProps)(PendingWithCustomer);



