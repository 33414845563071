import React from "react";
import { Config, check_priv, get, set } from "../../helpers/storage_helper";
import { FaRegBell } from "@react-icons/all-files/fa/FaRegBell";
import { useEffect, useMemo, useState } from "react";
import CustomFlag from "../common/component/CustomFlag";
import { useDispatch, useSelector } from "react-redux";
import SelectMarket from "../home/SelectMarket";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import {
  GetLatestNotifications,
  UpdateReadStatus,
  updateFcmToken,
} from "../../actions/notification_action";
import {
  changeHeaderSlice,
  changeNotificationState,
  updateNotficationModalstate,
} from "../../Redux/Slices/HeaderSlice";
import Notification from "../Notification/Notification";
import {
  common_req,
  convert_to_options,
  req,
} from "../../helpers/common_helper";
import NotificationModal from "../utility/Component/NotificationModal";
import { onMessage } from "firebase/messaging";
import { messaging } from "../../firebase/config";
import Logo from "../common/partials/logo";
import { Avatar, Box, Typography, useMediaQuery } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import IconButton from "@mui/material/IconButton";
import createCustomTheme from "../../MUI/Theme";
import { data } from "jquery";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import CustomButton from "../../MUI/Components/CustomButton";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import EditIcon from "@mui/icons-material/Edit";
import UploadProfilePic from "./UploadProfilePic";
import CustomDropdown from "../../MUI/Components/CustomDropdown";
import Router from "../../Router/Router";
import { user_profile_pic } from "../../actions/common_actions";
import {
  fixedDrawer,
  mail_badge,
  market_dropdown,
  sm_display_none,
} from "../../styles/common_styles";
import FuzzySearchBox from "./FuzzySearchBox";
import { updateValuesIntoRedux } from "../../Redux/Slices/CommonSlice";
import { switch_market } from "../../helpers/common_apicalls";
import QuickActions from "../home/QuickActions";

const Header = () => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const {
    notification,
    markets,
    admin_roles,
    profile_path,
    showavatar,
    is_drawer_open,
    root,
  } = useSelector((state) => state.HeaderSlice);
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const [update_market, setUpdatemarket] = useState(false);
  const datas = useSelector((state) => state.common_slice);
  const [market, setMarket] = useState(get("market"));
  const location = useLocation();
  const navigate = useNavigate();
 

  const onMessageListener = () =>
    onMessage(messaging, (payload) => {
      getNotifications();
    });

  const handle_visibility_change = () => {
    if (document.visibilityState === "visible") {
      getNotifications();
    }
  };

  useEffect(() => {
    if (get("market")) {
      changeHeaderSlice({ market: get("market") });
    }
    header_profile_pic();

    getNotifications();
    update_fcm_token();

    // get the latest notifications if background notifications is arrived
    document.addEventListener("visibilitychange", handle_visibility_change);

    //  whenever new fcm arrived
    onMessageListener();
    if (!markets && get("market_list")) {
      dispatch(changeHeaderSlice({ markets: get("market_list") }));
    }

    return () => {
      document.removeEventListener(
        "visibilitychange",
        handle_visibility_change
      );
    };
  }, []);

  const update_fcm_token = () => {
    const fcmToken = localStorage.getItem("fcmToken")
      ? localStorage.getItem("fcmToken")
      : null;
    const updatedfcmToken = localStorage.getItem("updated_fcmToken")
      ? localStorage.getItem("updated_fcmToken")
      : "";
    if (fcmToken && fcmToken != updatedfcmToken) {
      updateFcmToken(common_req({ fcm_token: fcmToken })).then((response) => {
        if (!response) return;
        if (response.status == "success") {
          localStorage.setItem("updated_fcmToken", fcmToken);
        } else {
          return;
        }
      });
    }
  };

  const getNotifications = () => {
    GetLatestNotifications(common_req({ notification_count: 8 })).then(
      (response) => {
        dispatch(changeNotificationState({ loader: true }));
        if (!response) {
          dispatch(
            changeNotificationState({ loader: false, no_notification: true })
          );
          return;
        }
        if (response.status == "success") {
          if (response.data.length == 0) {
            dispatch(
              changeNotificationState({ loader: false, no_notification: true })
            );
            return;
          }
          dispatch(
            changeNotificationState({
              loader: false,
              notification_arr: response.data,
              no_notification: false,
            })
          );

          let un_read = response.data.filter((val) => val.read == false);
          dispatch(
            changeNotificationState({
              un_read_notifications_count: un_read.length,
            })
          );
        }
      }
    );
  };

  // var select_market = false

  const selectMarket = (value) => {
    const market_details = get("market_list");
    const market = market_details?.find((item) => item.country_code === value);
    const language_json = Config("language_json") ?? {};
    if (market) {
      set("language_json", language_json[market.country_code]);
      switch_market(market,dispatch,navigate)

    }
  
  };

  const handleBellclick = (option) => {
    if (option == null) {
      option = !notification.show_notification;
    }

    dispatch(
      changeNotificationState({
        show_notification: option,
      })
    );
  };

  const change_read = (notification) => {
    if (notification.read == false) {
      dispatch(changeNotificationState({ loader: true }));

      UpdateReadStatus(common_req({ notification_id: [notification.id] })).then(
        (response) => {
          if (!response) {
            dispatch(
              changeNotificationState({ loader: false, no_notification: true })
            );
            return;
          }
          if (response.status === "success") {
            getNotifications();
          }
        }
      );
    }
  };

  const handle_portfolio_modal = (option) => {
    if (
      notification.portfolio_details &&
      notification.portfolio_details.type == "flagged_customers" &&
      !option
    ) {
      change_read(notification.portfolio_details);
    }
    dispatch(
      changeNotificationState({
        portfolio_modal: option,
      })
    );
  };

  const set_portfolio_details = (notification) => {
    dispatch(updateNotficationModalstate(notification)).then(() => {
      handle_portfolio_modal(true);
    });
  };

  const setColorMode = () => {
    const current_mode = datas ? datas.mode : "dark";
    const theme = current_mode == "light" ? "dark" : "light";
    dispatch(updateValuesIntoRedux({ mode: theme }));
  };

  const header_profile_pic = (file_details = null) => {
    var request = file_details ? file_details : { type: "get" };
    user_profile_pic(req(request)).then((response) => {
      if (!response) {
        return;
      }
      if (response && response.status == "success" && request.type == "get") {
        dispatch(
          changeHeaderSlice({
            profile_path:
              root + response.data.file_path + "/" + response.data.file_name,
            user_email: response.data.email,
          })
        );
      }
    });
  };

  const fixedDrawer = {
    position: "fixed",
    top: "1px",
    right: 0,
    background: theme.palette.custom_colors.primary_900,
    "@media (min-width: 991px)": {
      width: "calc(100% - 94px)",
      zIndex: 1000,
    },
    height: "80px",
  };

 const quick_access = (check_priv('application', 'apply') || check_priv('field_visit','checkin') || check_priv('call_log','post') ||  check_priv("loan", "apply")) 
 const select_market =
    get("role_codes") &&
    ((admin_roles && admin_roles?.includes(get("role_codes"))) ||
    Config("global_users").includes(get("role_codes")));
  return (
    <Box sx={fixedDrawer}>
      <div className="main-pannel">
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          {check_priv("user", "fuzzy_search") &&
            <Box sx={sm_display_none}>
              <FuzzySearchBox />
            </Box>
          }

          { (datas.home_screen_cards_len <=1 && !isTablet ) &&quick_access &&
          
          <Box sx={sm_display_none}>
          <QuickActions/>
          </Box>
          }
       
         </Box>


          <div className="d-flex justify-content-end responsive_header my-2">
            <Box
              display={"flex"}
              className="my-2"
              justifyContent={"end"}
              alignItems={"center"}
            >
            
              {select_market && markets?.length>0  ?  (
                <Box display={"flex"} alignItems={"center"}>
                  <Typography
                    variant="s_inter_regular_white"
                    sx={sm_display_none}
                    className="text-center"
                    component={"div"}
                  >
                    Market
                  </Typography>
                  <CustomDropdown
                    style={market_dropdown(theme)}
                    default_value={get("market").country_code}
                    menu_items={convert_to_options(
                      markets,
                      "country",
                      "country_code"
                    )}
                    contains_img={true}
                    onChange={(country_code) => selectMarket(country_code)}
                  />
                </Box>
              ) : (
                <Box className="mx-2">
                  <CustomFlag
                    country_code={market.country_code}
                    height={45}
                    width={45}
                  />
                </Box>
              )}
              {location.pathname != "/view_all_notifications" &&
                location.pathname != "/report/par_health_report" &&
                check_priv("user", "web_notification") &&
                notification && (
                  <CustomButton
                    badge={true}
                    badge_style={[
                      mail_badge,
                      {
                        backgroundColor:
                          theme.palette.custom_colors.secondary_500,
                      },
                    ]}
                    badge_content={notification.un_read_notifications_count}
                    onClick={() => {
                      handleBellclick(null);
                    }}
                  />
                )}
              <Avatar
                className="ml-2 mr-4 pointer"
                onClick={() => {
                  dispatch(changeHeaderSlice({ showavatar: !showavatar }));
                }}
                sx={{
                  background: theme.palette.custom_colors.secondary_a_100,
                  width: 45,
                  height: 45,
                }}
                alt={get("user_name")}
                src={`${profile_path}`}
              />
            </Box>
            {notification.show_notification && (
              <Notification
                props={notification}
                handleBell={handleBellclick}
                handle_modal={handle_portfolio_modal}
                set_portfolio_details={set_portfolio_details}
                change_read={change_read}
              />
            )}
          </div>
        </Box>
        {showavatar && (
          <Box
            onMouseLeave={() =>
              dispatch(changeHeaderSlice({ showavatar: false }))
            }
            className="avatar_info_box d-flex justify-content-center"
            sx={{
              borderColor: theme.palette.custom_colors.secondary_a_500,
              background: theme.palette.custom_colors.primary_900,
            }}
          >
            <Box>
              <UploadProfilePic
                header_profile_pic={(file) => {
                  header_profile_pic(file);
                }}
              />
            </Box>
          </Box>
        )}
        {location.pathname != "/report/par_health_report" &&
          location.pathname != "/view_all_notifications" &&
          notification.portfolio_modal && (
            <NotificationModal
              notification={notification}
              handle_modal={handle_portfolio_modal}
              handle_bell_click={handleBellclick}
              id="portfolio_modal"
            />
          )}
      </div>
    </Box>
  );
};

export default Header;
