import {
  DispatchProps,
  SET_CUSTOMER_DETAILS_DISPATCH,
  SoftPhoneAndFreshChatFloatUIState,
  TOGGLE_FLOAT_BTN_UI_DISPATCH,
  TOGGLE_FLOAT_MODAL_FOR_DISPATCH,
} from "./softPhoneAndFreshChatFloatUICtx";

// reducer
function softPhoneAndFreshChatFloatUICtxReducer(
  state: SoftPhoneAndFreshChatFloatUIState,
  action: DispatchProps
) {
  const { type, payload } = action;
  switch (type) {
    case TOGGLE_FLOAT_BTN_UI_DISPATCH:
      return {
        ...state,
        showFloatBtnsOptions: payload.showFloatBtnsOptions,
      };
    case TOGGLE_FLOAT_MODAL_FOR_DISPATCH:
      return {
        ...state,
        floatModalFor: payload.floatModalFor,
      };
    case SET_CUSTOMER_DETAILS_DISPATCH:
      return {
        ...state,
        callState: {
          ...state.callState,
          screen: payload.screen,
          isCustomer: payload.isCustomer,
          deliquencyId: payload.deliquencyId,
          churnId: payload.churnId,
          customer: {
            ...state.callState.customer,
            custId: payload.custId,
            mobileNum: payload.mobileNum,
          },
        },
      };
    default:
      return state;
  }
}

export default softPhoneAndFreshChatFloatUICtxReducer;
