import React, { useEffect, useState } from "react";
import Fuse from "fuse.js";
import MenuList from "../home/menuList";
import {
  TextField,
  Typography,
  Box,
  List,
  ListItem,
  IconButton,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const FuzzySearchBox = (props) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [options, setOptions] = useState([]);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.sub_menus) {
      setOptions(mergeMenus(props.sub_menus));
    }
  }, [props.sub_menus]);

  const mergeMenus = (menuObject) => {
    let mergedArray = [];
    if (!menuObject) return mergedArray;
    Object.values(menuObject).forEach((menuArray) => {
      if (!Array.isArray(menuArray)) return;
      menuArray.forEach((menuItem) => {
        if (menuItem?.sub_menus && Array.isArray(menuItem.sub_menus)) {
          menuItem.sub_menus.forEach((subItem) => {
            if (subItem.prev && menuItem.url !== "modal") {
              mergedArray.push(subItem);
            }
          });
        } else if (menuItem?.prev && menuItem.url !== "modal") {
          mergedArray.push(menuItem);
        }
      });
    });
    return mergedArray;
  };

  const fuse = new Fuse(options || [], {
    keys: ["label", "fuzzy_key"],
    threshold: 0.3,
  });

  const handleInputChange = (event) => {
    const value = event.target.value;
    setQuery(value);

    if (value && value.trim() !== "") {
      const fuseResults = fuse.search(value).map((result) => result.item);
      const uniqueResults = fuseResults.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.label === item.label)
      );
      setResults(uniqueResults);
      setFocusedIndex(-1); 
    } else {
      setResults([]);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      setFocusedIndex((prev) => (prev < results.length - 1 ? prev + 1 : 0));
    } else if (event.key === "ArrowUp") {
      setFocusedIndex((prev) => (prev > 0 ? prev - 1 : results.length - 1));
    } else if (event.key === "Enter") {
      if (focusedIndex >= 0 && results[focusedIndex]) {
        handleOptionSelect(results[focusedIndex]);
      } else if (query.trim() !== "") {
        const exactMatch = results.find((result) => result.label === query);
        if (exactMatch && exactMatch.url) {
          navigate(exactMatch.url);
        }
      }
    }
  };

  const handleOptionSelect = (selectedOption) => {
    setQuery(selectedOption.label); 
    if (selectedOption.url) {
      navigate(selectedOption.url);
    }
    setResults([]); 
    setFocusedIndex(-1); 
  };

  const handleClear = () => {
    setQuery(""); 
    setResults([]); 
    setFocusedIndex(-1); 
  };

  return (
    <Box sx={{ position: "relative", width: "334px",mx:2 }}>
      <TextField
        value={query}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        variant="outlined"
        placeholder="Search"
        fullWidth
        InputProps={{
          startAdornment: (
            <SearchIcon sx={{ fontSize: "20px", marginRight: "8px" }} />
          ),
          endAdornment: query && (
            <IconButton onClick={handleClear} size="small">
              <CloseIcon />
            </IconButton>
          ),
          style: {
            backgroundColor: theme.palette.custom_colors.primary_500,
            borderRadius: "12px",
          },
        }}
      />
      {results.length > 0 && (
        <List
          sx={{
            position: "absolute",
            top: "100%",
            left: 0,
            right: 0,
            bgcolor: theme.palette.background.paper,
            borderRadius: "12px",
            boxShadow: theme.shadows[3],
            maxHeight: "250px",
            overflowY: "auto",
            zIndex: 1000,
          }}
        >
          {results.map((option, index) => (
            <ListItem
              key={option.label}
              button
              onClick={() => handleOptionSelect(option)}
              sx={{
                bgcolor:
                  index === focusedIndex
                    ? theme.palette.action.hover
                    : "transparent",
                "&:hover": {
                  bgcolor: theme.palette.action.hover,
                },
              }}
            >
              <Typography variant="s_inter_regular_white">
                {option.label}
              </Typography>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default MenuList(FuzzySearchBox);
