import CallIcon from "@mui/icons-material/Call";
import { Alert, Box, Button, Divider, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ERROR_INFO } from "../../../consts";
import RHFTextField from "../../CommonComponent/rhfComponents/rhfTextField";
import {
  BodyVarientTwoTypo,
  HeaderVarientH2Typo,
  HeaderVarientH4Typo,
  HeaderVarientH5Typo,
} from "../../CommonComponent/typography";
import { useGetCustDetailsByUserNameQuery } from "../hooks/useGetCustDetailsQuery";
import CustNameAndSinceUI from "./custNameAndSinceUI";

const NoCustFoundUI = () => {
  // form ctx
  const { watch, setValue } = useFormContext();

  // state
  const [userName, setuserName] = useState("");

  // queryhook
  const { data, isError, isLoading, isFetched } =
    useGetCustDetailsByUserNameQuery({
      userName,
      phoneNumber: watch("phoneNumber"),
    });

  // cb
  const searchForCustomer = () => {
    setuserName(watch("custName"));
  };

  // effect
  useEffect(() => {
    return () => {
      setuserName("");
      setValue("custName", "");
    };
  }, []);

  // const
  const disableSubmitBtn = !watch("custName");
  const isErrorOccured = isError || (isFetched && !data?.data);

  return (
    <Box my={2}>
      <Alert severity="info">No customers found with this mobile number!</Alert>
      <Box display="flex" alignItems="center" py={2}>
        <RHFTextField
          name="custName"
          label="Customer Name"
          placeholder="Search by Customer Name"
          sx={{ maxWidth: "500px" }}
        />
        <Button
          onClick={searchForCustomer}
          disabled={disableSubmitBtn}
          variant="contained"
          color="secondary"
          sx={{ minWidth: "75px", ml: 2 }}
        >
          Submit
        </Button>
      </Box>
      {isLoading && ( // loading
        <HeaderVarientH2Typo
          overRideStyles={{ color: "#fff", textAlign: "center", py: 6 }}
          text={"Loading..."}
        />
      )}
      {isErrorOccured &&
        !isLoading && ( // error UI
          <HeaderVarientH2Typo
            overRideStyles={{ color: "#fff", textAlign: "center", py: 6 }}
            text={ERROR_INFO}
          />
        )}
      {isFetched && data?.data.customer_list.length === 0 ? ( // empty list UI
        <HeaderVarientH2Typo
          overRideStyles={{ color: "#fff", textAlign: "center", py: 6 }}
          text={"No customers found!"}
        />
      ) : (
        <>
          {data?.data.customer_list.map((c, idx) => (
            <Box
              key={idx}
              sx={{ p: 2, backgroundColor: "#fff", borderRadius: "5px", mb: 2 }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <CustNameAndSinceUI custDetail={c} textColor={"#6C757D"} />
                <Box
                  onClick={() =>
                    setValue(
                      "phoneNumber",
                      c.mob_nums.mobile_num ||
                        c.mob_nums.alt_biz_mobile_num_1 ||
                        c.mob_nums.alt_biz_mobile_num_2,
                      {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true,
                      }
                    )
                  }
                >
                  <HeaderVarientH5Typo
                    text="View Customer"
                    overRideStyles={{
                      textDecoration: "underline",
                      color: "#0DCAF0 !important",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              </Box>
              <Divider sx={{ borderColor: "#6C757D", mb: 2 }} />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MobileNumberShowUI
                  label="Mobile Num"
                  val={c.mob_nums.mobile_num}
                />
                <MobileNumberShowUI
                  label="Alternate Biz Mobile Num 1"
                  val={c.mob_nums.alt_biz_mobile_num_1}
                />
                <MobileNumberShowUI
                  label="Alternate Biz Mobile Num 2"
                  val={c.mob_nums.alt_biz_mobile_num_2}
                />
              </Box>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

export default NoCustFoundUI;

const MobileNumberShowUI = ({ label, val }: { label: string; val: string }) => {
  // form ctx
  const { setValue } = useFormContext();
  return (
    <>
      {val && (
        <Box display="flex" alignItems="center">
          <Box>
            <BodyVarientTwoTypo
              text={label}
              overRideStyles={{ color: "#6C757D!important" }}
            />
            <HeaderVarientH4Typo
              overRideStyles={{ color: "#6C757D!important" }}
              text={val}
            />
          </Box>
          <IconButton
            type="button"
            onClick={() =>
              setValue("phoneNumber", val, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              })
            }
            sx={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              backgroundColor: "#397BAA",
              "&:hover": {
                backgroundColor: "#6099C5",
              },
              ml: 2,
            }}
          >
            <CallIcon sx={{ width: "25px", height: "25px" }} />
          </IconButton>
        </Box>
      )}
    </>
  );
};
