import React, { useEffect, useState, useMemo } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import { BodyVarientOneTypo } from '../../CommonComponent/typography';
import AuditKycCard from './MobileNumberCards';
import { viewLead } from '../../../actions/lead_actions';
import { useParams } from 'react-router-dom';
import { req } from '../../../helpers/common_helper';
import { useSelector } from 'react-redux';
import Softphone from '../../cs_management/container/cs_soft_phone';

const RekycMobileNumberVerification = ({
  biz_mobilenumbers_sdata,
  account_owner_handler,
  file_upload_submit_handler,
  verify_handler,
  audit_kyc_modify_handler,
  audit_kyc_submit_callLog,
  callLogRejectHandler,
}) => {
  const { id } = useParams(); // Retrieve the URL parameter

  const theme = useTheme();
  const [localBizMobileNumbersData, setLocalBizMobileNumbersData] = useState(biz_mobilenumbers_sdata);

  useEffect(() => {
    if (JSON.stringify(localBizMobileNumbersData.cust_reg_json)
      !== JSON.stringify(biz_mobilenumbers_sdata.cust_reg_json)) {
      setLocalBizMobileNumbersData(biz_mobilenumbers_sdata);
    }
  }, [biz_mobilenumbers_sdata]);

 

  const title = useMemo(() => `${localBizMobileNumbersData?.cust_reg_json?.same_as_owner_person ? '6' : '7'} - Mobile Number Verification`, [localBizMobileNumbersData]);

  const pendingWith = useMemo(() => localBizMobileNumbersData?.active_section?.pending_with === 'rm' ? 'RM' : 'YOU', [localBizMobileNumbersData]);


  const view_lead = () => {
    const lead_id = id;
    viewLead(req({ id: lead_id }))
      .then((response) => {
        if (response && response.status === 'success') {
          setLocalBizMobileNumbersData((prevData) => ({
            ...prevData,
            cust_reg_json: response.data.cust_reg_json,
          }));
        }
      })
      .catch((error) => {
        console.error("Failed to fetch lead data:", error);
      });
  };

  const SectionHeader = ({ title, subtitle }) => (
    <Box sx={{ background: '#01062c', padding: 3, textAlign: 'center' }}>
      <h2 style={{ color: '#fff' }}><b>{title}</b></h2>
      {subtitle && (
        <h3 style={{ color: '#F3EA0B', marginTop: '1rem' }}>
          <img src="/img/pending_with_rm.png" alt="Pending" height="50" />
          <span style={{ marginLeft: '0.5rem' }}><b>{subtitle}</b></span>
        </h3>
      )}
      <p style={{ color: '#fff', marginTop: '1rem' }}>Business Mobile Numbers</p>
    </Box>
  );

  const renderMobileNumbers = (numbers, section, json_type = null) => (
    numbers.map((item, idx) => {

      const isModified = Object.values(item).some(value =>
        typeof value === 'object'  && value?.modified === true && (json_type==null)
      );
    
      return (
        <Grid item xs={12} sm={6} md={12} my={3} key={idx}>
          <Box
            className={isModified? "rekyc_modified_num":""}
            component="fieldset"
            sx={{
              border:  isModified ? '2px solid #ff6600' :"2px solid #2FB8D8", // Custom border color
              borderRadius: '8px',
              maxWidth: '400px',
              color: '#fff',
              margin: '0 auto',
              backgroundColor: '#2c3e50', // Fieldset background
            }}
          >
            <legend
              style={{
                fontSize: '1.2rem',
                fontWeight: 'bold',
                color: '#ff6600',
                marginLeft:'15px'
              }}
            >
             {isModified ?"Modified" :""} 
            </legend>
           
            
            <AuditKycCard biz_mob_num={{ ...item, section: section, is_rekyc:true, isModified:isModified, json_type: json_type, index: idx }} {...handlers} />

          </Box>

        </Grid>)
    })
  );

  const renderGridSection = (numbers, section,) => {


    
   return( <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} sm={6} md={4}>
        {renderMobileNumbers( localBizMobileNumbersData?.old_cust_reg_json?.mobile_numbers?.[section], section, "old_json")}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        {renderMobileNumbers(localBizMobileNumbersData?.cust_reg_json?.mobile_numbers?.[section], section)}
      </Grid>
    </Grid>
  );
}



  const handlers = {
    numbers_verify: verify_handler,
    retrieval_api: account_owner_handler,
    audit_state: localBizMobileNumbersData,
    submit_holder_name_proof: file_upload_submit_handler,
    audit_kyc_modify_handler,
    audit_kyc_submit_callLog,
    callLogRejectHandler,
    cust_reg_json: localBizMobileNumbersData?.cust_reg_json,

    view_lead,
  };

  const { lead_num_verify, outgoing_call_data } = useSelector((state) => state.cs_soft_phone);


  return (
    <Box>
      <SectionHeader title={title} subtitle={`Pending with ${pendingWith}`} />


      <Grid container spacing={2} justifyContent={"center"} mt={2}>
        <Grid item xs={12} sm={6} md={4} >
          <Box sx={{ background: '#2fb8d8', padding: 2, m: '0 auto', maxWidth: '400px', textAlign: 'center' }}>
            <BodyVarientOneTypo text={'Business Mob Num (As per KYC)'} overRideStyles={{ color: theme.palette.text.primary, fontWeight: 600 }} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}  >
          <Box sx={{ background: '#f39348', padding: 2, m: '0 auto', maxWidth: '400px', textAlign: 'center' }}>
            <BodyVarientOneTypo text={`Business Mob Num (As Per Re-KYC)`} overRideStyles={{ color: theme.palette.text.primary, fontWeight: 600 }} />
          </Box>
        </Grid>
      </Grid>
      {renderGridSection(localBizMobileNumbersData?.cust_reg_json?.mobile_numbers?.biz_mob_num || [], "biz_mob_num")}
      <Box sx={{ background: '#01062c', padding: 2, textAlign: 'center' }}>
        <BodyVarientOneTypo text={`Additional Mobile Numbers`} overRideStyles={{ color: theme.palette.text.primary, fontWeight: 600 }} />

      </Box>


      <Grid container spacing={2} justifyContent={"center"} mt={2} >
        <Grid item xs={12} sm={6} md={4} >
          <Box sx={{ background: '#2fb8d8', padding: 2, m: '0 auto', maxWidth: '400px', textAlign: 'center' }}>
            <BodyVarientOneTypo text={'Add Mob Num (As per KYC)'} overRideStyles={{ color: theme.palette.text.primary, fontWeight: 600 }} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}  >
          <Box sx={{ background: '#f39348', padding: 2, m: '0 auto', maxWidth: '400px', textAlign: 'center' }}>
            <BodyVarientOneTypo text={`Add Mob Num (As Per Re-KYC)`} overRideStyles={{ color: theme.palette.text.primary, fontWeight: 600 }} />
          </Box>
        </Grid>
      </Grid>

      {renderGridSection(localBizMobileNumbersData?.cust_reg_json?.mobile_numbers?.addl_num || [], "addl_num")}
     
      {/* {
        lead_num_verify &&
        <Softphone screen_from={"lead_num_verify"}
          is_direct_outgoing={true}
          lead_id={id}
          outgoing_call_data={outgoing_call_data} />
      } */}
    </Box>
  );
};

export default React.memo(RekycMobileNumberVerification);