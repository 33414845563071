import React,{ useState }  from 'react';
import '../../../styles/flow.css';
import {getLoan, cancelLoan, release_loan, repeatFloatAdvance, resend_OTP, allowPartialPayment, bypass_cust_conf} from '../../../actions/loan_actions';
import {dd_value, sendSMS} from '../../../actions/common_actions';
import DisburseLoan from '../component/DisburseLoan';
import InstantDisburseLoan from '../component/InstantDisburseLoan';
import { Navigate } from 'react-router-dom';
import FlowContainer from '../../common/container/core/FlowContainer';
import CustPanel  from './../../loan_appl/component/CustPanel';
import ProductPanel  from './../../loan_appl/component/ProductPanel';
import {title_case, word_count, get_loan_status, capitalize_first_letter, getValueFromLangJson} from '../../../helpers/common_helper';
import CaptureRepayment from '../../loan/component/CaptureRepayment';
import AddComment from '../../loan/component/AddComment';
import ViewComments from '../../loan/component/ViewComments';
import {Modal,Button } from 'react-bootstrap';
import ViewTransactions from '../../loan/component/ViewTransactions';
import ResendCode from "../../common/component/ResendCode";
import {check_priv, get} from '../../../helpers/storage_helper';
import Recovery from "../../recovery/Recovery";
import { event } from 'jquery';
import SyncPayment from '../../lender/component/SyncPayment';
import WriteOff from '../component/WriteOff';
import {Config} from '../../../helpers/storage_helper';
import {listSmsLogs} from '../../../actions/sms_action';
import moment from 'moment';
import { BasicReactTable } from '../../../helpers/react_table';
import withRouter from '../../../Router/Withrouter';
import { connect } from 'react-redux';
import Softphone from '../../cs_management/container/cs_soft_phone';
import { setValuesIntoRedux } from '../../../Redux/Slices/CsSoftphoneSlice';
import { SoftPhoneAndFreshChatFloatUICtx } from '../../../context/softPhoneAndFreshChatModalUICtx/softPhoneAndFreshChatFloatUICtx';
import { FA_VIEW_LOG_MODAL } from '../../../context/softPhoneCtx/softPhoneCtx';
import { DisbAtmptHistory } from '../../CommonComponent/DisbAtmptHistory';

class ViewLoanContainer extends FlowContainer {
	// here single context is required, if multiple context required need to wrap this class with another new class and from there need to pass the methods and state as prop to this component
	static contextType = SoftPhoneAndFreshChatFloatUICtx
	constructor(props) {
        super(props);
	this.state ={
		loan: [],
		viewReady: false,
		viewLoan:false,
		viewLoanAppln:false,
		loan_comment: {},
		disb_atmpt_modal:false,
		txn_mode: '',
		doc_id: '',
	}
	this.softphone_is_supported 
	}
componentWillMount(){
	let loan;
		const loan_doc_id = this.props.params.loan_doc_id;
		
		if(loan_doc_id == null){
			
			loan = this.props.data;
			this.setState({loan});
		}else{
			getLoan(this.req({loan_doc_id}))
			    .then((response) => {
				this.resp(response);
          		if(!response){return };
		            if(response.status === "success"){
		            	loan = response.data.loan;
						this.props.setValuesIntoRedux({cust_id :loan.cust_id })
						this.context.methods.setCustomerDetails({screen: FA_VIEW_LOG_MODAL, custId: loan.cust_id, isCustomer: true, mobileNum: loan.cust_mobile_num, churnId: "", deliquencyId: ""})
		            	this.setState({message : loan.message});
		            	this.setState({message_count: word_count(loan.message)});
		           		this.setState({loan});
		           		this.owner_person_id=loan.owner_person_id;
						this.setState({viewReady : true});
		           		const allow_pp = loan.allow_pp == "enabled" ? true : false;
						this.setState({loan_doc_id : loan.loan_doc_id,allow_pp});
		            }else{
		              alert(response.message + "\n" + JSON.stringify(response.data));  
		            }
		        }
		    );
		}
		this.softphone_is_supported =  check_priv('home', 'soft_phone')  && this.props.softPhoneState.is_softphone_supported

	}
handleSMSChange = (event) => {

	this.setState({message : event.target.value});
	this.setState({message_count: word_count(event.target.value)});
}
handleSendSMSClick = (event) => {
	const message = this.state.message;
	const recipient = this.state.loan.mobile_num;
	const request = {message , recipient};

	sendSMS(this.req(request))
		.then((response) => {
			   	if(response){       	
			        alert("Message Sent");
			    }	
		});
}

cancelLoan = (event) => {
	const resp = window.confirm("Are you sure to cancel FA?");
	if(resp == false){
		return;
	}
	const request = {"cancel_loan" : {'loan_doc_id' : this.state.loan.loan_doc_id}};
	
	cancelLoan(this.req(request))
		.then((response) => {
			this.resp(response);
			if(!response){return };
			   	if(response.status == 'success'){   
					window.location.reload();
			    }	
		});
}
releaseLoan = (event) => {
	const resp = window.confirm("Are you sure to release FA?");
	if(resp == false){
		return;
	}
	const request = {"loan_doc_id" : this.state.loan.loan_doc_id};
	release_loan(this.req(request))
		.then((response) => {
			this.resp(response);
			if(!response){return };
			   	if(response){ 	
					window.location.reload();
			    }	
		});
}

bypassCustConf = () => {
	const resp = window.confirm("Are you sure if you want to bypass the customer confirmation process and proceed to initiate FA disbursal?");
	if(resp == false){
		return;
	}
	const request = {"loan_doc_id" : this.state.loan.loan_doc_id};
	bypass_cust_conf(this.req(request))
		.then((response) => {
			this.resp(response);
			if(!response){return};
			if(response){
				window.location.reload();
			}
		})
}

resendOTP = (event) => {
	const request = {"loan_doc_id" : this.state.loan.loan_doc_id};
	resend_OTP(this.req(request)).then((response) => {
		this.resp(response);
		if(!response){return;}
		else{alert(response.message)}
});
}


repeatFloatAdvance = (event) => {
	const resp = window.confirm("Are you sure to confirm?");
	if(resp == false){
		return;
	}
	const request = {"cust_id" : this.state.loan.cust_id, "loan_doc_id" : this.state.loan.loan_doc_id};
	repeatFloatAdvance(this.req(request,true))
		.then((response) => {
			this.resp(response);
			if(!response){return };
			   	if(response){ 
					if(response.data.loan_application){
						const loan_appl_doc_id = response.data.loan_application.loan_appl_doc_id;	
						const approver_name = response.data.loan_application.loan_approver_name;	
						this.setState({loan_appl_doc_id, viewLoanAppln: true, approver_name});


					}else if (response.data.loan){
						const loan_doc_id = response.data.loan.loan_doc_id
						this.setState({loan_doc_id, viewLoan: true});

					}
					
				}
			   	alert(response.message);
					
		});
}
handleCheck = (event) => {
	
	const allow_pp = event.target.checked;

	const request = {'loan_doc_id' : this.state.loan_doc_id, 'allow_pp' : allow_pp };
	allowPartialPayment(this.req(request))
		.then((response) => {
			this.resp(response);
			if(!response){return};
			if(response.status === 'success'){
				if(response.data.allow_pp == 'enabled'){
					this.setState({allow_pp : true});
					alert("Customer Success will now be able to capture part payments for this FA.");
				}
				else if(response.data.allow_pp == 'disabled'){
					this.setState({allow_pp : false});
					alert("Customer Success will not be able to make part payment for this FA.");
				}
			}
		});

}
attempt_status = (doc_id,mode) => {
	this.setState({
		txn_mode: mode,
		doc_id: doc_id,
		disb_atmpt_modal: true
	});
}
handleClosemodel = () => {
	this.setState({ disb_atmpt_modal: false });
}

render(){
	if(!this.state.viewReady){
			return(<></>);
	}
	
	let action;
	// let approve_pp_check;
	let instant_disbursal;
	let initiate_disbursal;
	let capture_repayment;
	let capture_repayment_new;
	const loan_doc_id = this.state.loan.loan_doc_id;
	const cust_id = this.state.loan.cust_id;
	const lender_code = this.state.loan.lender_code;
	const acc_prvdr_code = this.state.loan.acc_prvdr_code;
	const lender_name = this.state.loan.lender_name;
	
	const appl_cancel = check_priv('application' , 'cancel');
	const release_loan = check_priv('loan','release');
	// const approve_part_payment = check_priv('loan','approve_part_payment');
	if(this.state.viewLoanAppln == true)
	{
		return <Navigate to={`/fa_appl/view/${this.state.loan_appl_doc_id}`} />
	}
	
	if(this.state.viewLoan == true)
	{
		return <Navigate to={`/fa/view/${this.state.loan_doc_id}`} />
	}
	// if(approve_part_payment){
	// 	approve_pp_check = <label className="checkbox-inline mt-3"><input id="approve_pp" type="checkbox" onChange = {(e) => {this.handleCheck(e)}} checked ={this.state.allow_pp}/><span> Allow Partial Payment</span></label>
	// }
	if(!this.state.loan.customer_consent_rcvd && this.state.loan.status === "pending_disbursal"){
		if(check_priv("loan","retry_disbursal")){
			initiate_disbursal = <Button variant="danger" onClick={this.bypassCustConf}>Bypass Cust Confirm</Button>
		}
	}

	// if(this.state.loan.customer_consent_rcvd && this.state.loan.status === "pending_disbursal"){
		
	// 	if(check_priv("loan","view_fa_capture_disbursal")){
	// 		const amount = this.state.loan.loan_principal;
	// 		const cust_acc_id = this.state.loan.cust_acc_id;
	// 		const acc_purpose = this.state.loan.loan_purpose;
			
	// 		this.disburse_loan = {loan_doc_id, cust_id, lender_code, amount, cust_acc_id,lender_name, acc_prvdr_code,acc_purpose};

	// 		action =  <DisburseLoan id="disbursal_txn" data={this.disburse_loan} title="Disbursal Loan" 
	// 					mode={this.props.mode} />
	// 	}
	// 	if(check_priv("loan","disburse")){
	// 		const cust_acc_id = this.state.loan.cust_acc_id;
	// 		let {biz_name, cust_name, acc_number, loan_principal,duration, flow_fee, due_amount,
	// 										to_acc_num, due_date, currency_code} = this.state.loan;
	// 		this.instant_disburse_loan = {biz_name, cust_name, acc_number, loan_principal,
	// 										duration, flow_fee, due_amount, loan_doc_id, 
	// 										cust_id, cust_acc_id, to_acc_num, lender_code, acc_prvdr_code, currency_code,due_date }
		
			
											
	// 		instant_disbursal = <InstantDisburseLoan id="instant_disbursal_txn" 
	// 						data={this.instant_disburse_loan} 
	// 						title="Instant Disbursal Loan" 
	// 						mode={this.props.mode} />	

	// 		}
		
	// }
	
	else if((this.state.loan.status === "ongoing" || this.state.loan.status === "due" || this.state.loan.status === "overdue")){
		const due_date = this.state.loan.due_date;
		const cust_id = this.state.loan.cust_id
		const current_os_amount = this.state.loan.current_os_amount;
		const status = this.state.loan.status;
		const acc_prvdr_code = this.state.loan.acc_prvdr_code
		const loan_purpose = this.state.loan.loan_purpose
		const provisional_penalty = this.state.loan.provisional_penalty;
		const penalty_collected = this.state.loan.penalty_collected;
		const allow_partial_payment = this.state.loan.allow_pp;
		const days_overdue = ("days_overdue" in this.state.loan) ? this.state.loan.days_overdue:'';
		const penalty_days = ("penalty_days" in this.state.loan) ? this.state.loan.penalty_days:''
		if(check_priv("loan","capture_repayment"))  {
			this.capture_repayment = {loan_purpose,loan_doc_id,lender_code,status,acc_prvdr_code,lender_name, allow_partial_payment,cust_id};	
			action = <SyncPayment id = "repayment_txn" mode="capture" parent = "view_fa" title="Capture Repayment (New)" 
			data={this.capture_repayment} />

		}

		
	}
	
	return(

			<div className="container main_div">				
				<br/>
				<div className="row view_loan_menu">
					{['approved', 'recovered', 'partially_recovered'].includes(this.state.loan.write_off_status) && (check_priv('loan', 'search_list_view_write_off')) &&
						<div class="closedStamp">
								<div className="stamp is-nope">Written-Off</div>
						</div>
					}
					<div className="col-sm-8 col-md-4">
						<div className="row p-inherit ">
							<h4 className='sizexm'>{getValueFromLangJson('float_advance_id')}</h4>
						</div>
						<div className="row p-inherit ">
							<h4 className='sizexm'><b>{this.state.loan.loan_doc_id}</b></h4>
						</div>
					</div>

					<div className="col-sm-4 col-md-2 ">
						<h4 className='sizexm'>{getValueFromLangJson('status')}: <br/><b className='sizexm'>{get_loan_status(this.state.loan)}
						{this.state.loan.status == 'overdue' && ` (${this.state.loan.overdue_days})`}
						</b></h4>
					</div>
					
					<div className="col-sm-8 col-md-4">
						<div className = ' d-flex justify-content-between p-inherit'>
						<div className="pb-2">

							{this.state.loan.status === "settled" && this.state.loan.pending_loan_appl_doc_id == null  && this.state.loan.ongoing_loan_doc_id == null 
								&& this.state.loan.last_loan_doc_id == this.state.loan.loan_doc_id &&  check_priv ("loan" ,"repeat_fa") &&
								<input type="button" disabled={this.state.calling_api} className="btn btn-primary pull mr-2" value={getValueFromLangJson('repeat_this_fa')} onClick={this.repeatFloatAdvance} />
							}
							{!this.state.loan.customer_consent_rcvd && this.state.loan.status === "pending_disbursal" &&
							<ResendCode entity_id={this.state.loan.loan_doc_id} otp_type="confirm_fa"/>}
							{action}
							{this.state.loan.status === "hold" &&  release_loan &&
								<div className="pb-2">
									<input type="button" className="btn btn-primary pull mr-2" value="Release" onClick={this.releaseLoan} />
								</div>
							}
						</div>
						<div className="pb-2">
							{instant_disbursal}
							{initiate_disbursal}
							{/* {(this.state.loan.status === "ongoing" || this.state.loan.status === "due" || this.state.loan.status === "overdue") &&
								approve_pp_check
							} */}
								
							

						</div>
						<div className="pb-2 mr-1">
							<ViewTransactions parent="view_loan" loan_doc_id={this.state.loan.loan_doc_id} btn={"btn"}/>
						</div>
							{this.state.loan.status === 'overdue' && check_priv('loan','capture_recovery') &&
							<div className="pb-2 mr-1">
								<Recovery id='recovery_txn' name="Request Cash" loan_doc_id={this.state.loan.loan_doc_id} cust_id={this.state.loan.cust_id} biz_name={this.state.loan.biz_name}/>
							</div>}
						{this.state.loan.status === "pending_disbursal" &&  appl_cancel && !this.state.loan.customer_consent_rcvd &&
							<div className="pb-2 mr-1" >

								<input type="button" className="btn btn-primary pull" value="Cancel" onClick={this.cancelLoan} />
							</div>
						}
						<div >
							{
								check_priv('disbursal', 'show_disb_atmpts') &&
								<button className='btn btn-primary pull mr-2'
									style={{ marginLeft: '10%',marginBottom: '3%'}}
									onClick={() => { this.attempt_status(loan_doc_id, 'Float Advance'); }}>
									Disb Attempts
								</button>
							}	
							{this.state.disb_atmpt_modal && (
								<DisbAtmptHistory
								docId={this.state.doc_id}
								mode={this.state.txn_mode}
								onClose={this.handleClosemodel}
								/>
							)}
						</div>
					</div>
					</div>
				</div>
				{this.state.loan.overdue_days > this.state.loan.write_off_od_days && this.state.loan.status == 'overdue' &&
					<WriteOff id = "write_off" loan_doc_id = {this.state.loan.loan_doc_id} acc_prvdr_code = {this.state.loan.acc_prvdr_code} cust_id = {this.state.loan.cust_id} write_off_status = {this.state.loan.write_off_status} write_off_id={this.state.loan.write_off_id}/>
				}
				<CustPanel parent="loan" from_fa_view={true} customer={this.state.loan}/>
				<ProductPanel parent="loan" credit_score={this.state.loan.credit_score} customer={this.state.loan} product={this.state.loan}/>

                
				{/* {this.state.loan.message &&
					<div>
						<div className="col-md-3">

							<h4><b className="text-danger">Notify customer on overdue</b></h4>
							<textarea rows="5" cols="30" maxlength="160" value={this.state.message} onChange={(e) => {this.handleSMSChange(e);}} />
							<br/>

							<div id="float_style">
								<b>({this.state.message_count} / 160)</b>
							</div>
							<br/>
                            </div>
                            <div className="col-md-4">
							<div>
								<input type="button" value="Send SMS" className="btn_style btn btn-primary" onClick={this.handleSendSMSClick}/>
							</div>
						    </div>
					</div>
				}
				<div className="row">
				<div className="col-md-6">
	      		<AddComment id="loan_comment" loan_comments={this.state.loan_comment} loan_doc_id={this.state.loan.loan_doc_id}
	            				onComponentChange={this.handleComponentChange}/>
	            </div>
	  
	            </div>			
	            <div>
	            	<ViewComments loan_doc_id={this.state.loan.loan_doc_id}/>
	            					
				</div> */}

               {/* {this.props.softPhoneState.softPhone && (!this.props.softPhoneState.missed_calls_view)   && (!this.props.softPhoneState.isIncomingCall) && (!this.props.softPhoneState.outgoingCall) && this.softphone_is_supported&&
				<div className='Call_log_section zoomIn_animation'>
					<Softphone screen_from ={"fa_applications"} cust_id={this.state.loan?this.state.loan.cust_id:null}/>
             
				</div>


			} */}
			</div>	
	
		);
}

}

const mapStateToProps = (state) => {
	return {
	  softPhoneState: state.cs_soft_phone,

	};
  };

const mapDispatchToProps ={
    setValuesIntoRedux
  
  };


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewLoanContainer));


// export default withRouter(ViewLoanContainer);
