import HistoryIcon from "@mui/icons-material/History";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import PhoneMissedIcon from "@mui/icons-material/PhoneMissed";
import SettingsPhoneIcon from "@mui/icons-material/SettingsPhone";
import { Box, List, ListItem, ListItemButton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { getCsCallLogs } from "../../../actions/cs_management_actions";
import { ERROR_INFO } from "../../../consts";
import EnclosedTabSwitchUI from "../../CommonComponent/enclosedTabSwitchUI";
import {
  HeaderVarientH2Typo,
  HeaderVarientH3Typo,
  HeaderVarientH4Typo,
  HeaderVarientH5Typo,
} from "../../CommonComponent/typography";

type SoftPhoneHistoryResProps = {
  data: CallDetailsProp[];
};

type CallDetailsProp = {
  call_start_time: string;
  hangup_causes: "NO_ANSWER";
  direction: "Inbound" | "Outbound";
  cust_number: string;
  cust_name: string;
  created_at: string;
  id: number;
  status: string;
};

const CallHistory = () => {
  // const
  const tabItems = useMemo(
    () => [
      {
        label: "All",
        icon: <HistoryIcon sx={{ width: "20px", height: "20px" }} />,
      },
      {
        label: "Missed",
        icon: <PhoneMissedIcon sx={{ width: "20px", height: "20px" }} />,
      },
      {
        label: "Connected",
        icon: <SettingsPhoneIcon sx={{ width: "20px", height: "20px" }} />,
      },
    ],
    []
  );

  // state
  const [tab, settab] = useState(0); // 0: All, 1: Missed, 2: Connected

  // query
  const { data, isLoading, isError, isFetched } =
    useQuery<SoftPhoneHistoryResProps>({
      queryKey: ["softphone_history_list"],
      queryFn: () => getCsCallLogs(),
    });

  // const
  const isErrorOccured = isError || (isFetched && !data?.data);
  const filteredAllCalls =
    isFetched && !isErrorOccured
      ? data?.data.filter((c) => {
          if (tab === 0) {
            return c;
          } else if (
            (tab === 1 &&
              c.call_start_time === null &&
              c.hangup_causes === "NO_ANSWER") ||
            (tab === 1 && c.status === "missed_call")
          )
            return c;
          else if (tab === 2 && c.call_start_time) {
            return c;
          }
        })
      : [];

  return (
    <>
      {/* tab section UI */}
      <EnclosedTabSwitchUI
        viewOverRideStyles={{
          "& button": { minHeight: "auto" },
          "& .MuiTabs-indicator": {
            height: "100% !important",
            backgroundColor: "#3149a1",
          },
          "& .MuiButtonBase-root": {
            zIndex: 1,
          },
          "& .MuiTabs-root": {
            minHeight: "35px",
            border: "solid 2px #3149a1",
            borderRadius: "5px",
            marginY: 2,
          },
        }}
        tab={tab}
        setTab={settab}
        tabItems={tabItems}
        activeColor="#fff"
      />
      {/* call history list UI */}
      <Box
        sx={{ height: "calc(76vh - 170px)", overflowY: "auto" }}
        display="flex"
        flexDirection="column"
        pb={2}
      >
        {isLoading && ( // loading
          <HeaderVarientH2Typo
            overRideStyles={{ color: "#fff", textAlign: "center", py: 6 }}
            text={"Loading..."}
          />
        )}
        {isErrorOccured && ( // error UI
          <HeaderVarientH2Typo
            overRideStyles={{ color: "#fff", textAlign: "center", py: 6 }}
            text={ERROR_INFO}
          />
        )}
        {/* {isFetched && data?.data.length === 0 && (
          <HeaderVarientH2Typo
            overRideStyles={{ color: "#fff", textAlign: "center", py: 6 }}
            text={"Empty logs..."}
          />
        )} */}
        {isFetched && filteredAllCalls && filteredAllCalls.length > 0 ? (
          <List sx={{ width: "100%", overflowY: "auto" }}>
            {filteredAllCalls.map((c) => {
              const iconColor = c.call_start_time ? "#02D130" : "red";
              const direction = c.direction;
              const custName =
                c.cust_name === "unknown" ? "UNKNOWN" : c.cust_name;
              const custNum = c.cust_number;
              const createdAt = c.created_at;

              // UI
              return (
                <CallItemUI
                  key={c.id}
                  iconColor={iconColor}
                  direction={direction}
                  custName={custName}
                  custNum={custNum}
                  createdAt={createdAt}
                />
              );
            })}
          </List>
        ) : (
          <>
            {!isLoading && !isError && (
              <HeaderVarientH2Typo
                overRideStyles={{ color: "#fff", textAlign: "center", py: 6 }}
                text={"Empty logs..."}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default CallHistory;

const CallItemUI = ({
  iconColor,
  direction,
  custName,
  custNum,
  createdAt,
}: {
  iconColor: string;
  direction: string;
  custName: string;
  custNum: string;
  createdAt: string;
}) => {
  // form ctx
  const { setValue } = useFormContext();

  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={() =>
          setValue("phoneNumber", custNum, {
            shouldDirty: true,
            shouldValidate: true,
          })
        }
        divider
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {direction === "Inbound" ? (
          <PhoneCallbackIcon
            sx={{ width: "22px", height: "22px", color: iconColor }}
          />
        ) : (
          <PhoneForwardedIcon
            sx={{ width: "22px", height: "22px", color: iconColor }}
          />
        )}
        <Box>
          <HeaderVarientH4Typo
            overRideStyles={{ color: "#fff" }}
            text={custName}
          />
          <HeaderVarientH3Typo
            overRideStyles={{ color: "#fff" }}
            text={custNum}
          />
        </Box>
        <ShowTimeStampUI createdAt={createdAt} />
      </ListItemButton>
    </ListItem>
  );
};

const ShowTimeStampUI = ({ createdAt }: { createdAt: string }) => {
  // cb
  const showDailedTime = useCallback((createdAt: string) => {
    const options = { timeZone: "Africa/Kampala" };

    const currentDateTime = new Date().toLocaleString("en-US", options);
    const currentTime: any = new Date(currentDateTime);

    const endDateTime = new Date(createdAt);
    const endTimeInKampala: any = new Date(endDateTime);

    const diffInMilliseconds = currentTime - endTimeInKampala;
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

    if (diffInMinutes >= 60) {
      const hours = Math.floor(diffInMinutes / 60);
      return hours > 1 ? `${hours} hours ago` : `${hours} hour ago`;
    } else {
      return diffInMinutes >= 1 ? `${diffInMinutes} min ago` : `just now`;
    }
  }, []);

  // state
  const [time, settime] = useState(showDailedTime(createdAt));

  // effect
  useEffect(() => {
    const intervalId = setInterval(
      () => settime(showDailedTime(createdAt)),
      60000
    );
    return () => clearInterval(intervalId);
  }, [createdAt]);

  return <HeaderVarientH5Typo overRideStyles={{ color: "#fff" }} text={time} />;
};
