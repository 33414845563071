import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { dd_value, disbursal_attempt } from '../../actions/common_actions';
import DataGridTable from './table';
import CustomTableGridToolbar from './table/components/customTableGridToolbar';
import { CloseIcon } from "../../helpers/common_helper";

export const DisbAtmptHistory = ({ docId, mode, onClose }) => {
  const [disbAtmptModal, setDisbAtmptModal] = useState(false);
  const [txnMode, setTxnMode] = useState('');
  const [docID, setDocID] = useState('');
  const [disbAtmptData, setDisbAtmptData] = useState([]);
  const [disbAtmptColumn, setDisbAtmptColumn] = useState([]);

  const handleFetchData = (docId, mode) => {
    setTxnMode(mode);
    setDocID(docId);

    const request = { loan_doc_id: docId };
    disbursal_attempt(request).then((response) => {
      if (!response) return;

      const rows = response.data.map((item) => {
        return {
          id: item.id,
          attemptDate: item.attempt_date,
          status: dd_value(item.status),
          isAutoRetry: item.is_auto_retry ,
          createdBy: item.created_by,
          Response: item.response,
          screenshot: item.screenshot_path,
        };
      });

      const columns = [
        { field: 'id', headerName: 'ID', minWidth: 100, flex: 1 },
        { field: 'attemptDate', headerName: 'Attempted at', minWidth: 200, flex: 1 },
        { field: 'status', headerName: 'Status', minWidth: 150, flex: 1 },
        { field: 'isAutoRetry', headerName: 'Attempt Made', minWidth: 150, flex: 1 },
        { field: 'createdBy', headerName: 'Attempted By', minWidth: 150, flex: 1 },
        {
          field: 'Response',
          headerName: 'Response',
          minWidth: 350,
          flex: 1,
          renderCell: (params) => (
            <div style={{ 
              display: 'flex',
              wordBreak: 'break-word',
              overflow: 'hidden',
              lineHeight: '1.8em', 
              whiteSpace: 'normal', 
              height: '100%' 
            }}>
              {params.value}
            </div>
          ),
        },
        {
          field: 'screenshot',
          headerName: 'Screenshot',
          headerAlign: 'center',
          minWidth: 200,
          flex: 1,
          renderCell: (params) => (
            <div style={{ textAlign: 'center' }}>
              {params.value ? <Link to={params.value} target="_blank">View</Link> : '-'}
            </div>
          ),
        },
      ];

      setDisbAtmptData(rows);
      setDisbAtmptColumn(columns);
      setDisbAtmptModal(true);
    });
  };

  const handleCloseModal = () => {
    setDisbAtmptModal(false);
    if (onClose) onClose();
  };

  React.useEffect(() => {
    if (docId && mode) {
      handleFetchData(docId, mode);
    }
  }, [docId, mode]);

  return (
    <Modal
      className="txn_modal"
      show={disbAtmptModal}
      onHide={handleCloseModal}
      backdrop="static"
      keyboard={true}>
      <Modal.Body className="justify-content-center align-items-center" >
        <div className='d-flex align-items-center' 
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '10px',
            marginTop: '20px',
          }}>
          <h4 style={{  flexWrap: 'wrap'}}> Disbursal Attempts of {txnMode} : {docID}</h4>
          <CloseIcon size={30} color="white" onClick={handleCloseModal} />
        </div>
      <div style={{ 
          width: '100%', 
          maxHeight: '70vh', 
          overflowX: 'auto', 
          overflowY: 'auto',  
        }}>
        <DataGridTable
          rows={disbAtmptData ?? []}
          rowCount={disbAtmptData?.length}
          columns={disbAtmptColumn ?? []}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};
