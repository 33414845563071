import { Box, IconButton, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { CSVLink } from 'react-csv';
import { Config } from '../../../helpers/storage_helper';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';

export type ExportCSVProps = {
  total_count?: number | undefined;
  handleExport?: Function;
  export_csv_datas: GridRowsProp; // Adjust type if needed
  csvFileName: string;
  paginationMode?: string;
  columns: GridColDef[];
  isCsvExportedToMail : boolean;
};

const ExportCsv = ({
  total_count,
  handleExport,
  export_csv_datas,
  csvFileName,
  paginationMode,
  columns,
  isCsvExportedToMail,
}: ExportCSVProps) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const exportcsv = () => {
    
    if (export_csv_datas.length === 0 && handleExport && paginationMode == 'server') {
      setLoading(true);  
      let resp = handleExport(total_count && total_count > Config("export_csv").limit ? 'email' : 'download');
      if(!resp) setLoading(false);
    }
  };
  useEffect(() => {
    if (loading && (export_csv_datas.length > 0 || isCsvExportedToMail)) {
      setLoading(false); 
    }
  }, [export_csv_datas, loading, isCsvExportedToMail]);

  const prepareCsvData = () => {
    const csvHeaders = columns?.map((col) => ({ label: col.headerName, key: col.field }));

    const csvRows = export_csv_datas?.map((row) => {
      const rowData: { [key: string]: any } = {};
      columns.forEach((col) => {
        rowData[col.field] = row[col.field];
      });
      return rowData;
    });

    return { headers: csvHeaders, data: csvRows };
  };

  const csvData = prepareCsvData();

  return (
    <Box display="flex" alignItems="center">
    {loading ? (
      <IconButton disabled sx={{ color: theme.palette.text.primary, borderRadius: 0 }}>
        <Typography sx={{ color: 'white', fontSize: '15px' }}>Loading...</Typography>
      </IconButton>
    ) : export_csv_datas.length > 0 ? (
      <CSVLink
       headers={csvData.headers}
        data={csvData.data}
         filename={csvFileName}
          style={{ textDecoration: 'none' }}>
        <IconButton sx={{ color: theme.palette.text.primary, borderRadius: 0 }}>
          <FileDownloadOutlinedIcon sx={{ fontSize: 20 }} />
          <Typography component="span" sx={{ px: 1, fontSize: '15px' }}>
            Export as CSV
          </Typography>
        </IconButton>
      </CSVLink>
    ) : (
      <IconButton onClick={exportcsv} sx={{ color: theme.palette.text.primary, borderRadius: 0 }}>
        <FileDownloadOutlinedIcon sx={{ fontSize: 15 }} />
        <Typography component="span" variant="body1" sx={{ px: 1 }}>
          Prepare for CSV
        </Typography>
      </IconButton>
    )}
  </Box>
  
  );
};

export default ExportCsv;
