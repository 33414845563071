import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  getValueFromLangJson,
  lbl_amt
} from "../../../../../helpers/common_helper";
import { format_date } from "../../../../../helpers/helper";
import DataGridTable from "../../../../CommonComponent/table";
import ExportCsv from "../../../../common/component/ExportCsv";

interface TableProps {
  rows?: Record<string, any>[];
  loader: boolean;
  tab: string
}

const Table: React.FC<TableProps> = ({ rows = [], loader, tab }) => {
  const [page, setPage] = useState({ page: 0, pageSize: 10 });




  const columns: GridColDef[] = [

    {
      headerName: getValueFromLangJson("flow_cust_id"),
      field: "cust_id",
      headerAlign: "left", width: 200,
      align: "left",
      resizable: true,
      renderCell: (cell) => (
        <Link
          to={`/borrower/indiv/view/${cell.row.cust_id}`}
          target="_blank"
          className="apply"
        >
          {cell.row.cust_id}
        </Link>
      ),
    },
    {
      headerName: 'Loan ID',
      field: "loan_doc_id",
      headerAlign: "left", width: 200,
      align: "left",
      resizable: true,
      renderCell: (cell) => (
        <Link
          to={`/fa/view/${cell.row.loan_doc_id}`}
          target="_blank"
          className="apply"
        >
          {cell.row.loan_doc_id}
        </Link>
      ),
    },
    {
      headerName: "Customer Name",
      field: "cust_name", width: 220,
      headerAlign: "left",
      align: "left",
      resizable: true,
    },

    {
      headerName: "Mobile No",
      field: "cust_mobile_num",
      headerAlign: "left", width: 190,
      align: "left",
      resizable: true,
    },
    {
      headerName: `${getValueFromLangJson("rm_label_short")}`,
      field: "flow_rel_mgr_name", width: 200,
    },
    {
      headerName: "FA Amount",
      field: "loan_principal",
      headerAlign: "left", width: 200,
      align: "left",
      resizable: true,
      renderCell: (cell) => cell.row.loan_principal ? lbl_amt(cell.row.loan_principal) : "-",
    },
    {
      headerName: 'Fee',
      field: "flow_fee", width: 200,
      renderCell: (cell) =>
        cell.row.flow_fee ? lbl_amt(cell.row.flow_fee) : "-",
    },
    {
      headerName: " Due Date",
      field: "due_date",
      headerAlign: "left",
      align: "left", width: 200,
      resizable: true,
      renderCell: (cell) => (
        <p className="m-0">
          {cell.row.due_date ? format_date(cell.row.due_date) : ""}

        </p>
      ),
    },
    {
      headerName: "Overdue Days",
      field: "overdue_days", width: 200,
      renderCell: (cell) => cell.row.overdue_days ?? "-",
    },
    {
      headerName: "Partially Paid",
      field: "partial_payment", width: 200,
    },
    {
      headerName: "Last Payment Date",
      field: "txn_date", width: 200,
      renderCell: (cell) => cell.row?.txn_date ? format_date(cell.row?.txn_date) : "-",
    },
    {
      headerName: "Amount Paid",
      field: "paid_amount", width: 200,
      renderCell: (cell) => cell.row?.paid_amount ? lbl_amt(cell.row.paid_amount) : "-",
    },
    {
      headerName: ` ${(tab === "overdue") ? "Outstanding" : "Due"} Amount`,
      field: "current_os_amount",
      headerAlign: "left", width: 200,
      align: "left",
      resizable: true,
      renderCell: (cell) =>
        cell.row.current_os_amount ? lbl_amt(cell.row.current_os_amount) : "-",
    },
  ];

  const itemsToRemove = ["overdue_days", "partial_payment","paid_amount"];

  const due_csv_columns = useMemo(() => (columns.map((c => ({field: c.field, headerName: c.headerName})))), [])
  const due_custs_col = useMemo(() => (due_csv_columns.filter(item => !itemsToRemove.includes(item.field))),[])


  return (
    <Box sx={{ my: 3 }}>
      {rows && rows.length > 0 &&
        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 10 }}>
          <ExportCsv
            export_csv_datas={rows ?? []}
            csvFileName={`Collections Due Report`}
            paginationMode={"client"}
            columns={tab == "due" ? due_custs_col :columns}
            isCsvExportedToMail={false}

          />
        </div>
      }

      {/* @ts-ignore */}
      <DataGridTable
        rows={rows}
        columns={ columns ??[]}
        paginationMode="client"
        sortingMode="client"
        page={page}
        rowCount={rows.length}
        setpage={setPage}
        isLoading={loader}
        noOfColumnsToLoad={7}
        columnVisibility={{
          overdue_days: tab === "overdue",
          paid_amount: tab === "overdue",
          partial_payment: tab === "overdue",
          due_date: tab === "overdue",
        }}

      />

    </Box>
  );
};

export default Table;