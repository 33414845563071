import CallIcon from "@mui/icons-material/Call";
import InfoIcon from "@mui/icons-material/Info";
import { Box, IconButton } from "@mui/material";
import { SOFT_PHONE_LOG_MODAL } from "../../../context/softPhoneCtx/softPhoneCtx";
import { useSoftPhoneCtx } from "../../../context/softPhoneCtx/useSoftPhoneCtx";
import { HeaderVarientH2Typo } from "../../CommonComponent/typography";
import useGetMissedCallLists from "../hooks/useGetMissedCallLists";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const MissedCallsUI = () => {
  // query
  const { data } = useGetMissedCallLists({ enabled: true });

  // ctx
  const {
    methods: { initiateCall },
    softPhoneState: { showCallStatusModal },
  } = useSoftPhoneCtx();

  // handler
  const initiateCallHandler = async (phoneNumber: string) => {
    initiateCall({
      phoneNumber: phoneNumber ?? "",
      screen: SOFT_PHONE_LOG_MODAL,
      churnOrDeliquencyId: "",
    });
  };

  // const
  const missedCallsList = data?.data?.missed_calls;
  const hasAnyOngoingCall = showCallStatusModal;
  const disableCallBtn = hasAnyOngoingCall;

  if (Array.isArray(missedCallsList) && missedCallsList?.length === 0)
    return (
      <HeaderVarientH2Typo
        text="No Missed Call List"
        overRideStyles={{
          color: "#000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      />
    );

  return (
    <Box sx={{ p: 2 }}>
      {missedCallsList &&
        missedCallsList.map((mC: any) => {
          return (
            <div className="m-1 misscall_card_border p-2" key={mC.id}>
              <div className="d-flex align-items-center my-1 justify-content-between">
                <div className="d-flex align-items-center">
                  <AccountCircleIcon sx={{ width: "20px", height: "20px" }} />

                  <div className="ml-2">
                    <p className="m-0 font_weight_600 ">
                      <b>{mC.cust_name}</b>
                    </p>
                    <p className=" mb-0  text-gray secondary_text_width">
                      {mC.cust_number}
                    </p>
                  </div>
                </div>

                <div>
                  <div className="d-flex align-items-center justify-content-center">
                    <div>
                      <IconButton
                        type="button"
                        disabled={disableCallBtn}
                        onClick={() => initiateCallHandler(mC?.cust_number)}
                        sx={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          backgroundColor: "#397baa",
                          "&:hover": { backgroundColor: "#4a90c1" },
                          ml: 2,
                        }}
                      >
                        <CallIcon sx={{ width: "25px", height: "25px" }} />
                      </IconButton>
                      <p
                        className="m-0 small_fontSize text-gray secondary_text_width"
                        style={{ textAlign: "right" }}
                      >
                        {" "}
                        Call Back{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center p-0 col-12 my-2 mb-3">
                <p className="m-0 bg_light_gray text-dark px-2 py-2 xs_font ">
                  <InfoIcon sx={{ mr: 1, width: "20px", height: "20px" }} />
                  This list will be disappear after 30 mins
                </p>
              </div>
            </div>
          );
        })}
    </Box>
  );
};

export default MissedCallsUI;
