
import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import { AiOutlineIdcard,AiOutlineMobile,AiFillShop ,AiFillEdit} from "react-icons/ai";
import{MdBusiness,MdPerson,MdAccountCircle,MdImage,MdPersonOutline,MdPhoto,MdLocationPin,MdArrowDropDown} from "react-icons/md";
import { BiRefresh,BiPhoneOff ,BiPhoneCall,BiXCircle,BiPhotoAlbum,BiEdit,BiMap,BiCheck} from "react-icons/bi";
import{GoVerified} from "react-icons/go";
import{FaHandsHelping} from "react-icons/fa";
import{HiOutlineDocumentAdd,HiPhoneOutgoing,HiArrowCircleRight,HiArrowCircleLeft}from"react-icons/hi";
import{ImCircleDown,ImCircleUp} from "react-icons/im";
import{ CgNotes}from'react-icons/cg';
import{BsClipboardData}from'react-icons/bs';
import FileUpload from '../../common/component/FileUpload';
import Alert from 'react-bootstrap/Alert';
import {get, Config} from '../../../helpers/storage_helper';
import { viewLead ,UpdateFlags,addComments,auditKycVerification,auditKyRetrievals,auditKycFileUpload, submitCallLog,RejectCallLog,auditkycModify,updateAccPrvdrCode} from '../../../actions/lead_actions';
import {capitalize_first_letter,getLabel,getValueFromLangJson,get_dir_path} from '../../../helpers/common_helper';
import DisplayImage from '../../common/component/DisplayImage';
import { approveKYC,updateLeadStatus} from '../../../actions/kyc_actions';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import isValid from 'date-fns/fp/isValid';
import {dd_value} from '../../../actions/common_actions';
import { connect } from 'react-redux';


class AuditKYCPhotosVerification extends FlowComponent{
    constructor() {
        super();
        this.uga_owner_address_alignment=["parish","sub_county","county","village","district","region","location","landmark"]
        this.rwa_owner_address_alignment=["province","district","sector","cell","village","location","territory","landmark"]
      
    }

    checkbox_spinner(){
        return(
          <div class="d-flex col-lg-10 py-2 px-5 mt-4 justify-content-center ml-5">
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
         </div>
        )
      }
      audit_section_spinner(){
        return(
          <div class="audit_spinner d-flex justify-content-center View_lead_section_loader" style={{margin:'20vh 0px  30vh 45%'}}>
          <span class="level level-1"></span>
          <span class="level level-2"></span>
          <span class="level level-3"></span>
          <span class="level level-4"></span>
          <span class="level level-5"></span>
          <span class="level level-6"></span>
        </div>
        )
      }
      
      rekyc_modify_state_handler(json_type,json_key,key){
        if((this.props.data[json_type][json_key][key].hasOwnProperty("modified")) && (this.props.data[json_type][json_key][key].modified)){
          return 'new_kyc_json';
        }else{
          return "";
          }

      }


    render(){
        var audit_kyc =  this.props.audit_kyc  && this.props.audit_kyc[this.props.shop_and_kyc_photo_datas.active_key]

        return(
            <div>
            {this.props.shop_and_kyc_photo_datas.audit_kyc_wizard_menus.kyc_photos &&
            <>{this.props.shop_and_kyc_photo_datas.View_lead_section_loader ? this.audit_section_spinner():

            <div className='row no-margin justify-content-center'>
            <div className='col-lg-12 py-3 mt-5 ' style={{background:' #01062c'}}>
            <h2 className='text-white text-center'>
            <b>{this.props.shop_and_kyc_photo_datas.cust_reg_json.same_as_owner_person ? "4" :"5"} - Customer Face Verification</b>
            </h2>
            <h2 className=' d-flex mt-1 text-center justify-content-center align-items-center' ><img src='/img/pending_with_rm.png' height={'50'}/> <b className='ml-2 align-self-cente' style={{color:'#F3EA0B'}}> Pending with {this.props.shop_and_kyc_photo_datas.active_section && (this.props.shop_and_kyc_photo_datas.active_section.pending_with=="rm"?getValueFromLangJson("rm_label_short"):'YOU')}</b></h2>

            </div>
            {this.props.shop_and_kyc_photo_datas.View_lead_section_loader ? this.audit_section_spinner():

            <>
        
            
           <div className='col-lg-10 pb-3 mt-2 px-5' >
            <br/>
            { this.props.shop_and_kyc_photo_datas.active_section &&
            <div>
                {this.props.shop_and_kyc_photo_datas.active_section.verify &&
                    <div class="closedStamp ">
                    <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                    </div>   
                }
            </div>
            } 
            <div className='d-flex justify-content-around mt-1'>
                <div className='col-md-12 my-3 col-lg-5 no-padding'>
                <p className='no-margin text-white text-center py-3 audit_kyc_text'>CUSTOMER PHOTO</p>
                <div className='col p-0'>

                <DisplayImage rotate={true} file_of={"pps"} lead_id={this.props.lead_id} image_path={get_dir_path(this.props.shop_and_kyc_photo_datas.cust_reg_json.kyc_photos.photo_pps?.path,this.props.shop_and_kyc_photo_datas.cust_reg_json.kyc_photos.photo_pps.value)} style={{height: '530px',width:'100%'}} file_name={this.props.shop_and_kyc_photo_datas.cust_reg_json.kyc_photos.photo_pps.value} view_lead={()=>this.props.view_lead()} actual_file={"photo_pps"} addl_file_name={null} />
                </div>
            
                </div>
                <div className='col-md-12 my-3 col-lg-5 no-padding'>
                <p className='no-margin text-white text-center py-3 audit_kyc_text'>SELFIE PHOTO</p>
                <div className='col p-0'>
                <DisplayImage  rotate={true} file_of={"selfie"} view_lead={()=>this.props.view_lead()} lead_id={this.props.lead_id} image_path={get_dir_path(this.props.shop_and_kyc_photo_datas.cust_reg_json.kyc_photos.photo_selfie.path,this.props.shop_and_kyc_photo_datas.cust_reg_json.kyc_photos.photo_selfie.value)} style={{height: '530px',width:'100%'}} file_name={this.props.shop_and_kyc_photo_datas.cust_reg_json.kyc_photos.photo_selfie.value} actual_file={"photo_selfie"} addl_file_name={null}  />
                </div>
            
                </div>
            </div>

            </div>

            <div className='col-lg-10 py-1 mt-3 px-5' >
            
            <div className='d-flex  row justify-content-center'>
            <p className='text-white no-margin py-2 pt-4 col-12 audit_kyc_text text-center'> PHOTO ID PROOF </p>
            <div className='col-md-12 my-3 col-lg-6 no-padding'>
                {this.props.shop_and_kyc_photo_datas.cust_reg_json.id_proof.id_proof_type.value &&
            <DisplayImage rotate={true} file_of={this.props.shop_and_kyc_photo_datas.cust_reg_json.id_proof.id_proof_type.value} lead_id={this.props.lead_id} image_path={get_dir_path(this.props.shop_and_kyc_photo_datas.cust_reg_json.id_proof.photo_id_proof.path,this.props.shop_and_kyc_photo_datas.cust_reg_json.id_proof.photo_id_proof.value)} style={{height: '580px',width:'100%'}} file_name={this.props.shop_and_kyc_photo_datas.cust_reg_json.id_proof.photo_id_proof.value} actual_file={"photo_id_proof"} />
                }
            </div>
          
            </div>
            <br/>
            </div>
            
            

            <div className='col-lg-10 py-2 px-5 mt-4'>
          
                <form className={`${(this.props.shop_and_kyc_photo_datas.active_section.verify || (this.props.shop_and_kyc_photo_datas.active_section.to_reassign==true) )? "disable_div":''}  ${ ((this.props.shop_and_kyc_photo_datas.responseData.audited_by== get("user_id")) || (this.props.shop_and_kyc_photo_datas.responseData.audited_by==null )) ?"":'disable_div'}`}>
                    <div className='d-flex align-items-center'>
                    <input type="checkbox" id="vehicle1" className='pointer' checked={ audit_kyc &&this.props.audit_kyc['kyc_photos'].face_clear_nd_visible && true}  style={{height:'19px',width:'20px'}} name="vehicle1" value="Bike" onChange={(e)=>this.props.checkbox_verification(e.target.checked,"face_clear_nd_visible")}/>
                    <label className='no-margin pointer pl-3 audit_kyc_text'  for="vehicle1">The face of the customer is clear and visible.</label><br/><br/>
                    </div>
                    <div className='d-flex align-items-center'>
                    <input type="checkbox" id="potrait" className='pointer' checked={ audit_kyc && this.props.audit_kyc['kyc_photos'].portrait_mode && true}  style={{height:'19px',width:'20px'}} name="potrait" onChange={(e)=>this.props.checkbox_verification(e.target.checked,"portrait_mode")}/>
                    <label className='no-margin pl-3 audit_kyc_text pointer'  for="potrait">The photo is in portrait mode. </label><br/><br/>
                    </div>
                    <div className='d-flex align-items-center'>
                    <input type="checkbox" id="vehicle3" className='pointer' checked={audit_kyc &&this.props.audit_kyc['kyc_photos'].passport_photo_match && true}  style={{height:'19px',width:'20px'}} name="vehicle3" value="Bike" onChange={(e)=>this.props.checkbox_verification(e.target.checked,"passport_photo_match")}/>
                    <label className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle3">{`The customer face in passport size photo matches with the ${getLabel('national_id')}.`}</label><br/><br/>
                    </div>
                    <div className='d-flex align-items-center'>
                    <input type="checkbox" id="vehicle4" className='pointer' checked={audit_kyc && this.props.audit_kyc['kyc_photos'].selfie_photo_match && true}  style={{height:'19px',width:'20px'}} name="vehicle4" value="Bike" onChange={(e)=>this.props.checkbox_verification(e.target.checked,"selfie_photo_match")}/>
                    <label className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle4">{`The customer face in selfie photo matches with the ${getLabel('national_id')}.`}</label><br/><br/>
                    </div>

                </form>
                
                    
            </div>
            </>}
            </div>}</>
            }
            {this.props.shop_and_kyc_photo_datas.audit_kyc_wizard_menus.shop_photo &&
            <>
            { this.props.shop_and_kyc_photo_datas.cust_reg_json &&
            <>{this.props.shop_and_kyc_photo_datas.View_lead_section_loader ? this.audit_section_spinner():

            <div className='row no-margin justify-content-center'>
            <div className='col-lg-12 py-3 mt-5 ' style={{background:' #01062c'}}>
            <h2 className='text-white text-center'>
            <b>  {this.props.shop_and_kyc_photo_datas.cust_reg_json.same_as_owner_person ? "5" :"6"} - Establishment Photo Verification</b> 
            </h2>
            <h2 className=' d-flex mt-1 text-center justify-content-center align-items-center' ><img src='/img/pending_with_rm.png' height={'50'}/> <b className='ml-2 align-self-cente' style={{color:'#F3EA0B'}}> Pending with {this.props.shop_and_kyc_photo_datas.active_section && (this.props.shop_and_kyc_photo_datas.active_section.pending_with=="rm"?getValueFromLangJson("rm_label_short"):'YOU')}</b></h2>

            </div>
            {this.props.shop_and_kyc_photo_datas.View_lead_section_loader ? this.audit_section_spinner():
            <>
            
            
            <div className='col-lg-10 mt-4 py-1 px-5' >
            <div className='d-flex justify-content-center  row no-margin align-items-center' style={{background:'#233a56'}}>
                <div className='col-lg-4 col-sm-12 col-md-4 my-3'>
                <p className='text-white business_operations_text'>Biz distance</p>
                {/* <h2 className='text-white   audit_kyc_text'> <b>{ this.props.shop_and_kyc_photo_datas.cust_reg_json.biz_info.business_distance.value? dd_value (capitalize_first_letter(this.props.shop_and_kyc_photo_datas.cust_reg_json.biz_info.business_distance.value)):'-'}</b></h2> */}
                <h2 className='text-white   audit_kyc_text'> <b>{ this.props.shop_and_kyc_photo_datas.cust_reg_json.gps_address.business_distance.value? dd_value (capitalize_first_letter(this.props.shop_and_kyc_photo_datas.cust_reg_json.gps_address.business_distance.value)):'-'}</b></h2>

                </div>
                <div className='col-lg-4 col-sm-12 col-md-4 py-4'>
                <p className='text-white business_operations_text'>Ownership</p>
                <h2 className='text-white   audit_kyc_text'> <b>{ this.props.shop_and_kyc_photo_datas.cust_reg_json.shop_photo.ownership.value ? dd_value(capitalize_first_letter(this.props.shop_and_kyc_photo_datas.cust_reg_json.shop_photo.ownership.value)):'-'}</b></h2>

                </div>
                <div className='col-lg-4 col-sm-12 col-md-4 py-3'>
                <p className='text-white business_operations_text'>Establishment type</p>
                {this.props.shop_and_kyc_photo_datas.cust_reg_json.shop_photo.biz_addr_prop_type ?
                <>
                <h2 className='text-white audit_kyc_text'> <b>{this.props.shop_and_kyc_photo_datas.cust_reg_json.shop_photo.biz_addr_prop_type.value ? dd_value(capitalize_first_letter(this.props.shop_and_kyc_photo_datas.cust_reg_json.shop_photo.biz_addr_prop_type.value)):'-'}</b> 
                {this.props.shop_and_kyc_photo_datas.cust_reg_json.shop_photo.biz_addr_prop_type.value &&  this.props.shop_and_kyc_photo_datas.cust_reg_json.shop_photo.biz_addr_prop_type.value == 'others' && this.props.shop_and_kyc_photo_datas.cust_reg_json.shop_photo.custom_biz_addr_prop_type.value && <b> - {dd_value(this.props.shop_and_kyc_photo_datas.cust_reg_json.shop_photo.custom_biz_addr_prop_type.value)}</b>}                </h2>
               
                </> :"-"}
             </div>
            </div>
            <div className='d-flex  row justify-content-center'>
            <p className='text-white no-margin pt-3 py-2 col-12 audit_kyc_text text-center'>ESTABLISHMENT PHOTO</p>
                <div className='col-md-12 my-3 col-lg-6 no-padding'>
                
                <DisplayImage rotate={true} file_of={"shop"} lead_id={this.props.lead_id} view_lead={()=>this.props.view_lead()} image_path={get_dir_path(this.props.shop_and_kyc_photo_datas.cust_reg_json.shop_photo.photo_shop.path,this.props.shop_and_kyc_photo_datas.cust_reg_json.shop_photo.photo_shop.value)} style={{height: '500px',width:'100%'}} file_name={this.props.shop_and_kyc_photo_datas.cust_reg_json.shop_photo.photo_shop.value} actual_file={"photo_shop"}   />

                </div>
                { this.props.shop_and_kyc_photo_datas.active_section &&
                <div>
                    {this.props.shop_and_kyc_photo_datas.active_section.verify &&
                    <div class="closedStamp ">
                    <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                    </div>   
                    }
                </div>
            } 
            </div>
            <br/>
            </div>

            <div className='col-lg-10 py-2 px-5 mt-4'> 
           
                <form className={`${(this.props.shop_and_kyc_photo_datas.active_section.verify || (this.props.shop_and_kyc_photo_datas.active_section.to_reassign==true) )? "disable_div":''} ${ ((this.props.shop_and_kyc_photo_datas.responseData.audited_by== get("user_id")) || (this.props.shop_and_kyc_photo_datas.responseData.audited_by==null )) ?"":'disable_div'}`}>
                    <div className='d-flex align-items-center'>
                    <input type="checkbox" id="vehicle1"  className='pointer' style={{height:'19px',width:'20px'}} 
                    checked={ audit_kyc && this.props.audit_kyc['shop_photo'].clear_nd_visible && true} name="vehicle1" value="Bike" onChange={(e)=>this.props.checkbox_verification(e.target.checked,"clear_nd_visible")}/>
                    <label className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle1">The establishment photo is clear and visible.</label><br/><br/>
                    </div>
                    <div className='d-flex align-items-center'>
                    <input type="checkbox" id="vehicle2" className='pointer' style={{height:'19px',width:'20px'}} checked={ audit_kyc &&this.props.audit_kyc['shop_photo'].covers_shop_name && true} name="vehicle2" value="Bike" onChange={(e)=>this.props.checkbox_verification(e.target.checked,"covers_shop_name")}/>
                    <label className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle2">The establishment photo covers with the shop name. </label><br/><br/>
                    </div>
                    <div className='d-flex align-items-center'>
                    <input type="checkbox" id="vehicle3" className='pointer' style={{height:'19px',width:'20px'}} checked={ audit_kyc && this.props.audit_kyc['shop_photo'].covers_nearby_areas && true} name="vehicle3" value="Bike" onChange={(e)=>this.props.checkbox_verification(e.target.checked,"covers_nearby_areas")}/>
                    <label className='no-margin pl-3 audit_kyc_text pointer'  for="vehicle3">The establishment photo covers the nearby areas.</label><br/><br/>
                    </div>
                </form>
                
                    
            </div>
            </>}
            </div>
            }</>
            }
            </>
            }
            </div>

        )        
     }
}



const mapStateToProps = (state) => {
    return {
      audit_kyc: state.common_slice,
    };
    };
  
 
 export default connect(mapStateToProps)(AuditKYCPhotosVerification);
  
