import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import moment from 'moment';
import { check_priv, get } from '../../../helpers/storage_helper';
import { getValueFromLangJson, Homeacc_prvdrLogoselector, lbl_amt } from '../../../helpers/common_helper';
import { dd_value } from '../../../actions/common_actions';
import { Link } from "react-router-dom";
import { TooltipLabel } from '../../common/partial';
import DatePicker from 'react-datetime';
import { BiCalendar } from "react-icons/bi";
import Select from "react-select";
import { FiFilter } from "react-icons/fi";
import CommonReactTable from '../../../helpers/commonReact_table';
import { pendingRMvisit, completeRMreminder,RMlISTS } from '../../../actions/delinquency_actions';
import { BasicReactTable } from '../../../helpers/react_table';
import { FaPhoneAlt } from 'react-icons/fa';
import Softphone from '../../cs_management/container/cs_soft_phone';
import { connect } from 'react-redux';
import { setValuesIntoRedux } from '../../../Redux/Slices/CsSoftphoneSlice';
class PendingRMvisits extends FlowComponent {

    state = {
        callTasksStatus: [
            { value: "all", label: "All" },
            { value: "to_be_remainded", label: "To be Reminded" },
            { value: "remainded", label: "Reminded" },

        ],
        rms_visit_tasks: [
            { label: "All", key: "all", addl_key: "all", status: "all" },
            { label: "1st Visit", key: "Reason for Delay", addl_key: "2-4 Days", status: "1st_visit" },
            { label: "2nd Visit", key: "Warning Letter", addl_key: "5-10 Days", status: "2nd_visit" },
            { label: "3rd Visit ", key: "Demand Notice", addl_key: "11-20 Days", status: "3rd_visit" },
            { label: "4th Visit", key: "NIS ", addl_key: "30-40 Days", status: "4th_visit" },

        ],
        rm_visits: "all",
        criteria:{
            start_date : moment().format("YYYY-MM-DD"),
            end_date : moment().format("YYYY-MM-DD"),

        },
        loader:false,
        status:"all",

    }


    componentWillMount() {
        this.getRmVisitData()
        this.getRmLists()
        const  softphone_is_supported =  check_priv('home', 'soft_phone')  && this.props.softPhoneState.is_softphone_supported
        this.setState(
            { softphone_is_supported },
            () => this.setState({ column_data: this.column_datas() })
          );
          
    }

    componentDidMount(){
        this.props.setValuesIntoRedux({screen_from:"pending_rm_visits"})

    }

   getRmLists(){
    RMlISTS(this.req())
    
    .then((response)=>{
        if(!response){return}
        if(response.status=="success"){
            this.setState({RmList:response.data},()=>{this.RmListsOptions(response.data)})
        }
    })
   }

   filterByStatus(value,type){
    if(value=="all"){
    this.setState({row_data: (this.state.rm_visits=="all")?this.state.pendingRMvisit : this.state.pendingRMvisit.filter((items,idx)=>{return items['visit_type'] == this.state['rm_visits']}), loader: false })
  
    }else{
    if(this.state['rm_visits']!=="all"){
      
        this.setState({
            row_data: (this.state.pendingRMvisit).filter((items, idx) => {
                return (items['remind_rm'] == ((value == "to_be_remainded") ? 0 : 1) &&
                    items['visit_type'] == this.state.rm_visits); 
            }),
            loader: false
        });
        
    }else{
        this.setState({ row_data: (this.state.pendingRMvisit).filter((items, idx) => { return items['remind_rm'] == ((value=="to_be_remainded" )? 0 :1 )}), loader: false })
    }
   
    }
   }
   RmListsOptions(data) {
    var arr = [
        {value:"all",label:"All"}
    ];
    for (var i = 0; i < data.length; i++) {
        var obj = {};
        obj['value'] = data[i].id;
        obj['label'] =
         (
            <div>
                <span className="m-0">{data[i].name}</span>
                {data[i].count !=null &&
                <b className=" px-2 py-1 pr-3  mx-2 bg-info" style={{borderRadius:"20px"}}> {data[i].count} </b>
    }
            </div>
        );        arr.push(obj);
    }

    this.setState({ RmListOptions: arr });
}

    getRmVisitData() {
        var request={
            criteria:this.state.criteria,
            time_zone : get('market').time_zone
        }
        this.setState({loader:true})
        pendingRMvisit(request)
            .then((response) => {
                if (!response) { return }
                if (response.status == "success") {
                    this.setState({ pendingRMvisit: response.data,
                        //  row_data: response.data,
                         row_data: (this.state.rm_visits !="all" ?(response.data).filter((items, idx) => { return items['visit_type'] == this.state['rm_visits'] }) :response.data),

                         loader:false })
                }
            })
    }

    get_input_handler(value, type) {
        this.setState({ [type]: value })

        if((type =="start_date")||(type =="end_date") || (type =="rm_id" )){
            var critera_obj= {...this.state.criteria };
            if(value =="all"){
                critera_obj[type] = null

            }else{
                critera_obj[type] = value
            }
            this.setState({criteria:critera_obj},()=>{this.getRmVisitData("criteria")})
        }
        if(type=="status"){
            this.setState({loader:true},()=>{this.filterByStatus(value,type)})
        }
        if (type == "rm_visits") {
            this.setState({ loader: true }, () => { this.filetration("rm_visits") })

        }
    }

    filetration(type) {
     
        if (this.state[type] == "all") {

            this.setState({row_data:
            //      (this.state.status!="all") ?this.state.pendingRMvisit.filter((items,idx)=>{
            //     return (items['remind_rm'] == ((this.state.status == "to_be_remainded") ? 0 : 1))
            // }) :  
            this.state.pendingRMvisit,status:"all", loader: false })

        } else {
            if(this.state.status!="all"){
                this.setState({ row_data: (this.state.pendingRMvisit).filter((items, idx) => { return ((items['visit_type'] == this.state[type] ) && (items['remind_rm'] == ((this.state.status == "to_be_remainded") ? 0 : 1))) }), loader: false })  

            }else{
            this.setState({ row_data: (this.state.pendingRMvisit).filter((items, idx) => { return items['visit_type'] == this.state[type] }), loader: false })
            }

        }

    }

    call_handler(data){

        this.props.softPhoneCtxMethods.initiateCall({
            phoneNumber: data.rm_mob_num ?? "",
            screen: "rm_visits",
            churnOrDeliquencyId: ""
           
        });

        // this.props.setValuesIntoRedux({rm_id :row.owner_id,pending_rms_details : row})
        // this.setState({isactive_call :row})
    }

    column_datas() {
        return [


            {
                Header: <div className='d-flex justify-content-center'>Customer Name</div>,
                id: "cust_name",
                disableSortBy: true,
                accessor: row => <p className='m-0 text-center'> <TooltipLabel full_text={row.cust_name} no_reduce={true} /> </p>,


            },
            {
                Header:  <div className='d-flex justify-content-center'>Customer ID</div> ,
                id: "cust_id",

                disableSortBy: true,

                accessor: row => <Link style={{ color: "#008fc1" }} to={"/fa/view/" + row.loan_doc_id} target="_blank"><TooltipLabel full_text={row.loan_doc_id} no_reduce={true} /></Link>,


            },
            {
                Header:  <div className='d-flex justify-content-center'>FA Amount Disbursed</div>,
                id: "fa_amt",

                disableSortBy: true,
                accessor: row => <p className='no-margin text-white text-center '  > {lbl_amt(row.fa_amt, get("market").currency_code)}</p>,
            },
            {
                Header: <div className='d-flex justify-content-center'>Overdue Date</div>,
                id: "fa_due_date",
                disableSortBy: true,
                accessor: row => <p className='no-margin text-white text-center '  > {moment(row.fa_due_date).format("DD MMM YYYY")} ( {row.overdue_day} {(row.overdue_day > 1 ? " days" : " day")})</p>,


            },
            // {
            //     Header: "Overdue Days",
            //     id: "overdue_days",

            //     disableSortBy: true,
            //     accessor: row => <p className='no-margin text-white text-center '  > {row.overdue_day ? row.overdue_day : "-"} Day</p>,
            // },
            {
                Header:  <div className='d-flex justify-content-center'>Flat Fees</div>,
                id: "fee",

                disableSortBy: true,
                accessor: row => <p className='no-margin text-white text-center '  > {lbl_amt(row.fee, get("market").currency_code)}</p>,
            },
            {
                Header:  <div className='d-flex justify-content-center'>Visit Pending</div>,
                id: "visit_type",

                disableSortBy: true,
                accessor: row => <p className='no-margin text-white text-center text-right '  > {dd_value(row.visit_type)}</p>,
            },
            {
                Header:  <div className='d-flex justify-content-center'>{getValueFromLangJson("rm_label_short")} Name</div>,
                id: "rm_name",
                disableSortBy: true,
                accessor: row => <p className='no-margin text-white text-center'  > {row.rm_name}</p>,


            },


            {
                Header:   <div className='d-flex justify-content-center'>{getValueFromLangJson("rm_label_short")} Mobile Number</div>,
                id: "rm_mob_num",
                disableSortBy: true,

                accessor: row => <div className='d-flex align-items-center  justify-content-center '>
                    <p className='no-margin text-center text-white  '  >{row.rm_mob_num} </p>
                    {(this.state.softphone_is_supported) &&
                    <div className='ml-3'>
                    <p className="m-0 text-secondary pointer  secondary_text_width p-2 border_radius_circle bg_blue" onClick={()=>{this.call_handler(row)}}>        
                        <FaPhoneAlt color="white" className='' size={17}/>
                    </p>
                    </div>}
                </div> ,


            },
            

            {
                Header:  <div className='d-flex justify-content-center'>Status</div>,
                id: "reasons",
                disableSortBy: true,
                accessor: row => <p className='no-margin text-white text-center '  > {row.remind_rm == 1 ? 'Reminded' : 'To be Reminded'}</p>,


            },

           

            {
                Header:  <div className='d-flex justify-content-center'>Action</div>,
                id: "call_log",
                disableSortBy: true,
                accessor: row => (<div className='d-flex justify-content-center'>
                    <button className={`btn product_takeup_submit px-2 py-2 text-white  ${row.remind_rm ? "btn-secondary auto":""}`}onClick={() => this.visit_tasks_done(row)} disabled={row.remind_rm == 1}>
                        {"Done"}
                      </button>
                    </div>
                  ),
            },


        ]
    }

    lodaer() {
        return (
            <div class="spinner-border text-light" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        )
    }

    visit_tasks_done(data) {
        completeRMreminder(this.req({ task_id: data.id }))
            .then((response) => {
                if (!response) { return }
                if (response.status === "success") {
                    alert(response.message)
                    window.location.reload()
                }
            })
    }

   
    show_tab_menus(data, addl_key = null) {
        return (
            data && data.map((items, idx) => {
                return (

                    <div className={` col-lg-2 px-0   text-center align-self-center ${(this.state["rm_visits"] === items.status) ? "active_call_tasks_tab text-white call_task_padding " : "audit_kyc_text-black"}`} onClick={() => { this.get_input_handler(items.status, "rm_visits") }}>

                        <p className={` m-0   px-3 sizem ${items.label == "All" ? "py-4" : ""}`} ><b>{items.label}</b></p>
                        {(items.label !== "All") &&
                            <div>
                                <p className='m-0 '>{items.key}</p>
                                <p className='m-0 '>{items.addl_key}</p>

                            </div>
                        }
                    </div>
                )
            })
        )
    }

    remove_state_value(){
        this.setState({isactive_call:null})
    }

    render() {
        const soft_phone_modal = (!this.props.softPhoneState.isIncomingCall && !this.props.softPhoneState.outgoingCall ) 

        return (
            <div>
                <h2 className='text-white text-center mt-5'><b>Pending {getValueFromLangJson("rm_label_short")} Visits</b></h2>
                <div className='row mx-0 my-3 d-flex align-items-center justify-content-center'>
                    <div className='col-lg-3 col-md-12 col-sm-12 '>
                        <fieldset className={`pending_rm_visits`} >
                            <legend className="text-white  rm_visits_legend d-flex align-items-center " > Start Date
                            </legend>
                            <div className={`col-12 px-4   date_select call_tasks_filter ${(this.state.filter_by_status != "assigned") ? "" : "disable_div"}`} id="call_tasks_filter" style={{ position: 'relative' }}>
                                <DatePicker closeOnSelect={true}
                                    isValidDate={(current) => {
                                        const today = moment().startOf('day');
                                        return current.isSameOrBefore(today, 'day');
                                    }}
                                    value={this.state.start_date ? moment(this.state.start_date).format('DD MMM YYYY'):  moment().format('DD MMM YYYY')} onChange={(value) => this.get_input_handler(moment(value).format("YYYY-MM-DD"), "start_date")} inputProps={{ id: 'visit_tasks_date', name: 'Referral_from', autoComplete: 'off' }} dateFormat={"DD MM YYYY"}
                                    showMonthYearPicker timeFormat={false} required="required" onKeyDown={(value) => value.preventDefault()} />
                                <label className='col-1.5 no-margin visit_task_calender d-flex p-0 ' style={{ position: 'static' }} htmlFor='visit_tasks_date'>
                                    <p className='m-0 text-white' style={{ position: 'absolute' }}><BiCalendar size={"25"} /></p>
                                </label>

                            </div>

                        </fieldset>
                    </div>
                    <div className='col-lg-3 col-md-12 col-sm-12 '>
                        <fieldset className={`pending_rm_visits`} >
                            <legend className="text-white  rm_visits_legend d-flex align-items-center " > End Date
                            </legend>
                            <div className={`col-12 px-4   date_select  call_tasks_filter ${(this.state.filter_by_status != "assigned") ? "" : "disable_div"}`} id="call_tasks_filter" style={{ position: 'relative' }}>
                                <DatePicker closeOnSelect={true}
                                    isValidDate={(current) => {
                                        const today = moment().startOf('day');
                                        return current.isSameOrBefore(today, 'day');
                                    }}
                                    value={this.state.end_date ? moment(this.state.end_date).format('DD MMM YYYY') : moment().format('DD MMM YYYY') } onChange={(value) => this.get_input_handler(moment(value).format("YYYY-MM-DD"), "end_date")} inputProps={{ id: 'call_tasks_date', name: 'Referral_from', autoComplete: 'off', placeholder: "DD MM YYYY" }} dateFormat={"DD MM YYYY"}
                                    showMonthYearPicker timeFormat={false} required="required" onKeyDown={(value) => value.preventDefault()} />
                                <label className='col-1.5 no-margin visit_task_calender d-flex p-0 ' style={{ position: 'static' }} htmlFor='call_tasks_date'>
                                    <p className='m-0 text-white ' style={{ position: 'absolute' }}><BiCalendar size={"25"} /></p>
                                </label>

                            </div>

                        </fieldset>
                    </div>
                </div>
                <div className='row mx-0 my-3 d-flex align-items-center justify-content-center'>
                    <div className='col-md-12 col-sm-12 col-lg-3 mt-4'>
                        <label> <b>Search by {getValueFromLangJson("rm_label_short")} Name </b></label>
                        {this.state.RmListOptions &&
                        <div className='col-12   pl-0 date_select ' style={{ position: 'relative' }}>
                            <Select
                                onChange={(e) => { this.get_input_handler(e.value, "rm_id") }}
                                value={this.state.select_value}
                                options={this.state.RmListOptions}
                                placeholder="Choose"
                                styles={this.select_custom_styles()}
                                classNamePrefix="my-select"
                                defaultValue={{ value:null, label: "Choose" }}
                            />
                        </div>
                        }
                    </div>
                    <div className='col-md-12 col-sm-12 col-lg-3 mt-4'>
                        <label> <b>Search by Status </b></label>
                        {this.state.RmListOptions &&
                        <div className='col-12   pl-0 date_select ' style={{ position: 'relative' }}>
                            <Select
                                onChange={(e) => { this.get_input_handler(e.value, "status") }}
                                value={ (this.state.status=="all" ) ? "all" :this.state.select_value}
                                options={this.state.callTasksStatus}
                                placeholder= {this.state.status=="all" ? "All": "Choose"}
                                styles={this.select_custom_styles()}
                                classNamePrefix="my-select"
                                defaultValue={{ value: this.state.status, label: dd_value(this.state.status) }}
                            />
                        </div>
                        }
                    </div>
                </div>
                {!this.state.loader &&
                <div className='row m-0 mt-4 d-flex justify-content-around col-12 px-0 py-2 ' >
                    <div className='col-lg-6 col-md-12 col-sm-12 row m-0 px-0 past_churn_filter d-flex justify-content-around py-2 call_tasks_filter_bg pointer'>
                        {this.show_tab_menus(this.state.rms_visit_tasks)}

                    </div>

                </div>}

                {!(this.state.loader) ?
                <div className='mt-3 row m-0'>

                    <>
                        {this.state.row_data && this.state.row_data.length > 0 ?
                            <div className={`cust_next_follow_up mt-2  w-100 col-lg-12 col-md-12 col-sm-12`} >
                                {/* <CommonReactTable className='border' row_data={this.state.row_data ? this.state.row_data : []} background_header={true} column_data={this.state.column_data} FName="Customer Follow Up" exportCSV={true} csvFile={"Call Tasks"} csvData={this.state.row_data ? this.state.row_data : []} global_search={false} showPag={this.state.row_data.length > 9 ? true : false} resizable={false} minRow={0} default_page_size={10} addBackground={true} /> */}

                                <BasicReactTable row_data={this.state.row_data ? this.state.row_data : []} column_data={this.state.column_data} FName="Call Tasks"  csv_file={true} export_csv_datas={this.state.row_data ? this.state.row_data : []} global_search={false} pagination={this.state.row_data.length > 9 ? true : false} default_page_size={10} header_class='table_background_header'/>
                            </div>
                            :
                            <p className='text-white text-center mb-0 mt-5 col-12 '>NO DATA</p>
                        }
                    </>

                </div>:
                 <div className='d-flex justify-content-center mt-5'>
                 <div>
                   <p className='text-light mt-3 text-center'>Loading...</p>
                   <div className="product_takeup_loader"></div>
                   </div>
                 </div>
                }

                {/* {this.props.softPhoneState.pending_rms_details &&  (this.state.softphone_is_supported)  && soft_phone_modal &&
                <div className='Call_log_section zoomIn_animation'>
              
                <Softphone screen_from ={"pending_rm_visits"} is_direct_outgoing = {true} rm_id={this.props.softPhoneState.pending_rms_details.owner_id ?this.props.softPhoneState.pending_rms_details.owner_id:null} remove_state_value ={()=>{this.remove_state_value()}} outgoing_call_data = {this.props.softPhoneState.pending_rms_details}/>
         
               </div>
                } */}
            </div>
        );

    }
}

const mapStateToProps = (state) => {
	return {
	  softPhoneState: state.cs_soft_phone,

	};
  };

const mapDispatchToProps ={
    setValuesIntoRedux
  
  };


export default connect(mapStateToProps, mapDispatchToProps)(PendingRMvisits);




