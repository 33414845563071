import { useQuery } from "@tanstack/react-query";
import { missedCallsList } from "../../../actions/cs_management_actions";

type MissedCallProp = {
  data: {
    missed_calls: {
      call_dialed_time: string;
      created_at: string;
      cust_id: string;
      cust_name: string;
      cust_number: string;
      id: number;
    }[];
  };
};

const useGetMissedCallLists = ({ enabled }: { enabled: boolean }) => {
  const { isFetching, isFetched, data, isLoading, isError } =
    useQuery<MissedCallProp>({
      queryKey: ["missed_calls"],
      queryFn: () => missedCallsList(),
      enabled,
    });

  return { isLoading, isFetching, isFetched, data, isError };
};

export default useGetMissedCallLists;
