import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import { AiFillEdit} from "react-icons/ai";
import{ImCircleDown,ImCircleUp} from "react-icons/im";
import FileUpload from '../../common/component/FileUpload';
import {get} from '../../../helpers/storage_helper';
import {getLabel,getValueFromLangJson,get_dir_path,lbl_amt} from '../../../helpers/common_helper';
import DisplayImage from '../../common/component/DisplayImage';
import {dd_value} from '../../../actions/common_actions';
import RegistrationDocument from './RegistrationDocument';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { updateValuesIntoRedux } from '../../../Redux/Slices/CommonSlice';
class AuditKYCAccountOwnershipComponent extends FlowComponent{
    constructor() {
        super();
        this.uga_owner_address_alignment=["parish","sub_county","county","village","district","region","location","landmark"]
        this.rwa_owner_address_alignment=["province","district","sector","cell","village","location","territory","landmark"]
        this.national_id_details=['first_name','last_name','national_id','gender','dob']
        this.state={
          lead:{},
          Biz_accounts_arr:[],
          accordian_0:true,
          biz_acc :{}
        }
        
    }

   componentWillMount(){
  //   if(this.props.account_ownership_datas){
  //     var a=this.props.account_ownership_datas.cust_reg_json.biz_accounts.accounts
  //     var indexToChange = a.findIndex(function(element) {
  //       return element.is_default_acc === true;
  //   });
    
  //   if (indexToChange !== -1) {
  //       var elementToMove = a.splice(indexToChange, 1)[0];
  //               a.unshift(elementToMove);
  //   }
    
  // this.setState({Biz_accounts_arr:a})
  // }
   this.setState({ rm_label_short : getValueFromLangJson("rm_label_short")})
   }



    checkbox_spinner(no_margin=false){
        return(
          <div class={`d-flex col-lg-12 py-2 justify-content-center ${!no_margin ?"mt-4 ":"" }`}>
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
         </div>
        )
      }
      audit_section_spinner(){
        return(
          <div class="audit_spinner" style={{margin:'20vh 0px  30vh 45%'}}>
          <span class="level level-1"></span>
          <span class="level level-2"></span>
          <span class="level level-3"></span>
          <span class="level level-4"></span>
          <span class="level level-5"></span>
          <span class="level level-6"></span>
        </div>
        )
      }
      
      togglePrev(key_val,key){
        var value=false
        if(this.state[key]){
          this.setState({[key]: !this.state[key] })
        }else{
          this.setState({[key]: true })

        }
      }
      
      romanize(num) {
        var lookup = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 }, roman = '', i;
        for (i in lookup) {
          while (num >= lookup[i]) {
            roman += i;
            num -= lookup[i];
          }
        }
        return roman;
      }
    
    

      rekyc_modify_state_handler(json_type,json_key,key){
        if((this.props.data[json_type][json_key][key].hasOwnProperty("modified")) && (this.props.data[json_type][json_key][key].modified)){
      
          return 'new_kyc_json';
        }else{
         
          return "";
          }

      }

      setAuditLeadState = (event) => {
      
        this.setChangeToState(event)
       }

       is_disable = (item) =>{


       let value =(this.state.biz_acc?.[item.acc_number.value]?.holder_name_proof_path &&
         this.state.biz_acc?.[item.acc_number.value]?.holder_name) 
          return !value
       }

      onCreateUpload = (fileData, item) => {
        this.setState(prevState => ({
            biz_acc: {
                ...prevState.biz_acc,
                [item?.acc_number?.value]: {
                    ...prevState.biz_acc[item?.acc_number?.value],
                    holder_name_proof_path: fileData?.target?.f_name
                }
            }
        }));
      };
  

    acc_holder_name = (value, type, idx = null, item = null) => {
        
      if(value !== "retrieved_franchise_name" && type === "mismatch_reason"  && value){
        this.setState({is_edit:false})
        let key = item?.acc_number?.value +"_is_editable"
        this.props.updateValuesIntoRedux({[key]:false})
      }

      this.setState(prevState => {
          let key_name = item?.acc_number?.value;
          let biz_accounts = { 
              ...prevState.biz_acc, 
              [key_name]: { 
                  ...prevState.biz_acc[key_name], 
                  [type]: value 
              } 
          };
          if (type === "mismatch_reason" && value !== "retrieved_franchise_name" && value) {
              this.props.verify_handler("biz_acc", {
                  mismatch_reason: value,
                  acc_number: key_name,
                  acc_type: `accounts_${idx}`
              });
          }
  
          return { biz_acc: biz_accounts };
      });

    
  };
  
    
    upload_file = async (idx, item) => { 
    

        const biz_accounts = {
            category: `acc_num_${idx}`,
            index: idx,
            mobile_num: item?.acc_number,
            holder_name :this.state.biz_acc?.[item.acc_number.value].holder_name,
            holder_name_proof_path : this.state.biz_acc?.[item.acc_number.value]?.holder_name_proof_path
        };
            
        await this.props.file_upload_submit_handler(biz_accounts);
        this.setState({ biz_acc: {} });
    };


    handleEdit = (id) =>{
   
      let acc = {...this.state.biz_acc}
      if(acc ?.[id]){
        if( acc?.[id]?.mismatch_reason !=="retrieved_franchise_name"){
          acc[id]['mismatch_reason'] = ''
           this.setState({ biz_acc:acc})
         }
      }

       
       this.setState({is_edit:true })
       let key = id+"_is_editable"
       this.props.updateValuesIntoRedux({[key]:true})
     }

    

    render(){
      var audit_kyc = this.props.audit_kyc && this.props.audit_kyc[this.props.account_ownership_datas.active_key]
        return(
            <div>
            <>
          {this.props.account_ownership_datas.View_lead_section_loader ? this.audit_section_spinner():
           <div className='row no-margin justify-content-center'>
           <div className='col-lg-12 py-3 mt-5 ' style={{background:' #01062c'}}>
             <h2 className='text-white text-center'> <b>  { this.props.account_ownership_datas.cust_reg_json.same_as_owner_person ? "3 - Business Accounts" :"4 - Business Accounts "} </b></h2>
             <h2 className=' d-flex mt-1 text-center justify-content-center align-items-center' ><img src='/img/pending_with_rm.png' height={'50'}/> <b className='ml-2 align-self-cente' style={{color:'#F3EA0B'}}> Pending with {this.props.account_ownership_datas.active_section && (this.props.account_ownership_datas.active_section.pending_with=="rm"? this.state.rm_label_short ??'':'YOU')}</b></h2>
           
           </div>
           
           
            <>
           
            <>
           
            <div className='col-lg-12 pb-5 pt-2 mt-5 px-5' >
             { this.props.account_ownership_datas.active_section &&
                       <div>
                           {this.props.account_ownership_datas.active_section.verify &&
                             <div class="closedStamp ">
                             <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                             </div>   
                           }
                       </div>
             }  
             {this.props.account_ownership_datas.cust_reg_json.biz_accounts.accounts.map((item,idx)=>{ if(item.is_default_acc?.value===true){return(
              <div key={`acc_ownership_${idx}`} className="col-12 row m-0 justify-content-center"> 
                <div  className="col-lg-12 col-md-12" style={{marginBottom:`${this.state[`accordian_0`] ? "2%":"10px"}`}} >


                  <div className='col-lg-12 py-3 mt-2 d-flex align-items-center justify-content-between' style={{background:'rgb(1, 6, 44)'}}>
                    <h2 className='text-white d-flex align-items-center pl-4'>
                      <b> {item.is_default_acc.value ? (`Primary Account  ${(item.acc_ownership.value != "owned" ? " (Third Party)" : "")}` ) :  ( `Additional Account - ${( this.romanize(idx) ) +" "+ ((item.acc_ownership.value != 'owned') ? "(Third Party)" : "")  }`) } </b> 
                      <p className='text-white m-0 pl-3'> - Float Advance</p>
                    </h2>
                    <div className='d-flex align-items-center  align-items-center my-2 mx-2 '>
                        <img src={`/img/${ item.acc_prvdr_code.value}_logo_color.png`} style={{width:'60px',height:'30px'}}/>
                          <p className=' pl-4 no-margin audit_kyc_text  text-white'> <b>{ item.acc_number.value}</b></p>
                          { this.state[`accordian_${idx}`] ?
                             <ImCircleUp   size={25} className='text-white pointer bottom-arrow-left ml-3' value={ this.state[`accordian_0`]?this.state[`accordian_0`]: true} onClick={(e)=>{this.togglePrev(e,`accordian_0`)}} />
                            :
                            <ImCircleDown  className='pointer ml-3 bottom-arrow-left ' color={"white"} size={25} onClick={(e)=>{this.togglePrev(e,`accordian_${idx}`)}}/>
                          }

                      </div>
                  </div>

                {this.state[`accordian_0`] &&
                <div className='row no-margin'>

                <div className='col-lg-12' style={{background:'#223b56'}}>

              {this.props.account_ownership_datas.cust_reg_json &&
              
                <div className=' mt-4  justify-content-center row  '>
              
              <div className='col-lg-12 p-0 d-flex justify-content-around row no-margin'>
          
                

               
              
                <div className='third_party_bg col-lg-12 p-0'>
                        {/* <p className='text-white text-end pt-5' style={{fontSize:'18px',textAlign:'end',marginRight:'35px'}}> Account Purpose : <b className='pl-2'>{ "Float Advance"  }</b></p> */}


                      <div className={`${item.third_party_account?'third_party_bg pb-4 ':''}d-flex pt-3 py-3 third_party_bg no-margin mb-4 justify-content-around row `}>
                        <div className='col-md-5  mt-3 mb-5 col-lg-5' >
                        <div  className='h-100' style={{background: '#E4E7E9',minHeight:'295px'}}>
                          <p  className="no-margin audit_kyc_text text_dark py-3 px-4" >Name as entered by {this.state.rm_label_short??''}</p><hr className='no-margin' style={{border:'1px solid black'}}/>
              
                          {(item.tp_national_id)  ? 
                            <div className='p-3 '>
              
                            <p  className="no-margin py-2 mt-2 audit_kyc_text  px-3 pt-3 " style={{fontSize:'14px'}}>{`THIRD PARTY A/C OWNER NAME (as per ${getLabel('national_id')})`}</p>
                            <h4 className='my-1 px-3  audit_kyc_text text_dark mb-5' style={{color:'black',fontSize:'26px'}}> {item.tp_national_id.first_name.value?<b>{item.tp_national_id.first_name.value } {item.tp_national_id.last_name.value} </b>:"NA"} </h4>
                            
                            </div>
                            :
                          <div className='p-3 '>
              
                          <p  className="no-margin py-2 mt-2 audit_kyc_text  px-3 pt-3 " style={{fontSize:'14px'}}>{`CUSTOMER NAME (as per ${getLabel('national_id')})`}</p>
                          <h4 className='my-1 px-3  audit_kyc_text mb-5 text_dark' style={{fontSize:'26px'}}><b>{this.props.account_ownership_datas.cust_reg_json.id_proof.first_name.value } {this.props.account_ownership_datas.cust_reg_json.id_proof.last_name.value} </b> </h4>
                          
                          {/* <p  className="no-margin pt-3  px-3 audit_kyc_text " style={{fontSize:'14px'}}>BUSINESS NAME (as per Biz License)</p>
                          <h4 className='my-1 px-3 py-2 mb-4' style={{color:'black',fontSize:'26px'}}><b >{ this.props.account_ownership_datas.cust_reg_json.license_proof.biz_name.value}</b> </h4> */}
                          </div>
                            }
                          </div>
              
                        </div>
                        
                        <div className='col-md-2 mt-3 mb-5 col-lg-1' style={{alignSelf:'center'}}>
                        {((!item.hasOwnProperty('holder_name'))) ? "" :  
                        <div className='px-2 py-3' style={{background:` ${item.name_match ? '#22cd07':'red'}`,borderRadius:'30px'}}>
                          <p className='no-margin text-white  text-center audit_kyc_text '> <b style={{fontSize:'25px'}}> { item.name_match ? "=" : '≠'}</b></p>
                        </div>}
                        </div>
                        
                        <div className='col-md-5 mt-3 mb-5 col-lg-5'  >
                        <div  style={{background: '#E4E7E9',minHeight:'295px'}}>
                      <p  className="no-margin audit_kyc_text py-3 px-4" style={{color:'black'}}>{(get('language_json'))?.holder_name}</p><hr className='no-margin' style={{border:'1px solid black'}}/>
              
                      <div className='p-3 '>
                      <div className='d-flex align-items-center row align-items-center my-2 mx-2 '>
                        {/* <img src={`/img/${ item.acc_prvdr_code.value}_logo_color.png`} style={{width:'60px',height:'30px'}}/> */}
                              {/* <p className='no-margin text-white col-4 business_operations_text '>  COUNTRY</p> */}
                          {/* <p className='col-8 pl-4 no-margin audit_kyc_text'> <b>{ item.acc_number.value}</b></p> */}
                      </div>
                      {(this.props.account_ownership_datas.timers[item.acc_number.value]!=0 && (!item.hasOwnProperty('holder_name')) && (( get('app_config').audit_kyc_line[this.props.account_ownership_datas.cust_reg_json.biz_accounts.accounts[idx].acc_prvdr_code.value]=="ussd") || 
                      ( get('app_config').audit_kyc_line[this.props.account_ownership_datas.cust_reg_json.biz_accounts.accounts[idx].acc_prvdr_code.value]=="partner_api")) ) ?
                      this.checkbox_spinner()
                      :
                        <>
                      {item.hasOwnProperty('holder_name') ?
              
                      <>
                      {item.name_match ?
                      <>
                        <p  className="no-margin  mb-2 pt-3 " style={{fontSize:'14px'}}>{`Name as per ${item.acc_prvdr_code.value} records`}</p>
                        <h4 className='my-1 mt-3 px-3 py-2 audit_kyc_text mb-3' style={{background:'rgba(0, 0, 0, 0.3)',color:'black'}}><b>{ item.holder_name}</b> </h4>
                        </>
                      :
                      <div> 
                        <h2 className='my-1 mt-3 px-3 py-2 audit_kyc_text '  style={{background:'rgba(0, 0, 0, 0.3)'}}><b>{item.holder_name}</b> </h2>
                        <div className='mt-4' style={{color:'black'}} ><label className='audit_kyc_text-black no-margin'>Account Holder Name Mismatch Reason </label>
                        {item.hasOwnProperty("mismatch_reason")  && !this.state.is_edit? 
                        
                        <b className='d-flex align-items-center'>
                          <p className='audit_kyc_text no-margin '>

                            { this.state.biz_acc?.[item.acc_number.value]?.mismatch_reason?
                            dd_value(this.state.biz_acc?.[item.acc_number.value]?.mismatch_reason ,'mismatch_reason'):
                            dd_value(item.mismatch_reason ,"mismatch_reason")}
                            
                            </p>
                          <p className='mx-5 mb-0 pointer' onClick={()=>this.handleEdit(item.acc_number.value)}> <AiFillEdit/> Edit
                          </p>
                        </b>
                        :

                        <select
                        id="holder_name_mismatch_reason"
                        className="form-control px-0 text_dark col-md-12"
                        value={this.state.biz_acc?.[item.acc_number.value]?.mismatch_reason??''}
                        onChange={(e)=> this.acc_holder_name(e.target.value,"mismatch_reason" , idx, item)}
                          style={{ color: "black" }}
                      >
                        <option value={''} >Choose</option>
                        {this.getSelectOptions("holder_name_mismatch_reason", null,null,null,null, true )}

                      </select>
                      }
                          </div>

                        { (this.state.biz_acc?.[item.acc_number.value]?.mismatch_reason =="retrieved_franchise_name" )
                        
                        &&
                        <div className='col-12 no-padding'>
                        <div class="form-group mt-4 mb-2">
                        <input type="text" className='p-3 text_dark' id="lname" name="lname" style={{width:'100%',background:'#a0a2a3',color:'black',border:'hidden',fontSize:'14px'}} autocomplete="off" placeholder='ENTER HOLDER NAME' onChange={(e)=>this.acc_holder_name(e.target.value, "holder_name", idx, item)}/>
                      </div>
                      
                      <FileUpload className="audit_kyc_file_upload" lead_id = {this.props.lead_id} id="account_holder_name_proof"  mode = "create" entity ='leads' type="image"          
                        onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}} onCreateUpload={(e)=>this.onCreateUpload(e,item)} />

                      <div className='mt-2'>
                      <button type="button" class="btn btn-info btn-sm " disabled={this.is_disable(item)} onClick={()=>this.upload_file(idx,item)}>
                        submit</button>
                      </div>

                      <div className='col-lg-12 p-3 mt-2 d-flex align-items-center' style={{background:'#fe9f9f'}}>
                            <p className='no-margin  pl-3' style={{color:'black',fontSize:'10px'}}> <b style={{color:'red'}}>Note : </b>If the  SMS is shown on the mobile screen, please merge them into a single image using any photo editing software and upload it.</p>
                        </div>
                      </div>
                      }
                          {/* {()=>this.props.account_owner_checkbox_handler()} */}
                      </div>
                      }
              
                    
                        { (item.hasOwnProperty('holder_name_proof') && (this.state.biz_acc?.[item.acc_number.value]?.mismatch_reason  !="retrieved_franchise_name" ) )&&
                      <div className='m-2'>
                        {item.holder_name_proof &&
                      <DisplayImage image_path={get_dir_path(item.holder_name_proof_path,item.holder_name_proof)} style={{height: '50px',width:'90px'}} file_name={item.holder_name_proof} />
                      }
                      </div>
                      }
                      </>
                      :
                      <div className='col-12 no-padding'>
                        <div class="form-group mt-3 mb-4">
                        <input type="text" className='p-3' id="lname" name="lname" style={{width:'100%',background:'#a0a2a3',color:'black',border:'hidden',fontSize:'14px'}} autocomplete="off" placeholder='ENTER HOLDER NAME' onChange={(e)=>
                          this.acc_holder_name(e.target.value,"holder_name" ,idx, item)
                         }
                          />
                      </div>
                      
                      <FileUpload className="audit_kyc_file_upload" lead_id = {this.props.lead_id} id="account_holder_name_proof"  mode = "create" entity ='leads' type="image"          
                        onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}}  onCreateUpload={(e)=>this.onCreateUpload(e,item)} 
                        />
                       
                      <div className='mt-4'>

                      <button type="button" class="btn btn-info btn-sm " 
                      disabled={this.is_disable(item)} 
                      
                      onClick={()=>this.upload_file(idx,item)}>
                        
                        submit</button>

                      {/* <button type="button" class="btn btn-info btn-sm " disabled={(this.props.account_ownership_datas.lead.account_holder_name_proof && this.props.account_ownership_datas[`account_holder_name_${idx}`]) ? false :true} onClick={()=>this.props.file_upload_submit_handler(this.props.account_ownership_datas[`account_holder_name_${idx}`],`acc_num_${idx}`,"account_holder_name_proof", item.acc_number.value)}>submit</button> */}
                      </div>
                        
                      </div>
              
                      }
                        </>
                          } 
                      </div>
                      </div>
                        </div>
                      
                      </div>



                        { ( item.hasOwnProperty("name_match") && !(item.name_match ))&&
                          <div className='row  pb-3 justify-content-center no-margin pt-2 px-4' >
                          <div className='col-lg-12 p-3 d-flex align-items-center' style={{background:'#fe9f9f'}}>
                            <h2 className='d-flex align-items-center'> <b>Note</b> <p className='no-margin audit_kyc_text pl-3' style={{color:'black'}}>The name of the customer doesn’t match  with customer name </p></h2>
                          </div>
                          </div>
                          }

                          {((item.acc_ownership.value=="rented")|| ((item.acc_ownership.value=="bought")) )?
                          <div className='col-lg-10 py-2 px-5  mt-1'>
                          {this.props.account_ownership_datas.active_section &&
                          <>
                            
                         
                            <form className={`pointer ${(this.props.account_ownership_datas.active_section.verify || (this.props.account_ownership_datas.active_section.to_reassign==true) )? "disable_div":''}   ${ ((this.props.account_ownership_datas.responseData.audited_by== get("user_id")) || (this.props.account_ownership_datas.responseData.audited_by==null )) ?"":'disable_div'}`}>
                            {item.holder_name &&
                            <div className='d-flex align-items-center mt-2 mb-4'>
                            <input type="checkbox" id={`check_box_index_${idx}`} style={{height:'19px',width:'20px'}} checked={ audit_kyc && this.props.audit_kyc.biz_accounts[`acc_num_${idx+1}_holder_name_match`] && true } name="vehicle1" 
                             onChange={(e)=>this.props.checkbox_verification(e.target.checked,`acc_num_${idx+1}_holder_name_match`,"acc_owner_verification")}/>
                            <label className='no-margin pl-3 audit_kyc_text '  for={`check_box_index_${idx}`}>{`The third party account holder name matches with the third party ${getLabel('national_id')} name`}</label><br/><br/>
                            </div>
                            }
                          </form>
                         
                          </>
                          }
                      
                          </div>:
                          
                          <div className='col-lg-10 py-2 pb-4 px-5 mb-3  mt-1'> 
                          
                            <form className={`${(this.props.account_ownership_datas.active_section.verify || (this.props.account_ownership_datas.active_section.to_reassign==true) )? "disable_div":''} `}>
                              {item.holder_name &&
                                <div className='d-flex align-items-center'>
                                  {/* {this.props.account_ownership_datas.form_spinner ? 
                                  <div className='d-flex align-items-center justify-content-center'>{this.checkbox_spinner("no_margin")}</div>
                                : */}
                                <input type="checkbox" id={`acc_num_${idx+1}_holder_name_match`} style={{height:'19px',width:'20px'}} 
                                // checked={ this.props.account_ownership_datas[`acc_num_${idx+1}_holder_name_match`]?this.props.account_ownership_datas[`acc_num_${idx+1}_holder_name_match`]: this.props.account_ownership_datas.active_section.flags[`acc_num_${idx+1}_holder_name_match`] } 

                                checked={ audit_kyc && this.props.audit_kyc.biz_accounts[`acc_num_${idx+1}_holder_name_match`]  && true} 

                                
                                name="vehicle2"  onChange={(e)=>this.props.checkbox_verification(e.target.checked,`acc_num_${idx+1}_holder_name_match`,"acc_owner_verification")}/>
                               
                                <label className='no-margin pl-3 audit_kyc_text'  for={`acc_num_${idx+1}_holder_name_match`}>{`The  account holder name  matches with the customer name (as captured by ${this.state.rm_label_short??''}). `}</label><br/><br/>
                                </div>
                              }
                            </form>
                            
                          
                                
                          </div>
                          }

                          <div className='col-lg-12 py-3 mt-3 px-5 mb-5' >

                          

                          <RegistrationDocument root={this.props.root} update_mismatch_reason={(a,b,c,d)=>this.props.update_mismatch_reason(a,b,c,d)}  checkbox_verification={(a,b,c)=>this.props.checkbox_verification(a,b,c)} account_ownership_datas={this.props.account_ownership_datas} lead_id={this.props.lead_id} items={item} document_index={idx } view_lead = {(a,b,c)=>this.props.view_lead(a,b,c)}/>

                         

                          </div>

                    


              
                    
              
              
                </div>

              
                      
                </div>
              
              </div>}
                </div>

                </div>
                }
              
                </div>
               
              </div>
             )}})}
               {this.props.account_ownership_datas.cust_reg_json.biz_accounts.accounts.map((item,idx)=>{ if(!item.is_default_acc?.value){return(
              <div key={`acc_ownership_${idx}`} className="col-12 row m-0 justify-content-center"> 
                <div  className="col-lg-12 col-md-12" style={{marginBottom:`${this.state[`accordian_${idx+1}`] ? "2%":"10px"}`}} >


                  <div className='col-lg-12 py-3 mt-2 d-flex align-items-center justify-content-between' style={{background:'rgb(1, 6, 44)'}}>
                    <h2 className='text-white d-flex align-items-center pl-4'>
                      <b> {item.is_default_acc?.value ? (`Primary Account  ${(item.acc_ownership.value != "owned" ? " (Third Party)" : "")}` ) :  ( `Additional Account - ${( this.romanize(idx+1) ) +" "+ ((item.acc_ownership.value != 'owned') ? "(Third Party)" : "")  }`) } </b> 
                      <p className='text-white m-0 pl-3'> - Float Advance</p>
                    </h2>
                    <div className='d-flex align-items-center  align-items-center my-2 mx-2 '>
                        <img src={`/img/${ item.acc_prvdr_code.value}_logo_color.png`} style={{width:'60px',height:'30px'}}/>
                          <p className=' pl-4 no-margin audit_kyc_text  text-white'> <b>{ item.acc_number.value}</b></p>
                          { this.state[`accordian_${idx+1}`] ?
                             <ImCircleUp   size={25} className='text-white pointer bottom-arrow-left ml-3' value={ this.state[`accordian_${idx+1}`]?this.state[`accordian_${idx+1}`]: true} onClick={(e)=>{this.togglePrev(e,`accordian_${idx+1}`)}} />
                            :
                            <ImCircleDown  className='pointer ml-3 bottom-arrow-left ' color={"white"} size={25} onClick={(e)=>{this.togglePrev(e,`accordian_${idx+1}`)}}/>
                          }

                      </div>
                  </div>

                {this.state[`accordian_${idx+1}`] &&
                <div className='row no-margin'>

                <div className='col-lg-12' style={{background:'#223b56'}}>

              {this.props.account_ownership_datas.cust_reg_json &&
              
                <div className=' mt-4  justify-content-center row  '>
              
              <div className='col-lg-12 p-0 d-flex justify-content-around row no-margin'>
          
                

               
              
                <div className='third_party_bg col-lg-12 p-0'>
                        {/* <p className='text-white text-end pt-5' style={{fontSize:'18px',textAlign:'end',marginRight:'35px'}}> Account Purpose : <b className='pl-2'>{ "Float Advance"  }</b></p> */}


                      <div className={`${item.third_party_account?'third_party_bg pb-4 ':''}d-flex pt-3 py-3 third_party_bg no-margin mb-4 justify-content-around row `}>
                        <div className='col-md-5  mt-3 mb-5 col-lg-5' >
                        <div  className='h-100' style={{background: '#E4E7E9',minHeight:'295px'}}>
                          <p  className="no-margin audit_kyc_text py-3 px-4" style={{color:'black'}}>Name as entered by {this.state.rm_label_short??''}</p><hr className='no-margin' style={{border:'1px solid black'}}/>
              
                          {(item.tp_national_id)  ? 
                            <div className='p-3 '>
              
                            <p  className="no-margin py-2 mt-2 audit_kyc_text  px-3 pt-3 " style={{fontSize:'14px'}}>{`THIRD PARTY A/C OWNER NAME (as per ${getLabel('national_id')})`}</p>
                            <h4 className='my-1 px-3  audit_kyc_text mb-5' style={{color:'black',fontSize:'26px'}}> {item.tp_national_id.first_name.value?<b>{item.tp_national_id.first_name.value } {item.tp_national_id.last_name.value} </b>:"NA"} </h4>
                            
                            </div>
                            :
                          <div className='p-3 '>
              
                          <p  className="no-margin py-2 mt-2 audit_kyc_text  px-3 pt-3 " style={{fontSize:'14px'}}>{`CUSTOMER NAME (as per ${getLabel('national_id')})`}</p>
                          <h4 className='my-1 px-3  audit_kyc_text mb-5 text_dark' style={{fontSize:'26px'}}><b>{this.props.account_ownership_datas.cust_reg_json.id_proof.first_name.value } {this.props.account_ownership_datas.cust_reg_json.id_proof.last_name.value} </b> </h4>
                          
                          {/* <p  className="no-margin pt-3  px-3 audit_kyc_text " style={{fontSize:'14px'}}>BUSINESS NAME (as per Biz License)</p>
                          <h4 className='my-1 px-3 py-2 mb-4' style={{color:'black',fontSize:'26px'}}><b >{ this.props.account_ownership_datas.cust_reg_json.license_proof.biz_name.value}</b> </h4> */}
                          </div>
                            }
                          </div>
              
                        </div>
                        
                        <div className='col-md-2 mt-3 mb-5 col-lg-1' style={{alignSelf:'center'}}>
                        {((!item.hasOwnProperty('holder_name'))) ? "" :  
                        <div className='px-2 py-3' style={{background:` ${item.name_match ? '#22cd07':'red'}`,borderRadius:'30px'}}>
                          <p className='no-margin text-white  text-center audit_kyc_text '> <b style={{fontSize:'25px'}}> { item.name_match ? "=" : '≠'}</b></p>
                        </div>}
                        </div>
                        
                        <div className='col-md-5 mt-3 mb-5 col-lg-5'  >
                        <div  style={{background: '#E4E7E9',minHeight:'295px'}}>
                      <p  className="no-margin audit_kyc_text py-3 px-4" style={{color:'black'}}>{(get('language_json'))?.holder_name}</p><hr className='no-margin' style={{border:'1px solid black'}}/>
              
                      <div className='p-3 '>
                      <div className='d-flex align-items-center row align-items-center my-2 mx-2 '>
                        {/* <img src={`/img/${ item.acc_prvdr_code.value}_logo_color.png`} style={{width:'60px',height:'30px'}}/> */}
                              {/* <p className='no-margin text-white col-4 business_operations_text '>  COUNTRY</p> */}
                          {/* <p className='col-8 pl-4 no-margin audit_kyc_text'> <b>{ item.acc_number.value}</b></p> */}
                      </div>
                      {(this.props.account_ownership_datas.timers[item.acc_number.value]!=0 && (!item.hasOwnProperty('holder_name')) && (( get('app_config').audit_kyc_line[this.props.account_ownership_datas.cust_reg_json.biz_accounts.accounts[idx].acc_prvdr_code.value]=="ussd") || 
                      ( get('app_config').audit_kyc_line[this.props.account_ownership_datas.cust_reg_json.biz_accounts.accounts[idx].acc_prvdr_code.value]=="partner_api")) ) ?
                      this.checkbox_spinner()
                      :
                        <>
                      {item.hasOwnProperty('holder_name') ?
              
                      <>
                      {item.name_match ?
                      <>
                        <p  className="no-margin  mt-4 mb-2 pt-3 " style={{fontSize:'14px'}}>{`Name as per ${item.acc_prvdr_code.value} records`}</p>
                        <h4 className='my-1 mt-3 px-3 py-2 audit_kyc_text mb-3' style={{background:'rgba(0, 0, 0, 0.3)',color:'black'}}><b>{ item.holder_name}</b> </h4>
                        </>
                      :
                      <div> 
                        <h2 className='my-1 mt-3 px-3 py-2 audit_kyc_text '  style={{background:'rgba(0, 0, 0, 0.3)'}}><b>{item.holder_name}</b> </h2>
                        <div className='mt-4' style={{color:'black'}} >
                          <label className='audit_kyc_text-black no-margin'>Account Holder Name Mismatch Reason </label>
                        {item.hasOwnProperty("mismatch_reason") && !this.state.is_edit ? 
                        <b className='d-flex align-items-center'>
                        <p className='audit_kyc_text no-margin '>

                          { this.state.biz_acc?.[item.acc_number.value]?.mismatch_reason?
                          dd_value(this.state.biz_acc?.[item.acc_number.value]?.mismatch_reason ,'mismatch_reason'):
                          dd_value(item.mismatch_reason ,"mismatch_reason")}
                          
                          </p>
                        <p className='mx-5 mb-0 pointer' onClick={()=>this.handleEdit(item.acc_number.value)}> <AiFillEdit/> Edit
                        </p>
                      </b>
                        :
                          
                          <select
                          id="holder_name_mismatch_reason"
                          className="form-control px-0 text_dark col-md-12"
                          value={this.state.biz_acc?.[item.acc_number.value]?.mismatch_reason??''}
                          onChange={(e)=> this.acc_holder_name(e.target.value,"mismatch_reason" , idx, item)}
                            style={{ color: "black" }}
                        >
                          <option value={''} >Choose</option>
                          {this.getSelectOptions("holder_name_mismatch_reason", null,null,null,null, true )}
                          </select>


                          }
                          </div>

                        { (this.state.biz_acc?.[item.acc_number.value]?.mismatch_reason  =="retrieved_franchise_name" )&&
                        <div className='col-12 no-padding'>
                        <div class="form-group mt-4 mb-2">
                        <input type="text" className='p-3' id="lname" name="lname" style={{width:'100%',background:'#a0a2a3',color:'black',border:'hidden',fontSize:'14px'}} autocomplete="off" placeholder='ENTER HOLDER NAME' 
                        
                        onChange={(e)=>
                          this.acc_holder_name(e.target.value,"holder_name", idx, item)
                          
                          // this.props.account_owner_name_handler(e.target.value,`account_holder_name_${idx}`)
                        }
                        
                        
                        />
                      </div>
                      
                      <FileUpload className="audit_kyc_file_upload" lead_id = {this.props.lead_id} id="account_holder_name_proof"  mode = "create" entity ='leads' type="image"          
                        onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}} onCreateUpload={(e)=>this.onCreateUpload(e,item)}
                         />
                      <div className='mt-2'>
                      <button type="button" class="btn btn-info btn-sm " disabled={this.is_disable(item)} onClick={
                        ()=>this.upload_file(idx,item)}
                          
                          
                          // this.props.file_upload_submit_handler(this.props.account_ownership_datas[`account_holder_name_${idx}`],`acc_num_${idx}`,"account_holder_name_proof", item.acc_number.value)}
                        
                        >submit</button>
                      </div>

                      <div className='col-lg-12 p-3 mt-2 d-flex align-items-center' style={{background:'#fe9f9f'}}>
                            <p className='no-margin  pl-3' style={{color:'black',fontSize:'10px'}}> <b style={{color:'red'}}>Note : </b>If the  SMS is shown on the mobile screen, please merge them into a single image using any photo editing software and upload it.</p>
                        </div>
                      </div>
                      }
                          {/* {()=>this.props.account_owner_checkbox_handler()} */}
                      </div>
                      }
              
                    
                        { (item.hasOwnProperty('holder_name_proof') && (this.state.biz_acc?.[item.acc_number.value]?.mismatch_reason !="retrieved_franchise_name" ) )&&
                      <div className='m-2'>
                        {item.holder_name_proof &&
                      <DisplayImage image_path={get_dir_path(item.holder_name_proof_path,item.holder_name_proof)} style={{height: '50px',width:'90px'}} file_name={item.holder_name_proof} />
                      }
                      </div>
                      }
                      </>
                      :
                      <div className='col-12 no-padding'>
                        <div class="form-group mt-3 mb-4">
                        <input type="text" className='p-3' id="lname" name="lname" style={{width:'100%',background:'#a0a2a3',color:'black',border:'hidden',fontSize:'14px'}} autocomplete="off" placeholder='ENTER HOLDER NAME' 
                        
                        onChange={(e)=>
                          this.acc_holder_name(e.target.value,"holder_name" ,idx, item)

                          // this.props.account_owner_name_handler(e.target.value,`account_holder_name_${idx}`)
                        }
                        
                        />
                      </div>
                      
                      <FileUpload className="audit_kyc_file_upload" lead_id = {this.props.lead_id} id="account_holder_name_proof"  mode = "create" entity ='leads' type="image"          
                        onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}}  onCreateUpload={(e)=>this.onCreateUpload(e,item)} />
                      <div className='mt-4'>
                      <button type="button" class="btn btn-info btn-sm " 
                      disabled={this.is_disable(item)}
                       onClick={      ()=>this.upload_file(idx,item)
                      }>submit</button>

                      {/* <button type="button" class="btn btn-info btn-sm " disabled={(this.props.account_ownership_datas.lead.account_holder_name_proof && this.props.account_ownership_datas[`account_holder_name_${idx}`]) ? false :true} onClick={()=>this.props.file_upload_submit_handler(this.props.account_ownership_datas[`account_holder_name_${idx}`],`acc_num_${idx}`,"account_holder_name_proof", item.acc_number.value)}>submit</button> */}
                      </div>
                        
                      </div>
              
                      }
                        </>
                          } 
                      </div>
                      </div>
                        </div>
                      
                      </div>
                      {item.existing_account_details && item.existing_account_details.length >0 &&
                      <div className='third_party_bg px-5 mb-5'>
                        <div className='pb-5 pt-3' style={{background:"#e4e7e9"}}>
                        <div className='row m-0 d-flex align-items-center pb-3'>
                          <div className='col-lg-12 col-md-12 col-sm-12 my-4 '>
                            <p className='text-center m-0 audit_kyc_text' style={{fontSize:'23px'}}><b> Other Accounts owned by the same third party ({item.tp_national_id ? <b>{item.tp_national_id.first_name.value} {item.tp_national_id.last_name.value}</b>:<b>{this.props.account_ownership_datas.cust_reg_json.id_proof.first_name.value } {this.props.account_ownership_datas.cust_reg_json.id_proof.last_name.value} </b>} )</b></p>
                          </div>
                        </div>

                        <div className='px-3 mx-3'>
                        <table class="table table-hover audit_existig_account_table col-lg-12 col-md-12 col-sm-12">
                          <thead>
                            <tr>
                              <th scope="col">Customer ID</th>
                              <th scope="col">Customer Name</th>
                              <th scope="col">Account No</th>

                              <th scope="col">Biz Name</th>
                              <th scope="col">Ownership</th>
                              <th scope="col">Mobile Number</th>
                              <th scope="col">Limit</th>

                            </tr>
                          </thead>
                          <tbody className='py-2'>

                            {item.existing_account_details.length >0 && item.existing_account_details.map((values,indexval)=>{return(
                                <tr  className= "audit_kyc_existing_table_text" style={{color:'black'}}>
                                <td> <b className='existing_acc_cust_id'> <Link className="nav-link existing_acc_cust_id" to={`/borrower/indiv/view/${values.cust_id}`} target='_blank'>{values.cust_id} </Link>
                                 </b>
                                </td>    
                                <td> <b>{values.first_name ?values.first_name :"" } {values.last_name ?values.last_name :"" }</b></td>
                                <td> 
                                  <div className='d-flex align-items-center'>   
                                <img style={{width:'60px',height:'30px'}} className="img-fluid" src={`/img/${values.acc_prvdr_code }_logo_color.png`} alt={values.acc_prvdr_code}/>	
                                <p className='m-0 pl-3'> <b>{values.acc_number?values.acc_number:"-"}</b></p>
                                </div>
                               
                                </td>

                                <td> <b>{values.biz_name?values.biz_name:"-"}</b></td>
                                <td><b>{values.acc_ownership ?values.acc_ownership :"-" }</b></td>

                                <td><b>{values.mobile_num ?values.mobile_num:"-"}</b></td>
                                <td><b>{ values.limit_amount ? lbl_amt(values.limit_amount,get("market").currency_code):"500,000 UGX"}</b></td>
                                </tr>

                            )}) }
                           
                          </tbody>
                        </table>
                        </div>

                        </div>
                      </div>
                }
                        { ( item.hasOwnProperty("name_match") && !(item.name_match ))&&
                          <div className='row  pb-3 justify-content-center no-margin pt-2 px-4' >
                          <div className='col-lg-12 p-3 d-flex align-items-center' style={{background:'#fe9f9f'}}>
                            <h2 className='d-flex '> <b>Note</b> <p className='no-margin audit_kyc_text pl-3' style={{color:'black'}}>The name of the customer doesn’t match  with customer name </p></h2>
                          </div>
                          </div>
                          }

                          {((item.acc_ownership.value=="rented")|| ((item.acc_ownership.value=="bought")) )?
                          <div className='col-lg-10 py-2 px-5  mt-1'>
                          {this.props.account_ownership_datas.active_section &&
                          <>
                            
                         
                            <form className={`pointer ${(this.props.account_ownership_datas.active_section.verify || (this.props.account_ownership_datas.active_section.to_reassign==true) )? "disable_div":''}   ${ ((this.props.account_ownership_datas.responseData.audited_by== get("user_id")) || (this.props.account_ownership_datas.responseData.audited_by==null )) ?"":'disable_div'}`}>
                            {item.holder_name &&
                            <div className='d-flex align-items-center mt-2 mb-4'>
                            <input type="checkbox" id={`check_box_index_${idx}`} style={{height:'19px',width:'20px'}} 
                             
                            checked={audit_kyc && this.props.audit_kyc.biz_accounts[`acc_num_${idx+1}_holder_name_match`]  && true} 
                            name="vehicle1"  onChange={(e)=>this.props.checkbox_verification(e.target.checked,`acc_num_${idx+1}_holder_name_match`,"acc_owner_verification")}/>
                            <label className='no-margin pl-3 audit_kyc_text '  for={`check_box_index_${idx}`}>{`The third party account holder name matches with the third party ${getLabel('national_id')} name`}</label><br/><br/>
                            </div>
                            }
                          </form>
                         
                          </>
                          }
                      
                          </div>:
                          
                          <div className='col-lg-10 py-2 pb-4 px-5 mb-3  mt-1'>
                         
                            <form className={`${(this.props.account_ownership_datas.active_section.verify || (this.props.account_ownership_datas.active_section.to_reassign==true) )? "disable_div":''} `}>
                              {item.holder_name &&
                                <div className='d-flex align-items-center'>
                                {/* {this.props.account_ownership_datas.form_spinner ? 
                                  <div className='d-flex align-items-center justify-content-center'>{this.checkbox_spinner("no_margin")}</div>
                                : */}
                                <input type="checkbox" id={`acc_num_${idx+1}holder_name_match`} style={{height:'19px',width:'20px'}} 
                        
                                checked={audit_kyc &&this.props.audit_kyc.biz_accounts[`acc_num_${idx+1}_holder_name_match`]  && true} 

                                
                                name="vehicle2"  onChange={(e)=>this.props.checkbox_verification(e.target.checked,`acc_num_${idx+1}_holder_name_match`,"acc_owner_verification")}/>
                                
                                <label className='no-margin pl-3 audit_kyc_text'  for={`acc_num_${idx+1}holder_name_match`}>{`The  account holder name  matches with the customer name (as captured by ${this.state.rm_label_short??''}). `}</label><br/><br/>
                                </div>
                              }
                            </form>
                         
                                
                          </div>
                          }

                          <div className='col-lg-12 py-3 mt-3 px-5 mb-5' >

                          

                          <RegistrationDocument  root={this.props.root}  update_mismatch_reason={(a,b,c,d)=>this.props.update_mismatch_reason(a,b,c,d)}  checkbox_verification={(a,b,c)=>this.props.checkbox_verification(a,b,c)} account_ownership_datas={this.props.account_ownership_datas} lead_id={this.props.lead_id} items={item} document_index={idx} view_lead = {(a,b,c)=>this.props.view_lead(a,b,c)}/>

                         

                          </div>

                    


              
                    
              
              
                </div>

              
                      
                </div>
              
              </div>}
                </div>

                </div>
                }
              
                </div>
               
              </div>
             )}})}
           

           
            
            </div>
             
            
            </>
           
            </>
           </div>
            }
          
           </> 
           
            
            </div>
            
           

        )   
        }     
     
}


const mapStateToProps = (state) => {
  return {
    audit_kyc: state.common_slice,
  };
  };

const mapDispatchToProps ={
    updateValuesIntoRedux
};

export default connect(mapStateToProps,mapDispatchToProps)(AuditKYCAccountOwnershipComponent);

 


