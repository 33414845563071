import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';

import '../../../styles/flow.css';
import {Form, Col , Row, Button,checkbox } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {getLoanSearchCriteria} from '../../../actions/common_actions';
import DatePicker from 'react-datetime';
import moment from 'moment';
import {check_priv, Config, get} from '../../../helpers/storage_helper';
import '../../../styles/flow.css';
import Select from "react-select";
import { getValueFromLangJson } from '../../../helpers/common_helper';
import { connect } from 'react-redux';
class SearchLoanCriteria extends FlowComponent{
	
	
constructor(props){
    super(props);
    this.state = {
    	
    	customer_id:"",
      action : "Approved",	
      adv_search: false,
      show_by_today : false,
      show_from_until: false,
      today: false,
      screen: "",
      search_by_dropdown_style: "show_div",
      search_by_textbox_style: "show_div",
      register_date: null,
      __from:"",
      __to:"",
      reg_date_from:"",
      reg_date_to:"",
      section1: "show_div",
      section3: "show_div",
      value : "enabled",
      section2:"disable_div",
      disable_date : false,
	  language_json : get("language_json"),
	  selectedValues: {},
	  addr_hiearchy: Config("addr_hiearchy")[get("market").country_code]
    }; 
}

onDiableStausCheckbox = (event)=>{

 if(event.target.checked){
	this.setState({enabled_check_box:true})
	this.handleElementChange('status',"disabled")
 }else{
	this.setState({enabled_check_box:false})
	this.handleElementChange('status',null)
 }
	
}

onEnabledStausCheckbox=(event)=>{
	if(event.target.checked){
		this.setState({disabled_check_box:true})
		this.handleElementChange('status',"enabled")
	 }else{
		this.setState({disabled_check_box:false})
		this.handleElementChange('status',null)
	 }
}

	handleSection1 = (value) =>{
		value = moment(value).format("YYYY-MM-DD");
		if(value.target){
			const event = value;
			value = event.target.value
		}
		if(value != "")
		{
			this.setState({section3: "disable_div",search_by_textbox_style:"disable_div",section2:"disable_div"});
		}

		else{
			this.setState({section1:"show_div",search_by_textbox_style:"show_div"});
		}
	}

	handleSection3 = (event) =>{

		if(event.target.value == "")
		{
			this.setState({section1: "disable_div",section2:"disable_div"});
		}
		else if(event.target.value != "")
		{
		this.setState({section1:"show_div",section2:"show_div"});
		}
	}

		handleSection2 = (event) =>{

		if(event.target.value != "")
		{
			this.setState({section1: "disable_div",section3: "disable_div"});
		}
		else if(event.target.value == "")
		{
			this.setState({section1: "show_div",section3: "show_div"});	
		}
	}

	/*handleCustIDTextboxValue = (event) => {
		if(event.target.value != "")
		{
			this.setState({loan_id_div_sonSearchtyle: "disable_div"});
		}
		else if(event.target.value == "")
		{
		this.setState({loan_id_div_style: "show_div"});	
		}
	}*/

	handleStatusChange = (event) => {
		this.handleReset(event);
		this.handleElementReset(this.state.action_date_field + '__from');	
		this.handleElementReset(this.state.action_date_field + '__to');	
		this.handleElementReset(this.state.action_date_field + '__today');	
		this.handleElementReset(this.state.actor_id);
		this.setState({submitted_field_style: "enable_div",__from: "", __to: "", today: false,
		show_by_today : false,  show_from_until: false, show_approver: false})
		

		if(this.props.mode == 'loan' ){
			
			if(event.target.value == "pending_disbursal"){
				
				this.setState({action: "Approved", show_by_today : true, show_from_until : true, 
					actor_id: "loan_approver_id", action_date_field: "loan_approved_date",
					actor_list_dd: this.state.approver_list_dd,  
					 });
			}
			else if(event.target.value == "ongoing" || event.target.value == "disbursed"){
				this.setState({action: this.state.language_json.disbursed, show_from_until : true, 
					actor_id : "disbursed_by", action_date_field: "disbursal_date",
					actor_list_dd: this.state.disbursed_by_dd, 
					__from: "", __to: ""});
			}else if(event.target.value == "due"){
				this.setState({action: "Due_today"});

			}else if(event.target.value == "Overdue"){
				this.setState({action: this.state.language_json.due, show_from_until : true, action_date_field: "due_date",
					__from: "", __to: ""});
			}else if(event.target.value == "settled" ){
				this.setState({action: this.state.language_json.payment_captured,show_by_today : true, show_from_until : true, 
					actor_id: "paid_by", action_date_field: "paid_date",
					actor_list_dd: this.state.payer_list_dd, 
					  __from: "", __to: ""});
			}	
		}
		else if (this.props.mode == 'loan_appl') {
			if(event.target.value == "approved"){
				this.setState({action: this.state.language_json.approved, show_by_today : true, show_from_until : false, 
					actor_id: "loan_approver_id", action_date_field: "loan_approved_date",
					actor_list_dd: this.state.approver_list_dd,  
					 });
			}else if(event.target.value == "pending_approval"){
				this.setState({action: this.state.language_json.applied, show_by_today : true, show_from_until : true,  show_approver : true,
					actor_id: "loan_applied_by", action_date_field: "loan_appl_date",
					actor_list_dd: this.state.applier_list_dd,  
					 });
			}
		}
	}	

	handleDropdownValue = (event) => {

		if(event.target.id == 'status'){
			this.handleStatusChange(event)			
		}

		/*if(event.target.id == 'data_prvdr_code'){
			this.load_dp_rel_mgr(event.target.value);
		}*/
		
		if(event.target.value != ""){
			this.setState({search_by_textbox_style: "disable_div"});
		}else{
			this.setState({search_by_textbox_style: "show_div"});
		}
	}

	componentWillMount(){

	  	const country_code = this.country_code;
	  	if(this.props.mode == 'loan'){
			this.screen = "loan_search";
			this.setState({doc_id : "loan_doc_id"});
			this.setState({doc_id_lbl : "FA ID"});
		}else if(this.props.mode == 'loan_appl'){
			this.screen = "loan_appl_search";
			this.setState({doc_id : "loan_appl_doc_id"});
			this.setState({doc_id_lbl : "FA Application ID"});
		}
		else if(this.props.mode == 'borrower')
		{
   
			this.screen = "borrower_search";
		}
	  	this.handleApiCall(country_code,this.screen);

	  	this.load_flow_rel_mgr(null,"multiselect_rm_name");
	  	this.load_dp_rel_mgr();
		this.setOptionsListToStateFromAppApi('common', 'list', {...this.def_req ,status: "enabled", biz_account: true}, ['acc_prvdr_code', 'name'], "acc_prvdr");

	
	}

handleApiCall = (country_code,screen) => {
	var acc_prvdr_code = this.acc_prvdr_code;
	getLoanSearchCriteria(this.req({country_code, screen, acc_prvdr_code, "status": "enabled"}))
	  		  .then((response) => {
	     		 if(!response){return };
	    		var result = response.data;
				for (var dd_name in result) {
					var name_list = result[dd_name];
					//alert(JSON.stringify(name_list));
					if(dd_name == "loan_status" || dd_name == "loan_appl_status" || dd_name == "borrower_status" || dd_name == 'territory_list'
						|| dd_name == 'profile_status' || dd_name == 'activity_status' || dd_name == 'risk_category'  || dd_name == 'write_off_status_list' 
						|| dd_name == 'location' || dd_name == 'sub_lender_code'){
						const data_holders = ["data_code", "data_value"];
						this.getSelectOptionsFromResult(name_list, dd_name, data_holders,false);
					}else{	
						this.getSelectOptionsFromResult(name_list, dd_name);
					}		
				}
		   });
}

toggleAdvSearch = () => {
	this.setState({adv_search : !this.state.adv_search});
}
SearchByFromUntil = (space) => (
		<div className='d-flex align-items-start textInputshow_searchBox' style={{flexWrap:'wrap'}}>
				{this.state.show_approver &&				
					<div className={'col-md-3 marT-5'}>
						<div className={this.state.section1}>
							<Form.Group className='no-margin'>						
								<Form.Label className='no-margin'>Approver</Form.Label>
								<Form.Control as="select" className='search_select'  id="loan_approver_id" onChange={(e) => {this.handleChange(e); this.handleDropdownValue(e);this.handleSection1(e);}}>
									{this.state.approver_list_dd}
								</Form.Control>
							</Form.Group>
						</div>
					</div>
    			}

				{this.state.show_by_today &&
				<>
					{this.props.mode == "loan_appl" ?
						<div className='col-md-3'>
							<div className={this.state.section1}>
								<Form.Group>
									<Form.Label className='no-margin'>{this.state.action}</Form.Label>
									<Form.Control as="select" className='search_select'  id={this.state.actor_id} onChange={(e) => {this.handleChange(e); this.handleDropdownValue(e);this.handleSection1(e);}}>
										{this.state.actor_list_dd}
									</Form.Control>
								</Form.Group>
							</div>
						</div>
						:
						<div className='col-md-3 marT-5 searchInput_height'>
							<div className={this.state.section1}>
								<Form.Group className='no-margin'>
									<Form.Control as="select" className='search_select'  id={this.state.actor_id} onChange={(e) => {this.handleChange(e); this.handleDropdownValue(e);this.handleSection1(e);}} >
										{this.state.actor_list_dd} <option selected value="">{this.state.action} {this.state.language_json.by}</option>
									</Form.Control>
								</Form.Group>
							</div>
						</div>
					}
					{this.props.mode == "loan_appl" ? 
						<div className='col-md-3 searchInput_height'>
							<div className={`${this.state.section1} d-flex align-items-center mt-4`}>
								{/* <Form.Group className='no-margin'>
									<Form.Label className='no-margin'>rebi</Form.Label>
									<Form.Row className="justify-content-md-center d-flex align-items-center" >
										<Form.Check className="aggregation" type="checkbox" id={this.state.action_date_field + "__today"} onChange={(e) => {this.handleCheckboxClick(e); this.handleDropdownValue(e);this.handleSection1(e);}} label={this.state.action + " " +this.state.language_json.today} />
									</Form.Row>
								</Form.Group> */}

								
								<input id={this.state.action_date_field + "__today"} className='p-2 pointer' type="checkbox" onChange={(e) => {this.handleCheckboxClick(e); this.handleDropdownValue(e);this.handleSection1(e);}} style={{height:"15px",width:"15px"}}/>
								<label for={this.state.action_date_field + "__today"} className="checkbox-inline no-margin d-flex align-items-center pl-2 pointer " style={{fontSize:'12px'}}>{this.state.action + " " +this.state.language_json.today} </label>
									
							</div>
						</div> 
						:
						<div className='col-md-3 searchInput_height'>
							<div className={this.state.section1}>
								<Form.Group className='no-margin'>
									<Form.Label className='no-margin'></Form.Label>
									<Form.Row className="justify-content-md-center">
										<Form.Check className="aggregation" type="checkbox" id={this.state.action_date_field + "__today"} onChange={(e) => {this.handleCheckboxClick(e); this.handleDropdownValue(e);this.handleSection1(e);}} label={this.state.action + " " +this.state.language_json.today} disabled={this.state.__from || this.state.__to}/>
									</Form.Row>
								</Form.Group>
							</div>
						</div>
					}
    			</>
    	
    		}
    		
			{this.state.show_from_until && 
				<>
					<div className='col-md-3 marT-5'>
						<Form.Group className={`${this.state.section1} no-margin searchInput_height`}>
							<Form.Label className='no-margin'>{this.state.action} {this.state.language_json.from} <span className='text-danger'>*</span></Form.Label>

							<DatePicker inputProps={{disabled:this.state.disable_date}} closeOnSelect={true} value={this.state.__from}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"
							onChange={(value) => {this.handleDateChange(this.state.action_date_field + '__from', value); this.setState({__from:value}); this.handleSection1(value);}} />
						</Form.Group>
					</div>
					<div className='col-md-3 marT-5'>
						<Form.Group className={`${this.state.section1} no-margin searchInput_height`}>
							<Form.Label className='no-margin'>{this.state.action} {this.state.language_json.until}<span className="text-danger">*</span></Form.Label>
							<DatePicker inputProps={{disabled:this.state.disable_date}} closeOnSelect={true} value={this.state.__to}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"
							onChange={(value) => {this.handleDateChange(this.state.action_date_field + '__to', value); this.setState({__to:value}); this.handleSection1(value);}}  />

						</Form.Group>
					</div>	
					{this.state.action == 'Due' &&
						<div className="col-md-3 p-2 mt-4">									
							<div className="">
							
								<label className="checkbox-inline no-margin">
								<input id="include_setld_fa" type="checkbox" onChange = {(e) => {this.handleBorrowerCheckboxClick(e)}}/>
								<span>  Include Settled FA</span>
							</label>
							
							</div>
						</div>
				}		
				</>    		
    		}
    		
    		</div>
)
handleClick =(event) => {
	
	window.open("/borrower/indiv/view/"+this.state.customer_id);
}

handleOnchange =(selectedOptions,type) =>{
	console.log('selectedOptions', selectedOptions)
		this.setState((prevState) => ({
		  selectedValues: {
			...prevState.selectedValues,
			[type]: selectedOptions,
		  },
		}));
	this.props.handlePurposeChange(selectedOptions,type)
}
/*setName = (event) => {
	this.setState({ 
     customer_id: event.target.value
  })
	
}*/
SearchBorrower = () => (

	<>
				{/* <div className="col-md-3">
					<div className="form-group">
						<select className="form-control" id="kyc_status" onChange={(e) => {this.handleChange(e);this.handleDropdownValue(e);this.handleSection1(e);}}>
							<option selected value="">KYC Status</option>
							<option value="">choose</option>
							<option value="pending">Pending</option>
							<option value ="in_progress">In Progress</option>
							<option value ="completed">Completed</option>
						</select>
					</div>
				</div> */}


		{/* <div className="col-md-3">
			<div className="form-group">
				<select className="form-control" id="profile_status" onChange={(e) => {this.handleChange(e);this.handleDropdownValue(e);this.handleSection1(e);}}>
					<option selected value="">Profile Status</option>
					{this.state.profile_status_dd}
				</select>
			</div>
		</div> */}

		<div className="col-md-3 marT-5">
			<div className="">
				<select className="form-control" id="activity_status" onChange={(e) => {this.handleChange(e);this.handleDropdownValue(e);this.handleSection1(e);}}>
					<option selected value="">{this.state.language_json.activity_status}</option>
					{this.state.activity_status_dd}
				</select>
			</div>
		</div>  
		   {this.getMultiSelectOptions('location')?.length >0 &&
				<div className="col-md-3 marT-5">
					<div className="">
					 

							<Select onChange={(e) =>{this.handleOnchange(e,'location')}}
                              value = { this.state.selectedValues?.location??[]}
                              options={this.getMultiSelectOptions('location')} 
							  placeholder={this.state.language_json.location}
							  styles={this.select_custom_styles()}
							  classNamePrefix="my-select" // Add a custom class prefix to target the components
							  isMulti/>

						{/* <select className="form-control" id="location" onChange={(e) => {this.handleChange(e);this.handleDropdownValue(e);this.handleSection1(e);}}>
							<option selected value="">Location</option>
							{this.state.location_dd}

						</select> */}
					</div>
				</div>
			}
		{/* <div className="row	mt-50 pt-4">
			<div className="col-md-3">
				<div className=""></div>
			</div>
		</div> */}
		{this.state.addr_hiearchy?.includes('territory') &&
			<div className="col-md-3 marT-5">
				<div className="">
					<Select onChange={(e) =>{this.handleOnchange(e,'territory')}}
								value = { this.state.selectedValues?.territory??[]}
								options={this.getMultiSelectOptions('territory')} 
								placeholder={this.state.language_json.territory}
								styles={this.select_custom_styles()}
								classNamePrefix="my-select" // Add a custom class prefix to target the components
								isMulti/>
								
					
				</div>
			</div>
		}
		<div className="col-md-3 marT-5">
			<div className="">
			    <Select onChange={(e) =>{this.handleOnchange(e,"district");}}
                              value = { this.state.selectedValues?.district??[]}
                              options={this.getMultiSelectOptions('district')} 
							  placeholder={this.state.language_json.district}
							  styles={this.select_custom_styles()}
							  classNamePrefix="my-select"
							  isMulti/>
							  
				
			</div>
		</div>


      { check_priv('customer' , 'risk_category') &&
			<div className="col-md-3 marT-5">
				<div className="">
					<select className="form-control" id="risk_category" onChange={(e) => {this.handleChange(e);this.handleDropdownValue(e);this.handleSection1(e);}}>
					<option selected value="">{this.state.language_json.risk_category}</option>

						{this.state.risk_category_dd}
					</select>
				</div>
			</div>
		}
		<div className="col-md-3 marT-5">
			<div className="">
				<select className="form-control" id="ownership" onChange={(e) => {this.handleChange(e);this.handleDropdownValue(e);this.handleSection1(e);}}>
					<option selected value="">{this.state.language_json.account_ownership}</option>
					{this.getSelectOptions("acc_ownership", false)}
				</select>
			</div>
		</div>
		<div className="col-md-3 marT-5">
				<div className="w-100">
					<select className="form-control" id="sub_lender_code" onChange={(e) => {this.handleChange(e);this.handleDropdownValue(e);this.handleSection1(e);}}>
						<option selected value="">{this.state.language_json.lender}</option>
						{this.state.sub_lender_code_dd}
					</select>
				</div>
		</div>
		<div className="col-md-3 marT-5">
			<div className="">
				<select className="form-control" id="customer_type" onChange={(e) => {this.handleChange(e);this.handleDropdownValue(e);this.handleSection1(e);}}>
					<option selected value="">Customer Type</option>
					{this.getSelectOptions("customer_type", true, null, null, false, false, 'All')}
				</select>
			</div>
		</div>
		{ Config('distributor_aval_country')?.includes(get('market').country_code)  && 
		<div className="col-md-3 marT-5">
			<div className="">
				<select className="form-control" id="distributor" onChange={(e) => {this.handleChange(e);this.handleDropdownValue(e);this.handleSection1(e);}}>
					<option selected value="">Distributor</option>
					{this.getSelectOptions("distributor", false)}
				</select>
			</div>
		</div>
		}
		<div className="col-md-3 marT-5">
			<Row>
				<Form.Group as={Col}>
					<Form.Label for="closed_profile">{this.state.language_json.include_closed_profile}
					<Form.Check type="checkbox"   id = "closed_profile"  className= "float-left mx-3 " onChange={(e) =>{this.props.onComponentCheckboxclick(e)}} />
					</Form.Label>
				</Form.Group>
			</Row>

		</div>	
		{/* <div className="col-md-3 marT-5">
			<Row>
				<Form.Group as={Col}>
					<Form.Label for="flagged_profile">{this.state.language_json.only_flagged_profiles}
					<Form.Check type="checkbox"  defaultChecked={this.props.profile_status.flagging_profile} id = "flagged_profile"  className= "float-left mx-3 " onChange={(e) =>{this.props.onComponentCheckboxclick(e)}} />
					</Form.Label>
				</Form.Group>
			</Row>

		</div>
		{/* {this.props.mode=='borrower'&&check_priv('customer','watchlist')&& 

		{this.props.mode=='borrower'&&check_priv('customer','watchlist')&& 

		<div className="col-md-3 marT-5">
			<Row>
				<Form.Group as={Col}>
					<Form.Label for="under_watch">Only Profiles Under Watch
					<Form.Check type="checkbox"   id = "under_watch"  className= "float-left  mx-3 " onChange={(e) =>{this.props.search_under_watch(e)}} />
					</Form.Label>
				</Form.Group>
			</Row>
		</div>
		}  */}
		{this.props.under_watch &&
			<div className="col-md-3 marT-5">
				<div className="">
					<select className="form-control" id="under_watch_reason" onChange={(e) => {this.handleChange(e);this.handleDropdownValue(e);this.handleSection1(e);}}>
						<option selected value="">Watch Reason</option>
						{this.getSelectOptions("under_watch")}
					</select>
				</div>
			</div>
		}

				<div className="col-md-3 marT-5">
					<Row>
						<Form.Group as={Col}>
							<Form.Label for="include_unlinked_cust">{this.state.language_json.include_unlinked_cust}
							<Form.Check type="checkbox"   id = "include_unlinked_cust"  className= "float-left mx-3 " onChange={(e) =>{this.props.onComponentCheckboxclick(e)}} />
							</Form.Label>
						</Form.Group>
					</Row>

				</div>	
				<div className="col-12 text-center">
					<h3 className="font-weight-bolder no-margin" style={{padding:10}}>{getValueFromLangJson('or')}</h3>
				</div>
				
				<div className='col-8'>
					<div className='no-margin align-items-center searchInput_height d-flex '>
						<Form.Group as={Col} className={`${this.state.section3} no-padding no-margin`}>
							<Form.Label className='no-margin'>{this.state.language_json.cust_name} / {this.state.language_json.cust_id} / {this.state.language_json.account_num} / {this.state.language_json.mobile_no} / {this.state.language_json.national_id} / {this.state.language_json.msisdn}</Form.Label>
							<Form.Control id="req_parameter" onChange={(e) => { this.handleChange(e);this.handleSection3(e);}} >
							</Form.Control>
						</Form.Group>

						{this.props.mode == 'borrower' &&
							<div>
								<Button style={{margin:'23px 0px 12px 0px'}} className={this.state.section3} id="view_btn"
								onChange={(e) => {this.handleSection3(e)}} onClick={this.props.onSearch('view', 'Individual')}>{getValueFromLangJson('view')}</Button>	&nbsp;
								
								<Button style={{margin:'23px 0px 12px 0px'}} className={this.state.section3} id="view_btn"
								onChange={(e) => {this.handleSection3(e)}} onClick={this.props.onView('edit','Individual',null)}>{getValueFromLangJson('edit')}</Button>
							</div>
						}
					</div>
					</div>

  			  </>
	)

WildCardSearch = () => (
	<div className="col-md-12">

				<div className="col-md-12">
					<div className='row no-margin text-center d-flex align-items-center justify-content-center'>
					{this.props.mode == 'loan' &&
						<div className="col-md-2 mt-4 d-flex align-items-center ">									
							<div>
								<label className="checkbox-inline no-margin  d-flex align-items-center">
								<input id="voided_fa" type="checkbox" onChange = {(e) => {this.handleBorrowerCheckboxClick
									(e)}}/>
								<span style={{paddingLeft:'5px'}}>{getValueFromLangJson("include_void_fa")}</span>
							</label>
						</div>
						</div>
						}
							{this.props.mode == 'loan' &&
									<div className="col-md-2 mt-4 d-flex align-items-center">									
										<div className="">
										
											<label className="checkbox-inline no-margin d-flex align-items-center">
											<input id="exclude_wo_fa" type="checkbox" onChange = {(e) => {this.handleBorrowerCheckboxClick(e)}}/>
											<span style={{paddingLeft:'5px'}}>{getValueFromLangJson("exclude_writt_off_fa")}</span>
										</label>
										
										</div>
									</div>
								}
								{this.props.mode == 'loan' &&this.state.action=='Due_today'&&
									<div className="col-md-2 mt-4 d-flex align-items-center">									
										<div className="">
										
											<label className="checkbox-inline no-margin d-flex align-items-center">
											<input id="include_setld_fa" type="checkbox" onChange = {(e) => {this.handleBorrowerCheckboxClick(e)}}/>
											<span style={{paddingLeft:'5px'}}>{getValueFromLangJson("include_settled_fa")}</span>
										</label>
										
										</div>
									</div>
								}
					</div>
                    <div>
						<h3 className='font-weight-bolder no-margin text-center' style={{padding:10}}>{getValueFromLangJson('or')}</h3>
					</div>
				</div>
				<div className='row no-margin'>
					<div className="col-md-5 no-padding searchInput_height">
						<div className={`form-group ${this.state.section3}`} >
								<Form.Label className='no-margin'>Any {this.state.language_json.cust_id} / {this.state.language_json.account_num} / {this.state.language_json.mobile_no} / {this.state.language_json.national_id} / {this.state.language_json.msisdn}</Form.Label>
								<Form.Control id="req_parameter" onChange={(e) => { this.handleChange(e);this.handleSection3(e);}} >
								</Form.Control>
						</div>
					</div>

					<div className="col-md-1">
						<div className="form-group">
						{this.props.mode == 'loan' &&
							<Button style={{margin:'40px 0px 12px 0px'}} id='view' className={this.state.section2}  
							onChange={(e) => {this.handleSection2(e)}} onClick={this.props.onSearch}>{getValueFromLangJson("view")}</Button>	}
						</div>
					</div>
					{/* {this.props.mode == 'loan' &&
						<div className="col-md-2 mb-4 d-flex align-items-center ">									
							<div className={this.state.section2}>
								<label className="checkbox-inline no-margin mt-3">
								<input id="voided_fa" type="checkbox" onChange = {(e) => {this.handleBorrowerCheckboxClick
									(e)}}/>
								<span> Include Voided FA </span>
							</label>
						</div>
						</div>
						} */}
				</div>

		  	</div>
		
);

updateState=(e)=>{
	this.setState({[e.target.id]:e.target.checked})
	
}

AdvancedSearch = (language_json) => (
	<>
	<div className="col-md-12"> 
		<div className='row'>
			{/* <div className="col-md-4">
				<div className="form-group">
					<label>Master Customer ID</label>
					<input className="form-control" id="master_cust_id" onChange={(e) => {this.handleChange(e);this.handleSection1(e)}}/>
				</div>
			</div> */}
			<div className="col-md-4">
				<div className="form-group searchInput_height">
					<label className='no-margin'>{language_json.reg_from}</label>
					<DatePicker closeOnSelect={true} value={this.state.reg_date_from} dateFormat="DD MMM YYYY" timeFormat={false} required="required"
							onChange={(value) => {this.handleDateChange("reg_date__from",value); this.setState({reg_date_from:value}); this.handleSection1(value);}}/>
				</div>
			</div>
			<div className="col-md-4">
				<div className="form-group searchInput_height">
					<label className='no-margin'>{language_json.reg_to}</label>
					<DatePicker closeOnSelect={true} value={this.state.reg_date_to} dateFormat="DD MMM YYYY" timeFormat={false} required="required"
							onChange={(value) => {this.handleDateChange("reg_date__to",value); this.setState({reg_date_to:value}); this.handleSection1(value);}}/>
				</div>
			</div>
		</div>
	</div>
	<div className="col-md-12"> 
		<div className='row'>
			
			<div className="col-md-4">
				<div className="form-group searchInput_height">
					<label className='no-margin'>{language_json.disabled_from}</label>
					<DatePicker closeOnSelect={true} value={this.state.disabled_from} dateFormat="DD MMM YYYY" timeFormat={false} required="required"
							onChange={(value) => {this.handleDateChange("disabled_from",value); this.setState({disabled_from:value}); this.handleSection1(value)}}/>
				</div>
			</div>
			<div className="col-md-4">
				<div className="form-group searchInput_height">
					<label className='no-margin'>{language_json.disabled_to}</label>
					<DatePicker closeOnSelect={true} value={this.state.disabled_to} dateFormat="DD MMM YYYY" timeFormat={false} required="required"
							onChange={(value) => {this.handleDateChange("disabled_to",value); this.setState({disabled_to:value}); this.handleSection1(value)}}/>
				</div>
			</div>
			{ (this.state.disabled_from && this.state.disabled_to) && <div className='col-md-4'>
					<Row >
						<Form.Group as={Col}>
							<div>
								<Form.Label for="currently_disabled">{language_json.include_currently_disabled}
								<Form.Check type="checkbox"  id = "currently_disabled" disabled={this.state.disabled_check_box} className= "float-left mx-3 " onChange={(e) =>{this.onDiableStausCheckbox(e)}} />
								</Form.Label>
							</div>
							<div>
								<Form.Label for="currently_enabled">{language_json.include_currently_enabled}
								<Form.Check type="checkbox" id = "currently_enabled" disabled={this.state.enabled_check_box} className= "float-left mx-3 " onChange={(e) =>{this.onEnabledStausCheckbox(e)}} />
								</Form.Label>
							</div>
						</Form.Group>
					</Row>
			</div>}
			
		</div>
	</div>

	<div className="col-md-12"> 
		<div className='row'>
			
			<div className="col-md-4">
				<div className="form-group searchInput_height">
					<label className='no-margin'>{language_json.last_audited_from}</label>
					<DatePicker closeOnSelect={true} value={this.state.last_audited_from} dateFormat="DD MMM YYYY" timeFormat={false} required="required"
					    isValidDate={(current) => {
						const today = moment().startOf('day');
						return current.isSameOrBefore(today, 'day');  }} 
						onChange={(value) => {this.handleDateChange("last_audited_from",value); this.setState({last_audited_from:value}); this.handleSection1(value)}}/>
				</div>
			</div>
			<div className="col-md-4">
				<div className= {`form-group searchInput_height ${this.state.last_audited_from  ? "":"disable_div"}`}>
					<label className='no-margin'>{language_json.last_audited_to}</label>
					<DatePicker closeOnSelect={true} value={this.state.last_audited_to} dateFormat="DD MMM YYYY" timeFormat={false} required="required"
					        isValidDate={(current) => {
						    const today = moment().startOf('day');
						    return current.isSameOrBefore(today, 'day');  }} 
							onChange={(value) => {this.handleDateChange("last_audited_to",value); this.setState({last_audited_to:value}); this.handleSection1(value)}}/>
				</div>
			</div>
			
		</div>
	</div>

	<div className="col-md-12" style={{marginTop:5,marginBottom:10}}>
		<div className='row formInputadvanced_search'>
			<div className="col-md-6">
				<div className='advanceSearch_groupBox'>
					<div className="form-check mb-2">
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox" value="" id="not_have_active_agrmnt" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e)}} /> {language_json.dont_have_active_agrmt}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>
					<div className="form-check">
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox"id="have_active_agrmnt" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e)}} /> {language_json.have_active_agrmt}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>
				</div>
			</div>		
			
			<div className="col-md-6">
				<div className='advanceSearch_groupBox'>
					<div className="form-check  mb-2">
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox" id="non_active_cust" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e)}} /> {language_json.non_active_customers}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>
					<div className="form-check">
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox"  id="active_cust" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e)}}/>{language_json.active_customers_30_days}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>
				</div>
			</div>		

			<div className="col-md-6">
				<div className='advanceSearch_groupBox'>
					<div className="form-check  mb-2">
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox"  id="not_have_overdue_loan" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e)}}/> {language_json.dont_have_overdue_fa}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>
					<div className="form-check">
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox"  id="have_overdue_loan" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e)}}/> {language_json.have_overdue_fa}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>
				</div>	
			</div>

			<div className="col-md-6">
				<div className='advanceSearch_groupBox'>
					<div className="form-check  mb-2">
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox"  id="not_have_pending_loan_appl" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e)}}/>{language_json.dont_have_fa_appl}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>
					<div className="form-check">
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox"  id="have_pending_loan_appl" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e)}}/> {language_json.have_fa_appl}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>	
				</div>
			</div>
			<div className="col-md-6">
				<div className='advanceSearch_groupBox'>
					<div className="form-check  mb-2">
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox"  id="not_have_ongoing_loan" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e)}}/> {language_json.dont_have_og_fa}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>
					<div className="form-check">
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox"  id="have_ongoing_loan" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e)}}/> {language_json.have_og_fa}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>
				</div>
			</div>

			<div className="col-md-6">
				<div className='advanceSearch_groupBox'>
					<div className="form-check  mb-2">
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox"  id="do_not_have_score" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e)}}/> {language_json.dont_have_new_cust_score}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>
					<div className="form-check">
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox"  id="have_score" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e)}}/> {language_json.have_new_cust_score}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>	
				</div>
			</div>
			<div className="col-md-6">
				<div className='advanceSearch_groupBox'>
					<div className="form-check  mb-2">
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox"  id="with_tp_acc" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e)}}/> {language_json.with_third_party_account}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>
					<div className="form-check">
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox"  id="without_tp_acc" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e)}}/> {language_json.without_third_party_account}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>
				</div>
			</div>
			
			<div className="col-md-6">
				<div className='advanceSearch_groupBox'>
					<div className={`form-check  `} >
				<div className={`form-check px-0 mb-2`} >
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox"  id="used_float_switch" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e);this.updateState(e)}} disabled={this.state.not_used_float_switch || this.state.without_multiple_enabled_acc  }/> {language_json.used_float_switch}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>
					<div className={`form-check px-0`}>
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox"  id="not_used_float_switch" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e);this.updateState(e)}}  disabled={this.state.used_float_switch || this.state.without_multiple_enabled_acc  }/> {language_json.havent_used_float_switch}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>
					</div>
				</div>
			</div>
			<div className="col-md-6">
				<div className='advanceSearch_groupBox'>
					<div className="form-check mb-2">
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox" id="installed_cust_app" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e);this.updateState(e)}} disabled={this.state.non_installed_cust_app }/> {language_json.installed_cust_app}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>
					<div className={`form-check `}>
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox"  id="non_installed_cust_app" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e);this.updateState(e)}} disabled={(this.state.installed_cust_app || (this.state.actively_using_cust_app))}/> {language_json.not_installed_cust_app}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>
					
					
				</div>
			</div>	
			<div className="col-md-6">
				<div className='advanceSearch_groupBox' >
					
					<div  > 
					<div className={`form-check mb-2`}>
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox"  id="with_multiple_enabled_acc" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e);this.updateState(e)}} disabled={this.state.without_multiple_enabled_acc}/> {language_json.with_multiple_enabled_acc}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>
					<div className={`form-check `}>
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox"  id="without_multiple_enabled_acc" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e);this.updateState(e)}} disabled={(this.state.with_multiple_enabled_acc || (this.state.used_float_switch) || (this.state.not_used_float_switch )) }/> {language_json.without_multiple_enabled_acc}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>
					</div>
					


				</div>
			</div>	
			<div className="col-md-6">
			<div className='advanceSearch_groupBox' >
			    <div className={`form-check `}>
					<div  className={`form-check px-0 mb-2 `} >

						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox"  disabled={this.state.not_actively_using_cust_app || this.state.non_installed_cust_app } id="actively_using_cust_app" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e);this.updateState(e)}}/> {language_json.actively_using_cust_app}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>
					<div className={`form-check px-0 `}>
						<label className="form-check-label no-margin">
						<input className="form-check-input" type="checkbox"  id="not_actively_using_cust_app" onChange={(e) => {this.handleBorrowerCheckboxClick(e);this.handleSection1(e);this.updateState(e)}} disabled={this.state.actively_using_cust_app || this.state.non_installed_cust_app }/> {language_json.not_actively_using_cust_app}
						<span className="form-check-sign">
							<span className="check"></span>
						</span>
						</label>
					</div>
					</div>
				</div>
			</div>

			

			
		</div>

	</div>

	  </>
	  )


handleTextboxValue = (event) =>{
	if(event.target.value != "")
	{
		this.setState({search_by_dropdown_style: "disable_div", search_by_textbox_style : "show_div",section1: "disable_div", cust_div_style: "disable_div"});
	}else{
		this.setState({search_by_dropdown_style: "show_div", cust_div_style: "show_div"});
	}
}

handleCheckboxClick = (event) => {
	if(event.target.checked)
	{
		event.target.value = true;

		this.setState({submitted_field_style: "disable_div", __from: "", __to: "", today: true, disable_date:true});
		this.handleElementReset(this.state.action_date_field + '__from');	
		this.handleElementReset(this.state.action_date_field + '__to');	

	}else{
		event.target.value = false;
		event.target.value = null;
		this.setState({submitted_field_style: "show_div", today: false, disable_date:false});
	}
	this.handleChange(event);
}

handleBorrowerCheckboxClick = (event) =>{
	if(event.target.checked)
	{

		event.target.value = true;
	}
	else
	{
		

		event.target.value = false;
	}
	this.handleChange(event);
}


handleResetClick = (event) => {
	this.setState({ selectedValues:{} ,section3:"show_div",search_by_textbox_style:"show_div",last_audited_from:"",last_audited_to:'',show_from_until:false,
	 __from: "", __to: "", today: false,disable_date:false,
	show_by_today : false,  show_from_until: false, show_approver: false
	
});
	this.handleReset();
	//this.setState({search_btn_style: ""});
	this.setState({search_by_dropdown_style: "show_div"});
	this.setState({search_by_textbox_style: "show_div"});
	this.setState({submitted_field_style: "show_div"});
	this.setState({approved_field_style: "show_div"});
	this.setState({cust_div_style: "show_div"});
	this.setState({loan_id_div_style: "show_div"});


	
}



handleChangeReset = ()=>{
	this.setState({__from:"",__to:"",reg_date_from:"",reg_date_to:"",section1:"show_div",section3:"show_div",section2:"disable_div"});
}
render(){
	    const rm_label = this.props.remove_flow_rm?'rel_mgr':'flow_rel_mgr'
		const language_json = this.state.language_json;
		const adjusted_padding = (this.props.mode == 'loan_appl' ) ?"pt-3":""
	return(
		<>
		{this.state.language_json && 
	
		<form method="get" action="/" className= {`form-horizontal ` } autocomplete="off">
                    <div className={`row no-margin ${adjusted_padding}`}style={{backgroundColor:'#202940'}}>
                      {/* <div className="col-sm-12">  */}
                        {/* <div className="row	"> */}
                          <div className="col-md-3 marT-5">
                            <div className="" style={{position:'relative'}}>
								{this.props.mode == 'borrower' ? null : <font className="text-danger" style={{position:'absolute',top:0,left:0}}>   *  </font>}
								<select className="form-control" title="Status" id="status" onChange={(e) => { this.handleDropdownValue(e);this.handleChange(e);this.handleSection1(e);  }} required>
									<option selected value="">{language_json.status}</option>
									{ this.props.mode == 'loan_appl' && this.state.loan_appl_status_dd}
    								{ this.props.mode == 'loan' && this.state.loan_status_dd}  
          							{ this.props.mode == 'borrower' && this.state.borrower_status_dd}  
								</select>
                            </div>
                          </div>
							<div className="col-md-3 marT-5">
							<div className="">
								<select className="form-control" id="acc_prvdr_code" onChange={(e) => {this.handleChange(e); this.handleDropdownValue(e);this.handleSection1(e);}}>
									<option selected value="">{language_json.account_provider}</option>
									{this.state.acc_prvdr_dd}
								</select>
							</div>
							</div>
                          <div className="col-md-3 marT-5">
                            <div className="">
								<div className="dropdown-container">

							 <Select onChange={(e) =>{this.handleOnchange(e,"flow_rel_mgr_id");}}
                              value = {this.state.selectedValues?.flow_rel_mgr_id??[]}
							  options={this.state.flow_rel_mgr}
							  placeholder={ language_json[rm_label]??'' }
							  styles={this.select_custom_styles()}
							  classNamePrefix="my-select" // Add a custom class prefix to target the components
							  isMulti/>

								</div>
								{/* <select className="form-control" title="Flow Relationship Manager" id="flow_rel_mgr_id" onChange={(e) => {this.handleChange(e);this.handleDropdownValue(e);this.handleSection1(e);}}>
									<option selected value="">Flow Relationship Manager</option>
									{this.state.flow_rel_mgr_dd} 
								</select> */}
                            </div>
                          </div>
						  {/* {(this.props.mode == 'borrower' || this.props.mode == 'loan') &&
                          <div className="col-md-3 marT-5">
                            <div className="">
								<select className="form-control" id="ap_rel_mgr_id" onChange={(e) => {this.handleChange(e); this.handleDropdownValue(e);this.handleSection1(e);}}>
									<option selected value="">AP Relationship Manager</option>
									{this.state.acc_prvdr_rel_mgr_dd}
								</select>
                            </div>
                          </div> } */}
						{/* </div> */}

						  {/* <div className="row	mt-50"> */}
						  	{(this.props.mode == 'loan' || this.props.mode == 'loan_appl')&&
								<div className="col-md-3 marT-5">
									<div className="">								
										<select className="form-control" id="product_id" onChange={(e) => { this.handleChange(e); this.handleDropdownValue(e); this.handleSection1(e);}}>
											<option selected value="">{language_json.product_name}</option>
											{this.state.product_name_list_dd}
										</select>
									</div>
								</div>
							}

							{this.props.mode == 'loan' && (check_priv('loan', 'search_list_view_write_off')) &&
							<>
								{this.props.mode == 'loan' &&
									<div className="col-md-3 marT-5">
										<div className="">
										
											<select className="form-control" id="write_off_status" onChange={(e) => { this.handleChange(e); this.handleDropdownValue(e); this.handleSection1(e);}}>
												<option selected value="">{language_json.write_off_status}</option>
												{this.state.write_off_status_list_dd}
											</select>
										</div>
									</div>
								}
								{/* {this.props.mode == 'loan' &&
									<div className="col-md-2 mt-4 d-flex align-items-center">									
										<div className="">
										
											<label className="checkbox-inline no-margin">
											<input id="exclude_wo_fa" type="checkbox" onChange = {(e) => {this.handleBorrowerCheckboxClick(e)}}/>
											<span>  Exclude Written Off FA</span>
										</label>
										
										</div>
									</div>
								} */}
							</>
							}
							{(this.props.id == 'searchLoans' && this.state.addr_hiearchy?.includes('territory')) &&
							<div className="col-md-3 marT-5">
								<div className="">
								<Select onChange={(e) =>{this.handleOnchange(e,"territory");}}
						   			 value = {this.props.selectedValues?.territory??[]}

									options={this.getMultiSelectOptions('territory')} 
									placeholder={language_json.territory}
									styles={this.select_custom_styles()}
									classNamePrefix="my-select" // Add a custom class prefix to target the components
									isMulti/>
								</div>
							</div>
							}
							{this.props.id == 'searchLoans' &&
							<div className="col-md-3 marT-5">
								<div className="">
								<Select onChange={(e) =>{this.handleOnchange(e,"district");}}
									 value = {this.props.selectedValues?.district??[]}

									options={this.getMultiSelectOptions('district')} 
									placeholder={language_json.district}
									styles={this.select_custom_styles()}
									classNamePrefix="my-select" // Add a custom class prefix to target the components
									isMulti/>
								</div>
							</div>
							}
							{this.props.mode == 'loan' &&
									<div className="col-md-3 marT-5">
										<div className="">
										
											<select className="form-control" id="sub_lender_code" onChange={(e) => { this.handleChange(e); this.handleDropdownValue(e); this.handleSection1(e);}}>
												<option selected value="">{language_json.lender}</option>
												{this.state.sub_lender_code_dd}
											</select>
										</div>
									</div>
							
							}
						{/* </div> */}
						
						
						{(this.props.mode == 'loan' || this.props.mode == 'loan_appl') && 
							<>
								<div className={`${this.state.search_by_dropdown_style} col-md-12 no-padding`} >
									{this.SearchByFromUntil(this.props.mode == 'loan' ? 3 : 0)}
								</div>
								{this.WildCardSearch()}
								
							</>
							}

						{/* <div className="row no-margin"> */}
						{this.props.mode == 'borrower' &&  
							<>
							{this.SearchBorrower()}

							{check_priv("customer", "advance_search_list_view") && 
							<div className='col-12'>
								<div className="col-12 text-center d-flex justify-content-center align-items-center" style={{padding:10}}>
									<h3 className="font-weight-bolder no-margin">{getValueFromLangJson('or')}</h3>
									<h3 className="no-margin" style={{paddingLeft:15, cursor:'pointer'}} onClick={this.toggleAdvSearch}>{language_json.advanced_search}</h3>
								</div>
								{/* <div style={{paddingTop:15,paddingBottom:15}}>
									<Link to="#" className="btn-std text-black" style={{backgroundColor:'#fff',color:"#000", borderRadius:3}} onClick={this.toggleAdvSearch}
									>Advanced Search<i class={`${this.state.adv_search ? "fa-angle-up" : "fa-angle-down" } fa-solid`} style={{paddingLeft:5}}></i></Link>
								</div> */}
								{/* <h1 className='no-margin text-white' style={{fontSize:20}} onClick={this.toggleAdvSearch}>Advanced Search</h1><i class="fa-solid fa-angle-up"style={{paddingLeft:5}}></i> */}
								{this.state.adv_search  && this.AdvancedSearch(language_json)}
							</div>	
							}						

							</>
							}

						{/* </div> */}

						{/* {this.props.mode == 'borrower' &&
							<div className='col-12'>
								<div className="col-12 text-center">
									<h3 className="font-weight-bolder no-margin" style={{padding:5}}>OR</h3>
								</div>
								<div style={{paddingTop:15,paddingBottom:15}}>
									<Link to="#" className="btn-std text-black" style={{backgroundColor:'#fff',color:"#000", borderRadius:3}} onClick={this.toggleAdvSearch}
									>Advanced Search<i class={`${this.state.adv_search ? "fa-angle-up" : "fa-angle-down" } fa-solid`} style={{paddingLeft:5}}></i></Link>
								</div>
							</div>
						} */}

						<div className="col-12 text-center mt-2" style={{paddingBottom:15,paddingTop:5}}>						
							<Button type="reset" variant="secondary" onClick={this.handleResetClick} >{getValueFromLangJson("reset")}</Button>	&nbsp;
							<Button id="searchbtn" varient="info" disabled={this.props.disable_btn} className={`btn-primary`} onClick={this.props.mode == 'borrower' ? this.props.onSearch('search', null, true) : this.props.onSearch}>{getValueFromLangJson('search')}</Button> &nbsp;										
						</div>

                      </div>
                    {/* </div> */}
                  </form>

					}

		  </>

		);
    }
}

const mapStateToProps = (state) => {
	return {
    remove_flow_rm : state.common_slice?.brand_info?.footer
	};
  };


export default connect(mapStateToProps)(SearchLoanCriteria);

