import { useQueryClient } from "@tanstack/react-query";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { connect_db_for_bridged_status } from "../../actions/call_log_actions";
import {
  CALL_INCOMING_OR_OUTGOING,
  CALL_NUMBER,
  CALL_STATUS_TYPE,
  CUST_NAME,
  INCOMING,
  IS_AT_CALL_INITIATED,
  OUTGOING,
} from "../../consts";
import { get } from "../../helpers/storage_helper";
import SoftPhoneAndCallLogUI from "../../UI/softPhone/components/softPhoneAndCallLogUI";
import useSoftPhoneMethods from "./hooks/useSoftPhoneMethods";
import useUpdateDocTitle from "./hooks/useUpdateDocTitle";
import { initializeAftricasTalking } from "./intializeAT";
import {
  OPEN_CALL_STATUS_MODAL_DISPATCH,
  SOFT_PHONE_LOG_MODAL,
  SoftPhoneCtx,
  SoftPhoneState,
  TRIGGER_CALL_LOG_MODAL_DISPATCH,
} from "./softPhoneCtx";
import softPhoneReducer from "./softPhoneReducer";

const initialState: SoftPhoneState = {
  isSoftPhoneClientReady: false,
  isATsInstanceBelongsToCurrentTab: false,
  showCallLogModal: false,
  showCallStatusModal: false,
  isCallInitiatedFromCurrentTab: false,
  callLogDetails: {
    phoneNumber: "",
    custName: "",
    callLogForIncomingOrOutGoing: "",
    callLogForScreen: "",
    churnOrDeliquencyId: "",
    callStartedTime: "",
  },
  startTimer: false,
  callState: {
    status: "",
  },
};

const SoftPhoneCtxProvider = ({
  children,
  atClient,
  broadCastChannelForDocTitleChange,
  canCreateAtInstance,
  instanceId,
  hasCallPriv,
}: {
  children: ReactNode;
  atClient: any;
  broadCastChannelForDocTitleChange: BroadcastChannel;
  canCreateAtInstance: boolean;
  instanceId: null | number;
  hasCallPriv: boolean;
}) => {
  // reducer
  const [softPhoneState, dispatch] = useReducer(softPhoneReducer, initialState);

  // hook
  const queryClient = useQueryClient();

  // state
  const [isCallDisconnected, setisCallDisconnected] = useState(false);
  const [isCallConnected, setisCallConnected] = useState(false);

  // const
  const isAtCallInitiated = get(IS_AT_CALL_INITIATED);
  const callStatusType = get(CALL_STATUS_TYPE);
  const callNumber = get(CALL_NUMBER);
  const name = get(CUST_NAME);
  const callIsIncomingOrOutgoing = get(CALL_INCOMING_OR_OUTGOING);

  // sofphone broadcast channel
  const broadCastChannel = useMemo(
    () => new BroadcastChannel("Soft_Phone_Bc"),
    []
  );

  // bc register cb event
  broadCastChannel.onmessage = (event) => channelMessageHandler(event);

  // effects
  useEffect(() => {
    if (atClient && canCreateAtInstance && instanceId) {
      // ensures the initialization occurs only one time
      initializeAftricasTalking({
        atClient,
        dispatch,
        setisCallDisconnected,
        intiateCallCb,
        setisCallConnected,
        changeSoftPhoneClientReadyStatus,
      });
    }
  }, [atClient, canCreateAtInstance, instanceId]);

  // once the call disconnected and connected, the history details and history lists are refetched via react query
  // this effect will executed during the call connection and call disconnection
  useEffect(() => {
    const isEndUserAnsweredTheCall =
      isCallConnected && softPhoneState.callState.status === OUTGOING;
    if (isCallDisconnected) {
      queryClient.invalidateQueries({
        queryKey: ["softphone_history_list"],
      });
      queryClient.invalidateQueries({
        queryKey: ["soft_phone_history"],
      });
      queryClient.invalidateQueries({
        queryKey: ["prev_pend_call_logs"],
      });
      // for stability have added 3s time delay to invalidate the below query,
      // if the event not updated the api before 3 sec,
      // then we have called this query on tab focus,
      // so that we can get those missed_calls data during window focus
      setTimeout(
        () =>
          queryClient.invalidateQueries({
            queryKey: ["missed_calls"],
          }),
        3000
      );
      // after call hangup need to update screen data in db
      cancelCall();
      const screen = softPhoneState.callLogDetails.callLogForScreen;
      const churnOrDeliquencyId =
        softPhoneState.callLogDetails.churnOrDeliquencyId;
      updateCallScreenCb({
        screen,
        churnOrDeliquencyId,
      });
    } else if (isEndUserAnsweredTheCall) {
      attendCall({ isCurrentUserAnsweringTheCall: false });
      updateDbOnBridgedStatus();
    }
  }, [isCallDisconnected, isCallConnected, softPhoneState]);

  // during any ongoing or incoming call we will show the call status modal on all the tabs
  // if the user refreshes any one of the tab need to show the proper call status modal on that refreshed page again
  // so that we have kept one key in localstorage api and depends upon that the call status modal will shown
  useEffect(() => {
    if (isAtCallInitiated)
      dispatch({
        type: OPEN_CALL_STATUS_MODAL_DISPATCH,
        payload: {
          showCallStatusModal: true,
          callStatus: callStatusType,
          phoneNumber: callNumber,
          callLogForIncomingOrOutGoing: callIsIncomingOrOutgoing,
        },
      });
    updateCustomerName({ custName: name });
  }, [
    isAtCallInitiated,
    callStatusType,
    callNumber,
    callIsIncomingOrOutgoing,
    name,
  ]);

  // if the call status modal is opened in any of the window, while closing the instance created tab all the call status modal should be closed,
  // that is achieved by calling the cancelCall function,
  // this will executed once the user closes or refresh the instance created tab
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = true;
      // if (softPhoneState.isATsInstanceBelongsToCurrentTab) {
      cancelCallCb();
      closeCallLogModal(); // this will close the call log modal in all windows during the window reloading
      broadCastChannel?.postMessage({
        type: "HangupCall",
      });
      // }
    };

    if (canCreateAtInstance) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      if (canCreateAtInstance) {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      }
    };
  }, [canCreateAtInstance]);

  // broadcast events cb
  const channelMessageHandler = (event: any) => {
    if (event.data.type === "OpenCallStatusModal") {
      // this will show the call status modal on all tabs for incoming and outgoing
      dispatch({
        type: OPEN_CALL_STATUS_MODAL_DISPATCH,
        payload: {
          showCallStatusModal: true,
          callStatus: event.data.payload.callStatus,
          phoneNumber: event.data.payload.phoneNumber,
          callLogForIncomingOrOutGoing: event.data.payload.callStatus,
        },
      });
      if (event.data.payload.callStatus === INCOMING) {
        // updating all incoming calls as for softphone log modal
        // on the current tab the updateCallLogScreenHandler already called but for other tabs we need to update these data
        // so via channel also we again called this function
        updateCallLogScreenHandler({
          callLogForScreen: SOFT_PHONE_LOG_MODAL,
          churnOrDeliquencyId: "",
        });
      }
    } else if (event.data.type === "HangupCall") cancelCallCb();
    else if (event.data.type === "AttendCall")
      attendCallCb({
        isCurrentUserAnsweringTheCall: true,
      });
    else if (event.data.type === "CloseCallLogModal")
      dispatch({
        type: TRIGGER_CALL_LOG_MODAL_DISPATCH,
        payload: {
          showCallLogModal: event.data.payload.showCallLogModal,
          callLogForIncomingOrOutGoing:
            event.data.payload.callLogForIncomingOrOutGoing,
          phoneNumber: event.data.payload.phoneNumber,
          callLogForScreen: event.data.payload.callLogForScreen,
          callStartedTime: event.data.payload.callStartedTime,
        },
      });
    else if (event.data.type === "OpenCallLogModalOnParticularTab")
      dispatch({
        type: TRIGGER_CALL_LOG_MODAL_DISPATCH,
        payload: {
          showCallLogModal: softPhoneState.isCallInitiatedFromCurrentTab,
          callLogForIncomingOrOutGoing:
            event.data.payload.callLogForIncomingOrOutGoing,
          phoneNumber: event.data.payload.phoneNumber,
          callLogForScreen: event.data.payload.callLogForScreen,
          callStartedTime: event.data.payload.callStartedTime,
        },
      });
    else if (
      event.data.type ===
      "CallIsInitiatedSomeWhereAmongTheOpenedTabSoMakeCallFromInstanceBelongsTab"
    ) {
      // used to updated the call log screen on all the opened tabs
      updateCallLogScreenHandler({
        callLogForScreen: event.data.payload.callLogForScreen,
        churnOrDeliquencyId: event.data.payload.churnOrDeliquencyId,
      });
      // the call will be initiated if the instance is created from the current tab
      if (softPhoneState.isATsInstanceBelongsToCurrentTab)
        initiateCallAndCbTriggerHandler(event.data.payload.phoneNumber);
    } else if (event.data.type === "UpdateCallStartTimeOnOtherWindows") {
      updateCallStartTimeOnOtherTabs();
    } else if (event.data.type === "UpdateCustName") {
      updateCustomerName({ custName: event.data.payload.custName });
    }
  };

  // connect to db once the bridged status success
  const updateDbOnBridgedStatus = useCallback(async () => {
    // getting the cached data from the query client,
    // it is safe to take this data at here,
    // because before the call of this function, the cs device details should be fetched and cached by react query
    const csDeviceDetails = queryClient.getQueryData([
      "cs_device_details",
    ]) as any;
    const req = {
      type: "webrtc",
      direction: "Inbound", // static key for this api
      callSessionState: "Bridged",
      callerNumber: csDeviceDetails.data.number,
      destinationNumber: csDeviceDetails.data.destinationNumber,
      clientDialedNumber: softPhoneState.callLogDetails.phoneNumber,
    };
    await connect_db_for_bridged_status(req);
  }, [softPhoneState]);

  // if the user duplicated 2 cs_management/soft_phone pages, the active instance is in the recent tab
  // in that case need to change the status in the first tab
  // this channel is used to change the document's title,
  broadCastChannelForDocTitleChange.onmessage = (_event) =>
    changeSoftPhoneClientReadyStatus(false);

  // custom hooks
  useUpdateDocTitle({ softPhoneState, canCreateAtInstance, hasCallPriv });
  const {
    updateCallScreenCb,
    checkUserHasAnyPendingCallLog,
    initiateCall,
    intiateCallCb,
    initiateCallAndCbTriggerHandler,
    cancelCall,
    cancelCallCb,
    attendCall,
    attendCallCb,
    closeCallLogModal,
    triggerCallLogModalOnParticularWindow,
    closeCallLogModalOnParticularWindow,
    changeSoftPhoneClientReadyStatus,
    updateCallLogScreenHandler,
    updateCallStartTimeOnOtherTabs,
    updateCustomerName,
  } = useSoftPhoneMethods({
    dispatch,
    softPhoneState,
    atClient,
    broadCastChannel,
    setisCallConnected,
    setisCallDisconnected,
  });

  return (
    <SoftPhoneCtx.Provider
      value={{
        softPhoneState,
        dispatch,
        methods: {
          initiateCall,
          cancelCall,
          attendCall,
          closeCallLogModal,
          triggerCallLogModalOnParticularWindow,
          changeSoftPhoneClientReadyStatus,
          closeCallLogModalOnParticularWindow,
          checkUserHasAnyPendingCallLog,
        },
      }}
    >
      {children}
      <SoftPhoneAndCallLogUI />
    </SoftPhoneCtx.Provider>
  );
};

export default SoftPhoneCtxProvider;
