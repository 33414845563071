import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function CustomModal(
  {show,onClose,header,body , footer ,size,alignItems}:
  {show:boolean,  
  header: string |  React.ReactNode,
  body: React.ReactNode,
  onClose?:()=>void,
  footer?:React.ReactNode,
  size?:'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
  alignItems?:string
  }) {
  
  return (
    <React.Fragment>
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={show}
      fullWidth 
      maxWidth={size || "xl"} 
      sx={{
        "& .MuiDialog-container": {
          alignItems: alignItems ||"flex-start", // Aligns the modal at the top
        },
        '& .MuiDialog-paper': {
          width: size || '1000px', // Custom width
          maxWidth: size || '1000px', // Overrides the default maxWidth behavior
        },
        "& .MuiPaper-root": {
          backgroundImage:` none !important`,
          backgroundColor:'#111936 !important',
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 , color:'white'}} id="customized-dialog-title">
        <Box sx={{textAlign:'center',fontSize:'1.4rem'}}>{header}</Box>
        
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 15,
            color: theme.palette.grey[500],
            fontSize:'20px'
          })}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        {body}
      </DialogContent>
      <DialogActions>
        {footer}
      </DialogActions>
    </Dialog>
  </React.Fragment>
  );
}
