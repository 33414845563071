import CallIcon from "@mui/icons-material/Call";
import ChatIcon from "@mui/icons-material/Chat";
import HelpIcon from "@mui/icons-material/Help";
import { Box } from "@mui/material";
import moment from "moment";
import { useMemo } from "react";
import { ERROR_INFO } from "../../../consts";
import {
  HeaderVarientH3Typo,
  HeaderVarientH4Typo,
} from "../../CommonComponent/typography";
import { useGetPrevCallInteractionQuery } from "../hooks/useGetPrevCallInteractionQuery";
import IconTypeTextField from "./iconTypeTextField";

const PrevCallInteractionsUI = ({ custId }: { custId: string | undefined }) => {
  if (!custId) return <></>;
  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#4f5167",
        m: 2,
        borderRadius: "5px",
        height: "calc(76vh - 32px)",
        overflowY: "auto",
      }}
    >
      <HeaderVarientH3Typo
        text={"Previous Interaction"}
        overRideStyles={{ color: "#fff", textAlign: "center" }}
      />
      <PrevCallInteractionsLists custId={custId} />
    </Box>
  );
};

export default PrevCallInteractionsUI;

const PrevCallInteractionsLists = ({
  custId,
}: {
  custId: string | undefined;
}) => {
  // query hook
  const { data, isLoading, isError, isFetched } =
    useGetPrevCallInteractionQuery({
      custId,
    });

  // const
  const iconStyle = useMemo(() => ({ width: "22px", height: "22px" }), []);

  if (isLoading)
    return (
      <HeaderVarientH4Typo
        overRideStyles={{ color: "#fff", textAlign: "center", py: 6 }}
        text={"Fetching cust details please wait..."}
      />
    );

  if (isError && !data?.data)
    return (
      <HeaderVarientH4Typo
        overRideStyles={{ color: "#fff", textAlign: "center", py: 6 }}
        text={ERROR_INFO}
      />
    );

  if (isFetched && data?.data.length === 0)
    return (
      <HeaderVarientH4Typo
        overRideStyles={{ color: "#fff", textAlign: "center", py: 6 }}
        text={"No results found!"}
      />
    );

  const callInteractionsList = data?.data;

  return (
    <>
      {callInteractionsList && (
        <>
          {callInteractionsList.map((c, idx) => (
            <Box
              key={idx}
              sx={{
                backgroundColor: "#282d41",
                p: 2,
                my: 2,
                borderRadius: "5px",
                color: "#fff",
              }}
            >
              {/* caller name and time UI */}
              <IconTypeTextField
                icon={<CallIcon sx={iconStyle} />}
                pairTextUI={
                  <>
                    <HeaderVarientH4Typo text={c.caller_name} />
                    <HeaderVarientH4Typo
                      overRideStyles={{ color: "#D9D9D9 !important" }}
                      text={`${
                        c?.call_date
                          ? moment(c?.call_date).format("DD MMM YYYY")
                          : "-"
                      } | ${
                        c?.call_dialed_time
                          ? `${c?.call_dialed_time} ${moment(
                              c?.call_dialed_time,
                              "hh:mm:ss"
                            ).format("A")}`
                          : "-"
                      }`}
                    />
                  </>
                }
              />
              <hr />
              {/* reason UI */}
              <IconTypeTextField
                icon={<HelpIcon sx={iconStyle} />}
                pairTextUI={
                  <HeaderVarientH4Typo text={c?.call_purpose ?? "-"} />
                }
              />
              <hr />
              {/* remarks UI */}
              <IconTypeTextField
                icon={<ChatIcon sx={iconStyle} />}
                pairTextUI={<HeaderVarientH4Typo text={c?.remarks ?? "-"} />}
              />
            </Box>
          ))}
        </>
      )}
    </>
  );
};
