import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import { AiOutlineIdcard,AiOutlineMobile,AiFillShop ,AiFillEdit} from "react-icons/ai";
import {get, Config} from '../../../helpers/storage_helper';
import { viewLead ,UpdateFlags,addComments,auditKycVerification,auditKyRetrievals,auditKycFileUpload, submitCallLog,RejectCallLog,auditkycModify,updateAccPrvdrCode} from '../../../actions/lead_actions';
import {capitalize_first_letter,getLabel,get_dir_path} from '../../../helpers/common_helper';
import DisplayImage from '../../common/component/DisplayImage';
import {dd_value} from '../../../actions/common_actions';
import { connect } from 'react-redux';

class RekycPhotoVerificationComponent extends FlowComponent{
    constructor() {
        super();
        this.uga_owner_address_alignment=["parish","sub_county","county","village","district","region","location","landmark"]
        this.rwa_owner_address_alignment=["province","district","sector","cell","village","location","territory","landmark"]
       
    }

    audit_section_spinner(){
        return(
          <div class="audit_spinner d-flex align-items-center" style={{margin:'20vh 0px'}}>
          <span class="level level-1"></span>
          <span class="level level-2"></span>
          <span class="level level-3"></span>
          <span class="level level-4"></span>
          <span class="level level-5"></span>
          <span class="level level-6"></span>
        </div>
        )
      }
      checkbox_spinner(){
        return(
          <div class="d-flex col-lg-10 py-2 px-5 mt-4 justify-content-center ml-5">
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
         </div>
        )
      }


      rekyc_modify_checking(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null){
        if(addl_key !=null && idx ==null ){
          if(((cust_reg_json[json_type][json_key][addl_key][key].hasOwnProperty("collected")) || (cust_reg_json[json_type][json_key][addl_key][key].hasOwnProperty("collected_n_modified")))){
            return this.get_class_name(cust_reg_json,"collected")
          }
          else{
            return false;
           }
        }

       else if(idx!=null){
          if(( (cust_reg_json[json_type][json_key][addl_key][idx][key].hasOwnProperty("collected")) || (cust_reg_json[json_type][json_key][addl_key][idx][key].hasOwnProperty("collected_n_modified")))) {
            return  this.get_class_name(cust_reg_json,"collected")
          }
          else{
            return false;
           }
        }
        else{
          if(((cust_reg_json[json_type][json_key][key].hasOwnProperty("collected"))  || (cust_reg_json[json_type][json_key][key].hasOwnProperty("collected_n_modified")))){
            return  this.get_class_name(cust_reg_json,"collected")
          }
          else{
            return false;
         }
        }
       
      }

      get_class_name(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null){
        if(json_type=="collected_modified"){
          return " COLLECTED_MODIFIED";
      
         }else if(json_type == "collected"){
          return "Re-KYCed"
         }
         
         else{
          return "MODIFIED"
         }
        }

    render(){
      const audit_kyc = this.props.audit_kyc && this.props.audit_kyc[this.props.photosverification.active_key]
        return(
            <div>
           
           {this.props.photosverification.audit_kyc_wizard_menus.kyc_photos &&
            <div className='row no-margin justify-content-center'> 
              {this.props.photosverification.View_lead_section_loader ? <div className="d-flex justify-content-center">{this.audit_section_spinner()}</div>:

            <>
            <div className='col-lg-12 py-3 mt-5 ' style={{background:' #01062c'}}>
              <h2 className='text-white text-center'>
              <b> {`${this.props.photosverification.cust_reg_json.same_as_owner_person ? "4" :"4"} - Customer Face Verification `}</b> 

            
              </h2>
              <h2 className=' d-flex mt-1 text-center justify-content-center align-items-center' ><img src='/img/pending_with_rm.png' height={'50'}/> <b className='ml-2 align-self-cente' style={{color:'#F3EA0B'}}> Pending with {this.props.photosverification.active_section && (this.props.photosverification.active_section.pending_with=="rm"?'RM':'YOU')}</b></h2>

             </div>
             {this.props.photosverification&&
             <>
        

             <>
             

             <div className='col-lg-10 py-1 px-5' >
             <h2 className='text-white no-margin py-2 col-12 audit_kyc_text text-center py-4'> <b>PHOTO ID PROOF  </b> </h2>


             <div className="row">
              <div className="col-md-12 no-padding col-lg-6">
              <h2 className='text-white p-2 px-3  old_json_text_bg_color text-center'> <b>As Per KYC</b></h2>
              </div>
              <div className="col-md-12 col-lg-6">
                {(this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","id_proof","photo_id_proof"))  || (this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","kyc_photos","photo_pps")) || (this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","kyc_photos","photo_selfie") ) ?
                   <h2 className='text-white p-2 px-3  new_re_kyc_text_bg_color text-center'> <b>As Per Re-KYC</b></h2>
                  : <h2 className='text-white p-2 px-3  old_json_text_bg_color text-center'> <b>As Per Re-KYC</b></h2>
                }

              </div>
             </div>

            <div className=' row justify-content-center'>

                
                
               <div className='col-md-12 my-3 col-lg-6 old_json_text_bg_color py-3 pt-4' style={{background:'#202940'}} >

                <div className='' style={{background:'#202940'}}>
               <DisplayImage  rotate={false} lead_id={this.props.lead_id} image_path={get_dir_path(this.props.photosverification.old_cust_reg_json.id_proof?.photo_id_proof.path,this.props.photosverification.old_cust_reg_json.id_proof?.photo_id_proof.value)} style={{height: '500px',width:'100%'}} file_name={this.props.photosverification.old_cust_reg_json.id_proof?.photo_id_proof.value} />
               </div>
              
               </div>
               <div className='col-md-12 my-3 col-lg-6 '>
             
               <fieldset className= {`${this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","id_proof","photo_id_proof") ? ` ${this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","id_proof","photo_id_proof")+"_field_set"}`:"unmodified_field_set" } ` } style={{background:'#223b56'}}>
              {this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","id_proof","photo_id_proof") ? <legend className={`legend_rekyc ${this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","id_proof","photo_id_proof")+"_text_color"} `}> 
              <b> {this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","id_proof","photo_id_proof") } </b></legend> :""}

                  <div className='col-md-12 no-padding my-2 px-3 col-lg-12' >
                <div className=' px-3 pb-4' >
                <DisplayImage rotate={true} file_of={"photo_id_proof"} actual_file={"photo_id_proof"} addl_file_name={null} lead_id={this.props.lead_id} image_path={get_dir_path(this.props.photosverification.new_cust_reg_json.id_proof.photo_id_proof.path,this.props.photosverification.new_cust_reg_json.id_proof.photo_id_proof.value)} style={{height: '500px',width:'100%'}} file_name={this.props.photosverification.new_cust_reg_json.id_proof.photo_id_proof.value} />

                </div>
                </div>
              

                </fieldset>

               </div>

               { this.props.photosverification.active_section &&
               <div>
                   {this.props.photosverification.active_section.verify &&
                     <div class="closedStamp ">
                     <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                     </div>   
                   }
               </div>
              } 
            </div>


            {/* <hr/> */}

            <div className="row">
            <h2 className='text-white no-margin py-2 col-12 audit_kyc_text text-center py-4'> <b>CUSTOMER PHOTO</b> </h2>

              <div className="col-md-12 no-padding col-lg-6">
              <h2 className='text-white p-2 px-3  old_json_text_bg_color text-center'> <b>As Per KYC</b></h2>

              </div>
              <div className="col-md-12 col-lg-6">
              <h2 className= {`text-white p-2 px-3  text-center  ${ (this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","kyc_photos","photo_pps")) ?"new_re_kyc_text_bg_color":"old_json_text_bg_color"}`}> <b>As Per Re-KYC</b></h2>

              </div>
             </div>

            <div className=' row justify-content-center'>
               <div className='col-md-12 my-3 col-lg-6 old_json_text_bg_color py-3 pt-4' style={{background:'#202940'}}>
               <div className='' >
               <DisplayImage  lead_id={this.props.lead_id} image_path={get_dir_path(this.props.photosverification.old_cust_reg_json.kyc_photos?.photo_pps.path,this.props.photosverification.old_cust_reg_json.kyc_photos?.photo_pps.value)} style={{height: '500px',width:'100%'}} file_name={this.props.photosverification.old_cust_reg_json.kyc_photos?.photo_pps.value} view_lead={()=>this.props.view_lead()} />
               </div>
              
               </div>
               <div className='col-md-12 my-3 col-lg-6 '>
               
               <fieldset className= {`h-100 ${this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","kyc_photos","photo_pps") ? ` ${this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","kyc_photos","photo_pps")+"_field_set"}`:"unmodified_field_set" } ` } style={{background:'#223b56'}}>
              {this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","kyc_photos","photo_pps") ? <legend className={`legend_rekyc ${this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","kyc_photos","photo_pps")+"_text_color"} `}> 
              <b> {this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","kyc_photos","photo_pps") } </b></legend> :""}

              <div className='col-md-12 no-padding  px-3 col-lg-12' >
                <div className=' px-3 pb-3 my-2' >
                <DisplayImage rotate={true} file_of={"photo_pps"} actual_file={"pps"} addl_file_name={null}  lead_id={this.props.lead_id} image_path={get_dir_path(this.props.photosverification.new_cust_reg_json.kyc_photos.photo_pps.path,this.props.photosverification.new_cust_reg_json.kyc_photos.photo_pps.value)} style={{height: '500px',width:'100%'}} file_name={this.props.photosverification.new_cust_reg_json.kyc_photos.photo_pps.value} view_lead={()=>this.props.view_lead()} />

                </div>
                </div>
              
               </fieldset>

               </div>

               
            </div>
            {/* <hr/> */}

            <div className="row">
            <h2 className='text-white no-margin py-2 col-12 audit_kyc_text text-center py-4'> <b>SELFIE PHOTO</b>  </h2>

              <div className="col-md-12 no-padding col-lg-6">
              <h2 className='text-white p-2 px-3  old_json_text_bg_color text-center'> <b>As Per KYC</b></h2>

              </div>
              <div className="col-md-12 col-lg-6">
              <h2 className= {`text-white p-2 px-3  text-center  ${ (this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","kyc_photos","photo_selfie")) ?"new_re_kyc_text_bg_color":"old_json_text_bg_color"}`}> <b>As Per Re-KYC</b></h2>

              </div>
             </div>

            <div className=' row justify-content-center'>
               <div className='col-md-12 my-3 col-lg-6 py-3 pt-4 old_json_text_bg_color' style={{background:'#202940'}}>
               <div className='' >
               <DisplayImage   view_lead={()=>this.props.view_lead()} lead_id={this.props.lead_id} image_path={get_dir_path(this.props.photosverification.old_cust_reg_json.kyc_photos?.photo_selfie.path,this.props.photosverification.old_cust_reg_json.kyc_photos?.photo_selfie.value)} style={{height: '500px',width:'100%'}} file_name={this.props.photosverification.old_cust_reg_json.kyc_photos?.photo_selfie.value} />

               </div>
               </div>
               <div className='col-md-12 my-3 col-lg-6 '>
            
               <fieldset className= {`h-100 ${this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","kyc_photos","photo_selfie") ? ` ${this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","kyc_photos","photo_selfie")+"_field_set"}`:"unmodified_field_set" } ` } style={{background:'#223b56'}}>
              {this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","kyc_photos","photo_selfie") ? <legend className={`legend_rekyc ${this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","kyc_photos","photo_selfie")+"_text_color"} `}> 
              <b> {this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","kyc_photos","photo_selfie") } </b></legend> :""}

          

               <div className='col-md-12 no-padding  px-3 col-lg-12' >
                <div className='px-3 pb-3 ' >
                <DisplayImage  rotate={true} file_of={"photo_selfie"} actual_file={"selfie"} addl_file_name={null}  view_lead={()=>this.props.view_lead()} lead_id={this.props.lead_id} image_path={get_dir_path(this.props.photosverification.new_cust_reg_json.kyc_photos?.photo_selfie.path,this.props.photosverification.new_cust_reg_json.kyc_photos?.photo_selfie.value)} style={{height: '500px',width:'100%'}} file_name={this.props.photosverification.new_cust_reg_json.kyc_photos?.photo_selfie.value} />

                </div>
                </div>
               </fieldset>
               </div>

               
            </div>
            <br/>
            <h2 className='rekyc_note text-center'> <b>NOTE</b> : Compare the existing data (as per FLOW) with the current data (as entered by RM). </h2>
            </div>
             
            

             <div className='col-lg-10 py-2 px-5 mt-4'>
              <div className='row m-0'>
                <div className='col-6'></div>
                <div className='col-6'>     
                <form className={`${(this.props.photosverification.active_section.verify || (this.props.photosverification.active_section.to_reassign==true) )? "disable_div":''}  ${ (((this.props.photosverification.responseData.audited_by== get("user_id")) || (this.props.photosverification.responseData.audited_by==null ))&&(this.props.photosverification.active_section.pending_with !="rm")) ?"":'disable_div'}`}>
                    <div className='d-flex align-items-center row m-0'>
                    <input type="checkbox" id="vehicle1" className='pointer col-1' checked={audit_kyc &&audit_kyc.face_clear_nd_visible && true}  style={{height:'19px',width:'20px'}} name="vehicle1" value="Bike" onChange={(e)=>this.props.checkbox_verification(e.target.checked,"face_clear_nd_visible")}/>
                    <label className='no-margin pointer px-0 audit_kyc_text col-11'  for="vehicle1">The face of the customer is clear and visible.</label><br/><br/>
                    </div>
                    <div className='d-flex align-items-center row m-0'>
                    <input type="checkbox" id="potrait" className='pointer col-1' checked={audit_kyc && audit_kyc.portrait_mode && true}  style={{height:'19px',width:'20px'}} name="potrait" onChange={(e)=>this.props.checkbox_verification(e.target.checked,"portrait_mode")}/>
                    <label className='no-margin px-0 audit_kyc_text pointer col-11'  for="potrait">The photo is in portrait mode. </label><br/><br/>
                    </div>
                    <div className='d-flex row m-0 '>
                    <input type="checkbox" id="vehicle3" className='pointer col-1' checked={audit_kyc && audit_kyc.passport_photo_match && true}  style={{height:'19px',width:'20px'}} name="vehicle3" value="Bike" onChange={(e)=>this.props.checkbox_verification(e.target.checked,"passport_photo_match")}/>
                    <label className='no-margin px-0 audit_kyc_text pointer col-11'  for="vehicle3" style={{lineHeight:'23px'}}>{`The customer face in passport size photo matches with the ${getLabel('national_id')}.`}</label><br/><br/>
                    </div>
                    <div className='d-flex  row m-0'> 
                    <input type="checkbox" id="vehicle4" className='pointer col-1' checked={audit_kyc && audit_kyc.selfie_photo_match && true}  style={{height:'18px',width:'20px'}} name="vehicle4" value="Bike" onChange={(e)=>this.props.checkbox_verification(e.target.checked,"selfie_photo_match")}/>
                    <label className='no-margin px-0 audit_kyc_text pointer col-11'  for="vehicle4">{`The customer face in selfie photo matches with the ${getLabel('national_id')}.`}</label><br/><br/>
                    </div>

                </form>
                </div>
              </div>
           
                    
             </div>
              </>
              
              

              </>}
              </>}
            </div>
            }

            {this.props.photosverification.audit_kyc_wizard_menus.shop_photo &&
             <>
             {this.props.photosverification.new_cust_reg_json &&
             <div className='row no-margin justify-content-center'>
              {this.props.photosverification.View_lead_section_loader ? <div className="d-flex justify-content-center">{this.audit_section_spinner()}</div>:

              <>
             <div className='col-lg-12 py-3 mt-5 ' style={{background:' #01062c'}}>
               <h2 className='text-white text-center'>
               <b> {`${this.props.photosverification.cust_reg_json.same_as_owner_person ? "5" :"6"} - Establishment Photo Verification`}</b> 

              
               </h2>
               <h2 className=' d-flex mt-1 text-center justify-content-center align-items-center' ><img src='/img/pending_with_rm.png' height={'50'}/> <b className='ml-2 align-self-cente' style={{color:'#F3EA0B'}}> Pending with {this.props.photosverification.active_section && (this.props.photosverification.active_section.pending_with=="rm"?'RM':'YOU')}</b></h2>

              </div>
              {this.props.photosverification &&
              <>
           
              <>
             
             
              <div className='col-lg-10 py-1 px-5' >
              <div className="row">
                <div className="col-md-12 p-0 col-lg-6">
                <h2 className='text-white p-2 px-3 text-center old_json_text_bg_color'> <b>As per KYC</b></h2>

                </div>
                <div className="col-md-12 col-lg-6">
                <h2 className={`text-white p-2 px-3 text-center  ${this.props.photosverification.new_cust_reg_json.shop_photo.photo_shop.modified ? "new_re_kyc_text_bg_color" :" old_json_text_bg_color"}`}> <b> As Per Re-KYC</b></h2>

                </div>
                </div>

              <div className='  row justify-content-center'>

              {/* <p className='text-white no-margin py-2 col-12 audit_kyc_text text-center'>ESTABLISHMENT PHOTO</p> */}
                 <div className='col-md-12 my-3 col-lg-6 old_json_text_bg_color'  style={{background:'#202940'}}>
               <div className='' >
               
                  <div className='py-3 pt-4' >
                   <DisplayImage  lead_id={this.props.lead_id} view_lead={()=>this.props.view_lead()} image_path={get_dir_path(this.props.photosverification.old_cust_reg_json.shop_photo?.photo_shop.path,this.props.photosverification.old_cust_reg_json.shop_photo?.photo_shop.value)} style={{height: '600px',width:'100%'}} file_name={this.props.photosverification.old_cust_reg_json.shop_photo?.photo_shop.value} />
                   </div>

                 {this.props.photosverification.old_cust_reg_json?.gps_address &&
                  <div className=' p-4 '   style={{background:'#202940'}}>
                      
                      <div className={` py-2 col-12  align-items-center mb-3  mx-2 align-items-center `}>
                      {this.props.rekyc_modify_state_handler(this.props.photosverification,"old_cust_reg_json","gps_address","business_distance") }

                            
                      </div>

                      <div className={` py-2 col-12  align-items-center mb-3  mx-2 align-items-center `}>
                      {this.props.rekyc_modify_state_handler(this.props.photosverification,"old_cust_reg_json","shop_photo","ownership") }

                              
                      </div>

                      <div className={` py-2 col-12  align-items-center my-3   mx-2 align-items-center `}>
                        {this.props.rekyc_modify_state_handler(this.props.photosverification,"old_cust_reg_json","shop_photo","biz_addr_prop_type") }

                      </div>



                  </div>
                  }
                  </div>
                 </div>

                 <div className='col-md-12 my-3 col-lg-6 '>
                
                 <fieldset className= {` h-100 ${this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","shop_photo","photo_shop") ? ` ${this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","shop_photo","photo_shop")+"_field_set"}`:"unmodified_field_set" } ` } style={{background:'#202940'}}>
                  {this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","shop_photo","photo_shop") ? <legend className={`legend_rekyc ${this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","shop_photo","photo_shop")+"_text_color"} `}> 
                  <b> {this.rekyc_modify_checking(this.props.photosverification,"new_cust_reg_json","shop_photo","photo_shop") } </b></legend> :""}


                  <div className='col-md-12 no-padding  px-3 my-2 col-lg-12' >
                <div className=' pt-2 px-3' >
                <DisplayImage rotate={true} file_of={"photo_shop"} actual_file={"shop"} addl_file_name={null}  lead_id={this.props.lead_id} view_lead={()=>this.props.view_lead()} image_path={get_dir_path(this.props.photosverification.new_cust_reg_json.shop_photo?.photo_shop.path,this.props.photosverification.new_cust_reg_json.shop_photo?.photo_shop.value)} style={{height: '600px',width:'100%'}} file_name={this.props.photosverification.new_cust_reg_json.shop_photo?.photo_shop.value} />

                </div>
                </div>

                <div className=' p-4  mt-3' style={{background:'#202940'}}>
 
                    <div className={` py-2 col-12  align-items-center mb-3  mx-2 align-items-center `}>
                    {this.props.rekyc_modify_state_handler(this.props.photosverification,"new_cust_reg_json","gps_address","business_distance",null,null,"gps_address.business_distance")} 
                          
                    </div>

                    <div className={` py-2 col-12  align-items-center mb-3  mx-2 align-items-center `}>

                    {this.props.rekyc_modify_state_handler(this.props.photosverification,"new_cust_reg_json","shop_photo","ownership",null,null,"shop_photo.ownership")} 
                            
                    </div>

                    <div className={` py-2 col-12  align-items-center my-3   mx-2 align-items-center `}>

                    {this.props.rekyc_modify_state_handler(this.props.photosverification,"new_cust_reg_json","shop_photo","biz_addr_prop_type",null,null,"shop_photo.biz_addr_prop_type","custom_biz_addr")} 


                    </div>

                  </div>
                </fieldset>
                  
                 </div>

                 {this.props.photosverification.active_section &&
                 <div>
                     {this.props.photosverification.active_section.verify &&
                       <div class="closedStamp ">
                       <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                       </div>   
                     }
                 </div>
                } 

                <br/>
                <h2 className='re_kyc_note text-danger text-center'> <b>NOTE</b> : Compare the existing data (as per FLOW) with the current data (as entered by RM). </h2>
              </div>

              <br/>
              </div>
 
              <div className='col-lg-10 py-2 px-5 mt-2'> 
              <div className='row'>
                <div className='col-6'></div>
                <div className='col-6'>
                
                 <form className={`${(this.props.photosverification.active_section.verify || (this.props.photosverification.active_section.to_reassign==true) )? "disable_div":''} ${ (((this.props.photosverification.responseData.audited_by== get("user_id")) || (this.props.photosverification.responseData.audited_by==null ))&&(this.props.photosverification.active_section.pending_with !="rm")) ?"":'disable_div'}`}>
                     <div className='d-flex align-items-center row'>
                     <input type="checkbox" id="vehicle1"  className='pointer col-1' style={{height:'19px',width:'20px'}} checked={audit_kyc && audit_kyc.clear_nd_visible && true} name="vehicle1" value="Bike" onChange={(e)=>this.props.checkbox_verification(e.target.checked,"clear_nd_visible")}/>
                     <label className='no-margin px-0 audit_kyc_text pointer col-11'  for="vehicle1">The establishment photo is clear and visible.</label><br/><br/>
                     </div>
                     <div className='d-flex align-items-center row'>
                     <input type="checkbox" id="vehicle2" className='pointer col-1' style={{height:'19px',width:'20px'}} checked={audit_kyc && audit_kyc.covers_shop_name && true} name="vehicle2" value="Bike" onChange={(e)=>this.props.checkbox_verification(e.target.checked,"covers_shop_name")}/>
                     <label className='no-margin px-0 audit_kyc_text pointer col-11'  for="vehicle2">The establishment photo covers with the shop name. </label><br/><br/>
                     </div>
                     <div className='d-flex align-items-center row'>
                     <input type="checkbox" id="vehicle3" className='pointer col-1' style={{height:'19px',width:'20px'}} checked={audit_kyc && audit_kyc.covers_nearby_areas && true} name="vehicle3" value="Bike" onChange={(e)=>this.props.checkbox_verification(e.target.checked,"covers_nearby_areas")}/>
                     <label className='no-margin px-0 audit_kyc_text pointer col-11'  for="vehicle3">The establishment photo covers the nearby areas.</label><br/><br/>
                     </div>
                 </form>
                </div>

              </div>
            
                     
              </div>
               </>
               </>}
               </>}
             </div>
             }
             </>
            }
            </div>

        )        
     }
}


const mapStateToProps = (state) => {
  return {
    audit_kyc: state.common_slice,
  };
  };

export default connect(mapStateToProps)(RekycPhotoVerificationComponent);
