import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box } from "@mui/material";
import {
  HeaderVarientH3Typo,
  SubTitleVarientTwoTypo,
} from "../../CommonComponent/typography";
import { CustDetailProp } from "../hooks/useGetCustDetailsQuery";

const CustNameAndSinceUI = ({
  custDetail,
  textColor,
}: {
  custDetail: CustDetailProp;
  textColor?: string;
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="start"
      py={2}
      color={textColor}
    >
      <AccountCircleIcon
        sx={{ width: "50px", height: "50px", color: textColor }}
      />
      <Box ml={2}>
        <HeaderVarientH3Typo
          text={custDetail?.biz_name ?? "-"}
          overRideStyles={{ color: `${textColor} !important` }}
        />
        <SubTitleVarientTwoTypo
          text={`(Customer since: ${custDetail?.customer_since ?? "-"})`}
          overRideStyles={{
            textTransform: "capitalize",
            color: `${textColor} !important`,
          }}
        />
      </Box>
    </Box>
  );
};

export default CustNameAndSinceUI;
