import React from "react";
import FlowComponent from "../../common/component/core/FlowComponent";
import { get, Config } from "../../../helpers/storage_helper";
import { connect } from "react-redux";

class AuditKycVerificationSections extends FlowComponent {
  constructor() {
    super();
  }

  verify_button_status() {
    let data = this.props.verification_datas;
    if (data) {
      if (data.audit_kyc_wizard_menus.mobile_numbers) {

        const mob_num_verify = data.cust_reg_json.mobile_numbers.biz_mob_num.every(
          (item) => item.verify && item.verify_identity
        );
        const addl_num_verify = data.cust_reg_json.mobile_numbers.addl_num.every(
          (item) => item.verify 
        );      
        return mob_num_verify && addl_num_verify;

      }
      
      if (data.audit_kyc_wizard_menus.agreements) {


        let flag_stats =
          this.check_flags(this.props.audit_kyc[data.active_key]) && 
          ( data.cust_reg_json?.agreements[0].witness_sign  ? 
            this.is_name_retrieved(
            data.cust_reg_json.agreements,
            data.active_key
          ) : true);

          return flag_stats ;
      }

      if (data.audit_kyc_wizard_menus.biz_accounts) {

        let section = this.props.audit_kyc[data.active_key]
        let flag_status 
        if (Array.isArray(data?.cust_reg_json?.biz_accounts?.flags)) {
          flag_status = true
        }else if (typeof data?.cust_reg_json?.biz_accounts?.flags === "object" &&
           data?.cust_reg_json?.biz_accounts?.flags !== null) {

            flag_status = (this.check_flags(section) &&
          this.validate_biz_accs(
            data.cust_reg_json.biz_accounts.accounts,
            data.active_key))
         }

      
        return flag_status;
      } else {
        let flag_status = this.check_flags(
          this.props.audit_kyc[data.active_key]
        );
        if (
          data.responseData.type == "re_kyc" &&
          (data.active_key == "id_proof" || data.active_key == "gps_address")
        ) {
          return flag_status && data.verify_bttn_status;
        } else {
          return flag_status;
        }
      }
    }
  }

  check_flags(flags) {
    if (flags && Object.keys(flags).length > 0) {
      for (const value of Object.values(flags)) {
        if (!value) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  is_name_retrieved(data, type) {
    return data.some((item, idx) => {
        if (type === "agreements" ) {
          let mis_match_reason =  item.witness_info?.name_match ? true : !(this.props.audit_kyc?.agr_reason_editable)
            return  mis_match_reason && 
            item.witness_info?.hasOwnProperty('name_match') &&
            (!item.witness_info?.name_match ? item.witness_info?.mismatch_reason :true)
            
        }

        return false;
    });
}

validate_biz_accs(data, type) {

  return data.every((item) => {
    const reason =
      (item?.name_match === false &&  item?.type !="existing_account") ? item?.mismatch_reason : true;

    const doc_status =

    item?.type !="existing_account"  ? 
    (
     item?.document_status  &&
      (item?.document_status === "insufficient_documents"
        ? item?.missing_documents?.length > 0
        : item?.document_status === "sufficient_documents")
    ):true;

    const mismatch_reason = item?.name_match
      ? true
      : !this.props.audit_kyc?.[`${item?.acc_number?.value}_is_editable`];

  

    return reason && doc_status && mismatch_reason;
  });
}



  render() {
    const {  active_section, responseData } = this.props.verification_datas;
    const section = (this.props.verification_datas?.active_key == "agreements") ? active_section[0] : active_section
    const is_verify = section?.verify
    let className = ( ( section?.to_reassign === true ||  section?.pending_with === "rm") || (responseData.audited_by != get("user_id") ||  responseData.audited_by == null || responseData?.profile_status == 'closed' ))
    ? "disable_div" : "";
   
    return (
      <>
        {this.props.verification_datas &&
          this.props.verification_datas.active_section && (
            <div
              className={`${className}`}
            >
              {/* {this.props.verification_datas.audit_kyc_wizard_menus.id_proof && */}
              <>
                {this.props.verification_datas.active_section &&
                  !this.props.verification_datas.active_section.to_reassign && (
                    <>
                      {is_verify ? (
                        <div className="d-flex  justify-content-center mt-4">
                          <button
                            type="button"
                            class="btn btn-danger z_index"
                            onClick={() => this.props.unverify_handler()}
                          >
                            {" "}
                            <b>Unverify</b>
                          </button>
                        </div>
                      ) : (
                        <div>
                          <div className="d-flex  justify-content-center mt-4">
                            <button
                              type="button"
                              class={`${
                                this.verify_button_status()
                                  ? "btn btn-success"
                                  : "btn btn-secondary"
                              }`}
                              disabled={
                                this.verify_button_status() ? false : true
                              }
                              onClick={() => this.props.verify_handler()}
                            >
                              {" "}
                              <b>Verify</b>
                            </button>
                          </div>

                          <div className="d-flex  justify-content-center z_index">
                            <p
                              className="text-white no-margin"
                              style={{ fontSize: "15px" }}
                            >
                              {" "}
                              Check all the items in the checklist to verify
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
              </>
            </div>
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    audit_kyc: state.common_slice,
  };
};

const mapDispatchToProps = {
  // setValuesIntoRedux
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuditKycVerificationSections);