import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import { AiOutlineIdcard,AiOutlineMobile,AiFillShop ,AiFillEdit} from "react-icons/ai";
import{MdBusiness,MdPerson,MdAccountCircle,MdImage,MdPersonOutline,MdPhoto,MdLocationPin,MdArrowDropDown,MdVerified} from "react-icons/md";
import { BiRefresh,BiPhoneOff ,BiPhoneCall,BiXCircle,BiPhotoAlbum,BiEdit,BiMap,BiCheck} from "react-icons/bi";
import{GoVerified} from "react-icons/go";
import{FaHandsHelping} from "react-icons/fa";
import{HiOutlineDocumentAdd,HiPhoneOutgoing,HiArrowCircleRight,HiArrowCircleLeft}from"react-icons/hi";
import{ImCircleDown,ImCircleUp} from "react-icons/im";
import{ CgNotes}from'react-icons/cg';
import{BsClipboardData}from'react-icons/bs';
import FileUpload from '../../common/component/FileUpload';
import Alert from 'react-bootstrap/Alert';
import {get, Config, check_priv} from '../../../helpers/storage_helper';
import { viewLead ,rekycVerify,UpdateFlags,addComments,auditKycVerification,auditKyRetrievals,auditKycFileUpload, submitCallLog,RejectCallLog,auditkycModify,updateAccPrvdrCode,updateMismatchReason} from '../../../actions/lead_actions';
import {capitalize_first_letter,getLabel,getValueFromLangJson,get_dir_path,get_path,pdf_viewer,rekyc_modify_state_handler, req} from '../../../helpers/common_helper';
import DisplayImage from '../../common/component/DisplayImage';
import { approveKYC,updateLeadStatus,rejectKYC} from '../../../actions/kyc_actions';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import isValid from 'date-fns/fp/isValid';
import {dd_value} from '../../../actions/common_actions';
import GoogleMapSetup from '../../../helpers/googleMap';
import moment from 'moment';
import PDFtoImage from './Pdf_to_image';
import { Homeacc_prvdrLogoselector , convert_sec_to_min_sec} from '../../../helpers/common_helper';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import AuditRekycComponent from './AuditRekycComponent';
import Form from 'react-bootstrap/Form';
import AccPurposeMultiSelect from '../../../helpers/accPurposemulti';
import AuditKYCMobileNumberVerification from '../container/AuditKycMobileNumberVerification';
import  AuditKycVerificationSections from '../container/AuditKycVerificationSections';
import AuditKYCOwnerInfoComponent from '../container/AuditKYCOwnerInfoComponent';
import { th } from 'date-fns/locale';
import AuditKYCAccountOwnershipComponent from '../container/AuditKYCAccountOwnershipComponent';

import AuditKYCMapandHandlerComponent from '../container/AuditKYCMapandHandlerComponent';
import AuditKYCPhotosVerification from '../container/AuditKYCPhotosVerification';
import AuditKYCAgreementsVerification from '../container/AuditKYCAgreementsComponent';
import {RoundOffSplitter} from '../../../helpers/common_helper';
import { viewLender } from '../../../actions/lender_actions';
import { connect } from 'react-redux';
import { object } from 'yup';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import { updateValuesIntoRedux } from '../../../Redux/Slices/CommonSlice';
import RekycMobileNumberVerification from './RekycMobileNumberVerification';


class AuditKycVerificationComponent extends FlowComponent
{

    constructor() {
        super();
        this.state ={
          selectedOptions:[], 
          under_watch:null,
          audit_kyc_wizard_menus:{},
          ussd_code:"",
        pdfMOdal:false,
        agreements_verify_section:false,
        text_blink:false,
        review_comments:[],
        previous_section:false,
        active_key:"id_proof",
        time: [],
        seconds:[30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30],
        index: 0,
        disabledNext: false,
        disabledPrev: false,
        lead:{},
        initial_load:1,
        first_load:1,
        active_load:0,
        section_key_type:'',
        call_log_verify:false,
        modify_status:false,
        cust_mobile_verify:false,
        addl_mobile_verify:false,
        form_spinner:false,
        doc_loder:false,
        reg_mobile_opertator:false,
        alt_biz1_mobile_opertator:false,
        approve_button_status:true,
        agreements_verify_status:false,
        selectedOptions:[], 
        timers:{},
        under_watch:null,
        loaders:{}
        };
        this.timer = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
        this.verified_key=this.state.active_key+"_verified"
        this.intervals = {}; 
     
        this.id_proof_data=["first_name","last_name","gender","dob","id_proof_num"];
        this.memoizedFunctions = {
          verify_handler: (a, b, c, d) => this.verify_handler(a, b, c, d),
          account_owner_handler: (a, b, c, d, e, f, g, h) => this.account_owner_name_handler(a, b, c, d, e, f, g, h),
        };
      }       
    componentWillMount()
    {   
      this.view_lead()
      let screen_width=window.screen.availWidth
      this.setState({screen_width, rm_label : getValueFromLangJson("rm_label_short")
      })      
    }

    shouldComponentUpdate(nextProps, nextState) {
      return JSON.stringify(this.state) !== JSON.stringify(nextState);
    }
  
    // Ensure state updates only when necessary
    componentDidUpdate(prevProps, prevState) {
      if (JSON.stringify(prevState) !== JSON.stringify(this.state)) {
        this.forceUpdate();
      }
    }
     retrival_api(mobile_num=null){
      var request={"mobile_num" : mobile_num??this.state.validate_mob_num,"lead_id":this.props.lead_id}
      auditKyRetrievals(this.req(request))
      .then((response)=>{
        if(response.status=="success"  ){

          if(response.data.ussd_code)
            {this.setState({ ussd_code: response.data.ussd_code });}
            else{
              const { data } = response;
              this.setState(prevState => {const newState = { ...prevState };
                Object.keys(data).forEach(key => {
                  newState[key] = data[key];
                });
                return newState;
              });
        }
  
        this.stopTimer(mobile_num)
        this.view_lead()
      }
        
      })
      
    }


  
    view_lead(show_comment_msg=null,mobile_num_verify=null,key_type=null,agreements_verify=null){
    this.id = this.props.lead_id;     
    if(key_type!=null) {this.setState({View_lead_section_loader:true})}
    viewLead(this.req({id: this.id}))
    .then((response)=>{
      if(!response){return}
      if(response.status=="success"){
       var active_key= key_type !=null?key_type:this.state.active_key
       this.update_flags(response.data.cust_reg_json,active_key)
        var cust_reg_json=response.data.cust_reg_json
        
      if(response.data.type=="re_kyc"){
        this.setState({agreements_verify_status:agreements_verify!=null?true:false})
        
        this.setState({ form_spinner:false,doc_loder:false, cust_reg_json: response.data.cust_reg_json,new_cust_reg_json:response.data.cust_reg_json,old_cust_reg_json:response.data.old_cust_reg_json,responseData:response.data,initial_load:0,data_code:'',View_lead_section_loader:false},()=>{ this.audit_kyc_data_fetching(active_key,cust_reg_json, show_comment_msg ) })
        // this.audit_kyc_verification_menus(active_key,response.data.cust_reg_json, show_comment_msg,response.data) 
        this.setState({verify_bttn_status:this.check_verify_button_handler(active_key,response.data.cust_reg_json)}) 
      }else{
        this.setState({agreements_verify_status:agreements_verify!=null?true:false})

        this.setState({form_spinner:false, doc_loder:false,cust_reg_json: response.data.cust_reg_json ,responseData:response.data,initial_load:0,data_code:'',View_lead_section_loader:false,},()=>{ this.audit_kyc_data_fetching(active_key,cust_reg_json, show_comment_msg ) })

      }

      if(response.data.cust_reg_json.sub_lender_code){
        var req = {lender_code : response.data.cust_reg_json.sub_lender_code }
        viewLender(this.req(req)).then(response => {
          if(!response) return;
          if(response.status == 'success') {
            this.setState({lender_name : response.data.name})
          }
        })  
      }
     
      if(active_key=="mobile_numbers"){
        this.addl_num_verify(response.data.cust_reg_json)
      }
    

      }
   
    })

  }

  update_flags(cust_json,section){
    let flags;
   if(section=="agreements"  ){
    flags = this.merge_all_flags(cust_json[section])
   }else{
     flags= cust_json[section].flags
   }
   this.props.updateValuesIntoRedux({[section]:flags})

  }


  merge_all_flags(agreements) {
    let flags = {};
    if (agreements && agreements.length > 0) {
      for (let i = 0; i < agreements.length; i++) {
        flags = { ...flags, ...agreements[i].flags };
      }
    }
    return flags;
  }

  license_proof_verify(current_section, data = this.state.cust_reg_json){
     var a=true
    for(const [key, value] of Object.entries(data[current_section].flags)) {
      if(!data[current_section].flags[key]){
        a=false
      }
    }

    return a;

  }

  update_mismatch_reason(index,key_type,val,addl_val=null){
    
   if(addl_val!=null ){

    var a = "sufficient_documents_"+index
      this.setState({[a]:addl_val,doc_loder: true })
   }
   this.setState({form_spinner: (key_type != "document_status")})

   var req={ "index":index,"key":key_type,value:val,"lead_id":this.props.lead_id}
   updateMismatchReason(this.req(req))
   .then((response)=>{
    if(!response){return}
    if(response.status=="success"){
      if(addl_val!=null ){
      this.view_lead()
      }
    }
   })
   
  }

  check_verify_button_handler(active_key,cust_reg_json){

   var data= (active_key=="id_proof" || active_key== "gps_address")? Config('addr_hiearchy')?.[get('market').country_code] : []
    for (let i=0; i<data?.length; i++) {
    
      if(active_key=="id_proof"){
        
        if(cust_reg_json[active_key]?.[data?.[i]]?.hasOwnProperty("verify") && !cust_reg_json[active_key][data[i]].verify){
          return false;
        }
      }
      if (active_key === "gps_address") {
        const { biz_address, owner_address, permanent_res_addr } = cust_reg_json[active_key];
      
        const isBizAddressUnverified = biz_address[data[i]]?.verify === false;
        const isOwnerAddressUnverified = owner_address[data[i]]?.verify === false;
        const isPermanentResAddrUnverified = permanent_res_addr?.[data[i]]?.verify === false;
      
        if (isBizAddressUnverified || isOwnerAddressUnverified || isPermanentResAddrUnverified) {
          return false;
        }
      }

    
    }
    return true;

  }

  
  audit_kyc_data_fetching(active_key,cust_reg_json, show_comment_msg   ){
    this.audit_kyc_wizard_menus(active_key,cust_reg_json, show_comment_msg )
    
  }


  audit_kyc_wizard_menus(active_key,cust_reg_json, show_comment_msg ){
    var wizard_array=[]
    var  audit_kyc_wizard_icons=[ 
    {"icon":<AiOutlineIdcard/>,"key":"id_proof","title":'Personal Info'},
    {"icon":<FaHandsHelping/>,"key":"contact_persons","title":`Handler Info`},
    {"icon":<BiMap/>,"key":"gps_address","title":`Gps & Address`},
    {"icon":<MdAccountCircle/>,"key":"biz_accounts","title":`Biz Accounts`},
    {"icon":<MdImage/>,"key":"kyc_photos","title":'KYC Photos'},
    {"icon":<AiFillShop/>,"key":"shop_photo","title":'Shop Photo'},
    {"icon":<AiOutlineMobile/>,"key":"mobile_numbers","title":'Biz Mobile'},
    {"icon":<BsClipboardData/>,"key":"data_consent","title":'Data Consent'},
    {"icon":<CgNotes/>,"key": "agreements","title":'Agreement'},
  ]


  audit_kyc_wizard_icons = audit_kyc_wizard_icons.filter(item => {
    if (item.key === "contact_persons") {
      return cust_reg_json && 
             cust_reg_json.hasOwnProperty(item.key) && 
             !cust_reg_json.contact_persons?.same_as_owner_person?.value;
    }
    
    return cust_reg_json && cust_reg_json.hasOwnProperty(item.key);
  });

let  audit_kyc_wizard_menus ={...this.state.audit_kyc_wizard_menus}

 if( Object.keys(this.state.audit_kyc_wizard_menus).length <1){

 audit_kyc_wizard_menus = Object.fromEntries(
    audit_kyc_wizard_icons
      .filter(item => 
        item.key === "contact_persons"
          ? cust_reg_json &&
            cust_reg_json.hasOwnProperty(item.key) &&
            !cust_reg_json.contact_persons?.same_as_owner_person?.value
          : cust_reg_json && cust_reg_json.hasOwnProperty(item.key)
      )
      .map(item => [item.key, false]) 
  );
  audit_kyc_wizard_menus.id_proof = true;  

  this.setState({audit_kyc_wizard_menus})
 }
 



 
  this.setState({audit_kyc_wizard_icons,root:get_path("view_file"),mobile_operators:this.getSelectOptions('mob_num_operator',null,null,"mobile_operator"), menu_idx:audit_kyc_wizard_icons.length},()=>{this.audit_kyc_verification_menus(active_key,cust_reg_json, show_comment_msg )})
 
  }



  next_section_handler(active_section){
    if(this.state.next_index){
      var index=this.state.index +1
    }
    if(this.state.active_key=="agreements" ){
      var index=this.state.index 
    }
    this.setState({initial_load:1,first_load:0,next_index:false},()=>{this.audit_kyc_verification_menus(Object.keys(this.state.audit_kyc_wizard_menus)[index],this.state.cust_reg_json)})

  }

   
audit_kyc_verification_menus(
  type=this.state.active_key,
  section_keys=this.state.cust_reg_json,
  show_comment_msg=null,
  response_data = this.state.responseData){

   var audit_kyc_wizard_menus= this.state.audit_kyc_wizard_menus
   var active_key;
   var active_index;
   var verified_modal=false;
   var audit_kyc_approve = false;
   var audit_kyc_reassign;
   var all_section_verified=true
   var audit_response ={...audit_kyc_wizard_menus}
   var audit_data_length= Object.keys(this.state.audit_kyc_wizard_menus).length;
   var reassign_arr=[]
   var count=0;
   for (const [key, value] of Object.entries(section_keys)) {

    if(value!=null && value.verify == false){
      all_section_verified=false
    }
    
    if((value!=null )&& (value.to_reassign==true)){
      audit_kyc_reassign=true
      reassign_arr.push(key)
    }

    audit_response[key] = value
     if(key == type){
      Object.keys(this.state.audit_kyc_wizard_menus).forEach(function(audit_key, index) {
        if(audit_key == type){
          
            audit_kyc_wizard_menus[audit_key]=true
            active_key = audit_key;
            active_index=index     
        }
        else{
            audit_kyc_wizard_menus[audit_key]=false
        }
        }); 
     }
   }

    if(all_section_verified){
      audit_kyc_approve = true
      verified_modal=true
    }
  var active_section = section_keys[active_key]
      if(this.state.active_section !=active_section ){
        this.setState({active_section,active_key})
      }

     if(active_key == "biz_accounts"){

      const accounts=this.state.cust_reg_json.biz_accounts.accounts
      accounts.map((item,idx)=>{
        const service_prvdr= item.acc_prvdr_code.value
        if(!item.hasOwnProperty('holder_name')&& !this.state.timers.hasOwnProperty(item.acc_number.value)  && ((get('app_config').audit_kyc_line[service_prvdr]=="ussd") || (get('app_config').audit_kyc_line[service_prvdr]=="partner_api"))){
         this.retrival_api_call_for_section(item.acc_number.value)
        }
      })
     }
      if(active_key=="agreements"){
        this.agreements_retrival()
      }
      
     var current_section = (active_key=="agreements" ) ? active_section[0] : active_section
     var comments_type_arr= (current_section.unresolved_comments || current_section.resolved_comments  )&&( current_section.unresolved_comments.length>0 ? current_section.unresolved_comments : current_section.resolved_comments.length>0?current_section.resolved_comments :[])
     var auditor_comments= comments_type_arr?.length >0 ? (comments_type_arr.reverse()): []
      if(auditor_comments){this.auditor_comments_section_handler(auditor_comments,response_data)}

    if(show_comment_msg==null){
      this.setState({audit_kyc_wizard_menus,active_key:active_key,review_comments:[],index:active_index,audit_response,auditor_comments,text_blink:false,previous_section:false,audit_kyc_reassign,audit_kyc_approve,verified_modal,})
    }else if(show_comment_msg!=null ){
      
      this.setState({audit_kyc_wizard_menus,active_key:active_key,review_comments:[],index:active_index,audit_response,auditor_comments,text_blink:true,audit_kyc_reassign,audit_kyc_approve,verified_modal})

    }
 
  
}
agreements_retrival() {
  this.state.cust_reg_json.agreements.forEach((item) => {
      if (item?.witness_info) {
          const service_prvdr = item.witness_info?.serv_prvdr?.value;
          const mobile_num = item.witness_info?.mobile_num?.value;
          
          if (
              !item.witness_info.hasOwnProperty("holder_name") &&
              !this.state.timers.hasOwnProperty(mobile_num) &&
              service_prvdr &&
              get("app_config")?.audit_kyc_line?.[service_prvdr] === "ussd"
          ) {
              this.retrival_api_call_for_section(mobile_num);
          }
      }
  });
}




scroll_top_view(){
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth' 
  });
  
}

retrival_api_call_for_section(id){

  this.startTimer(id)
  this.retrival_api(id)
  
}




startTimer = (id) => {
  if (!this.state.timers.hasOwnProperty(id)) {
    this.setState(
      (prevState) => ({
        timers: { ...prevState.timers, [id]: 30 }, 
      }),
      () => {
        this.intervals[id] = setInterval(() => this.countDown(id), 1000);
      }
    );

    // Automatically stop the timer after 30 seconds
    setTimeout(() => {
      this.stopTimer(id);
    }, 30000);
  }
};




countDown = (id) => {
  this.setState((prevState) => {
    const remaining = prevState.timers[id] - 1;

    if (remaining <= 0) {
      this.stopTimer(id);
      return { timers: { ...prevState.timers, [id]: 0 } }; // Set timer to 0
    }

    if( (remaining % 5 == 0) || remaining ==1){
      this.view_lead()
   }

    return { timers: { ...prevState.timers, [id]: remaining } };
  });
};

stopTimer = (id) => {
  const { timers, cust_reg_json } = this.state;

  if (this.intervals[id]) {
    clearInterval(this.intervals[id]);
    delete this.intervals[id];

    this.setState((prevState) => {
      const updatedTimers = { ...prevState.timers, [id]: 0 };
      const updatedCustRegJson = { ...prevState.cust_reg_json, [id]: "is_retrieved" };
      return {
        timers: updatedTimers,
        cust_reg_json: updatedCustRegJson
      };
    }, () => {
      if (this.state.timers[id] !== 0) {
        this.countDown(id);
      }
    });
  }
};



componentDidMount() {

  // let timeLeftVar = this.secondsToTime(this.state.seconds);
  // this.setState({ time: timeLeftVar }); 
  this.Ismobile() 
}
auditor_comments_section_handler(auditor_comments,response_data){
  var data = []
  auditor_comments.forEach((item, index) => {
    if(response_data && response_data.comments){
      var datas= response_data.comments.filter((comment_item,idx)=>comment_item.id== item)

      datas.forEach(element => {
         data.push(element)
      }); 
    }
  
});
this.setState({active_section_comments:data})
}

audit_kyc_comments(val,type){
 
    var comments=type+"_comments" 
    this.setState({[comments]:val})
}
 verify_comments_handler(comment_type){
  var comments= this.state.active_key+"_comments"
  if(this.state[comments]) {
  var req={ "lead_id" :this.props.lead_id,"section":this.state.active_key ,"comments":this.state[comments],"type":comment_type}
  if (window.confirm("Are you sure to submit the Comment ?")) {
  addComments(this.req(req))
  .then((response)=>{
    if(!response){return}
    if(response.status=="success"){
   
      this.state[comments]=''
      this.setState({text_blink:true,comments ,comment_type})
      this.view_lead("show_comments_msg")

    }
  })
  }
  }else{
    alert("Please Enter Valid Comments")
  }
      
}
previous_comments_section(type){
    this.setState({[type]:!this.state[type]})
}

checkbox_verification(checked_value=null,type=null,acc_owner_verification=null){
  let active_key = this.state.active_key;
  let check_list = { 
    ...this.props.audit_kyc[active_key],
    [type]: checked_value 
  };
 
  this.props.updateValuesIntoRedux({ [active_key]: check_list });
  this.setState({[type]:checked_value,form_spinner:true})

  var request={"lead_id":this.props.lead_id, "section":active_key, [type]:checked_value }

  UpdateFlags(this.req(request))
  .then((response)=>{
   if(!response){return}
  })

}


holder_name_retrieval_ussd_codes_handler(service_prvdr=null,validate_mob_num=null){
//  var holder_name_retrieval_ussd_code= get('app_config').holder_name_retrieval_ussd_codes[service_prvdr]

//  let ussd_code=holder_name_retrieval_ussd_code.replace(":recipient", validate_mob_num)

//  return ussd_code;

}

alert_close(){
    this.setState({text_blink:false})

}


  togglePrev(e) {
    let index = this.state.index - 1

    let disabledPrev = false
    if (index <= 0) {
      e.preventDefault()
      index = 0
      disabledPrev = true
    }
    if (index > 8) {
    
      e.preventDefault()
      index = 0
      disabledPrev = true
    }
    this.view_lead(null,null,Object.keys(this.state.audit_kyc_wizard_menus)[index])
   
    this.setState({ index: index, disabledPrev: disabledPrev, disabledNext: false })
    this.alert_close()
    this.scroll_top_view()
  }
  toggleNext(e) {

    let index = this.state.index + 1
    let disabledNext = false
   if(this.state.index == this.state.audit_kyc_wizard_icons.length -1) {
     
      e.preventDefault()
      index =  Object.keys(this.state.audit_kyc_wizard_menus).length - 1
      disabledNext = true
    }
    this.view_lead(null,null,Object.keys(this.state.audit_kyc_wizard_menus)[index])

   
    this.setState({ index: index, disabledNext: disabledNext, disabledPrev: false})
    this.alert_close()
    this.scroll_top_view()
  }


 
 
  verify_handler(type=null,verify_data ){
    var request  = {}

    if(["mobile_num_verify", "biz_acc"].includes(type)) {
          
        let num_type = verify_data?.section + "_" + verify_data.index + "_verify"

         request= { "lead_id" : this.props.lead_id,
           "section": this.state.active_key,
           "mismatch_reason": verify_data.mismatch_reason?? this.state.reg_account_holder_name_mismatch_reason }


          if(verify_data.acc_type){
            let key = verify_data.acc_type+"_verify"
            request[key] = verify_data.acc_number
          }else{
            request[num_type] = true
          }
      }
      else if(type=="witness_info"){
        request= { "lead_id" : this.props.lead_id, 
          "section": this.state.active_key,          
          "mismatch_reason": verify_data.mismatch_reason??'-',
         }
         if(verify_data.acc_type){
          let key = verify_data.acc_type+"_verify"
          request[key] = verify_data.acc_number
        }
     }
      
      else{
         request= {"lead_id" : this.props.lead_id, "section": this.state.active_key,"verify" : true}
      }
   

  auditKycVerification(this.req(request))
  .then((response)=>{
    if(!response){return}
    if(response.status=="success"){
      
     
      if(request.verify ){
        alert(response.message)

        const move_next_slide =  (this.state.index == this.state.audit_kyc_wizard_icons.length -1)
       this.setState({ next_index: !move_next_slide},()=>{ this.next_section_handler("active_section"); 
        if(!move_next_slide)
        this.toggleNext() 
      })
      
       this.view_lead(null,"mobile_num_verify",null)
      }else{
        this.setState({next_index:false,reg_account_holder_name_mismatch_reason:'',account_holder_name_mismatch_reason:''},()=>{this.view_lead(null,"mobile_num_verify") })

      }
      this.audit_kyc_handleClose()
      // this.scroll_top_view()
    
   
    }
  })

  }

  sufficient_document_radio_button_handler(val,type){
    
    for (var i = 0; i <this.state.cust_reg_json.biz_accounts.accounts.length; i++) {
    
      var state_name=`sufficient_doucument_yes_${i}`
      type =="sufficient_doucument_yes" ? this.setState({[state_name]:val}):this.setState({[state_name]:!val})

    }

  }


  
  unverify_handler(verified_numbers_type=null){

    if(verified_numbers_type!=null){
      var request= {"lead_id" : this.props.lead_id,
      "section": this.state.active_key,
      [verified_numbers_type] : false}
    }else{
      var request= {"lead_id" : this.props.lead_id,
      "section": this.state.active_key,
      "verify" : false}
    }
    auditKycVerification(this.req(request))
    .then((response)=>{
      if(!response){return}
      if(response.status=="success"){
        alert(response.message)
        this.view_lead()
      }
    })
  }
  


  addl_num_verify( data = this.state.cust_reg_json){
    
    const regex = /^alt_biz_mobile_num_\d+$/;   
     let mobile_numbers = data.mobile_numbers;
    let reassign_key_arrays = ['mobile_num'];

    Object.entries(mobile_numbers).forEach(([key, _values]) => {
      if (regex.test(key)) {
         if(_values.value){
          reassign_key_arrays.push(key);
         }
       
      }
    });
    var count=0
    for (let x in reassign_key_arrays) {
      
      if(mobile_numbers.hasOwnProperty("rejected_"+reassign_key_arrays[x])){
        count=count+1
        }
        if(mobile_numbers[ "verified_"+reassign_key_arrays[x]]==1){
          count=count+1
        }     
      }

    if(count== reassign_key_arrays.length){
      this.setState({cust_mobile_verify:true})
    }

   var addl_mob_count= data.mobile_numbers.addl_num.length
   count = 0
    for (let i = 0; i < addl_mob_count; i++){
      if(data.mobile_numbers[`addl_mobile_num_${i+1}_verify`]){
        count = count+1
      }
    }
    if(count== addl_mob_count){
      this.setState({addl_mobile_verify:true})
    }

  }

  setAuditLeadState = (event) => {
    this.setChangeToState(event)
   }

   audit_kyc_modify_handler(modified_data,  account_holder_name=null,category=null,acc_holder_proof=null,modify_status=null){
    var request={
      "lead_id":this.props.lead_id,
      "category" : modified_data.category ?? category,
      "holder_name": modified_data.holder_name ?? this.state.re_entered_name
    }
    auditkycModify(this.req(request))
    .then((res)=>{
      if(!res){return}
      if(res.status=="success"){
        this.setState({modify_status:false},()=>{ this.view_lead(null,"mobile_num_verify")})
     
      }
    })
   }

   file_upload_submit_handler(upload_data, is_call_view_lead=true,category=null,acc_holder_proof=null,mobile_num=null){
     
      var request={
        "lead_id":this.props.lead_id,
        "category" : upload_data?.category ?? category,
        "account_holder_name_proof":upload_data?.holder_name_proof_path ?? acc_holder_proof ,
        "holder_name":upload_data?.holder_name ?? '',
        "mobile_num": upload_data?.mobile_num.value ?? mobile_num

      }
   
    auditKycFileUpload(this.req(request))
    .then((res)=>{
      if(!res){return}
      if(res.status=="success"){
        var x="account_holder_name_mismatch_reason";
        var y="reg_account_holder_name_mismatch_reason";
          this.state.cust_reg_json.biz_accounts.accounts.map((item,idx)=>{
            if(this.state.hasOwnProperty(`account_holder_name_mismatch_reason_${idx}`)){
              x=x+"_"+idx
            }
            if(this.state.hasOwnProperty(`reg_account_holder_name_mismatch_reason_${idx}`)){
              y=y+"_"+idx
            }
          
          })

        this.setState({[x]:"",[y]:'',agreements_witness_mismatch_reason_0:''},()=>{
          if(is_call_view_lead){
            this.view_lead(null,"mobile_num_verify")
          }

      })
    }
    })
   }

  Operator_selection_handler(prvdr_info, operator_code,category,operator_name,addl_num,acc_prvdr_type=null){
  //  this.setState({[operator_name]:operator_code})
  // var mobile_num=''
  // if(addl_num!=null){
  //   mobile_num=addl_num

  // }else if (acc_prvdr_type=="witness_mob_num_serv_prvdr_code") {
  //   mobile_num=this.state.cust_reg_json.agreements[0][category]

  // }else{
  //   mobile_num=this.state.cust_reg_json.mobile_numbers[category].value

  // }
  let type = prvdr_info?.section + prvdr_info?.index
    let request={"category": type ??category,
      "operator": prvdr_info?.to_operator?? operator_code,
      "lead_id": prvdr_info.lead_id?? this.props.lead_id,
      "mobile_num":prvdr_info?. mobile_num?.value }

    if (window.confirm("Are you sure to change the mobile operator?")) {
    updateAccPrvdrCode(req(request))
    .then((response)=>{
      if(!response){return}
      if(response.status=="success"){
        this.view_lead() 
        this.setState({[operator_name]:false})
        alert(response.message)

       
      }
    })}
   }


   holder_name_retrieval_ussd_codes_handler(service_prvdr=null,validate_mob_num=null){
    var holder_name_retrieval_ussd_code= get('app_config')?.holder_name_retrieval_ussd_codes[service_prvdr]
    
    let ussd_code=holder_name_retrieval_ussd_code?.replace(/:recipient/g, validate_mob_num)
   
    return ussd_code;
   }

  //  account_owner_name_handler(val,type,section_key_type=null,index=null,audit_kyc_validate_key=null,validate_mob_num=null,mob_num_type=null,acc_ownership_verification=null){
    account_owner_name_handler(biz_num){
      this.setState({section_active_index:biz_num.index,validate_mob_num :biz_num?.mobile_num?.value })
     
      var serv_prvdr= biz_num.serv_prvdr?.value
         this.setState({active_serv_prvdr:serv_prvdr,
          // ussd_code:this.holder_name_retrieval_ussd_codes_handler(serv_prvdr,validate_mob_num)
        }   
      ,()=>{this.addl_retrival_api(biz_num)})
  

   
  }





  verify_status_handler(cust_reg_json,json_type,json_key,key,addl_key,idx,modified=null,verified_status=null,verify_request_key){
    if((cust_reg_json[json_type][json_key][addl_key][key].hasOwnProperty("verify")) && (cust_reg_json[json_type][json_key][addl_key][key].verify)){
      
       return this.get_field_set_tag(cust_reg_json,json_type,json_key,key,addl_key,idx,modified,"verified_true",verify_request_key)
    }
  
    else if((cust_reg_json[json_type][json_key][addl_key][key].hasOwnProperty("verify")) && !(cust_reg_json[json_type][json_key][addl_key][key].verify)){
      
       return this.get_field_set_tag(cust_reg_json,json_type,json_key,key,addl_key,idx,modified,"verified_false",verify_request_key)
  
    }
     return this.get_field_set_tag(cust_reg_json,json_type,json_key,key,addl_key,idx,modified)
  
  }
    
   rekyc_modify_state_handler(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null,verify_request_key=null,agreement_format){

    if((addl_key!=null)&& (idx == null)){
       
      alert(idx)
      alert(key)
        if(((cust_reg_json[json_type][json_key][addl_key][key]?.hasOwnProperty("collected_n_modified")) && (cust_reg_json[json_type][json_key][addl_key][key]?.collected_n_modified ==true))){
   
         return this.verify_status_handler(cust_reg_json,json_type,json_key,key,addl_key,idx,"collected_n_modified","verified_status",verify_request_key,agreement_format)
  
    
         }else if(((cust_reg_json[json_type][json_key][addl_key][key].hasOwnProperty("modified")) && (cust_reg_json[json_type][json_key][addl_key][key].modified))){
        
          return this.verify_status_handler(cust_reg_json,json_type,json_key,key,addl_key,idx,"modified","verifiy_status",verify_request_key,agreement_format)
          
        }
        else{
        
           return this.un_field_set_tag(cust_reg_json,json_type,json_key,key,addl_key,idx)
        }
      }
      else if(idx!=null ){
        if(addl_key!=null){
          if(((cust_reg_json[json_type][json_key][addl_key][idx][key]?.hasOwnProperty("collected_n_modified")) && (cust_reg_json[json_type][json_key][addl_key][idx][key]?.collected_n_modified))) {
            return this.get_field_set_tag(cust_reg_json,json_type,json_key,key,addl_key,idx,"collected_modified")
         }
         else if(((cust_reg_json[json_type][json_key][addl_key][idx][key]?.hasOwnProperty("modified")) && (cust_reg_json[json_type][json_key][addl_key][key]?.modified))){
            return this.get_field_set_tag(cust_reg_json,json_type,json_key,key,addl_key,idx,"modified")
         }
        }
        if(addl_key==null){
        
          if(((cust_reg_json?.[json_type]?.[json_key]?.[idx]?.[key]?.hasOwnProperty("collected_n_modified")) && (cust_reg_json?.[json_type]?.[json_key]?.[idx]?.[key]?.collected_n_modified))) {

            return this.get_field_set_tag(cust_reg_json,json_type,json_key,key,addl_key,idx,"collected_modified")
         }
         else if(((cust_reg_json?.[json_type]?.[json_key]?.[idx]?.[key]?.hasOwnProperty("modified")) && (cust_reg_json[json_type]?.[json_key]?.[idx]?.[key]?.modified))){

          return this.get_field_set_tag(cust_reg_json,json_type,json_key,key,addl_key,idx,"modified")
         }
         else{
             return this.un_field_set_tag(cust_reg_json,json_type,json_key,key,addl_key,idx)
           }

        }
       
        else{
          

           return this.un_field_set_tag(cust_reg_json,json_type,json_key,key,addl_key,idx)
           }
      }
      else{
      if(((cust_reg_json[json_type][json_key][key]?.hasOwnProperty("collected_n_modified")) && (cust_reg_json[json_type][json_key][key].collected_n_modified)) ||
       (((cust_reg_json[json_type][json_key][key]?.hasOwnProperty("modified")) && (cust_reg_json[json_type][json_key][key]?.modified)))){
      
        if((cust_reg_json[json_type][json_key][key]?.hasOwnProperty("verify")) && (cust_reg_json[json_type][json_key][key]?.verify)){
          
          return this.get_field_set_tag(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null,"collected_n_modified","verified_true",verify_request_key,agreement_format)
       }
 
       else if((cust_reg_json[json_type][json_key][key]?.hasOwnProperty("verify")) && !(cust_reg_json[json_type][json_key][key]?.verify)){

          
          return this.get_field_set_tag(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null,"modified","verified_false",verify_request_key,agreement_format)
 
       }

       if(((cust_reg_json[json_type][json_key][key]?.hasOwnProperty("collected_n_modified")) && (cust_reg_json[json_type][json_key][key]?.collected_n_modified)) ){
        return this.get_field_set_tag(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null,"collected_n_modified",null, verify_request_key,agreement_format)

       }else{
        return this.get_field_set_tag(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null,"modified",null, verify_request_key,agreement_format)
       }

      }
        else{
         return this.un_field_set_tag(cust_reg_json,json_type,json_key,key,addl_key,idx=null,"modified",null,verify_request_key,agreement_format)
         }
      }
     return false;
    }
  
    get_class_name(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null){
    if(json_type=="old_cust_reg_json"){
       return "new_kyc_json";
  
     }else{
       return "re_kyc_json"
     }
    }
  
    un_field_set_tag(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null,collected_modified,verified_status,verify_request_key,agreement_format){
     return (
      <>
      {(agreement_format!=null &&  agreement_format!="custom_biz_addr") ?
       <div className=''>

       <p className='no-margin text-white business_operations_text pt-2 '>  {dd_value(key=="biz_addr_prop_type"? "SHOP_TYPE" : key.toUpperCase())}  </p>
       {addl_key!=null ?
        <h2 className='text-white audit_kyc_text no-margin pt-2 '> <b>{ dd_value(capitalize_first_letter(cust_reg_json[json_type][json_key][addl_key][key].value))}</b></h2>
        :
 
         <h2 className='text-white audit_kyc_text no-margin pt-2 '> <b>{ dd_value(capitalize_first_letter(cust_reg_json[json_type]?.[json_key]?.[key]?.value))}</b></h2>
       }
     </div>
      :
      <div className='row no-margin py-2'>

        <p className='no-margin text-white business_operations_text pt-2 col-3'>  {dd_value(key=="biz_addr_prop_type"? "SHOP_TYPE" : key.toUpperCase())}  </p>
        {addl_key!=null ?
              <h2 className='text-white audit_kyc_text no-margin col-7 '> <b>{ dd_value(capitalize_first_letter(cust_reg_json[json_type][json_key][addl_key][key]?.value))}</b></h2>:
  
              <>{idx!=null ? 
                <h2 className='text-white audit_kyc_text no-margin col-7 '> <b>{ dd_value(capitalize_first_letter(cust_reg_json[json_type]?.[json_key]?.[idx]?.[key]?.value))}</b></h2>
                :
                <h2 className='text-white audit_kyc_text no-margin col-7 '> <b>{ dd_value(capitalize_first_letter(cust_reg_json[json_type][json_key][key]?.value))}</b></h2>

              }

              </>
        }
      </div>
      }
      </>
    
      )
    }
  
      get_field_set_tag(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null,collected_modified,verified_status,verify_request_key,agreement_format){
      const rm_label =  getValueFromLangJson("rm_label_short")
        return(
     <fieldset className={`modified_fieldset`} >
      <legend className="legend_rekyc d-flex align-items-center " style={{color:` #FF4C29`,fontSize:'16px',marginBottom:'0px'}}> <b className='mr-3'> {"MODIFIED"} </b> 
      <RoundOffSplitter val={ collected_modified == "collected_n_modified" ? `The ReKYC process prompted the ${rm_label} to collect this data field and the ${rm_label} has collected the same.This is found to be different from the data that already exists in  KYC`:
       `During the ReKYC process the ${rm_label} has observed that this data field needs to be collected.This is found to be different from the data that already exists in   KYC fields`}  unit={""} align_center={true} position={"top"} notify={true}/>    
  
    </legend>
    {(agreement_format!=null && agreement_format!="custom_biz_addr") ?


      <div className='d-flex align-items-center justify-content-between  px-3 no-margin pb-2'>
      <div>
        <p className='no-margin text-white business_operations_text '>  {dd_value(key=="biz_addr_prop_type"? "SHOP_TYPE" : key.toUpperCase())}  </p>
      {addl_key!=null ?
      <h2 className='text-white audit_kyc_text '> <b> {  cust_reg_json[json_type][json_key][addl_key][key].value ? dd_value( capitalize_first_letter(cust_reg_json[json_type][json_key][addl_key][key].value)):"-"}</b></h2>
      :
      <h2 className='text-white audit_kyc_text  no-margin '> <b>{ dd_value(capitalize_first_letter(cust_reg_json[json_type][json_key][key].value))}</b></h2>

      }
      </div>
      <div>
      {(verified_status!=null && verified_status=="verified_true") ? 

       <GoVerified  color={'green'} size={25}/>
       

      : (verified_status!=null && verified_status=="verified_false") &&
     
        <a className='no-margin pointer ' style={{color:'#2FB8D8',textDecoration: 'underline',fontSize:'14px'}} onClick={()=>{ this.rekyc_verify_handler(verify_request_key)}}>Verify </a>
      }
      </div>
      </div>
   :
      <div className='row no-margin  d-flex align-items-center pb-2'>
      <p className='no-margin text-white business_operations_text col-3'>  {dd_value(key=="biz_addr_prop_type"? "SHOP_TYPE" : key.toUpperCase())}  </p>
    {addl_key!=null ?
    <h2 className='text-white audit_kyc_text col-6 '> <b> {  cust_reg_json[json_type][json_key][addl_key][key].value ? dd_value( capitalize_first_letter(cust_reg_json[json_type][json_key][addl_key][key].value)):"-"}</b></h2>
    :
    <>
    {idx!=null ? 
      <h2 className='text-white audit_kyc_text col-6 no-margin '> <b>{ dd_value(capitalize_first_letter(cust_reg_json[json_type][json_key][idx][key].value))}</b></h2>
      :    
      <h2 className='text-white audit_kyc_text col-6 no-margin d-flex align-items-center'> <b>{ dd_value(capitalize_first_letter(cust_reg_json[json_type][json_key][key].value))}</b> 
      {agreement_format=="custom_biz_addr" && cust_reg_json[json_type][json_key]["custom_biz_addr_prop_type"] &&
      <p className='text-white m-0 pl-2 sizem' > -<b> { cust_reg_json[json_type][json_key]["custom_biz_addr_prop_type"].value ? capitalize_first_letter(cust_reg_json[json_type][json_key]["custom_biz_addr_prop_type"].value) :"-"}</b> </p>
      }</h2>

    }
    </>
    
    }
    <div className='col-3 text-right'>
    {(verified_status!=null && verified_status=="verified_true") ? 

    <GoVerified  color={'green'} size={25}/>
    
    : (verified_status!=null && verified_status=="verified_false") &&

      <a className='no-margin pointer ' style={{color:'#2FB8D8',textDecoration: 'underline',fontSize:'14px'}} onClick={()=>{ this.rekyc_verify_handler(verify_request_key)}}> Verify</a>
    }
    </div>
      </div>
   }
     </fieldset>
    )
    } 
  
    rekyc_verify_handler(verify_request_key){
    var request={"lead_id":this.props.lead_id,"path":verify_request_key}
    rekycVerify(this.req(request))
    .then((response)=>{
      if(response.status=="success"){
     
        this.view_lead()
      }
      if(!response){ return }
    })
    }

  operators_selection(acc_prvdr,category,operator_type,title,addl_num_operator=null){
  return( 
     
        <Modal
          show={this.state[operator_type]}
          onHide={(e)=>this.audit_kyc_handleClose(e,operator_type)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header style={{padding:'8px 0px 0px 0px'}} closeButton>
            <Modal.Title  className='mb-2'>Change Mobile Operator </Modal.Title>

          </Modal.Header>
         
          <hr className='no-margin' style={{width:'100%',border:'1px solid gray'}} />
          <Modal.Body style={{padding:'0 10px 10px'}}>
          <p className='text-white no-margin text-center pt-3 pb-5' style={{fontSize:'15px'}}> <b> {title} - { addl_num_operator !=null ? addl_num_operator :
          (acc_prvdr=="witness_mob_num")?
           this.state.cust_reg_json.agreements[0][category] :
           this.state.cust_reg_json.mobile_numbers[category].value}</b> </p>
          <div className='d-flex row justify-content-around align-items-center'>
          <p className='text-white col-2 no-margin'>From</p>
          <div className='col-5'>
          <img  className=' pointer' src={`/img/${(addl_num_operator!=null ? acc_prvdr :
           (acc_prvdr=="witness_mob_num")?
             this.state.cust_reg_json.agreements[0]?.witness_info?.serv_prvdr?.value: 
             this.state.cust_reg_json.mobile_numbers[acc_prvdr].value)}_logo_color.png`}  style={{width:'60px',height:'30px'}}/>
          </div>
          </div>

        
         
          <div className='d-flex mt-3 row justify-content-around align-items-center'>
          <p className='text-white col-2 no-margin'>To</p>
          <div className='col-5 d-flex justify-content-center align-items-center' >
         

          <input type="text" style={{background:'#595959',padding:'0px 0px 0px 15px'}} className='form-control pointer' value={`${this.state.data_code?this.state.data_code:'Choose'}`} onClick={()=>{this.previous_comments_section(operator_type+"options")}} />
          <MdArrowDropDown  className='text-white pointer' style={{background:'#595959',height:'36px'}} size={"25"} onClick={()=>{this.previous_comments_section(operator_type+"options")}}/>
           </div> 
          </div>

          <div className='d-flex row justify-content-around align-items-cenold_cust_reg_jsonold_cust_reg_jsonter'>
          <p className='text-white col-2 no-margin'></p>
          <div className='col-5'  >
          {this.state[operator_type+"options"] &&
           <div style={{background:'#595959'}}>

           {this.state.mobile_operators.map((item,idx)=>{return(
           <>
               
              {(item.data_code !=(addl_num_operator!=null ? acc_prvdr: (acc_prvdr=="witness_mob_num")? 
              this.state.cust_reg_json.agreements[0]?.witness_info?.serv_prvdr?.value 
              : this.state.cust_reg_json.mobile_numbers[acc_prvdr].value) )&&
             
              <div className='col-10 p-0 d-flex align-items-center'>
              <img  className='pl-1 pt-2 no-margin p-1 pointer' src={`/img/${item.data_code}_logo_color.png`} onClick={()=>{this.account_owner_name_handler(item.data_code,"data_code") ; this.setState({[operator_type+"options"]:false})} } style={{width:'60px',height:'30px'}}/> 
              
              <p className=' pl-1 pt-2  text-white no-margin pl-2 pointer' onClick={()=>{this.account_owner_name_handler(item.data_code,"data_code"); this.setState({[operator_type+"options"]:false})}}>{item.data_value} </p>
              </div>
            }
             
               </>
           
           )})}   
            </div>
          } 
          </div>
          </div>
         
          <>
       
            </>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" disabled={this.state.data_code ? false :true} onClick={()=>this.Operator_selection_handler(this.state.data_code,category,operator_type,addl_num_operator,acc_prvdr)}>
              Submit
            </Button>
            <Button variant="secondary" className='ml-3'  onClick={(e)=>this.audit_kyc_handleClose(e,operator_type)}>
              Cancel
            </Button>

          </Modal.Footer>
        </Modal>
        )  
  }

  addl_retrival_api(biz_num){
    let serv_prvdr = biz_num.serv_prvdr.value
      if(((!biz_num.hasOwnProperty('holder_name')) && get('app_config').audit_kyc_line[serv_prvdr]=="ussd")){
        this.retrival_api_call_for_section(biz_num.mobile_num.value )
      } 
  }




  approve_kyc(show_watch_list = true){
    this.setState({approve_button_status:false})
    var request={
      lead_id:this.props.lead_id,
      under_watch: show_watch_list ? this.state.under_watch : 0,
      under_watch_reason:this.state.selectedOptions
    }
    approveKYC(this.req(request, true))
    .then((response)=>{
      if(!response){
        this.setState({approve_button_status:true})
        return}
      if(response.status=="success"){
        alert(response.message)
        window.open(`/borrower/indiv/view/${this.state.cust_reg_json.cust_id}`, "_blank")
        this.setState({approve_button_status:true})
        window.location.reload();

      }
    })
   }

   handlereassignModalOpen = (event) => {
    this.setState({reassignModal:true,alert_popup_box:false, reassign_pop_box:false})
    }

    audit_kyc_handleClose=(event,operator_type=null,reassign_popup=null)=>{
      if(reassign_popup !=null){
      this.setState({ reassign_pop_box:false,verified_modal:false,alert_popup_box:false})
      }else{
        delete this.state["re_entered_name"];
        this.setState({[operator_type]:false,data_code:'',reassignModal:false,audit_kyc_mobile_numbers:false,audit_kyc_confirm_identity:false,recipents_name:'',modify_status:false,alert_popup_box:false,reg_account_holder_name_mismatch_reason:'',register_mobile_holder_name:"",lead:{}})
     
      }
   }

   handleUnderWatch=(e)=>{
    const {value}=e.target;
     if(value=='yes')
     {this.setState({ under_watch: 1 });}
     else{
      this.setState({ under_watch: 0 });
      this.setState({ selectedOptions: [] });
     }

  }
  handleOptionChange = (event) => {
    const { value } = event.target
    const { selectedOptions } = this.state;
    if(value==[]){
      this.setState({ selectedOptions: [] });
      return;
    }
      if (selectedOptions.includes(value)) {
        const updatedOptions = this.state.selectedOptions.filter(option => option != value);
        this.setState({ selectedOptions: updatedOptions });
      } else {
        this.setState({selectedOptions:  value});
      }
  }
    handleClose=()=>{
      this.setState({watchlist_modal:false,under_watch:null})
    }
    audit_kyc_reassign(){
      if(this.state.reassign_remarks && this.state.audit_kyc_reassign_reason){
        var request={"lead_id":this.props.lead_id,"reassign_reason":this.state.audit_kyc_reassign_reason,"remarks":this.state.reassign_remarks}
        updateLeadStatus(this.req(request))
        .then((response)=>{
          if(!response){return}
          if(response.status=="success"){
            alert(response.message)
            this.setState({reassignModal:false})
           this.view_lead()
          }
        })
      }
    
    }
    audit_kyc_confirm_identity(type,val,holder_name,call_log_type,key_type=null){
      this.setState({
        audit_kyc_confirm_identity:true, call_log_title:[type], mobile_num_value:val,active_holder_name:holder_name,call_log_type:call_log_type,call_log_key_type:key_type
      })
    }
    audit_kyc_submit_callLog(call_log_data){
      
      var call_log_type = call_log_data ? call_log_data.section + "_"+ call_log_data.index + "_verify_identity" : this.state.call_log_type 

      var request={
        "name":  call_log_data?.holder_name?? this.state.active_holder_name,
        "lead_id":this.props.lead_id,
        [call_log_type]: 'verified'
      }
      if(this.state.recipents_name && this.state.recipents_relation){
        var request={"name":this.state.recipents_name,"relation":this.state.recipents_relation,"lead_id":this.props.lead_id,[call_log_type]: this.state.mobile_num_value,}
      }
      // if (window.confirm("Are you sure to submit the calllog ?")) {
        this.setState({call_log_verify_status:true})
        submitCallLog(this.req(request))
       .then((response)=>{
        if(!response){return}
        if(response.status=="success"){
          this.view_lead(null,"mobile_num_verify")
          alert(response.message);
          this.setState({call_log_verify_status:false})
          this.audit_kyc_handleClose()
        
    
        }
      })
      // }
    }
    handleClose=()=>{
      this.setState({watchlist_modal:false,under_watch:null})
    }
    callLogRejectHandler(reject_data){

      var call_log_type = reject_data?.section ? reject_data.section+"_"+ reject_data.index + "_verify_identity" : this.state.call_log_type 
      var request={"lead_id":this.props.lead_id, [call_log_type]: 'rejected'

      }
     
      // if (window.confirm("Are you sure to reject the calllog ?")) {
        RejectCallLog(this.req(request))
        .then((response)=>{
        if(!response){return}
        if(response.status=="success"){
          this.audit_kyc_handleClose()
          
          this.view_lead(null,"mobile_num_verify")
           alert(response.message)
          
        }
      })
      // }
    }

    checkbox_spinner(){
      return(
        <div class="d-flex col-lg-10 py-2 px-5 mt-4 justify-content-center ml-5">
        <div class="spinner-border text-light" role="status">
          <span class="sr-only">Loading...</span>
        </div>
       </div>
      )
    }

    audit_section_spinner(){
      return(
        <div class="audit_spinner" style={{margin:'20vh 0px  30vh 0%'}}>
        <span class="level level-1"></span>
        <span class="level level-2"></span>
        <span class="level level-3"></span>
        <span class="level level-4"></span>
        <span class="level level-5"></span>
        <span class="level level-6"></span>
      </div>
      )
    }

    holder_num_verify(){
      var holder_name_loader=false
      for (var i = 0; i <this.state.cust_reg_json.mobile_numbers.addl_num.length; i++) {
    
        if((this.state.cust_reg_json.mobile_numbers.addl_num[i].holder_name ==undefined)){
          holder_name_loader=true;
          return holder_name_loader;
          }else{
            holder_name_loader = false
        }   


      }

      if(((this.state.cust_reg_json.mobile_numbers.hasOwnProperty("mobile_num_holder_name")) && (this.state.cust_reg_json.mobile_numbers.hasOwnProperty("alt_biz_mobile_num_1_holder_name"))) && 
          (this.state.cust_reg_json.mobile_numbers.hasOwnProperty("alt_biz_mobile_num_2")?this.state.cust_reg_json.mobile_numbers.hasOwnProperty("alt_biz_mobile_num_2_holder_name"):true)){
         holder_name_loader=false
        
      }else{
        holder_name_loader=true

        return holder_name_loader

      }
    }

    modify_handler(type,b=null){
     this.setState({[type]:true})
    }
 

    ImageDropdown() {
      function handleSelectChange(event) {
      }}

Ismobile=()=>{
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(details);
    if (isMobileDevice) {
      return true
    } 
    else {
      return false
    }
}

handleModalClose(type){
  this.setState({pdfMOdal: type=="open" ? true :false})
}

  Pdf_modal(props) {


    return (
      <>
        <div  className="w-100 h-100 p-0  " style={{position:'relative'}} >
        {props.modal_title ?
           <object type='application/pdf' className='bg-secondary' data={`${props.root}${props.modal_title}?#toolbar=0&navpanes=0&page=1`}   width={'100%'} height={'100%'} allowtransparency='true' onClick={()=>{props.handleModalClose("open")}}
           
           > 
            {/* {props.device&&<a href={`${props.root}${props.modal_title}`} className='btn btn-primary w-100 d-block'>View Pdf </a>} */}
            {props.device&& <PDFtoImage pdfUrl={`${props.root}${props.modal_title}`}/>}
            
           </object> 
            :
           <div class="d-flex align-items-center">
              <p className='text-center float_acc_balance_view_bttn' >NO DATA</p>
            </div>
            }
           
        </div>
        <div className={`w-100 h-100 p-0 ${props.device?"":'position_ab'}`} style={{top:0}} data-toggle={`${props.device?"":'modal'}`} data-target=".bd-example-modal-lg"></div>
       

            <Modal show={props.pdfMOdal && 
              props.modal_title
              } onHide={()=>props.handleModalClose("close")} size = 'lg' >
                     <div className="row d-flex justify-content-center align-items-center ml-0">
                     <h5 className="modal-title text-center mb-0 mt-5 " id="">{props.title}</h5>

                    </div>
                    <Modal.Body >
                    <div className="modal-body py-0" >
                    <object type='application/pdf' className='forpdf'  data={`${props.root}${props.modal_title}?#toolbar=0&navpanes=0&page=1`} width={'100%'} height={'485vh'} allowtransparency='true'>
              {props.device&& <PDFtoImage pdfUrl={`${props.root}${props.modal_title}`}/>}
              </object>
                </div>
                <div className="modal-footer d-flex">
                <a href={`${props.root}${props.modal_title}`} className='btn btn-success btn-sm my-0 mx-3' target="_blank">Download Pdf</a>
                  <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal" onClick={()=>props.handleModalClose("close")}>Close</button>
                  
                </div>
                    </Modal.Body>
            </Modal>



      </>
    );
  }
  handleUnderWatch=(e)=>{
    const {value}=e.target;
     if(value=='yes')
     {this.setState({ under_watch: 1 });}
     else{
      this.setState({ under_watch: 0 });
      this.setState({ selectedOptions: [] });
     }

  }
  handleOptionChange = (event) => {
    const { value } = event.target
    const { selectedOptions } = this.state;
    if(value==[]){
      this.setState({ selectedOptions: [] });
      return;
    }
      if (selectedOptions.includes(value)) {
        const updatedOptions = this.state.selectedOptions.filter(option => option != value);
        this.setState({ selectedOptions: updatedOptions });
      } else {
        this.setState({selectedOptions:  value});
      }
  };
  handleRejectKYC(event) {

    
    var request ={
        lead_id:this.props.lead_id,
        reason:this.state.reject_reason,
        remarks:this.state.reject_remarks
    }
   
        const resp = window.confirm(`Have you checked with the ${this.state.rm_label??''}? There is no way to correct the KYC after it has been rejected. Are you sure to mark the KYC as failed?`)
        if(resp){
          rejectKYC(this.req(request)).
                then((response) =>{
                    this.resp(response)
                    if(!response){return };
                    if(response.status === "success"){
                        alert(response.message)
                        window.location.replace("/");
                    }
                    else
                    {
                        alert(response.message + "\n" + JSON.stringify(response.data));
                    }
                })
        }
    
}
       


  

    render(){
      const audit_kyc = this.props.audit_kyc && this.props.audit_kyc[this.state.active_key]
      const  show_watch_list = check_priv("customer","watchlist") 
      const signature_ref_path = this.state.cust_reg_json?.data_consent?.signature_ref_path??null
      const profile_closed = this.state.responseData?.profile_status =="closed"
      const rm_full_name_label = getValueFromLangJson('rm_label')
      const { audit_kyc_wizard_menus, responseData } = this.state;

      this.Ismobile()
        return( 
          <>{this.state.screen_width && this.state.screen_width > 600 ?
            <div className='audit_kyc_component'>
                <Modal
                  show={this.state.reassignModal}
                  onHide={()=>this.audit_kyc_handleClose()}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Reassign Reason</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  <div className=" d-flex align-items-center row mx-0 mb-2 ">
                    <label for=""  className="col-sm-5 font-weight-bold label__name pl-4 no-margin">Reason<font className="text-danger">   *  </font></label>
                    <div className="col-sm-7 reset-padding">
                        <div>

                        <select id="reason" onChange={(e)=>this.account_owner_name_handler(e.target.value,"audit_kyc_reassign_reason")} className="form-control" type="text" >
                            {this.getSelectOptions('reassign_reason')}
                        </select>

                        </div>
                        <div className="control-me"></div>
                    </div>
                </div>
                <div className=" d-flex align-items-center row no-margin">
                    <label for="" className="col-sm-5 font-weight-bold label__name  no-margin  ">Remarks<font className="text-danger">   *  </font></label>
                    <div className="col-sm-7 reset-padding">
                        <div>
                            <textarea type="text" className="form-control" onChange={(e)=>this.account_owner_name_handler(e.target.value,"reassign_remarks")}  maxLength='256'/>
                        </div>
                    </div>
                </div> 

                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" disabled={(this.state.reassign_remarks && this.state.audit_kyc_reassign_reason) ? false :true} onClick={()=>this.audit_kyc_reassign()}>
                      Reassign
                    </Button>
            
                  </Modal.Footer>
                </Modal>
                
             
            <div className='row  d-flex audit_kyc_wizard_sticky_header no-margin align-items-center justify-content-center sticky_border '>

              <div className='col-lg-9 col-md-12 col-sm-12 ' style={{marginTop:'-5px',position:'relative'}}>
              {(this.state.active_section && this.state.audit_response && this.state.cust_reg_json )&&
              <div className=' d-flex justify-content-end audit_kyc_wizard_sticky_header  align-items-center sticky_border  col-md-12  my-3  audit_kyc_wizard_menus'>

                {(this.state.responseData.distributor_details || this.state.cust_reg_json.sub_lender_code) &&
                <p className='m-0 text-white px-3 py-2  align-items-center lenderCode_style' >
                <p className='m-0 text-white pr-2'> {this.state.responseData.distributor_details ? 'Distributor' : 'Lender' }
                </p>
                <div className='d-flex  align-items-center '>
                <p className='m-0 text-white pr-2'><b>{this.state.responseData.distributor_details ?  this.state.responseData.distributor_details.distributor_name : this.state.lender_name}</b> </p>
                <RoundOffSplitter val={this.state.responseData.distributor_details ? this.state.responseData.distributor_details.disb_text : `This customer's FAs will be disbursed using ${this.state.lender_name} funds`} unit={""} align_center={true} position={"right"} notify={true} />
              
                </div>
                </p>
                }

                {this.state.responseData.distributor_details ? <></> : 
                <>
                {this.state.cust_reg_json.sub_lender_code &&
                  <p className='m-0 text-white px-3 py-2  align-items-center lenderCode_style' >
                  <p className='m-0 text-white pr-2'> Lender 
                  </p>
                  <div className='d-flex  align-items-center '>
                  <p className='m-0 text-white pr-2'><b>{this.state.lender_name}</b> </p>
                  <RoundOffSplitter val={`This customer's FAs will be disbursed using ${this.state.lender_name} funds`} unit={""} align_center={true} position={"right"} notify={true} />
                
                  </div>
                  </p>
                  }
                </>
                
                }

                 {this.state.audit_kyc_wizard_icons && this.state.audit_kyc_wizard_icons.map((item,idx)=>{return(
                   <>
                     <div >
                     
                      <div className={`d-flex align-items-center pointer`} onClick={()=>this.view_lead(null,null,item.key)} style={{position:'relative'}} >
                        {(item.key =="agreements")? 
                        <>{this.state.audit_response[item.key][0] &&
                          <div style={{position:'relative'}} className={`${ (this.state.audit_response?.[item.key]?.[0]?.verify)? "section_verified  " :"audit_kyc_verification_wizard"}  ${this.state.audit_kyc_wizard_menus[item.key] ? (this.state.active_section?.[0]?.verify )? 'section_verified verified_menu' : "active_verification_wizard" : ""} ${this.state.audit_response[item.key][0].to_reassign && "verification_failed " } `}>
                          {this.state.audit_response[item.key][0].comments && this.state.audit_response[item.key][0].comments.length >0 &&
                            <p className='no-margin audit_kyc_comments_count' >{this.state.audit_response[item.key][0].comments.length }</p>
                            }
                          <p className={`no-margin text-white audit_kyc_icon_font`}> {item.icon}</p> 
                           </div>}
                           </>
                        :
                          <div style={{position:'relative'}} className={`${ (this.state.audit_response[item.key=="aggrements"? item.key[0]:item.key].verify)? "section_verified  " :"audit_kyc_verification_wizard"}  ${this.state.audit_kyc_wizard_menus[item.key] ? (this.state.active_section.verify )? 'section_verified verified_menu' : "active_verification_wizard" : ""} ${this.state.audit_response[item.key].to_reassign && "verification_failed " } `}> 
                          
                          {this.state.audit_response[item.key].comments && this.state.audit_response[item.key].comments.length >0 &&
                            <p className='no-margin audit_kyc_comments_count' >{this.state.audit_response[item.key].comments.length }</p>
                            }
                          <p className={`no-margin text-white audit_kyc_icon_font`}> {item.icon}</p> 
                      </div>
                 }
                    
                      
                       {idx < `${this.state.menu_idx ? this.state.menu_idx-1:8}` &&
                        <div className={`${this.state.audit_response[item.key].verify ? "verified_wizard" :"audit_kyc_wizard_right_border"} ${this.state.audit_response[item.key].to_reassign && "verification_border_failed audit_kyc_wizard_right_border" }`}>

                        </div>
                       }
                      
                      </div> 

                     <p className={`text-white mb-0 mt-2 audit_kyc_title`} style={{marginLeft: `${item.key=="gps_address" ? "-8px":"-5px"}`}} > {item.title}</p>

                     
                     </div> 
                    
                     </>
                 )})}
              </div> 
              }

              </div>
            
              {this.state.responseData && this.state.active_section &&
              <div className={`col-sm-12 col-lg-3 ${ (((this.state.responseData.audited_by== get("user_id")) || (this.state.responseData.audited_by==null ) ) &&(this.state.active_section.pending_with!="rm") )?"":'disable_div'}`}>
              {this.props.status >= "59_pending_enable"  || profile_closed ?"":

             <div className={`d-flex justify-content-end`} style={{marginRight:'17px'}}>
              <div className='d-flex p-2' style={{background:'#445668'}}>
              <button className='btn btn-danger mb-0' style={{background:'#c33b15'}} disabled={((this.state.responseData.audited_by== get("user_id")) || (this.state.responseData.audited_by==null )) ?false:true} onClick={()=>this.setState({reject_modal:true})}><b>Reject KYC</b></button>  
             
              <button type="button" class="btn btn-success" style={{margin:'0px 5px'}} disabled={( this.state.responseData.status=="50_pending_audit" && this.state.responseData.approval_status.action =='approve' && this.state.approve_button_status )? false : true} onClick={()=> {show_watch_list ? this.setState({watchlist_modal:true}) :this.approve_kyc(show_watch_list) }}> 
                
                <b>Approve</b></button>


              <Modal className='watchlist_width' show={this.state.watchlist_modal} onHide={this.handleClose} backdrop="static" keyboard={false}>
                <Modal.Header className='p-0  watch_list_header' closeButton>
                  <Modal.Title className=' p-2 '>
                    <h4 className=' text-center my-2 watchlist_font_h text-light font'>Watchlist</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center px-0'>
                  <div className="w-100 msg_container px-0 watchlist_font_l">
                    <div className='row w-100 mx-0 my-2'>
                      <p className='col-md-5 p-0 m-0 text-right text-light watchlist_font_l'>Add Profile to Watchlist <span className='product_red_star font'>*</span> : </p>
                      <div className='col-md-6 d-flex  align-items-center'>
                        <div>
                          <input className='mr-2' type="radio" id="yes" name="watchlist" value='yes' onClick={(e)=>this.handleUnderWatch(e)}/>
                          <label className='mr-5 mb-0'for="yes">Yes</label>
                        </div>
                        <div>
                          <input className='mr-2 ml-4'type="radio" id="no" name="watchlist" value='no' onClick={(e)=>this.handleUnderWatch(e)}/>
                          <label className='mb-0' for="no">No</label>
                        </div>
                      </div>
                    </div>
                    {this.state.under_watch ?
                    <div className='row w-100 mx-0 my-4 d-flex align-items-center'  style={{paddingBottom:'5vh'}}>
                      <p className='col-md-5 p-0 text-right m-0 text-light watchlist_font_l'>Reason {this.state.under_watch===1&&<span className='product_red_star font'>*</span>}: </p>
                      <div className='col-md-6 d-flex justify-content-left align-items-center'>
                        <div className={`col-12 w-100 p-0 m-0 watchlist_font ${this.state.under_watch===null||this.state.under_watch===0? 'watchlist_disable_option':''}`} >
                          <AccPurposeMultiSelect id="under_watch"  onChange={this.handleOptionChange} acc_purposes={this.getSelectOptions('under_watch')} initialAct_purpose={false} must={false}/>                    
                        </div>
                    </div>
                    </div>:""
                    }
                    <div className={`d-flex w-100 justify-content-center mt-3 ${!this.state.under_watch?"mt-5":""}`}style={{paddingBottom:'4vh'}}>
                    <button type="button" class="btn btn-success" disabled={!this.state.approve_button_status?true:(this.state.under_watch===1&&this.state.selectedOptions.length==0)?true:(this.state.under_watch===null)?true:false} onClick={()=> this.approve_kyc()}>Approve</button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              </div>                        
             </div>
              
             }
               {/* {this.props.status >= "59_pending_enable" ? "" :
                <div className='col-md-12 audit_reject_btn' >
                <button className='btn btn-danger' disabled={((this.state.responseData.audited_by== get("user_id")) || (this.state.responseData.audited_by==null )) ?false:true} onClick={()=>this.setState({reject_modal:true})}>Reject KYC</button>  
               </div>
              } */}
              </div>
              }
              
              <Modal show={this.state.reject_modal} onHide={()=>this.setState({reject_modal:false})} size="l">
            <Modal.Header closeButton show={this.state.reject_modal} onHide={this.props.handleModalClose}>
                <Modal.Title>Reject Reason</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group row mx-0 mb-2 ">
                    <label for=""  className="col-sm-5 font-weight-bold label__name reset-padding">Reason<font className="text-danger">   *  </font></label>
                    <div className="col-sm-7 reset-padding">
                        <div>
                        <select id="reason" onChange={(e)=>this.setState({reject_reason:e.currentTarget.value})} className="form-control" type="text" >
                            {this.getSelectOptions('reject_reason')}
                        </select>
                        </div>
                        <div className="control-me"></div>
                    </div>
                </div>
                <div className="rule-form-group form-group row reset-margin">
                    <label for="" className="col-sm-5 font-weight-bold label__name reset-padding">Remarks<font className="text-danger">   *  </font></label>
                    <div className="col-sm-7 reset-padding">
                        <div>
                            <textarea type="text" className="form-control" onChange={(e)=>this.setState({reject_remarks:e.target.value})}  maxLength='256'/>
                        </div>
                    </div>
                </div>         
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled = {(!this.state.reject_reason||!this.state.reject_remarks)?true:false} onClick={()=>this.handleRejectKYC()}>Submit</Button>
            </Modal.Footer>
            </Modal>
            </div>
            {(this.state.responseData && this.state.responseData.type =="re_kyc" ) ? 
        
            <div>
            <AuditRekycComponent root={this.state.root} update_mismatch_reason={(a,b,c,d)=>this.update_mismatch_reason(a,b,c,d)} operators_selection={(a,b,c,d,e)=>{return this.operators_selection(a,b,c,d,e)}} previous_comments_section={(a)=>{this.previous_comments_section(a)}} lead_id={this.props.lead_id}   sufficient_document_radio_button_handler={(a,b)=>{this.sufficient_document_radio_button_handler(a,b)}} rekyc_modify_state_handler={(a,b,c,d,e,f,g,h)=>{ return this.rekyc_modify_state_handler(a,b,c,d,e,f,g,h)}} checkbox_verification={(a,b,c)=>this.checkbox_verification(a,b,c)}
              audit_kyc_submit_callLog={(data)=>this.audit_kyc_submit_callLog(data)}
               callLogRejectHandler={(data)=>this.callLogRejectHandler(data)}
                audit_kyc_modify_handler={(a,b,c,d)=>this.audit_kyc_modify_handler(a,b,c,d)} view_lead={(a,b,c)=>this.view_lead(a,b,c)} modify_handler={(a,b)=>this.modify_handler(a,b)}    verify_handler={(a,b,c,d)=>this.verify_handler(a,b,c,d)} audit_kyc_confirm_identity={(a,b,c,d,e)=>this.audit_kyc_confirm_identity(a,b,c,d,e)} file_upload_submit_handler={(a,b,c,d)=>this.file_upload_submit_handler(a,b,c,d)} data={this.state} setAuditLeadState={(a)=>this.setAuditLeadState(a)} audit_kyc_handleClose={(a,b,c)=>this.audit_kyc_handleClose(a,b,c)}  holder_num_verify={()=>this.holder_num_verify()} viewLead={()=>this.view_lead()}  account_owner_name_handler={(a,b,c,d,e,f,g,h)=>this.account_owner_name_handler(a,b,c,d,e,f,g,h)}
              />
           </div>


            : 
           <div className={profile_closed ? 'disable_div' :""} >
          
            {(this.state.audit_kyc_wizard_menus.id_proof ) &&

            <AuditKYCOwnerInfoComponent lead_id={this.props.lead_id} checkbox_verification={(a,b,c)=>this.checkbox_verification(a,b,c)} owner_and_biz_info_data={this.state} view_lead={(a,b,c)=>this.view_lead(a,b,c)} />
            }

            {(this.state.audit_kyc_wizard_menus.gps_address ||this.state.audit_kyc_wizard_menus.contact_persons ) &&

            <AuditKYCMapandHandlerComponent  lead_id={this.props.lead_id} checkbox_verification={(a,b,c)=>this.checkbox_verification(a,b,c)} handler_and_maplocation_data={this.state}  view_lead={(a,b,c)=>this.view_lead(a,b,c)}/>
            }

           

            {this.state.audit_kyc_wizard_menus.biz_accounts &&
            
            <AuditKYCAccountOwnershipComponent root={this.state.root} update_mismatch_reason={(a,b,c,d)=>this.update_mismatch_reason(a,b,c,d)}   lead_id={this.props.lead_id} setAuditLeadState={(a)=>this.setAuditLeadState(a)} file_upload_submit_handler={(a,b,c,d)=>this.file_upload_submit_handler(a,b,c,d)} view_lead={(a,b,c)=>this.view_lead(a,b,c)}   account_owner_name_handler={(a,b,c,d,f,g,h,i)=>{this.account_owner_name_handler(a,b,c,d,f,g,h,i)}} checkbox_verification={(a,b,c)=>this.checkbox_verification(a,b,c)} account_ownership_datas={this.state} retrival_api_call_for_section={this.retrival_api_call_for_section} verify_handler={(a,b,c,d)=>this.verify_handler(a,b,c,d)} />
            }

            {(this.state.audit_kyc_wizard_menus.kyc_photos || this.state.audit_kyc_wizard_menus.shop_photo) &&

            <AuditKYCPhotosVerification  lead_id={this.props.lead_id} checkbox_verification={(a,b,c)=>this.checkbox_verification(a,b,c)} shop_and_kyc_photo_datas ={this.state} view_lead={(a,b,c)=>this.view_lead(a,b,c)}/>
            }
              {this.state.audit_kyc_wizard_menus.mobile_numbers &&
            <AuditKYCMobileNumberVerification lead_id={this.props.lead_id} audit_kyc_submit_callLog={(data)=>this.audit_kyc_submit_callLog(data)}  callLogRejectHandler={(data)=>this.callLogRejectHandler(data)} audit_kyc_modify_handler={(a,b,c,d)=>this.audit_kyc_modify_handler(a,b,c,d)} modify_handler={(a,b)=>this.modify_handler(a,b)} operators_selection={(a,b,c,d,f)=>this.operators_selection(a,b,c,d,f)} previous_comments_section={(a)=>this.previous_comments_section(a)} verify_handler={(a,b,c,d)=>this.verify_handler(a,b,c,d)} audit_kyc_confirm_identity={(a,b,c,d,e)=>this.audit_kyc_confirm_identity(a,b,c,d,e)} file_upload_submit_handler={(a,b,c,d)=>this.file_upload_submit_handler(a,b,c,d)} setAuditLeadState={(a)=>this.setAuditLeadState(a)} biz_mobilenumbers_data={this.state} audit_kyc_handleClose={(a,b,c)=>this.audit_kyc_handleClose(a,b,c)}  holder_num_verify={()=>this.holder_num_verify()} account_owner_handler={(a,b,c,d,e,f,g,h)=>this.account_owner_name_handler(a,b,c,d,e,f,g,h)}  />
            }
  
            {this.state.audit_kyc_wizard_menus.data_consent &&
            <>{this.state.View_lead_section_loader ? <div className="d-flex justify-content-center">{this.audit_section_spinner()}</div>:

            <div className='row no-margin justify-content-center'>
            <div className='col-lg-12 py-3 mt-5 ' style={{background:' #01062c'}}>
              <h2 className='text-white text-center'>
               <b>{this.state.cust_reg_json?.same_as_owner_person ? "7" :"8" } - Data Consent Verification</b> 
              </h2>
              <h2 className=' d-flex mt-1 text-center justify-content-center align-items-center' ><img src='/img/pending_with_rm.png' height={'50'}/> <b className='ml-2 align-self-cente' style={{color:'#F3EA0B'}}> Pending with {this.state.active_section && (this.state.active_section.pending_with=="rm"? 
                this.state.rm_label??'':'YOU')}</b></h2>

             </div>
           
             {this.state.active_section  &&
              <>

             <>
            
             <div className='col-sm-6 p-2 mt-4'>
              <div className='m-2 text-light border h-100 w-100' >

                {(this.state.cust_reg_json.data_consent) &&

          <div style={{height:'100%'}} onClick={()=>{!this.state.pdfMOdal ?  this.handleModalClose('open'):null}}>
                
                 <this.Pdf_modal  title={"Data Consent PDF"} root={this.state.root} device={this.Ismobile()} modal_title= {this.state.cust_reg_json.data_consent.signed_consent_path ? this.state.cust_reg_json.data_consent.signed_consent_path :''} pdfMOdal={this.state.pdfMOdal}  handleModalClose ={(type)=>{this.handleModalClose(type)}}/>
                </div>
                 
                 }
                 {this.state.active_section &&
                  <div>
                      {this.state.active_section.verify &&
                        <div className="closedStamp ">
                        <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                        </div>   
                      }
                  </div>
                 }
                
                
               
              </div>
             </div>
             <div className='col-sm-6 p-2 mt-4'>
              <div className='m-2 text-light border h-100 w-100  p-5 mb-5' >

                 
              {/* <p className='no-margin text-white  business_operations_text'>  BUSINESS NAME  </p>
              <h2 className='text-white audit_kyc_text'> <b>{ capitalize_first_letter(this.state.cust_reg_json.license_proof.biz_name.value)}</b></h2> */}
              <p className='  no-margin text-white  business_operations_text pt-4'>CUSTOMER NAME</p>
              {this.state.cust_reg_json.id_proof.first_name &&

              <h2 className='text-white audit_kyc_text bold sizel  '>{capitalize_first_letter(this.state.cust_reg_json.id_proof.first_name.value)}&nbsp;{capitalize_first_letter(this.state.cust_reg_json.id_proof.last_name.value)}</h2>}
             
              <p className='no-margin text-white   business_operations_text pt-4'>MOBILE NUMBER</p>
              <h2 className=' bold sizel  text-white audit_kyc_text'>{this.state.cust_reg_json.mobile_numbers?.biz_mob_num?.[0]?.mobile_num?.value} </h2>
             
              {/* <p className=' no-margin text-white   business_operations_text pt-4 '>Consent Date</p>
              <h2 className='text-white audit_kyc_text bold  sizel ' > {  this.state.cust_reg_json.data_consent.consent_signed_date ?moment(this.state.cust_reg_json.data_consent.consent_signed_date).format("DD-MMM-YYYY"):"-" }</h2>
              */}
              <p className=' no-margin text-white   business_operations_text pt-4 pb-3 '>SIGNATURE REFERENCE</p>
              {this.state.cust_reg_json.id_proof && this.state.cust_reg_json.id_proof.photo_id_proof &&
              <>
            {   signature_ref_path ? 
                    <div>
                    <img src={ this.state.root + signature_ref_path}
                   alt={signature_ref_path} style={{width:'450px',height:'400px'}} />
                   </div>
                :
              <>
              { get('market').country_code =="MDG" ? 
              
                <DisplayImage image_path={get_dir_path(this.state.cust_reg_json.id_proof.photo_id_proof_back.path,this.state.cust_reg_json.id_proof.photo_id_proof_back.value)} style={{height: '350px',width:'100%'}} file_name={this.state.cust_reg_json.id_proof.photo_id_proof_back.value} />

              :
                <DisplayImage image_path={get_dir_path(this.state.cust_reg_json.id_proof.photo_id_proof.path,this.state.cust_reg_json.id_proof.photo_id_proof.value)} style={{height: '350px',width:'100%'}} file_name={this.state.cust_reg_json.id_proof.photo_id_proof.value} />
              }
              </>
              }
             </>}
             </div>
             </div>
             
            {this.state.active_section &&
            <>
            
             <form className={`col-sm-12 mt-3 mb-3 ${(this.state.active_section.verify || (this.state.active_section?.to_reassign) )? "disable_div":''}   ${ (((this.state.responseData.audited_by== get("user_id")) || (this.state.responseData.audited_by)) && this.state.active_section.pending_with!="rm") ? "":'disable_div'}`}>
                     {this.state.active_section &&
                    <div className='d-flex align-items-center'>

                    <input type="checkbox" id="vehicle1" className='pointer' style={{height:'19px',width:'20px'}} checked={audit_kyc && this.props.audit_kyc['data_consent'].consent_form_data_match && true}
                     onChange={(e)=>this.checkbox_verification(e.target.checked,"consent_form_data_match")} />
                    <label className='no-margin pl-3 audit_kyc_text pointer' for="vehicle1">The business name, customer name, mobile number and consent date matches with the data consent form </label><br/><br/>
                    </div>}

                    { audit_kyc && this.props.audit_kyc['data_consent'].hasOwnProperty("signature_match") &&
                    <div className='d-flex align-items-center'>
                    <input type="checkbox" id="vehicle2" className='pointer' style={{height:'19px',width:'20px'}} checked={audit_kyc && this.props.audit_kyc['data_consent'].signature_match && true} onChange={(e)=>this.checkbox_verification(e.target.checked,"signature_match")} />
                    <label className='no-margin pl-3 audit_kyc_text pointer' for="vehicle2">{`The signature of the customer in the data consent form matches with the signature in the ${getLabel('national_id')}`}</label><br/><br/>
                    </div>
                    }
                </form>
                
            </>}
             </>
            </>}
            </div>
            }</>
            }

            {this.state.audit_kyc_wizard_menus.agreements &&
            <AuditKYCAgreementsVerification lead_id={this.props.lead_id} Ismobile={()=>{this.Ismobile()}} account_owner_name_handler={(a,b,c,d,e,f,g,h)=> {this.account_owner_name_handler(a,b,c,d,e,f,g,h)}}  operators_selection={(a,b,c,d,e)=>{return this.operators_selection(a,b,c,d,e)}} previous_comments_section={(a)=>{this.previous_comments_section(a)}} agreements_data={this.state} checkbox_verification={(a,b,c)=>this.checkbox_verification(a,b,c)} setAuditLeadState={(a)=>this.setAuditLeadState(a)} file_upload_submit_handler={(a,b,c,d)=>this.file_upload_submit_handler(a,b,c,d)} 
            verify_handler={(a,b,c,d)=>{this.verify_handler(a,b,c,d)}}
            view_lead={(a,b,c)=>this.view_lead(a,b,c)}
             />
           
            }
           </div>
    }
           
            {this.state.active_section && this.state.responseData && (!this.state.View_lead_section_loader) &&
              <div className={`row no-margin justify-content-center ${(((this.state.responseData.audited_by== get("user_id")) || (this.state.responseData.audited_by==null ) ) && (((this.state.active_key=="agreements") )?( this.state.active_section[0].pending_with!="rm"): ( this.state.active_section.pending_with!="rm"))) ?"":'disable_div'}`} style={{position: (this.state.active_key=="mobile_numbers" &&  !this.state.active_section.verify) ?"":
              'relative'}} >                    
                <div className='col-lg-10 py-5 mt-5 px-5' style={{background:'#223b56'}}>
                <div className= {`d-flex row justify-content-center align-items-center ${this.state.active_section.verify ? "disable_div" :""}`}>
              
                <div className={`col-md-12 col-lg-12 no-padding ${this.state.active_section.verify ?'bg_after_verify_comment':''}`}>
                  <div className={`${(this.state.active_section.verify || profile_closed) ? "disable_div" :""}  ${(((this.state.responseData.audited_by== get("user_id")) || (this.state.responseData.audited_by==null )) && this.state.active_section.pending_with!="rm") ?"":'disable_div'}  `}  style={{border:'1px solid #D9D9D9'}} >
                  <h2 className='text-white audit_kyc_text no-margin px-4 py-3'> <b>Discussions  </b>  <span>( Review Comments added here will be visible to the {this.state.rm_label??''} on their Mobile App )</span> </h2>
                  <hr className='no-margin'/>
                  <div className=' p-4' >

                  <div className='row px-3 d-flex'>
                <div className='col-lg-9 col-md-12'>
                <textarea  
                maxLength={200}
                className={`audit_kyc_text_area ${this.state.active_section.verify ?'bg_after_verify_comment':''}`} value={this.state[[this.state.active_key]+"_comments"] ? this.state[[this.state.active_key]+"_comments"] :''} placeholder="Add your review comment"  onChange={(e)=>this.audit_kyc_comments(e.target.value,this.state.active_key)}  name="w3review"  rows="3"   >
                </textarea>
                <p className='d-flex justify-content-end m-0 text-white'>
                      {this.state[this.state.active_key + "_comments"]?.length || 0} / 200
                </p>
                </div>
            
                <div className='col-md-12 no-padding col-xl-3 col-sm-12 col-lg-3  d-flex align-items-end'>
                <button type="button" className="btn btn-primary audit_kyc_active_list" disabled={this.state[this.state.active_key+"_comments"] ?false :true}  onClick={()=>this.verify_comments_handler("informative")}> Information</button>
                <button type="button" className="btn  audit_kyc_ressign_button ml-2 text-white" disabled={this.state[this.state.active_key+"_comments"] ?false :true}  onClick={()=>this.verify_comments_handler("reassign")} > <b className='text-white'>Reassign</b></button>

                </div> 
               </div>
              {this.state.text_blink &&
                   
                      <Alert  variant="success" className="alert alert-success audit_kyc_alert  mx-3 mt-2" show={this.state.text_blink}  onClose={() => this.alert_close()} dismissible>

                      <p className='no-margin' style={{fontSize:'13px'}}> •
                        {(this.state.comment_type == "informative") ? 
                        `The provided information has been sent to the ${rm_full_name_label}`
                        :(this.state.comment_type == "reassign") ? `This section has been reassigned to the ${rm_full_name_label} for further processing` :""
                        }
                      </p>
                      
                    </Alert>
              }
                { this.state.active_section_comments &&   ((this.state.active_key === "agreements"  ) ? this.state.active_section_comments.length > 0 : this.state.active_section_comments.length >0) &&
                <>
                <div className=' px-0 d-flex align-items-center' onClick={()=>this.previous_comments_section("previous_section")}>
                <p className='text-white no-margin audit_kyc_text pl-3 py-3 pr-3 pointer'> Previous Comments </p>
                {this.state.previous_section ?
                <ImCircleUp  className='pointer' color={"white"} size={20}/>:   <ImCircleDown  className='pointer' color={"white"} size={20}/>}
                </div>
                </>
                }

                {this.state.previous_section && this.state.active_section_comments &&
                <div className='row px-3  no-margin'>
                  {this.state.active_section_comments.map((item,idx)=>{return(
                     <div className='d-flex justify-content-between align-items-end my-3 row mx-0 col-lg-10' style={{background:'#6A737C'}}>
                     <div className='my-2 col-2 px-0'>
                     <h6 className='no-margin' style={{fontSize:'13px',color:'#F1EC70'}}>  {  moment(item.date_time).format("DD-MMM-YYYY")}</h6>
                     </div>
                     <div className='my-2 col-10 px-0 d-flex justify-content-between align-items-end '>
                        
                         <h2 className='no-margin text-white' style={{fontSize:'15px'}}>{item.comment} </h2>
                         <h6 className='no-margin' style={{fontSize:'13px',color:'#F1EC70',textAlign:'end'}}> <b>{ get('user_id')== item.person_id ? "You": item.first_name} </b>
                         </h6>
                     </div>
                     </div>
                  )
                   
                  })}
               
                </div>
                }
   
                </div>
              </div>
           
                </div>
               
            </div>


                </div>
                { this.state.active_section.verify &&
                <div className={`col-lg-6 py-4 rounded  float_acc_balance_view_bttn ${this.state.active_section.verify ?'after_verify_comment_pop':''}`} style={{background:'#030303'}}>
                 <p className='text-white no-margin audit_kyc_text'>You can't add comments, as you have already verified this section.</p>
                </div>
                 }
              </div>
            }

            {this.state.responseData &&   (!this.state.View_lead_section_loader) && 
            <>
            {this.props.status >= "59_pending_enable" || profile_closed ? "" :
              <AuditKycVerificationSections account_owner_checkbox_handler={()=>{this.account_owner_checkbox_handler()}} verification_datas={this.state} unverify_handler={(a)=>{this.unverify_handler(a)}} verify_handler={(a,b,c,d)=>{this.verify_handler(a,b,c,d)}}/>

            }
            </>
            }


             {this.state.active_section &&  (!this.state.View_lead_section_loader) &&
              <div className={`row d-flex justify-content-between mb-5 px-4  ${this.props.status >= "59_pending_enable" ? "mt-2":''}` } style={{marginTop:`${(this.state.active_section.to_reassign) ? "0%" : (this.state.active_key=="agreements" ||this.state.active_key=="data_consent" ) ?"-5%":'-5%'}`}}>
               
              <div className={`col  d-flex btn_holder_left  justify-content-left text-center my-5 mx-1 ${this.state.index==0 ?"disable_div invisible":""}`} >
              
              <button className='text-white no-margin  btn btn-secondary btn-sm' onClick={this.togglePrev.bind(this)}>{
               <HiArrowCircleLeft  style={{marginRight:'10px'}} size={30} className='text-white  bottom-arrow-left' onClick={this.togglePrev.bind(this)} />}
              <b>{ this.state.index==0 ? "" : (this.state.responseData.type=="re_kyc" && this.state.index== ( this.state.audit_kyc_wizard_icons && this.state.audit_kyc_wizard_icons.length -1) )?  capitalize_first_letter((this.state.audit_kyc_wizard_icons && (this.state.audit_kyc_wizard_icons[this.state.index-1].title))) : capitalize_first_letter((this.state.audit_kyc_wizard_icons && (this.state.audit_kyc_wizard_icons[this.state.index-1].title)))}</b>
              </button>
              </div>
              
              {
              <div className={`col d-flex btn_holder_right justify-content-end text-center my-5 mx-1 ${this.state.index== (this.state.audit_kyc_wizard_icons && this.state.audit_kyc_wizard_icons.length -1) ?"disable_div invisible":""}`}>
              <button className='text-white no-margin  btn btn-secondary btn-sm' onClick={this.toggleNext.bind(this)} > 
            <b> { (this.state.index ==(this.state.audit_kyc_wizard_icons && (this.state.audit_kyc_wizard_icons.length -1)) ) ? capitalize_first_letter((this.state.audit_kyc_wizard_icons[this.state.index].title)) :   capitalize_first_letter((this.state.audit_kyc_wizard_icons && (this.state.audit_kyc_wizard_icons[this.state.index+1].title)))} </b> 
              {<HiArrowCircleRight  style={{marginLeft:'10px'}} onClick={this.toggleNext.bind(this)}  size={30} className='text-white arrow-right'  />}</button>
              
            </div>
            }
           
             </div>
             }
            </div>
            :
            <h2 className='text-white text-center my-3 mb-5'>You cannot proceed with the Audit process in a Mobile device. Please use your laptop or tablet.</h2>
            }
          </>
          
        )
    }
  }

  const mapStateToProps = (state) => {
    return {
      audit_kyc: state.common_slice,
    };
    };
  
  const mapDispatchToProps ={
      updateValuesIntoRedux
    
    };

export default connect(mapStateToProps, mapDispatchToProps)(AuditKycVerificationComponent);