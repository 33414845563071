import {call_fetch, app_path} from './../helpers/fetch_helper';

export const delinquencyCallTask= request => {
    return  call_fetch(app_path + '/delinquency/overdue_call_tasks', request) 
}
export const getCallLog= request => {
    return  call_fetch(app_path + '/delinquency/overdue_cust_details', request) 
}
export const updateCalls= request => {
    return  call_fetch(app_path + '/delinquency/update_calls', request) 
}
export const overDueDataBydaya =request =>{
    return  call_fetch(app_path + '/delinquency/overdue_by_days', request)    
}
export const getCommitmentDatas =request =>{
    return  call_fetch(app_path + '/delinquency/od_by_commitment_date', request)    
}
export const setCallLog =request =>{
    return  call_fetch(app_path + '/delinquency/od_cust_call_logs', request)    
}
export const submitCallLog =request =>{
    return  call_fetch(app_path + '/delinquency/submit_call_log', request)    

}
export const recoveryHistory =request =>{
    return  call_fetch(app_path + '/delinquency/recovery_history', request)    

}
export const pendingRMvisit =request =>{
    return  call_fetch(app_path + '/delinquency/pending_rm_visit', request)    

}
export const completeRMreminder =request =>{
    return  call_fetch(app_path + '/delinquency/complete_rm_reminder', request)    

}
export const RMlISTS =request =>{
    return  call_fetch(app_path + '/delinquency/get_rm_list', request)    

}

export const getDeliquencyById = req => {
    return call_fetch(app_path + "/delinquency/get_overdue_fa_by_id", req)
}



