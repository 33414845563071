import { createContext, Dispatch } from "react";
import { CallLogForScreenProps } from "../softPhoneCtx/softPhoneCtx";

// state
export type SoftPhoneAndFreshChatFloatUIState = {
  showFloatBtnsOptions: boolean;
  floatModalFor: FloatModalForProps;
  callState: {
    screen: CallLogForScreenProps;
    churnId: string;
    deliquencyId: string;
    isCustomer: boolean;
    customer: CustomerProps;
    isRm: boolean;
    rm: {
      mobileNum: string;
    };
  };
};

export type CustomerProps = {
  custId: string;
  mobileNum: string;
};

export type FloatModalForProps =
  | typeof MISSED_CALL_MODAL
  | typeof PHONE_NUMS_LIST
  | "";

// const
export const MISSED_CALL_MODAL = "MISSED_CALL_MODAL";
export const PHONE_NUMS_LIST = "PHONE_NUMS_LIST";

// dispatch type
export const TOGGLE_FLOAT_BTN_UI_DISPATCH = "TOGGLE_FLOAT_BTN_UI_DISPATCH";
export const TOGGLE_FLOAT_MODAL_FOR_DISPATCH =
  "TOGGLE_FLOAT_MODAL_FOR_DISPATCH";
export const SET_CUSTOMER_DETAILS_DISPATCH = "SET_CUSTOMER_DETAILS_DISPATCH";

// dispatch handler
export type SoftPhoneAndFreshChatFloatUIDispatchHandlerProps =
  | {
      type: typeof TOGGLE_FLOAT_BTN_UI_DISPATCH;
      payload: {
        showFloatBtnsOptions: boolean;
      };
    }
  | {
      type: typeof TOGGLE_FLOAT_MODAL_FOR_DISPATCH;
      payload: {
        floatModalFor: FloatModalForProps;
      };
    }
  | {
      type: typeof SET_CUSTOMER_DETAILS_DISPATCH;
      payload: {
        screen: CallLogForScreenProps;
        isCustomer: boolean;
        custId: string;
        mobileNum: string;
        deliquencyId: string;
        churnId: string;
      };
    };

// ctx type
type SoftPhoneAndFreshChatFloatUICtxProps = {
  softPhoneAndFreshChatFloatUIState: SoftPhoneAndFreshChatFloatUIState;
  dispatch: Dispatch<DispatchProps>;
  methods: {
    toggleFloatBtnUI: ({
      showFloatBtnsOptions,
    }: {
      showFloatBtnsOptions: boolean;
    }) => void;
    toggleFloatModalFor: ({
      floatModalFor,
    }: {
      floatModalFor: FloatModalForProps;
    }) => void;
    setCustomerDetails: ({
      screen,
      isCustomer,
      custId,
      mobileNum,
      churnId,
      deliquencyId,
    }: {
      screen: CallLogForScreenProps;
      isCustomer: boolean;
      custId: string;
      mobileNum: string;
      churnId: string;
      deliquencyId: string;
    }) => void;
    closeFloatBtnUIAndFloatModal: () => void;
  };
};

export type DispatchProps = SoftPhoneAndFreshChatFloatUIDispatchHandlerProps;

export const SoftPhoneAndFreshChatFloatUICtx = createContext(
  {} as SoftPhoneAndFreshChatFloatUICtxProps
);
