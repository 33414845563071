import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';

import FileUpload from '../../common/component/FileUpload';

import {get, Config} from '../../../helpers/storage_helper';
import {capitalize_first_letter,getLabel,getValueFromLangJson,get_dir_path,lbl_amt} from '../../../helpers/common_helper';
import DisplayImage from '../../common/component/DisplayImage';
import {dd_value} from '../../../actions/common_actions';
import RegistrationDocument from '../container/RegistrationDocument';
import {Link} from 'react-router-dom';
import {VscVerified} from 'react-icons/vsc';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import {RoundOffSplitter} from '../../../helpers/common_helper';
import { connect } from 'react-redux';
import { updateValuesIntoRedux } from '../../../Redux/Slices/CommonSlice';
import { AiFillEdit} from "react-icons/ai";


class RekycAcOwnershipComponent extends FlowComponent{
    constructor() {
        super();
       
        this.national_id_details=['first_name','last_name','national_id','gender','dob']
        this.state={
       
          biz_acc :{}
        }
    }

    componentWillMount(){
   
     }

    checkbox_spinner(){
      return(
        <div class="d-flex col-lg-10 py-2 px-5 mt-4 justify-content-center ml-5">
        <div class="spinner-border text-light" role="status">
          <span class="sr-only">Loading...</span>
        </div>
       </div>
      )
    }
    audit_section_spinner(){
        return(
          <div class="audit_spinner" style={{margin:'20vh 0px'}}>
          <span class="level level-1"></span>
          <span class="level level-2"></span>
          <span class="level level-3"></span>
          <span class="level level-4"></span>
          <span class="level level-5"></span>
          <span class="level level-6"></span>
        </div>
        )
      }
      
  

      rekyc_modify_checking(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null){
        if(addl_key !=null && idx ==null ){
          if(((cust_reg_json[json_type][json_key][addl_key][key].hasOwnProperty("collected")) || (cust_reg_json[json_type][json_key][addl_key][key].hasOwnProperty("collected_n_modified")))){
            return this.get_class_name(cust_reg_json,"collected")
          }
          else{
            return false;
           }
        }

       else if(idx!=null){
          if(( (cust_reg_json[json_type][json_key][addl_key][idx][key].hasOwnProperty("collected")) || (cust_reg_json[json_type][json_key][addl_key][idx][key].hasOwnProperty("collected_n_modified")))) {
            return  this.get_class_name(cust_reg_json,"collected")
          }
          else{
            return false;
           }
        }
        else{
          if(((cust_reg_json[json_type][json_key][key].hasOwnProperty("collected"))  || (cust_reg_json[json_type][json_key][key].hasOwnProperty("collected_n_modified")))){
            return  this.get_class_name(cust_reg_json,"collected")
          }
          else{
            return false;
         }
        }
       
      }
      

      get_class_name(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null){
        if(json_type=="collected_modified"){
          return " COLLECTED_MODIFIED";
      
         }else if(json_type == "collected"){
          return "Re-KYCed"
         }
         
         else{
          return "MODIFIED"
         }
        }

        get_pool_accounts_limit(){
         var x=[];

         this.props.acownership.responseData.pooled_limit.pooled_limits.map((item,idx)=>{

          x.push(
            <>
            <div className='d-flex align-items-center mt-3 justify-content-between'>
              <div className='d-flex align-items-center' style={{width:'40px',height:'20px'}} >
              <img  className="img-fluid  " src={`/img/${item.acc_prvdr_code}_logo_color.png`} alt={item.acc_prvdr_code}/>
              <div>
              <p className=' pl-2 m-0'  style={{alignSelf:'center',fontSize:'10px'}}>{item.acc_number ? item.acc_number:'-'}</p>
              </div>
              </div>

              <div>
              <p className=' pl-2 m-0 polled_limit'  style={{alignSelf:'center',fontSize:'10px'}}>  {item.limit?  lbl_amt(item.limit ,get('market').currency_code)   : `0 ${get('market').currency_code}`}
                </p>
              </div>
            </div>
            {this.props.acownership.responseData.pooled_limit.pooled_limits.length >0 && 
           <hr className='mt-2 mb-0 no-paddon ' style={{borderBottom:'1px solid gray'}}/>
            }
           </>
           )          
         })
         
          

          return x;

        }


        onCreateUpload = (fileData, item) => {
          this.setState(prevState => ({
              biz_acc: {
                  ...prevState.biz_acc,
                  [item?.acc_number?.value]: {
                      ...prevState.biz_acc[item?.acc_number?.value],
                      holder_name_proof_path: fileData?.target?.f_name
                  }
              }
          }));
        };
    
  
      acc_holder_name = (value, type, idx = null, item = null) => {

        if(value !== "retrieved_franchise_name" && type === "mismatch_reason"  && value){
          this.setState({is_edit:false})
          let key = item?.acc_number?.value +"_is_editable"
          this.props.updateValuesIntoRedux({[key]:false})
        }
        this.setState(prevState => {
            let key_name = item?.acc_number?.value;
            let biz_accounts = { 
                ...prevState.biz_acc, 
                [key_name]: { 
                    ...prevState.biz_acc[key_name], 
                    [type]: value 
                } 
            };
            if (type === "mismatch_reason" && value !== "retrieved_franchise_name") {
                this.props.verify_handler("biz_acc", {
                    mismatch_reason: value,
                    acc_number: key_name,
                    acc_type: `accounts_${idx}`
                });
            }
    
            return { biz_acc: biz_accounts };
        });
    };
    
      
      upload_file = async (idx, item) => { 
          const biz_accounts = {
              category: `acc_num_${idx}`,
              index: idx,
              mobile_num: item?.acc_number,
              holder_name :this.state.biz_acc?.[item.acc_number.value].holder_name,
              holder_name_proof_path : this.state.biz_acc?.[item.acc_number.value]?.holder_name_proof_path
          };
              
          await this.props.file_upload_submit_handler(biz_accounts);
          this.setState({ biz_acc: {} });
      };
  
      is_disable = (item) =>{


        let value =(this.state.biz_acc?.[item.acc_number.value]?.holder_name_proof_path &&
          this.state.biz_acc?.[item.acc_number.value]?.holder_name) 
           return !value
        }

    handleEdit = (id) =>{
   
      let acc = {...this.state.biz_acc}
      if(acc ?.[id]){
            if( acc?.[id]?.mismatch_reason !=="retrieved_franchise_name"){
              acc[id]['mismatch_reason'] = ''
               this.setState({ biz_acc:acc})
             }
    }
           this.setState({is_edit:true })
           let key = id+"_is_editable"
           this.props.updateValuesIntoRedux({[key]:true})
    }
    
      
    render(){
      const audit_kyc = this.props.audit_kyc && this.props.audit_kyc[this.props.acownership.active_key]
      const rm_label_short = getValueFromLangJson("rm_label_short")
        return(
            <div>
                <>
           {this.props.acownership &&
           <div className='row no-margin justify-content-center'>
                       {this.props.acownership.View_lead_section_loader ? <div className="d-flex justify-content-center">{this.audit_section_spinner()}</div>:

            <>
           <div className='col-lg-12 py-3 mt-5 ' style={{background:' #01062c'}}>

             <h2 className='text-white text-center'>
             <b> {`${this.props.acownership.cust_reg_json.same_as_owner_person ? "3" :"4"} - A/C Owner Verification `}</b> 

               </h2>
             <h2 className=' d-flex mt-1 text-center justify-content-center align-items-center' ><img src='/img/pending_with_rm.png' height={'50'}/> <b className='ml-2 align-self-cente' style={{color:'#F3EA0B'}}> Pending with {this.props.acownership.active_section && (this.props.acownership.active_section.pending_with=="rm"? rm_label_short:'YOU')}</b></h2>

           </div>
           

           <>

            <>
          
            <>
          
              
            <div className='col-lg-10 py-5 mt-5 px-5' >
             { this.props.acownership.active_section &&
                       <div>
                           {this.props.acownership.active_section.verify &&
                             <div class="closedStamp ">
                             <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                             </div>   
                           }
                       </div>
             }  

         
                  <div className='row no-margin justify-content-center'>
                     

                     <div className='col-md-12 no-padding col-lg-12'  >
                     <h2 className='text-white audit_kyc_text old_json_text_bg_color no-margin px-4 py-3 text-center'> <b>Existing Account Number</b> </h2>

                     <div  >

                     <table class="table table-bordered mt-3" >
                      <thead style={{background: '#3C4048',fontSize:'18px',}}>
                        <tr>
                          <th className='text-white py-4 text-center' style={{fontSize:'16px',border:'1px solid gray'}}> <b>Account Provider</b></th>
                          <th className='text-white py-4 text-center' style={{fontSize:'16px',border:'1px solid gray'}}> <b>Customer Name</b></th>
                          <th className='text-white py-4 text-center' style={{fontSize:'16px',border:'1px solid gray'}}> <b>Third Party Account</b></th>
                          <th className='text-white py-4 text-center' style={{fontSize:'16px',border:'1px solid gray'}}><b>Status</b></th>
                        </tr>
                      </thead>
                      {this.props.acownership.new_cust_reg_json.biz_accounts.accounts.map((item,idx)=>{return(
                        <>
                         {(item.type=="existing_account" )&&
                      <tbody style={{background:'#404258',fontSize:'16px',border:'1px solid gray'}}>
                        <tr>
                       <td className='text-center' style={{border:'1px solid gray'}}> 
                       <div className='d-flex align-items-center text-center justify-content-center'> 
                        <img src={`/img/${this.props.acownership.new_cust_reg_json.biz_accounts.accounts[idx].acc_prvdr_code.value}_logo_color.png`} style={{width:'72px',height:'30px'}}/>
                        <p className='text-white no-margin pl-1'> {this.props.acownership.new_cust_reg_json.biz_accounts.accounts[idx].acc_number.value} </p>
                          </div>
                          <p className='text-white'>{item.is_default_acc?.value ? "( Default Account )" :""}</p>
                          </td>
                        {/* <td>  <img src={`/img/${this.props.acownership.new_cust_reg_json.biz_accounts.accounts[idx].acc_prvdr_code}_logo_color.png`} style={{width:'60px',height:'30px'}}/> {this.props.acownership.new_cust_reg_json.biz_accounts.accounts[idx].acc_number} </td>  */}
                       <td className='text-center text-white ' style={{border:'1px solid gray'}}> <b>{this.props.acownership.new_cust_reg_json.biz_accounts.accounts[idx].holder_name} </b></td>
                        <td className='text-center text-white' style={{border:'1px solid gray'}}> <b>{this.props.acownership.new_cust_reg_json.biz_accounts.accounts[idx].hasOwnProperty("tp_national_id") && (this.props.acownership.new_cust_reg_json.biz_accounts.accounts[idx].tp_national_id.hasOwnProperty("id_proof_num") )?

                         <div> <p className='text-white no-margin'> {this.props.acownership.new_cust_reg_json.biz_accounts.accounts[idx].tp_national_id.first_name.value} <span className='pl-1'>{this.props.acownership.new_cust_reg_json.biz_accounts.accounts[idx].tp_national_id.last_name.value},</span></p> 
                       <p className='text-white no-margin'>{this.props.acownership.new_cust_reg_json.biz_accounts.accounts[idx].tp_national_id.hasOwnProperty("id_proof_num")?this.props.acownership.new_cust_reg_json.biz_accounts.accounts[idx].tp_national_id.id_proof_num.value :"-"} </p>
                        </div>: "-"} </b></td>
                        <td  className=' text-white text-center'style={{border:'1px solid gray'}}> <b>{this.props.acownership.new_cust_reg_json.biz_accounts.accounts[idx].status ? dd_value(this.props.acownership.new_cust_reg_json.biz_accounts.accounts[idx].status) : "-"}</b></td>
                        </tr>
                     
                       
                       
                      </tbody>
                      }
                      </>
                      )})}
                     </table>
                      
                        
                       </div>

                     </div>
                  </div>
                  
             { this.props.acownership.cust_reg_json.biz_accounts.accounts.map((item,idx)=>{return(
                 <>
               

                  {((item.type=="new_account")|| (item.type=="existing_account_doc_upload")) && 
                  <>
                  {}



                  <div className='row no-margin justify-content-around pt-5'>
                  <h2 className='col-12 text-white audit_kyc_text new_re_kyc_text_bg_color no-margin px-4 py-3 text-center'> <b> { item.is_rented_line ? ` Additional Account  - Third Party Account (As per Re-KYC)` :"Additional Account (As per Re-KYC)"}</b> </h2>
                  </div>
                  <fieldset className= {`Re-KYCed_field_set mt-3` } style={{background:'#0f273f'}}>
                     
                      <legend className={`Re-KYCed_text_color legend_rekyc `}> 
                      <b>Re-KYCed </b></legend>
                      <p className='text-white text-end' style={{fontSize:'18px',textAlign:'end',marginRight:'35px'}}> Account Purpose : <b className='pl-2'>{"Float Advance" }</b></p>


            <div className='third_party_bg'>


                   <div className={`${item.third_party_account?'third_party_bg pb-4 ':''}d-flex pt-3 py-3 third_party_bg no-margin mb-4 justify-content-around row `}>
                     <div className='col-md-5  mt-3 mb-5 col-lg-5' >
                     <div  className='h-100' style={{background: '#E4E7E9',minHeight:'295px'}}>
                       <p  className="no-margin audit_kyc_text py-3 px-4" style={{color:'black'}}>Name as entered by {rm_label_short}</p><hr className='no-margin' style={{border:'1px solid black'}}/>
           
                       {(item.tp_national_id) ? 
                         <div className='p-3 '>
           
                         <p  className="no-margin py-2 mt-2 audit_kyc_text  px-3 pt-3 " style={{fontSize:'14px'}}>{`THIRD PARTY A/C OWNER NAME (as per ${getLabel('national_id')})`}</p>
                         <h4 className='my-1 px-3  audit_kyc_text mb-5 text_dark' style={{fontSize:'26px'}}> {item.tp_national_id.first_name.value?<b>{item.tp_national_id.first_name.value } {item.tp_national_id.last_name.value} </b>:"NA"} </h4>
                         
                       </div>
                         :
                       <div className='p-3 '>
           
                       <p  className="no-margin py-2 mt-2 audit_kyc_text  px-3 pt-3 " style={{fontSize:'14px'}}>{`CUSTOMER NAME (as per ${getLabel('national_id')})`}</p>
                       <h4 className='my-1 px-3  audit_kyc_text mb-5 text_dark' style={{fontSize:'26px'}}><b>{this.props.acownership.cust_reg_json.id_proof.first_name.value } {this.props.acownership.cust_reg_json.id_proof.last_name.value} </b> </h4>
                       
                       {/* <p  className="no-margin pt-3  px-3 audit_kyc_text " style={{fontSize:'14px'}}>BUSINESS NAME (as per Biz License)</p>
                       <h4 className='my-1 px-3 py-2 mb-4' style={{color:'black',fontSize:'26px'}}><b >{ this.props.acownership.cust_reg_json.license_proof.biz_name.value}</b> </h4> */}
                       </div>
                         }
                       </div>
           
                     </div>
                    
                     <div className='col-md-2 mt-3 mb-5 col-lg-1' style={{alignSelf:'center'}}>
                     {((!item.hasOwnProperty('holder_name'))) ? "" :  
                     <div className='px-2 py-3' style={{background:` ${item.name_match ? '#22cd07':'red'}`,borderRadius:'30px'}}>
                       <p className='no-margin text-white  text-center audit_kyc_text '> <b style={{fontSize:'25px'}}> { item.name_match ? "=" : '≠'}</b></p>
                     </div>}
                     </div>
                    
                     <div className='col-md-5 mt-3 mb-5 col-lg-5'  >
                     <div  style={{background: '#E4E7E9',minHeight:'295px'}}>
                   <p  className="no-margin audit_kyc_text py-3 px-4" style={{color:'black'}}>{(get('language_json'))?.holder_name}</p><hr className='no-margin' style={{border:'1px solid black'}}/>
           
                   <div className='p-3 '>
                   <div className='d-flex align-items-center row align-items-center my-2 mx-2 '>
                     <img src={`/img/${ item.acc_prvdr_code.value}_logo_color.png`} style={{width:'72px',height:'30px'}}/>
                           {/* <p className='no-margin text-white col-4 business_operations_text '>  COUNTRY</p> */}
                       <p className='col-8 pl-1 no-margin audit_kyc_text'> <b>{ item.acc_number.value}</b></p>
                   </div>
                   {(this.props.acownership.timers[item.acc_number.value]!=0 && (!item.hasOwnProperty('holder_name')) && (( get('app_config').audit_kyc_line[this.props.acownership.cust_reg_json.biz_accounts.accounts[idx].acc_prvdr_code.value]=="ussd") || 
                  ( get('app_config').audit_kyc_line[this.props.acownership.cust_reg_json.biz_accounts.accounts[idx].acc_prvdr_code.value]=="partner_api")) ) ?
                   this.checkbox_spinner()
                   :
                     <>
                   {item.hasOwnProperty('holder_name') ?
           
                   <>
                   {item.name_match ?
                   <>
                     <p  className="no-margin  mt-4 mb-2 pt-3 " style={{fontSize:'14px'}}>Name as per {item.acc_prvdr_code.value} records</p>
                     <h4 className='my-1 mt-3 px-3 py-2 audit_kyc_text mb-3' style={{background:'rgba(0, 0, 0, 0.3)',color:'black'}}><b>{ item.holder_name}</b> </h4>
                     </>
                   :
                   <div> 
                     <h2 className='my-1 mt-3 px-3 py-2 audit_kyc_text '  style={{background:'rgba(0, 0, 0, 0.3)'}}><b>{item.holder_name}</b> </h2>
                     <div className='mt-4' style={{color:'black'}} ><label className='audit_kyc_text-black no-margin'>Account Holder Name Mismatch Reason </label>

                     {item.hasOwnProperty("mismatch_reason") && !this.state.is_edit ?  

                      <b className='d-flex align-items-center'>
                      <p className='audit_kyc_text no-margin '>

                        { this.state.biz_acc?.[item.acc_number.value]?.mismatch_reason?
                        dd_value(this.state.biz_acc?.[item.acc_number.value]?.mismatch_reason ,'mismatch_reason'):
                        dd_value(item.mismatch_reason ,"mismatch_reason")}
                        
                        </p>
                      <p className={`mx-5 mb-0 pointer ${this.props.acownership.active_section.verify  ? "disable_div":''}`} onClick={()=>this.handleEdit(item.acc_number.value)}> <AiFillEdit/> Edit
                      </p>
                      </b>
                     :
                      
                     <select
                     id="holder_name_mismatch_reason"
                     className="form-control px-0 text_dark col-md-12"
                     value={this.state.biz_acc?.[item.acc_number.value]?.mismatch_reason??''}
                     onChange={(e)=> this.acc_holder_name(e.target.value,"mismatch_reason" , idx, item)}
                     style={{ color: "black" }}>
                     <option value={''} >Choose</option>
                     {this.getSelectOptions("holder_name_mismatch_reason", null,null,null,null, true )}
                    </select>
                    }
                    </div>

                    { ((this.state.biz_acc?.[item.acc_number.value]?.mismatch_reason =="retrieved_franchise_name" ) )&&
                     <div className='col-12 no-padding'>
                     <div class="form-group mt-4 mb-2">
                     <input type="text" className='p-3' id="lname" name="lname" style={{width:'100%',background:'#a0a2a3',color:'black',border:'hidden',fontSize:'14px'}} autocomplete="off" placeholder='ENTER HOLDER NAME' onChange={(e)=>this.acc_holder_name(e.target.value,`holder_name`,idx,item)}/>
                   </div>
                   
                   <FileUpload className="audit_kyc_file_upload" lead_id = {this.props.lead_id} id="account_holder_name_proof"  mode = "create" entity ='leads' type="image"          
                     onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}} 
                     onCreateUpload={(e)=>{this.onCreateUpload(e,item)}} 
                     />
                   <div className='mt-2'>
                   <button type="button" class="btn btn-info btn-sm "
                    disabled={this.is_disable(item)}
                    
                    onClick={()=>this.upload_file(idx,item)}
                    >submit</button>
                   </div>
                   <div className='col-lg-12 p-3 mt-2 d-flex align-items-center' style={{background:'#fe9f9f'}}>
                        <p className='no-margin  pl-3' style={{color:'black',fontSize:'10px'}}> <b style={{color:'red'}}>Note : </b>If the  SMS is shown on the mobile screen, please merge them into a single image using any photo editing software and upload it.</p>
                    </div>
                   </div>
                   }
                       {/* {()=>this.props.account_owner_checkbox_handler()} */}
                   </div>
                   }
           
                
                    { item.hasOwnProperty('holder_name_proof') && ((this.state.biz_acc?.[item.acc_number.value]?.mismatch_reason !="retrieved_franchise_name" ) )&&
                  <div className='m-2'>
                  <DisplayImage image_path={get_dir_path(item.holder_name_proof_path,item.holder_name_proof)} style={{height: '50px',width:'90px'}} file_name={item.holder_name_proof} />
                  </div>
                  }
                   </>
                   :
                   <div className='col-12 no-padding'>
                     <div class="form-group mt-3 mb-4">
                     <input type="text" className='p-3' id="lname" name="lname" style={{width:'100%',background:'#a0a2a3',color:'black',border:'hidden',fontSize:'14px'}} autocomplete="off" placeholder='ENTER HOLDER NAME' onChange={(e)=>this.acc_holder_name(e.target.value,`holder_name`,idx,item)}/>
                   </div>
                   
                   <FileUpload className="audit_kyc_file_upload" lead_id = {this.props.lead_id} id="account_holder_name_proof"  mode = "create" entity ='leads' type="image"          
                     onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}} 
                     onCreateUpload={(e)=>{this.onCreateUpload(e,item)}}     
                    />
                   <div className='mt-4'>
                   <button type="button" class="btn btn-info btn-sm " 
                    disabled={this.is_disable(item)}

                    onClick={()=>this.upload_file(idx,item)}>submit</button>

                   {/* <button type="button" class="btn btn-info btn-sm " disabled={(this.props.acownership.lead.account_holder_name_proof && this.props.acownership[`account_holder_name_${idx}`]) ? false :true} onClick={()=>this.props.file_upload_submit_handler(this.props.acownership[`account_holder_name_${idx}`],`acc_num_${idx}`,"account_holder_name_proof", item.acc_number.value)}>submit</button> */}
                   </div>
                    
                   </div>
           
                   }
                     </>
                       } 
                   </div>
                   </div>
                     </div>
                  
                   </div>

                   {item.existing_account_details && item.existing_account_details.length >0 &&
                      <div className='third_party_bg px-5 mb-5'>
                        <div className='pb-5 pt-3' style={{background:"#e4e7e9"}}>
                        <div className='row m-0 d-flex align-items-center pb-3'>
                          <div className='col-lg-12 my-4 '>
                            <p className='text-center m-0 audit_kyc_text' style={{fontSize:'23px'}}><b> Other Accounts owned by the same third party ( {(item.tp_national_id && item.tp_national_id.first_name.value!=null) ? <b>{item.tp_national_id.first_name.value } {item.tp_national_id.last_name.value}</b> : <b>{this.props.acownership.cust_reg_json.id_proof.first_name.value } {this.props.acownership.cust_reg_json.id_proof.last_name.value} </b> } )</b> </p>
                          </div>
                        </div>

                        <div className='px-3 m-0 col-lg-12 col-md-12 col-sm-12 ' style={{overflowX:'scroll'}}>
                        <table class="table table-hover audit_existig_account_table">
                          <thead>
                            <tr>
                              <th scope="col">Customer ID</th>
                              <th scope="col">Customer Name</th>
                              <th scope="col">Account No</th>

                              <th scope="col">Biz Name</th>
                              <th scope="col">Ownership</th>
                              <th scope="col">Mobile Number</th>
                              <th scope="col">Limit</th>

                            </tr>
                          </thead>
                          <tbody className='py-2'>

                            {item.existing_account_details.length >0 && item.existing_account_details.map((values,indexval)=>{return(
                                <tr  className= "audit_kyc_existing_table_text" style={{color:'black'}}>
                                <td> <b className='existing_acc_cust_id'> <Link className="nav-link existing_acc_cust_id" to={`/borrower/indiv/view/${values.cust_id}`} target='_blank'>{values.cust_id} </Link>
                                 </b>
                                </td>    
                                <td> <b>{values.first_name ?values.first_name :"" } {values.last_name ?values.last_name :"" }</b></td>
                                <td> 
                                <div className='d-flex align-items-center  m-0'>   
                                 <img style={{width:'72px',height:'30px'}} className="img-fluid col-6 pr-0" src={`/img/${values.acc_prvdr_code }_logo_color.png`} alt={values.acc_prvdr_code}/>	
                                <p className='m-0 pl-1 col-6 '> <b>{values.acc_number?values.acc_number:"-"}</b></p>
                                </div>
                               
                                </td>

                                <td> <b>{values.biz_name?values.biz_name:"-"}</b></td>
                                <td><b>{values.acc_ownership ? dd_value(values.acc_ownership,"acc_ownership") :"-" }</b></td>

                                <td><b>{values.mobile_num ?values.mobile_num:"-"}</b></td>
                                <td><b>{ values.limit_amount ? lbl_amt(values.limit_amount,get("market").currency_code):"-"}</b></td>
                                </tr>

                            )}) }
                           
                          </tbody>
                        </table>
                        </div>

                        </div>
                      </div>
                }
                    { ( item.hasOwnProperty("name_match") && !(item.name_match ))&&
                      <div className='row  pb-3 justify-content-center no-margin pt-2 px-5' >
                      <div className='col-lg-12 p-3 d-flex align-items-center' style={{background:'#fe9f9f'}}>
                        <h2 className='d-flex pl-3 ' style={{fontSize:'15px'}}> <b >Note :</b> <p className='no-margin  pl-3' style={{color:'black'}}>The name of the customer doesn’t match either with customer name nor business name.</p></h2>
                      </div>
                      </div>
                      }


                      {((item.acc_ownership.value=="rented")|| ((item.acc_ownership.value=="bought")) ) ?
                       <div className='col-lg-10 py-2  px-5  mt-1'>
                       {this.props.acownership.active_section &&
                       <>
                        
                         <form className={`pointer ${(this.props.acownership.active_section.verify || (this.props.acownership.active_section.to_reassign==true) )? "disable_div":''}   ${ ((this.props.acownership.responseData.audited_by== get("user_id")) || (this.props.acownership.responseData.audited_by==null )) ?"":'disable_div'}`}>
                         <div className='d-flex align-items-center mt-2 mb-4'>
                         <input type="checkbox" id={`acc_num_${idx+1}_holder_name_match`} style={{height:'19px',width:'20px'}} checked={audit_kyc && audit_kyc[`acc_num_${idx+1}_holder_name_match`] && true} name="vehicle1"  onChange={(e)=>this.props.checkbox_verification(e.target.checked,`acc_num_${idx+1}_holder_name_match`,"acc_owner_verification")}/>
                         <label className='no-margin pl-3 audit_kyc_text '  for={`acc_num_${idx+1}_holder_name_match`}>{`The third party account holder name matches with the third party ${getLabel('national_id')} name`}</label><br/><br/>
                         </div>
                       </form>
                       </>
                       }
                   
                       </div>:
                      
                       <div className='col-lg-12 py-2 pb-4 px-5 mb-3  mt-1'>
                
                    <form className={`${(this.props.acownership.active_section.verify || (this.props.acownership.active_section.to_reassign==true) )? "disable_div":''} `}>
                        <div className='d-flex align-items-center'>
                        <input type="checkbox" id={`check_box_id_${idx}`} style={{height:'19px',width:'20px'}} checked={audit_kyc && audit_kyc[`acc_num_${idx+1}_holder_name_match`] ? true:false} name="vehicle2"  onChange={(e)=>this.props.checkbox_verification(e.target.checked,`acc_num_${idx+1}_holder_name_match`,"acc_owner_verification")}/>
                        <label className='no-margin pl-3 audit_kyc_text'  for={`check_box_id_${idx}`}>{`The account holder name  matches with the customer name or business name (as captured by ${rm_label_short}). `}</label><br/><br/>
                        </div>
                    </form>

                  
                    
                  
                        
                  </div>
                    }
                 
                

                  <div className='col-lg-12 py-3 mt-3 px-5' >

                     <div className='row  pb-3 justify-content-center no-margin pt-2 px-0' >
                      <div className='col-lg-12 p-3 d-flex align-items-center audit_assessment_box' >
                       <div className='row m-0 col-12 py-3'>
                       <p className='m-0 text-white col-3 py-3 loggedPersondetails '> Account Ownership</p>
                       <p className='m-0 text-white col-9 py-3  audit_kyc_text'> <b>{(item.acc_ownership && item.acc_ownership.value) ? dd_value(item.acc_ownership.value, "acc_ownership_rm_app") : "-"}</b></p>
                       <p className='m-0 text-white col-3 py-3 loggedPersondetails '> Business Type</p>
                       <p className='m-0 text-white col-9 py-3  audit_kyc_text'> <b>{item.biz_type.value ? dd_value(item.biz_type.value, "biz_type_rm_app") : "-"}</b></p>

                       <h2 className='text-white col-12 m-0 text-center py-2'> <b>Assessment Status</b>  </h2>

                       <p className='m-0 text-white col-3 py-3 loggedPersondetails '> DATA ASSESSMENT</p>
                       <div className='m-0 text-white col-9 py-3  audit_kyc_text'>
                       <p className='m-0 text-white  audit_kyc_text'> <b> { this.props.acownership.responseData.assessment_status  ?(this.props.acownership.responseData.assessment_status == "eligible") ? <b> Eligible <VscVerified size={25} color='#06D755'/> </b> :( this.props.acownership.responseData.assessment_status == "ineligible") ? <b> Ineligible <AiOutlineCloseCircle size={25} color='red'/> </b> : <span>Data Not Available  <AiOutlineCloseCircle size={25} color='red'/></span>:"Data Not Available"}</b></p>
                      
                       { this.props.acownership.responseData.assessment_status  ?(this.props.acownership.responseData.assessment_status == "eligible") ? 
                       
                       <p className='m-0 text-white ' style={{fontSize:'13px'}}>   This account is already assessed & deemed as eligible</p>
                       :
                       ( this.props.acownership.responseData.assessment_status == "ineligible") ?
                       <p className='m-0 text-white ' style={{fontSize:'13px'}}>   The data of this account has been assessed and found not to be eligible </p>
                       :
                      
                       <p className='m-0 text-white ' style={{fontSize:'13px'}}> No data found for the given account</p>
                       :" "}

                       </div>

                       <p className='m-0 text-white col-3 py-3 loggedPersondetails '> NAME CHECK</p>
                       <div className='m-0 text-white col-9 py-3  audit_kyc_text'>
                       <p className='m-0 text-white  audit_kyc_text'> <b> {(this.props.acownership.responseData.retrieval_status && this.props.acownership.responseData.retrieval_status == 'unable_to_retrieve') ? <span>Unable to Retrieve  <AiOutlineCloseCircle size={25} color='red'/></span> :  

                        item.holder_name  ? ((item.name_match) ? <b> Matched <VscVerified size={25} color='#06D755'/> </b> :<b> Name Mismatch <AiOutlineCloseCircle size={25} color='red'/> </b> ): <span>Unable to Retrieve  <AiOutlineCloseCircle size={25} color='red'/></span>}</b></p>
                      
                       {item.holder_name ?
            
                       <>
                       {(item.name_match) ?
                         <p className='m-0 text-white ' style={{fontSize:'13px'}}> The name of the account as per <b>{item.acc_prvdr_code.value}</b> matches with { item.tp_national_id ?  <b>{item.tp_national_id.first_name.value} {item.tp_national_id.last_name.value }</b>:<b>{this.props.acownership.cust_reg_json.id_proof.first_name.value } {this.props.acownership.cust_reg_json.id_proof.last_name.value} </b> }, the name in ID proof</p>
                          :
                          <p className='m-0 text-white ' style={{fontSize:'13px'}}> The name of the account as per <b>{item.acc_prvdr_code.value}</b>  doesn’t match with { item.tp_national_id ?  <b>{item.tp_national_id.first_name.value} {item.tp_national_id.last_name.value }</b>:<b>{this.props.acownership.cust_reg_json.id_proof.first_name.value } {this.props.acownership.cust_reg_json.id_proof.last_name.value} </b> }, the name in ID proof</p>
                          

                      }

                       </>

                      
                       :" "}

                       </div>

                       {/* <p className='m-0 text-white col-3 py-3 loggedPersondetails '> ACCOUNT SPECIFIC LIMIT</p>
                       <p className='m-0 text-white col-9 py-3  audit_kyc_text'> <b>{this.props.acownership.responseData.hasOwnProperty('new_acc_fa_limit') ? lbl_amt(this.props.acownership.responseData.new_acc_fa_limit ,get('market').currency_code)  : "-"}</b></p>
                      */}
                       <p className='m-0 text-white col-3 py-3 loggedPersondetails '> POOLED LIMIT</p>
                       <div className=' col-9 py-3 d-flex align-items-center '>
                       <p className='m-0 text-white    audit_kyc_text'> <b>{this.props.acownership.responseData.pooled_limit ? this.props.acownership.responseData.pooled_limit.max_limit?lbl_amt(this.props.acownership.responseData.pooled_limit.max_limit,get('market').currency_code):"-" : "-"}</b></p>
                        {this.props.acownership.responseData.hasOwnProperty("pooled_limit") &&
                        <div className='ml-3' style={{fontSize:'18px',color:'#2fb8d8'}}>
                       <RoundOffSplitter val={<div className='no-padding no-margin' >
                                             <p className='no-margin no-padding'>Max limit of all the existing assessed A/Cs is considered</p>
                                             <hr className='no-margin no-paddon' style={{borderBottom:'1px solid green'}}/>
                                             <div> {this.get_pool_accounts_limit()}</div> 

                                             
                                             </div>} unit={""} align_center={true} position={"top"} notify={true} />
                        </div>
                       }
                       </div>
                       
                       
                       <p className='m-0 text-white col-3 py-3 loggedPersondetails '> KYC LIMIT</p>
                       
                       <div className=' col-9 py-3 d-flex align-items-center '>
                        {item.document_status ?
                       <>
                       <p className='m-0 text-white    audit_kyc_text'> <b>{this.props.acownership.responseData.pooled_limit ? this.props.acownership.responseData.pooled_limit.kyc_limit.actual ?lbl_amt(this.props.acownership.responseData.pooled_limit.kyc_limit.actual ,get('market').currency_code):"-" : "-"}</b></p>
                        {this.props.acownership.responseData.hasOwnProperty("pooled_limit") &&
                        <div className='ml-3' style={{fontSize:'18px',color:'#2fb8d8'}}>
                       <RoundOffSplitter val={<div className='no-padding no-margin' >
                                            
                                             <div> 
                                              <p className='m-0' > If documents are sufficient,   <b className='font-weight-bold'>{ this.props.acownership.responseData.pooled_limit.kyc_limit?lbl_amt(this.props.acownership.responseData.pooled_limit.kyc_limit.sufficient_docs_limit,get('market').currency_code):"-"} </b> </p>
                                              <p className='m-0' > If documents are NOT sufficient, <b className='font-weight-bold'>  { this.props.acownership.responseData.pooled_limit.kyc_limit?lbl_amt(this.props.acownership.responseData.pooled_limit.kyc_limit.insufficient_docs_limit,get('market').currency_code):"-"} </b> </p>

                                              </div> 

                                             
                                             </div>} unit={""} align_center={true} position={"top"} notify={true} />
                        </div>
                       }
                       </>:  <p className='text-white m-0' style={{fontSize:'13px'}}>Choose document sufficiency option above to know the limit.</p>}
                       </div>
                      
                      
                       <p className='m-0 text-white col-3 py-3 loggedPersondetails ' > FINAL LIMIT </p>
                       <div className=' col-9 py-3 d-flex align-items-center '>
                      
                      {item.document_status? <>
                       <p className='m-0  audit_kyc_text'  style={{fontSize:"21px",color:"#1da81d"}}> 
                       <b >{ this.props.acownership.responseData.pooled_limit ? lbl_amt(this.props.acownership.responseData.pooled_limit.final_limit ,get('market').currency_code)  : "-"    }</b>
                       </p>
                        {this.props.acownership.responseData.pooled_limit && this.props.acownership.responseData.pooled_limit.final_limit &&
                        <div className='ml-3' style={{fontSize:'18px',color:'#2fb8d8'}}>
                       <RoundOffSplitter val={"Minimum of the above mentioned limits"} unit={""} align_center={true} position={"top"} notify={true} />
                        </div>
                       }
                       </>: <p className='text-white m-0' style={{fontSize:'13px'}}>Choose document sufficiency option above to know the limit.</p>}
                       </div>

                       <p className='m-0 text-white col-3 py-3 loggedPersondetails '> INITIATED FROM</p>
                       <p className='m-0 text-white col-9 py-3  audit_kyc_text'> <b>{ dd_value(this.props.acownership.responseData.channel ,"channel")  }</b></p>
                     

                       </div>

                      </div>
                      </div>

                     <RegistrationDocument  root={this.props.root} update_mismatch_reason={(a,b,c,d)=>this.props.update_mismatch_reason(a,b,c,d)}  checkbox_verification={(a,b,c)=>this.props.checkbox_verification(a,b,c)} account_ownership_datas={this.props.acownership} lead_id={this.props.lead_id} items={item} document_index={idx } view_lead = {(a,b,c)=>this.props.view_lead(a,b,c)} acc_ownership_box={false}/>

                  </div>

                 </div>


                  </fieldset>
                  </>
                  }



               


                </>
             )})}
          
            
            
            </div>
             
             
       
             

          
            </>
          
            </>
            </>
         </>}
           </div>
            }
           </> 
           
            </div>

        )        
     }
}


const mapStateToProps = (state) => {
  return {
    audit_kyc: state.common_slice,
  };
  };

const mapDispatchToProps ={
    updateValuesIntoRedux
};

export default connect(mapStateToProps,mapDispatchToProps)(RekycAcOwnershipComponent);