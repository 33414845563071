import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import custom_colors, { colors } from '../colors';
import { styled } from '@mui/system';
import { useTheme } from '@emotion/react';
import { Alert } from '@mui/material';

const CustomSnackbarContent = styled('div')(({ background, color }) => ({
  background: background,
  color: color,
  borderRadius: '4px',
}));

export default function CustomSnackbar({ message, vl= 'bottom', hl = 'right' }) {
  const theme = useTheme()
  const [open, setOpen] = React.useState(true);
  const alignment = {
    vertical: vl,
    horizontal: hl,
  };
  const { vertical, horizontal } = alignment;
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  const snackbar_sx = {
    backgroundColor:theme.palette.custom_colors.positive_600,
    color:theme.palette.custom_colors.grey_50
  }
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


  return (

    <div>
      <Snackbar open={open} autoHideDuration={6000} action={action} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
        <Alert
          className='d-flex align-items-center'
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}>
      {message} 
      </Alert>
      </Snackbar>
    </div>

  );
}
