import React from 'react';
import FlowContainer from '../../common/container/core/FlowContainer';
import '../../../styles/flow.css';
import { Navigate } from 'react-router-dom';
import CustPanel from './CustPanel';
import TnCPanel from './TnCPanel';
import ProductPanel from './ProductPanel';
import {applyLoan, fa_appl_resend_otp} from '../../../actions/loan_appl_actions';
import {repayment_amt} from '../../../helpers/product_helper';
import {Row, Col } from 'react-bootstrap';
import { Config, get } from '../../../helpers/storage_helper';
import ApplyFAOtpMadal from './ApplyFAOtpMadal';
import CustomSnackbar from '../../../MUI/Components/CustomSnackbar';

class LoanApplSummary extends FlowContainer {

state = {
	tncPanel : {chk_tnc: false},
	customer_accounts_dd: null,
	viewLoan : false,
	showModal : false,
	loan_appl_data : null,
	show_snackbar: false,
	loan_appl_doc_id: null
}

componentWillMount(){
	const country_code = this.country_code;
	const status = "enabled";		
	const cust_id = this.props.customer.cust_id;
	const acc_purpose = 'float_advance';
	const customer_account = {cust_id,status,acc_purpose};
	
	// this.setOptionsListToStateFromAppApi("common","approvers",{country_code,status}, ['id' , 'name_email']);

	this.setOptionsListToStateFromAppApi("common","customer_accounts", this.req({customer_account}), ["account_id", "acc_num_disp_txt"]);
	//this.resp(customer_account);
}

clickBackButton = (event) => {
	//this.setState({prevScreen: true});
	window.location.reload();
}

handleResendOtp = (is_resend = true) =>{

	if(!this.state.tncPanel.chk_tnc){
		alert("Please select the Terms and Conditions checkbox to proceed.");
	} 

		let request = {
			data : {
				"purpose" : "loan_appl",
				"cust_id": this.props.customer.cust_id,
				"loan_appl_id" : this.state.loan_appl_data.fa_appl_id,
				"loan_appl_doc_id" : this.state.loan_appl_data.loan_appl_doc_id,
				"cust_mobile_num" : this.state.loan_appl_data.mob_num,
				"cust_name" : this.props.customer.cust_name,
				"resend_otp" : is_resend
			}
		}
		
	 
		fa_appl_resend_otp(this.req(request,true))
		.then((response)=>{
			this.resp(response);
				if(!response){ return;}
				if(response?.status =="success"){
					let data = response.data;
					this.setState({showModal : true, 
						loan_appl_data: {
						...this.state.loan_appl_data,
						exp_at : data.exp_at,
						otp_id: data.otp_id,
						otp_code: data.otp_code
					}});
			}
			
		});
		
	}

	handleSubmit = () =>{

		if(!this.state.loan_appl_doc_id){
			this.handleApplyFA()
		}else{
			this.handleResendOtp(false)
		}
		
	}

handleApplyFA = (event) => {
	
	if(!this.state.tncPanel.chk_tnc){
		alert("Please select the Terms and Conditions checkbox to proceed.");
	} 
	// else if(this.cust_account.value == null || this.cust_account.value == ""){
	// 	alert("Please select the customer account.");
	// }
	else{
		
		const loan_application = this.getLoanApplicationRequest(this.props.customer, this.props.selectedProduct, this.props.customer.flow_rel_mgr_id);
		loan_application.customer_consent_rcvd = true;
		loan_application.product_type = this.props.product_type;
		loan_application.master_loan_doc_id = this.props.master_loan_doc_id;
		
		loan_application.cs_result_code = this.props.cs_result_code;
		//loan_application.rel_mgr_id = this.props.rel_mgr.id;
		//loan_application.rel_mgr_name = this.props.rel_mgr.name;

		
	
		  applyLoan(this.req({loan_application},true))
	      	.then((response) => {
				this.resp(response);
	      		if(!response){return}
	      		
	      		if(response.data.loan_application){
	      			const loan_appl_doc_id = response.data.loan_application.loan_appl_doc_id;	
	      			const approver_name = response.data.loan_application.loan_approver_name;	
           			this.setState({loan_appl_doc_id, viewLoanAppln: true, approver_name});
	      		}else if (response.data.loan){
	      			const loan_doc_id = response.data.loan.loan_doc_id
	      			this.setState({loan_doc_id, viewLoan: true});
	      		}
				else if (response.data.notify_partner) {
					window.location.reload();
				}
				else if (response.data.status) {
					let status = response.data.status
					if(status == 'pending_otp'){
						this.setState({showModal : true, loan_appl_data : response.data, loan_appl_doc_id : response.data.loan_appl_doc_id});
					}
					return;	
				}
				
				alert(response.message);
		       
	          }
	        );
	}
		
}


getLoanApplicationRequest = (customer, product, approver) => {
	var application = customer;
	application.product_name = product.product_name;
	application.product_id = product.id ;
	application.loan_principal = product.max_loan_amount ;
	application.duration = product.duration ;
	application.flow_fee = product.flow_fee ;
	application.currency_code = product.currency_code ;
	application.cs_model_id = product.cs_model_id;
	// application.credit_score = product.credit_score ;
	application.flow_fee_type = product.flow_fee_type;
	application.flow_fee_duration = product.flow_fee_duration;
	application.due_amount = repayment_amt(product);
	application.loan_approver_id = approver;
	application.cust_acc_id = this.props.cust_account.value;
	//application.approver = this.approver.value;
	application.country_code = this.country_code;
	application.credit_score = this.props.credit_score;
	return application;
}


handleChange = (event) => {
	const id = event.target.id
	this.setState({[id] : event.target.value});
}

handleApply = () =>{
	this.setState({ 
        show_snackbar: true, 
        loan_appl_doc_id: this.state.loan_appl_data.loan_appl_doc_id 
    });

	setTimeout(() => {
        this.setState({ viewLoanAppln: true });
    }, 3000);
}

render(){

	if(this.state.viewLoanAppln == true)
	{
		//alert("FA Application ("+ this.state.loan_appl_doc_id + ") has been submitted to "+ this.state.approver_name)
		return <Navigate to={`/fa_appl/view/${this.state.loan_appl_doc_id}`} />
	}
	
	if(this.state.viewLoan == true)
	{
		return <Navigate to={`/fa/view/${this.state.loan_doc_id}`} />
	}
	this.appl_action = 'create_fa';
	if(this.props.cs_result_code == "ineligible" || this.props.cs_result_code == "requires_flow_rm_approval") {
		this.appl_action = 'submit_for_approval'	
	}
	if(Config('aps_w_flow_portal_int').includes(this.props.customer.acc_prvdr_code)) {
		this.appl_action = 'notify_to_partner'	
	}
	
	return(
		<div id="">
			{this.state.showModal &&  <ApplyFAOtpMadal customer={this.props.customer} show={this.state.showModal} data={this.state.loan_appl_data} show_sccess_msg ={()=>this.handleApply()} onhide={()=>this.setState({showModal : false})}/>}
			{this.state.show_snackbar &&
				<CustomSnackbar message={"FA Application request sent successfully"} vl={'top'} hl={'center'}/>
			}
			<div className="container">
				
				<div className="row">
					<h4>New Float Advance Application </h4>
				</div>
				<CustPanel parent="apply" customer={this.props.customer} cust_agreement_status={this.props.cust_agreement_status} />
				<ProductPanel parent="apply" credit_score = {this.props.credit_score} customer={this.props.customer} product={this.props.selectedProduct} />
				<div className="card selectCusAccountBox">
					<div className="card-body">
						{!this.appl_action !== 'submit_for_approval'	 &&
						<div className="row">
							<label className="col-sm-2 col-form-label">Approver Name</label>
							<div className="col-sm-7">
								<div className="form-group md-form-group">
									<label className="col-sm-5 col-form-label personalDetailstextval">{this.props.selectedProduct.approver_name}</label>
								</div>
							</div>
						</div>
						}
					</div>
				</div>
			
				
				<div className="row">
					<TnCPanel id="tncPanel" mode="apply" customer={this.props.customer} product={this.props.selectedProduct}
						onComponentChange={this.handleComponentChange} />
				</div>
				<div className='mb-3 d-flex justify-content-end'>
				<div>
				<input type="button" className="btn btn-primary" onClick={this.clickBackButton} value="Back"/>&nbsp;&nbsp;
				<span>
				{Config("web_fa_apply_otp_needed_country")?.includes(get('market').country_code) ? 
				<input type="button" disabled={!this.state.tncPanel.chk_tnc || this.state.tncPanel.chk_tnc == 'false'} className="btn btn-danger" onClick={this.handleSubmit} value="Get OTP Confirmation"   />
				:
				<input type="button" disabled={this.state.calling_api} className={this.appl_action == 'submit_for_approval' ? "btn btn-danger" : "btn btn-success"} onClick={this.handleSubmit} value={ this.appl_action == 'submit_for_approval' ? "Submit for Approval" : ( this.appl_action == 'notify_to_partner' ? 'Notify to Partner' : "Go to Disbursal" ) }    />
				}
				
				</span>
				</div>
				</div>
				</div>
		</div>
		);
}

}

export default LoanApplSummary;
