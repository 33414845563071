import { useState } from "react";
import CallLogModal from "./modal/callLogModal";
import CallStatusModal from "./modal/callStatusModal";

const SoftPhoneAndCallLogUI = () => {
  // state
  const [timer, settimer] = useState(0); // used for the timer this value is shared between the call status modal and call log modal
  return (
    <>
      <CallStatusModal timer={timer} setTimer={settimer} />
      <CallLogModal timer={timer} />
    </>
  );
};

export default SoftPhoneAndCallLogUI;
