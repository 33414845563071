import { createContext, Dispatch } from "react";
import { BRIDGED, INCOMING, OUTGOING } from "../../consts";

export type callStatusType =
  | ""
  | typeof BRIDGED
  | typeof INCOMING
  | typeof OUTGOING;

// state
export type SoftPhoneState = {
  isSoftPhoneClientReady: boolean;
  isATsInstanceBelongsToCurrentTab: boolean;
  showCallStatusModal: boolean;
  showCallLogModal: boolean;
  callLogDetails: CallLogDetailsProps;
  isCallInitiatedFromCurrentTab: boolean;
  startTimer: boolean;
  callState: {
    status: callStatusType;
  };
};

type CallLogDetailsProps = {
  phoneNumber: string;
  custName: string;
  callLogForIncomingOrOutGoing: Omit<callStatusType, "BRIDGED">;
  callLogForScreen: CallLogForScreenProps;
  churnOrDeliquencyId: string;
  callStartedTime: string;
};

// all the possible call log modals
export const SOFT_PHONE_LOG_MODAL = "soft_phone";
export const FA_VIEW_LOG_MODAL = "fa_view";
export const CHURN_CALL_LOG_NEW_MODAL = "churn_management_new";
export const CHURN_CALL_LOG_OLD_MODAL = "churn_management_old";
export const DELIQUENCY_CALL_TASK_LOG_MODAL = "delinquency_call_task";
export const DELIQUENCY_OVERDUE_BY_DAYS_LOG_MODAL = "overdue_by_days";
export const DELIQUENCY_OVERDUE_BY_COMMIT_DATE_LOG_MODAL =
  "overdue_by_commitment_days";
export const PENDING_WITH_CUST_CALL_LOG_MODAL = "pending_w_cust";
export const LEAD_NUM_VERIFY_CALL_LOG_MODAL = "lead_num_verify";
export const BORROWER_VIEW_SCREEN = "borrower_view";
export const FA_APPLY_SCREEN = "fa_apply_screen";
export const DUP_TXN_REPORT= "dup_txn_report"

export type CallLogForScreenProps =
  | typeof SOFT_PHONE_LOG_MODAL
  | typeof CHURN_CALL_LOG_NEW_MODAL
  | typeof CHURN_CALL_LOG_OLD_MODAL
  | typeof DELIQUENCY_CALL_TASK_LOG_MODAL
  | typeof DELIQUENCY_OVERDUE_BY_DAYS_LOG_MODAL
  | typeof DELIQUENCY_OVERDUE_BY_COMMIT_DATE_LOG_MODAL
  | typeof PENDING_WITH_CUST_CALL_LOG_MODAL
  | typeof FA_VIEW_LOG_MODAL
  | typeof LEAD_NUM_VERIFY_CALL_LOG_MODAL
  | typeof BORROWER_VIEW_SCREEN
  | typeof FA_APPLY_SCREEN
  | typeof DUP_TXN_REPORT
  | "";

// dispatch type
export const OPEN_CALL_STATUS_MODAL_DISPATCH =
  "OPEN_CALL_STATUS_MODAL_DISPATCH";
export const HANG_UP_CALL_DISPATCH = "HANG_UP_CALL_DISPATCH";
export const TRIGGER_CALL_LOG_MODAL_DISPATCH =
  "TRIGGER_CALL_LOG_MODAL_DISPATCH";
export const ATTEND_CALL_DISPATCH = "ATTEND_CALL_DISPATCH";
export const SET_SOFT_PHONE_CLIENT_STATUS_DISPATCH =
  "SET_SOFT_PHONE_CLIENT_STATUS_DISPATCH";
export const HANGUP_CALL = "HANGUP_CALL";
export const TRIGGER_CALL_LOG_MODAL_FOR_PREV_UNLOGGED_CALL_DISPATCH =
  "TRIGGER_CALL_LOG_MODAL_FOR_PREV_UNLOGGED_CALL_DISPATCH";
export const UPDATE_CALL_LOG_SCREEN_DISPATCH =
  "UPDATE_CALL_LOG_SCREEN_DISPATCH";
export const UPDATE_CALL_START_TIME_DISPATCH =
  "UPDATE_CALL_START_TIME_DISPATCH";
export const UPDATE_CALL_INITIATED_FROM_CURRENT_TAB_DISPATCH =
  "UPDATE_CALL_INITIATED_FROM_CURRENT_TAB_DISPATCH";
export const UPDATE_CUST_NAME_DISPATCH = "UPDATE_CUST_NAME_DISPATCH";

// dispatch handler
export type HandleSoftPhoneDispatchHandlerProps =
  | {
      type: typeof OPEN_CALL_STATUS_MODAL_DISPATCH;
      payload: {
        showCallStatusModal: boolean;
        callStatus: callStatusType;
        phoneNumber: string;
        callLogForIncomingOrOutGoing: Omit<callStatusType, "BRIDGED">;
      };
    }
  | {
      type: typeof HANG_UP_CALL_DISPATCH;
      payload: {
        showCallStatusModal: boolean;
        startTimer: boolean;
        callStatus: callStatusType;
        isCallInitiatedFromCurrentTab: boolean;
      };
    }
  | {
      type: typeof TRIGGER_CALL_LOG_MODAL_DISPATCH;
      payload: {
        showCallLogModal: boolean;
        callLogForIncomingOrOutGoing: Omit<callStatusType, "BRIDGED">;
        phoneNumber: string;
        callLogForScreen: CallLogForScreenProps;
        callStartedTime: string;
      };
    }
  | {
      type: typeof TRIGGER_CALL_LOG_MODAL_FOR_PREV_UNLOGGED_CALL_DISPATCH;
      payload: {
        showCallLogModal: boolean;
        callLogForIncomingOrOutGoing: Omit<callStatusType, "BRIDGED">;
        phoneNumber: string;
        callLogForScreen: CallLogForScreenProps;
        callStartedTime: string;
      };
    }
  | {
      type: typeof ATTEND_CALL_DISPATCH;
      payload: {
        callStatus: callStatusType;
        startTimer: boolean;
      };
    }
  | {
      type: typeof SET_SOFT_PHONE_CLIENT_STATUS_DISPATCH;
      payload: {
        isSoftPhoneClientReady: boolean;
        isATsInstanceBelongsToCurrentTab: boolean;
      };
    }
  | {
      type: typeof HANGUP_CALL;
      payload: {
        callStatus: callStatusType;
        showCallStatusModal: boolean;
      };
    }
  | {
      type: typeof UPDATE_CALL_LOG_SCREEN_DISPATCH;
      payload: {
        callLogForScreen: CallLogForScreenProps;
        churnOrDeliquencyId: string;
      };
    }
  | {
      type: typeof UPDATE_CALL_START_TIME_DISPATCH;
      payload: {
        callStartedTime: string;
      };
    }
  | {
      type: typeof UPDATE_CALL_INITIATED_FROM_CURRENT_TAB_DISPATCH;
      payload: {
        isCallInitiatedFromCurrentTab: boolean;
      };
    }
  | {
      type: typeof UPDATE_CUST_NAME_DISPATCH;
      payload: {
        custName: string;
      };
    };

// ctx type
type SoftPhoneCtxProps = {
  softPhoneState: SoftPhoneState;
  dispatch: Dispatch<DispatchProps>;
  methods: {
    initiateCall: ({
      phoneNumber,
      screen,
      churnOrDeliquencyId,
    }: {
      phoneNumber: string;
      screen: CallLogForScreenProps;
      churnOrDeliquencyId: string;
    }) => void;
    cancelCall: () => void;
    attendCall: ({
      isCurrentUserAnsweringTheCall,
    }: {
      isCurrentUserAnsweringTheCall: boolean;
    }) => void;
    closeCallLogModal: () => void;
    triggerCallLogModalOnParticularWindow: ({
      callStartedTime,
      showCallLogModal,
    }: {
      callStartedTime: string;
      showCallLogModal: boolean;
    }) => void;
    changeSoftPhoneClientReadyStatus: (status: boolean) => void;
    closeCallLogModalOnParticularWindow: () => void;
    checkUserHasAnyPendingCallLog: ({ screen }: { screen: string }) => Promise<{
      hasAnyPrevPendCallLog: any;
      callLog: any;
      hasError: boolean;
    }>;
  };
};

export type DispatchProps = HandleSoftPhoneDispatchHandlerProps;

export const SoftPhoneCtx = createContext({} as SoftPhoneCtxProps);
