import React from 'react';
import {Card,Button} from 'react-bootstrap';
import SearchLead from '../component/SearchLead';
import {searchLead} from '../../../actions/lead_actions';
import {format_date_tbl, capitalize_first_letter, title_case, page_count, table_tooltip, getValueFromLangJson} from '../../../helpers/common_helper';
import {dd_value} from '../../../actions/common_actions';
import { Link} from 'react-router-dom';
import _ from 'lodash';
import FlowContainer from '../../common/container/core/FlowContainer';
import { Homeacc_prvdrLogoselector } from '../../../helpers/common_helper';
import CommonReactTable from '../../../helpers/commonReact_table';
import SearchReKYCLeads from './SearchReKYCLeads';
import { Config, get } from '../../../helpers/storage_helper';
import { BasicReactTable } from '../../../helpers/react_table';
import DataGridTable from '../../CommonComponent/table';
import { Box } from '@mui/material';
import DataTableLoadingOrErrorUI from '../../CommonComponent/table/dataTableLoadingOrErrorUI';
import ExportCsv from '../../common/component/ExportCsv';
import { GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import CustomTableGridToolbar from '../../CommonComponent/table/components/customTableGridToolbar';
class LeadSearchContainer extends FlowContainer {
    state ={
        lead :{
			profile_status: 'open',
		},
		profile_status:{'closed_profile' : false} ,
        columns :[],
        lead_list:[],
        lead_table:"hide_div",
		paginate : Config('paginate') ? Config('paginate') : null,
		export_data:[],
		total:0,
		loader : false,
		nodata_found:false,
		tm_ids : Config('rm_head_person_id'),
		user_id : get('user_id'),
		page: { page: 0, pageSize: 10 },
	

    }

	validate_search(lead) {
		if((lead.account_num && !lead.acc_prvdr_code)){
            alert("Please select account provider")
            return false  
        }

		if(lead.created_from || lead.created_to || lead.flow_rel_mgr_id || lead.territory
			|| lead.acc_prvdr_code || lead.account_num || lead.location ||
			lead.mobile_num || lead.status || lead.biz_name || lead.cust_id || 
			lead.acc_purpose || lead.type || lead.profile_status){
		 	return true
  		}else{
  			alert("Please enter valid search criteria")
  			return false
  		}

		
	}
	handlecloseprofilechange =(event) =>{
		var profile_status = this.state.profile_status
		const value = event.target.id === 'closed_profile' ? event.target.checked : event.target.value;
		profile_status[event.target.id] = value
		this.state.lead.profile_status = profile_status.closed_profile == true ? 'closed' :'open';	
   }

   handlePurposeChange = (selected_options,id) =>{
	this.setMultiselectOptionOnState(selected_options,id,this.state.lead)
}

	handleButtonSearch = (is_btn_search)=>{
		this.setState(state=>state.lead_list = [])
		this.setState({total:0})
		if(this.state.paginate){
			this.setState((state) => {
				return {
					paginate: Config('paginate')};
			  }, ()=>this.handleSearch('button'));
			
		}
		else{
			this.handleSearch('button')
		}
		
	}

	componentDidUpdate(prevProps, prevState) {
		if (
		  prevState.page !== this.state.page
		) {
		  this.pagination();
		}
	  }

	column_datas(){

		const columns = [{  
				headerName:' Biz name & Mobile Number',  
				field: 'biz_name' ,
				renderCell :cell =><div>
						{dd_value(cell.row.biz_name)} <br/>
						{cell.row.mobile_num}
					</div>,
				width: 150,resizable: true,
			},
			{  
				headerName: ' A/C Number',
				field: 'account_num',
				renderCell: cell =><div className='d-flex align-items-center'> <Homeacc_prvdrLogoselector logotype={cell.row.acc_prvdr_code}/>  <p className='m-0 text-white pl-3'></p>{cell.row.account_num}</div>,
				width:200,resizable: true,
			},{
				headerName: 'Alt Account Number',
				field: 'alt_acc_num' ,
				sortable : this.state.paginate ? false : true,
				renderCell: cell => cell.row.alt_acc_num ? <>{cell.row.alt_acc_num}</> : <>NA</>,
				width: 190,resizable: true,
			},{
				headerName: 'Location',  
				field: 'location' ,resizable: true,
				width: 150,
				renderCell :cell =>capitalize_first_letter(cell.row.location)
			},{  
				headerName: 'Territory',  
				field: 'territory' ,resizable: true,
				width: 150,
				renderCell :cell =>capitalize_first_letter(cell.row.territory)
			},{  
				headerName: 'Created At',
				field: 'created_at', resizable: true,			
				width: 150,

				renderCell:cell => format_date_tbl(cell.row.created_at,true)  
			},{  
				headerName: 'Status',resizable: true,
				field: 'status', width: 150,
				renderCell: cell => dd_value(cell.row.status)
			},{
				headerName: `${getValueFromLangJson("rm_label_short")} Name`,resizable: true,
				field: 'rm_name',width: 150,
				sortable : this.state.paginate ? false : true,
				renderCell: cell => cell.row.flow_rel_mgr_name
			},{
				headerName: 'Sales Rep',resizable: true,
				field: 'sales_rep',width: 150,
				sortable : this.state.paginate ? false : true,
				renderCell: cell => cell.row.sales_rep_name
			}
			,{
				headerName: 'KYC Approved by',resizable: true,
				field: 'auditor_name',width: 150,
				sortable : this.state.paginate ? false : true,
				renderCell: cell => cell.row.auditor_name
			},{
				headerName: ' Channel',  resizable: true,
					field :'channel',width: 150,
					renderCell : cell =><div className='d-flex justify-content-center'>{dd_value(cell.row.channel)}</div>,
					style : {textAlign:"center"} 
			},{
				headerName: 'Profile Status',resizable: true,
				field: 'profile_status',width: 150,
				width: 70,
				renderCell: cell => title_case(cell.row.profile_status)
			},{
				headerName: 'Type',resizable: true,
				field: 'type',
				width: 100,
				renderCell: cell => dd_value(cell.row.type, 'lead_type')
			},{
				headerName: 'Reason',resizable: true,
				field: 'reason',
				sortable : this.state.paginate ? false : true,
				width: 200,
				renderCell: cell => title_case(cell.row.kyc_reason),
				width :90

			},{
				headerName: this.state.tm_ids.includes(this.state.user_id) ? 'View' : 'View / Edit',  
				field:"id",resizable: true,
				// renderCell:row => row.type == 're_kyc' ? <Link className="btn btn-primary" role="button" to={"/lead/audit_kyc/"+row.id} target="_blank" >Audit KYC</Link> : 
				// 										<Link className="btn btn-primary" role="button" to={"/lead/edit/"+row.id} target="_blank" >View / Edit</Link>
				renderCell:cell => <Link className="btn btn-primary btn-std" role="button" to={"/lead/edit/"+cell.row.id} target="_blank" >{this.state.tm_ids.includes(this.state.user_id) ? 'View' : 'View / Edit'}</Link>,	
				width: 125		
			}]

	    return columns
	}

  

    handleSearch =(event = null)=>{
		this.setState({loader:true})
		var lead= this.state.lead
		var type =this.props.search_rekyc ?"re_kyc": this.state.lead["type"]
        if(this.props.search_rekyc){
			lead["type"]=type
		}

		if(event){
			this.setState({copy_search : this.props.search_rekyc ? JSON.parse(JSON.stringify(lead)): JSON.parse(JSON.stringify(this.state.lead))})
		}
		var is_valid = this.validate_search(this.state.lead)
		if(is_valid){
			var req_json = this.state.paginate ? {lead_search_criteria: this.props.search_rekyc ?lead: this.state.lead, paginate : this.state.paginate}  : {lead_search_criteria: this.props.search_rekyc ?lead: this.state.lead}
			searchLead(this.req(req_json))
			.then((response) => {
				this.resp(response)
				if(!response){return this.setState({loader:false,nodata_found:true, reload:false})};
				if(response.status === "success"){
					this.resp(response)
					this.setState({nodata_found:true})	
					if(this.state.paginate != null){								
						this.setState(state=>state.total=response.data.total_count)	
						this.page_count()
					}
					else{
						this.setState(state=>state.total=response.data.length)
					}
					var columns = this.state.columns
						columns = this.column_datas()
								}
								this.setState({lead_table:"show_div"})

								this.setState((state) => {
									return { columns,lead_list  : this.state.paginate ? response.data.results : response.data};
								}, ()=>this.setState({loader : false, reload:false}));

			})
		}
       
    }

	pagination = (page, count, sorted)=>{
		var search  = JSON.parse(JSON.stringify(this.state.lead))
		if(this.props.search_rekyc)  search['type'] = 're_kyc'
		if('status' in search && search.status == null) this.state.copy_search.status = search.status
		if(JSON.stringify(search) !== JSON.stringify(this.state.copy_search)){
			let cur_search_state = JSON.parse(JSON.stringify(this.state.lead))
			if(this.props.search_rekyc)  cur_search_state['type'] = 're_kyc'
			this.setState({paginate : Config('paginate'), reload:true, lead_list : [], total : 0, copy_search : cur_search_state}, ()=>{
				this.handleSearch()	
			})
		}
		else{
			
			this.setState((state) => {
				return {
				  paginate: {
					...state.paginate,
					pagination_count: this.state.page.pageSize,
                    pagination_page: this.state.page.page ,
					sorting : sorted,
					total_count : this.state.total
				  },
				};
			  }, ()=>this.handleSearch());
		}
	
	}

		page_count = ()=>{
			
			const total = this.state.total
			const page_count_mod = (total%this.state.paginate.pagination_count)
			
			if(page_count_mod > 0){
				const page_count = (total/this.state.paginate.pagination_count)
				this.setState({'page_count' : Math.trunc(page_count)+1}, ()=>console.log(this.state.page_count))

			}else{
				const page_count = (total/this.state.paginate.pagination_count)
				this.setState({'page_count' : page_count})
			}
		}

		handleExportCSV = (event) => {
			let lead = JSON.parse(JSON.stringify(this.state.lead));
			let resp = null;
			if(event == 'email') {
				lead.export = 'email'
				resp = window.confirm(`The current search exceeds ${Config("export_csv").limit} rows. So the exported data will be sent to your email shortly.`)
			}
	
			if(event == 'email' && !resp){
				return false
			}
			else{
				this.handleCSVapi(lead, event)
				return true
			}
		}

		handleCSVapi(lead, action){
			let calling_api = true
			if (action == 'email') calling_api = false 
			var request={lead_search_criteria:lead, paginate : false}
			searchLead(this.req(request, true, null, calling_api))
				.then((response) => {
					this.resp(response)
					if(action == 'email'){
						return
					}
					this.setState(state=>state.export_data = response && response.data ? response.data : [])
				})
		}

    render(){

		const is_server_side = this.state.paginate?true:false
        return(
            <div className="container containerTopmargin max-width no-padding">   {console.log('Config(', Config('rm_head_person_id'))}         
                <form className="mb-3" autocomplete="off">
                    <Card>
                        <Card.Header style = {{backgroundColor: "#01062c" , color: "white"}}><h4>{this.props.title}</h4></Card.Header>
                            <Card.Body style={{padding:15}}>

								{this.props.search_rekyc ?
								<>
								<SearchReKYCLeads  handlePurposeChange={(a,b)=>{this.handlePurposeChange(a,b)}} id = "lead" onComponentChange={this.handleComponentChange} onComponentElementChange = {this.handlecloseprofilechange} profile_status = {this.state.profile_status}/>
								<div className="form-group row reset-margin justify-content-center py-4">
                                    <Button  disabled={this.state.calling_api} className= {`col-md-2 mr-3 btn btn-primary${this.state.submit_btn_style}`} onClick={(e)=>this.handleButtonSearch(true)} >Search</Button>
                                </div>
								</>
								:
								<>
                            	<SearchLead id = "lead" handlePurposeChange={(a,b)=>{this.handlePurposeChange(a,b)}} onComponentChange={this.handleComponentChange} onComponentElementChange = {this.handlecloseprofilechange} profile_status = {this.state.profile_status}/>
                                <div className="form-group row reset-margin justify-content-center py-4">
                                    <Button  disabled={this.state.calling_api} className= {`col-md-2 mr-3 btn btn-primary${this.state.submit_btn_style}`} onClick={(e)=>this.handleButtonSearch(true)} >Search</Button>
                                </div>
								</>
								}
                            </Card.Body>
                        </Card>
                </form>
                {!_.isEmpty(this.state.lead_list) ?
                <div className={`mt-3 ${this.state.lead_table}`} >	

					<div>{
						
                        <div >
							{this.state.loader ?
							 <DataTableLoadingOrErrorUI
								
								loadingRows={10}
								loadingColumns={6}
							/>
							:
							<>

									
									{/* // <BasicReactTable pagination={true}  row_data={this.state.lead_list !== undefined ? this.state.lead_list : []} column_data={this.state.columns}  csv_file={true} FName={"lead"} export_csv_datas={this.state.lead_list !== undefined ? this.state.lead_list : []} searchData={[{...this.state.lead,title:'Search Lead'}]} default_page_size={10}/> */}
								
{/* 									
									// <BasicReactTable pagination={true} row_data={this.state.lead_list !== undefined ? this.state.lead_list : []} column_data={this.state.columns} resizable={false} csv_file={true} FName={"lead"} export_csv_datas={this.state.export_data !== undefined ? this.state.export_data : []} searchData={[{...this.state.lead,title:'Search Lead'}]} filter_data = {this.pagination}  total_page_count = {page_count(this.state.total, this.state.paginate.pagination_count)} defaultPageSize={10} handleExport = {this.handleExportCSV} default_page_size={10}  server_side_pagination={this.state.paginate}  csv_total_data={this.state.total !== undefined ? this.state.total : 0}/> */}
                                    <Box display={'flex'} justifyContent={'space-between'}>
									<p className='m-0 text-white '>Total Entries : {this.state.total?? this.state.lead_list?.length}</p>

									<ExportCsv
									total_count={this.state.total ?? 0} 
									handleExport={this.handleExportCSV} 
									export_csv_datas={this.state.export_data ?? []}
									csvFileName = {'Lead'}
									paginationMode= { is_server_side?  "server":"client"}
									columns={this.state.columns??[]}
									isCsvExportedToMail={false}

									/>
									</Box>

									<DataGridTable
											rows={this.state.lead_list}
											columns={this.state.columns}
											paginationMode= { is_server_side?  "server":"client"}
											sortingMode={"client"}
											page={this.state.page}
											rowCount={this.state.total??0}
											setpage={(newPage) => this.setState({ page: newPage })}
											isLoading={ this.state.loader }
											slots={{ toolbar: CustomTableGridToolbar }}
												slotProps={{
												  toolbar: {
													showQuickFilter: true,
												  },
												}}
											
										/>
							
							</>
							}

                            
                        </div>}
                    </div>					
					    				
    			</div>
				:
				<>
				{ this.state.nodata_found && (this.state.lead_list.length==0) &&
				<p className='text-white text-center'>NO DATA</p>
				}
				</>
            }
			{/* <div style={{display:this.state.loader ?'':'none'}}>
							<div style={{marginTop:"15%"}} > <p className='text-light mt-3 text-center'>Loading...</p>
                        			<div className="product_takeup_loader"></div>
							</div>
						</div> */}
            </div>        )
    }
}

export default LeadSearchContainer;
