import moment from "moment";
import { Dispatch, useCallback } from "react";
import {
  getCSDevice,
  getCustDetail,
  updateCallScreen,
} from "../../../actions/cs_management_actions";
import {
  BRIDGED,
  CALL_INCOMING_OR_OUTGOING,
  CALL_NUMBER,
  CALL_STATUS_TYPE,
  CHURN_ROUTE,
  CUST_NAME,
  DELIQUENCY_ROUTE,
  INCOMING,
  IS_AT_CALL_INITIATED,
  OUTGOING,
} from "../../../consts";
import { get, set } from "../../../helpers/storage_helper";
import {
  ATTEND_CALL_DISPATCH,
  BORROWER_VIEW_SCREEN,
  CallLogForScreenProps,
  callStatusType,
  CHURN_CALL_LOG_NEW_MODAL,
  CHURN_CALL_LOG_OLD_MODAL,
  DELIQUENCY_CALL_TASK_LOG_MODAL,
  DELIQUENCY_OVERDUE_BY_COMMIT_DATE_LOG_MODAL,
  DELIQUENCY_OVERDUE_BY_DAYS_LOG_MODAL,
  DUP_TXN_REPORT,
  FA_APPLY_SCREEN,
  FA_VIEW_LOG_MODAL,
  HandleSoftPhoneDispatchHandlerProps,
  HANG_UP_CALL_DISPATCH,
  OPEN_CALL_STATUS_MODAL_DISPATCH,
  PENDING_WITH_CUST_CALL_LOG_MODAL,
  SET_SOFT_PHONE_CLIENT_STATUS_DISPATCH,
  SOFT_PHONE_LOG_MODAL,
  SoftPhoneState,
  TRIGGER_CALL_LOG_MODAL_DISPATCH,
  TRIGGER_CALL_LOG_MODAL_FOR_PREV_UNLOGGED_CALL_DISPATCH,
  UPDATE_CALL_INITIATED_FROM_CURRENT_TAB_DISPATCH,
  UPDATE_CALL_LOG_SCREEN_DISPATCH,
  UPDATE_CALL_START_TIME_DISPATCH,
  UPDATE_CUST_NAME_DISPATCH,
} from "../softPhoneCtx";

type Props = {
  dispatch: Dispatch<HandleSoftPhoneDispatchHandlerProps>;
  softPhoneState: SoftPhoneState;
  atClient: any;
  broadCastChannel: BroadcastChannel;
  setisCallConnected: Function;
  setisCallDisconnected: Function;
};

const useSoftPhoneMethods = ({
  dispatch,
  softPhoneState,
  atClient,
  broadCastChannel,
  setisCallConnected,
  setisCallDisconnected,
}: Props) => {
  /**
   *
   * @param screen
   * @param churnOrDelinquencyId
   * we need to update screen for the active call log,
   * at first we fetching if there is any pending call log via "checkUserHasAnyPendingCallLog"
   * if there is "call_log_id" we need to update need to update that screen to the db via "updateCallScreen"
   */
  const updateCallScreenCb = async ({
    screen,
    churnOrDeliquencyId,
  }: {
    screen: CallLogForScreenProps;
    churnOrDeliquencyId: string;
  }) => {
    // check for any prev pend call log
    const { callLog } = await checkUserHasAnyPendingCallLog({ screen: "" });
    // if it is update that call id with the current call log screen
    if (callLog?.call_log_id) {
      const req = {
        screen,
        call_log_id: callLog.call_log_id,
        ...((screen === DELIQUENCY_OVERDUE_BY_COMMIT_DATE_LOG_MODAL ||
          screen === DELIQUENCY_OVERDUE_BY_DAYS_LOG_MODAL) && {
          delinquency_id: churnOrDeliquencyId,
        }),
        ...(screen === DELIQUENCY_CALL_TASK_LOG_MODAL && {
          delinquency_task_id: churnOrDeliquencyId,
        }),
        ...((screen === CHURN_CALL_LOG_NEW_MODAL ||
          screen === CHURN_CALL_LOG_OLD_MODAL) && {
          churn_id: churnOrDeliquencyId,
        }),
      };
      await updateCallScreen(req);
    }
  };

  /**
   *
   * @param screen
   * @returns if the user has any pending call log it will returns that
   */
  const checkUserHasAnyPendingCallLog = async ({
    screen,
  }: {
    screen: string;
  }) => {
    const req = {
      screen,
    };
    const res = await updateCallScreen(req);
    const callLog = res?.data?.call_log_details;
    return {
      hasAnyPrevPendCallLog:
        callLog?.call_log_id && callLog?.status === "pending",
      callLog,
      hasError: res?.status !== "success",
    };
  };

  /**
   * this function is used to finds out the current logged in user is in break
   * before initiate the call we need to check that
   */
  const checkCsInBreak = useCallback(async (): Promise<boolean> => {
    const response = await getCSDevice({ type: "webrtc" });
    if (response.status === "success") return response.data.on_break === 1;
    else return false;
  }, []);

  /**
   *
   * @param phoneNumber
   * @param screen
   * @param churnOrDelinquencyId
   * the call is initiated from any of the local components some where in the entire project
   * need to pass phoneNumber, screen, churnOrDelinquencyId
   * this function first checks the csInBreak
   * if not checks user has any prev pending call log
   * if so it will call "toggleCallLogModalForPrevUnloggedCall"
   * if everything okay then the call will be initiated
   */
  const initiateCall = async ({
    phoneNumber,
    screen,
    churnOrDeliquencyId,
  }: {
    phoneNumber: string;
    screen: CallLogForScreenProps;
    churnOrDeliquencyId: string;
  }) => {
    const csInBreak = await checkCsInBreak();
    if (csInBreak) {
      alert("Please change the break status");
    } else {
      const { hasAnyPrevPendCallLog, callLog, hasError } =
        await checkUserHasAnyPendingCallLog({ screen: "" });
      if (hasAnyPrevPendCallLog) {
        // if user has any pending call log
        alert("You have a pending call log, Please log the call!");
        if (
          callLog?.screen === SOFT_PHONE_LOG_MODAL ||
          callLog.screen === FA_VIEW_LOG_MODAL ||
          callLog.screen === PENDING_WITH_CUST_CALL_LOG_MODAL ||
          callLog.screen === BORROWER_VIEW_SCREEN ||
          callLog.screen === FA_APPLY_SCREEN ||
          callLog.screen === DUP_TXN_REPORT ||
          !callLog.screen // for exception case (unknown pending call logs)
        ) {
          // for the above call log modals the default call log entry UI is used
          toggleCallLogModalForPrevUnloggedCall({
            showCallLogModal: true,
            callLogForIncomingOrOutGoing:
              callLog?.call_type === "outgoing" ? OUTGOING : INCOMING,
            phoneNumber: callLog?.mobile_num,
            callLogForScreen: callLog?.screen ?? SOFT_PHONE_LOG_MODAL, // for exception case the pending call log's screen fall back to SOFT_PHONE_LOG_MODAL
            callStartedTime: callLog?.call_start_time,
          });
        }
        // for churn the call log modal will opened in new window at there they can do the call log
        else if (
          callLog?.screen === CHURN_CALL_LOG_NEW_MODAL ||
          callLog?.screen === CHURN_CALL_LOG_OLD_MODAL
        ) {
          const fullPath = `${CHURN_ROUTE}?churn=${
            callLog?.screen === CHURN_CALL_LOG_NEW_MODAL ? "new" : "past"
          }&churn_id=${callLog?.churn_id}`;
          window.open(fullPath, "_blank");
        }
        // for delinquency, the call log modal will opened in new window at there they can do the call log
        else if (
          (callLog?.screen === DELIQUENCY_OVERDUE_BY_COMMIT_DATE_LOG_MODAL ||
            callLog?.screen === DELIQUENCY_OVERDUE_BY_DAYS_LOG_MODAL) &&
          callLog?.delinquency_id
        ) {
          const fullPath = `${DELIQUENCY_ROUTE}?screen=${callLog?.screen}&delinquency_id=${callLog?.delinquency_id}`;
          window.open(fullPath, "_blank");
        }
        // for delinquency tasks type, the call log modal will opened in new window at there they can do the call log
        else if (
          callLog?.screen === DELIQUENCY_CALL_TASK_LOG_MODAL &&
          callLog?.delinquency_task_id
        ) {
          const fullPath = `${DELIQUENCY_ROUTE}?screen=${callLog?.screen}&delinquency_task_id=${callLog?.delinquency_task_id}`;
          window.open(fullPath, "_blank");
        }
      } else if (!hasError && !hasAnyPrevPendCallLog) {
        updateCallInitiatedFromCurrentTab();
        // during the call initialization the screen is updated, if the call belongs to churn or delinquency it's id is passing via params,
        updateCallLogScreenHandler({
          callLogForScreen: screen,
          churnOrDeliquencyId,
        });
        // all tabs has its standalone ctx, but the call should be initiated at where the AIT's client connected
        // it will be the "cs_managements/soft_phone" route
        if (softPhoneState.isATsInstanceBelongsToCurrentTab) {
          initiateCallAndCbTriggerHandler(phoneNumber);
        }
        // this channel attends a call from client initiated tab, because the call can be initiated from anywhere
        // if it is initiated from the isATsInstanceBelongsToCurrentTab then the above condition executed
        // or this channel hit all tabs, then by call the same function it makes the call
        broadCastChannel?.postMessage({
          type: "CallIsInitiatedSomeWhereAmongTheOpenedTabSoMakeCallFromInstanceBelongsTab",
          payload: {
            phoneNumber,
            callLogForScreen: screen,
            churnOrDeliquencyId,
          },
        });
      } else {
        alert("An error occured");
      }
    }
  };

  /**
   *
   * @param phoneNumber phone number to connect
   * this function gets the phone number via param, and initiates the call via AIT client...
   */
  const initiateCallAndCbTriggerHandler = (phoneNumber: string) => {
    // getting market from storage api
    const market = get("market");
    // getting isdCode from the market obj
    const isdCode = `+${market?.isd_code}`;
    // AIT call cb
    atClient.call(`${isdCode}${phoneNumber}`);
    // local function to show the call status
    intiateCallCb(OUTGOING, phoneNumber);
  };

  /**
   *
   * @param type
   * @param num
   * for open the call status modal this function is used
   * for incoming call this function will be called via the AT initialized event
   * for outgoing call this function will be executed via initiateCallAndCbTriggerHandler
   */
  const intiateCallCb = (
    type: typeof OUTGOING | typeof INCOMING,
    num: string
  ) => {
    set(IS_AT_CALL_INITIATED, true);
    set(CALL_STATUS_TYPE, type);
    set(CALL_NUMBER, num);
    set(CALL_INCOMING_OR_OUTGOING, type);
    if (type === INCOMING) {
      updateCallLogScreenHandler({
        callLogForScreen: SOFT_PHONE_LOG_MODAL, // we are updating all incoming calls as for softphone log modal
        churnOrDeliquencyId: "",
      });
    }
    // opens the call status modal in the current tab
    // and sets the callstatus as INCOMING or OUTGOING, phone num and callLogForIncomingOrOutGoing status
    dispatch({
      type: OPEN_CALL_STATUS_MODAL_DISPATCH,
      payload: {
        showCallStatusModal: true,
        callStatus: type,
        phoneNumber: num,
        callLogForIncomingOrOutGoing: type,
      },
    });
    // channel used to update the call log modal in all the opened tabs
    broadCastChannel?.postMessage({
      type: "OpenCallStatusModal",
      payload: { callStatus: type, phoneNumber: num },
    });
    // to update the user name in call status modal
    fetchUserDetailsViaNum(num);
  };

  /**
   * called once the user clicks the hangup call btn inside the call status modal
   */
  const cancelCall = () => {
    cancelCallCb();
    broadCastChannel?.postMessage({
      type: "HangupCall",
    });
  };

  /**
   * this fn will be called via cancelCall
   * also this fn will be called once the end user hangup the call, that is handled by the AIT "hangup" event
   */
  const cancelCallCb = () => {
    localStorage.removeItem(IS_AT_CALL_INITIATED);
    localStorage.removeItem(CALL_STATUS_TYPE);
    localStorage.removeItem(CALL_NUMBER);
    localStorage.removeItem(CUST_NAME);
    setisCallConnected(false);
    setisCallDisconnected(false);
    // if the instance is belongs to current tab the call terminated by calling atClient.hangup
    if (softPhoneState.isATsInstanceBelongsToCurrentTab) {
      atClient.hangup();
    }
    dispatch({
      type: HANG_UP_CALL_DISPATCH,
      payload: {
        showCallStatusModal: false,
        callStatus: "",
        startTimer: false,
        isCallInitiatedFromCurrentTab: false,
      },
    });
    updateCustNameOnCurrentAndAllOpenedTab({ custName: "" });
  };

  /**
   *
   * @param isCurrentUserAnsweringTheCall
   * for the incoming call the user will click the answer button
   * but for outgoing call the end user will answer the call at that time via the AIT an event this fn will be triggered
   * to differentiate both call types here we are using isCurrentUserAnsweringTheCall param
   */
  const attendCall = ({
    isCurrentUserAnsweringTheCall,
  }: {
    isCurrentUserAnsweringTheCall: boolean;
  }) => {
    attendCallCb({
      isCurrentUserAnsweringTheCall,
    });
    broadCastChannel?.postMessage({
      type: "AttendCall",
    });
  };

  /**
   *
   * @param isCurrentUserAnsweringTheCall
   * used to answer the call,
   */
  const attendCallCb = ({
    isCurrentUserAnsweringTheCall,
  }: {
    isCurrentUserAnsweringTheCall: boolean;
  }) => {
    // setting call status type in localStorage api
    // this is used if the user opens the new tab during ongoing call there is no state persist, so that we couldn't show the call status modal during any ongoing call
    // using this local storage value, OPEN_CALL_STATUS_MODAL_DISPATCH is executed in "useEffect" at the softPhoneCtxProvider page
    set(CALL_STATUS_TYPE, BRIDGED);
    if (
      softPhoneState.isATsInstanceBelongsToCurrentTab &&
      softPhoneState.callState.status === INCOMING &&
      isCurrentUserAnsweringTheCall
    ) {
      atClient.answer();
      // update call log timer in instance created tab (cs_managements/soft_phone)
      updateCallStartTimeOnOtherTabs();
      // update call log timer in all other tabs
      broadCastChannel?.postMessage({
        type: "UpdateCallStartTimeOnOtherWindows",
        payload: moment().format("DD MMM YYYY hh:mm a"),
      });
    }
    if (
      softPhoneState.isATsInstanceBelongsToCurrentTab &&
      softPhoneState.callState.status === OUTGOING &&
      (softPhoneState.callLogDetails.callLogForScreen ===
        SOFT_PHONE_LOG_MODAL ||
        softPhoneState.callLogDetails.callLogForScreen ===
          PENDING_WITH_CUST_CALL_LOG_MODAL ||
        softPhoneState.callLogDetails.callLogForScreen === FA_VIEW_LOG_MODAL ||
        softPhoneState.callLogDetails.callLogForScreen === BORROWER_VIEW_SCREEN ||
        softPhoneState.callLogDetails.callLogForScreen === DUP_TXN_REPORT
      )
    ) {
      // this triggers the call log modal depends on showCallLogModal
      // this updates the call started time
      // for the current screen the call log modal is opened from this fn (because the channel doesn't trigger it's cb on it's own tab)
      triggerCallLogModalOnParticularWindow({
        callStartedTime: moment().format("DD MMM YYYY hh:mm a"),
        showCallLogModal:
          softPhoneState.callLogDetails.callLogForScreen ===
          SOFT_PHONE_LOG_MODAL,
      });
      // for other screens, the call log modal is opened using the channel below
      broadCastChannel?.postMessage({
        type: "OpenCallLogModalOnParticularTab",
        payload: {
          callLogForIncomingOrOutGoing: OUTGOING,
          phoneNumber: softPhoneState.callLogDetails.phoneNumber,
          callLogForScreen: softPhoneState.callLogDetails.callLogForScreen,
          callStartedTime: moment().format("DD MMM YYYY hh:mm a"),
        },
      });
    }
    dispatch({
      type: ATTEND_CALL_DISPATCH,
      payload: {
        callStatus: BRIDGED,
        startTimer: softPhoneState.isATsInstanceBelongsToCurrentTab,
      },
    });
  };

  /**
   * call log modal's close on all opened tabs
   */
  const closeCallLogModal = () => {
    localStorage.removeItem(CALL_INCOMING_OR_OUTGOING);
    dispatch({
      type: TRIGGER_CALL_LOG_MODAL_DISPATCH,
      payload: {
        showCallLogModal: false,
        callLogForIncomingOrOutGoing: "",
        phoneNumber: "",
        callLogForScreen: "",
        callStartedTime: "",
      },
    });
    broadCastChannel?.postMessage({
      type: "CloseCallLogModal",
      payload: {
        showCallLogModal: false,
        callLogForIncomingOrOutGoing: "",
        phoneNumber: "",
        callLogForScreen: "",
        callStartedTime: "",
      },
    });
  };

  /**
   * @param callStartedTime
   * @param showCallLogModal
   * this function is used to open the call log modal from which tab user clicks a call log btn inside the call status modal
   * at the same time "callLogForIncomingOrOutGoing", "phoneNumber", "callLogForScreen", "callStartedTime" got updated
   */
  const triggerCallLogModalOnParticularWindow = ({
    callStartedTime,
    showCallLogModal,
  }: {
    callStartedTime: string;
    showCallLogModal: boolean;
  }) => {
    dispatch({
      type: TRIGGER_CALL_LOG_MODAL_DISPATCH,
      payload: {
        showCallLogModal: showCallLogModal,
        callLogForIncomingOrOutGoing:
          softPhoneState.callLogDetails.callLogForIncomingOrOutGoing,
        phoneNumber: softPhoneState.callLogDetails.phoneNumber,
        callLogForScreen: softPhoneState.callLogDetails.callLogForScreen,
        callStartedTime: callStartedTime,
      },
    });
  };

  /**
   * this function is used to close the call log modal at which tab it has opened
   * at the same time "callLogForIncomingOrOutGoing", "phoneNumber", "callLogForScreen", "callStartedTime" got updated
   */
  const closeCallLogModalOnParticularWindow = () => {
    dispatch({
      type: TRIGGER_CALL_LOG_MODAL_DISPATCH,
      payload: {
        showCallLogModal: false,
        callLogForIncomingOrOutGoing:
          softPhoneState.callLogDetails.callLogForIncomingOrOutGoing,
        phoneNumber: softPhoneState.callLogDetails.phoneNumber,
        callLogForScreen: softPhoneState.callLogDetails.callLogForScreen,
        callStartedTime: softPhoneState.callLogDetails.callStartedTime,
      },
    });
  };

  /**
   *
   * @param showCallLogModal
   * @param callLogForIncomingOrOutGoing
   * @param phoneNumber
   * @param callLogForScreen
   * @param callStartedTime
   * this function is used for toggle the prev pending call log modal
   * this will be executed when the user try to call other person while that user has any pending call log
   */
  const toggleCallLogModalForPrevUnloggedCall = ({
    showCallLogModal,
    callLogForIncomingOrOutGoing,
    phoneNumber,
    callLogForScreen,
    callStartedTime,
  }: {
    showCallLogModal: boolean;
    callLogForIncomingOrOutGoing: Omit<callStatusType, "BRIDGED">;
    phoneNumber: string;
    callLogForScreen: CallLogForScreenProps;
    callStartedTime: string;
  }) => {
    dispatch({
      type: TRIGGER_CALL_LOG_MODAL_FOR_PREV_UNLOGGED_CALL_DISPATCH,
      payload: {
        showCallLogModal,
        callLogForIncomingOrOutGoing,
        phoneNumber,
        callLogForScreen,
        callStartedTime,
      },
    });
  };

  /**
   * this function is used to update the call started time, which one is showing in the call status modal on all the tabs,
   */
  const updateCallStartTimeOnOtherTabs = () => {
    dispatch({
      type: UPDATE_CALL_START_TIME_DISPATCH,
      payload: { callStartedTime: moment().format("DD MMM YYYY hh:mm a") },
    });
  };

  /**
   * this function is used to keep "isCallInitiatedFromCurrentTab" updated
   * we have the requirement as after the call connection a call log modal will be opened from tab where the user initiated a call,
   * for that we need this "isCallInitiatedFromCurrentTab" in ctx
   */
  const updateCallInitiatedFromCurrentTab = () => {
    dispatch({
      type: UPDATE_CALL_INITIATED_FROM_CURRENT_TAB_DISPATCH,
      payload: {
        isCallInitiatedFromCurrentTab: true,
      },
    });
  };

  /**
   *
   * @param status
   * this function is used to update the AIT package's instance initialized status
   */
  const changeSoftPhoneClientReadyStatus = (status: boolean) => {
    // toggles the softPhoneConnected status on browser's current tab
    dispatch({
      type: SET_SOFT_PHONE_CLIENT_STATUS_DISPATCH,
      payload: {
        isSoftPhoneClientReady: status,
        isATsInstanceBelongsToCurrentTab: status,
      },
    });
  };

  /**
   *
   * @param callLogForScreen
   * @param churnOrDeliquencyId
   * this function is used to update the call log screen
   * because the call log modal will be shown conditionally depends on what screen, (eg) churn and delinquency pages have it's own standalone call log,
   * while the other screens have softphone call log modal, *some screens have auto call log feature also,
   * churn and delinquency screens need it's id, for showing the call log modal, so that "churnOrDelinquencyId" is passed as param here and updated in ctx.
   */
  const updateCallLogScreenHandler = ({
    callLogForScreen,
    churnOrDeliquencyId,
  }: {
    callLogForScreen: CallLogForScreenProps;
    churnOrDeliquencyId: string;
  }) => {
    // we need the "callLogForScreen" during the call process,
    // this function used to update the "callLogForScreen" and delinquencyOrChurn id
    dispatch({
      type: UPDATE_CALL_LOG_SCREEN_DISPATCH,
      payload: {
        callLogForScreen,
        churnOrDeliquencyId,
      },
    });
  };

  /**
   *
   * @param phoneNumber
   * this fn fetch the customer detail and update the customer name in a browser's current tab
   */
  const fetchUserDetailsViaNum = (phoneNumber: string) => {
    getCustDetail({ mobile_num: phoneNumber, search_by: "mobile_number" }).then(
      (r) => {
        const isCustomer = r?.data && r?.data?.customer_list ? true : false;
        const custName =
          r &&
          r?.data &&
          r.data?.customer_list &&
          Array.isArray(r.data.customer_list) &&
          r.data.customer_list.length > 0
            ? r.data.customer_list[0].biz_name
            : "Unknown Customer";
        const rmName =
          r && r?.data && r.data?.rm_details && r.data.rm_details.rm_name;
        set(CUST_NAME, isCustomer ? custName ?? "" : rmName ?? "");
        // while incoming or outgoing call the end user will be cust or rm, so that
        // here we have checked the end user is rm or cust
        // depends on that we have updated the name
        updateCustNameOnCurrentAndAllOpenedTab({
          custName: isCustomer ? custName : rmName,
        });
      }
    );
  };

  /**
   *
   * @param custName
   * via channel this function sent the customer name to all the other tabs
   * via that channel again the updateCustomerName fn triggered
   * at last the customer name updated between all over the browser's tabs
   */
  const updateCustNameOnCurrentAndAllOpenedTab = ({
    custName,
  }: {
    custName: string;
  }) => {
    updateCustomerName({ custName });
    broadCastChannel?.postMessage({
      type: "UpdateCustName",
      payload: {
        custName,
      },
    });
  };

  /**
   *
   * @param name
   * update the custName in the browser's current tab
   */
  const updateCustomerName = ({ custName }: { custName: string }) => {
    dispatch({
      type: UPDATE_CUST_NAME_DISPATCH,
      payload: {
        custName,
      },
    });
  };

  return {
    updateCallScreenCb,
    checkUserHasAnyPendingCallLog,
    initiateCall,
    intiateCallCb,
    initiateCallAndCbTriggerHandler,
    cancelCall,
    cancelCallCb,
    attendCall,
    attendCallCb,
    closeCallLogModal,
    triggerCallLogModalOnParticularWindow,
    closeCallLogModalOnParticularWindow,
    changeSoftPhoneClientReadyStatus,
    updateCallLogScreenHandler,
    updateCallStartTimeOnOtherTabs,
    updateCustomerName,
  };
};

export default useSoftPhoneMethods;
