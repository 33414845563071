export const role_based_screen_menus = {
    app_support: ['home', 'search','transactions','utilities'],
    l2_support: ['home', 'search','transactions','utilities'],
    ops_analyst:[ 'home','dashboard','operations', 'transactions','reports','market','search'],
    it_admin:[ 'home','dashboard','operations', 'transactions','reports','market','search'],
    customer_success:[ 'home','operations', 'search' ,'transactions','reports'],
    customer_success_officer:[ 'home','operations','search', 'transactions','reports',],
    verification_officer:[ 'home','audit','operations', 'reports','search'],
    risk_manager:[ 'home','audit','operations', 'reports','search'],
    operations_auditor:[ 'home','audit','operations','reports','search'],
    super_admin:[ 'home','dashboard','reports', 'operations', 'market','search'],
    market_admin:[ 'home','dashboard','operations', 'reports','transactions','market','search'],
    finance_officer:[ 'home','dashboard','reports'],
    recovery_specialist:[ 'home','search','reports'],
    portfolio_reporter:[ 'home','dashboard','reports','search'],
    rm_support:[ 'home','operations', 'reports','search'],
    ops_admin:[ 'home','dashboard','operations', 'transactions','reports','market','search'],
    risk_compl_head:[ 'home','dashboard','audit', 'operations','reports','search'],
    loan_approver:[ 'home','search','reports'],
    mce_officer : [ 'home','operations', 'search' ,'transactions','reports'],
    investor:['home','dashboard', 'search' ,'reports'],
    relationship_manager:['home','search','reports','operations'],
    reporter_view: ['home', 'search', 'reports']
}

