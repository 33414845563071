import { get_path } from '../helpers/common_helper';
import {call_fetch} from './../helpers/fetch_helper';


export const viewCustomerDetails = request  => {
   return call_fetch(get_path('app') + '/loan_appl/products', request);
}

export const request_fa_upgrade = request => {
   return call_fetch(get_path('app') + '/task/create', request)
}

export const request_fa_upgrade_status_web = request => {
   return call_fetch(get_path('app') + '/loan_appl/req_upgrade_status_web', request)
}

export const approveLoan = request  => {
   return call_fetch(get_path('app') + '/loan/approval', request);
}


/*export const getApprovers = request  => {
   return call_fetch(get_path('app') + '/loan_appl/approvers', request);
}*/

export const applyLoan = request  => {
   return call_fetch(get_path('app') + '/loan_appl/apply', request);
} 

export const viewLoanApplication = request  => {
   return call_fetch(get_path('app') + '/loan_appl/application', request);
} 

export const searchLoanAppl = request  => {
   return call_fetch(get_path("app") + '/loan_appl/applications', request);
} 

export const validateAppl = request  => {
   return call_fetch(get_path('app') + '/loan_appl/validate', request);
} 

export const fa_appl_submit = request  => {
   return call_fetch(get_path('app') + '/loan_appl/fa_appl_submit', request);
} 

export const fa_appl_resend_otp = request  => {
   return call_fetch(get_path('app') + '/otp/generate', request);
} 


   