import { Dispatch } from "react";
import { AT_TOKEN, INCOMING } from "../../consts";
import {
  HandleSoftPhoneDispatchHandlerProps,
  SET_SOFT_PHONE_CLIENT_STATUS_DISPATCH,
} from "./softPhoneCtx";

type Props = {
  atClient: any;
  dispatch: Dispatch<HandleSoftPhoneDispatchHandlerProps>;
  setisCallDisconnected: Function;
  intiateCallCb: Function;
  setisCallConnected: Function;
  changeSoftPhoneClientReadyStatus: Function;
};

/**
 *
 * @param atClient
 * @param dispatch
 * @param setisCallDisconnected
 * @param intiateCallCb
 * @param setisCallConnected
 * @param changeSoftPhoneClientReadyStatus
 * used to register all the AIT events
 */
export const initializeAftricasTalking = ({
  atClient,
  dispatch,
  setisCallDisconnected,
  intiateCallCb,
  setisCallConnected,
  changeSoftPhoneClientReadyStatus,
}: Props) => {
  atClient.on(
    "ready",
    () =>
      dispatch({
        type: SET_SOFT_PHONE_CLIENT_STATUS_DISPATCH,
        payload: {
          isATsInstanceBelongsToCurrentTab: true,
          isSoftPhoneClientReady: true,
        },
      }),
    false
  );

  atClient.on(
    "notready",
    () => alert("Unable to make or receive calls"),
    false
  );

  atClient.on(
    "incomingcall",
    (params: any) => {
      let full_num = params.from;
      let mobile_num = full_num.replace("+256", "");
      intiateCallCb(INCOMING, full_num);
    },
    false
  );

  atClient.on(
    "hangup",
    (params: any) => {
      setisCallDisconnected(true);
    },
    false
  );

  atClient.on("offline", () => {
    alert(
      "Token expires, this will reload your tab and new token will be created"
    );
    changeSoftPhoneClientReadyStatus(false);
    localStorage.removeItem(AT_TOKEN);
    window.location.reload();
  });

  atClient.on(
    "callaccepted",
    (params: any) => {
      setisCallConnected(true);
    },
    false
  );

  atClient.on("isAudioMuted", () => alert("Audio is muted"), false);

  atClient.on(
    "closed",
    () => {
      changeSoftPhoneClientReadyStatus(false);
      alert(
        "An error occured unable to reach server, may be you are facing poor internet connection, this will automatically reload your tab"
      );
      window.location.reload();
    },
    false
  );
};
