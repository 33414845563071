import { Box, Grid, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { HeaderVarientH3Typo } from '../../CommonComponent/typography';
import FormProvider from '../../CommonComponent/rhfComponents/formProvider';
import { useForm } from 'react-hook-form';
import DateFilter from './DateFilter';
import CustomButton from '../../../MUI/Components/CustomButton';
import DataGridTable from '../../CommonComponent/table';
import { GridColDef, GridPaginationModel, GridRowsProp, GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import ReportFilters from './ReportFilters';
import { useLocation } from 'react-router-dom';
import withRouter from '../../../Router/Withrouter';
import { capitalize_first_letter_string } from '../../../helpers/common_helper';
import ExportCsv from '../../common/component/ExportCsv';

interface CommonReportProps {
    rows: GridRowsProp; 
    columns: GridColDef[];
    get_report:(data: any) => void;
    is_loading:boolean;
    date_fields: { 
        key: string;
        label: string;
      }[];

  }

  const CommonReport: React.FC<CommonReportProps> = ({ rows, columns, get_report,date_fields,is_loading }) => {

    const location = useLocation()
    const [page, setpage] = useState({ page: 0, pageSize: 10 });
    const theme = useTheme()
    const methods = useForm();
    const { handleSubmit, watch, reset, formState: {errors, defaultValues, dirtyFields} } = methods;
    const title = (location.pathname).replace(/^\/|\/$/g, '')

    const CustomToolbar = () => {
        return (
          <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 10 }}>
               <ExportCsv
                  export_csv_datas={ rows??[] }
                  csvFileName = {`${title} Report`}
                  paginationMode= {"client"}
                  columns={columns??[]}
                  isCsvExportedToMail={false}

                />
          </div>
        );
      };



  
    return (
        <Box>
            <HeaderVarientH3Typo
                text={capitalize_first_letter_string(title)  + " Report"}
                overRideStyles={{ color: theme.palette.text.primary, fontFamily: "Montserrat" }} />
               
                <ReportFilters get_report={get_report} date_fields={date_fields}/>
                

                <DataGridTable
                
                    rows={rows}
                    columns={columns}
                    paginationMode={"client"}
                    sortingMode={"client"}
                    page={page}
                    isLoading={is_loading}
                    isError={false}
                    errorText={''}
                    noOfColumnsToLoad={10}
                    setpage={setpage}
                    slots={{ toolbar: CustomToolbar }}
                    // @ts-ignore
                    slotProps={{
                        columnsPanel:false,
                        toolbar: {
                            printOptions: { disableToolbarButton: true },
                        
                    }}
                  }
                
                />

        </Box>
    );
};

export default  CommonReport ;