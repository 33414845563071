import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CallIcon from "@mui/icons-material/Call";
import CallEndIcon from "@mui/icons-material/CallEnd";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import SensorsIcon from "@mui/icons-material/Sensors";
import { Box, Button, IconButton } from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useRef } from "react";
import Draggable from "react-draggable";
import { BRIDGED, INCOMING } from "../../../../consts";
import {
  BORROWER_VIEW_SCREEN,
  DUP_TXN_REPORT,
  FA_VIEW_LOG_MODAL,
  PENDING_WITH_CUST_CALL_LOG_MODAL,
  SOFT_PHONE_LOG_MODAL,
} from "../../../../context/softPhoneCtx/softPhoneCtx";
import { useSoftPhoneCtx } from "../../../../context/softPhoneCtx/useSoftPhoneCtx";
import { FormatSecondsToHHMMSS } from "../../../../helpers/helper";
import {
  BodyVarientTwoTypo,
  HeaderVarientH5Typo,
} from "../../../CommonComponent/typography";

const CallStatusModal = ({
  timer,
  setTimer,
}: {
  timer: number;
  setTimer: Function;
}) => {
  // ctx
  const {
    methods: { attendCall, cancelCall, triggerCallLogModalOnParticularWindow },
    softPhoneState: {
      showCallStatusModal,
      startTimer: startTimerFromCtx,
      callState: { status },
      callLogDetails: {
        phoneNumber,
        callStartedTime,
        callLogForScreen,
        custName,
      },
      showCallLogModal,
    },
  } = useSoftPhoneCtx();

  // ref
  const nodeRef = useRef(null);

  // const
  const broadCastChannel = useMemo(
    () => new BroadcastChannel("Sync_Soft_Phone_Timer"),
    []
  );
  broadCastChannel.onmessage = (event) => channelMessageHandler(event);

  // cb
  const channelMessageHandler = (event: any) => {
    setTimer(event.data.payload.timerVal);
  };

  const answeringTheCallHandler = () => {
    triggerCallLogModalOnParticularWindow({
      callStartedTime: moment().format("DD MMM YYYY hh:mm a"),
      showCallLogModal: true,
    });
    attendCall({ isCurrentUserAnsweringTheCall: true });
  };

  // effect
  useEffect(() => {
    // if user answers the call from instance created window the timer is initiated by setting isATsInstanceBelongsToCurrentTab context state
    // next to that by broadcasting method the timer is initiated for all the other windows
    // if the user answers the call from the instance created window then the timer is initiated by (isATsInstanceBelongsToCurrentTab && status === BRIDGED)
    const canStartTimer = startTimerFromCtx;
    let timer: any;
    if (canStartTimer) {
      timer = setInterval(() => {
        setTimer((prev: number) => {
          broadCastChannel.postMessage({ payload: { timerVal: prev + 1 } });
          return prev + 1;
        });
      }, 1000);
    }

    if (status === "") {
      // set the timer value to 0 for "" status
      setTimer(0);
      if (canStartTimer) clearInterval(timer);
    }

    return () => {
      if (canStartTimer) {
        clearInterval(timer);
      }
    };
  }, [startTimerFromCtx, status]);

  const openCallStatusModal = showCallStatusModal;
  const isIncomingCall = status === INCOMING;
  const isCallConnected = status === BRIDGED;
  const showOpenCallLogBtn =
    status === BRIDGED &&
    !showCallLogModal &&
    (callLogForScreen === SOFT_PHONE_LOG_MODAL ||
      callLogForScreen === PENDING_WITH_CUST_CALL_LOG_MODAL ||
      callLogForScreen === FA_VIEW_LOG_MODAL ||
      callLogForScreen === BORROWER_VIEW_SCREEN ||
      callLogForScreen === DUP_TXN_REPORT);

  return (
    <>
      {openCallStatusModal && (
        <Draggable nodeRef={nodeRef}>
          <Box
            sx={{
              width: "300px",
              position: "absolute",
              right: 10,
              bottom: 10,
              backgroundColor: "#c6c7cd",
              p: 2,
              borderRadius: 5,
              textAlign: "center",
              cursor: "move",
              zIndex: 100000,
            }}
            ref={nodeRef}
          >
            {/* call indication ui */}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={1}
            >
              {/* incoming or outgoing indication icon */}
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "50%",
                  width: "25px",
                  height: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mr: 1,
                }}
              >
                {isIncomingCall ? (
                  <PhoneCallbackIcon />
                ) : (
                  <PhoneForwardedIcon />
                )}
              </Box>
              {/* incoming or outgoing indication text */}
              <BodyVarientTwoTypo
                text={isIncomingCall ? "Incoming Call" : "Calling..."}
                overRideStyles={{ color: "#6c757d" }}
              />
            </Box>
            {/* call connected and timer UI */}
            <Box
              my={1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SensorsIcon
                  sx={{
                    color: isCallConnected ? "#02D130" : "#FF0000",
                    mr: 1,
                  }}
                />
                <BodyVarientTwoTypo
                  text={isCallConnected ? "In Call" : ""}
                  overRideStyles={{ color: "#6c757d" }}
                />
              </Box>
              <BodyVarientTwoTypo
                text={isCallConnected ? FormatSecondsToHHMMSS(timer) : ""}
                overRideStyles={{ color: "#6c757d" }}
              />
            </Box>
            {/* avatar UI */}
            <div>
              <AccountCircleIcon
                sx={{
                  width: "100px",
                  height: "100px",
                  color: "#4f5167",
                  my: 2,
                }}
              />
              <HeaderVarientH5Typo
                text={phoneNumber ?? "Unknown number"}
                overRideStyles={{
                  textTransform: "uppercase",
                  color: "#000 !important",
                  fontWeight: "bolder",
                }}
              />
              <HeaderVarientH5Typo
                text={custName}
                overRideStyles={{
                  textTransform: "uppercase",
                  color: "#000 !important",
                  fontWeight: "bolder",
                }}
              />
            </div>
            {/* call log action UI */}
            {showOpenCallLogBtn && (
              <Button
                onClick={() =>
                  triggerCallLogModalOnParticularWindow({
                    callStartedTime,
                    showCallLogModal: true,
                  })
                }
                variant="contained"
                type="submit"
                color="secondary"
                sx={{ my: 2 }}
              >
                Call log
              </Button>
            )}
            {/* call action UI attend/decline */}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-around"
              my={3}
            >
              {isIncomingCall && (
                <IconButton
                  onClick={answeringTheCallHandler}
                  sx={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    backgroundColor: "#02D130",
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.success.main,
                    },
                  }}
                >
                  <CallIcon sx={{ width: "25px", height: "25px" }} />
                </IconButton>
              )}
              <IconButton
                onClick={cancelCall}
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    backgroundColor: (theme) => theme.palette.error.main,
                  },
                }}
              >
                <CallEndIcon sx={{ width: "25px", height: "25px" }} />
              </IconButton>
            </Box>
          </Box>
        </Draggable>
      )}
    </>
  );
};

export default CallStatusModal;
