import { Box } from "@mui/material";
import moment from "moment";
import { BRIDGED, OUTGOING } from "../../../../consts";
import { useSoftPhoneCtx } from "../../../../context/softPhoneCtx/useSoftPhoneCtx";
import TextField from "../../../CommonComponent/field/TextField";
import { HeaderVarientH4Typo } from "../../../CommonComponent/typography";
import CallLogModalCustDetailUI from "../callLogModalCustDetailUI";
import RemarksAndReasonUI from "../remarksAndReasonUI";

const SoftPhoneCallLogModal = ({
  setcustomerList,
  closeModalHandler,
}: {
  setcustomerList: Function;
  closeModalHandler: Function;
}) => {
  // ctx
  const {
    softPhoneState: {
      callState: { status },
      callLogDetails: {
        callLogForIncomingOrOutGoing,
        phoneNumber,
        callStartedTime,
      },
    },
  } = useSoftPhoneCtx();

  const isCallLogForOutGoingCall = callLogForIncomingOrOutGoing === OUTGOING;
  const isCallConnected = status === BRIDGED;

  return (
    <>
      <CallLogModalCustDetailUI />
      <HeaderVarientH4Typo
        overRideStyles={{ color: "#fff", margin: "16px 0px" }}
        text={"Call Details"}
      />
      <Box width="50%" textAlign="left" sx={{ my: 2 }}>
        <TextField
          label="Call started at"
          val={moment(callStartedTime).format("DD MMM YYYY hh:mm a")}
          direction="row"
        />
        <TextField label="Mobile number" val={phoneNumber ?? "Unknown number"} direction="row" />
      </Box>
      <RemarksAndReasonUI
        isCallLogForOutGoingCall={isCallLogForOutGoingCall}
        phoneNumber={phoneNumber}
        closeModalHandler={closeModalHandler}
        isCallConnected={isCallConnected}
        setcustomerList={setcustomerList}
      />
    </>
  );
};

export default SoftPhoneCallLogModal;
