import React from 'react';
import { Box, Button, Grid, Typography, TextField, Select, MenuItem } from '@mui/material';
import { BiPhoneCall } from 'react-icons/bi';
import CallIcon from '@mui/icons-material/Call';
const BizMobileVerifyIdentity = ({ biz_mob_numbers, callLogRejectHandler, audit_kyc_submit_callLog ,modal_close }) => {


  const  buttonHandler = async () =>{
   await audit_kyc_submit_callLog(biz_mob_numbers)
   modal_close()
  }

  const rejectButton = async () =>{
    callLogRejectHandler(biz_mob_numbers)
    modal_close()

  }

  const renderStepContent = (stepNumber, content, buttonType, additionalContent = null) => (



    <Grid container spacing={2} alignItems="center" sx={{my:2}}>
      <Grid item xs={9}>
        <Box sx={{ background: '#c4c7ca', borderRadius: '0px 20px 20px 0px' }}>
          <Grid container>
            <Grid item xs={2}>
              <Box sx={{ background: '#59687c', borderRadius: '0px 20px 20px 0px', p: biz_mob_numbers.call_log_key_type === "addl_num" ? 3 : 2 }}>
                <Typography variant="body2" color="white" fontWeight="bold">
                  Step {stepNumber}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={10} display="flex" alignItems="center">
              <Typography variant="body2" color="black" sx={{ml:2}}>
                {content}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
        { ['reject','verify'].includes(buttonType) ?
         <>
        {buttonType === 'reject' ? (
          <Button variant="contained" color="error" onClick={rejectButton}>
            Reject
          </Button>
        ) : (
          <Button
            variant="contained"
            color="info"
            disabled={buttonType === 'submit' && (!biz_mob_numbers.recipents_name || !biz_mob_numbers.recipents_relation)}
            onClick={buttonHandler}
          >
            Verify
          </Button>
        )}
        </>:
        <>
        <Typography variant="body1"  sx={{ml:2, display:'flex', alignItems:'center'}}>
        <CallIcon  sx={{mr:1}}/>
        {biz_mob_numbers?.mobile_num?.value}
        </Typography>
        
        </>
        }
      </Grid>
      {additionalContent}
    </Grid>
  );

  return (
    <Box sx={{ border: '1px solid grey', margin: '15px', padding: '20px 30px' }}>
      {renderStepContent(
        1,
        'Please dial the number and verify who picks the call',
        'info',
        audit_kyc_submit_callLog
      )}

      {renderStepContent(
        2,
        biz_mob_numbers.call_log_key_type === "addl_num"
          ? `If recipient is not related to ${biz_mob_numbers.active_holder_name}, click Reject button.`
          : <>If it is <b>{biz_mob_numbers.holder_name} </b>, click  <b>VERIFY</b> button</>,
        'verify',
         audit_kyc_submit_callLog
      )}

      {renderStepContent(
        3,
        
         <>If the information mismatches click <b>REJECT</b> button.</>,
         'reject',
        audit_kyc_submit_callLog
      )}
    </Box>
  );
};

export default BizMobileVerifyIdentity;
