import React from 'react';
import { Navigate } from 'react-router-dom';
import '../../../styles/flow.css';
import '../../market/container/datatables.css';
import $ from 'jquery';
import {listPerson} from "../../../actions/person_actions";
import FlowContainer from "../../common/container/core/FlowContainer";
import StatusButton from "../../common/component/core/StatusButton";
import ViewEdit from "../../common/component/ViewEdit";
import RelationshipManager from "../../relationship_manager/component/RelationshipManager";
import {create_column, person_name} from "../../../helpers/common_helper";
import { BasicReactTable } from '../../../helpers/react_table';
import DataGridTable from '../../CommonComponent/table';
import ExportCsv from '../../common/component/ExportCsv';
import { Box } from '@mui/material';
import { GridToolbar } from '@mui/x-data-grid';
import { GridToolbarExport } from '@mui/x-data-grid/components/toolbar/GridToolbarExport';
import { GridToolbarQuickFilter } from '@mui/x-data-grid/components/toolbar/GridToolbarQuickFilter';
import { dd_value } from '../../../actions/common_actions';

class ListUserContainer extends FlowContainer {

  state = {
     userList: null,
     toView: false,
     person_id: null ,
     data_prvdr: {},
     page: { page: 0, pageSize: 10 },


  }

    componentWillMount(){
    listPerson(this.req({'associated_with':'FLOW'}))
        .then((response) => {
          if(!response){return };
          this.setState({column_data:this.column_datas(),userList:response.data})
        });
  }

  on_status_change = ( id, status) => {
    this.setState({
      userList: this.state.userList.map((user) => {
        if (user.id == id) {
          return {
            ...user,
            status
          };
        }
        return user;
      })
    });
    this.setState({column_data:this.column_datas()})
  };


  column_datas(can_edit){
  
    const  column = [
      {  
      headerName:'Name',
      field:'first_name',
      minWidth:180,
      renderCell:(cell)=>{
        return <div className=' r font-for-otp-table'  >
          { person_name(cell.row)}
        </div>
        }
      },
      {headerName:"WhatsApp Number",field:"whatsapp" , minWidth:180},
      {headerName:"Mobile Number",field:"mobile_num",minWidth:180},
      {headerName:"Email ID",field:"email_id",minWidth:250},
      {headerName:"Designation",
        field:"role",
        valueFormatter: role => dd_value(role, 'role_code'),
        minWidth:180},

     
        {  id:'status',
          headerName:'Status',
          field:'status',
          minWidth:180,
          renderCell:(cell)=>{
            return <div className=' font-for-otp-table' id={cell.row.id} >
               <StatusButton id={cell.row.id} entity="users" status={cell.row.status}  head={"1"}/>
            </div>
          },
         },
          {  id:'action',
          headerName:'Action',
          field:'action_1',
          minWidth:180,
          renderCell:(cell)=>{
            return <div className=' font-for-otp-table' id={cell.row.id} >
               <StatusButton id={cell.row.id} entity="users" status={cell.row.status}  head={"2"} onStatusChange = {this.on_status_change}/>
            </div>
          },
         },
         {    id:'Edit',
              headerName:'Action',
              field:'action_2',
              minWidth:180,
              renderCell:(cell)=>{
                 return <div className='font-for-otp-table'><ViewEdit id={cell.row.id} entity="user"  person_id={cell.row.id}/></div>
              },
  
      }  
      ]
      return column;
  
  
    }


  handleFilterChange = (filteredRows) => {
    this.setState({ filteredUserList: filteredRows });
  };

  render(){
    const CustomGridToolbar = () => (
      <div style={{ display: 'flex', justifyContent: 'end',alignItems:'center'}}>
        <div className='mx-3 mt-1' >
        <ExportCsv
          export_csv_datas={ this.state.userList??[] }
          csvFileName = {'User List'}
          paginationMode= {"client"}
          columns={this.state.column_data??[]}
          isCsvExportedToMail={false}
          
        />
        </div>
        <GridToolbarQuickFilter />
      </div>
    );
    

    return(

    <div className="container">

      

          <div id='datatable data_prvdr_table ' className={"table-responsive  mt-1"}>
            <h5 className='mb-2'>Person List</h5>
                 {/* <table id ='user-list'  className="table">
                          <thead className={"text-info"}>
                          <tr>
                          <th>Name</th>
                          <th>WhatsApp Number</th>
                          <th>Mobile Number</th>
                          <th>Email ID</th>
                          <th>Designation</th>
                          <th>Status</th>
                          <th>Action</th>
                          <th>Action</th></tr></thead>
                          <tbody>
                          {this.state.userList}
                          </tbody>
                 </table> */}

                      {/* <Box display={'flex'} justifyContent={'end'}>
                       
                        <ExportCsv
                        // total_count={this.state.par_data?.length ?? 0} 
                        export_csv_datas={ this.state.userList??[] }
                        csvFileName = {'User List'}
                        paginationMode= {"client"}
                        isCsvExportedToMail={false}
                      />
                      </Box> */}
                 { (this.state.userList && this.state.userList.length>0 ) &&

                  <DataGridTable
                  rows={this.state.userList}
                  columns={this.state.column_data??[] }
                  paginationMode="client"
                  sortingMode="client"
                  rowCount={this.state.userList?.length}
                  page={this.state.page}
                  setpage={(newPage) => this.setState({ page: newPage })}
                  isLoading={undefined}
                  isError={false}
                  errorText=""
                  noOfColumnsToLoad={10}
                  slots={{ toolbar: CustomGridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      sx: {
                        color: '#FFFFFF', // Change text color
                        '& .MuiTypography-root': {
                          color: '#FFFFFF !important', // Customize specific child elements
                        },
                      }
                    },
                  }}
                  
                  /> 

                //  <BasicReactTable  row_data={this.state.userList} column_data={this.state.column_data} default_page_size = {10} pagination={this.state.userList.length > 9 ? true : false} csv_file={true}  global_search={true} export_csv_datas={this.state.userList}/> 


                 }
            </div>
       

        </div>
    );
}
}
export default ListUserContainer;
